import { Replay } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ModalContext, ToastContent } from "../../../../shared/components";
import { useToast } from "../../../../shared/hooks";
import { clientService } from "../../../../shared/services/api/client/clientService";
import { Box, Button, CircularProgress, Grid, Icon, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { formatDateTimeFNS } from "../../../../shared/utils/format-date";
import { HtmlTooltip } from "../../../../infra/components/tooltipsCustom/tooltipsCuston";
import { globalTranslateStatusContract } from "../../../../shared/utils";

export type TClientContracts = {
	id: string
	title: string
	createdAt: string
	updatedAt: string
	signed_dateAt: string
	uploadedAt: string
	signersCopies: string
	clientIdAdmin: string
	clientId: string
	contractId: string
	docKey: string
	storage: boolean
	statusSignature: string
	url: string
}

interface ContractsProps {
	clientId: string;
}

export default function Contracts({ clientId }: ContractsProps) {
	const [openModalConfirm, setOpenModalConfirm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const toastHandler = useToast();
	const [isRefresh, setIsRefresh] = useState(true);
	const [contractList, setContractList] = useState<TClientContracts[]>([]);
	const handleOpenModalConfirm = () => {
		setOpenModalConfirm(true);
	};

	const handleCloseModalConfirm = () => {
		setOpenModalConfirm(false);
	};

	useEffect(() => {
		setIsRefresh(true);
		clientService.getClientContractByID(clientId)
			.then((result) => {
				if (result instanceof Error) {
					alert(result.message);
				} else {
					const clientContractResult = result as unknown as TClientContracts[];
					setContractList(clientContractResult);
					setIsRefresh(false);
				}
			});
	}, []);


	const handleResendContract = (clientID: string) => {
		setIsLoading(true);

		clientService
			.resendContract(clientID)
			.then(() => {
				handleCloseModalConfirm();
				setIsLoading(false);
				toastHandler.present({
					type: "success",
					position: "top-right",
					messageOrContent: <ToastContent title={"Sucesso"} content={"Contrato reenviado com sucesso!"} />,
				});
			})
			.catch(() => {
				setIsLoading(false);
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={"Erro ao reenviar contrato!"} />,
				});
			});
	};

	return (
		<div>
			<Button variant="contained" endIcon={<Replay />} onClick={handleOpenModalConfirm}>
				Reenviar último contrato
			</Button>

			<Grid container item direction="row" spacing={2}>
				<Grid item md={12}>
					<TableContainer component={Paper} variant="outlined" sx={{ marginLeft: 1, marginTop: 1, marginRight: 2, width: 'auto', padding: 4 }} aria-label="customized table">
						{isRefresh ? <TableRow> <TableCell sx={{ textAlign: "center" }} colSpan={4}><CircularProgress /> </TableCell> </TableRow> :
							<Table aria-label="a dense table" >
								<TableRow >
									<TableCell sx={{ color: "#7e7777b5" }}>Titulo do contrato</TableCell>
									<TableCell sx={{ color: "#7e7777b5" }}>Status</TableCell>
									<TableCell sx={{ color: "#7e7777b5" }}>Enviado em</TableCell>
									<TableCell sx={{ color: "#7e7777b5" }}>Assinado em</TableCell>
									<TableCell sx={{ color: "#7e7777b5" }}>Arquivo</TableCell>
								</TableRow>
								<TableBody>
									{contractList.map(row => (
										<TableRow key={row.id} hover >
											<TableCell sx={{ fontWeight: "normal" }}>
												<Typography
													variant="subtitle2"
													color={"#959595"}
													fontWeight={"normal"}
													sx={{ display: "flex", alignItems: "center" }}>
													{row.title}
													<HtmlTooltip
														title={row.docKey}>
														<Icon fontSize="small" sx={{ marginLeft: 1, color: "#428af5" }}>info_outline</Icon>
													</HtmlTooltip>
												</Typography>
											</TableCell>
											<TableCell sx={{ fontWeight: "normal" }} >
												<Typography variant="subtitle2" fontWeight={"normal"} >
													{globalTranslateStatusContract(row.statusSignature)}
												</Typography>
											</TableCell>
											<TableCell sx={{ fontWeight: "normal" }} >
												<Typography variant="subtitle2" fontWeight={"normal"} textAlign={"center"} >
													{formatDateTimeFNS(row.createdAt)}
												</Typography>
											</TableCell>
											<TableCell sx={{ fontWeight: "normal" }} >
												<Typography variant="subtitle2" fontWeight={"normal"} textAlign={"center"} >
													{formatDateTimeFNS(row.signed_dateAt)}
												</Typography>
											</TableCell>
											<TableCell sx={{ fontWeight: "normal" }} >
												<IconButton size="small"
													href={row.url}
													disabled={!row.storage}
													sx={{ borderRadius: "18px" }}>

													<Icon sx={{ color: row.storage ? "#46a832" : "#d6d4d4", fontWeight: "normal" }}>download</Icon>
													<Typography variant="subtitle2" marginLeft={1} sx={{ color: row.storage ? "#46a832" : "#d6d4d4", fontWeight: "normal" }}>
														Baixar contrato
													</Typography>
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						}
					</TableContainer>
				</Grid>
			</Grid>

			{openModalConfirm && (
				<ModalContext
					isCloseModal={handleCloseModalConfirm}
					isOpenModal={openModalConfirm}
					messageType={"alert"}
					showTitleIcon={false}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					IMessage={[
						<Box display={"flex"} gap={3}>
							<Button variant="outlined" onClick={handleCloseModalConfirm}>
								Cancelar
							</Button>
							<Button variant="contained" onClick={() => handleResendContract(clientId)}>
								Reenviar
							</Button>
							,
						</Box>,
					]}
					ITitle="Deseja realmente reenviar o contrato?"
				/>
			)}
		</div>
	);
}
