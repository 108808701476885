import { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    LinearProgress,
    MenuItem,
    Paper,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';

import { IDetalheUsuario, userService } from "../../../shared/services/api/user/userService";
import { AutoCompleteClient, FerramentaDetalhe } from "../../../shared/components";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { IVFormErros, useVForm, VForm, VTextField } from "../../../shared/forms";
import { Email, } from "@mui/icons-material";
import { PermissionServices } from "../../../shared/services/api/permissions/PermissionService";
import { IPermissionInterface } from "../interface/PermissionInterface";
import { VMaskTextField } from "../../../shared/forms/VMaskTextField";
import { useAuthContext } from "../../../context";
import { UserTokenResponseType } from "../../../shared/interfaces/UserTokenResponse";
import { checkByProfileGuard } from '../../../shared/guards/ProfileGuard';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';
import { useProfile } from '../../../context/ProfileContext';
import { Client } from "../../../../client_api/model/client";
import { useToast } from '../../../shared/hooks';
import { ToastContent } from '../../../shared/components';
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import moment from "moment";


interface IFormData {
    name: string;
    email: string;
    phone: string;
    password?: string;
    permissionId: string;
    createdFrom: string;
    status: string;
    // usu_documento?: string;
    // role: string;
    // estateId:number;
}

const url = window.location.href;
const formatUrl = url.split('/');
let isUpdate = true;
if (formatUrl[5] === 'novo') {
    isUpdate = false;
}

const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required().email(),
    phone: yup.string().required(),
    password: isUpdate ? yup.string() : yup.string().required(),
    permissionId: yup.string().required(),
    createdFrom: yup.string().default('web_app'),
    status: yup.string().default('active').required(),
    // usu_documento: yup.string(),
    // role: yup.string().required(),
    // estateId:yup.number().default(0),

});


export const DetalheUsuariosPresentation: React.FC = () => {
    const { id = 'novo' } = useParams<'id'>();
    const navigate = useNavigate();
    const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } = useVForm();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const { permissionLevel, permissionId, isSuperAdmin } = useProfile();
    const [isLoading, setIsLoading] = useState(false);
    const [nome, setNome] = useState('');
    const [nomeClient, setNomeClient] = useState('');
    const [clientId, setClientId] = useState('');
    const [functionOptions, setFunctionOptions] = useState<IPermissionInterface[]>([]);
    const toastHandler = useToast();
    const [user, setUser] = useState<IDetalheUsuario | null>(null);

    useEffect(() => {
        if (id !== 'novo') {
            setIsLoading(true);

            userService.getByID(id)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        alert(result.message);
                        navigate('/usuarios');
                    } else {
                        setUser(result);

                        setNome(result.name);

                        const cli = result?.client as Client;
                        //console.log(cli,cli.name)
                        const nameClient = cli?.name || '';
                        const idClient = cli?.id || '';
                        setNomeClient(nameClient);
                        setClientId(idClient)


                        formRef.current?.setData({ ...result, password: 'garantti' });
                    }
                });
        } else {
            formRef.current?.setData({
                usu_cadastro: new Date(),
                // status: 'active',
                name: '',
                email: '',
                phone: '',
                usu_documento: '',
                usu_funcao: 'Operador',
            });
        }
    }, [id]);

    useEffect(() => {
        PermissionServices.getUnlimited(permissionLevel || 99).then((result: any) => {
            const data = result.data as unknown as IPermissionInterface[]
            setFunctionOptions(data);
        }).catch((err: any) => {
            const { titleMessage, contentMessage } = returnMessageError(err);

            toastHandler.present({
                type: 'error',
                position: 'top-right',
                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
            })
        })
    }, [])

    const handleSave = (dados: IFormData) => {

        formValidationSchema.
            validate(dados, { abortEarly: false })
            .then((dadosvalidados) => {
                setIsLoading(true);
                if (id === 'novo') {
                    userService
                        .create(dadosvalidados)
                        .then((result: any) => {

                            setIsLoading(false);
                            if (result instanceof Error) {
                                const titleMessage = "Alerta!!";
                                const contentMessage = "Erro: " + result?.message;

                                toastHandler.present({
                                    type: 'error',
                                    position: 'top-right',
                                    messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                                });

                            } else {
                                toastHandler.present({
                                    type: 'success',
                                    position: 'top-right',
                                    messageOrContent: <ToastContent title={"Sucesso"} content={"Usuário criado com sucesso!"} />
                                });

                                if (issaveAndClose()) {
                                    navigate(`/usuarios/`);
                                } else {
                                    navigate(`/usuarios/detalhe/${result}`);
                                }
                            }
                        });
                } else {

                    const role = functionOptions.map((result) => {
                        if (dadosvalidados.permissionId && result.id == dadosvalidados.permissionId) {
                            return (result.clientTypeId)
                        }
                    })
                    delete dadosvalidados.password; //remover a senha para nao atualizar....
                    userService
                        .updateById(id, { id: id, ...dadosvalidados, role: role ? role[0] : 'estate_user' })
                        .then((result: any) => {
                            setIsLoading(false);

                            if (result instanceof Error) {
                                const titleMessage = "Alerta!!";
                                const contentMessage = "Erro: " + result?.message;

                                toastHandler.present({
                                    type: 'error',
                                    position: 'top-right',
                                    messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                                });
                            } else {
                                toastHandler.present({
                                    type: 'success',
                                    position: 'top-right',
                                    messageOrContent: <ToastContent title={"Sucesso"} content={"Usuário atualizado com sucesso."} />
                                })
                                
                                if (issaveAndClose()) {
                                    navigate(`/usuarios/`);
                                }
                            }
                        });
                }
            })
            .catch((errors: yup.ValidationError) => {
                const validationErros: IVFormErros = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErros[error.path] = error.message;
                });

                //console.log(validationErros);
                formRef.current?.setErrors(validationErros);
            });
    };

    const handleDelete = (id: string) => {
        if (confirm("Deseja apagar o registro ?")) {
            userService.deleteById(id)
                .then(result => {
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        alert('Registro apagado com sucesso !');
                        navigate('/usuarios');
                    }
                });
        }
    };

    const label = { inputProps: { 'aria-label': 'Switch demo' } }

    // const handleClient =() =>{ return clientId}

    return (
        <LayoutBaseDePagina
            titulo='Usuarios'
            subtitulo={id === 'novo' ? 'Cadastro de novo usuario' : `Alteração de usuario [ ${nome} ]`}
            barraDeFerramentas={
                <FerramentaDetalhe
                    textoButtonNew="novo"
                    showButtonNew={id !== 'novo' && checkByProfileGuard(PermissionRolesEnum.UsersOptions, PermissionActionsEnum.Add)}
                    showBotaoApagar={false}
                    showBotaoSalvarFechar={checkByProfileGuard(PermissionRolesEnum.UsersOptions, PermissionActionsEnum.Update)}
                    showBotaoSalvar={checkByProfileGuard(PermissionRolesEnum.UsersOptions, PermissionActionsEnum.Update)}
                    clicarSalvar={save}
                    clicarSalvarFechar={saveAndClose}
                    clicarApagar={() => handleDelete(id)}
                    clicarNovo={() => navigate('/usuarios/detalhe/novo')}
                    clicarVoltar={() => navigate('/usuarios')}
                />
            }
        >
            <VForm
                id="formIdSave"
                placeholder="Your placeholder"
                onPointerEnterCapture={() => console.log("Pointer entered")}
                onPointerLeaveCapture={() => console.log("Pointer left")}
                ref={formRef} onSubmit={handleSave}>
                <Box display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                    padding={1}
                    alignItems={"flex-start"}
                    // sx={{ background: "red"  }}
                    gap={1}
                >
                    <Grid item sm={12} md={8} width={"100%"}
                        direction={smDown ? 'column' : 'row'}
                        padding={2}
                        spacing={2}
                        component={Paper} variant="outlined"
                    >

                        <Grid container item spacing={2}>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={11} >
                                    <Typography variant="h6" marginBottom={2} color={"secondary"}>Informações Gerais</Typography>
                                </Grid>

                                <Grid item xs={1} justifyContent="end" alignContent={"end"} textAlign="end">
                                    <IconButton color="primary">
                                        <Icon>input</Icon>
                                    </IconButton>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} >
                                    <VTextField
                                        fullWidth
                                        label='Status'
                                        name="status"
                                        disabled={isLoading}
                                        select
                                    >
                                        <MenuItem value={'active'}>Ativo</MenuItem>
                                        <MenuItem value={'inactive'}>Inativo</MenuItem>
                                        <MenuItem value={'blocked'}>Bloqueado</MenuItem>
                                    </VTextField>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} >
                                    <VTextField
                                        fullWidth
                                        label='Nome'
                                        name="name"
                                        disabled={isLoading}
                                        onChange={e => setNome(e.target.value)}
                                    // size="normal"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} >
                                    <VTextField
                                        fullWidth
                                        label='Password'
                                        name="password"
                                        disabled={isLoading || id !== 'novo'}
                                        type='password'
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} >
                                    <VMaskTextField
                                        fullWidth
                                        label='Telefone'
                                        name="phone"
                                        disabled={isLoading}
                                        mask="cell"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} >
                                    <VTextField
                                        fullWidth
                                        label='Função'
                                        name="permissionId"
                                        disabled={isLoading}
                                        select
                                    >
                                        {
                                            functionOptions?.length > 0
                                                ?
                                                functionOptions?.map((result, index) => {
                                                    if (permissionLevel && result.level >= permissionLevel) {
                                                        return (
                                                            <MenuItem key={index} value={result.id}>{result.description}</MenuItem>
                                                        )
                                                    }
                                                })
                                                :
                                                <></>
                                        }
                                        {/* <MenuItem value={'Operador'}>Operador</MenuItem>
                                        <MenuItem value={'Administrador'}>Administrador</MenuItem> */}
                                    </VTextField>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={2}>

                                <Grid item xs={12} sm={12} md={6} >
                                    <VTextField
                                        fullWidth
                                        label='E-mail'
                                        name="email"
                                        disabled={isLoading || id !== 'novo'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        // onClick={handleClickShowPassword}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        <Email />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    // size="normal"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container sm={12}
                                mt={3}
                                direction="row"
                                spacing={1} >
                                <Grid item xs={12} >
                                    <Typography variant="h6" ml={2} color={'secondary'}>
                                        Informações Complementares
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2} mb={1}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" fontWeight={'bold'} color={'text.secondary'}>
                                        Data do cadastro: {moment(user?.createdAt).format("DD/MM/YYYY HH:mm")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" fontWeight={'bold'} color={'text.secondary'}>
                                        Último login: {!user?.last_login ? '--' : moment(user.last_login).format("DD/MM/YYYY HH:mm")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item sm={12} md={4}
                        width={smDown ? "100%" : "65%"}
                        direction={smDown ? 'column' : 'row'}
                        padding={2} spacing={2}
                        component={Paper} variant="outlined"
                        marginRight={1}

                    >
                        <Grid container item direction="row" spacing={2}>
                            <Grid item xs={11} >
                                <Typography variant="h6" marginBottom={2} color={"secondary"}>Empresa</Typography >
                            </Grid>

                            <Grid item xs={1} justifyContent="end" alignContent={"end"} textAlign="end">
                                <IconButton color="primary">
                                    <Icon>input</Icon>
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid container item direction="row" spacing={2}>
                            <Grid item xs={12} sm={12} >
                                <AutoCompleteClient
                                    isExternalLoading={isLoading}
                                    labelSelect={'Cliente'}
                                    namecampo='pes_parceiro_id'
                                    //idSelected = {handleClient}
                                    nameDefault={nomeClient}
                                    clientDefault={clientId}
                                // action={clientId != ''?'edit':'add'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </VForm>

        </LayoutBaseDePagina >
    );
};