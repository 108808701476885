import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  Grid,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as yup from "yup";
import { ResetPasswordService } from "../../../shared/services/api/reset-password/ResetPasswordService";
import { useNavigate } from "react-router-dom";
import imageSite from "../../../assets/recover_pass.png";
import { Loading } from "../../../shared/components";

const resetPasswordSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export const ForgotPasswordPresentation: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [resetCallSuccessful, setResetCallSuccessful] = useState(false);

  const navigate = useNavigate();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await resetPasswordSchema.validate({ email }, { abortEarly: false });

      try {
        await ResetPasswordService.save({ email });

        setResetCallSuccessful(true);
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
        alert(e.message);
      }
    } catch (errors: any) {
      setIsLoading(false);

      errors.inner.forEach((error: yup.ValidationError) => {
        if (error.path === "email") {
          setEmailError(error.message);
        }
      });
    }
  };

  const handleNavigateBack = async () => {
    setIsLoading(true);

    navigate("/login");
  };

  return (
      <Box>
        <Grid container>
          <Grid item xs={7} display={smDown ? "none" : "flex"} textAlign={"center"} justifyContent={"center"}>
            <Box height="99.5vh" justifyContent="left" display={"flex"} alignItems={"center"}>
              <img
                src={imageSite}
                alt="Site image site.png"
                width="auto"
                height="70%"
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={smDown ? 12 : 5}
            display="flex"
            alignItems="center"
            justifyContent={smDown ? "center" : "flex-start"}
          >
            {!resetCallSuccessful ? (
              <>
                <Card sx={{ borderRadius: "8px" }}>
                  <CardContent sx={{ margin: "1rem 2rem" }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      width={400}
                    >
                      <Box
                        id="operation-box"
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <img
                          src="logo_garantti_login.png"
                          alt="Logo"
                          height={"100px"}
                        />
                      </Box>
                      <Typography variant="h6" align="center">
                        Recuperação de Senha
                      </Typography>
                      <Typography variant="caption" align="center">
                        Por favor, insira seu e-mail. Caso ele seja válido, iremos
                        lhe enviar um e-mail com as informações para criar uma
                        nova senha.
                      </Typography>
                      <TextField
                        fullWidth
                        type="email"
                        label="Email"
                        value={email}
                        disabled={isLoading}
                        error={!!emailError}
                        helperText={emailError}
                        onKeyDown={() => setEmailError("")}
                        onChange={(e) => setEmail(e.target.value)}
                        size={"small"}
                        sx={{ margin: "1rem 0" }}
                      />
                    </Box>
                  </CardContent>
                  <CardActions
                    sx={{ margin: "1rem 2rem" }}
                    style={{
                      height: "50%",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Button
                        variant="contained"
                        disabled={isLoading}
                        onClick={handleNavigateBack}
                        endIcon={
                          isLoading ? (
                            <CircularProgress variant="indeterminate" />
                          ) : undefined
                        }
                        sx={{
                          width: "inherit",
                          background: "#F5F5F5",
                          color: "#000000",
                          margin: "1rem 1rem 1rem 0",
                        }}
                      >
                        Voltar
                      </Button>

                      <Button
                        variant="contained"
                        disabled={isLoading}
                        onClick={handleSubmit}
                        endIcon={
                          isLoading ? (
                            <CircularProgress variant="indeterminate" />
                          ) : undefined
                        }
                        sx={{
                          width: "inherit",
                          background: "primary",
                          margin: "1rem 0",
                        }}
                      >
                        Confirmar
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </>
            ) : (
              <>
                <Card
                  sx={{ borderRadius: "8px" }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent
                    sx={{ margin: "1rem 2rem" }}
                    style={{
                      height: "50%",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      width={400}
                    >
                      <Box
                        id="success-box"
                        width="100%"
                        display="flex"
                        alignItems="space-between"
                        justifyContent="center"
                      >
                        <img src="green_check_mark_68.png" alt="Check Mark" />
                      </Box>

                      <Typography variant="h6" align="center">
                        Solicitação concluida com sucesso!
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions
                    sx={{ margin: "1rem 2rem" }}
                    style={{
                      height: "50%",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      width="100%"
                    >
                      <Button
                        variant="contained"
                        disabled={isLoading}
                        onClick={handleNavigateBack}
                        endIcon={
                          isLoading ? (
                            <CircularProgress variant="indeterminate" />
                          ) : undefined
                        }
                        sx={{
                          width: "inherit",
                          background: "primary",
                          margin: "1rem 0",
                        }}
                      >
                        Voltar
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
  );
};
