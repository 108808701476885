import { Box, Button, Grid, Icon, LinearProgress, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { VForm } from "../../../../../shared/forms";
import { useToast } from "../../../../../shared/hooks";
import { ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";

interface ModalCancelWaiverProps {
	isClose: () => void;
	isOpenModal: boolean;
	rows: any;
}

const ModalWaiverCancel: React.FC<ModalCancelWaiverProps> = ({ isClose, isOpenModal, rows }) => {
	const formRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const toastHandler = useToast();
	const navigate = useNavigate();

	const handleConfirmCancelProposalWaiver = () => {
		{
			ProposalService.updateConfirmCancelWaiver(rows.id).then((result) => {
				setIsLoading(false);

				if (result instanceof Error) {
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={"Sucesso"} content={"Erro ao desistir do cancelamento de garantia   |  " + result.message} />,
					});
				} else {
					toastHandler.present({
						type: "success",
						position: "top-right",
						messageOrContent: <ToastContent title={"Sucesso"} content={"Garantia ativada com sucesso"} />,
					});
					navigate(`/garantias/`);
				}
			});
		}
	};

	return (
		<>
			{isOpenModal && (
				<ModalContext
					isCloseModal={isClose}
					isOpenModal={isOpenModal}
					messageType={"question"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					showTitleIcon={false}
					textButtonCancel={"Voltar"}
					ITitle={"Confirma a desistência em cancelar a garantia ?"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formIdCancelProposalConclude"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRef}
									onSubmit={handleConfirmCancelProposalWaiver}
								>
									<Grid container direction="column" padding={1} spacing={2}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}

										<Typography mb={3} textAlign={"center"} color={"red"}>
											A Desistência do Cancelamento da Garantia, voltará para o status de ativada
										</Typography>
									</Grid>
									<Grid
										container
										sm={12}
										sx={{
											border: "none",
											display: "flex",
											justifyContent: "center",
										}}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										<Button
											color="inherit"
											disableElevation
											variant={"contained"}
											startIcon={<Icon> arrow_back_Ico</Icon>}
											onClick={isClose}
										>
											{"Voltar"}
										</Button>
										<Button
											color="error"
											disableElevation
											variant={"contained"}
											startIcon={<Icon> checkIcon</Icon>}
											onClick={() => {
												handleConfirmCancelProposalWaiver();
											}}
										>
											{"Desisitir do Cancelamento"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}
		</>
	);
};

export default ModalWaiverCancel;
