import { Api } from "../axios-config";

export type ClientFollowUpNotesResponse = {
	id: string;
	userId: string;
	userName: string;
	createdAt: Date;
	note: string;
};

export type ContactDataType = {
	id: string;
	email: string;
};

const getClientFollowUpNotes = async (clientId: string): Promise<ClientFollowUpNotesResponse[]> => {
	try {
		const { data } = await Api().get(`/client-followup/${clientId}/notes`);

		if (data) return data;

		throw new Promise((_, reject) => reject(new Error("Erro ao carregar os followups.")));
	} catch (error) {
		console.error(error);
		throw new Promise((_, reject) => reject(error));
	}
};

const addClientFollowUpNote = async (clientId: string, formattedNote: string): Promise<String> => {
	try {
		const { data } = await Api().post(`/client-followup/notes`, {
			clientId,
			formattedNote,
		});

		if (data) return data;

		throw new Promise((_, reject) => reject(new Error("Erro ao adicionar followup.")));
	} catch (error) {
		console.error(error);
		throw new Promise((_, reject) => reject(error));
	}
};

const getClientFollowUpContacts = async (clientId: string): Promise<ContactDataType[]> => {
	try {
		const { data } = await Api().get(`/client-followup/${clientId}/contacts`);

		if (data) return data;

		throw new Promise((_, reject) => reject(new Error("Erro ao carregar emails de contato!")));
	} catch (error) {
		console.error(error);
		throw new Promise((_, reject) => reject(error));
	}
};

const addClientFollowUpContact = async (clientId: string, email: string): Promise<any> => {
	try {
		const { data } = await Api().post(`/client-followup/contacts`, {
			clientId,
			email,
		});

		if (data) return data;

		throw new Promise((_, reject) => reject(new Error("Erro ao adicionar email de contato!")));
	} catch (error) {
		console.error(error);
		throw new Promise((_, reject) => reject(error));
	}
};

const removeClientFollowUpContact = async (contactId: string): Promise<void> => {
	try {
		await Api().delete(`/client-followup/contacts/${contactId}`);
	} catch (error) {
		console.error(error);
		throw new Promise((_, reject) => reject(error));
	}
};

export const ClientFollowUpService = {
	getClientFollowUpNotes,
	addClientFollowUpNote,
	getClientFollowUpContacts,
	addClientFollowUpContact,
	removeClientFollowUpContact,
};
