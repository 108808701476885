import { AuthGuard } from '../../shared/guards/AuthGuard';
import { Sidebar } from '../../shared/components';
import { ReportProposalPresentation } from './components/ReportProposalPresentation';


export const ReportProposalPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <ReportProposalPresentation  />
            </Sidebar>
        </AuthGuard>
    )
};