
import { Api } from '../axios-config';
import {AnalysisContractTypeEnum} from '../../../../enums/analysis-documents';
import {GetValuesDocumentTypesResponseType, SinisterDocumentType} from './types';
import { SinisterValuesDocumentType } from './types/sinister-values-document.type';

interface IAuth {
    accessToken: string;
}
export type TSinisterDocumentsListaAndTotal = {
  results: SinisterDocumentType[];
  totalCount: number;
}


const uploadSinisterDocument = async (sinisterId: string, checkListId:string,documentTypeId: string, file: File): Promise<any> => {
  const formData = new FormData()
  formData.append('sinisterId', sinisterId);
  formData.append('checkListId', checkListId);
  //formData.append('documentTypeId', documentTypeId);
 // formData.append('status', 'pending');
  formData.append('file', file);
  const { data }  = await Api().post('/sinister-document', formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return data as any;
};

const uploadSinisterValuesDocument = async (sinisterId: string, checkListId:string,documentTypeId: string, file: File): Promise<any> => {
  const formData = new FormData()
  formData.append('sinisterId', sinisterId);
  formData.append('sinisterValuesId', checkListId);
  //formData.append('documentTypeId', documentTypeId);
 // formData.append('status', 'pending');
  formData.append('file', file);
  const { data }  = await Api().post('/sinister-values-document', formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return data as any;
};

//: Promise<SinisterDocumentType[]> 
const getSinisterDocuments = async (sinisterId: string, checkListId:string)=> {
  const data = await Api().get(`/sinister-document/search/${sinisterId}/${checkListId}`);

  return data;
};


// const getSinisterValuesDocuments = async (sinisterId: string, sinisterValues:string) => {
//   const data = await Api().get(`/sinister-values-document/${sinisterId}/lista/${sinisterValues}`);
// console.log('sinisterDocumentsService 31',data)

//   return data ;


// };


const getSinisterValuesDocumentTypes = async (sinisterParam:string,listId:string): Promise< SinisterValuesDocumentType[]> => {
//GetValuesDocumentTypesResponseType
  const { data }  = await Api()
    .get(`/sinister-values-document/${sinisterParam}/lista/${listId}`)
    //?_page=1&_limit=100`);

  return data as  SinisterValuesDocumentType[];//GetValuesDocumentTypesResponseType[];
};

const removeDocumentBySinister = async (
  documentId: string
): Promise<void | Error> => {

  try {
    const { data } = await Api().patch(
      `/sinister-document/sinister/${documentId}`
    );

    if (!data)
      return new Promise((_, reject) =>
        reject(new Error("Erro ao remover documento."))
      );
  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};





export const SinisterDocumentsService = {
  uploadSinisterDocument,
  getSinisterDocuments,
  getSinisterValuesDocumentTypes,
   uploadSinisterValuesDocument,
   removeDocumentBySinister,
};

