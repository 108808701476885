import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Paper, LinearProgress, FormControlLabel, Switch, MenuItem } from "@mui/material";
import { globalBackgroundPayment, globalRemoveMask, globalRemoveMaskReal } from "../../../../shared/utils";
import { VTextField } from "../../../../shared/forms";
import { VMaskTextField } from "../../../../shared/forms/VMaskTextField";
import { differenceInDays } from "date-fns";
import { ToastContent } from "../../../../shared/components";
import { useToast } from "../../../../shared/hooks";

interface RentValuesSectionProps {
	isLoading: boolean;
	showButtonReanalysis: (valor: boolean) => void;
	isContractRunner: (valor: boolean) => void;
	formRef: any;
	Rows: any;
}

const RentValuesSection: React.FC<RentValuesSectionProps> = ({ isLoading, formRef, Rows, showButtonReanalysis, isContractRunner }) => {
	const weeks = Array.from({ length: 49 }, (_, index) => index + 12);
	const [vRentAmount, setVRentAmount] = useState("0");
	const [vCondominiumAmount, setVCondominiumAmount] = useState("0");
	const [vEletricAmount, setVEletricAmount] = useState("0");
	const [vWaterAmount, setVWaterAmount] = useState("0");
	const [vGasAmount, setVGasAmount] = useState("0");
	const [vIPTUAmount, setVIPTUAmount] = useState("0");
	const [vTotalAmout, setVTotalAmout] = useState("0");
	const [colorContractEnd, setColorContractEnd] = useState("");
	const toastHandler = useToast();
	const [LimitSerasa, setLimitSerasa] = useState(Rows?.limitSerasa || "0");
	const [statusAnalysis, setStatusAnalysis] = useState(Rows?.status || "");
	const [isContractRunnerCheck, setIsContractRunnerCheck] = useState(false);

	// Atualiza valores de Limite e Status quando disponíveis em Rows
	useEffect(() => {
		if (Rows.creditLimit) setLimitSerasa(Rows.creditLimit);
		if (Rows.status) setStatusAnalysis(Rows.status);
		if (Rows.rentAmount) setVRentAmount(Rows.rentAmount);
		if (Rows.condominiumAmount) setVCondominiumAmount(Rows.condominiumAmount);
		if (Rows.electricEnergyAmount) setVEletricAmount(Rows.electricEnergyAmount);
		if (Rows.waterAmount) setVWaterAmount(Rows.waterAmount);
		if (Rows.gasAmount) setVGasAmount(Rows.gasAmount);
		if (Rows.iptuAmount) setVIPTUAmount(Rows.iptuAmount);
		
	}, [Rows]);

	// Função para validar o botão de reanálise
	const validateButtonReanalysis = () => {
		const showReanalysis = parseFloat(vRentAmount) <= parseFloat(LimitSerasa) || statusAnalysis !== "pre_approved";
		showButtonReanalysis(showReanalysis);
	};

	// Validar o botão de reanálise ao montar e ao atualizar limite/status
	useEffect(() => {
		validateButtonReanalysis();
	}, [LimitSerasa, statusAnalysis, vRentAmount]);

	//Calcula o valor total
	useEffect(() => {
		const total = [vRentAmount, vCondominiumAmount, vEletricAmount, vWaterAmount, vGasAmount, vIPTUAmount].reduce(
			(acc, curr) => acc + parseFloat(curr || "0"),
			0
		);
		setVTotalAmout(total.toString());
	}, [vRentAmount, vCondominiumAmount, vEletricAmount, vWaterAmount, vGasAmount, vIPTUAmount]);

	const handleBlurEndDate = (e: React.FocusEvent<HTMLInputElement>) => {
		const isValid = isValidContractEnd(e.target.value);
		setColorContractEnd(isValid ? "" : "#ffefef");
	};

	const isValidContractEnd = (contractEndDate: string) => {
		const endDate = new Date(contractEndDate);
		const currentDate = new Date();
		const diff = differenceInDays(endDate, currentDate);

		if (diff < 365) {
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: (
					<ToastContent title="Alerta!" content={`${diff} dias. O contrato deve ser maior que 12 meses a partir da data de análise.`} />
				),
			});
			return false;
		}
		return true;
	};

	const handleChangeContractRunner = (event: React.ChangeEvent<HTMLInputElement>) => {
		const ischecked = event.target.checked;
		setIsContractRunnerCheck(ischecked);
		isContractRunner(ischecked);
	};

	const handleBlurValueField = (e: React.FocusEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<string>>) => {
		let value = globalRemoveMask(e.target.value);
		setter(value);
	};

	return (
		<Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
			<Grid container direction="column" padding={2} spacing={2}>
				{isLoading && (
					<Grid item>
						<LinearProgress variant="indeterminate" />
					</Grid>
				)}
				<Grid container item direction="row" spacing={2}>
					<Grid item xs={11}>
						<Typography variant="h6" color="#1c1b1b">
							Valores da locação
						</Typography>
					</Grid>
				</Grid>

				{statusAnalysis === "pre_approved" && (
					<Grid container item spacing={2}>
						<Grid item xs={12}>
							<Box
								sx={{ border: "1px solid #ffefef", background: globalBackgroundPayment("overdue"), padding: "0.2% 2.3%", borderRadius: "4px" }}
							>
								<Typography variant="body1" color="#d54949" fontWeight="bold">
									Analise Pré-Aprovada com valor até {parseFloat(LimitSerasa).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
								</Typography>
								<Typography variant="body2" color="#d54949" fontWeight="bold">
									Próximos passos:
								</Typography>
								<Typography variant="body2" color="#d54949">
									Você deverá reduzir o valor do aluguel ou negociar junto ao proprietário ou ainda buscar outro imóvel que possua os valores
									e taxas compatíveis com este valor total pré-aprovado. Será necessário ajustar o valor do aluguel (conforme o limite
									aprovado) e, caso tenha buscado outro imóvel, alterar os DADOS DO IMÓVEL. (Não é necessário realizar uma nova análise)
								</Typography>
							</Box>
						</Grid>
					</Grid>
				)}

				<Grid container item spacing={2}>
					<Grid item xs={12} md={4}>
						<FormControlLabel
							control={<Switch onChange={handleChangeContractRunner} name="isContractRunner" color="primary" checked={isContractRunnerCheck} />}
							label="Contrato de locação já existe?"
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<VTextField
							fullWidth
							label="Inicio do contrato de locação"
							name="contractBegin"
							disabled={isLoading || !isContractRunnerCheck}
							type="date"
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<VTextField
							fullWidth
							label="Fim do contrato de locação"
							name="contractEnd"
							disabled={isLoading || !isContractRunnerCheck}
							type="date"
							onBlur={handleBlurEndDate}
							sx={{ background: colorContractEnd }}
						/>
					</Grid>
				</Grid>

				<Grid container item spacing={2}>
					<Grid item xs={12} md={4}>
						<VTextField fullWidth label="Quantidade de meses Vigência" name="rentMonthsCount" disabled={isLoading} select>
							{weeks.map((month) => (
								<MenuItem key={month} value={month}>
									{month}
								</MenuItem>
							))}
						</VTextField>
					</Grid>

					{/* Campos de valores do aluguel */}
					{[
						{ label: "Valor do Aluguel", name: "rentAmount", value: vRentAmount, setter: setVRentAmount },
						{ label: "Valor do Condomínio", name: "condominiumAmount", value: vCondominiumAmount, setter: setVCondominiumAmount },
						{ label: "Valor da Energia", name: "electricEnergyAmount", value: vEletricAmount, setter: setVEletricAmount },
						{ label: "Valor da Água", name: "waterAmount", value: vWaterAmount, setter: setVWaterAmount },
						{ label: "Valor do Gás", name: "gasAmount", value: vGasAmount, setter: setVGasAmount },
						{ label: "Valor IPTU", name: "iptuAmount", value: vIPTUAmount, setter: setVIPTUAmount },
					].map(({ label, name, value, setter }) => (
						<Grid item xs={12} md={4} key={name}>
							<VMaskTextField
								fullWidth
								label={label}
								name={name}
								disabled={isLoading}
								mask="real"
								value={value}
								onBlur={(e) => handleBlurValueField(e as any, setter)}
								sx={{
									background:
										name === "rentAmount" && parseFloat(vRentAmount) > parseFloat(LimitSerasa) && statusAnalysis === "pre_approved"
											? globalBackgroundPayment("overdue")
											: "#FFF",
								}}
							/>
							{name === "rentAmount" && parseFloat(vRentAmount) > parseFloat(LimitSerasa) && statusAnalysis === "pre_approved" && (
								<Typography variant="caption" color="#e71d1d">
									Valor maior que o limite de {parseFloat(LimitSerasa).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
								</Typography>
							)}
						</Grid>
					))}
					{/* Exibição do valor total */}
					<Grid item xs={4}>
						<Box display="flex" alignItems="center" gap={2}>
							<Typography variant="body1">Valor total da locação:</Typography>
							<Typography variant="h6" sx={{ background: "#FFF", padding: "4px 8px", borderRadius: 1, color: "gray" }}>
								{parseFloat(vTotalAmout).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default RentValuesSection;
