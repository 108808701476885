import React from "react";
import { Grid, Paper, Icon, Typography, Skeleton } from "@mui/material";

interface ProposalSinisterCardProps {
	rows: any;
	isSinisterFinalizedByProposal: boolean;
	smDown: boolean;
	isLoading: boolean;
}

const ProposalSinisterCard: React.FC<ProposalSinisterCardProps> = ({ rows, isSinisterFinalizedByProposal, smDown, isLoading }) => {
	if (isLoading) {
		return (
			<Grid item xs={12} md={12}>
				<Grid container display={"flex"} flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} ml={1}>
					<Skeleton variant="rectangular" width="100%" height={50} />
				</Grid>
			</Grid>
		);
	}

	const hasSinister = rows?.sinister?.some((s: any) => !(s.status === "finalized" || s.status === "canceled" || s.status === "credit_recovery"));

	return (
		<>
			{hasSinister && (
				<Grid item xs={12} md={12}>
					<Grid container display={"flex"} flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} ml={1}>
						<Grid container item direction="row">
							{hasSinister && (
								<Grid
									container
									item
									direction={"row"}
									xs={12}
									sx={{ background: "#fef2f2", borderRadius: "5px", justifyContent: "center", padding: 1 }}
								>
									<Typography variant="h4" color={"red"}>
										Garantia Sinistrada.
									</Typography>
								</Grid>
							)}

							{isSinisterFinalizedByProposal && (
								<Grid container item direction={"row"} xs={12} sx={{ background: "#fef2f2", borderRadius: "5px", alignItems: "center" }}>
									<Icon
										sx={{
											color: "#ffffff",
											background: "#b8b1b1",
											fontSize: "18px",
											borderRadius: "40px",
											padding: "3px",
										}}
									>
										error_outline_Outlined_Icon
									</Icon>
									<Typography variant="subtitle1" sx={{ color: "#a30d12", flex: "auto", textAlign: "center" }}>
										Esta garantia teve um sinistro finalizado.
									</Typography>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default ProposalSinisterCard;
