import { Box, CircularProgress, Icon, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { formatAnalysisContractTypeEnum } from "../../../../../../enums/analysis-documents";
import { ModalContext, ToastContent } from "../../../../../../shared/components";
import { useDebounce, useToast } from "../../../../../../shared/hooks";
import { ProposalDocumentsService } from "../../../../../../shared/services/api/proposal/ProposalDocumentsService";
import { ProposalDocumentType } from "../../../../../../shared/services/api/proposal/types";
import { formatDate } from "../../../../../../shared/utils/format-date";
import { returnMessageError } from "../../../../../../shared/utils/returnMessageError";
import { getFileExtensionIcon } from "../../helpers";
import {
	StyledFileBox,
	StyledFileIconBox,
	StyledFileMetadataBox,
	StyledFileOperationsBox,
	StyledOperationFileExclude,
	StyledOperationIconBox,
	StyledProposalFileItemContainer,
	StyledTypography,
} from "./ProposalFileItem.styled";

type ProposalFileItemProps = {
	item: ProposalDocumentType;
	refreshListDocuments?: (value: boolean) => void;
};

export const ProposalFileItem: React.FC<ProposalFileItemProps> = ({ item, refreshListDocuments }) => {
	const [openModal, setOpenModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const toastHandler = useToast();
	const { debounce } = useDebounce();

	const handleRemoveFile = useCallback(async () => {
		setLoading(true);
		refreshListDocuments?.(false);
		debounce(() => {
			ProposalDocumentsService.removeDocumentByProposal(item.id)
				.then((result) => {
					setLoading(false);
					refreshListDocuments?.(true);
					if (result instanceof Error) {
						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title="Alerta!!" content={`Erro: ${result.message}`} />,
						});
					} else {
						toastHandler.present({
							type: "success",
							position: "top-right",
							durationMs: 3000,
							messageOrContent: <ToastContent title="SUCESSO!" content="Documento excluido com sucesso." />,
						});
						setTimeout(() => {
							refreshListDocuments?.(false);
						}, 500);
					}
				})
				.catch((err) => {
					setLoading(false);
					const { titleMessage, contentMessage } = returnMessageError(err);
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				});
		});
	}, [item.id, refreshListDocuments, debounce, toastHandler]);

	const handleModalClose = useCallback(() => setOpenModal(false), []);
	const handleModalOpen = useCallback(() => setOpenModal(true), []);

	return (
		<div style={{ flexDirection: "row", display: "flex" }}>
			<StyledProposalFileItemContainer>
				<StyledFileBox>
					<StyledFileIconBox style={{ height: "4rem", width: "4rem" }}>{getFileExtensionIcon(item.mimetype)}</StyledFileIconBox>
				</StyledFileBox>

				<StyledFileMetadataBox>
					<StyledTypography style={{ fontWeight: "bold", fontSize: "0.9rem" }}>{item.originalFilename}</StyledTypography>
					<StyledTypography style={{ fontSize: "0.8rem" }}>{formatAnalysisContractTypeEnum(item.docType)}</StyledTypography>
					<StyledTypography style={{ fontSize: "0.8rem", color: "grey", opacity: 0.9 }}>{formatDate(new Date(item.uploadedDate))}</StyledTypography>
				</StyledFileMetadataBox>

				<StyledFileOperationsBox>
					<a href={item.url} style={{ textDecoration: "none", color: "inherit" }}>
						<StyledOperationIconBox>
							<Icon style={{ fontSize: "2.5rem" }}>download</Icon>
						</StyledOperationIconBox>
					</a>
				</StyledFileOperationsBox>
			</StyledProposalFileItemContainer>

			<div style={{ alignSelf: "center", cursor: "pointer", marginRight: "8px", marginTop: "25px" }}>
				{loading ? (
					<CircularProgress />
				) : (
					<>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							justifyContent="center"
							padding={1}
                            borderRadius={2}
							sx={{
								cursor: "pointer",
								"&:hover": {
									backgroundColor: "#f5f5f5",
								},
							}}
							onClick={handleModalOpen}
						>
							<StyledOperationFileExclude />
							<Typography variant="body2" color="error">
								Excluir
							</Typography>
						</Box>

						<ModalContext
							isOpenModal={openModal}
							isCloseModal={handleModalClose}
							ITitle="Confirmação"
							messageType="info"
							showButtonCancel
							showButtonConfirm
							showTitleIcon={false}
							textButtonCancel="Cancelar"
							textButtonConfirm="Excluir"
							enableBackdropClose={false}
							overrideWidthValue="30%"
							overrideHeightValue="25%"
							IMessage={[
								<Typography variant="h6" marginTop={2} fontWeight={400}>
									Você deseja excluir mesmo o arquivo?
								</Typography>,
							]}
							onclickConfirm={() => {
								handleModalClose();
								handleRemoveFile();
							}}
						/>
					</>
				)}
			</div>
		</div>
	);
};
