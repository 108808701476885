import { Api } from "../axios-config";
import { IMessage } from "../../../types/message";

export interface ISerasaData {
  id?: string;
  document?: string;
  personType?: string;
  context?: string;
  contextTableId?: string;
  data?: string;
  createdAt?: Date;
  name?: string;
  naturalPersonMotherName?: string;
  naturalPersonBirthDate?: Date;
  naturalPersonSerasaScore?: number;
  income?: string;
}

export interface ISerasaResponse {
  response?: {
    body?: string;
  };
  serasa_response?: {
    error?: string;
    message?: string;
    message_description?: string;
    name?: string;
    naturalPersonSerasaScore?: number;
    policy?: string;
    policy_data?: string;
    report: string;
  };
  status?: string;
}

const getSerasaByAnalysisId = async (
  id: string
): Promise<IMessage<ISerasaData> | Error> => {
  try {
    const { data } = await Api().get(`/serasa/analysis/${id}`);

    if (!data) {
      return new Promise((_, reject) =>
        reject({
          message: "Erro!",
          description: "Erro ao consultar as informações do serasa.",
          status: 400,
          error: new Error("Erro ao consultar as informações do serasa."),
        })
      );
    }

    return new Promise((resolve) =>
      resolve({
        message: "Sucesso!",
        description: "Informações do log do serasa retornado com sucesso!",
        status: 200,
        data,
      })
    );
  } catch (error: any) {
    console.error(error);

    return new Promise((_, reject) =>
      reject({
        message: "Erro!",
        description: error?.response?.data?.message,
        status: error?.response?.status,
        error,
      })
    );
  }
};

export const serasaService = {
  getSerasaByAnalysisId,
};
