import { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
  LinearProgress,
  Button,
} from '@mui/material';
import { FerramentaDetalhe, Sidebar } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useNavigate, useParams } from 'react-router-dom';
import { translate } from '../../../shared/utils/translate';
import {
  IDetalheUsuario,
  userService,
} from '../../../shared/services/api/user/userService';
import { useProfile } from '../../../context/ProfileContext';
import moment from 'moment';
import { useToast } from '../../../shared/hooks';
import { ToastContent } from '../../../shared/components';
import { returnMessageError } from '../../../shared/utils/returnMessageError';
import { AuthGuard } from '../../../shared/guards';
import { Loading } from '../../../shared/components/loading2';
import { FinancialService } from '../../../shared/services/api/financial/FinancialService';

export const PerfilUserDetailPresentation: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<IDetalheUsuario>();
  const { permissionDescription } = useProfile();
  const toastHandler = useToast();

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      userService
        .getByID(id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            const titleMessage = 'Alerta!!';
            const contentMessage = 'Erro: ' + result?.message;

            toastHandler.present({
              type: 'error',
              position: 'top-right',
              messageOrContent: (
                <ToastContent title={titleMessage} content={contentMessage} />
              ),
            });

            setTimeout(() => {
              navigate('/dashboard');
            }, 4000);
          } else {
            setUser(result);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          const { titleMessage, contentMessage } = returnMessageError(err);
          toastHandler.present({
            type: 'error',
            position: 'top-right',
            messageOrContent: (
              <ToastContent title={titleMessage} content={contentMessage} />
            ),
          });

          setTimeout(() => {
            navigate('/dashboard');
          }, 4000);
        });
    }
  }, [id]);

  const maskPhone = (value: string) => {
    let cell =
      value.length <= 14
        ? value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1')
        : value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1');

    if (cell.length < 1) {
      cell = ' ';
    }
    return cell;
  };

  const runNfseIssueRoutine = async () => {
    const data = await FinancialService.executeNfseIssueRoutine();
    if (data instanceof Error) {
      toastHandler.present({
        type: 'error',
        position: 'top-right',
        messageOrContent: (
          <ToastContent
            title="Erro"
            content="Erro ao executar rotina de emissão de Nfse."
          />
        ),
      });
    } else {
      toastHandler.present({
        type: 'success',
        position: 'top-right',
        messageOrContent: (
          <ToastContent
            title="Sucesso"
            content="Rotina de emissão de Nfse executada com sucesso."
          />
        ),
      });
    }
  };

  return (
    <AuthGuard>
      <Sidebar>
        <LayoutBaseDePagina
          titulo={'Perfil'}
          subtitulo={'Perfil do Usuário'}
          barraDeFerramentas={
            <FerramentaDetalhe
              showButtonNew={false}
              showBotaoApagar={false}
              showBotaoSalvar={false}
              showBotaoSalvarFechar={false}
              clicarVoltar={() => navigate('/dashboard')}
            />
          }
        >
          {isLoading && <LinearProgress variant="indeterminate" />}

          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Grid
                container
                item
                sm={12}
                display={'flex'}
                flexDirection={smDown ? 'column' : 'row'}
                ml={smDown ? 0 : 2}
              >
                <Grid
                  container
                  item
                  sm={12}
                  display={'flex'}
                  flexDirection={smDown ? 'column' : 'row'}
                  component={Paper}
                  padding={2}
                  spacing={1}
                >
                  <Grid container item sm={12} direction="row" spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6" color={'primary'}>
                        Informações Gerais Imobiliária
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item direction="row" spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="caption" color={'text.secondary'}>
                        Nome/ Razão Social
                      </Typography>
                      <Typography variant="subtitle2">
                        {user?.client?.name}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item direction="row" spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      sx={{ backgroundColor: '#f0f1f1' }}
                    >
                      <Typography variant="caption" color={'text.secondary'}>
                        CPF/CNPJ
                      </Typography>
                      <Typography variant="subtitle2">
                        {user &&
                        user.client &&
                        user.client.document &&
                        user.client.document.length > 11
                          ? `${user?.client?.document?.replace(
                              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                              '$1.$2.$3/$4-$5'
                            )}`
                          : `${user?.client?.document?.replace(
                              /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                              '$1.$2.$3-$4'
                            )}`}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={8}
                      sx={{ backgroundColor: '#f0f1f1' }}
                    >
                      <Typography variant="caption" color={'text.secondary'}>
                        Nome Fantasia
                      </Typography>
                      <Typography variant="subtitle2">
                        {user?.client?.trade_name}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item direction="row" spacing={1}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography variant="caption" color={'text.secondary'}>
                        Tipo de Cliente
                      </Typography>
                      <Typography variant="subtitle2">
                        {translate(`${user?.client?.type_person}`)}
                        {user?.client?.type_person}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography variant="caption" color={'text.secondary'}>
                        Responsável
                      </Typography>
                      <Typography variant="subtitle2">
                        {user?.client?.responsible_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography variant="caption" color={'text.secondary'}>
                        CRECI
                      </Typography>
                      <Typography variant="subtitle2">
                        {user?.client?.creci}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item direction="row" spacing={1} mb="1rem">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      sx={{ backgroundColor: '#f0f1f1' }}
                    >
                      <Typography variant="caption" color={'text.secondary'}>
                        Celular
                      </Typography>
                      <Typography variant="subtitle2">
                        {maskPhone(`${user?.client?.mobilePhone}`)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      sx={{ backgroundColor: '#f0f1f1' }}
                    >
                      <Typography variant="caption" color={'text.secondary'}>
                        Telefone
                      </Typography>
                      <Typography variant="subtitle2">
                        {maskPhone(`${user?.client?.phone}`)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      sx={{ backgroundColor: '#f0f1f1' }}
                    >
                      <Typography variant="caption" color={'text.secondary'}>
                        Email
                      </Typography>
                      <Typography variant="subtitle2">
                        {user?.client?.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" color={'text.secondary'}>
                        Endereço
                      </Typography>
                      <Typography variant="subtitle2">
                        {`${
                          user?.client?.address_street
                            ? `${
                                user?.client?.address_complement
                                  ? `${user?.client?.address_street},${user?.client?.address_number} - ${user?.client?.address_complement} -
                                            ${user?.client?.address_district} - ${user?.client?.address_city} - ${user?.client?.address_state} - ${user?.client?.address_zipcode}`
                                  : `${user?.client?.address_street},${user?.client?.address_number} - 
                                            ${user?.client?.address_district} - ${user?.client?.address_city} - ${user?.client?.address_state} - ${user?.client?.address_zipcode}`
                              } `
                            : ''
                        }`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                sm={12}
                mb={2}
                display={'flex'}
                flexDirection={smDown ? 'column' : 'row'}
                mt={2}
                ml={smDown ? 0 : 2}
              >
                <Grid
                  container
                  item
                  sm={12}
                  display={'flex'}
                  flexDirection={smDown ? 'column' : 'row'}
                  component={Paper}
                  padding={2}
                  spacing={1}
                >
                  <Grid container item sm={12} direction="row" spacing={1}>
                    <Grid item xs={11}>
                      <Typography variant="h6" color={'primary'}>
                        Informações Gerais Usuário
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item direction="row" spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      sx={{ backgroundColor: '#f0f1f1' }}
                    >
                      <Typography variant="caption" color={'text.secondary'}>
                        Nome
                      </Typography>
                      <Typography variant="subtitle2">{user?.name}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      sx={{ backgroundColor: '#f0f1f1' }}
                    >
                      <Typography variant="caption" color={'text.secondary'}>
                        Status
                      </Typography>
                      <Typography variant="subtitle2">
                        {user?.status == 'active' ? 'Ativo' : 'Inativo'}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      sx={{ backgroundColor: '#f0f1f1' }}
                    >
                      <Typography variant="caption" color={'text.secondary'}>
                        E-mail
                      </Typography>
                      <Typography variant="subtitle2">{user?.email}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      sx={{ backgroundColor: '#f0f1f1' }}
                    >
                      <Typography variant="caption" color={'text.secondary'}>
                        Data Criação
                      </Typography>
                      <Typography variant="subtitle2">
                        {moment(user?.created_at).format('DD/MM/YYYY')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      sx={{ backgroundColor: '#f0f1f1' }}
                    ></Grid>
                  </Grid>

                  <Grid container item direction="row" spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      sx={{ backgroundColor: '#f0f1f1' }}
                    >
                      <Typography variant="caption" color={'text.secondary'}>
                        Celular
                      </Typography>
                      <Typography variant="subtitle2">
                        {maskPhone(`${user?.phone}`)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={9}
                      sx={{ backgroundColor: '#f0f1f1' }}
                    >
                      <Typography variant="caption" color={'text.secondary'}>
                        Função
                      </Typography>
                      <Typography variant="subtitle2">
                        {permissionDescription}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                sm={12}
                display={'flex'}
                flexDirection={smDown ? 'column' : 'row'}
                ml={smDown ? 0 : 2}
              >
                <Grid
                  container
                  item
                  sm={12}
                  display={'flex'}
                  flexDirection={smDown ? 'column' : 'row'}
                  component={Paper}
                  padding={2}
                  spacing={1}
                >
                  <Grid container item sm={12} direction="row" spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6" color={'primary'}>
                        Manutenção do Sistema
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item direction="row" spacing={1}>
                    <Grid item direction="column">
                      <Button variant="contained" onClick={runNfseIssueRoutine}>
                        Executar Rotina de Emisão de NFSE
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </LayoutBaseDePagina>
      </Sidebar>
    </AuthGuard>
  );
};
