import { Api } from '../axios-config';
import {ProposalDocumentType} from './types';

export type GetProposalCancellingFollowupNotesResponse = {
  id: string,
  userId: string,
  userName: string,
  createdAt: Date,
  note: string,
}

const getProposalCancellingFollowupNotes = async (proposalId: string): Promise<GetProposalCancellingFollowupNotesResponse[]> => {
  const { data }  = await Api().get(`/proposal-cancelling/${proposalId}/notes`);

  return data as GetProposalCancellingFollowupNotesResponse[];
};

const addProposalCancellingFollowupNote = async (proposalId: string, formattedNote: string): Promise<void> => {
  return Api().post(`/proposal-cancelling/notes`,  {
    proposalId,
    formattedNote
  });
};

const getProposalCancellingFollowupContacts = async (proposalId: string): Promise<{ id: string; email: string; }[]> => {
  const { data }  = await Api().get(`/proposal-cancelling/${proposalId}/contacts`);

  return data;
};

const addProposalCancellingFollowupContact = async (proposalId: string, email: string): Promise<void> => {
  return Api().post(`/proposal-cancelling/contacts`,  {
    proposalId,
    email
  });
};

const removeProposalCancellingFollowupContact = async (contactId: string): Promise<void> => {
  await Api().delete(`/proposal-cancelling/contacts/${contactId}`);
};

export const ProposalCancellingService = {
  getProposalCancellingFollowupNotes,
  addProposalCancellingFollowupNote,
  getProposalCancellingFollowupContacts,
  addProposalCancellingFollowupContact,
  removeProposalCancellingFollowupContact
};

