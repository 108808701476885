import { format, isValid } from "date-fns";

export const globalMaskDocument = (value: string) => {
	if (!value) return "";

	const formattedValue =
		value.length > 11
			? value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
			: value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
	return formattedValue;
};

export const globalRemoveMask = (value: string) => {
	if (!value) return "";

	return value
		.replace(/\D/g, "")
		.replace(/[^0-9]/g, "")
		.replace(/(\d{1,2})$/, ".$1");
};

export const globalRemoveSpecialCharacters = (value: string) => {
	if (!value) return "";

	return value.replace(/[^\w\s]/gi, "");
};

export function maskCpf(value: any) {
	if (!value) return "";

	return value
		.replace(/\D/g, "")
		.replace(/(\d{3})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d)/, "$1-$2");
}
export function maskCnpj(value: any) {
	if (!value) return "";

	return value
		.replace(/\D/g, "")
		.replace(/(\d{2})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d{4})/, "$1/$2")
		.replace(/(\d{4})(\d{1,2})/, "$1-$2");
}
export function unmaskCpfOrCnpj(value: any) {
	if (!value) return "";

	return value.replace(/\D/g, "");
}

export function maskCpfOrCnpj(value: any, lgpd?: any) {
	if (!value) return "";

	if (unmaskCpfOrCnpj(value).length <= 11) {
		if (lgpd) return `***${value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4").substring(3, 12)}**`;

		return maskCpf(value);
	}
	return maskCnpj(value);
}

export const globalMaskReal = (value: string) => {
	return value
		.replace(/\D/g, "")
		.replace(/(\d{1,2})$/, ",$1")
		.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const globalRemoveMaskReal = (value: string) => {
	return value
		.replace(/\D/g, "")
		.replace(/[^0-9]/g, "")
		.replace(/(\d{1,2})$/, ".$1");
};

export const globalRemoveMaskReal2 = (value: string) => {
	// Remove todos os caracteres que não são dígitos
	let cleanedValue = value.replace(/\D/g, "");

	// Se o valor tiver menos de 3 dígitos, adiciona "0." no início (ex.: "1" -> "0.01")
	if (cleanedValue.length <= 2) {
		cleanedValue = cleanedValue.padStart(3, "0"); // Garante ao menos 3 dígitos
		return `0.${cleanedValue.slice(-2)}`;
	}

	// Caso contrário, insere um ponto antes dos últimos dois dígitos
	return cleanedValue.slice(0, -2) + "." + cleanedValue.slice(-2);
};

export function globalFormatCurrencyBRL(value: string) {
	const numberValue = Number(value) || 0; // Garantir que seja um número válido
	return numberValue.toLocaleString("pt-BR", {
		style: "currency",
		currency: "BRL",
	});
}

export const globalFormatDates = (date: string | undefined) => {
	if (!date || date === "1899-11-30") {
		return ""; // Evita mostrar datas inválidas como 1899-11-30
	}
	const parsedDate = new Date(date);
	return isValid(parsedDate) ? format(parsedDate, "dd/MM/yyyy") : "";
};
