export const translate: (value: string) => string = (value: string) => {
  switch (value) {
    case "active":
      return "Ativo";
    case "inactive":
      return "Inativo";
    case "blocked":
      return "Bloqueado";
    case "canceled":
      return "Cancelado";
    case "sent":
      return "Enviado";
    default:
      return "Ativo";
  }
};

export const translateBiometryStatus: (value: string) => string = (value: string) => {
  switch (value) {
    case "created":
      return "Criado";
    case "send":
      return "Enviado";
    case "authenticated":
      return "Autenticado";
    case "canceled":
      return "Cancelado";
    case "manual-authenticated":
      return "Autenticado manualmente";
    case "expired":
      return "Expirado";
    default:
      return "Não definido";
  }
};
