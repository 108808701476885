import React from 'react';
import { AlertProvider } from './AlertContext';
import Checkout from './Checkout';

const OnboardCheckoutPresentation: React.FC = () => {
  return (
    <AlertProvider>
      <Checkout />
    </AlertProvider>
  );
};

export default OnboardCheckoutPresentation;
