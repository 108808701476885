import { Autocomplete, Box, Button, CircularProgress, FormControl, Grid, Icon, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { ListSearchParams } from "../../../../pages/analysis/types/list-search-params.type";
import { useEffect, useMemo, useState } from "react";
import { useProfile } from "../../../../context/ProfileContext";
import { ToastContent } from '../..';
import { TClientData, clientService } from "../../../services/api/client/clientService";
import { useToast } from '../../../hooks';
import { returnMessageError } from '../../../utils/returnMessageError';
import { Search, UploadFile } from "@mui/icons-material";
import moment from 'moment';
import 'moment/locale/pt-br';
import { IListagemUsuario, userService } from "../../../../shared/services/api/user/userService";
import { Environment } from "../../../environment";


export type TAutoCompleteOptionAnalysis = {
    id: string;
    label: string;
}

interface IToolbarAnalysisFilterReportProps {

    showButtonBack?: boolean;
    showButtonBackLoading?: boolean;
    clickBack?: () => void;

    showPeriodo?: boolean;
    onChangeDataIni?: (value: any) => void;
    onChangeDataFim?: (value: any) => void;

    onClick?: () => void;

    showInputOrigem?: boolean;
    onChangeOrigem?: (text: string) => void;

    showInputClientFilter?: boolean;
    onChangeClientFilter?: (text: string) => void;

    showInputManagerFilter?: boolean;
    textoButtonManager?: string;
    onChangeManagerFilter?: (text: string) => void;

    showInputBusca?: boolean;
    loading?: boolean | undefined;

    loadingExport?: boolean | undefined;

    showExport?: boolean;
    onClickExport?: () => void;

    showLimitPage?: boolean;
    onChangeLimitPage?: (text: number) => void;

    onclickReport?:() => void;
    showReport?:boolean;

}


export const ToolbarAnalysisFilterReport: React.FC<IToolbarAnalysisFilterReportProps> = ({
    showButtonBack = true,
    showButtonBackLoading = false,
    showPeriodo = true,

    showInputClientFilter = true,
    onChangeClientFilter,

    showInputOrigem = true,
    onChangeOrigem,

    showInputManagerFilter = false,
    textoButtonManager = 'Gestor',
    onChangeManagerFilter,

    showInputBusca = true,
    loading = false,

    loadingExport = false,

    showExport = true,
    onClickExport,

    showLimitPage = true,
    onChangeLimitPage,

    clickBack,
    onChangeDataIni,
    onChangeDataFim,
    onClick,
    onclickReport,
    showReport = true

}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [dataIni, setDataIni] = useState(moment().startOf('month').subtract(1, 'months').format("YYYY-MM-DD"));
    const [dataFim, setDataFim] = useState(moment().endOf('month').format("YYYY-MM-DD"));
    const [origem, setOrigem] = useState('Todos');

    const [opcoes, setOpcoes] = useState<TAutoCompleteOptionAnalysis[]>([]);
    const [selectedId, setSelectedId] = useState<string | undefined>();
    const [busca, setBusca] = useState('');
    const { userClientId, isSuperAdmin, permissionLevel } = useProfile();
    const toastHandler = useToast();
    const theme = useTheme();
    const [opcoesManager, setOpcoesManager] = useState<TAutoCompleteOptionAnalysis[]>([]);
    const [nameManager, setNameManager] = useState<string>('');
    const [selectedIdManager, setSelectedIdManager] = useState<string | undefined>();
    const [pageSize, setPageSize] = useState(Environment.LIMITE_LINHAS);

    const handleChangeOrigem = (event: SelectChangeEvent) => {
        setOrigem(event.target.value as string);
        onChangeOrigem?.(event.target.value as string)
    };

    useEffect(() => {
        setIsLoading(true);

        clientService.getSelect(1, busca, "", "", "active", (isSuperAdmin || permissionLevel == 2) ? "" : userClientId)
            .then((result: TClientData | Error) => {

                setIsLoading(false);
                if (result instanceof Error) {
                    const titleMessage = "Alerta!!";
                    const contentMessage = "Erro: " + result?.message;

                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    })

                } else {
                    const options = result?.data?.map(client => ({ id: client.id, label: client.name }));

                    if ((!isSuperAdmin || permissionLevel == 2) && options?.length == 1) {
                        setSelectedId(options[0].id);
                    }

                    setOpcoes(options);
                }
            }).catch((err: any) => {
                setIsLoading(false);
                const { titleMessage, contentMessage } = returnMessageError(err);

                toastHandler.present({
                    type: 'error',
                    position: 'top-right',
                    durationMs: 4000,
                    messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                })
            })
    }, [busca]);


    useEffect(() => {
        if ((isSuperAdmin || permissionLevel == 2)) {
            setIsLoading(true);

            userService.getAdminManagers(nameManager)
                .then((result: any) => {
                    setIsLoading(false);

                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })

                    } else {
                        const options = result?.data?.map((user: IListagemUsuario) => ({ id: user.id, label: user.name }));
                        setOpcoesManager(options);
                    }
                }).catch((err: any) => {
                    setIsLoading(false);
                    const { titleMessage, contentMessage } = returnMessageError(err);

                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    })
                })
        }
    }, [nameManager]);

    const autoCompleteSelectedOption = useMemo(() => {
        if (!selectedId) return null;
        const selectedOption = opcoes.find(opcao => opcao.id === selectedId);
        if (!selectedOption) return null;
        return selectedOption;
    }, [selectedId, opcoes]);

    const autoCompleteSelectedManager = useMemo(() => {
        if (!selectedIdManager) return null;
        const selectedOption = opcoesManager.find(opcao => opcao.id === selectedIdManager);
        if (!selectedOption) return null;
        return selectedOption;
    }, [selectedIdManager, opcoesManager]);

    return (
        <Box
            marginX={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            height={theme.spacing(5)}
            component={Paper}
            sx={{ padding: "70px 15px" }}
        >

            <Grid container direction="row" spacing={2} sx={{ marginLeft: "0px" }}>
                <Grid item xs={12} rowGap={2} sx={{ display: 'contents', alignItems: 'center' }}>
                    {(showButtonBack && !showButtonBackLoading) && (
                        <Grid item xs={12} md={1.5} paddingRight={0.5}>
                            <Button
                                color="primary"
                                disableElevation
                                sx={{width: '95%', height: '40px'}}
                                variant="contained"
                                onClick={clickBack}
                                startIcon={<Icon>keyboard_double_arrow_left_icon</Icon>}
                            >
                                <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                    Voltar
                                </Typography>
                            </Button>
                        </Grid>
                    )}

                    {showInputManagerFilter && (
                        <> <Grid item xs={12} md={4} paddingRight={1}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    fullWidth
                                    openText="Abrir"
                                    closeText="Fechar"
                                    noOptionsText="Nenhuma opção"
                                    loadingText="Loading ..."
                                    disablePortal
                                    value={autoCompleteSelectedManager}
                                    options={opcoesManager}
                                    loading={isLoading}
                                    size={'small'}
                                    onInputChange={(_, newValue) => {
                                        setNameManager(newValue);
                                    }}
                                    onChange={(_, value: any) => {
                                        setSelectedIdManager(value?.id ? value?.id : '')
                                        onChangeManagerFilter?.(value?.id ? value?.id : '' as string);
                                    }}
                                    renderInput={(params) => <TextField {...params} label={textoButtonManager} name="gestor" />}
                                />
                            </FormControl>
                        </Grid>
                        </>
                    )}

                    {showInputClientFilter && (
                        <Grid item xs={12} md={4} paddingRight={1}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    fullWidth
                                    openText="Abrir"
                                    closeText="Fechar"
                                    noOptionsText="Nenhuma opção"
                                    loadingText="Loading ..."
                                    disablePortal
                                    value={autoCompleteSelectedOption}
                                    options={opcoes}
                                    loading={isLoading}
                                    size={'small'}
                                    onInputChange={(_, newValue) => setBusca(newValue)}
                                    onChange={(_, newValue) => {
                                        onChangeClientFilter?.(newValue?.id ? newValue?.id : '' as string);
                                        setSelectedId(newValue?.id ? newValue?.id : '' as string);
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Parceiro/Cliente" name="cliente"
                                    />}
                                />
                            </FormControl>
                        </Grid>
                    )}

                    {showInputOrigem && (<>
                        <Grid item xs={12} md={2} paddingRight={1}>
                            <FormControl fullWidth>
                                <InputLabel id="id_origem">Origem</InputLabel>
                                <Select
                                    labelId="label_Origem"
                                    id="id_origem"
                                    value={origem}
                                    size="small"
                                    label="Origem"
                                    onChange={handleChangeOrigem}
                                >
                                    <MenuItem value={'Todos'}>Todos</MenuItem>
                                    <MenuItem value={'web_app'}>Web</MenuItem>
                                    <MenuItem value={'web_service'}>Integração</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                    )}

                    {showLimitPage && <Grid item xs={12} md={2} paddingRight={1} marginTop={2} >
                        <FormControl fullWidth>
                            <InputLabel id="page-size-label">
                                Limite por Página
                            </InputLabel>
                            <Select
                                labelId="page-size-label"
                                id="page-size"
                                value={pageSize.toString()}
                                size="small"
                                label="Registros por página"
                                onChange={(event: SelectChangeEvent) => {
                                    if (event.target?.value) {
                                        setPageSize(Number(event.target.value));
                                        onChangeLimitPage?.(Number(event.target.value));
                                    }
                                    else {
                                        setPageSize(5);
                                        onChangeLimitPage?.(5);
                                    }
                                }}
                                style={{
                                    minWidth: "100%",
                                }}
                            >
                                <MenuItem value={5}>{5}</MenuItem>
                                <MenuItem value={10}>{10}</MenuItem>
                                <MenuItem value={25}>{25}</MenuItem>
                                <MenuItem value={50}>{50}</MenuItem>
                                <MenuItem value={100}>{100}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>}

                    {showPeriodo && (
                        <>
                            <Grid item xs={12} md={2} paddingRight={1} marginTop={2}>
                                <TextField
                                    fullWidth
                                    label='Data Inicial'
                                    name="datainicial"
                                    size="small"
                                    type="date"
                                    value={dataIni}
                                    onChange={(e) => (
                                        setDataIni(moment(e.target.value).format("YYYY-MM-DD")),
                                        onChangeDataIni?.(e.target.value)
                                    )}
                                >
                                </TextField>
                            </Grid>

                            <Grid item xs={12} md={2} paddingRight={1} marginTop={2}>
                                <TextField
                                    fullWidth
                                    label='Data Final'
                                    name="datafinal"
                                    size="small"
                                    type="date"
                                    value={dataFim}
                                    onChange={
                                        (e) => (
                                            setDataFim(moment(e.target.value).format("YYYY-MM-DD")),
                                            onChangeDataFim?.(e.target.value)
                                        )}
                                >
                                </TextField>
                            </Grid>
                        </>
                    )}

                    {showInputBusca && (
                        <Grid item xs={12} md={1.5} sx={{ marginLeft: 0 }} marginTop={2}>
                            <Box display="flex">
                                {loading ? <CircularProgress /> :
                                    <Tooltip title="Clique para Pesquisar">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={onClick}
                                            disableRipple
                                            disableFocusRipple
                                            edge="end"
                                            sx={{
                                                color: 'white', backgroundColor: 'red', borderRadius: "4px",
                                                width: '95%',
                                            }}
                                        >
                                            <Search />
                                            <Typography marginLeft={1}>
                                                Filtrar
                                            </Typography>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Box>
                        </Grid>
                    )}

                    {showExport && (
                        <Grid item xs={12} md={2} sx={{ marginLeft: 0 }} marginTop={2}>
                            <Box display="flex">
                                {loadingExport ? <CircularProgress /> :
                                    <Tooltip title="Clique para Exportar">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={onClickExport}
                                            disableRipple
                                            disableFocusRipple
                                            edge="end"
                                            sx={{
                                                color: 'white', backgroundColor: 'red', borderRadius: "4px",
                                            }}
                                        >
                                            <UploadFile />
                                            <Typography marginLeft={1}>
                                                Exportar Csv
                                            </Typography>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Box>
                        </Grid>
                    )}

                    {showReport && (
                        <Grid item xs={12} md={2} sx={{ marginLeft: 0 }} marginTop={2}>
                            <Box display="flex">
                                <Tooltip title="Clique para Gerar Relatório">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={onclickReport}
                                        disableRipple
                                        disableFocusRipple
                                        edge="end"
                                        //variant="contained"
                                        color="primary"
                                    >
                                        <UploadFile />
                                        <Typography marginLeft={1}>
                                            Gerar Relatório
                                        </Typography>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>
                    )}

                </Grid>
            </Grid>
        </Box>
    );
};