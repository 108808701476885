import { Environment } from "../../../environment";
import { Api } from "../axios-config";
import { IPaymentSetup } from "./Types";

export interface IInstallmentList {
	id: string;
	paymentMethod: string;
	type: string;
	status: string;
	number: string;
	proposalId: string;
	paymentLinkId: string;
	acquirerPaymentId: string;
	invoiceId: string;
	amount: string;
	dueDate: string;
	createdAt: string;
	updatedAt: string;
	proposal: {
		certificateNumber?: string;
		id: string;
		clientId: string;
		analysisId: string;
		status: string;
		name: string;
		motherName?: string;
		type_person?: string;
		document: string;
		birthDate?: string;
		mobilePhone?: string;
		createdAt?: string;
		updatedAt?: string;
		canceledAt?: string;
		termBegin?: string;
		expiresAt?: string;
		expiredAt?: string;
		installmentsNumber?: string;
		createdFrom?: string;
		employmentRelationShip?: string;
		maritalStatus?: string;
		wifeName?: string;
		email?: string;
		phone?: string;
		rentalCost?: string;
		sendSerasa?: string;
		propertyType?: string;
		addressZipcode?: string;
		addressStreet?: string;
		addressResidenceType?: string;
		addressNumber?: string;
		addressComplement?: string;
		addressDistrict?: string;
		addressCity?: string;
		addressState?: string;
		addressIbge?: string;
		serasaId?: string;
		serasaResponse?: string;
		serasaAnalysisStatus?: string;
		paymentMethod?: string;
		rentMonthsCount?: string;
		residentialFee?: string;
		commercialFee?: string;
		rentAmount?: string;
		condominiumAmount?: string;
		electricEnergyAmount?: string;
		waterAmount?: string;
		gasAmount?: string;
		iptuAmount?: string;
		// analysisPlan?: string,
		assuranceMonthAmount?: string;
		lmi?: string;
		lmiAmount?: string;
		lmiBalance?: string;
		userId?: string;
		relation?: string;
		planId?: string;
		peopleId?: string;
		reasonId?: string;
		lessorName?: string;
		lessorCpf?: string;
		propertyId?: string;
		lessorTypePerson?: string;
		lessorBirthDate?: string;
		lessorEmail?: string;
		lessorPhone?: string;
		lessorMobilePhone?: string;
	};
}

type TInstallmentCount = {
	data: IInstallmentList[];
	totalCount: number;
};

interface IIntallmentUpdateTransfer {
	id: string;
	clientId: string;
}
export interface IInstallmentInvoiceList {
	count_amount: string; // "3",
	name: string; // "Parra Imobiliaria Ltda",
	document: string; // "10340638000125",
	sum_amount: string; // "371.25",
	//due_date: string; // "2023-01-13T03:00:00.000Z"
	day_due_date: number;
	data_generate: string;
	id: string;
	status?: string;
	clientId: string;
}
export interface INfse {
	Installment_id: string; //": "0010dbad-0533-4f89-afb8-d32c9bd374f4",
	Installment_payment_method: string; //": "billet",
	Installment_type: string; //": "monthly_payment",
	Installment_status: string; //": "billed",
	Installment_number: string; //": 4,
	Installment_proposalId: string; //": "fd26f74f-c1e5-4903-8fe8-e6ca556442ae",
	Installment_clientId: string; //": "e4bf2d21-c9d9-480b-b118-063d639d0cfb",
	Installment_paymentLinkId: string; //": null,
	Installment_acquirerPaymentId: string; //": null,
	Installment_invoiceId: string; //": "41b902f9-aa49-4ed1-a3b7-dd1d291106e2",
	Installment_type_amount: string; //": "parcel",
	Installment_amount: string; //": "44.17",
	Installment_due_date: string; //": "2023-03-15T03:00:00.000Z",
	Installment_obs: string; //": "Gerada automaticamente na migracao",
	Installment_nfseId: string; //": null,
	Installment_created_at: string; //": "2022-09-29T16:22:06.000Z",
	Installment_updated_at: string; //": "2023-03-08T20:28:57.000Z",
	invoice_status: string; //": "paid",
	tenant_name: string; //": "Marcilio Gomes Franco",
	tenant_document: string; //": "10536350604",
	client_document: string; //": "33670988000155",
	client_email: string; //": "financeiro@jkaimoveis.com.br",
	client_name: string; //": "Araújo E Freire Negócios Imobiliários - Jean Karllo Imóveis",
	client_status?: string; //": "active",
	nfse_id: string; //": null,
	nfse_status: string; //": null,
	nfse_gateway: string; //": null,
	nfse_value: string; //": null,
	nfse_url: string; //": null,
	nfse_clientId: string; //": null,
	nfse_userId: string; //": null,
	nfse_installmentId: string; //": null,
	nfse_proposalId: string; //": null,
	nfse_invoiceId: string; //": null,
	nfse_nfseId: string; //": null,
	nfse_obs: string; //": null,
	nfse_nfse_emissao: string; //": null,
	nfse_nfse_urL_pdf: string; //": null,
	nfse_nfse_url_xml: string; //": null,
	nfse_nfse_codigo_verificacao: string; //": null,
	nfse_nfse_prestador: string; //": null,
	nfse_nfse_tomador: string; //": null,
	nfse_nfse_lote: string; //": null,
	nfse_nfse_numero: string; //": null,
	nfse_nfse_serie: string; //": null,
	nfse_nfse_autorizacao: string; //": null,
	nfse_nfse_situacao: string; //": null,
	nfse_nfse_tipo_autorizacao: string; //": null,
	nfse_nfse_mensagem: string; //": null,
	nfse_nfse_protocol: string; //": null,
	nfse_nfse_servico_codigo: string; //": null,
	nfse_nfse_servico_discriminacao: string; //": null,
	nfse_created_at: string; //": null,
	nfse_updated_at: string; //": null,
	nfse_canceled_at: string; //": null,
	nfse_canceled_reason: string; //": null,
	nfse_canceled_user: string; //": null,
	nfse_canceled_type: string; //": null,
	email: string; //": "administrativo@jkaimoveis.com.br",
	phone: string; //": "3197182106",
	address_zipcode: string; //": "35700226",
	address_street: string; //": "Rua João Mendes",
	address_number: string; //": "810",
	address_complement: string; //": "Sem Complemento",
	address_district: string; //": "Piedade",
	address_city: string; //": "Sete Lagoas",
	address_state: string; //": "MG",
	address_ibge: string; //": null,
	invoice_due_date: string; //": "2023-03-15T03:00:00.000Z",
	invoice_payment_date: string; //": "2023-03-14T03:00:00.000Z"
	document_guid?: string;
}

export interface ICancelInvoice {
	id: string;
	canceledType: string;
	canceledReason: string;
}

export interface ICancelSetup {
	id: string;
	reason: string;
	note: string;
}
export interface ISendMailSetup {
	id: string;
	email: string;
}

export interface IPaymentInvoice {
	canceledType: string;
	canceledReason: string;
	paymentDate: string;
	creditAmount: string;
}

export interface ISetup {
	id: string;
	client_id: string;
	client_name: string;
	client_document: string;
	tenant_name: string;
	tenant_document: string;
	analysis_id: string;
	nfse_id: string;
	transaction_id: string;
	invoice_id: string;
	type: string;
	type_amount: string;
	status: string;
	number: string;
	raw_amount: string;
	administrative_amount: string;
	credited_amount: string;
	gateway_amount: string;
	note: string;
	payment_method: string;
	dueDate: string;
	updated_at: string;
	created_at: string;
	reason?: string;
	canceled_at?: string;
	payment_date?: string;
	credit_date?: string;
	credit_amount?: string;
	tenant_email?: string;
}
type TSetupCount = {
	data: ISetup[];
	totalCount: number;
};

type TInstallmentInvoiceCount = {
	data: IInstallmentInvoiceList[];
	totalCount: number;
};

type TNfseCount = {
	data: INfse[];
	totalCount: number;
};

export interface IGenerateInvoice {
	clientId: string;
	dueDate: string;
	reference: string;
	value: string;
	installmentsNumber: number;
	id: string;
	gateway?: "EMPTY";
	status?: "pending";
}

export interface IInstallmentDocument {
	Installment_id: string; //"00A027BC-6F65-4D4D-9C26-68B6B8F34027",
	Installment_payment_method: string; // "billet",
	Installment_type: string; // "monthly_payment",
	Installment_status: string; // "pending",
	Installment_number: string; // 10,
	Installment_proposalId: string; // "d0ab97fc-a6e2-4ca4-8460-45c2f222e35e",
	Installment_clientId: string; // "07d8083d-bed0-4102-917d-67f853e2119f",
	Installment_paymentLinkId: string; // null,
	Installment_acquirerPaymentId: string; // null,
	Installment_invoiceId: string; // null,
	Installment_type_amount: string; // "parcel",
	Installment_amount: string; // "110.00",
	Installment_due_date: string; // "2022-12-13T03:00:00.000Z",
	Installment_obs: string; // "Gerada automaticamente na migracao",
	Installment_created_at: string; // "2022-09-02T08:46:28.000Z",
	Installment_updated_at: string; // "2023-01-04T14:53:39.000Z",
	name: string; // "Rudinei Paz de Moraes",
	document: string; // "72233621034",
	residentialFee: string; // 10,
	commercialFee: string; // 9.5,
	fee: string; // "0.00",
	rentAmount: string; // "1100.00",
	termBegin: string; // "2022-01-27T03:00:00.000Z"
}

type TInstalmentDocumentCount = {
	data: IInstallmentDocument[];
	totalCount: number;
};

export interface IProposalCount {
	Status: string;
	quantidade: Number;
	aluguel: Number;
	lmi_total: Number;
	lmi_saldo: Number;
	valor_parcela: Number;
}

export interface IInstallmentProposal {
	id: string;
	status: string;
	number: number;
	paymentMethod: string;
	type: string;
	proposalId: string;
	paymentLinkId: string;
	acquirerPaymentId: string;
	invoiceId: string;
	amount: string;
	dueDate: string;
	name: string;
}

export interface IInstallmentFinancialSummaryAnalytical {
	idClient: string;
	clientName: string;
	qtdInstallment: number;
	totalValue: number;
}

export type InstallmentFinancialSummaryCountData<T> = {
	result: T;
	resultCount: number;
};

type TInstallmentProposalCount = {
	data: IInstallmentProposal[];
	totalCount: number;
};

export interface IListInvoice {
	id: string; // "ef2c8cd9-66a4-42bf-8048-505c570e2785",
	platformId?: string; // null,
	gateway?: string; // "EMPTY",
	url?: string; // "",
	dueDate: string; // "2023-01-12T03:00:00.000Z",
	status: string; // "pending",
	summaryUrl?: string; // "",
	clientId: string; // "133f241d-9c5c-4e5f-afdf-834d6c927b47",
	userId?: string; // null,
	value: string; // "144.00",
	discount: string; // "0.00",
	addition: string; // "0.00",
	paymentDate?: string; // null,
	creditDate?: string; // null,
	amountPaid?: string; // "0.00",
	creditAmount?: string; // null,
	platformTaxes?: string; // "0.00",
	paymentLinkId?: string; // null,
	acquirerPaymentId?: string; // null,
	installments_number: string; // 0,
	createdAt: string; // "2023-01-11T18:00:55.000Z",
	client: {
		id?: string; // "133f241d-9c5c-4e5f-afdf-834d6c927b47",
		document: string; // "42622604000110",
		type_person?: string; // "legal",
		email: string; // "contato@japbastos.com",
		name: string; // "JAPBASTOS",
		trade_name?: string; // "JAPBASTOS",
		status?: string; // "active",
	};
}

type TListInvoiceCount = {
	data: IListInvoice[];
	totalCount: number;
};

export interface IListFinancial {
	id?: string;
	document: string;
	type_person?: string;
	email: string;
	name: string;
	trade_name?: string;
	status: string;
	openSum?: string;
	paidSum?: string;
	pendingSum?: string;
	overdueSum?: string;
	overdueCount?: string;
	openCount?: string;
	paidCount?: string;
	pendingCount?: string;
	invoice_due_date?: string;
	invoices: [
		{
			id: string;
			platformId: string;
			sicoobSeuNumero: string;
			gateway: string;
			url: string;
			dueDate: string;
			status: string;
			summaryUrl: string;
			clientId: string;
			userId: string;
			value: string;
			discount: string;
			addition: string;
			paymentDate: string;
			creditDate: string;
			amountPaid: string;
			creditAmount: string;
			platformTaxes: string;
			paymentLinkId: string;
			acquirerPaymentId: string;
			installments_number: string;
			createdAt: string;
			groupedNumber: string;
			updatedAt: string;
			canceledAt: string;
			hook: string;
			canceledType: string;
			canceledReason: string;
			canceledUser: string;
			sicoobLinhaDigitavel: string;
			sicoobCodigoBarras: string;
			sicoobPdfBoleto: string;
			sicoobContrato: string;
			sicoobContaCorrente: string;
		}
	];
}

type TListFinancialCount = {
	data: IListFinancial[];
	totalCount: number;
};

export interface ISummary {
	Installment_id: string; // "54e3a509-fd86-490f-a7a8-4666fa642f13",
	Installment_payment_method: string; // "billet",
	Installment_type: string; // "monthly_payment",
	Installment_status: string; // "pending",
	Installment_number: string; // 1,
	Installment_proposalId: string; // "d10f9240-ff96-45c9-aad3-096ed2a781da",
	Installment_clientId: string; // "133f241d-9c5c-4e5f-afdf-834d6c927b47",
	Installment_paymentLinkId: string; // "",
	Installment_acquirerPaymentId: string; // "",
	Installment_invoiceId: string; // "74c52462-799b-4d0f-b3ca-3ff9cd93ba58",
	Installment_type_amount: string; // "parcel",
	Installment_amount: string; // "144.00",
	Installment_due_date: string; // "2023-01-12T03:00:00.000Z",
	Installment_obs: string; // "Gerada automaticamente na contratação da garantia",
	Installment_created_at: string; // "2022-12-30T15:19:25.000Z",
	Installment_updated_at: string; // "2023-01-17T22:36:24.000Z",
	name: string; // "GARANTTI 3",
	document: string; // "34079095007",
	residentialFee: string; // 12,
	commercialFee: string; // 12,
	fee: string; // "0.00",
	rentAmount: string; // "1200.00",
	termBegin: string; // "2022-12-30T03:00:00.000Z"
	client_name: string;
	client_document: string;
	addressZipcode: string; // "32183 - 025",
	addressStreet: string; // "Rua Beta Centauri",
	addressNumber: string; // "280",
	addressComplement: string; // "",
	addressCity: string; // "Contagem",
	addressState: string; // "MG",
	addressDistrict: string;
}

type TSummaryCount = {
	data: ISummary[];
	totalCount: string;
};

const getAll = async (page = 1, busca = "", tipo = "", origem = "", status = "", dataini = "", datafim = ""): Promise<TInstallmentCount | Error> => {
	try {
		const urlRelativa = `/proposal?_page=${page}&_limit=${Environment.LIMITE_LINHAS}&nome_like=${busca}&tipo=${tipo}&origem=${origem}&status=${tipo}&dataini=${dataini}&datafim=${datafim}`;
		const { data, headers } = await Api().get(urlRelativa);
		if (data.results) {
			return {
				data: data.results,
				totalCount: data.total,
			};
		}
		return new Error("Erro ao listar registros.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao listar registros.");
	}
};

const getInvoice = async (page2 = 1, busca2 = "", status2 = "", dataini2 = "", datafim2 = ""): Promise<TListInvoiceCount | Error> => {
	try {
		const urlRelativa = `/invoice?_page=${page2}&_limit=${Environment.LIMITE_LINHAS}&nome_like=${busca2}&status=${status2}&dataini=${dataini2}&datafim=${datafim2}`;
		const { data, headers } = await Api().get(urlRelativa);
		if (data.results) {
			return {
				data: data.results,
				totalCount: data.total,
			};
		}
		return new Error("Erro ao listar registros.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao listar registros.");
	}
};

const getAllInstallmentInvoice = async (page = 1, busca = "", status = "", dataini = "", datafim = ""): Promise<TInstallmentInvoiceCount | Error> => {
	try {
		//const urlRelativa = `/installment/sum?_page=${page}&_limit=${Environment.LIMITE_LINHAS}&nome_like=${busca}&status=${status}&dataini=${dataini}&datafim=${datafim}`;
		const urlRelativa = `/installment/sum?_page=${page}&_limit=5&nome_like=${busca}&status=${status}&dataini=${dataini}&datafim=${datafim}`;

		const { data, headers } = await Api().get(urlRelativa);

		if (data.results) {
			return {
				data: data.results,
				totalCount: data.total,
			};
		}
		return new Error("Erro ao listar registros.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao listar registros.");
	}
};

const getNfse = async (
	page = 1,
	busca = "",
	status = "",
	dataini = "",
	datafim = "",
	clientId = "",
	pageSize = Environment.LIMITE_LINHAS,
	filter = "Todos"
): Promise<TNfseCount | Error> => {
	try {
		const urlRelativa = `/installment/proposal-nfse?_page=${page}&_limit=${pageSize}&nome_like=${busca}&status=${status}&dataini=${dataini}&datafim=${datafim}&clientId=${clientId}&filter=${filter}`;

		const { data } = await Api().get(urlRelativa);

		if (data.results) {
			return {
				data: data.results,
				totalCount: data.total,
			};
		}

		return new Promise((_, reject) => reject(new Error("Erro ao consultar os registros.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getSetupList = async (
	search = "",
	page = 1,
	pageSize = "1000",
	status = "",
	dataini = "2024-01-01",
	datafim = "2024-07-19"
): Promise<TSetupCount | Error> => {
	try {
		const urlRelativa = `/setup/find?search=${search}&_page=${page}&_order=DESC&_limit=${pageSize}&status=${status}&dateStart=${dataini}&dateEnd=${datafim}&paymentMethod=credit_card`;

		const { data } = await Api().get(urlRelativa);
		if (data.results) {
			return {
				data: data.results,
				totalCount: data.total,
			};
		}

		return new Promise((_, reject) => reject(new Error("Erro ao consultar os registros.")));
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao listar registros.");
	}
};

const getAllInstallmentProposal = async (id: string): Promise<TInstallmentProposalCount | Error> => {
	try {
		const urlRelativa = `/installment/proposal/${id}`;
		const { data, headers } = await Api().get(urlRelativa);

		if (data.results) {
			return {
				data: data.results,
				totalCount: data.total,
			};
		}
		return new Error("Erro ao listar registros.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao listar registros.");
	}
};

const getInstallmentbyDocument = async (
	page: number = 1,
	limitPage: number = 5,
	id: string,
	dataini = "",
	datafim = ""
): Promise<TInstalmentDocumentCount | Error> => {
	try {
		const urlRelativa = `/installment/filter/client?_page=${page}&_limit=${limitPage}&id=${id}&status=pending&dataini=${dataini}&datafim=${datafim}`;
		const { data, headers } = await Api().get(urlRelativa);

		if (data.results) {
			return {
				data: data.results,
				totalCount: data.total,
			};
		}
		return new Error("Erro ao listar registros.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao listar registros.");
	}
};

const getInvoicesPDF = async (id: string) => {
	//: Promise<TInstalmentDocumentCount | Error> => {
	try {
		const { data } = await Api().get(`/installment/pdf/${id}`, {
			responseType: "blob",
		});
		if (data) {
			const file = new Blob([data], { type: "application/pdf" });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		}
		//return new Error('Erro ao consultar o registro.');
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getInvoicesBoletoPDF = async (id: string) => {
	//: Promise<TInstalmentDocumentCount | Error> => {
	try {
		const { data } = await Api().get(`/invoice/sicoob/boleto/${id}`, {
			responseType: "blob",
		});
		if (data) {
			const file = new Blob([data], { type: "application/pdf" });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		}
		//return new Error('Erro ao consultar o registro.');
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getInstallmentPDF = async (id: string, dataini = "", datafim = "") => {
	//: Promise<TInstalmentDocumentCount | Error> => {
	try {
		const { data } = await Api().get(`/installment/extract/pdf?id=${id}&dataini=${dataini}&datafim=${datafim}`, { responseType: "blob" });
		if (data) {
			const file = new Blob([data], { type: "application/pdf" });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		}
		//return new Error('Erro ao consultar o registro.');
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getReportUpdatePDF = async (clientId: string, dataini = "", datafim = "") => {
	//: Promise<TInstalmentDocumentCount | Error> => {
	try {
		const { data } = await Api().get(`/proposal/report/update-proposal?clientId=${clientId}&dataIni=${dataini}&dataFim=${datafim}`, {
			responseType: "blob",
		});
		if (data) {
			const file = new Blob([data], { type: "application/pdf" });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		}
		//return new Error('Erro ao consultar o registro.');
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getNfsePDF = async (id: string) => {
	try {
		const { data } = await Api().get(`/nfse/pdf/${id}`, {
			responseType: "blob",
		});

		if (!data) return new Promise((_, reject) => reject(new Error("Erro ao consultar os registros.")));

		const file = new Blob([data], { type: "application/pdf" });
		const fileURL = URL.createObjectURL(file);
		window.open(fileURL);
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getNfsePDFGridWebti = async (id: string, urlNfse: string) => {
	try {
		const { data } = await Api().post(
			`/nfse/pdf/gridwebti/${id}`,
			{ urlNfse },
			{
				responseType: "blob",
			}
		);

		if (!data) return new Promise((_, reject) => reject(new Error("Erro ao consultar os registros.")));

		const file = new Blob([data], { type: "application/pdf" });
		const fileURL = URL.createObjectURL(file);
		window.open(fileURL);
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getById = async (id: string): Promise<IInstallmentList | Error> => {
	try {
		const { data } = await Api().get(`/proposal/${id}`);
		if (data) {
			return data;
		}
		return new Error("Erro ao consultar o registro.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getInvoiceById = async (id: string): Promise<IListInvoice | Error> => {
	try {
		const { data } = await Api().get(`/invoice/${id}`);
		if (data) {
			return data;
		}
		return new Error("Erro ao consultar o registro.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getSetupById = async (id: string): Promise<ISetup | Error> => {
	try {
		const { data } = await Api().get(`/setup/details/${id}`);
		if (data) {
			return data;
		}
		return new Error("Erro ao consultar o registro.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getSummary = async (id: string): Promise<TSummaryCount | Error> => {
	try {
		const { data } = await Api().get(`/installment/summary/${id}`);
		if (data.results) {
			return {
				data: data.results,
				totalCount: data.total,
			};
		}
		return new Error("Erro ao listar registros.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao listar registros.");
	}
};

const getReportInvoice = async (type = "", order = "", dataini = "", datafim = ""): Promise<string | Error> => {
	try {
		const { data } = await Api().get(`/invoice/report/bankstatement?model=${type}&order=${order}&dataini=${dataini}&datafim=${datafim}`, {
			responseType: "blob",
		});
		if (data) {
			console.log(data);
			if (data.size > 0) {
				const file = new Blob([data], { type: "application/pdf" });
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			} else {
				alert("Relatório Vazio. Sem Informação");
			}
		}

		return "";
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const generateInvoice = async (dados: Omit<IGenerateInvoice, "id">, dataini = "", datafim = ""): Promise<string | Error> => {
	try {
		const { data } = await Api().post<IGenerateInvoice>(`/invoice?dataini=${dataini}&datafim=${datafim}`, dados);
		///invoice?&dataini=2022-01-01&datafim=2023-01-31
		if (data) {
			return data.id;
		}
		return new Error("Erro ao criar o registro.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao criar o registro.");
	}
};

const generateNfse = async (id: string): Promise<string | Error> => {
	try {
		const { data } = await Api().get(`/installment/Nfse/${id}`);

		return data?.id;
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const generateBillet = async (id: string, dueDate: string): Promise<string | Error> => {
	try {
		const { data } = await Api().post(`/invoice/create-billet`, {
			id,
			dueDate,
		});

		if (data) {
			return data.id;
		}
		return new Error("Erro ao criar o registro " + id);
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao criar o registro.");
	}
};

const generateGroupedBillet = async (clientId: string, dueDate: string, invoiceIds: string[]): Promise<string | Error> => {
	//console.log('834....', clientId, dueDate, invoiceIds);
	try {
		const { data } = await Api().post(`/invoice/generate-invoice`, {
			clientId,
			dueDate,
			invoiceIds,
		});

		if (data) {
			return data.id;
		}
		return new Error("Erro ao criar o registro.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao criar o registro.");
	}
};

const SendEmail = async (id: string): Promise<string | Error> => {
	try {
		//console.log('/invoice/sendmail/'+id)
		const { data } = await Api().get(`/invoice/sendmail/${id}`);
		if (data) {
			return data.id;
		}
		return new Error("Erro ao Reenviar email.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao reenviar email.");
	}
};

const create = async (dados: Omit<IInstallmentList, "id">): Promise<string | Error> => {
	try {
		const { data } = await Api().post<IInstallmentList>("/garantias", dados);
		if (data) {
			return data.id;
		}
		return new Error("Erro ao criar o registro.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao criar o registro.");
	}
};

const updateById = async (id: string, dados: IInstallmentList): Promise<void | Error> => {
	try {
		await Api().put(`/garantias/${id}`, dados);
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao atualizar o registro.");
	}
};

const updateInstallmentClient = async (id: string, clientId: string): Promise<void | Error> => {
	try {
		await Api().patch(`/installment/${id}`, { clientId });
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao atualizar o registro.");
	}
};

const CancelInvoice = async (id: string, dados: ICancelInvoice): Promise<void | Error> => {
	try {
		await Api().post(`/invoice/cancel-billet/${id}`, dados);
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao cancelar o registro.");
	}
};

const CancelSetup = async (
	id: string,

	dados: ICancelSetup
): Promise<void | Error> => {
	try {
		await Api().patch(`/setup/cancel/${id}`, dados);
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao cancelar o registro.");
	}
};

const PaymentInvoice = async (id: string, dados: IPaymentInvoice): Promise<void | Error> => {
	try {
		await Api().post(`/invoice/manual-payment/${id}`, dados);
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao cancelar o registro.");
	}
};

const PaymentSetup = async (id: string, dados: IPaymentSetup): Promise<void | Error> => {
	try {
		await Api().patch(`/setup/payment/${id}`, dados);
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao cancelar o registro.");
	}
};

const sendMailSetup = async (id: string, dados: ISendMailSetup): Promise<void | Error> => {
	try {
		await Api().post(`/setup/sendmail/${id}`, dados);
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao cancelar o registro.");
	}
};

const deleteById = async (id: string): Promise<void | Error> => {
	try {
		await Api().delete(`/garantias/${id}`);
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao apagar o registro.");
	}
};

const cancelNfse = async (installmentId: string, nfseId: string, reason: string): Promise<void | Error> => {
	try {
		const nfse = await Api().post(`/nfse/cancel/${nfseId}`, { reason });
		if (nfse) {
			await Api().delete(`/installment/cancelnfse/${installmentId}`);
		}
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const cancelNfseGridWebti = async (installmentId: string, nfseId: string, reason: string): Promise<void | Error> => {
	try {
		const nfse = await Api().post(`/nfse/cancel/${nfseId}/new`, {
			reason,
		});
		if (nfse) {
			await Api().delete(`/installment/cancelnfse/${installmentId}`);
		}
	} catch (error: any) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const cancelarNfse = async (id: string, reason: string): Promise<void | Error> => {
	try {
		const nfse = await Api().post(`/nfse/plugnotas/cancelar/${id}`, {
			reason,
		});
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const cancelarNfseGridWebti = async (id: string, reason: string, documentGuid: string): Promise<void | Error> => {
	try {
		await Api().post(`/nfse/gridwebti/cancelar/${id}/new`, {
			reason,
			documentGuid,
		});
	} catch (error: any) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const updateNfse = async (id: string, nfseId: string): Promise<void | Error> => {
	try {
		const nfse = await Api().patch(`nfse/plugnotas/conciliation`, {
			id,
			nfseId,
		});
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao Atualizar  registro.");
	}
};

const updateNfseStatusGridWebti = async ({ nfse_id, document_guid }: INfse): Promise<INfse | Error> => {
	try {
		const { data: nfse } = await Api().patch(`nfse/gridwebti/status`, {
			idIntegracao: nfse_id,
			document_guid,
		});

		if (nfse) return nfse;

		return new Promise((_, reject) => reject(new Error("Não foi possível atualizar status da nfse.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const updateStatusCancelNfseGridWebti = async (id: string, number_protocol: string): Promise<any> => {
	try {
		const { data } = await Api().patch(`nfse/gridwebti/cancel/consult/${id}`, {
			number_protocol,
		});

		if (data) return data;

		return new Promise((_, reject) => reject(new Error("Não foi possível verificar o status da nfse cancelada.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getInstallmentFinancialSummary = async (
	clientId: string = "",
	dataIni: string = "",
	dataFim: string = "",
	isOverview: boolean = false
): Promise<any | Error> => {
	try {
		const { data } = await Api().get(
			`/installment/summary/financial/installment?clientId=${clientId}&dataIni=${dataIni}&dataFim=${dataFim}&isOverview=${isOverview}`
		);

		if (data) return data;

		return new Promise((_, reject) => reject(new Error("Erro ao buscar o registro.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getInvoiceForClient = async (
	page = 1,
	limitClient = "5",
	busca: string = "",
	statusInvoice = "",
	limitInvoice = "6",
	grouped = "Todos"
): Promise<any | Error> => {
	try {
		const { data } = await Api().get(
			//      `/invoice/boletos-por-cliente?_page=${page}&_limit=5&nome_like=${busca}`
			`/invoice/boletos-por-cliente?_page=${page}&_limit=${limitClient}&nome_like=${busca}&status=Todos&_invoice_limit=${limitInvoice}&_invoice_status=${statusInvoice}&_invoice_dataIni=&_invoice_dataFim=&_union=${grouped}`
		);

		if (data) return data;

		return new Promise((_, reject) => reject(new Error("Erro ao buscar o registro.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getInstallmentFinancialSummaryAnalytical = async (
	clientId: string = "",
	dataIni: string = "",
	dataFim: string = "",
	statusInstallment: string = "proposal",
	page: string = "1",
	limit: string = "10"
): Promise<InstallmentFinancialSummaryCountData<IInstallmentFinancialSummaryAnalytical[]> | Error> => {
	try {
		const { data } = await Api().get<InstallmentFinancialSummaryCountData<IInstallmentFinancialSummaryAnalytical[]>>(
			`/installment/summary/financial/analytical/installment?clientId=${clientId}&dataIni=${dataIni}&dataFim=${dataFim}&statusInstallment=${statusInstallment}&page=${page}&limit=${limit}`
		);

		if (data) {
			const { result, resultCount } = data;

			return {
				result,
				resultCount,
			};
		}

		return new Promise((_, reject) => reject(new Error("Erro ao buscar o registro.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getInstallmentsByClient = async (
	idClient: string,
	dataIni: string = "",
	dataFim: string = "",
	statusInstallment: string = "proposal"
): Promise<IInstallmentDocument[] | Error> => {
	try {
		const { data } = await Api().get(`/installment/client/${idClient}/status/${statusInstallment}/installments?dataIni=${dataIni}&dataFim=${dataFim}`);

		if (!data) return new Promise((_, reject) => reject(new Error("Erro ao consultar os registros.")));

		return data;
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getConciliation = async (dataIni: string = "", dataFim: string = ""): Promise<any | Error> => {
	try {
		const { data } = await Api().get(`/invoice/sicoob/conciliation-month?dataini=${dataIni}&datafim=${dataFim}`);

		if (data) return data;

		return new Promise((_, reject) => reject(new Error("Erro ao buscar o registro.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const cancelBlockClient = async (id: string = ""): Promise<any> => {
	try {
		const { data } = await Api().post(`/invoice/cancel/block/client/${id}`);

		if (data) return data;

		return new Promise((_, reject) => reject(new Error("Erro ao realizar bloqueio/cancelmento do cliente.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getReportProposalPDF = async (clientId: string, managerId: string, origem: string, dataini = "", datafim = "") => {
	//_page=1&_limit=50&dataini=2021-01-01&datafim=2024-01-18&clientId=&managerId=1ad3b82d-8d4f-410c-9caf-676133944610&origem=Todos
	//: Promise<TInstalmentDocumentCount | Error> => {
	try {
		const { data } = await Api().get(
			`/proposal/report/proposal?_page=1&_limit=50&dataini=${dataini}&datafim=${datafim}&clientId=${clientId}&managerId=${managerId}&origem=${origem}`,
			{ responseType: "blob" }
		);
		if (data) {
			const file = new Blob([data], { type: "application/pdf" });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		}
		//return new Error('Erro ao consultar o registro.');
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getReportProposal = async (page: number, rowPages: number, clientId: string, managerId: string, origem: string, dataini = "", datafim = "") => {
	try {
		console.log("1241..", clientId, managerId, origem, dataini, datafim);
		const { data, headers } = await Api().get(
			`/proposal/reports?_page=${page}&_limit=${rowPages}&dataini=${dataini}&datafim=${datafim}&clientId=${clientId}&managerId=${managerId}&origem=${origem}`
		);
		if (data.results) {
			console.log(data.results);
			return {
				data: data.results,
				totalCount: data.total,
			};
		}
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getExportCsv = async (
	page: number,
	rowPages: number,
	clientId: string,
	managerId: string,
	origem: string,
	dataini: string = "",
	datafim: string = ""
): Promise<any> => {
	try {
		const { data } = await Api().get(
			`/proposal/exports?_page=${page}&_limit=${rowPages}&dataini=${dataini}&datafim=${datafim}&clientId=${clientId}&managerId=${managerId}&origem=${origem}`,
			{ responseType: "blob" }
		);
		return { data };
	} catch (error: any) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getExportInvoiceCsv = async (nome_like = "", _status = "") => {
	try {
		const { data } = await Api().get(`/invoice/exports?nome_like=${nome_like}&_status=${_status}`, { responseType: "blob" });
		return { data };
	} catch (error) {
		throw error;
	}
};

const getFilterReportAnalysisPDF = async (
	page = 1,
	pageSize = 10,
	clientId: string,
	dataini = "",
	datafim = "",
	managerId = "",
	origem = "Todos",
	isFilter: any
): Promise<any> => {
	try {
		let url = `/analysis/report/filter?_page=${page}&_limit=${pageSize}&clientId=${clientId}&dataini=${dataini}&datafim=${datafim}&managerId=${managerId}&origem=${origem}&isFilter=${isFilter}`;

		if (!isFilter || isFilter == "false")
			url = `/analysis/report/filter?clientId=${clientId}&dataini=${dataini}&datafim=${datafim}&managerId=${managerId}&origem=${origem}&isFilter=${isFilter}`;

		const { data } = await Api().get(url, {
			...(!isFilter || isFilter == "false" ? { responseType: "blob" } : {}),
		});

		if (isFilter == "true" || isFilter == true) return data;

		if (!data) return new Promise((_, reject) => reject(new Error("Erro ao consultar o registro.")));
		else if (data?.size == 0) return new Promise((resolve) => resolve("Nenhum registro encontrado referente a busca solicitada!"));

		const urlFile = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement("a");
		link.href = urlFile;
		link.setAttribute("download", "exported_data.csv");
		document.body.appendChild(link);
		link.click();
	} catch (error: any) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getReportLifecycleAnalysis = async (
	page = 1,
	pageSize = 10,
	clientId: string,
	dataini = "",
	datafim = "",
	managerId = "",
	origem = "Todos",
	isFilter: any,
	reportType = 2,
	status = "Todos",
	documentLocator = ""
): Promise<any> => {
	try {
		let url = `/lifecycle-history/report/analysis?_page=${page}&_limit=${pageSize}&clientId=${clientId}&dataini=${dataini}&datafim=${datafim}&managerId=${managerId}&origem=${origem}&reportType=${reportType}&status=${status}&document=${documentLocator}&isFilter=${isFilter}`;

		const { data } = await Api().get(url, {
			...(!isFilter || isFilter == "false" ? { responseType: "blob" } : {}),
		});

		if (isFilter == "true" || isFilter == true) return data;

		if (!data) return new Promise((_, reject) => reject(new Error("Erro ao consultar o registro.")));
		else if (data?.size == 0) return new Promise((resolve) => resolve("Nenhum registro encontrado referente a busca solicitada!"));

		const urlFile = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement("a");
		link.href = urlFile;
		link.setAttribute("download", "exported_data.csv");
		document.body.appendChild(link);
		link.click();
	} catch (error: any) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getReportLifecycleProposal = async (
	page = 1,
	pageSize = 10,
	clientId: string,
	dataini = "",
	datafim = "",
	managerId = "",
	origem = "Todos",
	isFilter: any,
	reportType = 2,
	status = "Todos",
	documentLocator = ""
): Promise<any> => {
	try {
		let url = `/lifecycle-history/report/proposal?_page=${page}&_limit=${pageSize}&clientId=${clientId}&dataini=${dataini}&datafim=${datafim}&managerId=${managerId}&origem=${origem}&reportType=${reportType}&status=${status}&document=${documentLocator}&isFilter=${isFilter}`;

		const { data } = await Api().get(url, {
			...(!isFilter || isFilter == "false" ? { responseType: "blob" } : {}),
		});

		if (isFilter == "true" || isFilter == true) return data;

		if (!data) return new Promise((_, reject) => reject(new Error("Erro ao consultar o registro.")));
		else if (data?.size == 0) return new Promise((resolve) => resolve("Nenhum registro encontrado referente a busca solicitada!"));

		const urlFile = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement("a");
		link.href = urlFile;
		link.setAttribute("download", "exported_data.csv");
		document.body.appendChild(link);
		link.click();
	} catch (error: any) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const executeNfseIssueRoutine = async (): Promise<string | Error> => {
	try {
		const { data } = await Api().get(`/installment/nfse/generate-manually`);

		return data;
	} catch (error) {
		return new Error((error as { message: string }).message || "Error ao executar rotina de emissão de Nfse.");
	}
};

export const FinancialService = {
	getAll,
	getInvoiceForClient,
	getAllInstallmentProposal,
	getById,
	getInvoiceById,
	create,
	updateById,
	deleteById,
	getAllInstallmentInvoice,
	getInstallmentbyDocument,
	generateInvoice,
	getInvoice,
	generateBillet,
	getSummary,
	SendEmail,
	CancelInvoice,
	PaymentInvoice,
	getInvoicesPDF,
	getInvoicesBoletoPDF,
	getInstallmentPDF,
	getReportInvoice,
	getReportUpdatePDF,
	getNfse,
	generateNfse,
	getNfsePDF,
	cancelNfse,
	updateNfse,
	cancelarNfse,
	getInstallmentFinancialSummary,
	getInstallmentFinancialSummaryAnalytical,
	getInstallmentsByClient,
	cancelNfseGridWebti,
	cancelarNfseGridWebti,
	updateNfseStatusGridWebti,
	getNfsePDFGridWebti,
	updateStatusCancelNfseGridWebti,
	getConciliation,
	cancelBlockClient,
	generateGroupedBillet,
	getFilterReportAnalysisPDF,
	getReportProposalPDF,
	getReportProposal,
	getExportCsv,
	getExportInvoiceCsv,
	getReportLifecycleAnalysis,
	getReportLifecycleProposal,
	getSetupList,
	CancelSetup,
	PaymentSetup,
	getSetupById,
	sendMailSetup,
	updateInstallmentClient,
	executeNfseIssueRoutine,
};
