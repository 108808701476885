import React, { useEffect, useState } from "react";
import { LayoutBaseDePagina } from "../../../../shared/layouts";
import { ModalComparation, ToastContent, ToolbarEditProposal, dataComparation } from "../../../../shared/components";
import { ProposalHeader } from "../ProposalHeader/ProposalHeader";
import { Box, Grid, IconButton, InputAdornment, LinearProgress, MenuItem, Paper, Typography } from "@mui/material";
import { IVFormErros, VForm, VTextField, useVForm } from "../../../../shared/forms";
import { VMaskTextField } from "../../../../shared/forms/VMaskTextField";
import { globalRemoveMask } from "../../../../shared/utils";
import * as yup from 'yup';
import { IProposal, ISimulateEditCancel, ISimulateEditLocator, ISimulateEditProposal, ISimulateEditProposalAddress, ISimulateEditProposalValues, ProposalEditService, TProposalSimulation } from "../../../../shared/services/api/proposal/ProposalEditService";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";
import { IProposalList, ProposalService } from "../../../../shared/services/api/proposal/ProposalService";
import { formatDateForInput } from "../../../../shared/utils/format-date";
import { useDebounce, useToast } from "../../../../shared/hooks";
import { clientService } from "../../../../shared/services/api/client/clientService";
import { differenceInMonths, startOfMonth } from "date-fns";
import { useProfile } from "../../../../context/ProfileContext";
import { useAuthContext } from "../../../../context";
import { checkByProfileGuard } from "../../../../shared/guards/ProfileGuard";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../../shared/enums/profiles";
import { Email } from "@mui/icons-material";
import { PeopleService } from "../../../../shared/services/api/people/PeopleService";
import { returnMessageError } from "../../../../shared/utils/returnMessageError";


const formValidationSchema: yup.SchemaOf<ISimulateEditProposal> = yup.object().shape({
    termBegin: yup.string().required('Campo Obrigatório'),
    expiresAt: yup.string().required('Campo Obrigatório'),
    contractBegin: yup.string().required('Campo Obrigatório'),
    contractEnd: yup.string().required('Campo Obrigatório'),
    rentMonthsCount: yup.string().notRequired(),
    rentMonthsCountContract: yup.string().notRequired(),
});


const formValidationSchemaValue: yup.SchemaOf<ISimulateEditProposalValues> = yup.object().shape({
    rentAmount: yup.string().required('Campo Obrigatório'),
    fee: yup.string().required('Campo Obrigatório'),
    assuranceMonthAmount: yup.string().required('Campo Obrigatório'),
    lmi: yup.string().required('Campo Obrigatório'),
    lmiAmount: yup.string().required('Campo Obrigatório'),
    planId: yup.string().notRequired()
});


const formValidationSchemaAddress: yup.SchemaOf<ISimulateEditProposalAddress> = yup.object().shape({
    addressCity: yup.string().required('Campo Obrigatório'),
    addressComplement: yup.string().notRequired(),
    addressDistrict: yup.string().required('Campo Obrigatório'),
    addressNumber: yup.string().required('Campo Obrigatório'),
    addressState: yup.string().required('Campo Obrigatório'),
    addressStreet: yup.string().required('Campo Obrigatório'),
    addressIBGE: yup.string().notRequired(),
});

const formValidationSchemaLocator: yup.SchemaOf<ISimulateEditLocator> = yup.object().shape({
    lessorName: yup.string().required('Campo Obrigatório'),
    lessorCpf: yup.string().required('Campo Obrigatório'),
    lessorBirthDate: yup.string().required('Campo Obrigatório'),
    lessorEmail: yup.string().notRequired().default(""),
    lessorPhone: yup.string().notRequired().default(""),
    lessorMobilePhone: yup.string().notRequired().default(""),
    lessorTypePerson: yup.string().notRequired().default(""),
    obs: yup.string().required('Campo Obrigatório'),
});

const formValidationSchemaCancel: yup.SchemaOf<ISimulateEditCancel> = yup.object().shape({
    canceledReason: yup.string().required('Campo Obrigatório'),
    canceledType: yup.string().required('Campo Obrigatório'),
    canceledAt: yup.string().required('Campo Obrigatório'),
});

interface IClientPlan {
    id: string;
    status: string;
    planoId: string;
    clientId: string;
    lmi: string;
    residentialFee: string;
    commercialFee: string;
    bonusAmount: string;
    productId: string;
    createdAt: string;
    updatedAt: string;
    flagAnalysis: string;
    flagRenovation: string;
    plan: {
        id: string;
        description: string;
    }
}
export const ProposalEditPresentation: React.FC = () => {
    const { id = 'novo' } = useParams<'id'>();
    const navigate = useNavigate();
    const { formRef } = useVForm();
    const [isLoading, setIsLoading] = useState(false);
    const [plano, setPlano] = useState('');
    const { permissionLevel, isSuperAdmin } = useProfile();

    const [contractBegin, setContractBegin] = useState("");
    const [contractEnd, setContractEnd] = useState("");
    const [termBegin, setTermBegin] = useState("");
    const [termEnd, setTermEnd] = useState("");

    const [VLmi, setVLmi] = useState("");
    const [VTaxa, setVTaxa] = useState("");
    const [VRentAmount, setVRentAmount] = useState("");
    const [VLmiAmount, setVLmiAmount] = useState("");
    //const [VInstallment, setVInstallment] = useState("");

    const [listPlans, setListPlans] = useState<IClientPlan[]>([]);
    const [openModalSimulation, setOpenModalSimulation] = useState(false)
    const [LoagindData, setLoadData] = useState<dataComparation[]>([]);
    let meusdados: dataComparation[] = [];
    const [rows, setRows] = useState<IProposalList>();
    const [validityChange, setValidityChange] = useState(false);
    const [valuesChange, setValuesChange] = useState(false);
    const [addressChange, setAddressChange] = useState(false);
    const [dataValidity, setDataValidity] = useState<ISimulateEditProposal>();
    const [dataValues, setDataValues] = useState<ISimulateEditProposalValues>();
    const [dataAddress, setDataAddess] = useState<ISimulateEditProposalAddress>();
    const [dataLocator, setDataLocator] = useState<ISimulateEditLocator>();
    const [dataCancel, setDataCancel] = useState<ISimulateEditCancel>();
    const [locatorChange, setLocatorChange] = useState(false);
    const [cancelChange, setCancelChange] = useState(false);
    const [sinisters, setSinisters] = useState<any[] | undefined>([]);
    const [hasSinister, setHasSinister] = useState(false);
    const [status, setStatus] = useState<string>('');
    const [document, setDocument] = useState<string>('');
    const toastHandler = useToast();
    const { debounce } = useDebounce();
    const [modified, setModified] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const { loggedUser } = useAuthContext();
    const [documentType, setDocumentType] = useState<string | undefined>("");

    const handleShow = () => {
        setOpenModalSimulation(true)
    }

    const handleSimulationValues = (dados: ISimulateEditProposalValues) => {
        setIsLoading(true);
        dados.rentAmount = globalRemoveMask(dados.rentAmount);
        dados.lmiAmount = globalRemoveMask(dados.lmiAmount);
        dados.assuranceMonthAmount = globalRemoveMask(dados.assuranceMonthAmount);
        formValidationSchemaValue.
            validate(dados, { abortEarly: false })
            .then((dadosvalidados) => {
                setIsLoading(true);
                ProposalEditService
                    .simulateValue(id, dadosvalidados)
                    .then((result) => {
                        setDataValues(dados);
                        meusdados.push({ Field: "Plano", ValueBack: result.proposal.planId, ValueNew: result.newProposal.planId as string })
                        meusdados.push({ Field: "LMI", ValueBack: result.proposal.lmi, ValueNew: result.newProposal.lmi as string })
                        meusdados.push({ Field: "Valor Lmi", ValueBack: parseFloat(`${result.proposal.lmiAmount}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), ValueNew: parseFloat(`${result.newProposal.lmiAmount}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) })
                        meusdados.push({ Field: "Valor Aluguel", ValueBack: parseFloat(`${result.proposal.rentAmount}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), ValueNew: parseFloat(`${result.newProposal.rentAmount}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) })
                        meusdados.push({ Field: "Taxa Aplicada", ValueBack: result.proposal.residentialFee, ValueNew: result.newProposal.residentialFee as string })
                        meusdados.push({ Field: "Valor Mensalidade", ValueBack: parseFloat(`${result.proposal.assuranceMonthAmount}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), ValueNew: parseFloat(`${result.newProposal.assuranceMonthAmount}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) })
                        setLoadData(meusdados);
                        setIsLoading(false);
                        handleShow();
                    }).catch((error) => {
                        setIsLoading(false);
                    });

            })
            .catch((errors: yup.ValidationError) => {
                const validationErros: IVFormErros = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErros[error.path] = error.message;
                });
                console.log(validationErros);
                formRef.current?.setErrors(
                    validationErros
                );
                setIsLoading(false);
            });
    }

    const handleSimulation = (dados: ISimulateEditProposal) => {
        setIsLoading(true);
        delete dados.rentMonthsCount;
        delete dados.rentMonthsCountContract;
        formValidationSchema.
            validate(dados, { abortEarly: false })
            .then((dadosvalidados) => {
                setIsLoading(true);
                ProposalEditService
                    .simulate(id, dadosvalidados)
                    .then((result) => {
                        setDataValidity(dados);
                        meusdados.push({ Field: "Inicio Contrato Locação", ValueBack: moment(`${result.proposal.contractBegin.substring(0, 10)}T03:00:00.000Z`).format("L"), ValueNew: moment(`${result.newProposal.contractBegin.substring(0, 10)}T03:00:00.000Z`).format("L") })
                        meusdados.push({ Field: "Fim do Contrato Locação", ValueBack: moment(`${result.proposal.contractEnd.substring(0, 10)}T03:00:00.000Z`).format("L"), ValueNew: moment(`${result.newProposal.contractEnd.substring(0, 10)}T03:00:00.000Z`).format("L") })
                        meusdados.push({ Field: "Inicio da Vigência", ValueBack: moment(`${result.proposal.termBegin.substring(0, 10)}T03:00:00.000Z`).format("L"), ValueNew: moment(`${result.newProposal.termBegin.substring(0, 10)}T03:00:00.000Z`).format("L") })
                        meusdados.push({ Field: "Fim da Vigência", ValueBack: moment(`${result.proposal.expiresAt.substring(0, 10)}T03:00:00.000Z`).format("L"), ValueNew: moment(`${result.newProposal.expiresAt.substring(0, 10)}T03:00:00.000Z`).format("L") })
                        meusdados.push({ Field: "Meses de Vigência", ValueBack: result.proposal.rentMonthsCount, ValueNew: result.newProposal.rentMonthsCount as string })
                        meusdados.push({ Field: "Valor da Parcela", ValueBack: parseFloat(`${result.proposal.assuranceMonthAmount}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), ValueNew: parseFloat(`${result.proposal.assuranceMonthAmount}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) })
                        meusdados.push({ Field: "Quantidade de parcelas", ValueBack: result.proposal.rentMonthsCount, ValueNew: result.newProposal.rentMonthsCount as string })
                        setLoadData(meusdados);
                        setIsLoading(false);
                        handleShow();
                    }).catch((error) => {
                        setIsLoading(false);
                    });

            })
            .catch((errors: yup.ValidationError) => {
                const validationErros: IVFormErros = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErros[error.path] = error.message;
                });
                console.log(validationErros);
                formRef.current?.setErrors(
                    validationErros
                );
                setIsLoading(false);
            });
    };

    const handleSimulationAddress = (dados: ISimulateEditProposalAddress) => {
        setIsLoading(true);
        formValidationSchemaAddress.
            validate(dados, { abortEarly: false })
            .then((dadosvalidados) => {
                setIsLoading(true);
                ProposalEditService
                    .simulateAddress(id, dadosvalidados)
                    .then((result) => {
                        setDataAddess(dados);
                        meusdados.push({ Field: "Endereço", ValueBack: result.proposal.addressStreet, ValueNew: result.newProposal.addressStreet })
                        meusdados.push({ Field: "Numero", ValueBack: result.proposal.addressNumber, ValueNew: result.newProposal.addressNumber })
                        meusdados.push({ Field: "Complemento", ValueBack: result.proposal.addressComplement, ValueNew: result.newProposal.addressComplement })
                        meusdados.push({ Field: "Bairro", ValueBack: result.proposal.addressDistrict, ValueNew: result.newProposal.addressDistrict })
                        meusdados.push({ Field: "Cidade", ValueBack: result.proposal.addressCity, ValueNew: result.newProposal.addressCity })
                        meusdados.push({ Field: "Estado", ValueBack: result.proposal.addressState, ValueNew: result.newProposal.addressState })
                        meusdados.push({ Field: "Cep", ValueBack: result.proposal.addressZipcode, ValueNew: result.newProposal.addressZipcode as string })
                        setLoadData(meusdados);
                        setIsLoading(false);
                        handleShow();
                    }).catch((error) => {
                        setIsLoading(false);
                    });

            })
            .catch((errors: yup.ValidationError) => {
                const validationErros: IVFormErros = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErros[error.path] = error.message;
                });
                console.log(validationErros);
                formRef.current?.setErrors(
                    validationErros
                );
                setIsLoading(false);
            });
    };

    const handleLocatorChange = (dados: ISimulateEditLocator) => {
        setIsLoading(true);

        formValidationSchemaLocator.
            validate(dados, { abortEarly: false })
            .then((dadosvalidados: any) => {
                setIsLoading(true);

                ProposalEditService
                    .simulateLocator(id, dadosvalidados)
                    .then((result: TProposalSimulation<any>) => {
                        setIsLoading(false);
                        if (result instanceof Error) {
                            const titleMessage = "Alerta!!";
                            const contentMessage = "Erro: " + result?.message;

                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            });
                        } else {
                            setDataLocator(dados);
                            meusdados.push({ Field: "Documento", ValueBack: result.proposal.document, ValueNew: result.newProposal.document })
                            meusdados.push({ Field: "Nome", ValueBack: result.proposal.name, ValueNew: result.newProposal.name })
                            meusdados.push({ Field: "Data de nascimento", ValueBack: moment(`${result.proposal.birthDate.substring(0, 10)}T03:00:00.000Z`).format("L"), ValueNew: moment(`${result.newProposal.birthDate.substring(0, 10)}T03:00:00.000Z`).format("L") })
                            meusdados.push({ Field: "Celular", ValueBack: result.proposal.mobilePhone, ValueNew: result.newProposal.mobilePhone })
                            meusdados.push({ Field: "Telefone", ValueBack: result.proposal.phone, ValueNew: result.newProposal.phone })
                            meusdados.push({ Field: "E-mail", ValueBack: result.proposal.email, ValueNew: result.proposal.email })
                            meusdados.push({ Field: "Tipo de Locador", ValueBack: result.proposal.lessorTypePerson, ValueNew: result.proposal.lessorTypePerson })
                            meusdados.push({ Field: "Motivo", ValueBack: result.proposal.obs, ValueNew: result.newProposal.obs })
                            setLoadData(meusdados);
                            handleShow();
                        }
                    }).catch((err: any) => {
                        setIsLoading(false);

                        const { titleMessage, contentMessage } = returnMessageError(err);

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    });

            })
            .catch((errors: yup.ValidationError) => {
                const validationErros: IVFormErros = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErros[error.path] = error.message;
                });
                console.log(validationErros);
                formRef.current?.setErrors(
                    validationErros
                );
                setIsLoading(false);
            });
    };

    const handleCancelChange = (dados: ISimulateEditCancel) => {
        setIsLoading(true);
        dados.canceledAt = dados.canceledAt != null ? dados.canceledAt : "";
        dados.canceledReason = dados.canceledReason != null ? dados.canceledReason : "";
        dados.canceledType = dados.canceledType != null ? dados.canceledType : "";

        formValidationSchemaCancel.
            validate(dados, { abortEarly: false })
            .then((dadosvalidados: any) => {
                setIsLoading(false);

                setDataCancel(dados);
                meusdados.push({ Field: "Motivo do cancelamento", ValueBack: rows?.canceledType, ValueNew: dados.canceledType })
                meusdados.push({ Field: "Data do cancelamento", ValueBack: moment(`${rows?.canceledAt?.substring(0, 10)}T03:00:00.000Z`).format("L"), ValueNew: moment(`${dados?.canceledAt?.substring(0, 10)}T03:00:00.000Z`).format("L") })
                meusdados.push({ Field: "Descrição do cancelamento", ValueBack: rows?.canceledReason, ValueNew: dados.canceledReason })
                setLoadData(meusdados);
                handleShow();

            })
            .catch((errors: yup.ValidationError) => {
                const validationErros: IVFormErros = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErros[error.path] = error.message;
                });
                console.log(validationErros);
                formRef.current?.setErrors(
                    validationErros
                );
                setIsLoading(false);
            });
    };

    useEffect(() => {

        if (sinisters?.length && status == 'active' && isSuperAdmin) {
            const length = sinisters.length;
            const canceledSinisters = sinisters.filter(sinister => sinister?.status === 'canceled');
            if (canceledSinisters?.length < length) {
                setHasSinister(true);
            }
        }

    }, [sinisters]);

    useEffect(() => {
        setIsLoading(true);
        ProposalService.getById(`${id}`)
            .then((result) => {
                setIsLoading(false);
                if (result instanceof Error) {
                    alert(result.message);
                    navigate(`/proposal/detail/${id}`)
                } else {
                    setRows(result);
                    setSinisters(result.sinister);
                    setStatus(result.status);
                    setDocument(result.document);
                    setTermBegin(result.termBegin!);
                    setTermEnd(result.expiresAt!);
                    setContractBegin(result.contractBegin!);
                    setContractEnd(result.contractEnd!);
                    //console.log('249....',result.lmi)
                    if (result.lmi == '0') { setVLmi('12') }
                    else {
                        setVLmi(result.lmi!);
                    }
                    setVTaxa(result.residentialFee!);
                    setVRentAmount(result.rentAmount!);
                    setVLmiAmount(result.lmiAmount!);
                    //setVInstallment(result.assuranceMonthAmount!);
                    setPlano(result.planId!);
                }
            });
    }, [id, validityChange, addressChange, valuesChange, locatorChange, cancelChange]);

    const handleCPFOrCNPJ = (value: string) => {

        const documentCPFOrCNPJ = value?.replace(/[^0-9]/g, "");
        const documentLocator = document?.replace(/[^0-9]/g, "");

        if (documentCPFOrCNPJ === documentLocator) {
            toastHandler.present({
                type: 'error',
                position: 'top-right',
                messageOrContent: <ToastContent title={'Erro '} content={'Documento do locador deve ser diferente do documento do locatário'} />
            })

            formRef.current?.setFieldValue("lessorName", "");
            formRef.current?.setFieldValue("lessorBirthDate", "");
            formRef.current?.setFieldValue("lessorPhone", "");
            formRef.current?.setFieldValue("lessorMobilePhone", "");
            formRef.current?.setFieldValue("lessorEmail", "");

        } else {

            PeopleService.getPersonDataByDocument(documentCPFOrCNPJ)
                .then(data => {
                    formRef.current?.setFieldValue("lessorName", data.naturalPersonData?.name);
                    formRef.current?.setFieldValue("lessorBirthDate", data.naturalPersonData?.birthDate);
                    formRef.current?.setFieldValue("lessorPhone", data.naturalPersonData?.phone);
                    formRef.current?.setFieldValue("lessorMobilePhone", data.naturalPersonData?.mobilePhone);
                    formRef.current?.setFieldValue("lessorEmail", data.naturalPersonData?.email);

                })
                .catch(err => {
                    const messageTitle = 'Erro na pesquisa'
                    const messageContent = 'Documento não localizado na base, verifique se esta correto.'

                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={messageTitle} content={messageContent} />
                    })
                });
        }
    }

    useEffect(() => {
        if (validityChange) {
            formRef.current?.setData({
                termBegin: formatDateForInput(rows?.termBegin ? new Date(`${rows?.termBegin.substring(0, 10)}T03:00:00.000Z`) : new Date('2023-01-1899')),
                contractBegin: formatDateForInput(rows?.contractBegin ? new Date(`${rows?.contractBegin.substring(0, 10)}T03:00:00.000Z`) : new Date('2023-01-1899')),
                contractEnd: formatDateForInput(rows?.contractEnd ? new Date(`${rows?.contractEnd.substring(0, 10)}T03:00:00.000Z`) : new Date('2023-01-1899')),
                expiresAt: formatDateForInput(rows?.expiresAt ? new Date(`${rows?.expiresAt.substring(0, 10)}T03:00:00.000Z`) : new Date('2023-01-1899')),
                rentMonthsCount: rows?.rentMonthsCount ? rows?.rentMonthsCount : 1,
                rentMonthsCountContract: rows?.rentMonthsCount ? rows?.rentMonthsCount : 1,
            })
        }
    }, [validityChange])

    useEffect(() => {
        if (valuesChange) {
            clientService.getByID(rows!.clientId)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        const clientPlan = result.__client_plan__ as unknown as IClientPlan[];
                        setListPlans(clientPlan)
                        const pplano = clientPlan;
                        setListPlans(pplano);
                    }
                });
            formRef.current?.setData({
                rentAmount: rows?.rentAmount,
                fee: rows?.residentialFee,
                assuranceMonthAmount: rows?.assuranceMonthAmount,
                lmi: rows?.lmi,
                lmiAmount: rows?.lmiAmount,
                planId: rows?.planId,
            })
        }
    }, [valuesChange])

    useEffect(() => {
        if (addressChange) {
            formRef.current?.setData({
                addressStreet: rows?.addressStreet,
                addressNumber: rows?.addressNumber,
                addressComplement: rows?.addressComplement,
                addressDistrict: rows?.addressDistrict,
                addressCity: rows?.addressCity,
                addressState: rows?.addressState,
                addressZipcode: rows?.addressZipcode,
                addressIBGE: rows?.addressIbge,
            })
        }
        { loggedUser?.role == "admin" && isSuperAdmin && (setEditAddress(true)) }
    }, [addressChange])

    useEffect(() => {
        if (locatorChange && isSuperAdmin && loggedUser?.role == "admin"
            && status == 'active' && !hasSinister) {

            formRef.current?.setData({
                lessorName: rows?.lessorName,
                lessorCpf: rows?.lessorCpf,
                lessorBirthDate: rows?.lessorBirthDate,
                lessorEmail: rows?.lessorEmail,
                lessorPhone: rows?.lessorPhone,
                lessorMobilePhone: rows?.lessorMobilePhone,
                lessorTypePerson: rows?.lessorTypePerson,
                obs: rows?.obs
            });
            setDocumentType(rows?.lessorTypePerson);
        }
    }, [locatorChange]);

    useEffect(() => {
        if (cancelChange) {
            formRef.current?.setData({
                canceledReason: rows?.canceledReason,
                canceledType: rows?.canceledType,
                canceledAt: rows?.canceledAt,
            });
        }
    }, [cancelChange]);

    useEffect(() => {
        if (VLmi && VLmiAmount) {
            debounce(() => {
                // console.log("rent:", parseFloat(globalRemoveMask(VRentAmount)), '* taxa:', (parseFloat(VTaxa) / 100), 'total = ', (parseFloat(globalRemoveMask(VRentAmount)) * (parseFloat(VTaxa) / 100)).toFixed(2))
                // console.log("rent:", parseFloat(globalRemoveMask(VRentAmount)), '* lmi:', (parseInt(VLmi)), 'total = ', (parseFloat(globalRemoveMask(VRentAmount)) * (parseFloat(VLmi) )).toFixed(2))
                if (plano == 'custom') {
                    setModified(true)
                } else {
                    setModified(false)
                }
                formRef.current?.setData({
                    lmiAmount: (parseFloat(globalRemoveMask(VRentAmount)) * (parseInt(VLmi))).toFixed(2),
                    rentAmount: VRentAmount,
                    assuranceMonthAmount: (parseFloat(globalRemoveMask(VRentAmount)) * (parseFloat(VTaxa) / 100)).toFixed(2),
                    lmi: parseInt(VLmi),
                    fee: parseFloat(VTaxa),
                    planId: plano
                });

            })
        }
    }, [VLmi, VRentAmount, VTaxa, plano])

    useEffect(() => {
        if (termBegin && termEnd && contractBegin && contractEnd) {
            debounce(() => {
                const difMonthsValidity = differenceInMonths(startOfMonth(new Date(termEnd)), startOfMonth(new Date(termBegin)));
                const difMonthsContract = differenceInMonths(startOfMonth(new Date(contractEnd)), startOfMonth(new Date(contractBegin)));
                formRef.current?.setData({
                    rentMonthsCountContract: difMonthsContract,
                    rentMonthsCount: difMonthsValidity,
                    termBegin: formatDateForInput(new Date(`${termBegin.substring(0, 10)}T03:00:00.000Z`)),
                    expiresAt: formatDateForInput(new Date(`${termEnd.substring(0, 10)}T03:00:00.000Z`)),
                    contractBegin: formatDateForInput(new Date(`${contractBegin.substring(0, 10)}T03:00:00.000Z`)),
                    contractEnd: formatDateForInput(new Date(`${contractEnd.substring(0, 10)}T03:00:00.000Z`)),
                });
            })
        }
    }, [contractBegin, contractEnd, termBegin, termEnd])


    const handleSaveUpdate = () => {
        if (validityChange) {
            if (dataValidity) {
                ProposalEditService
                    .updateValidity(id, dataValidity)
                    .then((result: any) => {
                        setIsLoading(false);
                        if (result instanceof Error) {
                            const titleMessage = "Alerta!!";
                            const contentMessage = "Erro: " + result?.message;
                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            });
                        }
                        else {

                            setOpenModalSimulation(false)
                            toastHandler.present({
                                type: 'success',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={'Sucesso'} content={'Garantia Atualizada com sucesso'} />
                            })
                        }
                    }).catch((error) => {
                        setIsLoading(false);
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + error?.message;
                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    });
            }
        }

        if (valuesChange) {
            if (dataValues) {
                ProposalEditService
                    .updateValues(id, dataValues)
                    .then((result: any) => {
                        if (result instanceof Error) {
                            const titleMessage = "Alerta!!";
                            const contentMessage = "Erro: " + result?.message;
                            setOpenModalSimulation(false)
                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            });
                        }
                        else {
                            toastHandler.present({
                                type: 'success',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={'Sucesso'} content={'Garantia Atualizada com sucesso'} />
                            })
                        }
                    }).catch((error) => {
                        setIsLoading(false);
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + error?.message;
                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    });
            }
        }

        if (addressChange) {
            if (dataAddress) {
                ProposalEditService
                    .updateAddress(id, dataAddress)
                    .then((result: any) => {
                        if (result instanceof Error) {
                            const titleMessage = "Alerta!!";
                            const contentMessage = "Erro: " + result?.message;
                            setOpenModalSimulation(false)
                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            });
                        }
                        else {
                            toastHandler.present({
                                type: 'success',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={'Sucesso'} content={'Garantia Atualizada com sucesso'} />
                            })
                        }
                    }).catch((error) => {
                        setIsLoading(false);
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + error?.message;
                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    });
            }
        }

        if (locatorChange && dataLocator) {

            setIsLoading(true);
            ProposalEditService
                .updateLocator(id, dataLocator)
                .then((result: TProposalSimulation<any>) => {
                    setIsLoading(false);
                    setOpenModalSimulation(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        });
                    }
                    else {
                        toastHandler.present({
                            type: 'success',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={'Sucesso'} content={'Garantia Atualizada com sucesso'} />
                        })
                    }
                }).catch((error: any) => {
                    setIsLoading(false);
                    setOpenModalSimulation(false);
                    const titleMessage = "Alerta!!";
                    const contentMessage = "Erro: " + error?.message;
                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    })
                });

        }

        if (cancelChange && dataCancel) {

            setIsLoading(true);
            ProposalEditService
                .updateCancel(id, dataCancel)
                .then((result: TProposalSimulation<any>) => {
                    setIsLoading(false);
                    setOpenModalSimulation(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        });
                    }
                    else {
                        toastHandler.present({
                            type: 'success',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={'Sucesso'} content={'Garantia Atualizada com sucesso'} />
                        })
                    }
                }).catch((error: any) => {
                    setIsLoading(false);
                    setOpenModalSimulation(false);
                    const titleMessage = "Alerta!!";
                    const contentMessage = "Erro: " + error?.message;
                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    })
                });

        }
    }

    const checkCEP = (e: any) => {
        const cep = e.target.value.replace(/\D/g, '');
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(res => res.json()).then(data => {
                formRef.current?.setFieldValue("addressZipcode", data.cep);
                formRef.current?.setFieldValue("addressStreet", data.logradouro);
                formRef.current?.setFieldValue("residenceTypeo", '');
                formRef.current?.setFieldValue("addressDistrict", data.bairro);
                formRef.current?.setFieldValue("addressCity", data.localidade);
                formRef.current?.setFieldValue("addressState", data.uf);
                formRef.current?.setFieldValue("addressIBGE", data.ibge);
            });
    }


    return (
        <LayoutBaseDePagina
            titulo='Garantias'
            subtitulo={`Alteração de Garantia`}
            barraDeFerramentas={
                <ToolbarEditProposal
                    clickBack={() => navigate(`/garantias/visualizar/${id}`)}
                    clickSave={() => { formRef.current?.submitForm(); setOpenModalSimulation(true) }}
                    isDisabledConfirm={!(validityChange || valuesChange || addressChange || locatorChange || cancelChange)}
                    showButtonSave={true}
                    showButtonEditValidity={checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.UpProposalPeriod)}
                    showButtonEditValues={checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.UpProposalValues)}
                    showButtonEditAddress={checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.UpProposalAddress)}
                    showButtonEditLocator={status == 'active' && isSuperAdmin && !hasSinister &&
                        loggedUser?.role == "admin"}//checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.UpProposalLocator)}
                    showButtonEditCancel={status == 'canceled' && checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.UpProposalCancel)}
                    clickEditValues={() => { { setValidityChange(false), setValuesChange(true), setAddressChange(false), setLocatorChange(false), setCancelChange(false) } }}
                    clickValidity={() => { { setValidityChange(true), setValuesChange(false), setAddressChange(false), setLocatorChange(false), setCancelChange(false) } }}
                    clickEditAddress={() => { { setAddressChange(true), setValuesChange(false), setValidityChange(false), setLocatorChange(false), setCancelChange(false) } }}
                    clickEditLocator={() => { { setLocatorChange(true), setAddressChange(false), setValidityChange(false), setValuesChange(false), setCancelChange(false) } }}
                    clickEditCancel={() => { { setCancelChange(true), setLocatorChange(false), setAddressChange(false), setValidityChange(false), setValuesChange(false) } }}
                />
            }
        >

            {openModalSimulation && (
                <ModalComparation
                    ITitle="Alteração de garantia"
                    TitleBack="Contrato Original"
                    TitleNew="Contrato Alterado"
                    isOpenModal={openModalSimulation}
                    dataComparation={LoagindData}
                    showButtonConfirm={!isLoading}
                    textButtonConfirm="Confirmar"
                    textButtonCancel="Desistir"
                    showButtonCancel={true}
                    isCloseModal={() => { setOpenModalSimulation(false) }}
                    onclickConfirm={() => {
                        handleSaveUpdate(),
                            setOpenModalSimulation(false)
                    }}
                />
            )}

            <Grid container spacing={3}>
                <ProposalHeader
                    document={rows?.document}
                    name={rows?.name}
                    email={rows?.email}
                    certificateNumber={rows?.certificateNumber}
                    birthDate={rows?.birthDate}
                    createdAt={rows?.createdAt}
                    addressCity={rows?.addressCity}
                    addressStreet={rows?.addressStreet}
                    addressNumber={rows?.addressNumber}
                    addressDistrict={rows?.addressDistrict}
                    addressState={rows?.addressState}
                    addressZipcode={rows?.addressZipcode}
                    status={rows?.status}
                    mobilePhone={rows?.mobilePhone}
                />
            </Grid>

            {validityChange && (<Box >
                <VForm
                    id="formIdSimulation"
                    placeholder="Your placeholder"
                    onPointerEnterCapture={() => console.log("Pointer entered")}
                    onPointerLeaveCapture={() => console.log("Pointer left")}
                    ref={formRef} onSubmit={handleSimulation}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container item direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant="indeterminate" />
                                </Grid>
                            )}
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={11} >
                                    <Typography variant="h6" color={'primary'}>Alteração de Vigência</Typography>
                                </Grid>

                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Inicio do contrato de locação'
                                            name="contractBegin"
                                            disabled={isLoading}
                                            type="date"
                                            onChange={(e) => setContractBegin(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Fim do contrato de locação'
                                            name="contractEnd"
                                            disabled={isLoading}
                                            onChange={(e) => setContractEnd(e.target.value)}
                                            type="date"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Vigência Locação'
                                            name="rentMonthsCountContract"
                                            disabled={true}
                                        >
                                        </VTextField>
                                    </Grid>
                                </Grid>

                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Inicio da Vigencia'
                                            name="termBegin"
                                            disabled={isLoading}
                                            onChange={(e) => setTermBegin(e.target.value)}
                                            type="date"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Fim da Vigencia'
                                            name="expiresAt"
                                            disabled={isLoading}
                                            onChange={(e) => setTermEnd(e.target.value)}
                                            type="date"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Vigência da garantia'
                                            name="rentMonthsCount"
                                            disabled={true}
                                        >
                                        </VTextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </VForm>
            </Box >
            )}

            {valuesChange && (<Box>
                <VForm
                    id="formIdSimulationValues"
                    placeholder="Your placeholder"
                    onPointerEnterCapture={() => console.log("Pointer entered")}
                    onPointerLeaveCapture={() => console.log("Pointer left")}
                    ref={formRef} onSubmit={handleSimulationValues}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container item direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant="indeterminate" />
                                </Grid>
                            )}
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={11} >
                                    <Typography variant="h6" color={'primary'}>Alteração de valores</Typography>
                                </Grid>


                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Plano Selecionado'
                                            name="planId"
                                            disabled={isLoading}
                                            select
                                            onChange={(e) => {
                                                setPlano(e.target.value)
                                            }}
                                        >
                                            {listPlans?.map((element, key) => (
                                                <MenuItem value={element.plan?.id}>{element.plan?.description}</MenuItem>
                                            ))}
                                        </VTextField>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='LMI'
                                            name="lmi"
                                            disabled={isLoading || !modified}
                                            onChange={(e) => setVLmi(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VMaskTextField
                                            fullWidth
                                            label='Valor LMI'
                                            name="lmiAmount"
                                            disabled={true}
                                            mask="real"
                                            onChange={(e) => setVLmiAmount(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VMaskTextField
                                            fullWidth
                                            label='Valor do Aluguel'
                                            name="rentAmount"
                                            disabled={isLoading}
                                            mask="real"
                                            onChange={(e) => setVRentAmount(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Taxa Aplicada'
                                            name="fee"
                                            disabled={isLoading || !modified}
                                            onChange={(e) => setVTaxa(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                        <VMaskTextField
                                            fullWidth
                                            label='Valor Mensal'
                                            name="assuranceMonthAmount"
                                            disabled={true}
                                            mask={"real"}
                                            sx={{ backgroundColor: "#ffe9e9", color: "red" }}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </VForm>
            </Box >
            )}

            {addressChange && (<Box>
                <VForm
                    id="formIdSimulationAddress"
                    placeholder="Your placeholder"
                    onPointerEnterCapture={() => console.log("Pointer entered")}
                    onPointerLeaveCapture={() => console.log("Pointer left")}
                    ref={formRef} onSubmit={handleSimulationAddress}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container item direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant="indeterminate" />
                                </Grid>
                            )}
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={11} >
                                    <Typography variant="h6" color={'primary'}>Alteração de endereço</Typography>
                                </Grid>


                                {/* ************************************************************************ */}
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VMaskTextField
                                            fullWidth
                                            label='CEP'
                                            name="addressZipcode"
                                            disabled={!editAddress}
                                            mask="cep"
                                            onBlur={checkCEP}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Rua'
                                            name="addressStreet"
                                            disabled={!editAddress}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Numero'
                                            name="addressNumber"
                                            disabled={!editAddress}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Complemento'
                                            name="addressComplement"
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Bairro'
                                            name="addressDistrict"
                                            disabled={!editAddress}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Cidade'
                                            name="addressCity"
                                            disabled={!editAddress}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Estado'
                                            name="addressState"
                                            disabled={!editAddress}
                                            select
                                        >
                                            <MenuItem value="AC">Acre</MenuItem>
                                            <MenuItem value="AL">Alagoas</MenuItem>
                                            <MenuItem value="AP">Amapá</MenuItem>
                                            <MenuItem value="AM">Amazonas</MenuItem>
                                            <MenuItem value="BA">Bahia</MenuItem>
                                            <MenuItem value="CE">Ceará</MenuItem>
                                            <MenuItem value="DF">Distrito Federal</MenuItem>
                                            <MenuItem value="ES">Espírito Santo</MenuItem>
                                            <MenuItem value="GO">Goiás</MenuItem>
                                            <MenuItem value="MA">Maranhão</MenuItem>
                                            <MenuItem value="MT">Mato Grosso</MenuItem>
                                            <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                                            <MenuItem value="MG">Minas Gerais</MenuItem>
                                            <MenuItem value="PA">Pará</MenuItem>
                                            <MenuItem value="PB">Paraíba</MenuItem>
                                            <MenuItem value="PR">Paraná</MenuItem>
                                            <MenuItem value="PE">Pernambuco</MenuItem>
                                            <MenuItem value="PI">Piauí</MenuItem>
                                            <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                                            <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                                            <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                                            <MenuItem value="RO">Rondônia</MenuItem>
                                            <MenuItem value="RR">Roraima</MenuItem>
                                            <MenuItem value="SC">Santa Catarina</MenuItem>
                                            <MenuItem value="SP">São Paulo</MenuItem>
                                            <MenuItem value="SE">Sergipe</MenuItem>
                                            <MenuItem value="TO">Tocantins</MenuItem>
                                        </VTextField>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Cód IBGE'
                                            name="addressIBGE"
                                            disabled={true}
                                            sx={{ border: "none" }}
                                        />
                                    </Grid>
                                </Grid>

                                {/* ************************************************************************ */}
                            </Grid>
                        </Grid>
                    </Box>
                </VForm>
            </Box >
            )}


            {locatorChange && status == 'active' && isSuperAdmin && !hasSinister &&
                loggedUser?.role == "admin" && (<Box>
                    <VForm
                        id="formIdLocatorChange"
                        placeholder="Your placeholder"
                        onPointerEnterCapture={() => console.log("Pointer entered")}
                        onPointerLeaveCapture={() => console.log("Pointer left")}
                        ref={formRef} onSubmit={handleLocatorChange}>
                        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                            <Grid container item direction="column" padding={2} spacing={2}>
                                {isLoading && (
                                    <Grid item>
                                        <LinearProgress variant="indeterminate" />
                                    </Grid>
                                )}
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} >
                                        <Typography variant="h6" color={'primary'}>Alteração do Locador</Typography>
                                    </Grid>

                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Documento'
                                                name="lessorCpf"
                                                disabled={isLoading}
                                                mask={documentType == "legal" ? "cnpj" : "cpf"}
                                                onBlur={e => handleCPFOrCNPJ(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <VTextField
                                                fullWidth
                                                label='Nome'
                                                name="lessorName"
                                                disabled={isLoading}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <VTextField
                                                fullWidth
                                                label='Data de nascimento'
                                                name="lessorBirthDate"
                                                disabled={isLoading}
                                                type="date"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Celular'
                                                name="lessorPhone"
                                                disabled={isLoading}
                                                mask="cell"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Telefone'
                                                name="lessorMobilePhone"
                                                disabled={isLoading}
                                                mask="cell"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <VTextField
                                                fullWidth
                                                label='E-mail'
                                                name="lessorEmail"
                                                disabled={isLoading}
                                                trimEmptySpaces={true}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                <Email />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={12} md={2}>
                                            <VTextField
                                                fullWidth
                                                label='Tipo de Locador'
                                                name="lessorTypePerson"
                                                disabled={isLoading}
                                                onChange={(e: any) => {
                                                    setDocumentType(e.target.value);
                                                    formRef.current?.reset();
                                                    formRef.current?.setData({
                                                        lessorTypePerson: e.target.value,
                                                        lessorName: rows?.lessorName,
                                                        lessorCpf: rows?.lessorCpf,
                                                        lessorBirthDate: rows?.lessorBirthDate,
                                                        lessorEmail: rows?.lessorEmail,
                                                        lessorPhone: rows?.lessorPhone,
                                                        lessorMobilePhone: rows?.lessorMobilePhone,
                                                        obs: rows?.obs
                                                    });
                                                }}
                                                select
                                            >
                                                <MenuItem value={'physical'}>Fisica</MenuItem>
                                                <MenuItem value={'legal'}>Juridica</MenuItem>
                                            </VTextField>
                                        </Grid>

                                        <Grid item xs={12} md={10}>
                                            <VTextField
                                                fullWidth
                                                label='Motivo'
                                                name="obs"
                                                disabled={isLoading}
                                                type="text"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </VForm>
                </Box >
                )}


            {cancelChange && (<Box>
                <VForm
                    id="formIdCancelChange"
                    placeholder="Your placeholder"
                    onPointerEnterCapture={() => console.log("Pointer entered")}
                    onPointerLeaveCapture={() => console.log("Pointer left")}
                    ref={formRef} onSubmit={handleCancelChange}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container item direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant="indeterminate" />
                                </Grid>
                            )}
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} >
                                    <Typography variant="h6" color={'primary'}>Alteração do Cancelamento</Typography>
                                </Grid>

                                <Grid container item direction="row" spacing={2}>

                                    <Grid item xs={12} md={8}>
                                        <VTextField
                                            fullWidth
                                            label="Motivo do cancelamento"
                                            name="canceledType"
                                            disabled={isLoading}
                                            select
                                        >
                                            {/* <MenuItem value={"Desistência da Locação"}>Desistência da Locação</MenuItem> */}
                                            {/* <MenuItem value={"Mudou de imobiliária/imóvel"}>Mudou de imobiliária/imóvel</MenuItem> */}
                                            {/* <MenuItem value={"Duplicidade de Garantia"}>Duplicidade de análise</MenuItem> */}
                                            {/* <MenuItem value={"Optou por caução ou fiador"}>Optou por caução ou fiador</MenuItem>
                            <MenuItem value={"Optou por outra garantia/seguro"}>Optou por outra garantia/seguro</MenuItem> */}
                                            <MenuItem value={"Preço"}>Preço</MenuItem>
                                            <MenuItem value={"Compra do imóvel"}>Compra do imóvel</MenuItem>
                                            <MenuItem value={"Desistência da locação"}>Desistência da locação</MenuItem>
                                            <MenuItem value={"Distrato locador e imobiliária"}>Distrato locador e imobiliária</MenuItem>
                                            <MenuItem value={"Duplicidade de garantias"}>Duplicidade de garantias</MenuItem>
                                            <MenuItem value={"Garantia irregular/fraude"}>Garantia irregular/fraude</MenuItem>
                                            <MenuItem value={"Imissão na posse - Sinistro"}>Imissão na posse - Sinistro</MenuItem>
                                            <MenuItem value={"Inadimplência (imobiliária)"}>Inadimplência (imobiliária)</MenuItem>
                                            <MenuItem value={"LMI/LMG atingido"}>LMI/LMG atingido</MenuItem>
                                            <MenuItem value={"Termo de entrega de chaves - Sinistro"}>Termo de entrega de chaves - Sinistro</MenuItem>
                                            <MenuItem value={"Termo de entrega de chaves e confissão de dívidas"}>Termo de entrega de chaves e confissão de dívidas</MenuItem>
                                            <MenuItem value={"Termo de entrega de chaves e inexistência de débitos - imobiliária"}>Termo de entrega de chaves e inexistência de débitos - imobiliária</MenuItem>
                                            <MenuItem value={"Termo de revogação/declaração de responsabilidade"}>Termo de revogação/declaração de responsabilidade</MenuItem>
                                            <MenuItem value={"Troca de garantia"}>Troca de garantia</MenuItem>
                                            <MenuItem value={"Troca de imobiliária/estipulante/administradora"}>Troca de imobiliária/estipulante/administradora</MenuItem>
                                            <MenuItem value={"Troca de locatário"}>Troca de locatário</MenuItem>
                                            <MenuItem value={"Troca de seguradora"}>Troca de seguradora</MenuItem>
                                            <MenuItem value={"Outros"}>Outros</MenuItem>
                                        </VTextField>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <VTextField
                                            fullWidth
                                            label='Data do cancelamento'
                                            name="canceledAt"
                                            disabled={isLoading}
                                            type="date"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12}>
                                        <VTextField fullWidth label="Descrição do cancelamento" name="canceledReason" disabled={isLoading} multiline rows={4} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </VForm>
            </Box >
            )}

        </LayoutBaseDePagina >
    )
}