import { Box, Button, Card, CircularProgress, Grid, Icon, styled, Typography } from "@mui/material";
import { format } from "date-fns";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useCallback, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import * as yup from "yup";
import { useVForm, VForm, VTextField } from "../../../shared/forms";
import { useToast } from "../../../shared/hooks";
import { GetSinisterFollowupNotesResponse, SinisterFollowUpService } from "../../../shared/services/api/sinister/SinisterFollowUpService";
import { returnMessageErrorAsync } from "../../../shared/utils/returnMessageError";
import { ToastContent } from "../../../shared/components";

type SinisterFollowupModalContentPropsType = {
	sinisterId: string;
};

const addContactSchema = yup.object().shape({
	email: yup.string().email().required(),
});
export const SinisterFollowupModalContent = ({ sinisterId }: SinisterFollowupModalContentPropsType) => {
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [isLoadingNotes, setIsLoadingNotes] = useState<boolean>(false);
	const [isLoadingContacts, setIsLoadingContacts] = useState<boolean>(false);
	const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
	const [notes, setNotes] = useState<GetSinisterFollowupNotesResponse[]>([]);
	const [contacts, setContacts] = useState<{ id: string; email: string }[]>([]);
	const [html, setHtml] = useState<string>("");
	const { formRef } = useVForm();
	const toastHandler = useToast();

	const handleEditorStateChange = async (state: EditorState) => {
		try {
			const html = draftToHtml(convertToRaw(state.getCurrentContent()));
			setHtml(html.trim());
		} catch (e) {
			const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);

			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});
		}
		setEditorState(state);
	};

	const submitNote = useCallback(async () => {
		setIsUploading(true);
		try {
			await SinisterFollowUpService.addSinisterFollowupNote(sinisterId, html);
			setIsUploading(false);
			toastHandler.present({
				type: "success",
				position: "top-right",
				messageOrContent: "Followup adicionado com sucesso",
			});

			setEditorState(EditorState.createEmpty());
			setHtml("");
			setIsLoadingNotes(true);

			try {
				const notes = await SinisterFollowUpService.getSinisterFollowupNotes(sinisterId);
				setNotes(notes);
				setIsLoadingNotes(false);
			} catch (e) {
				setIsLoadingNotes(false);

				const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);

				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			}
		} catch (e) {
			setIsUploading(false);
			setIsLoadingNotes(false);

			const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);

			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});
		}
	}, [html]);

	const addContact = async (form: { email: string }) => {
		try {
			await addContactSchema.validate({ email: form.email });
			setIsUploading(true);

			try {
				await SinisterFollowUpService.addSinisterFollowupContact(sinisterId, form.email);
				setIsUploading(false);
				formRef?.current?.clearField("email");

				try {
					const data = await SinisterFollowUpService.getSinisterFollowupContacts(sinisterId);
					setContacts(data);
				} catch (e) {
					const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				}
			} catch (e) {
				setIsUploading(false);
				const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);

				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			}
		} catch (e) {
			const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);

			toastHandler.present({
				type: "error",
				position: "top-right",
				durationMs: 6000,
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});
		}
	};

	const onContactDeleteCallback = async () => {
		try {
			const data = await SinisterFollowUpService.getSinisterFollowupContacts(sinisterId);
			setContacts(data);
		} catch (e) {
			const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);

			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});
		}
	};


  const getDataFalowUp = async () => {
		setIsLoadingNotes(true);

		try {
			SinisterFollowUpService.getSinisterFollowupNotes(sinisterId).then((data) => {
				setIsLoadingNotes(false);
				setNotes(data);
			});
		} catch (e) {
			setIsLoadingNotes(false);
			const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);

			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});
		}

		setIsLoadingContacts(true);

		try {
			SinisterFollowUpService.getSinisterFollowupContacts(sinisterId).then((data) => {
				setIsLoadingContacts(false);
				setContacts(data);
			});
		} catch (e) {
			setIsLoadingContacts(false);

			const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);

			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});
		}
	};

  useEffect(() => {
		getDataFalowUp();
	}, []);



	// useEffect(() => {
	// 	setIsLoadingNotes(true);
	// 	setIsLoadingContacts(true);
	// 	SinisterFollowUpService.getSinisterFollowupNotes(sinisterId)
	// 		.then((data) => {
	// 			setIsLoadingNotes(false);
	// 			setNotes(data);
	// 		})
	// 		.catch(() => {
	// 			setIsLoadingNotes(false);
	// 		});

	// 	SinisterFollowUpService.getSinisterFollowupContacts(sinisterId)
	// 		.then((data) => {
	// 			setIsLoadingContacts(false);
	// 			setContacts(data);
	// 		})
	// 		.catch(() => {
	// 			setIsLoadingContacts(false);
	// 		});
	// }, []);

	return (
		<>
			<Box
				style={{
					width: "100%",
					overflowY: "visible",
					overflowX: "hidden",
				}}
			>
				<Editor
					editorState={editorState}
					toolbarClassName="toolbarClassName"
					wrapperClassName=" wrapperClassName"
					editorClassName="red-background"
					onEditorStateChange={handleEditorStateChange}
					toolbar={{
						options: ["inline", "fontSize", "textAlign", "list", "blockType", "emoji"],
						fontSize: {
							icon: "fontSize",
							options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
							inDropdown: true,
						},
					}}
				/>
				<hr style={{ opacity: "0.6", margin: "16px 0px 4px" }} />
				<Card
					style={{
						border: "1px solid grey",
						margin: "8px 0px",
						padding: "8px",
					}}
				>
					<Typography style={{ textAlign: "center", opacity: "0.8", marginBottom: "8px" }}>Lista de e-mails de notificação</Typography>
					<VForm
						id="formIdaddContact"
						placeholder="Your placeholder"
						onPointerEnterCapture={() => console.log("Pointer entered")}
						onPointerLeaveCapture={() => console.log("Pointer left")}
						ref={formRef}
						onSubmit={addContact}
					>
						<Grid style={{ height: "100%" }}>
							<VTextField fullWidth={false} label="E-mail" name="email" />
							<Button
								type={"submit"}
								color="primary"
								disableElevation
								variant={"contained"}
								disabled={isUploading || isLoadingNotes || isLoadingContacts}
								style={{
									width: "auto",
									marginLeft: "8px",
								}}
							>
								Adicionar
							</Button>
						</Grid>

						<div style={{ margin: "16px 0px", display: "flex", flexWrap: "wrap" }}>
							{contacts.map((contact) => {
								return <FollowUpContact id={contact.id} email={contact.email} onDeleteCallback={onContactDeleteCallback}></FollowUpContact>;
							})}
						</div>
					</VForm>
				</Card>

				<div style={{ textAlign: "end" }}>
					<Button
						color="primary"
						disableElevation
						variant={"contained"}
						onClick={submitNote}
						disabled={isUploading || html.length === 0 || html === "<p></p>"}
						style={{
							width: "auto",
						}}
					>
						{"Salvar Followup"}
					</Button>
				</div>

				{isLoadingNotes && (
					<div style={{ textAlign: "center", marginTop: "32px" }}>
						<CircularProgress variant="indeterminate" />
					</div>
				)}

				{!isLoadingNotes && notes.length === 0 && (
					<div style={{ textAlign: "center", fontSize: "14px", opacity: "0.6", marginTop: "32px" }}>
						<Typography>Não há followups para serem exibidos</Typography>
					</div>
				)}
				{!isLoadingNotes && notes.length > 0 && (
					<div style={{ textAlign: "center", fontSize: "14px", marginTop: "32px" }}>
						{notes.map((note) => {
							return (
								<FollowUpNote key={note.id} createdAt={new Date(note.createdAt)} userName={note.userName} htmlNote={note.note}></FollowUpNote>
							);
						})}
					</div>
				)}
			</Box>
		</>
	);
};

const FollowUpNote = ({ createdAt, htmlNote, userName }: { createdAt: Date; userName: string; htmlNote: string }) => {
	const formattedDate: string = format(createdAt, "dd/MM/yyyy");
	const unformattedTime: string = format(createdAt, "HH mm");
	const formattedTime: string = `${unformattedTime.split(" ")[0]}h${unformattedTime.split(" ")[1]}m`;

	return (
		<Card
			style={{
				marginTop: "16px",
				minHeight: "75%",
				padding: "0px",
			}}
		>
			<div style={{ backgroundColor: "#f0f1f1", padding: "8px", textAlign: "start" }}>
				<Typography variant="subtitle2" color={"text.primary"}>
					Data: <strong>{`${formattedDate} ${formattedTime}`}</strong>
				</Typography>
				<Typography variant="subtitle2" color={"text.primary"}>
					Usuário: <strong>{userName}</strong>
				</Typography>
			</div>
			<Card style={{ margin: "8px", padding: "4px", textAlign: "start" }} dangerouslySetInnerHTML={{ __html: htmlNote }}></Card>
		</Card>
	);
};

const FollowUpContact = ({ id, email, onDeleteCallback }: { id: string; email: string; onDeleteCallback: () => void }) => {
	const [isLoading, setIsLoading] = useState(false);
	const toastHandler = useToast();

	const removeContact = async () => {
		setIsLoading(true);

		try {
			await SinisterFollowUpService.removeSinisterFollowupContact(id);
			setIsLoading(false);
			onDeleteCallback();

			toastHandler.present({
				type: "success",
				position: "top-right",
				messageOrContent: "Contato removido com sucesso",
			});
		} catch (e) {
			setIsLoading(false);
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: "Erro ao remover email de contato!",
			});
		}
	};

	return (
		<>
			{!isLoading && (
				<div
					key={id}
					style={{ margin: "4px", display: "flex", alignItems: "center", borderRadius: "5px", backgroundColor: "whitesmoke", minHeight: "40px" }}
				>
					<span style={{ padding: "8px", fontWeight: "bold", fontSize: "16px" }}>{email}</span>
					<ClickableIcon style={{ backgroundColor: "red", borderRadius: "5px" }} onClick={removeContact}>
						remove
					</ClickableIcon>
				</div>
			)}
			{isLoading && (
				<div key={id} style={{ margin: "4px", display: "flex", alignItems: "center", borderRadius: "5px", backgroundColor: "whitesmoke" }}>
					<CircularProgress></CircularProgress>
				</div>
			)}
		</>
	);
};

const ClickableIcon = styled(Icon)`
	& {
		transition: opacity 0.25s linear;
		cursor: default;
	}

	&:hover {
		opacity: 0.5;
		cursor: pointer;
	}
`;
