import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './App';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

    <App />

);