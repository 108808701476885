
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@mui/material';


const typeMessage: ITypeMessage[] = [
    {
        type: 'error',
        title: 'Erro',
        icon: 'cancel_Outlined_Icon',
        color: '#e87b9e'
    },
    {
        type: 'alert',
        title: 'Atenção',
        icon: 'info_Outlined_Icon',
        color: '#ffc107'
    },
    {
        type: 'success',
        title: 'Sucesso',
        icon: 'check_circle_Outline_Icon',
        color: '#39c463'
    },
    {
        type: 'info',
        title: 'Informação',
        icon: 'error_outline_Outlined_Icon',
        color: '#7924c7 '
    },
];

interface ITypeMessage {
    title: string;
    icon: string;
    color: string;
    type: string;
}

interface IModalInstallmentProps {
    widthModfy?: string;
    ITitle?: string;
    IMessage: React.ReactNode[];
    textButtonConfirm?: string;
    showButtonCancel?: boolean;
    isOpenModal: boolean;
    messageType: string;
    onclickConfirm?: () => void;
    isCloseModal?: () => void;
    onCloseModal?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
}

export const ModalInstallment: React.FC<IModalInstallmentProps> = ({ ITitle, IMessage, textButtonConfirm,
    showButtonCancel, isOpenModal, messageType, isCloseModal, onclickConfirm, onCloseModal, widthModfy }) => {

    const setTypeMessage = () => {
        switch (messageType) {
            case 'error':
                return typeMessage[0];
            case 'alert':
                return typeMessage[1];
            case 'success':
                return typeMessage[2];
            case 'info':
                return typeMessage[3];
            default: return typeMessage[0];
        }
    }

    const messageStyle: ITypeMessage = setTypeMessage();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: widthModfy?.trim() ? widthModfy: 'auto',
        height: "520px !important",
        overflowY: "scroll",
        bgcolor: 'background.paper',
        border: '1px solid #605e5e',
        boxShadow: 24,
        p: 2,
        borderRadius: '6px'
    };

    return (
        <div>
            <Modal
                open={isOpenModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={onCloseModal}
            >
                <Box sx={style}>
                    <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Icon style={{ color: `${messageStyle.color}`, fontSize: "3rem", fontWeight: 100 }}>
                            {messageStyle.icon}
                        </Icon>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            color={`${messageStyle.color}`}
                        >
                            {ITitle}
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                        {IMessage}
                    </DialogContent>
                    <DialogActions
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Button
                            color="primary"
                            disableElevation
                            variant={"contained"}
                            startIcon={< Icon > checkIcon</Icon >}
                            onClick={onclickConfirm}
                        >
                            {textButtonConfirm}
                        </Button>
                        {showButtonCancel && (
                            <Button
                                onClick={isCloseModal}
                                color="primary"
                                disableElevation
                                variant={"outlined"}
                                startIcon={< Icon > closeIcon</Icon >}
                            >
                                Cancelar
                            </Button>
                        )}
                    </DialogActions>
                </Box>
            </Modal>
        </div>
    );
}
