import { Box, Button, Icon, Paper, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";

interface IToobarProposalProps {
    showButtonSave?: boolean;
    showButtonCancel?: boolean;
    showButtonSinister?: boolean;
    showButtonCertificate?: boolean;
    showButtonEndorsement?: boolean;
    showButtonRenovate?: boolean;
    showButtonFiles?: boolean;
    showButtonback?: boolean;
    showButtonInstallment?: boolean;
    showButtonContract?: boolean;
    showButtonFinancial?: boolean;
    showButtonCancellingFollowup?: boolean;
    showButtonEdit?: boolean;

    showButtonSaveLoading?: boolean;
    showButtonCancelLoading?: boolean;
    showButtonSinisterLoading?: boolean;
    showButtonCertificateLoading?: boolean;
    showButtonEndorsementLoading?: boolean;
    showButtonRenovateLoading?: boolean;
    showButtonFilesLoading?: boolean;
    showButtonBackLoading?: boolean;
    showButtonInstallmentLoading?: boolean;
    showButtonContractLoading?: boolean;
    showButtonFinancialLoading?: boolean;
    showButtonCancellingFollowupLoading?: boolean;
    showButtonEditLoading?: boolean;
    isExpired?: boolean;

    clickSave?: () => void;
    clickCancel?: () => void;
    clickSinister?: () => void;
    clickCertificate?: () => void;
    clickEndorsement?: () => void;
    clickRenovate?: () => void;
    clickBack: () => void;
    clickInstallment?: () => void;
    clickFinancial?: () => void;
    clickFiles?: () => void;
    clickContract?: () => void;
    clickFollowup?: () => void;
    clickEdit?: () => void;
}


export const ToobarProposalDetails: React.FC<IToobarProposalProps> = ({
    showButtonSave = true,
    showButtonCancel = true,
    showButtonSinister = true,
    showButtonCertificate = true,
    showButtonEndorsement = true,
    showButtonRenovate = true,
    showButtonback = true,
    showButtonInstallment = true,
    showButtonContract = true,
    showButtonFinancial = true,
    showButtonFiles = true,
    showButtonCancellingFollowup,
    showButtonEdit = false,
    showButtonSaveLoading = false,
    showButtonCancelLoading = false,
    showButtonSinisterLoading = false,
    showButtonCertificateLoading = false,
    showButtonEndorsementLoading = false,
    showButtonRenovateLoading = false,
    showButtonBackLoading = false,
    showButtonInstallmentLoading = false,
    showButtonContractLoading = false,
    showButtonFinancialLoading = false,
    showButtonFilesLoading = false,
    showButtonCancellingFollowupLoading = false,
    showButtonEditLoading = false,
    isExpired = false,
    clickSave,
    clickCancel,
    clickSinister,
    clickCertificate,
    clickEndorsement,
    clickRenovate,
    clickBack,
    clickInstallment,
    clickFinancial,
    clickFiles,
    clickContract,
    clickFollowup,
    clickEdit,

}) => {
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            height={theme.spacing(5)}
            component={Paper}
        >

            {(showButtonback && !showButtonBackLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    onClick={clickBack}
                    startIcon={<Icon>keyboard_double_arrow_left_icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Voltar
                    </Typography>
                </Button>
            )}

            {showButtonBackLoading && (
                <Skeleton width={110} height={60} ></Skeleton>
            )}

            {(showButtonCancel && !showButtonCancelLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickCancel}
                    startIcon={<Icon>power_settings_new_outlined_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Cancelar
                    </Typography>
                </Button>
            )}

            {showButtonCancelLoading && (
                <Skeleton width={110} height={60} ></Skeleton>
            )}

            {(showButtonSinister && !showButtonSinisterLoading && !isExpired) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickSinister}
                    startIcon={<Icon>notifications_outlined_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Abrir Sinistro
                    </Typography>
                </Button>
            )}

            {showButtonSinisterLoading && (
                <Skeleton width={110} height={60} ></Skeleton>
            )}

            {(showButtonCertificate && !showButtonCertificateLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickCertificate}
                    startIcon={<Icon>verified_outlinedIcon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Certificado
                    </Typography>
                </Button>
            )}

            {showButtonCertificateLoading && (
                <Skeleton width={110} height={60} ></Skeleton>
            )}

            {(showButtonEndorsement && !showButtonEndorsementLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickEndorsement}
                    startIcon={<Icon>file_download_outlined</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Endosso
                    </Typography>
                </Button>
            )}

            {(showButtonRenovate && !showButtonRenovateLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickRenovate}
                    startIcon={<Icon>replay_outlined_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Renovar
                    </Typography>
                </Button>
            )}

            {showButtonRenovateLoading && (
                <Skeleton width={110} height={60} ></Skeleton>
            )}

            {(showButtonInstallment && !showButtonInstallmentLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickInstallment}
                    startIcon={<Icon>grading_outlined_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Parcelas
                    </Typography>
                </Button>
            )}

            {showButtonInstallmentLoading && (
                <Skeleton width={110} height={60} ></Skeleton>
            )}

            {(showButtonFinancial && !showButtonFinancialLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickFinancial}
                    startIcon={<Icon>format_list_numbered_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Lancamentos Financeiro
                    </Typography>
                </Button>
            )}

            {showButtonFinancialLoading && (
                <Skeleton width={110} height={60} ></Skeleton>
            )}

            {(showButtonFiles && !showButtonFilesLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickFiles}
                    startIcon={<Icon>attach_file_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Arquivos
                    </Typography>
                </Button>
            )}

            {showButtonFilesLoading && (
                <Skeleton width={110} height={60} ></Skeleton>
            )}

            {(showButtonContract && !showButtonContractLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickContract}
                    startIcon={<Icon>picture_as_pdf_outlined_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Contrato
                    </Typography>
                </Button>
            )}

            {showButtonContractLoading && (
                <Skeleton width={110} height={60} ></Skeleton>
            )}

            {(showButtonCancellingFollowup && !showButtonCancellingFollowupLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickFollowup}
                    startIcon={<Icon>format_list_numbered_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Followup Cancelamento
                    </Typography>
                </Button>
            )}

            {(showButtonEdit && !showButtonEditLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickEdit}
                    startIcon={<Icon>edit</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Alterar
                    </Typography>
                </Button>
            )}

        </Box>
    );
};