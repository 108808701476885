import React, { useEffect } from "react";
import { Box, Button, Divider, Grid, List, ListItem, Typography, Icon } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "../../../shared/components";
import { globalFormatCurrencyBRL, globalMaskReal } from "../../../shared/utils";

interface ModalResultAnalysisProps {
	status: string;
	limitSerasa?: number;
	plan: Array<any>;
	propFinally: string;
	chargesBaseCalc: number;
	assuranceBaseCalc: number;
	idcreate: string;
	smDown: boolean;
	isOpenModal: boolean;
	isCloseModal: () => void;
	Rows: any;
}

const ModalResultAnalysisAll: React.FC<ModalResultAnalysisProps> = ({
	status,
	plan,
	propFinally,
	chargesBaseCalc,
	assuranceBaseCalc,
	idcreate,
	smDown,
	isOpenModal,
	isCloseModal,
	Rows,
}) => {
	const [limitSerasa, setLimitSerasa] = React.useState<string>("0");
	const navigate = useNavigate();

	const generateAdditionalListItems = (row: any) => [
		{ label: "Sem fiador ou caução" },
		{ label: "Assessoria Jurídica gratuita." },
		{ label: "Contratação 100% digital" },
		{ label: "Recebimento Inadimplência" },
		{
			label: "Limite de saída",
			value:
				globalFormatCurrencyBRL(
					((row?.planId.includes("__charges") ? chargesBaseCalc : assuranceBaseCalc) * parseInt(row.coverage || "0")).toString()
				) + " absorvido do LMI",
		},
	];

	useEffect(() => {
		if (isOpenModal && Rows && Rows.creditLimit != null) {
			setLimitSerasa(Rows.creditLimit.toString());
		} else if (isOpenModal) {
			setLimitSerasa("0"); // Valor padrão para evitar erros
		}
	}, [isOpenModal, Rows]);

	const renderListItems = (items: { label: string; value?: string }[]) =>
		items.map((item, index) => (
			<ListItem key={index} sx={{ justifyContent: "left", padding: "1px" }}>
				<Typography variant="subtitle1" sx={{ color: "#39c463" }}>
					<CheckIcon fontSize="small" />
				</Typography>
				<Typography variant="subtitle2" sx={{ color: "text.secondary", marginLeft: "8px" }}>
					{item.label} {item.value || ""}
				</Typography>
			</ListItem>
		));

	return (
		<ModalContext
			isCloseModal={isCloseModal}
			isOpenModal={isOpenModal}
			messageType={["issued", "awaiting_biometria", "pre_approved"].includes(status ?? "") ? "success" : "error"}
			showButtonCancel={false}
			showButtonConfirm={false}
			textButtonConfirm={status === "issued" ? "Contratar depois" : "Fechar"}
			onclickConfirm={isCloseModal}
			overrideWidthValue={status != "refused" ? "md" : "30%"}
			enableBackdropClose={false}
			ITitle={
				status === "issued"
					? "Análise aprovada"
					: status === "awaiting_biometria"
					? "Aprovada Aguardando Biometria"
					: status === "pre_approved" && (limitSerasa ?? "0") !== "0"
					? "Análise Pré - Aprovada"
					: status === "pending"
					? "Análise Pendente"
					: "Análise Recusada"
			}
			IMessage={[
				<Grid container item sx={{ textAlign: "center", justifyContent: "center" }} display={"flex"} flexDirection={smDown ? "column" : "row"} gap={1}>
					{status === "pre_approved" && (
						<Typography variant="h5" color="primary">
							{limitSerasa.toString() !== "0" ? `Limite de crédito: R$ ${globalMaskReal(limitSerasa)}` : ""}
						</Typography>
					)}
					{status === "pending" && (
						<Box display={"flex"} flexDirection={"column"}>
							<Typography variant="h5" color="primary">
								Ocorreu um erro internamente.
							</Typography>
							<Typography variant="h6" color="secondary" fontWeight={400}>
								Não se preocupe, sua analise ficará salva e você poderá tentar novamente mais tarde.
							</Typography>
						</Box>
					)}

					{status !== "refused" && status !== "pending" && (
						<Grid
							container
							item
							sm={12}
							sx={{
								border: "none",
								display: "flex",
								justifyContent: "center",
							}}
							padding={2}
						>
							{plan?.map((row) => (
								<Grid
									container
									item
									xs={12}
									md={4}
									margin={1}
									sx={{
										border: "none",
										display: "flex",
										justifyContent: "center",
									}}
									key={row.id}
									padding={1}
								>
									<Box
										sx={{
											width: "100%",
											bgcolor: "background.paper",
											borderRadius: "80px 2px 80px 2px",
											padding: 4,
											boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
										}}
									>
										<Typography variant="h5" sx={{ color: "text.secondary" }}>
											{row?.planId.includes("__charges") ? `${row.plan.description} +` : row?.plan?.description}
										</Typography>
										<Typography variant="h5" sx={{ color: "#7924c7", fontWeight: 700, fontFamily: "arial" }}>
											R$
											{propFinally === "residential"
												? globalMaskReal(
														(
															(row?.planId.includes("__charges") ? chargesBaseCalc : assuranceBaseCalc) *
															(parseFloat(row.residentialFee) / 100.0)
														).toFixed(2)
												  )
												: globalMaskReal(
														(
															(row?.planId.includes("__charges") ? chargesBaseCalc : assuranceBaseCalc) *
															(parseFloat(row.commercialFee) / 100.0)
														).toFixed(2)
												  )}
											<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
												/mes
											</Typography>
										</Typography>
										<Button
											color="primary"
											disableElevation
											variant={"contained"}
											startIcon={status === "issued" ? <Icon>checkIcon</Icon> : <Icon>fingerprint</Icon>}
											onClick={
												status === "issued"
													? () => navigate(`/analises/contratacao?analise=${idcreate}&plano=${row.planId}`)
													: () => navigate("/analises/")
											}
											sx={{ mt: 1, mb: 1 }}
										>
											{status === "issued" ? "Contratar agora" : "Aguardando biometria"}
										</Button>
										<Divider />
										<nav aria-label="secondary mailbox folders">
											<List>
												<ListItem
													sx={{
														textAlign: "center",
														justifyContent: "center",
														display: "flex",
														flexDirection: "column",
														padding: "1px",
													}}
												>
													<Typography variant="caption">Taxa Garantti</Typography>
													<Typography variant="subtitle1">
														{propFinally === "residential" ? row.residentialFee : row.commercialFee} %
													</Typography>
												</ListItem>
												<ListItem
													sx={{
														textAlign: "center",
														justifyContent: "center",
														padding: "1px",
													}}
												>
													<Typography variant="subtitle1" sx={{ fontFamily: "cursive", color: "#1ac266" }}>
														LMI {row.lmi} x
													</Typography>
												</ListItem>
												<ListItem
													sx={{
														textAlign: "center",
														justifyContent: "center",
														display: "flex",
														flexDirection: "column",
														padding: "1px",
													}}
												>
													<Typography variant="caption">Valor LMI</Typography>
													<Typography variant="subtitle1" sx={{ fontFamily: "cursive" }}>
														R${" "}
														{globalMaskReal(
															(
																(row?.planId.includes("__charges") ? chargesBaseCalc : assuranceBaseCalc) *
																parseInt(row.lmi) *
																1.0
															).toFixed(2)
														)}
													</Typography>
													<Typography sx={{ color: "#7924c7", fontWeight: 400, fontFamily: "arial" }}>
														{row?.planId.includes("__charges") ? " (aluguel + encargos)" : "( aluguel )"}
													</Typography>
												</ListItem>
												{renderListItems(generateAdditionalListItems(row))}
											</List>
										</nav>
									</Box>
								</Grid>
							))}
						</Grid>
					)}
					<Grid
						container
						item
						sm={12}
						sx={{
							border: "none",
							display: "flex",
							justifyContent: "center",
						}}
						padding={2}
					>
						<Button color="primary" disableElevation variant={"outlined"} startIcon={<Icon>checkIcon</Icon>} onClick={() => navigate("/analises/")}>
							{status === "issued" ? "Contratar depois" : "Fechar"}
						</Button>
					</Grid>
				</Grid>,
			]}
		/>
	);
};

export default ModalResultAnalysisAll;
