import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { IVFormErros, useVForm, VForm, VTextField } from "../../../../../shared/forms";
import { useToast } from "../../../../../shared/hooks";
import { IFormInstallmentCancel, ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";
import * as yup from "yup";
import { returnMessageError } from "../../../../../shared/utils/returnMessageError";

interface ModalCancelInstallmentsProps {
	isSuperAdmin: boolean;
	idInstallmentCancel: string;
	isOpenModal: boolean;
	isClose: () => void;
}

export const ModalCancelInstallments: React.FC<ModalCancelInstallmentsProps> = ({ isSuperAdmin, idInstallmentCancel, isClose, isOpenModal }) => {
	const { formRef: formRefInstallment } = useVForm();
	const toastHandler = useToast();

	const formValidationSchemaCancelInstallment: yup.SchemaOf<IFormInstallmentCancel> = yup.object().shape({
		obs: yup.string().required("Campo Obrigatório"),
	});

	const handleSaveInstallmentCancel = (dados: IFormInstallmentCancel) => {
		formValidationSchemaCancelInstallment
			.validate(dados, { abortEarly: false })
			.then((dadosvalidados) => {
				ProposalService.saveCancelInstallment(dadosvalidados, idInstallmentCancel).then((result: string | Error) => {
					if (result instanceof Error) {
						const titleMessage = "Alerta!!";
						const contentMessage = "Erro: " + result?.message;

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					} else {
						toastHandler.present({
							type: "success",
							position: "top-right",
							durationMs: 3000,
							messageOrContent: <ToastContent title={"SUCESSO!"} content={"Parcela cancelada com sucesso."} />,
						});
                        isClose();
					}
				});
			})
			.catch((errors: yup.ValidationError) => {
				const validationErros: IVFormErros = {};
				errors.inner.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRefInstallment.current?.setErrors(validationErros);
				const { titleMessage, contentMessage } = returnMessageError(errors);
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			});
	};

	return (
		<ModalContext
			isCloseModal={isClose}
			isOpenModal={isOpenModal}
			messageType={"question"}
			showButtonCancel={false}
			showButtonConfirm={false}
			showTitleIcon={false}
			textButtonCancel="Fechar"
			enableBackdropClose={true}
			overrideWidthValue={"50%"}
			overrideHeightValue={"auto"}
			ITitle={"Deseja cancelar a parcela ?"}
            ISubTitle={idInstallmentCancel}
			IMessage={[
				<Box style={{ width: "100%" }}>
					<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
						<VForm
							id="formIdSaveInstallmentCancel"
							placeholder="Your placeholder"
							onPointerEnterCapture={() => console.log("Pointer entered")}
							onPointerLeaveCapture={() => console.log("Pointer left")}
							ref={formRefInstallment}
							onSubmit={handleSaveInstallmentCancel}
						>
							<Grid container item direction="column" padding={1} spacing={2}>
								<Grid container item direction="row" spacing={2}>
									<Grid item xs={12}>
										<VTextField fullWidth label="Motivo do cancelamento" name="obs"></VTextField>
									</Grid>
								</Grid>
							</Grid>

							<Grid
								container
								item
								sm={12}
								sx={{ border: "none", display: "flex", justifyContent: "center" }}
								display={"flex"}
								padding={2}
								gap={2}
							>
								<Button
									color="error"
									disableElevation
									variant={"contained"}
									startIcon={<Icon> checkIcon</Icon>}
									onClick={() => formRefInstallment.current?.submitForm()}
								>
									{"Cancelar parcela"}
								</Button>
								<Button color="primary" disableElevation variant={"outlined"} startIcon={<Icon> closeIcon</Icon>} onClick={isClose}>
									{"Sair"}
								</Button>
							</Grid>
						</VForm>
					</Typography>
				</Box>,
			]}
		/>
	);
};
