import { string } from 'yup/lib/locale';
import { Environment } from '../../../environment';
import { Api } from "../axios-config";

export interface IListagemBank {
    id: string,
    description: string,
    status: string,
}

type TBankTotalCount = {
    data: IListagemBank[];
    totalCount: number;
}


const getAll = async (page = 1, filter = ''): Promise<TBankTotalCount | Error> => {
    try {
        const urlRelativa = `/bank?&nome_like=${filter}`;
        const { data, headers } = await Api().get(urlRelativa);
        if (data.results) {
            return {
                data: data.results,
                totalCount: data.total,
            };
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

const getById = async (id: number): Promise<IListagemBank | Error> => {
    try {
        const { data } = await Api().get(`/bank/${id}`);

        if (data) {
            return data;
        }
        return new Error('Erro ao consultar o registro.');

    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};

const create = async (dados: Omit<IListagemBank, 'id'>): Promise<string | Error> => {
    try {
        const { data } = await Api().post<IListagemBank>('/bank', dados);

        if (data) {
            return data.id;
        }
        return new Error('Erro ao criar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
    }
};

const updateById = async (id: string, dados: IListagemBank): Promise<void | Error> => {
    try {
        await Api().put(`/bank/${id}`, dados);
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    }
};

const deleteById = async (id: string): Promise<void | Error> => {
    try {
        await Api().delete(`/bank/${id}`);
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
    }
};


export const bankService = {
    getAll,
    getById,
    create,
    updateById,
    deleteById
};