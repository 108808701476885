import { PermissionActionsEnum, PermissionRolesEnum } from '../enums/profiles';
import {useProfile} from '../../context/ProfileContext';
import React from 'react';
import {PermissionRolesType} from '../types';

type ProfileGuardProps = {
    children: React.ReactNode;
    requiredRole: PermissionRolesEnum
    requiredAction: PermissionActionsEnum
}

export const ProfileGuard = ({ children, requiredAction, requiredRole }: ProfileGuardProps): JSX.Element | null => {
    const { permissions } = useProfile()

    if (!validation(permissions, requiredRole, requiredAction)) {
      return null
    }

    return (
        <>
            { children }
        </>
    )
}

export const checkByProfileGuard = (requiredRole: PermissionRolesEnum, requiredAction: PermissionActionsEnum ): boolean => {
  const { permissions, isPermissionInitialized } = useProfile()

  if (!isPermissionInitialized) return true

  return validation(permissions, requiredRole, requiredAction);
}

const validation = (permissions: PermissionRolesType | null, requiredRole: PermissionRolesEnum, requiredAction: PermissionActionsEnum): boolean =>  {
  if (!permissions) return false;

  const foundRole = permissions[requiredRole];

  if (!foundRole || !foundRole[requiredAction]) return false;

  return true;
}