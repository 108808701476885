import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	Button,
	Grid,
	Icon,
	IconButton,
	InputAdornment,
	LinearProgress,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ModalContext, ToastContent, Toolbar } from "../../../shared/components";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { useDebounce } from "../../../shared/hooks";
import { Environment } from "../../../shared/environment";
import moment from "moment";
import "moment/locale/pt-br";
import { checkByProfileGuard, ProfileGuard } from "../../../shared/guards/ProfileGuard";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { ListSearchParams } from "../../analysis/types/list-search-params.type";
import { ToolbarGridProvision } from "../../../shared/components/toolbarGridProvision/toolbarGridProvision";
import { IBorderoPayment, IBorderoProvision, IFormCancel, IProvisionList, ProvisionService } from "../../../shared/services/api/provisoes/ProvisionService";
import { globalBackground, globalColorText, globalTranslate } from "../../../shared/utils";
import { IVFormErros, VForm, VTextField, useVForm } from "../../../shared/forms";
import * as yup from "yup";
import { RowMenuBordero } from "./RowMenuBordero";
import { useToast } from "../../../shared/hooks";
import { TFilterFields } from "../../../shared/types/filterfields.type";
import { useProfile } from "../../../context/ProfileContext";

const removeMask = (value: string) => {
	return value
		.replace(/\D/g, "")
		.replace(/[^0-9]/g, "")
		.replace(/(\d{1,2})$/, ".$1");
};

export const FinancialBorderoPresentation = () => {
	const filterAnalysis: TFilterFields[] = [
		{ name: "documento", displayName: "Documento" },
		{ name: "nome", displayName: "Nome" },
	];
	const optionfilterPeriod: TFilterFields[] = [
		{ name: "criacao", displayName: "Criação" },
		{ name: "vencimento", displayName: "Vencimento" },
		{ name: "provisionado", displayName: "Provisionamento" },
	];
	const [searchParams, setSearchParams] = useSearchParams();
	const { debounce } = useDebounce();
	const [rows, setRows] = useState<IProvisionList[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [modalPayment, setModalPayment] = useState(false);
	const [modalProvision, setModalProvision] = useState(false);
	const [modalReProvision, setModalReProvision] = useState(false);
	const [modalCancel, setModalCancel] = useState(false);
	const { formRef } = useVForm();
	const [rowSelect, setRowSelect] = useState<IProvisionList>(); // linha selecionada
	const [idBordero, setIdBordero] = useState("");
	const [paymentBordero, setPaymentBordero] = useState("");
	const [paymentValue, setPaymentValue] = useState("0");
	const [provisionBordero, setProvisionBordero] = useState("");
	const [pesquisar, setPesquisar] = useState(true);
	const theme = useTheme();
	const toastHandler = useToast();
	const { isSuperAdmin, permissionLevel } = useProfile();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const [refreshList, setRefreshList] = useState(false);
	const hasViewPermission = useMemo(() => {
		return checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.View);
	}, []);

	const filterInitialParams: ListSearchParams = useMemo(() => {
		return {
			tipo: "documento",
			status: "Todos",
			origem: "Todos",
			typePeriod: "criacao",
			dataIni: moment().startOf("month").subtract(24, "months").format("YYYY-MM-DD"),
			dataFim: moment().endOf("month").format("YYYY-MM-DD"),
		};
	}, []);

	const handleBusca = () => {
		setSearchParams(
			{
				busca,
				pagina: String(1),
				tipo: tipoBordero,
				status: statusBordero,
				typePeriod,
				dataini: dataIniBordero,
				datafim: dataFimBordero,
				clientFilter: clientFilter,
			},
			{ replace: true }
		);
		setPesquisar(!pesquisar);
	};

	useEffect(() => {
		setSearchParams(
			{
				...searchParams,
				dataini: filterInitialParams.dataIni,
				datafim: filterInitialParams.dataFim,
				status: filterInitialParams.status,
			},
			{ replace: true }
		);
	}, []);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const busca = useMemo(() => {
		return searchParams.get("busca") || "";
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get("pagina") || "1");
	}, [searchParams]);

	const tipoBordero = useMemo(() => {
		return searchParams.get("tipo") || "";
	}, [searchParams]);

	const statusBordero = useMemo(() => {
		return searchParams.get("status") || "";
	}, [searchParams]);

	const origemBordero = useMemo(() => {
		return searchParams.get("origem") || "";
	}, [searchParams]);

	const typePeriod = useMemo(() => {
		return searchParams.get("typePeriod") || "";
	}, [searchParams]);

	const dataIniBordero = useMemo(() => {
		return searchParams.get("dataini") || "";
	}, [searchParams]);

	const dataFimBordero = useMemo(() => {
		return searchParams.get("datafim") || "";
	}, [searchParams]);

	const clientFilter = useMemo(() => {
		return searchParams.get("clientFilter") || "";
	}, [searchParams]);

	const managerId = useMemo(() => {
		return searchParams.get("managerId") || "";
	}, [searchParams]);

	useEffect(() => {
		setSearchParams(
			{
				...searchParams,
				dataini: filterInitialParams.dataIni,
				datafim: filterInitialParams.dataFim,
				tipo: filterInitialParams.tipo,
				typePeriod: filterInitialParams.typePeriod,
				status: filterInitialParams.status,
				origembordero: filterInitialParams.origem,
			},
			{ replace: true }
		);
	}, []);

	const formValidationSchemaCancel: yup.SchemaOf<IFormCancel> = yup.object().shape({
		canceledReason: yup.string().required("Campo Obrigatório"),
	});

	const handleBorderoPayment = (dados: IBorderoPayment) => {
		setIsLoading(true);
		debounce(() => {
			//gravar valores deve se remover a mascar
			dados.paymentValue = removeMask(dados.paymentValue);
			ProvisionService.borderoPayment(idBordero, dados).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					alert(result.message);
				} else {
					toastHandler.present({
						type: "success",
						position: "top-right",
						messageOrContent: <ToastContent title={"Sucesso"} content={"Pagamento registrado com sucesso"} />,
					});
					setRefreshList(true);
				}
			});
		});
	};

	const handleBorderoProvision = (dados: IBorderoProvision) => {
		setIsLoading(true);
		debounce(() => {
			ProvisionService.borderoProvision(idBordero, dados).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					alert(result.message);
				} else {
					toastHandler.present({
						type: "success",
						position: "top-right",
						messageOrContent: <ToastContent title={"Sucesso"} content={"Fatura Gerada com sucesso"} />,
					});
					setRefreshList(true);
				}
			});
		});
	};

	const handleCancel = (dados: IFormCancel) => {
		formValidationSchemaCancel
			.validate(dados, { abortEarly: false })
			.then((dadosvalidados) => {
				try {
					const dadosForm: IFormCancel = {
						canceledReason: dadosvalidados.canceledReason,
					};

					ProvisionService.borderoCancel(idBordero, dadosForm).then((result) => {
						toastHandler.present({
							type: "success",
							position: "top-right",
							messageOrContent: <ToastContent title={"Sucesso"} content={"Cancelado com sucesso"} />,
						});
						setRefreshList(true);
					});
				} catch (e: any) {
					toastHandler.present({
						type: "error",
						messageOrContent: e.message,
						position: "top-center",
					});
				} finally {
					setRefreshList(true);
				}
			})
			.catch((errors: yup.ValidationError) => {
				const validationErros: IVFormErros = {};
				errors.inner.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRef.current?.setErrors(validationErros);
			});

		//handleOpenModal()
	};

	const goToEdit = (id: string, e: any) => {
		e.preventDefault();
		setIdBordero(id);
		//handleOpenModalAproved(id);
	};

	const goTo = (option: string, id: string, e: any, row: IProvisionList) => {
		e.preventDefault();
		setIdBordero(id);
		setRowSelect(row);

		if (option == "pagar") {
			setModalPayment(true);
			//handleOpenModalAproved(id);
		}
		if (option == "provisionar") {
			setModalReProvision(false);
			setModalProvision(true);
			// handleOpenModalRefused();
		}
		if (option == "cancelar") {
			setModalCancel(true);
			// handleOpenModalCanceled();
		}

		if (option == "reprovisionar") {
			setModalProvision(false);
			setModalReProvision(true);
			// handleOpenModalContested();
		}
	};

	useEffect(() => {
		if (hasViewPermission) {
			setIsLoading(true);
			debounce(() => {
				ProvisionService.getAllBordero(pagina, busca, tipoBordero, statusBordero, typePeriod, dataIniBordero, dataFimBordero, clientFilter).then(
					(result) => {
						setIsLoading(false);
						setRefreshList(false);
						if (result instanceof Error) {
							alert(result.message);
						} else {
							console.log(result);
							setTotalCount(result.totalCount);
							setRows(result.data);
						}
					}
				);
			});
		}
	}, [pesquisar, statusBordero, pagina, refreshList]);

	const handleExtractPDF = (id: string) => {
		setIsLoading(true);
		//setIsExtractPDF(true);
		debounce(() => {
			ProvisionService.getBorderoPDF(id).then((result) => {
				setIsLoading(false);
				//setIsExtractPDF(false);
				if (result instanceof Error) {
					//alert(result.message);
				} else {
					//setModalDetail(true)
				}
			});
		});
	};

	return (
		<LayoutBaseDePagina
			titulo="Bordero"
			subtitulo="Listagem de Borderos"
			barraDeFerramentas={
				<Toolbar
					filterInitialParams={filterInitialParams}
					filterFieldJson={filterAnalysis}
					textoButtonNew="Nova Análise"
					showInputBusca={true}
					showButtonSearch={false}
					showInputOrigem={false}
					onClickButtonSearch={handleBusca}
					showFilterField={true}
					onChangeFilterField={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: retorno,
								status: statusBordero,
								typePeriod,
								dataini: dataIniBordero,
								datafim: dataFimBordero,
								clientFilter: clientFilter,
							},
							{ replace: true }
						)
					}
					onChangeTextBuscaGrid={(texto) => {
						setSearchParams(
							{
								busca: texto,
								pagina: String(1),
								tipo: tipoBordero,
								status: statusBordero,
								typePeriod,
								dataini: dataIniBordero,
								datafim: dataFimBordero,
								clientFilter: clientFilter,
							},
							{ replace: true }
						);
					}}
					showInputClientFilter={checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Filter)}
					showPeriodo={checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Filter)}
					showButtonNew={false}
					showInputStatus={false}
					onChangeStatus={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoBordero,
								status: retorno,
								dataini: dataIniBordero,
								datafim: dataFimBordero,
								clientFilter: clientFilter,
							},
							{ replace: true }
						)
					}
					onChangeOrigem={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoBordero,
								status: statusBordero,
								typePeriod,
								origem: retorno,
								dataini: dataIniBordero,
								datafim: dataFimBordero ? dataFimBordero : moment().endOf("month").format("YYYY-MM-DD"),
								clientFilter: clientFilter,
							},
							{ replace: true }
						)
					}
					onChangeDataIni={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoBordero,
								status: statusBordero,
								typePeriod,
								dataini: retorno,
								datafim: dataFimBordero,
								clientFilter: clientFilter,
							},
							{ replace: true }
						)
					}
					onChangeDataFim={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoBordero,
								status: statusBordero,
								typePeriod,
								dataini: dataIniBordero ? dataIniBordero : moment().startOf("month").format("YYYY-MM-DD"),
								datafim: retorno,
								clientFilter: clientFilter,
							},
							{ replace: true }
						)
					}
					onChangeClientFilter={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoBordero,
								status: statusBordero,
								typePeriod,
								dataini: dataIniBordero,
								datafim: dataFimBordero,
								clientFilter: retorno,
							},
							{ replace: true }
						)
					}
					showInputManagerFilter={false}
					showTypePeriod={true}
					optionsTypePeriod={optionfilterPeriod}
					onChangeTypePeriod={(retorno: string) => {
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoBordero,
								status: statusBordero,
								typePeriod: retorno,
								dataini: dataIniBordero ? dataIniBordero : moment().startOf("month").format("YYYY-MM-DD"),
								datafim: dataFimBordero,
								clientFilter: clientFilter,
							},
							{ replace: true }
						);
					}}
					// (isSuperAdmin || permissionLevel==2)}
					// onChangeManagerFilter={
					//   (retorno: string) => {
					//     setSearchParams({
					//       busca, pagina: String(1), tipo: tipoBordero,
					//       status: statusBordero, typePeriod,
					//       origem: origemBordero, dataini: dataIniBordero, datafim: dataFimBordero,
					//       clientFilter: clientFilter,
					//       managerId: retorno,
					//     }, { replace: true });
					//   }
					// }

					// textoButtonNew="novo"
					// showInputBusca={true}
					// showButtonNew={false}
					// showInputStatus={false}
					// showCheckBox={false}
					// showInputOrigem={false}
					// showTypePeriod = {false}
				/>
			}
		>
			{modalPayment && (
				<ModalContext
					isCloseModal={() => setModalPayment(false)}
					isOpenModal={modalPayment}
					messageType={"question"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					textButtonCancel={"Voltar"}
					onclickConfirm={() => {
						// handleDuaDate();
					}}
					ITitle={"Confirma pagamento de borderô ?"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formIdBorderoPayment"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRef}
									onSubmit={handleBorderoPayment}
								>
									<Grid container direction="column" padding={1} spacing={2}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}

										<Grid container item direction="row" spacing={1}>
											<Grid item xs={2} sx={{ textAlign: "left" }}>
												Cliente :
											</Grid>
											<Grid item xs={8} sx={{ alignItems: "left" }}>
												<Typography variant="inherit" sx={{ fontWeight: "bold" }}>
													{rowSelect?.sinister.proposal.name}
												</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid container item direction="row" gap={3} sx={{ mb: 3, mt: "1rem" }}>
										<Grid item xs={3} sx={{ alignItems: "left" }}>
											<TextField
												fullWidth
												label="Pagamento"
												name="paymentAt"
												disabled={isLoading}
												size="small"
												type="date"
												InputLabelProps={{ shrink: true, required: true }}
												defaultValue={paymentBordero.substring(0, 10)}
												onChange={(e) => {
													setPaymentBordero(e.target.value + " 12:00:00");
												}}
											></TextField>
										</Grid>
										<Grid item xs={3} sx={{ alignItems: "left" }}>
											<TextField
												fullWidth
												label="Valor"
												name="Valor"
												disabled={true}
												value={parseFloat(rowSelect?.value || "0.00").toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
												size="small"
											></TextField>
										</Grid>
										<Grid item xs={3} sx={{ alignItems: "left" }}>
											<TextField
												fullWidth
												label="Valor"
												name="paymentValue"
												disabled={true}
												value={parseFloat(rowSelect?.value || "0.00").toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
												size="small"
											></TextField>
											{/* <VMaskTextField
                        fullWidth
                        label="Valor Pago"
                        name="paymentValue"
                        mask="real"
                        onChange={(e) => {
                          setPaymentValue(e.target.value);
                        }}
                        value={parseFloat(rowSelect?.value || '0.00').toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                      /> */}
										</Grid>
									</Grid>

									<Grid
										container
										sm={12}
										sx={{ border: "none", display: "flex", justifyContent: "center" }}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										<Button
											color="primary"
											disableElevation
											variant={"contained"}
											startIcon={<Icon> checkIcon</Icon>}
											onClick={() => {
												formRef.current?.submitForm();
												handleBorderoPayment({
													paymentAt: paymentBordero,
													paymentValue: paymentValue,
												});
												setModalPayment(false);
											}}
										>
											{"Confirmar"}
										</Button>
										<Button
											color="primary"
											disableElevation
											variant={"outlined"}
											startIcon={<Icon> arrow_back_Ico</Icon>}
											onClick={() => {
												setModalPayment(false);
											}}
										>
											{"Voltar"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{(modalProvision || modalReProvision) && (
				<ModalContext
					isCloseModal={() => {
						setModalProvision(false), setModalReProvision(false);
					}}
					isOpenModal={modalProvision || modalReProvision}
					messageType={"question"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					textButtonCancel={"Voltar"}
					onclickConfirm={() => {
						// handleDuaDate();
					}}
					ITitle={modalReProvision ? "Confirma reprovisionamento do borderô ?" : "Confirma provisionamento do borderô ?"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formIdBorderProvision"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRef}
									onSubmit={handleBorderoProvision}
								>
									<Grid container direction="column" padding={1} spacing={2}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}

										<Grid container item direction="row" spacing={1}>
											<Grid item xs={1} sx={{ textAlign: "left" }}>
												Cliente :
											</Grid>
											<Grid item xs={8} sx={{ alignItems: "left" }}>
												<Typography variant="inherit" sx={{ fontWeight: "bold" }}>
													{rowSelect?.sinister.proposal.name}
												</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid container item direction="row" gap={3} sx={{ mb: 3, mt: "1rem" }}>
										<Grid item xs={4} sx={{ alignItems: "left" }}>
											<TextField
												fullWidth
												label="Provisionamento"
												name="provisionedAt"
												disabled={isLoading}
												size="small"
												type="date"
												InputLabelProps={{ shrink: true, required: true }}
												defaultValue={new Date().toISOString().substring(0, 10)}
												onChange={(e) => {
													setProvisionBordero(e.target.value + " 12:00:00");
												}}
											></TextField>
										</Grid>
										<Grid item xs={4} sx={{ alignItems: "left" }}>
											<TextField
												fullWidth
												label="Valor"
												name="Valor"
												disabled={true}
												value={parseFloat(rowSelect?.value || "0.00").toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
												size="small"
											></TextField>
										</Grid>
										{/* <Grid item xs={4} sx={{ alignItems: "left" }}>
                      <TextField
                        fullWidth
                        label='Valor Pago'
                        name="paymentValue"
                        disabled={false}
                        value='0.00'
                        size='small'
                      >
                      </TextField>
                    </Grid> */}
									</Grid>

									<Grid
										container
										sm={12}
										sx={{ border: "none", display: "flex", justifyContent: "center" }}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										<Button
											color="info"
											disableElevation
											variant={"contained"}
											startIcon={<Icon> checkIcon</Icon>}
											onClick={() => {
												formRef.current?.submitForm();
												handleBorderoProvision({
													provisionedAt: provisionBordero,
												});
												setModalProvision(false);
												// navigate('/boletos/');
											}}
										>
											{"Confirmar"}
										</Button>
										<Button
											color="info"
											disableElevation
											variant={"outlined"}
											startIcon={<Icon> arrow_back_Ico</Icon>}
											onClick={() => {
												setModalProvision(false);
												setModalReProvision(false);
											}}
										>
											{"Voltar"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{modalCancel && (
				<ModalContext
					isCloseModal={() => setModalCancel(false)}
					isOpenModal={modalCancel}
					messageType={"error"}
					enableBackdropClose={false}
					overrideWidthValue="40%"
					showButtonCancel={false}
					overrideHeightValue="auto"
					ITitle="Cancelar item"
					IMessage={[
						<Box style={{ width: "90%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formIdCancel"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRef}
									onSubmit={handleCancel}
								>
									<Grid container direction="column" padding={1} spacing={2}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container item direction="row" spacing={1}>
													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<Typography variant="caption" color={"text.secondary"}>
															Status
														</Typography>
														<Typography variant="h5" sx={{ color: "red" }}>
															Cancelado
														</Typography>
													</Grid>

													<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
														<Typography variant="caption" color={"text.secondary"}>
															Data
														</Typography>
														<Typography variant="subtitle2" sx={{ color: "gray" }}></Typography>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12}>
												<VTextField
													fullWidth
													label="Descreva o motivo do cancelamento"
													name="canceledReason"
													disabled={isLoading}
													multiline
													rows={4}
												></VTextField>
											</Grid>
										</Grid>
									</Grid>
									<Grid container item direction="row"></Grid>

									<Grid
										container
										sm={12}
										sx={{
											border: "none",
											display: "flex",
											justifyContent: "center",
										}}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										<Button
											color="primary"
											disableElevation
											variant={"outlined"}
											startIcon={<Icon> arrow_back_Ico</Icon>}
											onClick={() => {
												//formRef.current?.submitForm();
												setModalCancel(false);
											}}
										>
											{"Voltar"}
										</Button>
										<Button
											color="primary"
											disableElevation
											variant={"contained"}
											startIcon={<Icon> checkIcon</Icon>}
											onClick={() => {
												formRef.current?.submitForm();
												setModalCancel(false);
											}}
										>
											{"Confirmar"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}

			<ProfileGuard requiredRole={PermissionRolesEnum.FinancialOptions} requiredAction={PermissionActionsEnum.View}>
				<TableContainer component={Paper} variant="outlined" sx={{ marginLeft: 1, marginTop: 1, marginRight: 2, width: "auto", padding: 4 }}>
					<Table aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell colSpan={7}>
									<ToolbarGridProvision
										textSearchGrid={busca}
										onChangeTextBuscaGrid={(texto) => setSearchParams({ busca: texto, pagina: "1" }, { replace: true })}
										showInputBuscaGrid={false}
										onClickTodos={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoBordero,
													status: "Todos",
													dataini: dataIniBordero,
													datafim: dataFimBordero,
													clientFilter: clientFilter,
													managerId,
												},
												{ replace: true }
											)
										}
										onClickPaid={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoBordero,
													status: "paid",
													dataini: dataIniBordero,
													datafim: dataFimBordero,
													clientFilter: clientFilter,
													managerId,
												},
												{ replace: true }
											)
										}
										onClickAberto={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoBordero,
													status: "open",
													dataini: dataIniBordero,
													datafim: dataFimBordero,
													clientFilter: clientFilter,
													managerId,
												},
												{ replace: true }
											)
										}
										onClickProvision={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoBordero,
													status: "provisioned",
													dataini: dataIniBordero,
													datafim: dataFimBordero,
													clientFilter: clientFilter,
													managerId,
												},
												{ replace: true }
											)
										}
										onClickCancel={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoBordero,
													status: "canceled",
													dataini: dataIniBordero,
													datafim: dataFimBordero,
													clientFilter: clientFilter,
													managerId,
												},
												{ replace: true }
											)
										}
										selectTodos={statusBordero === "Todos"}
										selectAberto={statusBordero === "open"}
										selectPaid={statusBordero === "paid"}
										selectProvision={statusBordero === "provisioned"}
										selectCancel={statusBordero === "canceled"}
									/>
								</TableCell>
							</TableRow>

							<TableRow sx={{ background: "#f5fbfd" }}>
								<TableCell sx={{ color: "#7e7777b5" }}> #</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Nome</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Parceiro</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Valor</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Abertura</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Data Provisão</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Data Limite</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Status</TableCell>
								<TableCell sx={{ color: "#7e7777b5", width: "3px" }}> </TableCell>
							</TableRow>
							{isLoading && (
								<TableRow>
									<TableCell colSpan={3}>
										<LinearProgress variant="indeterminate" />
									</TableCell>
								</TableRow>
							)}
						</TableHead>
						<TableBody>
							{rows.map((row) => (
								<TableRow key={row.id} hover>
									<TableCell>
										<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
											{row.sequencial}
										</Typography>
									</TableCell>
									<TableCell sx={{ fontWeight: "normal" }}>
										{row.sinister.proposal.name}
										<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
											{row?.sinister.proposal.document.length > 11
												? `${row?.sinister.proposal.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")}`
												: `${row?.sinister.proposal.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")}`}
										</Typography>
									</TableCell>

									<TableCell>
										<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
											{row.client.name}
										</Typography>
										<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
											{row?.client.document.length > 11
												? `${row?.client.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")}`
												: `${row?.client.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")}`}
										</Typography>
									</TableCell>

									<TableCell>
										<Typography variant="subtitle1" color={"#959595"} sx={{ fontWeight: "400", textAlign: "left" }}>
											{parseFloat(`${row.value}`).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
										</Typography>
									</TableCell>

									<TableCell>
										<Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
											{moment(row.createdAt).format("L")}
										</Typography>
										<Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
											{moment(row.createdAt).format("hh:mm:ss")}
										</Typography>
									</TableCell>

									<TableCell sx={{ color: "#959595", fontWeight: "normal" }}>
										<Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
											{row.provisionedAt != null && moment(row.provisionedAt).format("L")}
										</Typography>
									</TableCell>

									<TableCell sx={{ color: "#959595", fontWeight: "normal" }}>
										<Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
											{moment(row.due_date).format("L")}
										</Typography>
									</TableCell>

									<TableCell sx={{ textAlign: "start" }}>
										<IconButton
											size="small"
											sx={{
												background: globalBackground(row.status),
												borderRadius: "18px",
												paddingX: "15px",
												paddingY: "1px",
												marginBottom: "5px",
											}}
										>
											<Icon sx={{ color: globalColorText(row.status), fontWeight: "normal" }}> noise_control_off_sharp_icon</Icon>
											<Typography variant="subtitle2" marginLeft={1} sx={{ color: globalColorText(row.status), fontWeight: "normal" }}>
												{globalTranslate(row.status)}
											</Typography>
										</IconButton>
										{row.status === "paid" && (
											<Typography variant="subtitle2" sx={{ color: globalColorText(row.status) }}>
												em {moment(row.paymentAt).format("L")}
											</Typography>
										)}
										{row.status === "canceled" && (
											<Typography variant="subtitle2" sx={{ color: globalColorText(row.status) }}>
												em {moment(row.canceledAt).format("L")}
											</Typography>
										)}
									</TableCell>
									<TableCell sx={{ textAlign: "center" }}>
										<Tooltip title="Pdf Extrato do Sinistro" placement="top-start">
											<IconButton
												size="small"
												disabled={isLoading}
												onClick={() => handleExtractPDF(row?.id ? row.id : "0")}
												sx={{ borderRadius: "18px" }}
											>
												<Icon sx={{ color: "#7924c7", fontWeight: "normal" }}> picture_as_pdf_outlined_Icon</Icon>
												{/* <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#7924c7", fontWeight: "normal" }}>
                                                    Extrato
                                                </Typography> */}
											</IconButton>
										</Tooltip>
									</TableCell>
									<TableCell sx={{ textAlign: "start" }}>
										<Tooltip title="" placement="top-start">
											<div>
												<RowMenuBordero goTo={goTo} row={row} />
											</div>
										</Tooltip>
									</TableCell>
								</TableRow>
							))}
						</TableBody>

						{totalCount === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}

						<TableFooter>
							{isLoading && (
								<TableRow>
									<TableCell colSpan={3}>
										<LinearProgress variant="indeterminate" />
									</TableCell>
								</TableRow>
							)}
							{totalCount > 0 && (
								<TableRow>
									<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
										<Pagination
											page={pagina}
											count={Math.ceil(totalCount / Environment.LIMITE_LINHAS)}
											onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
										/>
									</TableCell>
									<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
										<Typography variant="body1" sx={{ fontWeight: "normal" }}>
											Exibindo {pagina * Environment.LIMITE_LINHAS - (Environment.LIMITE_LINHAS - 1)}
											{" - "}
											{Environment.LIMITE_LINHAS * (pagina - 1) + Environment.LIMITE_LINHAS * pagina < totalCount
												? Environment.LIMITE_LINHAS * pagina
												: totalCount}{" "}
											de
											<Typography variant="overline" sx={{ fontWeight: "bold", color: "#0170B3", fontSize: "16px" }}>
												{" "}
												{totalCount}{" "}
											</Typography>
											registro(s)
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableFooter>
					</Table>
				</TableContainer>
			</ProfileGuard>
		</LayoutBaseDePagina>
	);
};
