import React, { useEffect, useState } from "react";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { Box, Icon, Typography, Skeleton } from "@mui/material";
import { ISinistercheckList, ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";
import { SinisterFollowUpService } from "../../../../../shared/services/api/sinister/SinisterFollowUpService";
import { SinisterService } from "../../../../../shared/services/api/sinister/SinisterService";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../../shared/hooks";

interface ModalCheckListSinisterProps {
	isOpenModal: boolean;
	isClose: () => void;
	rows: any;
	propertyOccupied: "busy" | "open";
}

const ModalCheckListSinister: React.FC<ModalCheckListSinisterProps> = ({ isClose, isOpenModal, propertyOccupied, rows }) => {
	const [checklist, setChecklist] = useState<ISinistercheckList[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const toastHandler = useToast();

	const fetchChecklist = async (occupiedStatus: string) => {
		setIsLoading(true);
		try {
			const result = await ProposalService.getSinisterCheck(occupiedStatus);
			if (result?.data) {
				setChecklist(result.data);
			}
		} catch (error) {
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={"Erro"} content={"Erro ao buscar checklist. Tente novamente."} />,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleCreateSinister = async () => {
		const busyProperty = propertyOccupied === "busy" ? "busy" : "not_busy";
		const { id: proposalId, clientId: clientId } = rows;
		const status = "sketch";

		try {
			setIsLoading(true);
			const result = await SinisterService.create(busyProperty, proposalId, status, clientId);

			if (result instanceof Error) {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={result.message} />,
				});
				return;
			}

			const email = result.client?.email || "";
			const sinisterId = result?.id || "";

			if (sinisterId && email) {
				await SinisterFollowUpService.addSinisterFollowupContact(sinisterId, email);
			}

			toastHandler.present({
				type: "success",
				position: "top-right",
				messageOrContent: <ToastContent title={"Sucesso"} content={"Sinistro criado com sucesso."} />,
			});
			navigate(`/sinistros/`);
		} catch (error) {
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={"Erro"} content={"Erro ao criar sinistro. Tente novamente."} />,
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (propertyOccupied && isOpenModal) {
			fetchChecklist(propertyOccupied);
		}
	}, [isOpenModal, propertyOccupied]);

	const renderChecklistItems = () => {
		if (isLoading) {
			return Array.from(new Array(5)).map((_, index) => <Skeleton key={index} variant="text" height={40} sx={{ marginBottom: 1 }} />);
		}

		return checklist.map((row, index) => (
			<Typography
				key={index}
				paddingY={"5px"}
				borderBottom={"1px solid"}
				borderColor={"#efefef"}
				sx={{ backgroundColor: index % 2 ? "white" : "#f6f0ff" }}
			>
				<Icon sx={{ color: "primary", fontSize: "small", mr: "8px" }}>done_outline</Icon>
				{(row.ocupation !== "busy" && row.open === "required") || (row.ocupation !== "open" && row.busy === "required")
					? `${row.description} - Obrigatório`
					: row.description}
			</Typography>
		));
	};

	return (
		<ModalContext
			isCloseModal={isClose}
			isOpenModal={isOpenModal}
			messageType={"info"}
			showButtonCancel={!isLoading}
			showButtonConfirm={!isLoading}
			showTitleIcon={false}
			textButtonConfirm={"Aceito os termos"}
			textButtonCancel={"Fechar"}
			onclickConfirm={handleCreateSinister}
			ITitle={propertyOccupied === "busy" ? "Termos de abertura de Sinistro Imóvel Ocupado" : "Termos de abertura de Sinistro Imóvel Desocupado"}
			IMessage={[
				<Box style={{ width: "100%" }}>
					<Typography paddingY={"5px"} paddingX={"25px"} borderBottom={"1px solid"} borderColor={"#efefef"}>
						Para celeridade e regulação do seu sinistro, favor anexar os documentos solicitados abaixo, para não correr risco de recusa no sinistro.
						Já começamos a contar o prazo de análise e pagamento de sinistro após o recebimento da documentação completa.
					</Typography>
					<Box margin={1} display="flex" flexDirection="column">
						{renderChecklistItems()}
					</Box>
				</Box>,
			]}
		/>
	);
};

export default ModalCheckListSinister;
