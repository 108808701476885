import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "../../hooks";
import { IListagemUsuario, userService } from "../../services/api/user/userService";
import { ToastContent } from '../../../shared/components';
import { useToast } from '../../../shared/hooks';
import { useProfile } from '../../../context/ProfileContext';
import { returnMessageError } from "../../utils/returnMessageError";

type TAutoCompleteOption = {
    id: string;
    label: string;
    status: string;
}

interface IAutoCompleteManagerProps {
    isExternalLoading?: boolean;
    labelSelect?: string;
    managers: IListagemUsuario[];
    onClick?: (value: any) => any;
    selected: (user: IListagemUsuario) => void;
    idClient: string;
}

export const AutoCompleteManager: React.FC<IAutoCompleteManagerProps> = ({
    isExternalLoading = false,
    labelSelect = '',
    managers,
    selected,
    idClient,
}) => {

    const opcoesManager: TAutoCompleteOption[] = managers?.map(manager => ({ id: manager.id, label: manager.name, status: manager.status }));
    const { debounce } = useDebounce();
    const [selectedId, setSelectedId] = useState<string | undefined>();
    const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>(opcoesManager);
    const [isLoading, setIsLoading] = useState(false);
    const [busca, setBusca] = useState('');
    const toastHandler = useToast();
    const { isSuperAdmin,permissionLevel } = useProfile();


    useEffect(() => {
        if ((isSuperAdmin || permissionLevel==2) ) {
            setIsLoading(true);
            debounce(() => {
                userService.getManagers(busca, idClient)
                    .then((result) => {
                        setIsLoading(false);
                        if (result instanceof Error) {
                            const titleMessage = "Alerta!!";
                            const contentMessage = "Erro: " + result?.message;

                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            })
                        } else setOpcoes(result?.data?.map(manager => ({ id: manager.id, label: manager.name, status: manager.status })));


                    }).catch((err: any) => {
                        setIsLoading(false);
                        const { titleMessage, contentMessage } = returnMessageError(err);
                        
                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    })
            });
        }
    }, [busca]);

    const autoCompleteSelectedOption = useMemo(() => {

        if (!selectedId) return null;
        const selectedOption = opcoes.find(opcao => opcao.id === selectedId);

        if (!selectedOption) return null;
        return selectedOption;
    }, [selectedId]);



    return (
        <Autocomplete
            openText="Abrir"
            closeText="Fechar"
            noOptionsText="Nenhuma opção"
            loadingText="Loading ..."
            disablePortal
            value={autoCompleteSelectedOption}
            options={opcoes}
            loading={isLoading}
            disabled={isExternalLoading}
            onInputChange={(_, newValue: any) => {
                setBusca(newValue);
            }}
            onChange={(_, newValue: any) => {
                setSelectedId(newValue?.id);
                selected(newValue);
            }}
            popupIcon={isExternalLoading || isLoading ? <CircularProgress size={28} /> : undefined}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={labelSelect}
                    size={'small'}
                />
            )}
        />
    )
};