import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { IDetalheAnalises, analysisService } from "../../../../../shared/services/api/analysis/analysisService";
import { returnMessageError } from "../../../../../shared/utils/returnMessageError";
import { useToast } from "../../../../../shared/hooks";

interface AnalysisLogsModalProps {
	isOpenModal: boolean;
	isClose: () => void;
	rows: any;
}

export const ModalLogAnalysis: React.FC<AnalysisLogsModalProps> = ({ isOpenModal, isClose, rows }) => {
	const [dataAnalysis, setDataAnalysis] = useState<IDetalheAnalises>();
	const [isLoad, setIsLoad] = useState(true);
	const toastHandler = useToast();

	const handleGetLogsAnalysis = async (analysisId: string) => {
		setIsLoad(true);
		try {
			const result = await analysisService.getById(analysisId)
			if (result instanceof Error) {
				throw new Error("Analise não encontrada");
			}

			// Convert to object
			const planlist = JSON.parse(result.planlist);
			result.planlist = planlist;

			setDataAnalysis(result);
		} catch (err) {
			const { titleMessage, subtitleMessage, contentMessage } = returnMessageError(err);
			toastHandler.present({
				type: "error",
				position: "top-right",
				durationMs: 4000,
				messageOrContent: <ToastContent title={titleMessage} subtitle={subtitleMessage} content={contentMessage} />,
			});
		} finally {
			setIsLoad(false);
		}
	};

	useEffect(() => {
		if (isOpenModal) {
			handleGetLogsAnalysis(rows?.analysisId);
		}
	}, [isOpenModal]);

	return (
		<ModalContext
			isCloseModal={isClose}
			isOpenModal={isOpenModal}
			messageType={"info"}
			enableBackdropClose={true}
			overrideWidthValue="100"
			showButtonCancel={true}
			textButtonCancel={"Sair"}
			showTitleIcon={false}
			ITitle="Log do Analysis"
			IMessage={[
				<Box key="Analysis-log-modal" style={{ width: "100%", padding: 1 }}>
					{isLoad ? (
						<Box display="flex" justifyContent="center" alignItems="center" height="200px">
							<CircularProgress />
						</Box>
					) : (
						<>
							<Box sx={{ mt: 2, padding: 1, backgroundColor: "#f1f1f1", borderRadius: 1 }}>
								<Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
									Detalhes do JSON:
								</Typography>
								<pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{JSON.stringify(dataAnalysis, null, 2)}</pre>
							</Box>
						</>
					)}
				</Box>,
			]}
		/>
	);
};
