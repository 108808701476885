import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Paper, Typography, Box, IconButton, Icon, useTheme, useMediaQuery, Button, Grid, CircularProgress, } from "@mui/material";
import { FerramentaDetalhe, ModalContext, } from "../../../shared/components";
import { useVForm } from "../../../shared/forms";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { useDebounce } from "../../../shared/hooks";
import "moment/locale/pt-br";
import { useToast } from "../../../shared/hooks";
import { ToastContent } from "../../../shared/components";
import { IDetalheCliente, clientService } from "../../../shared/services/api/client/clientService";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { colorBackGroundStatus, colorIconStatus, colorTextStatus, sleep, translateStatusClient } from "../../../shared/utils";
import { FinancialService } from "../../../shared/services/api/financial/FinancialService";
import { useAuthContext } from "../../../context";


export const ProccessCancelBlockClientPresentation = () => {
    const { id = 'novo' } = useParams<'id'>();
    const [row, setRow] = useState<IDetalheCliente>();
    const { debounce } = useDebounce();
    const navigate = useNavigate();
    const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } =
        useVForm();
    const [isLoading, setIsLoading] = useState(true);
    const [modalConfirm, setModalConfirm] = useState(false);
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const toastHandler = useToast();
    const { logout } = useAuthContext();


    const getClientById = () => {
        if (id !== 'novo') {
            setIsLoading(true);
            clientService.getByID(id)
                .then(async (result: any) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                        await sleep(2000);
                        navigate('/boletos');

                    } else setRow(result);

                }).catch((err: any) => {

                    setIsLoading(false);
                    const { titleMessage, contentMessage } = returnMessageError(err);

                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    });

                });
        }
    }

    useEffect(() => {
        getClientById();
    }, [id]);

    const cancelBlockClientOperation = () => {
        setIsLoading(true);

        debounce(() => {
            FinancialService.cancelBlockClient(id)
                .then(async (result: any) => {
                    setIsLoading(false);
                    setModalConfirm(false);

                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        });

                    } else {
                        toastHandler.present({
                            type: 'success',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={"Cancelado/Bloqueado Inadimplência Garantia"}
                                content={"Cancelado/Bloqueado em inadimplência com sucesso da garantia "} />
                        });

                        await sleep(3000);
                        navigate('/financeiro', { replace: true });
                    }

                }).catch((err: any) => {

                    setIsLoading(false);
                    const { titleMessage, contentMessage } = returnMessageError(err);

                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    });

                });
        });
    }


    return (
        <LayoutBaseDePagina
            titulo="Processo de Cancelamento/bloqueio por inadimplência"
            subtitulo="Cancelamento/bloqueio"
            barraDeFerramentas={
                <FerramentaDetalhe
                    showButtonNew={false}
                    showBotaoApagar={false}
                    showBotaoSalvar={false}
                    showBotaoSalvarFechar={false}
                    showConfirm={true}
                    clicarVoltar={() => navigate('/saudefinanceira')}
                    clickConfirm={() => setModalConfirm(true)}
                />
            }
        >

            < Grid container spacing={3} >
                <Grid item xs={12} marginX={2} marginLeft={0} >
                    <Grid container xs={12}
                        display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                        ml={smDown ? 0 : 2}
                    >
                        <Grid container xs={12}
                            display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            component={Paper}
                            padding={2}
                            spacing={1}
                            paddingRight={"5px"}
                        >
                            <Grid container xs={12}
                                direction="row"
                                spacing={1} >
                                <Grid item xs={11} paddingLeft={"15px !important"}>
                                    <Typography variant="h5" color={'secondary'}>
                                        Resumo
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" color={'text.secondary'}>
                                        Nome:
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.name}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" marginTop={1} spacing={1} sx={{ backgroundColor: "#f0f1f1", marginLeft: 0 }}>
                                <Grid item xs={12} md={2} paddingLeft={"0px !important"}>
                                    <Typography variant="subtitle2" color={'text.secondary'}>
                                        CNPJ
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row && row.document?.length > 11 ?
                                            `${row?.document?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}`
                                            :
                                            `${row?.document?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")}`
                                        }
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <Typography variant="subtitle2" color={'text.secondary'} paddingLeft={"6px"}>
                                        Status
                                    </Typography>
                                    <IconButton size="small" sx={{ background: colorBackGroundStatus(row?.status), borderRadius: "15px", pl: "0px", pr: "15px", paddingY: "1px", marginBottom: "5px" }}>
                                        <Icon sx={{ color: colorIconStatus(row?.status), fontWeight: "normal" }}> noise_control_off_sharp_icon</Icon>
                                        <Typography variant="subtitle2" sx={{ color: colorTextStatus(row?.status), fontWeight: "normal" }}>
                                            {translateStatusClient(row?.status)}
                                        </Typography>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >

            {modalConfirm && (
                <ModalContext
                    isCloseModal={() => setModalConfirm(false)}
                    isOpenModal={modalConfirm}
                    messageType={"alert"}
                    showButtonCancel={false}
                    textButtonConfirm={"Fechar"}
                    onclickConfirm={() => setModalConfirm(false)}
                    ITitle={'Tem certeza que deseja prosseguir? Esta operação é irreversível!'}
                    IMessage={[
                        <Box>
                            <Grid container item sm={12}
                                sx={{ border: "none", display: 'flex', justifyContent: 'center' }}
                                display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                                padding={2} gap={2}
                            >
                                {isLoading ? <CircularProgress /> :
                                    <Button
                                        color="success"
                                        disableElevation
                                        variant={"contained"}
                                        startIcon={< Icon > checkIcon</Icon >}
                                        onClick={() => cancelBlockClientOperation()}
                                    >
                                        Confirmar
                                    </Button>
                                }
                                <Button
                                    color="info"
                                    disableElevation
                                    variant={"outlined"}
                                    startIcon={<Icon> close</Icon>}
                                    onClick={() => setModalConfirm(false)}
                                >
                                    Fechar
                                </Button>
                            </Grid>
                        </Box>
                    ]}
                />
            )}
        </LayoutBaseDePagina>
    );
};
