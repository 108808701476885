import { Client } from "../../../../../client_api/model/client";
import { Environment } from "../../../environment";
import { Api } from "../axios-config";

export interface IListagemUsuario {
  id: string;
  status: string;
  role: string;
  user_client_id?: string;
  // estateId?:number;
  name: string;
  email: string;
  phone: string;
  // clientId?:string;
  permission?: {
    description?: string;
    level?: number;
    clientId: string;
  };
  client?: {
    name?: string;
    clientType?: {
      id?: string;
      description: string;
      level?: number;
    };
    document?: string;
    email?: string;
  };
}

export interface IDetalheUsuario {
  id: string;
  status?: string;
  name: string;
  email: string;
  phone: string;
  document?: string;
  client?: Client;
  role?: string;
  created_at?: string;
  createdAt?: string;
  last_login?: string;
  password?: string;
}

type TUsuariosTotalCount = {
  data: IListagemUsuario[];
  totalCount: number;
};

type TUsuarios = {
  data: IListagemUsuario[];
};

type TAddManagerByClient = {
  userId: string;
  clientId: string;
  status: string;
};

const getAll = async (
  page = 1,
  filter = "",
  tipo = "",
  status = "",
  clientId = "",
  permissaoDescricao = "",
  userLogged = ""
): Promise<TUsuariosTotalCount | Error> => {
  try {
    const urlRelativa = `/user?_page=${page}&_limit=${Environment.LIMITE_LINHAS}&nome_like=${filter}&tipo=${tipo}&status=${status}&clientId=${clientId}&permissaoDescricao=${permissaoDescricao}&userLogged=${userLogged}`;

    const { data, headers } = await Api().get(urlRelativa);

    if (data.results) {
     
      return {
        data: data.results,
        totalCount: data.total, //Number(headers['x-total-count'] || Environment.LIMITE_LINHAS),
      };
    }
    return new Promise((_, reject) =>
      reject(new Error("Erro ao listar registros."))
    );
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const getManagers = async (
  busca: string = "",
  clientId: string
): Promise<TUsuarios | Error> => {
  try {
    const urlRelativa = `/user/managers?name=${busca}&clientId=${clientId}`;

    const { data } = await Api().get(urlRelativa);

    if (data.results) {
      return {
        data: data.results,
      };
    }
    return new Promise((_, reject) =>
      reject(new Error("Erro ao listar registros de usuários gestores."))
    );
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const getAdminManagers = async (
  busca: string = ""
): Promise<TUsuarios | Error> => {
  try {
    const urlRelativa = `/user/admin/managers?name=${busca}`;

    const { data } = await Api().get(urlRelativa);

    if (data.results) {
      return {
        data: data.results,
      };
    }
    return new Promise((_, reject) =>
      reject(new Error("Erro ao listar registros de usuários gestores."))
    );
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const getManagersByClient = async (
  idClient: string
): Promise<TUsuarios | Error> => {
  try {
    const urlRelativa = `/user/client/user/managers?clientId=${idClient}`;

    const { data } = await Api().get(urlRelativa);

    if (data.results) {
      return {
        data: data.results,
      };
    }
    return new Promise((_, reject) =>
      reject(
        new Error("Erro ao listar registros de gestores referente ao cliente.")
      )
    );
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const addManagerByClient = async (
  dataAddManager: TAddManagerByClient
): Promise<TUsuarios | Error> => {
  try {
    const urlRelativa = "/user/client/managers";

    const { data } = await Api().post(urlRelativa, dataAddManager);

    if (data) {
      return {
        data,
      };
    }
    return new Promise((_, reject) =>
      reject(new Error("Erro ao registrar Gestor para o cliente."))
    );
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const deleteManagerById = async (id: string = ""): Promise<any | Error> => {
  try {
    await Api().delete(`/user/client/managers/${id}`);
    return new Promise((resolve) =>
      resolve({
        message: "Sucesso",
        description: "Gestor removido com sucesso!",
      })
    );
  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const getById = async (id: string): Promise<IDetalheUsuario | Error> => {
  try {
    const { data } = await Api().get(`/user/${id}`);

    if (data) {
      return data;
    }
    return new Error("Erro ao consultar o registro.");
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || "Erro ao consultar o registro."
    );
  }
};

const getByToken = async () => {
  try {
    // const {data} = await Api().get('token', );
    const { data } = await Api().put("/auth/refresh");
    //console.log('getbytoken....',data)
    return data;
  } catch {
    return new Error("Erro ao consultar o registro");
  }
};

const create = async (
  dados: Omit<IDetalheUsuario, "id">
): Promise<any> => {
  try {
    const { data } = await Api().post<IDetalheUsuario>("/user", dados);

    if (data) return data.id;
    
    return new Promise((_, reject) =>
      reject(new Error("Erro ao criar o registro."))
    );

  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const updateById = async (
  id: string,
  dados: IDetalheUsuario
): Promise<void | Error> => {
  try {
    
    await Api().patch(`/user/${id}`, dados);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || "Erro ao atualizar o registro."
    );
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Api().delete(`/user/${id}`);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || "Erro ao apagar o registro."
    );
  }
};

export const userService = {
  getAll,
  getByID: getById,
  getByToken,
  create,
  updateById,
  deleteById,
  getManagers,
  addManagerByClient,
  getManagersByClient,
  deleteManagerById,
  getAdminManagers,
};
