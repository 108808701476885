import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { useProfile } from "../../../context/ProfileContext";
import moment from "moment";
import { globalColorLine, globalTranslateSex, maskPhone } from "../../../shared/utils";
import { isDate, isValid } from "date-fns";

interface IDetailLocatorProps {
    name?: string;
    document?: string;
    birthDate?: string;
    obs?: string;
    lessorTypePerson?: string;
    mobilePhone?: string;
    phone?: string;
    sex?: string;
    email?: string;
    dataLoading: boolean;
}

export const DetailLocator: React.FC<IDetailLocatorProps> = ({
    name = '',
    document = '',
    birthDate = '',
    obs = '',
    lessorTypePerson = '',
    mobilePhone = '',
    phone = '',
    email = '',
    sex = '',
    dataLoading,


}) => {

    const { isSuperAdmin } = useProfile();
    const ColorTytle = '#1c1b1b';

    return (
        <Grid container component={Paper} sx={{ width: '100%', maxWidth: '1300px', padding: 2 }}>
            <Grid container item direction="row" spacing={1}>
                <Grid item xs={isSuperAdmin ? 7 : 8} >
                    <Typography variant="h6" color={ColorTytle}>
                        Dados do Locador
                    </Typography>
                </Grid>
            </Grid >

            {!dataLoading && (
                <Grid container item direction="row" sx={{ padding: .71, background: globalColorLine(1) }}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Nome
                        </Typography>
                        <Typography variant="subtitle2" >
                            {name || '-'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Tipo do Locador
                        </Typography>
                        <Typography variant="subtitle2" >
                            {lessorTypePerson == "legal" ? "Juridica" : "Fisica"}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {dataLoading && (
                <Skeleton height={"50px"} width={"70rem"} />
            )}

            {!dataLoading && (
                <Grid container item direction="row" sx={{ padding: .71, background: globalColorLine(2) }}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Documento
                        </Typography>
                        <Typography variant="subtitle2" >
                            {!document || document == "undefined" ? '-' : document?.length > 11 ?
                                `${document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}`
                                :
                                `${document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")}`
                            }
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Nascimento
                        </Typography>
                        <Typography variant="subtitle2" >
                            {moment(birthDate, 'YYYY-MM-DD', true).isValid() && birthDate != '1899-11-30' ? moment(birthDate).format("L") : '-'}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Sexo
                        </Typography>
                        <Typography variant="subtitle2" >
                        {globalTranslateSex(sex)}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Motivo
                        </Typography>
                        <Typography variant="subtitle2" >
                            {obs && obs != "null" ? obs : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {dataLoading && (
                <Skeleton height={"50px"} width={"70rem"} />
            )}

            {!dataLoading && (
                <Grid container item direction="row" sx={{ padding: .71, background: globalColorLine(3) }}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Celular
                        </Typography>
                        <Typography variant="subtitle2" >
                            {phone ? maskPhone(`${phone}`) : '-'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Telefone
                        </Typography>
                        <Typography variant="subtitle2" >
                            {mobilePhone ? maskPhone(`${mobilePhone}`) : '-'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Email
                        </Typography>
                        <Typography variant="subtitle2" >
                            {email || '-'}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {dataLoading && (
                <Skeleton height={"50px"} width={"70rem"} />
            )}

        </Grid >

    )
}