import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableFooter,
    LinearProgress,
    Pagination,
    Typography,
    Box,
    IconButton,
    Icon,
    useTheme,
    useMediaQuery,
    Checkbox,
    Button,
    Grid,
    Tooltip,
    MenuItem,
    TextField,
    Autocomplete,
    InputLabel,
    Select,
    SelectChangeEvent,
    Link,
} from "@mui/material";
import { ToolbarGridFinancial } from "../../../shared/components/toolbarGridFinancial/ToolbarGridFinancial";
import {
    FinancialService,
    INfse,
} from "../../../shared/services/api/financial/FinancialService";
import {
    Loading,
    ModalContext,
    TAutoCompleteOption,
    ToolbarFinancial,
} from "../../../shared/components";
import { VForm, VTextField, useVForm } from "../../../shared/forms";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { useDebounce } from "../../../shared/hooks";
import { Environment } from "../../../shared/environment";
import moment from "moment";
import "moment/locale/pt-br";
import { useToast } from "../../../shared/hooks";
import { ToastContent } from "../../../shared/components";
import nfse from "../../../assets/nfse.png";
import iconProcess from "../../../assets/process.png";
import iconNfseCancel from "../../../assets/nfsecancel.png";
import iconCancel from "../../../assets/cancel.png";
import iconNfseReject from "../../../assets/nfsecancel.png";
import iconInCancel from "../../../assets/em_cancelamento.png";
import * as React from "react";
import { GenerateNFsModalPresentation } from "./GenerateNFsModalPresentation";
import { clientService } from "../../../shared/services/api/client/clientService";
import { returnMessageError } from "../../../shared/utils/returnMessageError";

function translateStatus(
    status: String,
    installmentNfseId: string,
    nfseStatus: string,
    nfseId: string,
    installmentStatus: string,
    nfseSituacao: string
) {
    console.log(
        status,
        installmentNfseId,
        nfseStatus,
        nfseId,
        installmentStatus,
        nfseSituacao
    );
    if (status === "expired") {
        return "Expirada";
    }
    if (status === "active") {
        return "Vigente";
    }
    if (status === "suspended") {
        return "Suspensa";
    }
    if (status === "renovated") {
        return "Renovada";
    }
    if (status === "canceled") {
        return "Cancelada";
    }
    if (status === "pending") {
        return "Faturada";
    }
    if (status === "open") {
        return "Aberto";
    }
    if (status === "paid") {
        if (!installmentNfseId) return "A Emitir";
        else if (nfseStatus == "requested") return "Em emissão";
        else if (
            nfseStatus == "issued" ||
            (nfseStatus && ["CONCLUIDO", "Autorizada"].includes(nfseSituacao))
        )
            return "Emitida";
        else if (nfseStatus == "incancellation") return "Em cancelamento";
        else if (nfseStatus == "canceled") return "Cancelada";
        else if (nfseStatus == "rejected") return "Não autorizada";
        else if (
            !nfseStatus &&
            !["CONCLUIDO", "Autorizada"].includes(nfseSituacao)
        )
            return "Nfse com erro";
        else return "Nfse com erro";
    }
    return status;
}

function colorLine(line: number) {
    if (line % 2 == 0) {
        return "#e5e8eb";
    }
    return "#ffffff";
}

const maskReal = (value: string) => {
    return value
        .replace(/\D/g, "")
        .replace(/(\d{1,2})$/, ",$1")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

type NfseUI = INfse & { checked: boolean };

export const NfseListPresentation = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { debounce } = useDebounce();
    const navigate = useNavigate();
    const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } =
        useVForm();
    const [rows, setRows] = useState<NfseUI[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [layoutFinan, setLayoutFinan] = useState("nfse");
    const [modalSend, setModalSend] = useState(false);
    const [modalCancel, setModalCancel] = useState(false);
    const [modalCancelarNfse, setModalCancelarNfse] = useState(false);
    const [modalCanceled, setModalCanceled] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [message, setMessage] = useState("");
    const [messageRejected, setMessageRejected] = useState("");
    const [messageError, setMessageError] = useState("");
    const [loadEmail, setLoadEmail] = useState("");
    const [loadURL, setLoadURL] = useState("");
    const [loadURLXML, setLoadURLXML] = useState("");
    const [loadId, setLoadId] = useState("");
    const [loadNfse, setLoadNfse] = useState("");
    const [loadInstallment, setLoadInstallment] = useState("");
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
    const [reasonCancel, setReasonCancel] = useState("");
    const [documentGuid, setDocumentGuid] = useState("");
    const [gateway, setGateway] = useState("GRIDWEBTI");
    const [refreshGateway, setRefreshGateway] = useState(false);
    const toastHandler = useToast();
    const [refreshList, setRefreshList] = useState(false);
    const [generalCheckboxSelected, setGeneralCheckboxSelected] =
        useState(false);
    const [isGenerateNFModalOpen, setIsGenerateNFModalOpen] = useState(false);
    const [clientOptionsList, setClientOptionsList] = useState<
        TAutoCompleteOption[]
    >([]);
    const [selectedClient, setSelectedClient] =
        useState<TAutoCompleteOption | null>(null);
    const [clientSearchText, setClientSearchText] = useState("");
    const [pageSize, setPageSize] = useState(Environment.LIMITE_LINHAS);
    const isNFSelectable = useCallback((row: NfseUI): boolean => {
        return row.invoice_status === "paid" && row.nfse_id === null;
    }, []);

    const selectedIds: Set<string> = useMemo(() => {
        return new Set<string>();
    }, []);

    const busca = useMemo(() => {
        return searchParams.get("busca") || "";
    }, [searchParams]);

    const pagina = useMemo(() => {
        return Number(searchParams.get("pagina") || "1");
    }, [searchParams]);

    const status = useMemo(() => {
        return searchParams.get("status") || "Todos";
    }, [searchParams]);

    const dataIni = useMemo(() => {
        return (
            searchParams.get("dataini") ||
            moment().startOf("month").format("YYYY-MM-DD")
        );
    }, [searchParams]);

    const dataFim = useMemo(() => {
        return (
            searchParams.get("datafim") ||
            moment().endOf("month").format("YYYY-MM-DD")
        );
    }, [searchParams]);

    const clientId = useMemo(() => {
        return searchParams.get("clientId") ?? "";
    }, [searchParams]);

    const filter = useMemo(() => {
        return searchParams.get("filter") ?? "Todos";
    }, [searchParams]);

    const handleOpenGenerateNFModal = useCallback(
        () => setIsGenerateNFModalOpen(true),
        [setIsGenerateNFModalOpen]
    );
    const handleCloseGenerateNFModal = useCallback(
        () => setIsGenerateNFModalOpen(false),
        [setIsGenerateNFModalOpen]
    );

    // const URL = 'https://appweb-staging.appweb.garantti.com.br'
    const URL = window.location.protocol + "//" + window.location.host;

    useEffect(() => {
        setIsLoading(true);

        clientService
            .getSelect(
                1,
                clientSearchText?.replace("(Inativo)", "")?.trim(),
                "",
                "",
                ""
            )
            .then((result) => {
                setIsLoading(false);
                if (result instanceof Error) {
                } else {
                    setClientOptionsList(
                        result.data.map((client) => ({
                            id: client.id,
                            label:
                                client.status == "inactive"
                                    ? `${client.name} (Inativo)`
                                    : client.name,
                        }))
                    );
                }
            });
    }, [clientSearchText]);

    useEffect(() => {
        if (isLoading) {
            setRows([]);
            setTotalCount(0);
        }
    }, [isLoading]);

    const getNfse = () => {
        setIsLoading(true);
        debounce(() => {
            FinancialService.getNfse(
                pagina,
                busca,
                status,
                dataIni,
                dataFim,
                clientId,
                pageSize,
                filter
            ).then((result: any) => {
                setIsLoading(false);
                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    setTotalCount(Number(result.totalCount));

                    const rows = result.data.map((row: any) => {
                        return {
                            ...row,
                            checked: selectedIds.has(row.Installment_id),
                        };
                    });

                    const selectableRows = rows.filter((row: any) =>
                        isNFSelectable(row)
                    );
                    const selectedRows = selectableRows.filter(
                        (row: any) => row.checked
                    );

                    setGeneralCheckboxSelected(
                        selectableRows.length === selectedRows.length
                    );
                    setRows(rows);
                }
            });
        });
    };

    useEffect(() => {
        getNfse();
    }, [
        pagina,
        busca,
        status,
        dataIni,
        dataFim,
        refreshList,
        clientId,
        pageSize,
        filter,
    ]);

    useEffect(() => {
        selectedIds.clear();
        rows?.forEach((row) => {
            row.checked = false;
        });

        setRows(rows);
        setGeneralCheckboxSelected(false);
    }, [
        busca,
        status,
        dataIni,
        dataFim,
        refreshList,
        clientId,
        pageSize,
        filter,
    ]);

    useEffect(() => {
        setGateway(refreshGateway == false ? "GRIDWEBTI" : "PLUGNOTAS");
    }, [refreshGateway]);

    const handleSuccessfulGenerateNFCall = (id: string) => {
        //TODO remover depois de homologar
        console.log(`${id} gerado com sucesso`);
    };

    const handleFailedGenerateNFCall = (id: string) => {
        //TODO remover depois de homologar
        console.log(`${id} - Erro`);
    };

    const handleCompletedGenerateNFCall = () => {
        setIsGenerateNFModalOpen(false);
        setRefreshList(!refreshList);
    };

    const handleCriateNfse = (idBIllet: string) => {
        setIsLoading(true);
        debounce(() => {
            FinancialService.generateNfse(idBIllet)
                .then((result) => {
                    toastHandler.present({
                        type: "success",
                        position: "top-right",
                        messageOrContent: (
                            <ToastContent
                                title={"Sucesso"}
                                content={"Nota gerada com sucesso"}
                            />
                        ),
                    });
                    setRefreshList(!refreshList);
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    setRefreshList(!refreshList);
                    const { titleMessage, contentMessage } =
                        returnMessageError(err);

                    toastHandler.present({
                        type: "error",
                        position: "top-right",
                        messageOrContent: (
                            <ToastContent
                                title={titleMessage}
                                content={contentMessage}
                            />
                        ),
                    });
                })
                .finally(() => setIsLoading(false));
        });
    };

    const handleNfsePdf = (id: string) => {
        setIsLoading(true);
        debounce(() => {
            FinancialService.getNfsePDF(id)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: "error",
                            position: "top-right",
                            messageOrContent: (
                                <ToastContent
                                    title={titleMessage}
                                    content={contentMessage}
                                />
                            ),
                        });
                    } else {
                        //setModalDetail(true)
                    }
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    const { titleMessage, contentMessage } =
                        returnMessageError(err);

                    toastHandler.present({
                        type: "error",
                        position: "top-right",
                        messageOrContent: (
                            <ToastContent
                                title={titleMessage}
                                content={contentMessage}
                            />
                        ),
                    });
                });
        });
    };

    const handleNfsePdfGridWebti = (id: string, urlNfse: string) => {
        setIsLoading(true);
        debounce(() => {
            FinancialService.getNfsePDFGridWebti(id, urlNfse)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: "error",
                            position: "top-right",
                            messageOrContent: (
                                <ToastContent
                                    title={titleMessage}
                                    content={contentMessage}
                                />
                            ),
                        });
                    }
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    const { titleMessage, contentMessage } =
                        returnMessageError(err);

                    toastHandler.present({
                        type: "error",
                        position: "top-right",
                        messageOrContent: (
                            <ToastContent
                                title={titleMessage}
                                content={contentMessage}
                            />
                        ),
                    });
                });
        });
    };

    const handleResendEmail = (idInvoice: string) => {
        // setIsLoading(true);
        // debounce(() => {
        // FinancialService.SendEmail(idInvoice)
        //     .then((result) => {
        //         setIsLoading(false);
        //         if (result instanceof Error) {
        //             alert(result.message);
        //         } else {
        //             toastHandler.present({
        //                 type: 'success',
        //                 position: 'top-right',
        //                 messageOrContent: <ToastContent title={'Sucesso'} content={'Nota enviado com sucesso'} />
        //             })
        //             setRefreshList(true);
        //         }
        //     });
        // });
    };

    const handleCheckboxRowChange = (row: NfseUI) => {
        let checked;

        if (selectedIds.has(row.Installment_id)) {
            selectedIds.delete(row.Installment_id);
            checked = false;
        } else {
            selectedIds.add(row.Installment_id);
            checked = true;
        }

        const uiRow = rows.find(
            (pageRow) => pageRow.Installment_id === row.Installment_id
        );

        if (uiRow) {
            uiRow.checked = checked;
        }

        const selectableRows = rows.filter((row) => isNFSelectable(row));
        const selectedRows = selectableRows.filter((row) => row.checked);

        setGeneralCheckboxSelected(
            selectableRows.length === selectedRows.length
        );
        setRows([...rows]);
    };

    const handleCancel = () => {
        setIsLoading(true);
        debounce(() => {
            FinancialService.cancelNfse(loadInstallment, loadNfse, reasonCancel)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: "error",
                            position: "top-right",
                            messageOrContent: (
                                <ToastContent
                                    title={titleMessage}
                                    content={contentMessage}
                                />
                            ),
                        });
                    } else {
                        //setModalDetail(true)
                        setRefreshList(!refreshList);
                    }
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    setRefreshList(!refreshList);
                    const { titleMessage, contentMessage } =
                        returnMessageError(err);

                    toastHandler.present({
                        type: "error",
                        position: "top-right",
                        messageOrContent: (
                            <ToastContent
                                title={titleMessage}
                                content={contentMessage}
                            />
                        ),
                    });
                });
        });
    };

    const handleCancelGridWebti = () => {
        setIsLoading(true);
        debounce(() => {
            FinancialService.cancelNfseGridWebti(
                loadInstallment,
                loadNfse,
                reasonCancel
            )
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: "error",
                            position: "top-right",
                            messageOrContent: (
                                <ToastContent
                                    title={titleMessage}
                                    content={contentMessage}
                                />
                            ),
                        });
                    } else {
                        setRefreshList(!refreshList);
                        toastHandler.present({
                            type: "success",
                            position: "top-right",
                            messageOrContent: (
                                <ToastContent
                                    title={"Sucesso"}
                                    content={
                                        "Processamento do cancelamento realizado!"
                                    }
                                />
                            ),
                        });
                    }
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    setRefreshList(!refreshList);
                    const { titleMessage, contentMessage } =
                        returnMessageError(err);

                    toastHandler.present({
                        type: "error",
                        position: "top-right",
                        messageOrContent: (
                            <ToastContent
                                title={titleMessage}
                                content={contentMessage}
                            />
                        ),
                    });
                });
        });
    };

    const handleCancelarNfse = () => {
        setIsLoading(true);
        debounce(() => {
            FinancialService.cancelarNfse(loadId, reasonCancel)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: "error",
                            position: "top-right",
                            messageOrContent: (
                                <ToastContent
                                    title={titleMessage}
                                    content={contentMessage}
                                />
                            ),
                        });
                    } else {
                        //setModalDetail(true)
                        setRefreshList(!refreshList);
                    }
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    setRefreshList(!refreshList);
                    const { titleMessage, contentMessage } =
                        returnMessageError(err);

                    toastHandler.present({
                        type: "error",
                        position: "top-right",
                        messageOrContent: (
                            <ToastContent
                                title={titleMessage}
                                content={contentMessage}
                            />
                        ),
                    });
                });
        });
    };

    const handleCancelarNfseGridWebti = () => {
        setIsLoading(true);
        debounce(() => {
            FinancialService.cancelarNfseGridWebti(
                loadId,
                reasonCancel,
                documentGuid
            )
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: "error",
                            position: "top-right",
                            messageOrContent: (
                                <ToastContent
                                    title={titleMessage}
                                    content={contentMessage}
                                />
                            ),
                        });
                    } else {
                        setRefreshList(!refreshList);
                    }
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    setRefreshList(!refreshList);
                    const { titleMessage, contentMessage } =
                        returnMessageError(err);

                    toastHandler.present({
                        type: "error",
                        position: "top-right",
                        messageOrContent: (
                            <ToastContent
                                title={titleMessage}
                                content={contentMessage}
                            />
                        ),
                    });
                });
        });
    };

    const handleUpdateStatusGridWebti = (nfse: NfseUI) => {
        setIsLoading(true);

        debounce(() => {
            FinancialService.updateNfseStatusGridWebti(nfse)
                .then((result: INfse | Error) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: "error",
                            position: "top-right",
                            messageOrContent: (
                                <ToastContent
                                    title={titleMessage}
                                    content={contentMessage}
                                />
                            ),
                        });
                    } else {
                        setRefreshList(!refreshList);
                        toastHandler.present({
                            type: "success",
                            position: "top-right",
                            messageOrContent: (
                                <ToastContent
                                    title={"Sucesso"}
                                    content={"Processado a emissão!"}
                                />
                            ),
                        });
                    }
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    setRefreshList(!refreshList);
                    const { titleMessage, contentMessage } =
                        returnMessageError(err);

                    toastHandler.present({
                        type: "error",
                        position: "top-right",
                        messageOrContent: (
                            <ToastContent
                                title={titleMessage}
                                content={contentMessage}
                            />
                        ),
                    });
                });
        });
    };

    const updateStatusCancelNfseGridWebti = (nfse: NfseUI) => {
        setIsLoading(true);

        debounce(() => {
            FinancialService.updateStatusCancelNfseGridWebti(
                nfse.nfse_id,
                nfse.nfse_nfse_protocol
            )
                .then((result: any) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: "error",
                            position: "top-right",
                            messageOrContent: (
                                <ToastContent
                                    title={titleMessage}
                                    content={contentMessage}
                                />
                            ),
                        });
                    } else {
                        setRefreshList(!refreshList);
                        toastHandler.present({
                            type: "success",
                            position: "top-right",
                            messageOrContent: (
                                <ToastContent
                                    title={"Sucesso"}
                                    content={
                                        "Verificado o status do cancelamento com sucesso!"
                                    }
                                />
                            ),
                        });
                    }
                })
                .catch((err: any) => {
                    setIsLoading(false);
                    setRefreshList(!refreshList);
                    const { titleMessage, contentMessage } =
                        returnMessageError(err);

                    toastHandler.present({
                        type: "error",
                        position: "top-right",
                        messageOrContent: (
                            <ToastContent
                                title={titleMessage}
                                content={contentMessage}
                            />
                        ),
                    });
                });
        });
    };

    const handleUpdate = (id: string, nfseId: string) => {
        setIsLoading(true);
        debounce(() => {
            //Rogerio vai mexer aqui
            FinancialService.updateNfse(id, nfseId).then((result) => {
                setIsLoading(false);
                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    //setModalDetail(true)
                    setRefreshList(!refreshList);
                }
            });
        });
    };

    const handleCheckboxGeneralChange = (checked: boolean) => {
        setGeneralCheckboxSelected(checked);

        for (const row of rows) {
            if (isNFSelectable(row)) {
                row.checked = checked;

                if (checked) {
                    selectedIds.add(row.Installment_id);
                } else {
                    selectedIds.delete(row.Installment_id);
                }
            }
        }

        setRows([...rows]);
    };

    const handleOnClientChange = (value: TAutoCompleteOption | null): void => {
        setSelectedClient(value);
        setSearchParams(
            {
                busca,
                pagina: String(1),
                dataini: dataIni,
                datafim: dataFim,
                clientId: value?.id ?? "",
                filter,
            },
            { replace: true }
        );
    };

    return (
        <LayoutBaseDePagina
            titulo="Notas Fiscais"
            subtitulo="Movimentação Financeira - Notas Fiscais"
            barraDeFerramentas={
                <ToolbarFinancial
                    onClickFaturamento={() => {
                        navigate(`/financeiro/`);
                    }}
                    onClickBoletos={() => {
                        navigate(`/boletos/`);
                        setLayoutFinan("installment");
                    }}
                    onClickNfse={() => setLayoutFinan("nfse")}
                    onClickRecebimento={() => setLayoutFinan("invoicing")}
                    onClickSetup={() => {
                        setLayoutFinan("setup");
                        navigate("/setup");
                    }}
                    selectBoletos={layoutFinan === "installment"}
                    selectFaturamento={layoutFinan === "invoicing"}
                    selectNfse={layoutFinan === "nfse"}
                    selectSetup={layoutFinan === "setup"}
                    showButtonReport={false}
                    //onClickReport={}
                    onChangeDataIni={(retorno: string) =>
                        setSearchParams(
                            {
                                busca,
                                pagina: String(1),
                                dataini: retorno,
                                datafim: dataFim,
                                clientId,
                                filter,
                            },
                            { replace: true }
                        )
                    }
                    onChangeDataFim={(retorno: string) =>
                        setSearchParams(
                            {
                                busca,
                                pagina: String(1),
                                dataini: dataIni,
                                datafim: retorno,
                                clientId,
                                filter,
                            },
                            { replace: true }
                        )
                    }
                />
            }
        >
            {isGenerateNFModalOpen && (
                <ModalContext
                    isCloseModal={handleCloseGenerateNFModal}
                    isOpenModal={isGenerateNFModalOpen}
                    messageType={"alert"}
                    showButtonCancel={false}
                    showButtonConfirm={false}
                    onclickConfirm={() => {}}
                    enableBackdropClose={false}
                    showTitleIcon={false}
                    IMessage={[
                        <GenerateNFsModalPresentation
                            nfInstallmentIds={Array.from(selectedIds.values())}
                            onSuccessfulCallHandler={
                                handleSuccessfulGenerateNFCall
                            }
                            onFailedCallHandler={handleFailedGenerateNFCall}
                            onCompletedHandler={handleCompletedGenerateNFCall}
                        />,
                    ]}
                />
            )}

            {modalSend && (
                <ModalContext
                    isCloseModal={() => setModalSend(false)}
                    isOpenModal={modalSend}
                    messageType={"alert"}
                    showButtonCancel={false}
                    textButtonConfirm={"Fechar"}
                    onclickConfirm={() => setModalSend(false)}
                    ITitle={"Nota Fiscal"}
                    IMessage={[
                        <>
                            <Box>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    gap={2}
                                    sx={{ mb: "2rem" }}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ alignSelf: "center" }}
                                    >
                                        Email cadastrado :
                                    </Typography>
                                    <Typography variant="h6">
                                        {loadEmail}
                                    </Typography>
                                </Grid>

                                <Grid container item direction="row" gap={2}>
                                    <Button
                                        color="info"
                                        disableElevation
                                        variant={"contained"}
                                        startIcon={<Icon> search</Icon>}
                                        onClick={() => {
                                            if (gateway == "GRIDWEBTI")
                                                handleNfsePdfGridWebti(
                                                    loadId,
                                                    loadURL
                                                );
                                            else handleNfsePdf(loadId);

                                            setModalSend(false);
                                            // window.open(loadURL)
                                        }}
                                    >
                                        {"Visualizar"}
                                    </Button>

                                    <Button
                                        color="info"
                                        disableElevation
                                        variant={"outlined"}
                                        startIcon={
                                            <Icon> mail_outlined_icon</Icon>
                                        }
                                        onClick={() => {
                                            setModalSend(false);
                                            handleResendEmail(loadId);
                                        }}
                                    >
                                        {"Reenviar por email"}
                                    </Button>

                                    <Button
                                        color="info"
                                        disableElevation
                                        variant={"outlined"}
                                        startIcon={<Icon> close</Icon>}
                                        onClick={() => {
                                            setModalSend(false);
                                        }}
                                    >
                                        {"Fechar"}
                                    </Button>
                                </Grid>
                            </Box>
                        </>,
                    ]}
                />
            )}

            {modalCanceled && (
                <ModalContext
                    isCloseModal={() => {
                        setMessage("");
                        setModalCanceled(false);
                    }}
                    isOpenModal={modalCanceled}
                    messageType={"info"}
                    showButtonCancel={false}
                    textButtonConfirm={"Fechar"}
                    onclickConfirm={() => {
                        setMessage("");
                        setModalCanceled(false);
                    }}
                    ITitle={"Nota Fiscal Cancelado"}
                    IMessage={[
                        <>
                            <Box>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    gap={2}
                                    sx={{ mb: "2rem" }}
                                >
                                    {message?.trim() && (
                                        <>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    alignSelf: "center",
                                                    fontWeight: "normal",
                                                }}
                                            >
                                                {message}
                                            </Typography>
                                        </>
                                    )}
                                    {loadURLXML?.trim() &&
                                        gateway != "PLUGNOTAS" && (
                                            <>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ alignSelf: "center" }}
                                                >
                                                    URL XML :
                                                </Typography>
                                                <Link
                                                    href={loadURLXML}
                                                    color="inherit"
                                                    target="_blank"
                                                    sx={{
                                                        textDecoration: "none",
                                                        cursor: "pointer",
                                                        alignSelf: "center",
                                                    }}
                                                >
                                                    {loadURLXML}
                                                </Link>
                                            </>
                                        )}

                                    {loadURL?.trim() &&
                                        gateway != "PLUGNOTAS" && (
                                            <>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{ alignSelf: "center" }}
                                                >
                                                    URL PDF :
                                                </Typography>
                                                <Link
                                                    href={loadURL}
                                                    color="inherit"
                                                    target="_blank"
                                                    sx={{
                                                        textDecoration: "none",
                                                        cursor: "pointer",
                                                        alignSelf: "center",
                                                    }}
                                                >
                                                    {loadURL}
                                                </Link>
                                            </>
                                        )}
                                </Grid>

                                <Grid container item direction="row" gap={2}>
                                    <Button
                                        color="info"
                                        disableElevation
                                        variant={"outlined"}
                                        startIcon={<Icon> close</Icon>}
                                        onClick={() => {
                                            setMessage("");
                                            setModalCanceled(false);
                                        }}
                                    >
                                        {"Fechar"}
                                    </Button>
                                </Grid>
                            </Box>
                        </>,
                    ]}
                />
            )}

            {modalError && (
                <ModalContext
                    isCloseModal={() => {
                        setMessageError("");
                        setModalError(false);
                    }}
                    isOpenModal={modalError}
                    messageType={"error"}
                    showButtonCancel={false}
                    textButtonConfirm={"Fechar"}
                    onclickConfirm={() => {
                        setMessageError("");
                        setModalError(false);
                    }}
                    ITitle={"Nota Fiscal - Inconsistência"}
                    IMessage={[
                        <>
                            <Box>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    gap={2}
                                    sx={{ mb: "2rem" }}
                                >
                                    {messageError?.trim() && (
                                        <>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    alignSelf: "center",
                                                    fontWeight: "normal",
                                                }}
                                            >
                                                {messageError}
                                            </Typography>
                                        </>
                                    )}
                                </Grid>

                                <Grid container item direction="row" gap={2}>
                                    <Button
                                        color="info"
                                        disableElevation
                                        variant={"outlined"}
                                        startIcon={<Icon> close</Icon>}
                                        onClick={() => {
                                            setMessageError("");
                                            setModalError(false);
                                        }}
                                    >
                                        {"Fechar"}
                                    </Button>
                                </Grid>
                            </Box>
                        </>,
                    ]}
                />
            )}

            {modalCancel && (
                <ModalContext
                    isCloseModal={() => {
                        setMessageRejected("");
                        setModalCancel(false);
                    }}
                    isOpenModal={modalCancel}
                    messageType={"alert"}
                    showButtonCancel={false}
                    textButtonConfirm={"Fechar"}
                    onclickConfirm={() => {
                        setMessageRejected("");
                        setModalCancel(false);
                    }}
                    ITitle={`Cancelar Nota Fiscal ${
                        gateway == "GRIDWEBTI" ? "Não autorizada" : "Rejeitada"
                    }`}
                    IMessage={[
                        <>
                            <Box>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    gap={2}
                                    sx={{ mb: "2rem" }}
                                >
                                    {messageRejected?.trim() && (
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                alignSelf: "center",
                                                fontWeight: "normal",
                                            }}
                                        >
                                            {messageRejected}
                                        </Typography>
                                    )}
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ alignSelf: "center" }}
                                    >
                                        Email cadastrado :
                                    </Typography>
                                    <Typography variant="h6">
                                        {loadEmail}
                                    </Typography>
                                </Grid>
                                <VForm
                                    id="formIdSave"
                                    placeholder="Your placeholder"
                                    onPointerEnterCapture={() =>
                                        console.log("Pointer entered")
                                    }
                                    onPointerLeaveCapture={() =>
                                        console.log("Pointer left")
                                    }
                                    ref={formRef}
                                    onSubmit={() => {
                                        if (gateway == "GRIDWEBTI") {
                                            handleCancelGridWebti();
                                            handleCancelarNfseGridWebti();
                                        } else {
                                            handleCancel();
                                            handleCancelarNfse();
                                        }
                                    }}
                                >
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        spacing={2}
                                    >
                                        <Grid item xs={12}>
                                            <VTextField
                                                fullWidth
                                                label="Descreva o motivo do Cancelamento"
                                                name="canceledReason"
                                                disabled={isLoading}
                                                onChange={(e) =>
                                                    setReasonCancel(
                                                        e.target.value
                                                    )
                                                }
                                                multiline
                                                rows={4}
                                            ></VTextField>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        sm={12}
                                        sx={{
                                            border: "none",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        display={"flex"}
                                        flexDirection={
                                            smDown ? "column" : "row"
                                        }
                                        padding={2}
                                        gap={2}
                                    >
                                        {/* <Grid container item direction="row" gap={2}> */}
                                        {reasonCancel != "" ? (
                                            <Button
                                                color="info"
                                                disableElevation
                                                variant={"contained"}
                                                startIcon={<Icon> cancel</Icon>}
                                                onClick={() => {
                                                    if (
                                                        gateway == "GRIDWEBTI"
                                                    ) {
                                                        handleCancelGridWebti();
                                                        handleCancelarNfseGridWebti();
                                                    } else {
                                                        handleCancel();
                                                        handleCancelarNfse();
                                                    }
                                                    setModalCancel(false);
                                                }}
                                            >
                                                {"Cancelar Nota"}
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                        {/* <Button
                                        color="info"
                                        disableElevation
                                        variant={"contained"}
                                        startIcon={< Icon > cancel</Icon >}
                                        onClick={() => {
                                            setModalCancel(false)
                                            handleNfsePdf(loadNfse)
                                           // window.open(loadURL)
                                        }}
                                    >
                                        {'Visualizar'}
                                    </Button> */}

                                        {/* <Button
                                        color="info"
                                        disableElevation
                                        variant={"outlined"}
                                        startIcon={< Icon > mail_outlined_icon</Icon >}
                                        onClick={() => {
                                            setModalCancel(false)
                                            handleResendEmail(loadNfse)
                                        }}
                                    >
                                        {'Reenviar por email'}
                                    </Button> */}

                                        <Button
                                            color="info"
                                            disableElevation
                                            variant={"outlined"}
                                            startIcon={<Icon> close</Icon>}
                                            onClick={() => {
                                                setMessageRejected("");
                                                setModalCancel(false);
                                            }}
                                        >
                                            {"Fechar"}
                                        </Button>
                                    </Grid>
                                </VForm>
                            </Box>
                        </>,
                    ]}
                />
            )}

            {modalCancelarNfse && (
                <ModalContext
                    isCloseModal={() => setModalCancelarNfse(false)}
                    isOpenModal={modalCancelarNfse}
                    messageType={"alert"}
                    showButtonCancel={false}
                    textButtonConfirm={"Fechar"}
                    onclickConfirm={() => {}}
                    ITitle={`Cancelar Nota Fiscal ${
                        gateway == "GRIDWEBTI" ? "Autorizada" : "Concluida"
                    }`}
                    IMessage={[
                        <>
                            <Box>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    gap={2}
                                    sx={{ mb: "2rem" }}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ alignSelf: "center" }}
                                    >
                                        Email cadastrado :
                                    </Typography>
                                    <Typography variant="h6">
                                        {loadEmail}
                                    </Typography>
                                </Grid>
                                <VForm
                                    id="formId"
                                    placeholder="Your placeholder"
                                    onPointerEnterCapture={() =>
                                        console.log("Pointer entered")
                                    }
                                    onPointerLeaveCapture={() =>
                                        console.log("Pointer left")
                                    }
                                    ref={formRef}
                                    onSubmit={() => {
                                        if (gateway == "GRIDWEBTI") {
                                            handleCancelGridWebti();
                                            handleCancelarNfseGridWebti();
                                        } else {
                                            handleCancel();
                                            handleCancelarNfse();
                                        }
                                    }}
                                >
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        spacing={2}
                                    >
                                        <Grid item xs={12}>
                                            <VTextField
                                                fullWidth
                                                label="Descreva o motivo do Cancelamento"
                                                name="canceledReasonNfse"
                                                disabled={isLoading}
                                                onChange={(e) =>
                                                    setReasonCancel(
                                                        e.target.value
                                                    )
                                                }
                                                multiline
                                                rows={4}
                                            ></VTextField>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        sm={12}
                                        sx={{
                                            border: "none",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        display={"flex"}
                                        flexDirection={
                                            smDown ? "column" : "row"
                                        }
                                        padding={2}
                                        gap={2}
                                    >
                                        {/* <Grid container item direction="row" gap={2}> */}
                                        {reasonCancel != "" ? (
                                            <Button
                                                color="info"
                                                disableElevation
                                                variant={"contained"}
                                                startIcon={<Icon> cancel</Icon>}
                                                onClick={() => {
                                                    if (
                                                        gateway == "GRIDWEBTI"
                                                    ) {
                                                        handleCancelGridWebti();
                                                        handleCancelarNfseGridWebti();
                                                    } else {
                                                        handleCancel();
                                                        handleCancelarNfse();
                                                    }
                                                    setModalCancelarNfse(false);
                                                }}
                                            >
                                                {"Cancelar Nota"}
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                        {/* <Button
                                        color="info"
                                        disableElevation
                                        variant={"contained"}
                                        startIcon={< Icon > cancel</Icon >}
                                        onClick={() => {
                                            setModalCancel(false)
                                            handleNfsePdf(loadNfse)
                                           // window.open(loadURL)
                                        }}
                                    >
                                        {'Visualizar'}
                                    </Button> */}

                                        {/* <Button
                                        color="info"
                                        disableElevation
                                        variant={"outlined"}
                                        startIcon={< Icon > mail_outlined_icon</Icon >}
                                        onClick={() => {
                                            setModalCancel(false)
                                            handleResendEmail(loadNfse)
                                        }}
                                    >
                                        {'Reenviar por email'}
                                    </Button> */}

                                        <Button
                                            color="info"
                                            disableElevation
                                            variant={"outlined"}
                                            startIcon={<Icon> close</Icon>}
                                            onClick={() => {
                                                setModalCancelarNfse(false);
                                            }}
                                        >
                                            {"Fechar"}
                                        </Button>
                                    </Grid>
                                </VForm>
                            </Box>
                        </>,
                    ]}
                />
            )}

            <TableContainer
                component={Paper}
                variant="outlined"
                sx={{
                    marginLeft: 1,
                    marginTop: 1,
                    marginRight: 2,
                    width: "auto",
                    padding: 4,
                }}
            >
                <Grid
                    container
                    item
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item sm={8} lg={9}>
                        <Grid container item alignItems="center">
                            <Grid
                                item
                                xs={7}
                                md={5}
                                lg={3}
                                xl={2}
                                style={{
                                    paddingBottom: "23px",
                                    marginRight: "8px",
                                }}
                            >
                                <InputLabel id="page-size-label">
                                    Registros por Página
                                </InputLabel>
                                <Select
                                    labelId="page-size-label"
                                    id="page-size"
                                    value={pageSize.toString()}
                                    size="small"
                                    label="Registros por página"
                                    onChange={(event: SelectChangeEvent) =>
                                        event.target?.value
                                            ? setPageSize(
                                                  Number(event.target.value)
                                              )
                                            : 5
                                    }
                                    style={{
                                        minWidth: "100%",
                                    }}
                                >
                                    <MenuItem value={5}>{5}</MenuItem>
                                    <MenuItem value={10}>{10}</MenuItem>
                                    <MenuItem value={25}>{25}</MenuItem>
                                    <MenuItem value={50}>{50}</MenuItem>
                                    <MenuItem value={100}>{100}</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={4} lg={3} alignItems="center">
                                <Autocomplete
                                    fullWidth
                                    openText="Abrir"
                                    closeText="Fechar"
                                    noOptionsText="Nenhuma opção"
                                    loadingText="Loading ..."
                                    disablePortal
                                    value={selectedClient}
                                    options={clientOptionsList}
                                    loading={isLoading}
                                    size={"small"}
                                    onInputChange={(_, newValue) => {
                                        setClientSearchText(newValue);
                                    }}
                                    onChange={(event, newValue) => {
                                        handleOnClientChange(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Cliente"
                                            name="cliente"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sm={4} lg={3} xl={2}>
                        <Grid
                            container
                            item
                            display="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    color="secondary"
                                    disableElevation
                                    disabled={selectedIds.size === 0}
                                    variant={"contained"}
                                    onClick={() => {
                                        setIsGenerateNFModalOpen(true);
                                    }}
                                    startIcon={<Icon>money_outlined_icon</Icon>}
                                >
                                    Gerar{" "}
                                    {selectedIds.size > 1
                                        ? "Notas Fiscais"
                                        : "Nota Fiscal"}
                                </Button>

                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "center" }}
                                >
                                    <Typography
                                        color={"primary"}
                                        style={{
                                            fontWeight: "bold",
                                            marginTop: "8px",
                                        }}
                                    >
                                        {selectedIds.size}{" "}
                                        {selectedIds.size > 1
                                            ? "Selecionados"
                                            : "Selecionado"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <hr style={{ opacity: 0.25 }} />

                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={7} sx={{ paddingLeft: 0 }}>
                                <ToolbarGridFinancial
                                    textSearchGrid={busca}
                                    onChangeTextSearchGrid={(text) => {
                                        setSearchParams(
                                            {
                                                busca: text,
                                                pagina: "1",
                                                status: status,
                                                dataini: dataIni,
                                                datafim: dataFim,
                                                clientId,
                                                filter,
                                            },
                                            { replace: true }
                                        );

                                        setRefreshList(!refreshList);
                                    }}
                                    onClickAllN={() => {
                                        setSearchParams(
                                            {
                                                busca,
                                                pagina: String(1),
                                                status: "",
                                                dataini: dataIni,
                                                datafim: dataFim,
                                                clientId,
                                                filter: "Todos",
                                            },
                                            { replace: true }
                                        );

                                        setRefreshList(!refreshList);
                                    }}
                                    onClickOpenN={() => {
                                        setSearchParams(
                                            {
                                                busca,
                                                pagina: String(1),
                                                status,
                                                dataini: dataIni,
                                                datafim: dataFim,
                                                clientId,
                                                filter: "open",
                                            },
                                            { replace: true }
                                        );

                                        setRefreshList(!refreshList);
                                    }}
                                    onClickBilled={() => {
                                        setSearchParams(
                                            {
                                                busca,
                                                pagina: String(1),
                                                status: "pending",
                                                dataini: dataIni,
                                                datafim: dataFim,
                                                clientId,
                                                filter,
                                            },
                                            { replace: true }
                                        );

                                        setRefreshList(!refreshList);
                                    }}
                                    onClickCanceledN={() => {
                                        setSearchParams(
                                            {
                                                busca,
                                                pagina: String(1),
                                                status,
                                                dataini: dataIni,
                                                datafim: dataFim,
                                                clientId,
                                                filter: "canceled",
                                            },
                                            { replace: true }
                                        );

                                        setRefreshList(!refreshList);
                                    }}
                                    onClickPaidN={() => {
                                        setSearchParams(
                                            {
                                                busca,
                                                pagina: String(1),
                                                status,
                                                dataini: dataIni,
                                                datafim: dataFim,
                                                clientId,
                                                filter: "paid",
                                            },
                                            { replace: true }
                                        );

                                        setRefreshList(!refreshList);
                                    }}
                                    onClickNotPaid={() => {
                                        setSearchParams(
                                            {
                                                busca,
                                                pagina: String(1),
                                                status,
                                                dataini: dataIni,
                                                datafim: dataFim,
                                                clientId,
                                                filter: "not_paid",
                                            },
                                            { replace: true }
                                        );

                                        setRefreshList(!refreshList);
                                    }}
                                    onClickIssued={() => {
                                        setSearchParams(
                                            {
                                                busca,
                                                pagina: String(1),
                                                status,
                                                dataini: dataIni,
                                                datafim: dataFim,
                                                clientId,
                                                filter: "issued",
                                            },
                                            { replace: true }
                                        );

                                        setRefreshList(!refreshList);
                                    }}
                                    onClickRejected={() => {
                                        setSearchParams(
                                            {
                                                busca,
                                                pagina: String(1),
                                                status,
                                                dataini: dataIni,
                                                datafim: dataFim,
                                                clientId,
                                                filter: "rejected",
                                            },
                                            { replace: true }
                                        );

                                        setRefreshList(!refreshList);
                                    }}
                                    showButtonBilled={false}
                                    showButtonReport={false}
                                    showButtonAll={false}
                                    showButtonCanceled={false}
                                    showButtonOpen={false}
                                    showButtonPaid={false}
                                    showButtonAllN={true}
                                    showButtonCanceledN={true}
                                    showButtonIssued={true}
                                    showButtonNotPaid={false}
                                    showButtonOpenN={true}
                                    showButtonPaidN={true}
                                    showButtonRejected={true}
                                    selectAllN={filter === "Todos"}
                                    selectOpenN={filter === "open"}
                                    // selectBilled={status === "pending"}
                                    selectCanceledN={filter === "canceled"}
                                    selectPaidN={filter === "paid"}
                                    selectNotPaid={filter === "not_paid"}
                                    selectIssued={filter === "issued"}
                                    selectRejected={filter === "rejected"}
                                    // onClickButtonReport={() => { }}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow sx={{ background: "#f5fbfd" }}>
                            <TableCell>
                                <Checkbox
                                    checked={generalCheckboxSelected}
                                    color="secondary"
                                    inputProps={{
                                        "aria-label": "select all desserts",
                                    }}
                                    onChange={(event) => {
                                        handleCheckboxGeneralChange(
                                            event.target.checked
                                        );
                                    }}
                                />
                            </TableCell>
                            <TableCell sx={{ color: "#7e7777b5" }}>
                                {" "}
                                Cliente
                            </TableCell>
                            <TableCell sx={{ color: "#7e7777b5" }}>
                                {" "}
                                Parceiro
                            </TableCell>
                            <TableCell sx={{ color: "#7e7777b5" }}>
                                {" "}
                                Endereço
                            </TableCell>
                            <TableCell sx={{ color: "#7e7777b5" }}>
                                {" "}
                                Vencimento
                            </TableCell>
                            <TableCell sx={{ color: "#7e7777b5" }}>
                                {" "}
                                Valor
                            </TableCell>
                            <TableCell
                                sx={{ color: "#7e7777b5", width: "12%" }}
                            >
                                {" "}
                            </TableCell>
                            <TableCell
                                sx={{ color: "#7e7777b5", width: "5px" }}
                            >
                                {" "}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row2) => (
                            <TableRow hover key={row2.nfse_invoiceId}>
                                <TableCell>
                                    <Checkbox
                                        color="secondary"
                                        inputProps={{
                                            "aria-label": "select all desserts",
                                        }}
                                        checked={row2.checked}
                                        disabled={!isNFSelectable(row2)}
                                        onChange={() =>
                                            handleCheckboxRowChange(row2)
                                        }
                                    />
                                </TableCell>
                                <TableCell sx={{ fontWeight: "normal" }}>
                                    {row2.tenant_name}
                                    {row2.tenant_name && (
                                        <Typography
                                            variant="subtitle2"
                                            color={"#959595"}
                                            fontWeight={"normal"}
                                        >
                                            {row2.tenant_document?.length > 11
                                                ? row2.tenant_document.replace(
                                                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                                                      "$1.$2.$3/$4-$5"
                                                  )
                                                : row2.tenant_document.replace(
                                                      /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                                                      "$1.$2.$3-$4"
                                                  )}
                                        </Typography>
                                    )}
                                </TableCell>

                                <TableCell sx={{ textAlign: "start" }}>
                                    <Typography
                                        variant="subtitle2"
                                        marginLeft={1}
                                        sx={{ fontWeight: "normal" }}
                                    >
                                        {row2.client_name}
                                        {row2.client_status == "inactive" &&
                                            " (Inativo)"}
                                    </Typography>
                                </TableCell>

                                <TableCell
                                    sx={{
                                        color: "#959595",
                                        fontWeight: "normal",
                                    }}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        color={"#959595"}
                                        fontWeight={"normal"}
                                    >
                                        {row2.address_street != null &&
                                        row2.address_street != ""
                                            ? `${
                                                  row2.address_complement !=
                                                      null &&
                                                  row2.address_complement != ""
                                                      ? `${row2.address_street} , ${row2.address_number} - ${row2.address_complement} - ${row2.address_district}`
                                                      : `${row2.address_street} , ${row2.address_number} - ${row2.address_district}`
                                              }`
                                            : "Endereço não cadastrado"}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color={"#959595"}
                                        fontWeight={"normal"}
                                    >
                                        {row2.address_city != null &&
                                        row2.address_city != ""
                                            ? `${row2.address_city} - ${row2.address_state}`
                                            : ""}
                                    </Typography>
                                </TableCell>

                                <TableCell sx={{ textAlign: "start" }}>
                                    <Typography
                                        variant="subtitle2"
                                        marginLeft={1}
                                        sx={{ fontWeight: "normal" }}
                                    >
                                        {moment(row2.invoice_due_date).format(
                                            "L"
                                        )}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        marginLeft={1}
                                        sx={{ fontWeight: "normal" }}
                                    >
                                        {translateStatus(
                                            row2.invoice_status,
                                            row2.Installment_nfseId,
                                            row2.nfse_status,
                                            row2.nfse_id,
                                            row2.Installment_status,
                                            row2.nfse_nfse_situacao
                                        )}
                                    </Typography>
                                </TableCell>

                                <TableCell sx={{ textAlign: "start" }}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ fontWeight: "normal" }}
                                    >
                                        {parseFloat(
                                            row2.Installment_amount
                                        ).toLocaleString("pt-br", {
                                            style: "currency",
                                            currency: "BRL",
                                        })}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        color={"#959595"}
                                        sx={{ fontWeight: "normal" }}
                                    >
                                        {row2.nfse_gateway}
                                    </Typography>
                                </TableCell>

                                <TableCell sx={{ textAlign: "end" }}>
                                    {row2.nfse_id != null ? (
                                        <Box
                                            display={"flex"}
                                            sx={{
                                                flexDirection: "column",
                                                textAlign: "center",
                                            }}
                                        >
                                            <Tooltip
                                                title={`${
                                                    !row2.nfse_status &&
                                                    ![
                                                        "CONCLUIDO",
                                                        "Autorizada",
                                                    ].includes(
                                                        row2.nfse_nfse_situacao
                                                    )
                                                        ? "Erro com inconsistência, pois não foi possível processar o status desta Nfse."
                                                        : [
                                                              "REJEITADO",
                                                              "Não autorizada",
                                                          ].includes(
                                                              row2.nfse_nfse_situacao
                                                          )
                                                        ? row2.nfse_nfse_mensagem
                                                        : [
                                                              "authorized",
                                                              "issued",
                                                          ].includes(
                                                              row2.nfse_status
                                                          ) &&
                                                          [
                                                              "CONCLUIDO",
                                                              "Autorizada",
                                                          ].includes(
                                                              row2.nfse_nfse_situacao
                                                          )
                                                        ? "Segunda Via Nota Fiscal"
                                                        : row2.nfse_status ==
                                                              "requested" &&
                                                          [
                                                              "PROCESSANDO",
                                                              "Em processamento",
                                                              "0",
                                                          ].includes(
                                                              row2.nfse_nfse_situacao
                                                          )
                                                        ? "Processando... "
                                                        : row2.nfse_nfse_situacao ==
                                                          "Não processada"
                                                        ? "Ainda não foi processada..."
                                                        : [
                                                              "CANCELADO",
                                                              "Cancelada",
                                                          ].includes(
                                                              row2.nfse_nfse_situacao
                                                          )
                                                        ? "Esta Nfse foi feito cancelamento."
                                                        : row2.nfse_status ==
                                                          "incancellation"
                                                        ? row2.nfse_nfse_situacao
                                                        : row2.nfse_status ==
                                                              "requested" &&
                                                          [
                                                              "CONCLUIDO",
                                                              "Autorizada",
                                                          ].includes(
                                                              row2.nfse_nfse_situacao
                                                          )
                                                        ? "Em emissão com a situação: " +
                                                          row2.nfse_nfse_situacao
                                                        : "Erro emitido com inconsistência, pois está sem conter o valor no status desta nfse."
                                                }`}
                                                placement="top-start"
                                            >
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        [
                                                            "authorized",
                                                            "issued",
                                                        ].includes(
                                                            row2.nfse_status
                                                        ) &&
                                                            [
                                                                "CONCLUIDO",
                                                                "Autorizada",
                                                            ].includes(
                                                                row2.nfse_nfse_situacao
                                                            ) &&
                                                            setModalSend(true);

                                                        if (
                                                            row2.nfse_status &&
                                                            [
                                                                "REJEITADO",
                                                                "Não autorizada",
                                                            ].includes(
                                                                row2.nfse_nfse_situacao
                                                            )
                                                        ) {
                                                            setMessageRejected(
                                                                `Situação => ${row2.nfse_nfse_situacao}   , mensagem => ${row2.nfse_nfse_mensagem}`
                                                            );
                                                            setModalCancel(
                                                                true
                                                            );
                                                        }

                                                        setLoadId(row2.nfse_id);
                                                        setLoadNfse(
                                                            row2.nfse_nfseId
                                                        );
                                                        setLoadEmail(
                                                            row2.email
                                                        );
                                                        setLoadInstallment(
                                                            row2.Installment_id
                                                        );
                                                        setGateway(
                                                            row2.nfse_gateway
                                                        );
                                                        setLoadURL(
                                                            row2.nfse_nfse_urL_pdf
                                                        );
                                                        setLoadURLXML(
                                                            row2.nfse_nfse_url_xml
                                                        );

                                                        if (
                                                            !row2.nfse_status &&
                                                            [
                                                                "CONCLUIDO",
                                                                "Autorizada",
                                                            ].includes(
                                                                row2.nfse_nfse_situacao
                                                            )
                                                        ) {
                                                            setMessageError(
                                                                `Erro emitido inconsistência => ${
                                                                    row2.nfse_nfse_situacao
                                                                }   , mensagem => ${
                                                                    row2.nfse_nfse_mensagem
                                                                        ? row2.nfse_nfse_mensagem +
                                                                          " , com inconsistência sem conter valor no status!"
                                                                        : " com inconsistência sem conter valor no status!"
                                                                } `
                                                            );
                                                            setModalError(true);
                                                        } else if (
                                                            !row2.nfse_status &&
                                                            ![
                                                                "CONCLUIDO",
                                                                "Autorizada",
                                                            ].includes(
                                                                row2.nfse_nfse_situacao
                                                            )
                                                        ) {
                                                            setMessageError(
                                                                `Erro inconsistência => ${
                                                                    row2.nfse_nfse_situacao
                                                                }   , mensagem => ${
                                                                    row2.nfse_nfse_mensagem
                                                                        ? row2.nfse_nfse_mensagem +
                                                                          " , com inconsistência!"
                                                                        : " com inconsistência!"
                                                                } `
                                                            );
                                                            setModalError(true);
                                                        }

                                                        if (
                                                            [
                                                                "CANCELADO",
                                                                "Cancelada",
                                                            ].includes(
                                                                row2.nfse_nfse_situacao
                                                            )
                                                        ) {
                                                            setMessage(
                                                                `Situação => ${row2.nfse_nfse_situacao}    , mensagem => ${row2.nfse_nfse_mensagem}`
                                                            );
                                                            setModalCanceled(
                                                                true
                                                            );
                                                        }

                                                        if (
                                                            row2.nfse_gateway ==
                                                                "GRIDWEBTI" &&
                                                            row2.nfse_status ==
                                                                "incancellation"
                                                        ) {
                                                            setRefreshGateway(
                                                                false
                                                            );
                                                            updateStatusCancelNfseGridWebti(
                                                                row2
                                                            );
                                                        }

                                                        if (
                                                            row2.nfse_gateway ==
                                                                "GRIDWEBTI" &&
                                                            ((row2.nfse_status ==
                                                                "requested" &&
                                                                [
                                                                    "CONCLUIDO",
                                                                    "Autorizada",
                                                                ].includes(
                                                                    row2.nfse_nfse_situacao
                                                                )) ||
                                                                [
                                                                    "PROCESSANDO",
                                                                    "Em processamento",
                                                                    "Não processada",
                                                                    "0",
                                                                ].includes(
                                                                    row2.nfse_nfse_situacao
                                                                ))
                                                        ) {
                                                            setRefreshGateway(
                                                                false
                                                            );
                                                            handleUpdateStatusGridWebti(
                                                                row2
                                                            );
                                                        } else if (
                                                            row2.nfse_gateway ==
                                                                "PLUGNOTAS" &&
                                                            ((row2.nfse_status ==
                                                                "requested" &&
                                                                [
                                                                    "CONCLUIDO",
                                                                    "Autorizada",
                                                                ].includes(
                                                                    row2.nfse_nfse_situacao
                                                                )) ||
                                                                [
                                                                    "PROCESSANDO",
                                                                    "Em processamento",
                                                                ].includes(
                                                                    row2.nfse_nfse_situacao
                                                                ))
                                                        ) {
                                                            setRefreshGateway(
                                                                true
                                                            );
                                                            handleUpdate(
                                                                row2.nfse_id,
                                                                row2.nfse_nfseId
                                                            );
                                                        }
                                                    }}
                                                    sx={{ borderRadius: "8px" }}
                                                >
                                                    <img
                                                        src={
                                                            row2.nfse_status ==
                                                            "incancellation"
                                                                ? iconInCancel
                                                                : !row2.nfse_status &&
                                                                  ![
                                                                      "CONCLUIDO",
                                                                      "Autorizada",
                                                                  ].includes(
                                                                      row2.nfse_nfse_situacao
                                                                  )
                                                                ? iconNfseReject
                                                                : [
                                                                      "authorized",
                                                                      "issued",
                                                                  ].includes(
                                                                      row2.nfse_status
                                                                  ) &&
                                                                  [
                                                                      "CONCLUIDO",
                                                                      "Autorizada",
                                                                  ].includes(
                                                                      row2.nfse_nfse_situacao
                                                                  )
                                                                ? nfse
                                                                : [
                                                                      "CANCELADO",
                                                                      "Cancelada",
                                                                  ].includes(
                                                                      row2.nfse_nfse_situacao
                                                                  )
                                                                ? iconCancel
                                                                : [
                                                                      "REJEITADO",
                                                                      "Não autorizada",
                                                                  ].includes(
                                                                      row2.nfse_nfse_situacao
                                                                  )
                                                                ? iconNfseReject
                                                                : !row2.nfse_status &&
                                                                  [
                                                                      "CONCLUIDO",
                                                                      "Autorizada",
                                                                  ].includes(
                                                                      row2.nfse_nfse_situacao
                                                                  )
                                                                ? iconNfseReject
                                                                : iconProcess
                                                        }
                                                        alt="cont"
                                                        width={
                                                            smDown
                                                                ? "55%"
                                                                : mdDown
                                                                ? "35%"
                                                                : lgDown
                                                                ? "25%"
                                                                : "15%"
                                                        }
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Typography
                                                variant="caption"
                                                marginLeft={1}
                                                sx={{ fontWeight: "normal" }}
                                            >
                                                {row2.nfse_nfse_emissao
                                                    ? `${moment(
                                                          row2.nfse_nfse_emissao
                                                      ).format("L")}`
                                                    : ""}
                                            </Typography>
                                        </Box>
                                    ) : row2.invoice_status == "paid" &&
                                      !row2.Installment_nfseId ? (
                                        <Box
                                            display={"flex"}
                                            sx={{
                                                flexDirection: "column",
                                                textAlign: "center",
                                            }}
                                        >
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    setGateway(
                                                        row2.nfse_gateway
                                                    );
                                                    setRefreshGateway(
                                                        row2.nfse_gateway ==
                                                            "GRIDWEBTI"
                                                            ? false
                                                            : true
                                                    );
                                                    handleCriateNfse(
                                                        row2.Installment_id
                                                    );
                                                }}
                                                sx={{ borderRadius: "18px" }}
                                            >
                                                <Icon
                                                    sx={{
                                                        color: "#0170B3",
                                                        fontWeight: "normal",
                                                    }}
                                                >
                                                    {" "}
                                                    feed_Outlined_Icon
                                                </Icon>
                                                <Typography
                                                    variant="subtitle2"
                                                    marginLeft={1}
                                                    sx={{
                                                        color: "#0170B3",
                                                        fontWeight: "normal",
                                                    }}
                                                >
                                                    Gerar Nota
                                                </Typography>
                                            </IconButton>
                                        </Box>
                                    ) : (
                                        <Box
                                            display={"flex"}
                                            sx={{
                                                flexDirection: "column",
                                                textAlign: "center",
                                            }}
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                marginLeft={1}
                                                sx={{
                                                    color: "#0170B3",
                                                    fontWeight: "normal",
                                                }}
                                            >
                                                Não apto para gerar nota
                                            </Typography>
                                        </Box>
                                    )}
                                </TableCell>

                                <TableCell sx={{ textAlign: "center" }}>
                                    {["authorized", "issued"].includes(
                                        row2.nfse_status
                                    ) &&
                                    ["CONCLUIDO", "Autorizada"].includes(
                                        row2.nfse_nfse_situacao
                                    ) &&
                                    row2.nfse_status != null ? (
                                        <Tooltip
                                            title="Cancelar"
                                            placement="top-start"
                                        >
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    // handleCancelarNfse(row2.id);
                                                    setGateway(
                                                        row2.nfse_gateway
                                                    );
                                                    setRefreshGateway(
                                                        row2.nfse_gateway ==
                                                            "GRIDWEBTI"
                                                            ? false
                                                            : true
                                                    );
                                                    setLoadEmail(row2.email);
                                                    setLoadURL(
                                                        row2.nfse_nfse_urL_pdf ||
                                                            ""
                                                    );
                                                    setLoadNfse(
                                                        row2.nfse_nfseId
                                                    );
                                                    setLoadId(row2.nfse_id);
                                                    setLoadInstallment(
                                                        row2.Installment_id
                                                    );
                                                    setModalCancelarNfse(true);
                                                    setDocumentGuid(
                                                        row2.document_guid!
                                                    );
                                                }}
                                                sx={{ borderRadius: "18px" }}
                                            >
                                                <Icon
                                                    sx={{
                                                        color: "#e63158",
                                                        fontWeight: "normal",
                                                    }}
                                                >
                                                    {" "}
                                                    cancel_Outlined_icon
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        ""
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    {totalCount === 0 && !isLoading && (
                        <caption>{Environment.LISTAGEM_VAZIA}</caption>
                    )}
                    <TableFooter>
                        {isLoading && (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <LinearProgress variant="indeterminate" />
                                </TableCell>
                            </TableRow>
                        )}
                        {totalCount > 0 && (
                            <TableRow>
                                <TableCell
                                    colSpan={5}
                                    sx={{ borderBottom: "none" }}
                                >
                                    <Pagination
                                        page={pagina}
                                        count={Math.ceil(totalCount / pageSize)}
                                        onChange={(_, newPage2) =>
                                            setSearchParams(
                                                {
                                                    busca,
                                                    pagina: newPage2.toString(),
                                                    status: status,
                                                    dataini: dataIni,
                                                    datafim: dataFim,
                                                    clientId,
                                                    filter,
                                                },
                                                { replace: true }
                                            )
                                        }
                                    />
                                </TableCell>
                                <TableCell
                                    colSpan={5}
                                    sx={{ borderBottom: "none" }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{ fontWeight: "normal" }}
                                    >
                                        Exibindo{" "}
                                        {pagina * pageSize - (pageSize - 1)}
                                        {" - "}
                                        {pageSize * (pagina - 1) +
                                            pageSize * pagina <
                                        totalCount
                                            ? pageSize * pagina
                                            : totalCount}{" "}
                                        de
                                        <Typography
                                            variant="overline"
                                            sx={{
                                                fontWeight: "bold",
                                                color: "#0170B3",
                                                fontSize: "16px",
                                            }}
                                        >
                                            {" "}
                                            {totalCount}{" "}
                                        </Typography>
                                        registro(s)
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableFooter>
                </Table>
            </TableContainer>
        </LayoutBaseDePagina>
    );
};
