
import { string } from 'yup/lib/locale';
import { Environment } from '../../../environment';
import { Api } from "../axios-config";

export interface IProvisionList {
    id?: string,
    sequencial:number,
    sinisterId: string,
    status: string,
    due_date?: string,
    provisionedAt?: string,
    provisionedUser?: string,
    value?: string,
    createdAt?: string,
    updatedAt?: string,
    paymentValue?: string,
    paymentAt?: string,
    paymentUser?: string,
    clientId?: string,
    peopleId?: string,
    agency_number?: string,
    bankCode?: string,
    agencyDigit?: string,
    accountNumber?: string,
    accountDigit?: string,
    pix_key?: string,
    pixKeyType?: string,
    email?: string,
    canceledReason?: string,
    canceledAt?: string,
    canceledUser?: string,
    limitedAt?: string,
    client:{
        name:string,
        document:string,
        trade_name:string,
    },
    sinister:{
        status: string,
		busyProperty: string,
        proposal:{
            document:string,
            name:string,
            email:string,
            phone:string,
        }
    }
}
export interface IProvisionListBySinister {
    id:string,
    sequencial:number,
    sinisterId:string,
    status:string,
    dueDate:string,
    provisionedAt:string,
    provisionedUser:string,
    value:string,
    createdAt:string,
    updatedAt:string,
    paymentValue:string,
    paymentAt:string,
    paymentUser:string,
    clientId:string,
    peopleId:string,
    agencyNumber:string,
    bankCode:string,
    agencyDigit:string,
    accountNumber:string,
    accountDigit:string,
    pixKey:string,
    pixKeytYpe:string,
    email:string,
    canceledReason:string,
    canceledAt:string,
    canceledUser:string
}
type TProvisionTotalCount = {
    data: IProvisionList[];
    totalCount: number;
}

type TProvisionBySinisterTotalCount = {
    data: IProvisionListBySinister[];
    totalCount: number;
}


export interface IBorderoPayment {
    // clientId: string;
    // dueDate: string;
    // provisionedAt?: string;
    paymentAt:string;
    paymentValue: string;
    //id: string;
    // gateway?: "EMPTY",
    // status?: "pending",
}

export interface IFormCancel {
    canceledReason: string;
  }
 
  export interface IBorderoProvision {
    provisionedAt?: string;
}

// export interface IBorderoPayment {
//     clientId: string;
//     dueDate: string;
//     value: string;
//     id: string;
//     gateway?: "EMPTY",
//     status?: "pending",
// }
//const getAllBorderoById = async (page = 1, busca = '', status = '', dataini = '', datafim = ''): Promise<TProvisionTotalCount | Error> => {
const getAllBorderoById = async (sinisterId = ''): Promise< TProvisionBySinisterTotalCount | Error> => {
    try {
        const urlRelativa = `/provision/sinister/${sinisterId}`;
        const { data, headers } = await Api().get(urlRelativa);
        if (data.results) {
            return {
                data: data.results,
                totalCount: data.total,
            };
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

                            //pagina, busca, tipoBordero, statusAnalise, dataIniBordero, dataFimBordero, clientFilter
const getAllBordero = async (page = 1, busca = '', tipo ='',status = '', typePeriod ='',dataini = '', datafim = '', clientFilter=''): Promise<TProvisionTotalCount | Error> => {
    try {                            
        const urlRelativa = `/provision?_page=${page}&_limit=${Environment.LIMITE_LINHAS_GREAT}&nome_like=${busca}&tipo=${tipo}&status=${status}&typePeriod=${typePeriod}&dataini=${dataini}&datafim=${datafim}&clientId=${clientFilter}`
        console.log(urlRelativa);
        const { data, headers } = await Api().get(urlRelativa);
        if (data.results) {
            console.log('?all Bordero....', data)
            return {
                data: data.results,
                totalCount: data.total,
            };
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

const cancelBordero = async (id:string, dados:IFormCancel): Promise<IBorderoPayment | Error> =>{
    try {                            
        const urlRelativa = `/provision/${id}`
        const { data, headers } = await Api().put(urlRelativa,dados);
        if (data.results) {
            return data.results
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }

} 



const borderoPayment = async (id: string, dados: IBorderoPayment): Promise<void | Error> => {
    try {
        await Api().patch(`/provision/pagamento/${id}`, dados);
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao cancelar o registro.');
    }
};


const borderoProvision = async (id: string, dados: IBorderoProvision): Promise<void | Error> => {
    try {
        await Api().patch(`/provision/provisionar/${id}`, dados);
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao cancelar o registro.');
    }
};


const borderoCancel = async (id: string, dados: IFormCancel): Promise<void | Error> => {
    try {
        await Api().patch(`/provision/cancelar/${id}`, dados);
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao cancelar o registro.');
    }
};

const countBordero = async (id: string): Promise<number | Error> => {
    try {
        const resultado = await Api().get(`/provision/count/${id}`);
        console.log('teste 201 count provision....',resultado);
        return Number(resultado?resultado.data:'0')
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao cancelar o registro.');
    }
};


const getBorderoPDF = async (id: string, ) => { 
    try {

        const { data } = await Api().get(`/sinister-report/indenizacao/${id}`, { responseType: 'blob' });
        if (data) {
            const file = new Blob(
                [data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
        //return new Error('Erro ao consultar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};


export const ProvisionService = {
    getAllBorderoById,
    getAllBordero,
    borderoPayment,
    borderoProvision,
    borderoCancel,
    countBordero,
    getBorderoPDF,


  
};