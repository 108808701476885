import { useNavigate } from "react-router-dom";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import {
  Box,
  Grid,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { ToolbarAnalysisFilterReport } from "../../../shared/components/toolbar/report/toolbarAnalysisFilterReport";
import { PermissionActionsEnum, PermissionRolesEnum, } from "../../../shared/enums/profiles";
import { formatScaleValueBR, formatValueBR, } from "../../../shared/utils/formatScaleValue";
import { FinancialService } from "../../../shared/services/api/financial/FinancialService";
import { ModalContext, TableReports, ToastContent, } from "../../../shared/components";
import { maskCpfOrCnpj, translateStatusAnalysis } from "../../../shared/utils";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { ProfileGuard } from "../../../shared/guards/ProfileGuard";
import { formatDateBR } from "../../../shared/utils/format-date";
import { useProfile } from "../../../context/ProfileContext";
import { useDebounce, useToast } from "../../../shared/hooks";
import { Environment } from "../../../shared/environment";
import { useEffect, useState } from "react";
import moment from "moment";

export const ReportAnalysisPresentation = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const toastHandler = useToast();
  const { debounce } = useDebounce();
  const [isLoading, setIsLoading] = useState(false);
  const [dataIni, setDataIni] = useState(moment().startOf("month").subtract(1, "months").format("YYYY-MM-DD"));
  const [dataFim, setDataFim] = useState(moment().endOf("month").format("YYYY-MM-DD"));
  const { isSuperAdmin } = useProfile();
  const [origem, setOrigem] = useState("Todos");
  const [dataManagerFilter, setDataManagerFilter] = useState("");
  const [dataClientFilter, setDataClientFilter] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState<any>([]);
  const [pageSize, setPageSize] = useState(Environment.LIMITE_LINHAS);
  const [loadingExport, setLoadingExport] = useState(false);
  const [isFilter, setIsFilter] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isRealod, setIsRealod] = useState(false);
  const [isReport, setIsReport] = useState(false);

  const searchFilterReportAnalysisPDF = () => {
    if (isFilter) setIsLoading(true);
    if (!isFilter) setLoadingExport(true);

    debounce(() => {
      FinancialService.getFilterReportAnalysisPDF(
        page,
        pageSize,
        dataClientFilter,
        dataIni,
        dataFim,
        dataManagerFilter,
        origem,
        isFilter
      )
        .then((result: any) => {
          if (isFilter) setIsLoading(false);

          if (!isFilter) setLoadingExport(false);

          if (result instanceof Error) {
            const titleMessage = "Alerta!!";
            const contentMessage = "Erro: " + result?.message;

            toastHandler.present({
              type: "error",
              position: "top-right",
              messageOrContent: (
                <ToastContent title={titleMessage} content={contentMessage} />
              ),
            });
          } else if (isFilter) {
            setRows(result.result);
            setTotalCount(result.totalCount);
          } else if (result) {
            toastHandler.present({
              type: "info",
              position: "top-right",
              messageOrContent: (
                <ToastContent title={"Atenção!"} content={result} />
              ),
            });
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          if (!isFilter) setLoadingExport(false);
          if (isFilter) setIsLoading(false);

          const { titleMessage, contentMessage } = returnMessageError(err);

          toastHandler.present({
            type: "error",
            position: "top-right",
            messageOrContent: (
              <ToastContent title={titleMessage} content={contentMessage} />
            ),
          });
        });
    });
  };

  const handleExport = () => {
    setIsReport(true);
  };

  const handleCloseExport = () => {
    setIsReport(false);
  };



  useEffect(() => {
    if (!isFirstLoad && isFilter) searchFilterReportAnalysisPDF();
  }, [page, pageSize, isRealod]);

  useEffect(() => {
    if (!isFirstLoad && !isFilter) searchFilterReportAnalysisPDF();
  }, [isRealod]);

  return (
    <LayoutBaseDePagina
      titulo="Relatório de Análises"
      barraDeFerramentas={
        <ToolbarAnalysisFilterReport
          clickBack={() => navigate("/relatorios")}
          onClick={() => {
            setIsFirstLoad(false);
            setIsFilter(true);
            setIsRealod(!isRealod);
          }}
          onClickExport={() => {
            setIsFirstLoad(false);
            setIsFilter(false);
            setIsRealod(!isRealod);
          }}
          showInputManagerFilter={true}
          onChangeManagerFilter={(value) => {
            setDataManagerFilter(value);
          }}
          onChangeOrigem={(value) => {
            setOrigem(value);
          }}
          onChangeClientFilter={(value) => {
            setDataClientFilter(value);
          }}
          onChangeDataIni={(value) => {
            setDataIni(value);
          }}
          onChangeDataFim={(value) => {
            setDataFim(value);
          }}
          onChangeLimitPage={(value) => {
            setPageSize(Number(value));
          }}
          loadingExport={loadingExport}
          loading={isLoading}
          onclickReport={handleExport}
        />
      }
    >
      <Grid container display="flex" flexDirection="row">
        <ProfileGuard
          requiredRole={PermissionRolesEnum.FinancialOptions}
          requiredAction={PermissionActionsEnum.View}
        >
          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ padding: "20px", margin: 1 }}
          >

          </TableContainer>
          {isReport && (
            <ModalContext
              isCloseModal={() => setIsReport(false)}
              isOpenModal={isReport}
              messageType={"alert"}
              showButtonCancel={true}
              textButtonCancel={"Voltar"}
              width="75%"
              showTitleIcon={false}
              ITitle={"Relatórios Solicitados"}
              IMessage={[
                <Box sx={{ display: "flex", flexDirection: "column", width: '100%' }}>
                  <TableReports
                    actionDownload={() => { }}
                    actionView={() => { }}
                    isloading={false}
                    showDownload={true}
                    showView={false}
                  />
                </Box>,
              ]}
            />
          )}
        </ProfileGuard>
      </Grid>
    </LayoutBaseDePagina>
  );
};
