import { Box, CircularProgress } from '@mui/material';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { StyledComponentPropsType } from '../../../../../../shared/types';
import { ProposalDocumentsService } from '../../../../../../shared/services/api/proposal/ProposalDocumentsService';
import { ProposalDocumentType } from '../../../../../../shared/services/api/proposal/types';
import {
  Divider, GreyedTypography,
  StyledEmptyListBox,
  StyledFileListContainer,
  StyledFileListSpinnerBox
} from './ProposalFilesList.styled';
import { ProposalFileItem } from './ProposalFileItem';
import { useToast } from '../../../../../../shared/hooks';

type ProposalFilesListProps = StyledComponentPropsType & {
  proposalId: string
}
export const ProposalFilesList = ({ proposalId }: ProposalFilesListProps) => {
  const [documentsList, setDocumentsList] = useState<ProposalDocumentType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshListDoc, setRefreshListDoc] = useState<boolean>(false);
  const handler = useToast();

  useEffect(() => {
    ProposalDocumentsService.getProposalDocuments(proposalId).then((data) => {
      setDocumentsList(data);
      setIsLoading(false);
    }).catch(_ => {
      setIsLoading(false);
    })
  }, []);

  useEffect(() => {
    if (refreshListDoc) {
      ProposalDocumentsService.getProposalDocuments(proposalId).then((data) => {
        setDocumentsList(data);
        setIsLoading(false);
      }).catch(_ => {
        setIsLoading(false);
      })
    }
  }, [refreshListDoc]);

  const isEmptyList = useMemo(() => {
    return documentsList.length === 0
  }, [documentsList, isLoading])

  return (
    <StyledFileListContainer>
      {isLoading && (
        <StyledFileListSpinnerBox>
          <CircularProgress variant='indeterminate' />
        </StyledFileListSpinnerBox>
      )}

      {!isLoading && !isEmptyList && (
        documentsList.map((item, index) =>
        (
          <Box key={item.id} style={{ marginBottom: '16px' }}>
            <ProposalFileItem item={item}
              refreshListDocuments={(value: boolean) => setRefreshListDoc(value)}
            />
            {index < documentsList.length - 1 ? <Divider /> : null}
          </Box>
        )

        ))}
      {!isLoading && isEmptyList && (
        <StyledEmptyListBox>
          <GreyedTypography>Não há arquivos para serem exibidos</GreyedTypography>
        </StyledEmptyListBox>
      )}
    </StyledFileListContainer>
  )
}