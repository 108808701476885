import { Box, Button, Checkbox, Divider, Grid, Icon, InputAdornment, LinearProgress, MenuItem, Paper, Table, TableCell, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import 'moment/locale/pt-br';
import { Loading, ToolbarRenovate } from "../../../../shared/components";
import { useVForm, VForm, VTextField, IVFormErros } from "../../../../shared/forms";
import { LayoutBaseDePagina } from "../../../../shared/layouts";
import { IInstallmentProposal, IProposalList, IRenovateProposal, ProposalService } from "../../../../shared/services/api/proposal/ProposalService";
import { useDebounce } from "../../../../shared/hooks";
import axios from "axios";
import { Environment } from "../../../../shared/environment";
import * as yup from 'yup';
import { useToast } from '../../../../shared/hooks';
import { ToastContent } from '../../../../shared/components';
import { useAuthContext } from "../../../../context";
import { useProfile } from '../../../../context/ProfileContext';
import { VMaskTextField } from "../../../../shared/forms/VMaskTextField";
import { add, format, parseISO, subDays } from "date-fns";
import { checkByProfileGuard } from "../../../../shared/guards/ProfileGuard";
import { IClientPlan, IListagemPlan, PlanServices } from "../../../../shared/services/api/plan/PlanServices";



interface IFormRenovateProposal {
    email: string;
    termBegin: string;
    contractBegin: string;
    contractEnd: string;
    rentAmount: string;
    condominiumAmount: string;
    electricEnergyAmount: string;
    waterAmount: string;
    gasAmount: string;
    iptuAmount: string;
    assuranceMonthAmount: string;
    rentMonthsCount: string;
    lmi: string;
    lmiAmount: string;
    fee: string;
};

function translate(traduzir: String) {
    if (traduzir === 'expired') { return 'Expirada' }
    if (traduzir === 'active') { return 'Vigente' }
    if (traduzir === 'suspended') { return 'Suspensa' }
    if (traduzir === 'renovated') { return 'Renovada' }
    if (traduzir === 'canceled') { return 'Cancelada' }
    if (traduzir === 'pending') { return 'Pendente' }
    if (traduzir === 'paid') { return 'Pago' }
    if (traduzir === 'not_paid') { return 'Aberta' }
    if (traduzir === 'billed') { return 'Faturada' }

    if (traduzir === 'web_app') { return 'Aplicação Garantti' }
    if (traduzir === 'web_service') { return 'Integração' }

    if (traduzir === 'single') { return 'Solteiro' }
    if (traduzir === 'married') { return 'Casado' }
    if (traduzir === 'widower') { return 'Viúvo' }
    if (traduzir === 'divorced') { return 'Divorciado' }
    if (traduzir === 'stable_union') { return 'União estável' }

    if (traduzir === 'retired') { return 'Aposentado / Pensionista' }
    if (traduzir === 'freelance') { return 'Autônomo' }
    if (traduzir === 'privateServant') { return 'Funcionário CLT' }
    if (traduzir === 'private_Servant') { return 'Funcionário CLT' }
    if (traduzir === 'private_servant') { return 'Funcionário CLT' }
    if (traduzir === 'liberal') { return 'Empresário / Liberal' }
    if (traduzir === 'student') { return 'Estudante' }
    if (traduzir === 'civilServant') { return 'Funcionário Público' }
    if (traduzir === 'civil_servant') { return 'Funcionário Público' }
    if (traduzir === 'civil_Servant') { return 'Funcionário Público' }
    if (traduzir === 'rentIncome') { return 'Proveniente de Aluguel' }
    if (traduzir === 'entrepreneur') { return 'Empreendedor' }


    if (traduzir === 'commercial') { return 'Comercial' }
    if (traduzir === 'residential') { return 'Residencial' }

    if (traduzir === 'billet') { return 'Fatura Imobiliária' }
    if (traduzir === 'credit_card') { return 'Cartão de crédito' }

    if (traduzir === 'parcel') { return 'Parcela' }
    if (traduzir === 'add') { return 'Acrescimo' }
    if (traduzir === 'discount') { return 'Desconto' }

    return traduzir;
};


function colorText(status: string) {
    if (status === 'expired') { return '#f0e555' }
    if (status === 'active') { return '#38df61' }
    if (status === 'canceled') { return '#eb3232' }
    if (status === 'suspended') { return '#eb3232' }
    if (status === 'renovated') { return '#3761eb' }
    if (status === 'paid') { return '#1ec448' }
    if (status === 'billed') { return '#3761eb' }

    return '#202020';
}

function colorLine(parcela: number) {
    if (parcela % 2 == 0) { return '#e5e8eb' }
    return '#ffffff';
}


const maskPhone = (value: string) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1')
};

const maskCell = (value: string) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$0')
};

const maskReal = (value: any) => {
    try {
        let valueX = value;
        if ((typeof value) == 'number') {
            valueX = value.toFixed(2);
        }
        //let valueX = (typeof value)=='number'?value.toFixed(2):value

        return valueX
            .replace(/\D/g, '')
            .replace(/(\d{1,2})$/, ',$1')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    } catch (error) {
        console.log(error);
        return 0
    }

};


const removeMask = (value: string) => {
    if (!value) return value;

    return value
        .replace(/\D/g, '')
        .replace(/[^0-9]/g, "")
        .replace(/(\d{1,2})$/, '.$1')
};
//adicionar propriedades a um interface ou typ
export type IListagemPlanRenovate = IClientPlan & {
    value?: string, feeReal: string, lmi: string, lmiValue?: string, checked?: boolean
};

export const ProposalRenovatePresentation: React.FC = () => {
    const { id = 'novo' } = useParams<'id'>();
    const navigate = useNavigate();
    const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } = useVForm();
    const [isLoading, setIsLoading] = useState(false);
    const { debounce } = useDebounce();
    const [nome, setNome] = useState('');
    const [doc, setDoc] = useState('');
    const [clientId, setClientId] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [status, setStatus] = useState('');
    const [docLessor, setDocLessor] = useState('');
    const toastHandler = useToast();
    const [rows, setRows] = useState<IProposalList>();
    const [rowsInstallment, setRowsInstallment] = useState<IInstallmentProposal[]>([]);
    const [totalCountInstallment, setTotalCountInstallment] = useState(0);
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [modalFinancial, setModalFinancial] = useState(false);
    const { permissionLevel } = useProfile();
    const [validity, setValidity] = useState('12');
    const [usRentAmount, setRentAmount] = useState('0.00');
    const [usLmiAmount, setLmiAmount] = useState('0.00');
    const [usLmi, setLmi] = useState('0.00');
    const [usFee, setFee] = useState('0.00');
    const [usAssuranceMonthAmount, setAssuranceMonthAmount] = useState('0.00');


    const [beginDate, setBeginDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [termBegin, setTermBegin] = useState('');
    const [planClient, setPlanClient] = useState<IListagemPlanRenovate[]>();
    const [planSelect, setPlanSelect] = useState('');
    const [recalculate, setRecalculate] = useState(false);
    const weeks = [];
    for (let i = 12; i <= 60; i++) {
        weeks.push(i);
    }



    const formValidationSchema: yup.SchemaOf<IFormRenovateProposal> = yup.object().shape({
        email: yup.string().required('Campo obrigatório'),
        termBegin: yup.string().required('Campo obrigatório'),
        contractBegin: yup.string().required('Campo obrigatório'),
        contractEnd: yup.string().required('Campo obrigatório'),
        rentAmount: yup.string().required('Campo obrigatório'),
        condominiumAmount: yup.string().required('Campo obrigatório'),
        electricEnergyAmount: yup.string().required('Campo obrigatório'),
        waterAmount: yup.string().required('Campo obrigatório'),
        gasAmount: yup.string().required('Campo obrigatório'),
        iptuAmount: yup.string().required('Campo obrigatório'),
        assuranceMonthAmount: yup.string().required('Campo obrigatório'),
        lmi: yup.string().required('Campo obrigatório'),
        lmiAmount: yup.string().required('Campo obrigatório'),
        planId: yup.string().trim(),
        fee: yup.string().required('Campo obrigatório'),
        rentMonthsCount: yup.string().required('Campo obrigatório'),
    });


    const handleOpenModal = () => {
        setOpenModal(true)
    };


    const handleSave = (dados: IFormRenovateProposal) => {
        console.log(dados)
        dados.rentAmount = removeMask(dados.rentAmount)
        dados.condominiumAmount = removeMask(dados.condominiumAmount)
        dados.electricEnergyAmount = removeMask(dados.electricEnergyAmount)
        dados.waterAmount = removeMask(dados.waterAmount)
        dados.gasAmount = removeMask(dados.gasAmount)
        dados.iptuAmount = removeMask(dados.iptuAmount)
        dados.rentMonthsCount = (dados.rentMonthsCount)
        console.log('225....', usAssuranceMonthAmount, usLmiAmount, usFee)
        dados.assuranceMonthAmount = removeMask(usAssuranceMonthAmount); //dados.assuranceMonthAmount)
        dados.lmiAmount = removeMask(usLmiAmount); // dados.lmiAmount)
        dados.fee = removeMask(String(usFee)); // dados?.fee));
        dados.lmi = usLmi;

        dados.termBegin = format(new Date(dados.termBegin + ' 3:00:00'), "yyyy-MM-dd HH:mm:ss")
        console.log('.........240', dados, planSelect)
        if (!planSelect || planSelect == '') {
            toastHandler.present({
                type: 'error',
                position: 'top-right',
                durationMs: 5000,
                messageOrContent: <ToastContent title={'Erro'} content={'É necessário pelo menos selecionar um plano para renovação!'} />
            });
            return null;
        }

        formValidationSchema.
            validate(dados, { abortEarly: false })
            .then((dadosvalidados) => {
                setIsLoading(true);


                ProposalService
                    .RenovateProposal(id, { ...dadosvalidados, planId: planSelect, residentialFee: dadosvalidados.fee, commercialFee: dadosvalidados.fee })
                    .then((result: any) => {
                        setIsLoading(false);
                        if (result instanceof Error) {
                            const titleMessage = "Alerta!!";
                            const contentMessage = "Erro: " + result?.message;

                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            });
                        } else {

                            toastHandler.present({
                                type: 'success',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={'Sucesso'} content={'Garantia renovada com sucesso.'} />
                            })
                            navigate('/garantias');
                        }
                    },
                        function (reason) {

                        }
                    );
            })
            .catch((errors: yup.ValidationError) => {
                console.log('erros....261 ', errors);
                const validationErros: IVFormErros = {};
                errors.inner.forEach(error => {
                    console.log('264..', error, error.message)
                    if (!error.path) return;
                    validationErros[error.path] = error.message;
                });
                console.log(validationErros)
                formRef.current?.setErrors(
                    validationErros
                );
            });
    };


    // useEffect(() => {

    //     const lmi = formRef.current?.getFieldValue('lmi');
    //     const fee = formRef.current?.getFieldValue('fee');
    //     const rentAmount = formRef.current?.getFieldValue('rentAmount');
    //     console.log('handleRent Amount......', lmi, fee, rentAmount)
    //     const assurancemonthAmount = ((rentAmount * 1.00) * (fee * 1.00) / 100).toFixed(2);
    //     const lmiAmount = ((rentAmount * 1.00) * (lmi * 1.00)).toFixed(2);

    //     formRef.current?.setFieldValue('lmiAmount', maskReal(lmiAmount));
    //     formRef.current?.setFieldValue('assuranceMonthAmount', maskReal(assurancemonthAmount));

    // }, [rentAmount]);

    const serializePlanClient = (tmpCheck: IListagemPlanRenovate[]) => {

        const countMorePlanCheked = tmpCheck?.filter(plan => plan.checked == true).length;
        if (countMorePlanCheked > 1) {
            tmpCheck = tmpCheck.map(plan => {
                if (rows?.plan?.id != plan.id) plan.checked = false;
                if (rows?.plan?.id == plan.id) plan.checked = true;

                return plan;
            })
        }

        return tmpCheck;
    }

    useEffect(() => {
        setIsLoading(true);
        ProposalService.getRenovateById(`${id}`)
            .then((result) => {
                setIsLoading(false);
                if (result instanceof Error) {
                    navigate('/proposal');
                } else {
                    setNome(result.name);
                    setDoc(result.document);
                    setClientId(result.clientId);
                    setDocLessor(result.lessorCpf ? result.lessorCpf : '');
                    setRows(result);
                    setStatus(result.status);
                    const lmi = result.lmi;
                    const rentMonthsCount = 12;

                    const fimdavigencia = result.expiresAt ? result.expiresAt.substring(0, 10) : '';
                    const inicionovavigencia = moment(fimdavigencia).add(1, 'days').format("YYYY-MM-DD");

                    setEndDate(moment(inicionovavigencia).add(rentMonthsCount, 'month').format("YYYY-MM-DD"));
                    setTermBegin(moment(inicionovavigencia).format("YYYY-MM-DD"));
                    setBeginDate(moment(inicionovavigencia).format("YYYY-MM-DD"));

                    const resFee = result.clientPlan?.residentialFee;
                    const commFee = result.clientPlan?.commercialFee;

                    let fee = '0.00';
                    let planFee = 0.00;
                    if (result && result.propertyFinally == 'residential') {
                        fee = result?.residentialFee ? result?.residentialFee : '00.00'
                        planFee = Number(resFee ? resFee : fee);
                        //  setFee(fee);
                    }
                    if (result && result.propertyFinally == 'commercial') {
                        fee = (result.commercialFee ? result?.commercialFee : '00.00')
                        planFee = Number(commFee ? commFee : fee); //(result.clientPlan? result.clientPlan?.commercialFee: fee);
                        //  setFee(fee);
                    }

                    if (result?.lmi) {
                        setLmi(result?.lmi ? result?.lmi : '0');
                    }
                    setRentAmount(result.rentAmount ? result?.rentAmount : '0.00');

                    let lmiAmount = result.lmiAmount ? result?.lmiAmount : '0.00';
                    const rentAmount = (result.rentAmount ? result.rentAmount : '0.00') //formRef.current?.getFieldValue('rentAmount');
                    if (lmiAmount == '0.00') {
                        lmiAmount = ((parseFloat(rentAmount) * 1.00) * (parseFloat(lmi ? lmi : '12') * 1.00)).toFixed(2);
                    }
                    setLmiAmount(lmiAmount);
                    //regra de renovacao acrescentando 1% a taxa se menor que 12

                    let feeReal = Number(fee) < 12 ? Number(fee) + 1 : Number(fee);
                    feeReal = (feeReal > planFee) ? planFee : feeReal;
                    setFee(feeReal.toFixed(2));
                    let assuranceMonthAmount = result.assuranceMonthAmount ? result?.assuranceMonthAmount : '0.00'
                    assuranceMonthAmount = ((parseFloat(rentAmount) * 1.00) * ((feeReal) * 1.00) / 100).toFixed(2);
                    setAssuranceMonthAmount(assuranceMonthAmount);

                    if (result.clientId) {
                        PlanServices.planByClient(result.clientId, 'renovation')
                            .then((plan) => {
                                if (plan instanceof Error) {
                                    // navigate('/proposal');
                                }
                                else {
                                    const tmpPlan: IListagemPlanRenovate[] = plan?.map((plan) => {
                                        const tempLmi = ((parseFloat(rentAmount) * 1.00) * (parseFloat(plan.lmi) * 1.00)).toFixed(2);
                                        const tempAssuranceMonthAmount = ((parseFloat(rentAmount) * 1.00) * (parseFloat(
                                            result.propertyFinally == 'residential' ? plan.residentialFee : plan.commercialFee) * 1.00) / 100).toFixed(2);

                                        return {
                                            ...plan,
                                            value: tempAssuranceMonthAmount,
                                            feeReal: result.propertyFinally == 'residential' ? plan.residentialFee : plan.commercialFee,
                                            lmi: plan?.lmi,
                                            lmiValue: tempLmi,
                                            checked: (result?.planId == plan?.planId) ? true : false
                                        }
                                    })
                                    console.log(tmpPlan)
                                    setPlanClient(serializePlanClient(tmpPlan));
                                    setPlanSelect(result.planId ? result.planId : '');
                                }

                            });
                    }


                    formRef.current?.setData({

                        rentMonthsCount: 12,
                        termBegin: inicionovavigencia,///moment( fimdavigencia ).add(1,'days').format("YYYY-MM-DD"),//'2022-11-26',//apagar
                        expiresAt: moment(inicionovavigencia).add(rentMonthsCount, 'month').format("YYYY-MM-DD"),//'2023-11-25',//apagar
                        contractBegin: result.contractBegin ? moment(result.contractBegin).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"), //'2022-11-26',//apagar
                        contractEnd: moment().add(rentMonthsCount, 'month').format("YYYY-MM-DD"),//'2023-11-25',//apagar
                        paymentMethod: 'billet',
                        lmi: lmi,
                        email: result.email,
                        fee: feeReal, //result.residentialFee ? result.residentialFee : result.commercialFee,
                        assuranceMonthAmount: assuranceMonthAmount, // result?.assuranceMonthAmount ? result.assuranceMonthAmount : '0.00',
                        rentAmount: result.rentAmount,
                        lmiAmount: lmiAmount,       //// result?.lmiAmount ? result.lmiAmount : '0.00',

                        condominiumAmount: result.condominiumAmount ? result.condominiumAmount : '0.00',
                        electricEnergyAmount: result.electricEnergyAmount ? result.electricEnergyAmount : '0.00',
                        waterAmount: result.waterAmount ? result.waterAmount : '0.00',
                        gasAmount: result.gasAmount ? result.gasAmount : '0.00',
                        iptuAmount: result.iptuAmount ? result.iptuAmount : '0.00',
                    })

                }
            });
    }, []);


    useEffect(() => {
        if (termBegin && validity && endDate) {
            const parsedExpireAt: Date = parseISO(termBegin)
            const parsedEndDate: Date = parseISO(endDate)

            const expires: Date = add(parsedExpireAt, { months: parseInt(`${validity}`) });
            const subtractedExpireDate: Date = subDays(expires, 1);
            const formattedSubtractedExpireDate: string = format(subtractedExpireDate, 'yyyy-MM-dd');

            formRef.current?.setFieldValue('expiresAt', formattedSubtractedExpireDate);

            if (subtractedExpireDate.getTime() > parsedEndDate.getTime()) {
                setEndDate(formattedSubtractedExpireDate);
                formRef.current?.setFieldValue('contractEnd', formattedSubtractedExpireDate);
            }
        }
    }, [termBegin, validity, endDate])

    const handleRentAmount = (value: string) => {
        {
            const rentAmount = value.replaceAll('.', '').replaceAll(',', '.'); //formRef.current?.getFieldValue('rentAmount');
            const assurancemonthAmount = ((parseFloat(rentAmount) * 1.00) * (parseFloat(usFee) * 1.00) / 100).toFixed(2);
            setAssuranceMonthAmount(assurancemonthAmount);
            const lmiAmount = ((parseFloat(rentAmount) * 1.00) * (parseFloat(usLmi))).toFixed(2);
            formRef.current?.setFieldValue('lmiAmount', maskReal(lmiAmount));
            formRef.current?.setFieldValue('assuranceMonthAmount', maskReal(assurancemonthAmount));
            if (planClient && planClient.length > 0) {
                const tmpPlan: IListagemPlanRenovate[] = planClient?.map((plan) => {
                    const tempLmi = ((parseFloat(rentAmount) * 1.00) * (parseFloat(plan.lmi) * 1.00)).toFixed(2);
                    const tempAssuranceMonthAmount = ((parseFloat(rentAmount) * 1.00) * (parseFloat(plan.feeReal) * 1.00) / 100).toFixed(2);
                    if (plan.checked) {

                        setAssuranceMonthAmount(tempAssuranceMonthAmount);
                        formRef.current?.setFieldValue('lmiAmount', maskReal(tempLmi));
                        formRef.current?.setFieldValue('assuranceMonthAmount', maskReal(tempAssuranceMonthAmount));
                        formRef.current?.setFieldValue('fee', maskReal(plan.feeReal));
                        formRef.current?.setFieldValue('lmi', (plan.lmi));
                        setPlanSelect(plan.planId);
                    }
                    return {
                        ...plan,
                        value: tempAssuranceMonthAmount,
                        lmiValue: tempLmi,
                    }
                })
                console.log(tmpPlan)
                setPlanClient(serializePlanClient(tmpPlan));
            }

        }
    };

    const handleCheckboxRowChange = (row: IListagemPlanRenovate) => {
        let checked = false;
        // console.log(row);
        if (planClient?.length) {
            let tmpCheck: IListagemPlanRenovate[] = planClient?.map((plan) => {
                if (plan.id == row.id) {

                    setAssuranceMonthAmount(plan.value ? plan.value : '0');
                    formRef.current?.setFieldValue('lmiAmount', maskReal(plan.lmiValue));
                    formRef.current?.setFieldValue('assuranceMonthAmount', maskReal(plan.value));
                    formRef.current?.setFieldValue('fee', maskReal(plan.feeReal));
                    formRef.current?.setFieldValue('lmi', (plan.lmi));
                    setPlanSelect(plan.planId);
                    setAssuranceMonthAmount(plan.value ? plan.value : '0');
                    setLmiAmount(plan.lmiValue ? plan.lmiValue : '0');
                    setFee(plan.feeReal ? plan.feeReal : '0');
                    setLmi(plan.lmi ? plan.lmi : '0');
                }
                return { ...plan, checked: (plan.id == row.id) ? true : false }

            })

            setPlanClient(serializePlanClient(tmpCheck));
        }

    }


    return (
        <LayoutBaseDePagina
            titulo='Garantias'
            subtitulo={`Renovação de Garantia  [ ${nome} ]`}
            barraDeFerramentas={
                <ToolbarRenovate
                    clickBack={() => navigate('/garantias')}
                    clickRenovate={() => {
                        console.log('clicqueir....', planSelect)

                        if (planClient && planClient.some(plan => plan.planId === planSelect)) {
                            // planSelect exists in planClient[].planId
                            // Add your code here
                        } else {
                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                durationMs: 5000,
                                messageOrContent: <ToastContent title={'Erro'} content={'É necessário pelo menos selecionar um plano para renovação!'} />
                            });
                            return null;
                            // planSelect does not exist in planClient[].planId
                            // Add your code here
                        }

                        formRef.current?.submitForm();
                        // alert('oi');
                    }}
                    showButtonRenovate={true}
                />
            }
        >

            <Grid container spacing={3}>
                <Grid item xs={12} md={6} >
                    {/* *************Inquilino */}
                    <Grid container sm={12}
                        display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                        ml={smDown ? 0 : 2}
                    >
                        <Grid container sm={12}
                            display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            component={Paper}
                            padding={2}
                            spacing={1}
                        >
                            <Grid container sm={12} md={12}
                                direction="row"
                                spacing={1} >
                                <Grid item xs={11} >
                                    <Typography variant="h6" color={'primary'}>
                                        Dados Inquilino
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Nome
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.name}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Documento
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {doc.length > 11 ?
                                            `${rows?.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}`
                                            :
                                            `${rows?.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")}`
                                        }
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Nascimento
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.birthDate != '1899-11-30' ? moment(rows?.birthDate).format("L") : ''}
                                        {/* {moment(rows?.birthDate).format("L")} */}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Nome da Mãe
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.motherName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Relação de trabalho
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {translate(`${rows?.employmentRelationShip}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Estado Civil
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {translate(`${rows?.maritalStatus}`)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} mb="1rem">
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Celular
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskCell(`${rows?.phone}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Telefone
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskCell(`${rows?.mobilePhone}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Email
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Endereço
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {`${rows?.addressStreet != null && rows?.addressStreet != '' ?
                                            `${rows?.addressComplement != null && rows?.addressComplement != '' ?
                                                `${rows?.addressStreet},${rows?.addressNumber} - ${rows?.addressComplement} -
                                            ${rows?.addressDistrict} - ${rows?.addressCity} - ${rows?.addressState} - ${rows?.addressZipcode}`
                                                : `${rows?.addressStreet},${rows?.addressNumber} - 
                                            ${rows?.addressDistrict} - ${rows?.addressCity} - ${rows?.addressState} - ${rows?.addressZipcode}`
                                            } ` : ''
                                            }`
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Tipo de cobrança
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {translate(`${rows?.paymentMethod}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Tipo de Imovel
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {translate(`${rows?.propertyType}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Tipo de Locação                                </Typography>

                                    <Typography variant="subtitle2" >
                                        {translate(`${rows?.propertyFinally}`)}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Parceiro
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.client?.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* *********** Locador */}
                    <Grid container sm={12}
                        display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                        mt={2} ml={smDown ? 0 : 2}
                    >
                        <Grid container sm={12}
                            display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            component={Paper}
                            padding={2}
                            spacing={1}
                        >
                            <Grid container sm={12} md={12}
                                direction="row"
                                spacing={1} >
                                <Grid item xs={11} >
                                    <Typography variant="h6" color={'primary'}>
                                        Dados Locador
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Documento
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {/* {rows?.lessorCpf } */}
                                        {docLessor.length > 11 ?
                                            `${docLessor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}`
                                            :
                                            `${docLessor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")}`
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Nome
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.lessorName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Nascimento
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.lessorBirthDate != '1899-11-30' ? moment(rows?.lessorBirthDate).format("L") : ''}
                                        {/* {moment(rows?.lessorBirthDate).format("L")} */}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={1} mb="1rem">
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Celular
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskCell(`${rows?.lessorPhone}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Telefone
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskCell(`${rows?.lessorMobilePhone}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Email
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.lessorEmail}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                    {/* *************Garantia */}
                    <Grid container sm={12}
                        display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                        component={Paper}
                        padding={2}
                        spacing={1}
                    >
                        {/* ################################################################################################################################################# */}
                        <Grid container item direction="row" spacing={1} pb={2}>
                            <Grid item xs={8} >
                                <Typography variant="h6" color={'primary'}>
                                    Dados da Renovação
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" padding={2} spacing={2}>
                            <VForm id="formIdSave"
                                placeholder="Your placeholder"
                                onPointerEnterCapture={() => console.log("Pointer entered")}
                                onPointerLeaveCapture={() => console.log("Pointer left")}
                                ref={formRef} onSubmit={handleSave}>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Forma de pagamento '
                                            name="paymentMethod"
                                            disabled={isLoading}
                                            select
                                        >
                                            <MenuItem value={'billet'}>Fatura Imobiliária</MenuItem>
                                        </VTextField>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Inicio do contrato de locação'
                                            name="contractBegin"
                                            disabled={true}
                                            type="date"
                                        // onChange={(e) => setBeginDate(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Fim do contrato de locação'
                                            name="contractEnd"
                                            disabled={isLoading}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            type="date"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Quantidade de meses Vigência'
                                            name="rentMonthsCount"
                                            onChange={(e) => setValidity(e.target.value)}
                                            disabled={isLoading}
                                            select
                                        >
                                            {weeks.map((element, key) => (
                                                <MenuItem value={element}>{element}</MenuItem>
                                            ))}
                                        </VTextField>
                                    </Grid>


                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Inicio da Vigencia'
                                            name="termBegin"
                                            disabled={true}
                                            type="date"
                                        // onChange={(e) => setTermBegin(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <VTextField
                                            fullWidth
                                            label='Fim da Vigencia'
                                            name="expiresAt"
                                            disabled={true}
                                            type="date"
                                        />
                                    </Grid>
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <VTextField
                                                fullWidth
                                                label='Email Locatário'
                                                name="email"
                                                disabled={isLoading}
                                                type="text"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={11} >
                                            <Typography variant="h6" color={"secondary"} >Valores da locação</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container item direction="row" spacing={2}>

                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Valor do Aluguel'
                                                name="rentAmount"
                                                disabled={isLoading}
                                                mask="real"
                                                onChange={(e) => setRentAmount(e.target.value)}
                                                onBlur={(e) => handleRentAmount(e.target.value)}
                                            // {(e) => setEndDate(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Valor do Condomínio'
                                                name="condominiumAmount"
                                                disabled={isLoading}
                                                mask="real"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Valor da Energia'
                                                name="electricEnergyAmount"
                                                disabled={isLoading}
                                                mask="real"
                                                onChange={e => removeMask(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item direction="row" spacing={2}>


                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Valor da Agua'
                                                name="waterAmount"
                                                disabled={isLoading}
                                                mask="real"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Valor do Gas'
                                                name="gasAmount"
                                                disabled={isLoading}
                                                mask="real"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Valor IPTU'
                                                name="iptuAmount"
                                                disabled={isLoading}
                                                mask="real"
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* 
                                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                            <VTextField
                                                fullWidth
                                                label='LMI'
                                                name="lmi"
                                                disabled={true}
                                                style={{ display: 'none' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                            <VTextField
                                                fullWidth
                                                label='Taxa Aplicada'
                                                name="fee"
                                                disabled={true}
                                                style={{ display: 'none' }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Valor LMI'
                                                name="lmiAmount"
                                                disabled={true}
                                                mask="real"
                                                style={{ display: 'none' }}
                                            />
                                        </Grid> 
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                            <VMaskTextField
                                                fullWidth
                                                label='Valor Mensal'
                                                name="assuranceMonthAmount"
                                                disabled={true}
                                                mask={"real"}
                                                style={{ display: 'none' }}
                                            />
                                        </Grid> */}
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={8} >
                                            <Typography variant="subtitle1" color={'secondary'}>
                                                Plano Atual:
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {rows?.plan?.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} >
                                            <Typography variant="subtitle1" color={'secondary'}>
                                                Selecione plano para renovação
                                            </Typography>
                                        </Grid>
                                        <Table>
                                            <TableHead>
                                                <TableRow key={0} >
                                                    <TableCell>
                                                        <Typography variant="subtitle2" color={'secondary'}>
                                                            #
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="subtitle2" color={'secondary'}>
                                                            Plano
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="subtitle2" color={'secondary'}>
                                                            Taxa %
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="subtitle2" color={'secondary'}>
                                                            Lmi
                                                        </Typography>
                                                    </TableCell>
                                                    {/* <TableCell>
                                                    <Typography variant="subtitle2" color={'secondary'}>
                                                        Plano
                                                    </Typography>
                                                </TableCell> */}
                                                    <TableCell>
                                                        <Typography variant="subtitle2" color={'secondary'}>
                                                            Valor Mensal
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="subtitle2" color={'secondary'}>
                                                            Valor Lmi
                                                        </Typography>
                                                    </TableCell>

                                                </TableRow>
                                            </TableHead>

                                            {planClient?.map((row, key) => (
                                                <TableRow key={row.id} hover>
                                                    <TableCell>
                                                        <Checkbox
                                                            color="secondary"
                                                            inputProps={{
                                                                "aria-label": "select all values",
                                                            }}
                                                            checked={row.checked}
                                                            onChange={() => handleCheckboxRowChange(row)}
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{ color: "#959595", fontWeight: "normal" }}   >
                                                        <Typography
                                                            variant="subtitle1"
                                                            color={"#959595"}
                                                            fontWeight={"700"}
                                                        >
                                                            {row?.plan?.description}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ color: "#959595", fontWeight: "normal" }}   >
                                                        <Typography
                                                            variant="subtitle1"
                                                            color={"#959595"}
                                                            fontWeight={"700"}
                                                        >
                                                            {maskReal(row?.feeReal)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ color: "#959595", fontWeight: "normal" }}   >
                                                        <Typography
                                                            variant="subtitle1"
                                                            color={"#959595"}
                                                            fontWeight={"700"}
                                                        >
                                                            {(row?.lmi)}
                                                        </Typography>
                                                    </TableCell>
                                                    {/* <TableCell sx={{ color: "#959595", fontWeight: "normal" }}   >
                                                    <Typography
                                                        variant="subtitle2"
                                                        color={"#959595"}
                                                        fontWeight={"normal"}
                                                        >
                                                        {maskReal(row?.residentialFee)} 
                                                    </Typography>
                                                </TableCell> */}
                                                    <TableCell sx={{ color: "#959595", fontWeight: "normal" }}   >
                                                        <Typography
                                                            variant="subtitle1"
                                                            color={"#959595"}
                                                            fontWeight={"700"}
                                                        >
                                                            {maskReal(row?.value)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ color: "#959595", fontWeight: "normal" }}   >
                                                        <Typography
                                                            variant="subtitle1"
                                                            color={"#959595"}
                                                            fontWeight={"700"}
                                                        >
                                                            {maskReal(row?.lmiValue)}
                                                        </Typography>
                                                    </TableCell>



                                                </TableRow>

                                                // <>
                                                //     <Typography paddingY={"5px"} borderBottom={"1px solid "} borderColor={"#efefef"} sx={{ backgroundColor: `${key % 2 ? 'whrite' : '#f1091c0d'}` }} >
                                                //       { `${row?.plan?description}  ${row?.commercialFee}  ${row?.residentialFee}`  }
                                                //     </Typography>
                                                // </>
                                            ))
                                            }
                                        </Table>
                                    </Grid>

                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                                            <Typography variant="h6" color={"primary"} >Valor Mensal</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                            <Typography variant="h6" color={"primary"} textAlign={"right"}>
                                                R$ {maskReal(usAssuranceMonthAmount)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </VForm>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </LayoutBaseDePagina >
    );
};
