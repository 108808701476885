import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, Button, CircularProgress, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { useToast } from "../../../../../shared/hooks";
import { ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";
import { returnMessageError } from "../../../../../shared/utils/returnMessageError";

interface Logs {
	createdDate: string;
	eventType: string;
	tableName: string;
	recordId: string;
	userId: string;
	userName: string;
	payload: any;
	_id: string;
}

interface ModalLogsProposalProps {
	isClose: () => void;
	isOpenModal: boolean;
	rows: { id: string };
}

export const ModalLogsProposal: React.FC<ModalLogsProposalProps> = ({ isClose, isOpenModal, rows }) => {
	const [payload, setPayload] = useState<any>(undefined);
	const [idLog, setIdLog] = useState("");
	const [openModalPayloadID, setOpenModalPayloadID] = useState(false);
	const [dataLogs, setDataLogs] = useState<Logs[]>([]);
	const [isLoading, setIsLoading] = useState(false); // Estado para controlar o carregamento
	const toastHandler = useToast();

	const handleGetLogs = (proposalId: string) => {
		setIsLoading(true); // Iniciar carregamento
		ProposalService.getLogs(proposalId)
			.then((result) => {
				if (result instanceof Error) {
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title="Alerta!!" content={`Erro: ${result.message}`} />,
					});
				} else if (Array.isArray(result)) {
					setDataLogs(result as unknown as Logs[]);
				} else {
					toastHandler.present({
						type: "warning",
						position: "top-right",
						messageOrContent: <ToastContent title="Aviso" content={"Os dados recebidos não estão no formato esperado."} />,
					});
				}
			})
			.catch((err: any) => {
				const { titleMessage, contentMessage } = returnMessageError(err);
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			})
			.finally(() => {
				setIsLoading(false); // Finalizar carregamento
			});
	};

	useEffect(() => {
		if (isOpenModal && rows?.id) {
			handleGetLogs(rows.id);
		}
	}, [isOpenModal, rows?.id]);

	const handleGetLogsUpdatePayload = (payload?: any, logId: string = "") => {
		if (payload) {
			setPayload(payload);
			setIdLog(logId);
			setOpenModalPayloadID(true);
		} else {
			toastHandler.present({
				type: "warning",
				position: "top-right",
				messageOrContent: <ToastContent title={"Alerta!!"} content={"Não foi encontrado evidência de payload neste log específico!"} />,
			});
		}
	};

	const handleCloseModalLogsPayload = () => {
		setOpenModalPayloadID(false);
	};

	const renderTableHeader = () => (
		<TableRow>
			{["Data Criada", "Tipo Evento", "Nome Tabela", "Referência ID Garantia", "Usuário Id", "Usuário Nome", "Payload"].map((header, index) => (
				<TableCell key={index} sx={{ fontWeight: 500 }}>
					{header}
				</TableCell>
			))}
		</TableRow>
	);

	const renderLogRow = (log: Logs, index: number) => (
		<TableRow key={index}>
			{[format(new Date(log.createdDate), "dd/MM/yyyy"), log.eventType, log.tableName, log.recordId, log.userId, log.userName].map(
				(cellContent, cellIndex) => (
					<TableCell key={cellIndex} sx={{ fontWeight: "normal" }}>
						<Typography variant="subtitle2" color={"#080808"} fontWeight={"normal"}>
							{cellContent}
						</Typography>
					</TableCell>
				)
			)}
			<TableCell>
				<Button
					color="primary"
					disableElevation
					variant={"outlined"}
					sx={{ alignItems: "flex-start" }}
					startIcon={<RemoveRedEyeOutlinedIcon />}
					onClick={() => handleGetLogsUpdatePayload(log.payload, log._id)}
				>
					Payload
				</Button>
			</TableCell>
		</TableRow>
	);

	const renderNoLogsMessage = () => (
		<TableRow>
			<TableCell colSpan={7} sx={{ fontWeight: "normal", borderBottom: "none", textAlign: "center" }}>
				<Typography color={"primary"} variant="subtitle2" fontWeight={"normal"} fontSize={20}>
					Não há registros de logs desta garantia
				</Typography>
			</TableCell>
		</TableRow>
	);

	return (
		<>
			<ModalContext
				isCloseModal={isClose}
				isOpenModal={isOpenModal}
				messageType={"info"}
				enableBackdropClose={true}
				overrideWidthValue="auto"
				maxWidth="lg"
				showButtonCancel={true}
				textButtonCancel="Sair"
				showTitleIcon={false}
				ITitle="Logs da Garantia"
				IMessage={[
					<Grid item key="table">
						<Table aria-label="a dense table" sx={{ overflow: "auto", border: "0.5px solid #e5e5e9" }}>
							<TableBody sx={{ textAlign: "center" }}>
								{renderTableHeader()}
								{isLoading ? (
									<TableRow>
										<TableCell colSpan={7} sx={{ textAlign: "center" }}>
											<CircularProgress />
										</TableCell>
									</TableRow>
								) : dataLogs.length > 0 ? (
									dataLogs.map(renderLogRow)
								) : (
									renderNoLogsMessage()
								)}
							</TableBody>
						</Table>
					</Grid>,
				]}
			/>

			{openModalPayloadID && (
				<ModalContext
					isCloseModal={handleCloseModalLogsPayload}
					isOpenModal={openModalPayloadID}
					messageType={"info"}
					enableBackdropClose={true}
					overrideWidthValue="100"
					showButtonCancel={true}
					showTitleIcon={false}
					textButtonCancel="Sair"
					ITitle={`Detalhe do Payload IdLog# ${idLog}`}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Grid container item sx={{ border: "none", display: "inline-flex" }}>
								<Box>
									<pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{JSON.stringify(payload, null, 2)}</pre>
								</Box>
							</Grid>
						</Box>,
					]}
				/>
			)}
		</>
	);
};
