import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';

interface AlertContextProps {
  showAlert: (severity: AlertColor, message: string) => void;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const useAlert = (): AlertContextProps => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

export const AlertProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [alert, setAlert] = useState<{
    severity: AlertColor;
    message: string;
  } | null>(null);
  const [open, setOpen] = useState(false);

  const showAlert = (severity: AlertColor, message: string) => {
    setAlert({ severity, message });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      {alert && (
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Alerta no topo e centralizado
        >
          <Alert onClose={handleClose} severity={alert.severity}>
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </AlertContext.Provider>
  );
};
