import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import {hr} from 'date-fns/locale';

export const StyledFileListSpinnerBox = styled(Box)(() => {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    transition: 'opacity linear 0.25s, z-index linear 0.1s',
    pointerEvents: 'none'
  }
})

export const StyledFileListContainer = styled(Box)(() => {
  return {
   marginTop: '16px',
   minWidth: '25vw',
   minHeight: '100px',
   border: '2px solid whitesmoke',
   position: 'relative'
/*   '& > *': {
     borderBottom: '1px solid grey'
   }*/
  }
})

/*export const Divider = styled(Box)(() => {
  return {
    marginTop: '16px',
    border: '1px solid grey',
    opacity: 0.5,
    marginLeft: '16px',
    marginRight: '16px'
  }
})*/

export const StyledEmptyListBox = styled(Box)(() => {
  return {
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export const GreyedTypography = styled(Typography)(() => {
  return {
    opacity: 0.9,
    color: 'grey',
    fontSize: '0.9rem'
  }
})

export const Divider = styled('hr')(() => {
  return {
    border: '0px',
    borderTop: '2px solid whitesmoke'
  }
})