
export const Environment = {
    /**
     * Define a quantide de linhas carregadas por padrao listagens
     */
    LIMITE_LINHAS: 5,

    INPUT_BUSCA: 'Pesquisar',

    LISTAGEM_VAZIA: 'Nenhum registro encontrado.',

    URL_BASE:  process.env.REACT_APP_BASE_API ,

    URL_API_CPF: process.env.REACT_CPF_BASE_URL,

    VERSION: process.env.REACT_APP_VERSION,

    AMBIENTE: process.env.REACT_APP_AMBIENTE,

    LIMITE_LINHAS_GREAT: 10,
    LIMITE_LINHAS_VALORES_BORDEROS: 50,
    
};