import { Typography, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useReset } from '../../../../context';
import { BasicModal } from '../../VModal/VModal';

export const ForgetPasswordForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const {email, setEmail} = useReset();

  return (
    <>
        <Typography variant='h6' align='center'>Login de usuário</Typography>
        <Typography variant='caption' align='center'>Insira seu e-mail e sua senha abaixo para concluir seu login.</Typography>
        <TextField
            fullWidth
            type='email'
            label='Email'
            value={email}
            disabled={isLoading}
            error={!!emailError}
            helperText={emailError}
            onKeyDown={() => setEmailError('')}
            onChange={e => setEmail(e.target.value)}
            size={'small'}
            sx={{ margin: '1rem 0' }}
        />
    </>
  )
}
