import { Clear, PrintOutlined, Search } from "@mui/icons-material";
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField } from "@mui/material";
import { startOfMonth } from "date-fns";
import moment from "moment";
import * as React from "react";
import { useState } from "react";
import { ListSearchSetupParams } from "../../../pages/analysis/types/list-search-params.type";
import { Environment } from "../../environment";
interface IToolbarSetupProps {
	textSearch?: string;
	showInputSearch?: boolean;
	onChangeTextSearch?: (newText: string) => void;
	onClearTextSearch?: (textEmpty: string) => void;

	showPeriodo?: boolean;
	onChangeDataIni?: (text: string) => void;
	onChangeDataFim?: (text: string) => void;

	showSelectQtde?: boolean;
	onChangeSelectQtde?: (text: string) => void;

	showSelectStatus?: boolean;
	onChangeSelectStatus?: (text: string) => void;

	showButtonRefresh?: boolean;
	onClickButtonRefresh?: () => void;

	isDateStartDefault?: boolean;
	filterInitialParams?: ListSearchSetupParams;
}

export const ToolbarSetup: React.FC<IToolbarSetupProps> = ({
	textSearch = "",
	showInputSearch = true,
	onChangeTextSearch: onChangeTextSearch,
	onClearTextSearch: onClearTextSearch,

	showPeriodo = true,
	onChangeDataIni,
	onChangeDataFim,
	isDateStartDefault = false,

	showSelectQtde = true,
	onChangeSelectQtde,

	onChangeSelectStatus,
	showSelectStatus = false,

	showButtonRefresh = true,
	filterInitialParams,
	onClickButtonRefresh,
}) => {
	const dataStartDefault = startOfMonth(new Date()).toISOString().split("T")[0];
	const [dataIni, setDataIni] = useState(isDateStartDefault ? dataStartDefault : moment().startOf("month").format("YYYY-MM-DD"));
	const [dataFim, setDataFim] = useState(filterInitialParams?.dataFim ?? moment().endOf("month").format("YYYY-MM-DD"));
	const [qtde, setQtde] = useState("10");
	const [Status, setStatus] = useState("todos");

	const handleChangeStatus = (event: SelectChangeEvent) => {
		setStatus(event.target.value as string);
		onChangeSelectStatus?.(event.target.value as string);
	};

	return (
		<Box gap={1} marginX={1} paddingY={2} display="flex" alignItems="space-beetwen" component={Paper} elevation={0}>
			{showPeriodo && (
				<>
					<Grid item xs={12} sm={12} md={2}>
						<TextField
							fullWidth
							label="Data Inicial"
							name="datainicial"
							size="small"
							type="date"
							value={dataIni}
							onChange={(e) => (setDataIni(moment(e.target.value).format("YYYY-MM-DD")), onChangeDataIni?.(e.target.value))}
						></TextField>
					</Grid>

					<Grid item xs={12} sm={12} md={2}>
						<TextField
							fullWidth
							label="Data Final"
							name="datafinal"
							size="small"
							type="date"
							value={dataFim}
							onChange={(e) => (setDataFim(new Date(e.target.value).toISOString().split("T")[0]), onChangeDataFim?.(e.target.value))}
						></TextField>
					</Grid>
				</>
			)}

			{showSelectQtde && (
				<Grid item xs={12} sm={12} md={3} minWidth={"150px"}>
					<FormControl fullWidth>
						<InputLabel id="qtdeLinhas">Linhas por página</InputLabel>
						<Select
							labelId="qtdeLinhas"
							id="qtdeLinhas"
							fullWidth
							label="Linhas por Pagina"
							name="qtde"
							size="small"
							value={qtde}
							onChange={(e) => (setQtde(e.target.value as string), onChangeSelectQtde?.(e.target.value as string))}
						>
							<MenuItem value="5">05</MenuItem>
							<MenuItem value="10">10</MenuItem>
							<MenuItem value="25">25</MenuItem>
							<MenuItem value="50">50</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			)}

			{{ showSelectStatus } && (
				<Grid item xs={12} sm={12} md={4} minWidth={"150px"}>
					<FormControl fullWidth>
						<InputLabel id="status">Status</InputLabel>
						<Select labelId="status" id="status" fullWidth label="Status" name="status" size="small" value={Status} onChange={handleChangeStatus}>
							<MenuItem value="todos">Todos</MenuItem>
							<MenuItem value="pending">Aberto</MenuItem>
							<MenuItem value="paid">Pago</MenuItem>
							<MenuItem value="canceled">Cancelado</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			)}

			{showButtonRefresh && (
				<Grid item xs={12} sm={12} md={1}>
					<Button variant="contained" color="primary" onClick={onClickButtonRefresh}>
						Pesquisar
					</Button>
				</Grid>
			)}

			<Box flex={1} display="flex" justifyContent="end" gap={3}>
				{showInputSearch && (
					<TextField
						size="small"
						value={textSearch}
						placeholder={Environment.INPUT_BUSCA}
						onChange={(e) => onChangeTextSearch?.(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="end">
									<IconButton aria-label="toggle password visibility" edge="start">
										<Search />
									</IconButton>
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										edge="end"
										sx={{ color: "#dbd5d5" }}
										onClick={() => onChangeTextSearch?.("")}
									>
										<Clear />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				)}
			</Box>
		</Box>
	);
};
