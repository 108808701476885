import { AccountCircleOutlined, Clear, Search } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Icon, IconButton, InputAdornment, Paper, TextField, useTheme } from "@mui/material";

import { Environment } from "../../environment";

interface IToolbarGridUserProps {
    textSearchGrid?: string;
    showInputBuscaGrid?: boolean;
    onChangeTextBuscaGrid?: (newText: string) => void;

    textButtonUserImob?: string;
    showBotaoUserImob?: boolean;
    onClickUserImob?: () => void;
    selectUserImob?: boolean;

    textButtonImobiliaria?: string;
    showBotaoImobiliaria?: boolean;
    onClickImobiliaria?: () => void;
    selectImobiliaria?: boolean;

    textButtonAdmin?: string;
    showBotaoAdmin?: boolean;
    onClickAdmin?: () => void;
    selectAdmin?: boolean;

    textButtonTodos?: string;
    showBotaoTodos?: boolean;
    onClickTodos?: () => void;
    selectTodos?: boolean;

}

export const ToolbarGridUser: React.FC<IToolbarGridUserProps> = ({
    textSearchGrid: textSearch = '',
    showInputBuscaGrid: showInputBusca = true,
    onChangeTextBuscaGrid: onChangeTextBusca,

    textButtonUserImob = 'Usuário da imobiliária',
    showBotaoUserImob = true,
    onClickUserImob,
    selectUserImob = false,

    textButtonImobiliaria = 'Admin. Imobiliária',
    showBotaoImobiliaria = true,
    onClickImobiliaria,
    selectImobiliaria = false,

    textButtonAdmin = 'Admin. Garantti',
    showBotaoAdmin = true,
    onClickAdmin,
    selectAdmin = false,

    textButtonTodos = 'Todos',
    showBotaoTodos = true,
    onClickTodos,
    selectTodos = true,

}) => {
    const theme = useTheme();

    const handleTodos = () => {
        selectTodos
    };


    return (
        <Box
            display="flex"
            alignItems="start"
            height={theme.spacing(6)}
        >

            <ButtonGroup aria-label="outlined primary button group">
                {showBotaoTodos && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectTodos ? "contained" : "outlined"}
                        onClick={onClickTodos}
                        startIcon={<Icon>done_all_icon</Icon>}
                    >
                        {textButtonTodos}

                    </Button>
                )}

                {showBotaoUserImob && (
                    <Button
                        color="primary"
                        disableElevation
                        onClick={onClickUserImob}
                        variant={selectUserImob ? "contained" : "outlined"}
                        startIcon={<Icon>portrait_outlined_icon</Icon>}
                    >
                        {textButtonUserImob}
                    </Button>
                )}

                {showBotaoImobiliaria && (
                    <Button
                        color="primary"
                        disableElevation
                        onClick={onClickImobiliaria}
                        variant={selectImobiliaria ? "contained" : "outlined"}
                        startIcon={<Icon>corporate_fare_outlined_icon</Icon>}
                    >
                        {textButtonImobiliaria}
                    </Button>
                )}

                {showBotaoAdmin && (
                    <Button
                        color="primary"
                        disableElevation
                        onClick={onClickAdmin}
                        variant={selectAdmin ? "contained" : "outlined"}
                        startIcon={<Icon>badge_outlined_icon</Icon>}
                    >
                        {textButtonAdmin}
                    </Button>
                )}

            </ButtonGroup>

            <Box flex={1} display="flex" justifyContent="end">
                {showInputBusca && (
                    <TextField
                        size="small"
                        value={textSearch}
                        placeholder={Environment.INPUT_BUSCA}
                        onChange={(e) => onChangeTextBusca?.(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        // onClick={handleClickShowPassword}
                                        edge="start"
                                    >
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        sx={{ color: "#dbd5d5" }}
                                        onClick={() => onChangeTextBusca?.('')}
                                    >
                                        <Clear />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            </Box>
        </Box >
    );
}; 