import { Box } from '@mui/material';

export const PaymentNotFound: React.FC = () => (
  <Box
    sx={{
      p: 2,
      backgroundColor: 'error.main',
      color: 'white',
      borderRadius: 4,
      textAlign: 'center',
      maxWidth: 600,
      margin: 'auto',
    }}
  >
    <h2>Pagamento não encontrado</h2>
    <p>
      O pagamento solicitado não foi encontrado. Entre em contato com o suporte
    </p>
  </Box>
);
