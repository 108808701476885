import { AuthGuard } from '../../shared/guards/AuthGuard';
import { Sidebar } from '../../shared/components';
import { ReportLifecycleAnalysisPresentation } from './components';

export const ReportLifecycleAnalysisPage = (): JSX.Element => {
  return (
    <AuthGuard>
      <Sidebar>
        <ReportLifecycleAnalysisPresentation />
      </Sidebar>
    </AuthGuard>
  );
};
