import { Api } from '../axios-config';

import { GetPersonDataByDocumentResponseDto } from './dtos/get-person-data-by-document-response.dto';
const getPersonDataByDocument = async (document: string): Promise<GetPersonDataByDocumentResponseDto> => {
  const response  = await Api()
    .get(`/people/by-document/${document}`);

  return response.data;
};

export const PeopleService = {
  getPersonDataByDocument
};

