import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import * as React from 'react';

interface IContactsClientProps {
  names: string[];
  options: string[];
  inputLabel: string;
  disabled?: boolean;
  onSelectChange?: (value: string[]) => void;
}

export const ContextsContact: React.FC<IContactsClientProps> = ({
  names,
  options,
  inputLabel,
  disabled,
  onSelectChange,
}) => {
  const [chipData, setChipData] = React.useState<string[]>(names);

  const handleChange = (event: SelectChangeEvent<typeof chipData>) => {
    const {
      target: { value },
    } = event;
    setChipData(typeof value === 'string' ? value.split(',') : value);
    onSelectChange?.(typeof value === 'string' ? value.split(',') : value);
    // Usando o operador de encadeamento opcional para chamar newValue
  };
  // const handleChange = (event: SelectChangeEvent<typeof chipData>) => {
  //     const { target: { value }, } = event;
  //     setChipData(typeof value === 'string' ? value.split(',') : value,);
  //     newValue(chipData)
  // };

  return (
    // <FormControl sx={{  width: "100%" }}>
    <>
      <InputLabel id="demo-multiple-chip-label">{inputLabel}</InputLabel>
      <Select
        multiple
        name="context"
        size="small"
        fullWidth
        disabled={disabled}
        value={chipData}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label={inputLabel} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {options.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </>
    // </FormControl>
  );
};
