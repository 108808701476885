export const removerAcentos = (text: string) => {
    if(!text?.length) return '';

    text = text.toLocaleLowerCase();

    return text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
}

export const compareTextEqual = (text: string, text2: string): boolean => {
    return removerAcentos(text) === removerAcentos(text2);
}