export const copyToClipboard = async (text: string): Promise<void> => {
    try {
    await navigator.clipboard.writeText(text);
    console.log("Texto copiado para a área de transferência!");
    //handleClick({ vertical: 'top', horizontal: 'center' })
  } catch (error) {
    console.error("Falha ao copiar para a área de transferência: ", error);
  }
};



export function isDateBeforeToday(date: Date): boolean {
  const today = new Date(); // Data e hora atuais
  // Ajusta a hora de 'today' para meia-noite para comparar apenas as datas, ignorando o tempo
  today.setHours(0, 0, 0, 0);

  // Retorna verdadeiro se 'date' for menor que 'today'
  return date < today;
}