import {AuthGuard} from '../../shared/guards/AuthGuard';
import {ListagemGeraisPresentation} from './components';
import {Sidebar} from '../../shared/components';

export const ListagemGeraisPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <ListagemGeraisPresentation/>
            </Sidebar>
        </AuthGuard>
    )
};