import { useCallback, useState } from 'react';
import {
    Box, Button, Card, CardActions, Checkbox, FormControlLabel,
    Grid, CardContent, CircularProgress, TextField, Typography,
    useMediaQuery, useTheme
} from '@mui/material';
import * as yup from 'yup';
import { useAuthContext, useReset } from '../../../context';
import { borderRadius } from '@mui/system';
import { ForgetPasswordForm } from './ForgetPassword/ForgetPasswordForm';
import { ForgetPasswordButton } from './ForgetPassword/ForgetPasswordButton';
import RecoverPasswordForm from './ForgetPassword/RecoverPasswordForm';
import RecoverPasswordButton from './ForgetPassword/RecoverPasswordButton';
import imageSite from "../../../assets/site.png";


const loginSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required().min(5),
});

interface ILoginProps {
    children: React.ReactNode;
}
export const Login: React.FC<ILoginProps> = ({ children }) => {
    const { isAuthenticated, login } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const {recoverPassword, resetPassword, setResetPassword} = useReset()

    const [openPopup, setOpenPopup] = useState(false);

    const handleSubmit = () => {
        setIsLoading(true);

        loginSchema
            .validate({ email, password }, { abortEarly: false })
            .then(dadosValidados => {
                login(dadosValidados.email, dadosValidados.password)
                    .then(() => {
                        setIsLoading(false);
                        //                        navigate("/usuarios", { replace: true });
                        // return null;
                    });
            })
            .catch((errors: yup.ValidationError) => {
                setIsLoading(false);

                errors.inner.forEach(error => {
                    if (error.path === 'email') {
                        setEmailError(error.message);
                    } else if (error.path === 'password') {
                        setPasswordError(error.message);
                    }
                });
            });
    };

    const handleResetPassword = useCallback(() => {
        setIsLogin(false);
        setResetPassword(true);
    }, [resetPassword]);


    if (isAuthenticated) return (
        <>{children}</>
    );

    return (

        <Box >
            <Grid container >

                <Grid item xs={7} display={smDown ? 'none' : 'flex'}>
                    <Box height="99.5vh" justifyContent="left" >
                        <img src={imageSite} alt="Site" width='auto' height="100%" />
                    </Box>
                </Grid>
                <Grid item xs={smDown ? 12 : 5} display='flex' alignItems="center" justifyContent={smDown ? "center" : "flex-start"} >
                    <Card sx={{ borderRadius: '8px' }}>
                        <CardContent sx={{ margin: '1rem 2rem' }}>
                            <Box display='flex' flexDirection='column' gap={2} width={400}>
                                <Box width="100%" display="flex" alignItems="center" justifyContent="center" >
                                    <img src="image3.png" alt="Logo" height={'30%'} />
                                </Box>

                                {
                                    isLogin && <>
                                        <Typography variant='h6' align='center'>Login de usuário</Typography>
                                        <Typography variant='caption' align='center'>Insira seu e-mail e sua senha abaixo para concluir seu login.</Typography>
                                        <TextField
                                            fullWidth
                                            type='email'
                                            label='Email'
                                            value={email}
                                            disabled={isLoading}
                                            error={!!emailError}
                                            helperText={emailError}
                                            onKeyDown={() => setEmailError('')}
                                            onChange={e => setEmail(e.target.value)}
                                            size={'small'}
                                            sx={{ margin: '1rem 0' }}
                                        />
                                        <TextField
                                            fullWidth
                                            label='Senha'
                                            type='password'
                                            value={password}
                                            disabled={isLoading}
                                            error={!!passwordError}
                                            helperText={passwordError}
                                            onKeyDown={() => setPasswordError('')}
                                            onChange={e => setPassword(e.target.value)}
                                            size={'small'}
                                        />
                                    </>
                                }
                                {
                                    resetPassword && <ForgetPasswordForm />
                                }
                                {
                                    recoverPassword && <RecoverPasswordForm />
                                }
                
                            </Box>
                        </CardContent>
                        <CardActions sx={{ margin: '1rem 2rem' }}>
                            {
                                isLogin && <>
                                    <Box display='flex' flexDirection='column' justifyContent='center' width='100%'>
                                        <Box display='flex' flexDirection='row' justifyContent='space-between' >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox name="lembre=me" />
                                                }
                                                label="Lembre-me"
                                            />
                                            <Button
                                                variant='text'
                                                disabled={isLoading}
                                                onClick={handleResetPassword}
                                            >
                                                Esqueceu sua senha ?
                                            </Button>
                                        </Box>
                                        <Button
                                            variant='contained'
                                            disabled={isLoading}
                                            onClick={handleSubmit}
                                            // onClick={() => setOpenPopup(true)}
                                            endIcon={isLoading ? <CircularProgress variant='indeterminate' /> : undefined}
                                            sx={{ width: 'inherit', background: '#1E325C', margin: '1rem 0' }}
                                        >
                                            Entrar
                                        </Button>
                                        
                                    </Box>
                                </>
                            }
                            {
                                resetPassword && <ForgetPasswordButton />
                            }
                            {
                                recoverPassword && <RecoverPasswordButton />
                            }
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};
