import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export interface ILoagindCompProps {
  isopen: boolean;
}

export const LoadingComp: React.FC<ILoagindCompProps> = ({
  isopen
}) => {

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isopen}
      >
        <CircularProgress color="inherit" size={"100px"} thickness={1} />
      </Backdrop>
    </div>
  );
}