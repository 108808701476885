import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Paper, TableContainer } from '@mui/material';
import 'moment-timezone';
import 'moment/locale/pt-br';
import { globalMaskDocument } from "../../../../shared/utils";


export interface IBudgetResumeProps {
    id?: string;
    propertyType: string;
    propertyOccupation: string;
    assistance: string;
    rentAmount: string;
    PL: string;
    personCommission: string;

    propertyAddressStreet?: string;
    propertyAddressNumber?: string;
    propertyAddressComplement?: string;
    propertyAddressDistrict?: string;
    propertyAddressCity?: string;
    propertyAddressState?: string;
    propertyAddressZipCode?: string;

    tenantName?: string;
    tenantDocument?: string;
    tenantPhone?: string;
    tenantEmail?: string;
    tenantBirthdate?: string;

    locatorName?: string;
    locatorDocument?: string;
    locatorPhone?: string;
    locatorEmail?: string;
    locatorBirthdate?: string;

    installmentNumber?: string;
    installmentValue?: string;
    amoutValue?: string;
    overRefresh?: (value: boolean) => void;
}


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.5rem' }} />}
        sx={{ paddingY: '0' }}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        paddingY: '0',
        marginY: '0',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
}));

export const AccordionResumeFire: React.FC<IBudgetResumeProps> = ({
    id, propertyType, propertyOccupation, assistance, rentAmount, PL, personCommission,
    propertyAddressStreet, propertyAddressNumber, propertyAddressComplement,
    propertyAddressDistrict, propertyAddressCity, propertyAddressState,
    tenantName, tenantDocument, tenantPhone, tenantBirthdate, locatorName,
    locatorDocument, locatorPhone, locatorBirthdate, installmentNumber, installmentValue, amoutValue

}) => {
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

        const style = {
            //content:'',
           // position:'absolute',
           before:{border:'5px solid "#c5d'},
            top:"0px",
            left:"0px",
            border:'5px solid #2f0',
            marginLeft: 1, 
            marginTop: 2, 
            marginRight: 1, 
            minWidth: '800px', 
            width: '30%', 
            paddingY: 3
          } 

    return (
<TableContainer component={Paper} variant="outlined" sx={{ ...style }}>
 

        <Box component={Paper}>
            <Typography variant="h6" fontWeight={"300"} sx={{ color: 'white', background: 'red', paddingY: 2, textAlign: 'center' }} >Resumo do Orçamento</Typography>

            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography sx={{ fontSize: '0.9rem', fontWeight: "400", color: '#7924c7' }}>Dados do Seguro</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1, margin: 0 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'} >Tipo de Imóvel :</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'}>
                            {propertyType}
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'} >Ocupação :</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'}>
                            {propertyOccupation}
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'}>Assitência :</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'}>
                            {assistance}
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'} >Valor do Aluguel :</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'}>
                            {parseFloat(rentAmount).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'} >PL :</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'}>
                            {PL} %
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'} >Comissão :</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'} >
                            {personCommission} %
                        </Typography>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography sx={{ fontSize: '0.9rem', fontWeight: "400", color: '#7924c7' }}>Dados do Imóvel</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1, margin: 0 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'}>Endereço:</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'}>
                            {propertyAddressComplement != null && propertyAddressComplement != '' ?
                                `${propertyAddressStreet} , ${propertyAddressNumber} - ${propertyAddressComplement} - ${propertyAddressDistrict} - ${propertyAddressCity} - ${propertyAddressState}`
                                :
                                `${propertyAddressStreet} , ${propertyAddressNumber} - ${propertyAddressDistrict} - ${propertyAddressCity} - ${propertyAddressState}`
                            }
                        </Typography>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ fontSize: '0.9rem', fontWeight: "400", color: '#7924c7' }}>Dados do Inquilino</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1, margin: 0 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'} >Nome :</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'}>
                            {tenantName}
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'}>CPF/CNPJ:</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'}>
                            {globalMaskDocument(tenantDocument || '0')}
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'}>Telefone:</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'}>
                            {tenantPhone}
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'} >Nascimento</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'} >
                            {tenantBirthdate}
                        </Typography>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ fontSize: '0.9rem', fontWeight: "400", color: '#7924c7' }}>Dados do Proprietário</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1, margin: 0 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'} >Nome :</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'}>
                            {locatorName}
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'} >CPF/CNPJ:</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'}>
                            {globalMaskDocument(locatorDocument || '0')}
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'} >Telefone:</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'}>
                            {locatorPhone}
                        </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: 'row', gap: 1 }}>
                        <Typography variant="subtitle2" fontWeight={500} fontSize={'0.8rem'} >Nascimento</Typography>
                        <Typography variant="subtitle2" fontWeight={300} fontSize={'0.8rem'}>
                            {locatorBirthdate}
                        </Typography>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
        </TableContainer>
    );
}