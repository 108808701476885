import { Environment } from "../../../environment";
import { Api } from "../axios-config";

export interface IListagemPermissao {
  id: string;
  status: string;
  description:string;
  level: string;
  clientId: string;
  permissions: string;
  client_type: string;
}

const getAll = async (  page = 1,
                        filter = "",
                        status = "",
                        clientId = "",
                        permissaoDescricao = ""
                      ) => {
  try {
    const urlRelativa = `/permission?_page=${page}&_limit=${100}&status=${status}&clientId=${clientId}&description_like=${filter}`;
    const { data, headers } = await Api().get(urlRelativa);
    if (data.results) {
      return {
        data: data.results,
        totalCount: data.total,
      };
    }
    return new Error("Erro ao listar permissões.");
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || "Erro ao listar permissões."
    );
  }
};

const getUnlimited = async (level = 99) => {
  //page = 1, filter = '') => {
  try {
    //const urlRelativa = `/permission?_page=${page}&_limit=${99}&description_like=${filter}`;
    const urlRelativa = `/permission/level/${level}`;

    const { data, headers } = await Api().get(urlRelativa);
    if (data.results) {
      return {
        data: data.results,
        totalCount: data.total,
      };
    }
    return new Promise((_, reject) =>
      reject(new Error("Erro ao listar permissões."))
    ); 
  } catch (error) {
    return new Promise((_, reject) => reject(error));
  }
};

const getById = async (id: string) => {
  try {
    const { data } = await Api().get(`/permission/${id}`);
    if (data) {
      return data;
    } else {
      return new Error("Erro ao consultar o registro.");
    }
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || "Erro ao listar a permissão."
    );
  }
};

const create = async (data: any) => {
  try {
    await Api().post(`/permission`, data);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || "Erro ao criar a permissão."
    );
  }
};

const updateById = async (id: string, dados: any) => {
  try {
    await Api().patch(`/permission/${id}`, dados);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || "Erro ao atualizar o registro."
    );
  }
};

export const PermissionServices = {
  getAll,
  getById,
  updateById,
  create,
  getUnlimited,
};
