import { useNavigate } from 'react-router-dom';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { Box, Button, Card, CardContent, Checkbox, CircularProgress, Divider, Grid, Icon, IconButton, LinearProgress, Pagination, Paper, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useProfile } from '../../../context/ProfileContext';
import { useDebounce, useToast } from '../../../shared/hooks';
import { ToolbarTransferClients } from '../../../shared/components/toolbarGridClientes/ToolbarGridTransferClients';
import { BasicModal, ModalContext, ToastContent } from '../../../shared/components';
import { colorIcon, colorText, colorBackGroundProposal, maskCpfOrCnpj, maskPhone, globalTranslateStatus, sleep } from '../../../shared/utils';
import { returnMessageError } from '../../../shared/utils/returnMessageError';
import { formatScaleValueBR } from '../../../shared/utils/formatScaleValue';
import { formatDateBR } from '../../../shared/utils/format-date';
import { isValid } from 'date-fns';
import { TWalletTransfer, walletTransferService } from '../../../shared/services/api/wallet-transfer/walletTransferService';
import { checkByProfileGuard } from '../../../shared/guards/ProfileGuard';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';


export const TransferClientsPresentation = () => {

    // const listDataInfo: Array<String> = [
    //     'garantias',
    //     'analises',
    //     'parcelas_garantias',
    //     'sinistros',
    // ];
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [dataPort, setDataPort] = useState('');
    const [dataClientFilter, setDataClientFilter] = useState('');
    const [dataClientFilter2, setDataClientFilter2] = useState('');
    const { registerClient } = useProfile();
    const toastHandler = useToast();
    const { debounce } = useDebounce();
    const [openModal, setOpenModal] = useState(false);
    const [openModalInfo, setOpenModalInfo] = useState(false);
    const [nameClient, setNameClient] = useState('');
    const [nameClient2, setNameClient2] = useState('');
    // const [dataInfo, setDataInfo] = useState<Array<String>>(listDataInfo);
    const [rows, setRows] = useState<Array<any>>([]);
    const [isCheckedProposal, setIsCheckedProposal] = useState(true);
    const [isCheckedAnalisis, setIsCheckedAnalisis] = useState(true);
    const [isCheckedInstallmentsProposal, setIsCheckedInstallmentsProposal] = useState(true);
    const [isCheckedSinister, setIsCheckedSinister] = useState(true);
    const [typeName, setTypeName] = useState('');
    const [total, setTotal] = useState(0);
    const [checkboxAllSelected, setCheckboxAllSelected] = useState(false);
    const [idsProposal, setIdsProposal] = useState<String[]>([]);

    const dataTypes = (e: any, type: string) => {

        setTypeName(type);
        getdataInfo(type);

        // if (e.target.checked) {
        //     setDataInfo([...dataInfo, type]);
        // } else {
        //     setDataInfo(dataInfo?.filter((d: any) => d !== type));
        // }
    }

    const validFieldsInfo = (all: boolean = false): boolean => {
        // const isNoAll: boolean = dataClientFilter && dataPort && isValid(dataPort) ? true : false;
        const isNoAll: boolean = dataClientFilter ? true : false;

        if (!all) return isNoAll;

        // const isAll: boolean = dataClientFilter && dataClientFilter2 && dataPort && isValid(dataPort) ? true : false;
        const isAll: boolean = dataClientFilter && dataClientFilter2 ? true : false;

        return isAll;
    }

    const getdataInfo = (type: string = '') => {

        if (!validFieldsInfo()) {
            toastHandler.present({
                type: 'warning',
                position: 'top-right',
                durationMs: 6000,
                messageOrContent: <ToastContent title={"Campo(s) faltam ser preenchido(s)."}
                    content={"O campo cliente de origem precisam ser preenchido para realizar a busca dos dados!"} />
            });
            return;
        }

        setOpenModalInfo(true);

        debounce(() => {
            setIsLoading(true);
            walletTransferService.getDataInfo(type, dataClientFilter, dataPort)
                .then((result: any) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    } else {
                        setTotal(result.total);
                        setCheckboxAllSelected(false);
                        setIdsProposal([]);
                        if (result.data?.length) {
                            setRows(result.data.map((d: any) => {
                                return { ...d, isChecked: false }
                            }));
                        } else setRows([]);
                    }
                }).catch((err: any) => {
                    setIsLoading(false);
                    const { titleMessage, contentMessage } = returnMessageError(err);

                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    })
                })
        });
    }

    const transferProcess = () => {

        setOpenModal(false);
        setIsLoading(true);
        debounce(() => {
            walletTransferService.transferProcess(dataClientFilter, dataClientFilter2, dataPort, idsProposal)
                .then((result: TWalletTransfer) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    } else {
                        toastHandler.present({
                            type: 'success',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={"Sucesso!"} content={result} />
                        })
                        setIdsProposal([]);
                        sleep(3500);
                        navigate('/clientes');
                    }
                }).catch((err: any) => {
                    setIsLoading(false);
                    const { titleMessage, contentMessage } = returnMessageError(err);

                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    })
                })
        });
    }

    const handleCheckboxChange = (checked: boolean) => {
        setCheckboxAllSelected(checked);
        setIdsProposal([]);

        const dataRows: any = rows?.map((r: any) => {

            return {
                ...r,
                isChecked: checked,
            }
        });

        setRows(dataRows);

        if (checked) setIdsProposal(dataRows?.map((r: any) => r.id));
    };

    const handleSelectCheckBox = (checked: boolean, id: String) => {

        const dataRows: any = rows?.map((r: any) => {

            if (r.id == id) {
                r.isChecked = checked;
            }

            return r;
        });

        setRows(dataRows);

        if (checked) setIdsProposal([...idsProposal, id]);
        else setIdsProposal(idsProposal?.filter((i: String) => i != id));
    }


    return registerClient?.walletTransfer && checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.WalletTransfer) ?
        (
            <LayoutBaseDePagina
                titulo="Clientes"
                subtitulo='Transferencia de clientes'
                barraDeFerramentas={
                    <ToolbarTransferClients
                        clickBack={() => navigate('/clientes')}
                        onClickTransfer={() => {
                            if (!validFieldsInfo(true)) {
                                toastHandler.present({
                                    type: 'warning',
                                    position: 'top-right',
                                    durationMs: 6000,
                                    messageOrContent: <ToastContent title={"Campo(s) faltam ser preenchido(s)."}
                                        content={"Os campos cliente de origem e cliente de destino precisam ser preenchidos para realizar a transferência dos dados!"} />
                                });
                            } else if (dataClientFilter == dataClientFilter2) {
                                toastHandler.present({
                                    type: 'warning',
                                    position: 'top-right',
                                    durationMs: 6000,
                                    messageOrContent: <ToastContent title={"Atenção!!"}
                                        content={"O cliente de origem e o cliente de destino são os mesmos, por favor verifique ao realizar a transferência!"} />
                                });
                            }
                            else if (!idsProposal?.length) {
                                toastHandler.present({
                                    type: 'warning',
                                    position: 'top-right',
                                    durationMs: 6000,
                                    messageOrContent: <ToastContent title={"Atenção!!"}
                                        content={"Nenhuma garantia foi selecionada para prosseguir na transferência, verifique!"} />
                                });
                            }
                            else setOpenModal(true);
                        }}
                        onChangeClientFilter={value => {
                            setDataClientFilter(value);
                        }}
                        onChangeClientFilterName={value => {
                            setNameClient(value);
                        }}
                        onChangeClientFilter2={value => {
                            setDataClientFilter2(value);
                        }}
                        onChangeClientFilterName2={value => {
                            setNameClient2(value);
                        }}
                        // onChangeDate={value => {
                        //     setDataPort(value);
                        // }}
                        loading={isLoading}
                    />
                }
            >

                {openModal && <BasicModal
                    ITitle="Confirmação de Transferência"
                    IMessage={`Deseja mesmo realizar a transferência destes dados do cliente ${nameClient} para o cliente ${nameClient2} ? Esta operação é irreversível!`}
                    isOpenModal={openModal}
                    showButtonCancel={true}
                    textButtonCancel='Cancelar'
                    onclickCancel={() => setOpenModal(false)}
                    messageType={'info'}
                    textButtonConfirm='Confirmar'
                    onclickConfirm={transferProcess}
                />}

                {openModalInfo && <ModalContext
                    ITitle={`Total das informações de ${typeName?.replace("_", " ")} é ${total} . ${typeName == 'garantias' ? ' Selecione as garantias:' : ''}`}
                    isCloseModal={() => setOpenModalInfo(false)}
                    showButtonConfirm={true}
                    variant='h5'
                    width='75%'
                    textButtonConfirm='Fechar'
                    isOpenModal={openModalInfo}
                    messageType={'info'}
                    onclickConfirm={() => {
                        setOpenModalInfo(false);
                    }}
                    IMessage={[
                        <Box>
                            < TableContainer component={Paper} variant="outlined" sx={{ marginLeft: 1, marginTop: 1, marginRight: 2, width: 'auto', padding: 4 }} aria-label="customized table">
                                <Table aria-label="a dense table" >
                                    <TableHead>
                                        <TableRow sx={{ background: '#f5fbfd' }} >
                                            {typeName == 'garantias' && <TableCell>
                                                <Checkbox color="primary"
                                                    checked={checkboxAllSelected}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleCheckboxChange(event.target.checked);
                                                    }}
                                                    inputProps={{ 'aria-label': 'select all desserts', }}
                                                />
                                            </TableCell>}

                                            <TableCell sx={{ color: "#7e7777b5" }}> Nome</TableCell>
                                            <TableCell sx={{ color: "#7e7777b5" }}> Documento</TableCell>
                                            {typeName == "sinistros" && <TableCell sx={{ color: "#7e7777b5" }}> Endereço de Imóvel</TableCell>}
                                            <TableCell sx={{ color: "#7e7777b5" }}> Valor</TableCell>
                                            <TableCell sx={{ color: "#7e7777b5" }}> Status </TableCell>
                                            {["garantias", "parcelas_garantias"].includes(typeName) && <TableCell sx={{ color: "#7e7777b5" }}> Número Certificado</TableCell>}
                                            <TableCell sx={{ color: "#7e7777b5" }}> Cadastro</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isLoading && <CircularProgress sx={{ marginTop: "10px" }} />}
                                        {!isLoading && !rows?.length && (
                                            <TableRow>
                                                <TableCell colSpan={6} sx={{ fontWeight: "normal", textAlign: "center", fontSize: "18px", borderBottom: "0px" }}>
                                                    <b>Nenhuma informação encontrada!</b>
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        {!isLoading && (rows?.map(row => (
                                            <TableRow key={row.id} hover >

                                                {typeName == "garantias" && <TableCell component="th" scope="row" sx={{ margin: 0 }}>
                                                    <Checkbox color="primary" inputProps={{ 'aria-label': 'select all desserts', }}
                                                        checked={row.isChecked}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSelectCheckBox(event.target.checked, row.id)}
                                                    />
                                                </TableCell>}

                                                <TableCell sx={{ fontWeight: "normal" }}>
                                                    <Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} >
                                                        {
                                                            !["sinistros", "parcelas_garantias"].includes(typeName) ?
                                                                row.name : row.proposal_lessor_name
                                                        }
                                                    </Typography>
                                                </TableCell>

                                                <TableCell sx={{ fontWeight: "normal" }}>
                                                    <Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} >
                                                        {maskCpfOrCnpj(!["sinistros", "parcelas_garantias"].includes(typeName) ? row?.document : row.proposal_lessor_cpf)}
                                                    </Typography>
                                                </TableCell>

                                                {typeName == "sinistros" &&
                                                    <TableCell sx={{ fontWeight: "normal" }}>
                                                        <Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} >
                                                            {`${row?.proposal_address_city != null && row?.proposal_address_street != '' ?
                                                                `${row?.proposal_address_complement != null && row?.proposal_address_complement != '' ?
                                                                    `${row?.proposal_address_street},${row?.proposal_address_number || ""} - ${row?.proposal_address_complement} -
                                              ${row?.proposal_address_district || ""} - ${row?.proposal_address_city || ""} - ${row?.proposal_address_state || ""} - ${row?.proposal_address_zipcode || ""}`
                                                                    : `${row?.proposal_address_street},${row?.proposal_address_number || ""} - 
                                              ${row?.proposal_address_district || ""} - ${row?.proposal_address_city || ""} - ${row?.proposal_address_state || ""} - ${row?.proposal_address_zipcode || ""}`
                                                                } ` : ''
                                                                }`
                                                            }
                                                        </Typography>
                                                    </TableCell>
                                                }

                                                <TableCell sx={{ fontWeight: "normal" }}>
                                                    <Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
                                                        {
                                                            formatScaleValueBR(`${!["sinistros", "parcelas_garantias"].includes(typeName) ? row?.rentAmount || 0 : row?.proposal_rent_amount || 0}`)
                                                        }
                                                    </Typography>
                                                </TableCell>


                                                < TableCell sx={{ fontWeight: "normal" }}>
                                                    <IconButton size="small" sx={{ background: colorBackGroundProposal(row.status), borderRadius: "18px", paddingX: "15px", paddingY: "1px", marginBottom: "5px" }}>
                                                        <Icon sx={{ color: colorIcon(row.status), fontWeight: "normal" }}> noise_control_off_sharp_icon</Icon>
                                                        <Typography variant="subtitle2" marginLeft={1} sx={{ color: colorText(row.status), fontWeight: "normal" }}>
                                                            {
                                                                "parcelas_garantias" == typeName ?
                                                                    globalTranslateStatus(row.statusInstallment) :
                                                                    "sinistros" == typeName ? globalTranslateStatus(row.statusSinister) : globalTranslateStatus(row.status)
                                                            }

                                                        </Typography>
                                                    </IconButton>
                                                </TableCell>

                                                {["garantias", "parcelas_garantias"].includes(typeName) &&
                                                    <TableCell sx={{ fontWeight: "normal" }}>
                                                        <Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
                                                            {typeName == "garantias" ? row.certificateNumber : row.proposal_certificate_number}
                                                        </Typography>
                                                    </TableCell>
                                                }

                                                <TableCell sx={{ fontWeight: "normal" }}>
                                                    <Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
                                                        {
                                                            typeName == "parcelas_garantias" ?
                                                                formatDateBR(row.createdAtInstallment) :
                                                                typeName == "sinistros" ? formatDateBR(row.createdAtSinister) : formatDateBR(row.createdAt)
                                                        }
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ]}
                />}

                <Grid container display="flex" marginTop={"4px"} justifyContent={"space-between"}
                    width={"98%"} bgcolor={"white"} marginLeft={"22px"}>
                    <Typography variant="h5" marginLeft={"10px"} marginTop={"10px"} marginBottom={"20px"}>
                        Itens de transferência
                    </Typography>

                    <Grid item xs={12} style={{ borderRadius: "8px", border: "1px solid lightgray", padding: "12px", marginTop: "8px" }}>
                        <Grid container display="flex" flexDirection="row" wrap='nowrap'
                            sx={{ marginBottom: "10px", alignItems: "center" }}>
                            <Checkbox
                                color="secondary"
                                inputProps={{
                                    'aria-label': 'select all desserts',
                                }}
                                sx={{ paddingLeft: 0 }}
                                checked={isCheckedAnalisis}
                                disabled={true}
                                onChange={(e: any) => {
                                    // setIsCheckedAnalisis(e.target.checked);
                                    // dataTypes(e, "analises");
                                }}
                            />
                            <Typography variant="caption" color={"red"} fontSize={"18px"}>
                                Análises
                            </Typography>
                            {isCheckedAnalisis && (
                                <Button
                                    style={{
                                        backgroundColor: "lightgray", marginLeft: "15px",
                                        height: "25px",
                                        fontSize: "12px",
                                        borderRadius: "10px",
                                    }}
                                    color={"info"}
                                    disableElevation
                                    variant={"outlined"}
                                    startIcon={<Icon>visibility</Icon>}
                                    onClick={() => {
                                        dataTypes(null, "analises");
                                    }}
                                >
                                    Informação das análises
                                </Button>
                            )}
                        </Grid>
                        <Typography variant="caption" marginLeft={"2px"}>
                            Todas as análises serão tranferidas.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ borderRadius: "8px", border: "1px solid lightgray", padding: "12px" }}>
                        <Grid container display="flex" flexDirection="row" wrap='nowrap'
                            sx={{ marginBottom: "10px", alignItems: "center" }}>
                            {/* <Checkbox
                            color="secondary"
                            sx={{ paddingLeft: 0 }}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                            checked={isCheckedProposal}
                            disabled={true}
                            onChange={(e: any) => {
                                // setIsCheckedProposal(e.target.checked);
                                // dataTypes(e, "garantias");
                            }}
                        /> */}
                            <Typography variant="caption" color={"red"} fontSize={"18px"}>
                                Garantias
                            </Typography>
                            {isCheckedProposal && (
                                <Button
                                    style={{
                                        backgroundColor: "lightgray", marginLeft: "15px",
                                        height: "25px",
                                        fontSize: "12px",
                                        borderRadius: "10px",
                                    }}
                                    color={"info"}
                                    disableElevation
                                    variant={"outlined"}
                                    startIcon={<Icon>visibility</Icon>}
                                    onClick={() => {
                                        dataTypes(null, "garantias");
                                    }}
                                >
                                    Informação das garantias
                                </Button>
                            )}
                        </Grid>
                        <Typography variant="caption" marginLeft={"2px"}>
                            Selecione as garantias a serem transferidas.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ borderRadius: "8px", border: "1px solid lightgray", padding: "12px", marginTop: "8px" }}>
                        <Grid container display="flex" flexDirection="row" wrap='nowrap'
                            sx={{ marginBottom: "10px", alignItems: "center" }}>
                            {/* <Checkbox
                            color="secondary"
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                            sx={{ paddingLeft: 0 }}
                            checked={isCheckedInstallmentsProposal}
                            disabled={true}
                            onChange={(e: any) => {
                                // setIsCheckedInstallmentsProposal(e.target.checked);
                                // dataTypes(e, "parcelas_garantias");
                            }}
                        /> */}
                            <Typography variant="caption" color={"red"} fontSize={"18px"}>
                                Parcelas das Garantias
                            </Typography>
                            {/* {isCheckedInstallmentsProposal && (
                            <Button
                                style={{
                                    backgroundColor: "lightgray", marginLeft: "15px",
                                    height: "25px",
                                    fontSize: "12px",
                                    borderRadius: "10px",
                                }}
                                color={"info"}
                                disableElevation
                                variant={"outlined"}
                                startIcon={<Icon>visibility</Icon>}
                                onClick={() => {
                                    dataTypes(null, "parcelas_garantias");
                                }}
                            >
                                Informação das parcelas das garantias
                            </Button>
                        )} */}
                        </Grid>
                        <Typography variant="caption" marginLeft={"2px"}>
                            As parcelas em aberto serão transferidas conforme garantias selecionadas.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ borderRadius: "8px", border: "1px solid lightgray", padding: "12px", marginTop: "8px" }}>
                        <Grid container display="flex" flexDirection="row" wrap='nowrap'
                            sx={{ marginBottom: "10px", alignItems: "center" }}>
                            {/* <Checkbox
                            color="secondary"
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                            sx={{ paddingLeft: 0 }}
                            checked={isCheckedSinister}
                            disabled={true}
                            onChange={(e: any) => {
                                // setIsCheckedSinister(e.target.checked);
                                // dataTypes(e, "sinistros");
                            }}
                        /> */}
                            <Typography variant="caption" color={"red"} fontSize={"18px"}>
                                Sinistros
                            </Typography>
                            {/* {isCheckedSinister && (
                            <Button
                                style={{
                                    backgroundColor: "lightgray", marginLeft: "15px",
                                    height: "25px",
                                    fontSize: "12px",
                                    borderRadius: "10px",
                                }}
                                color={"info"}
                                disableElevation
                                variant={"outlined"}
                                startIcon={<Icon>visibility</Icon>}
                                onClick={() => {
                                    dataTypes(null, "sinistros");
                                }}
                            >
                                Informação dos sinistros
                            </Button>
                        )} */}
                        </Grid>
                        <Typography variant="caption" marginLeft={"2px"}>
                            Os sinistros das garantias selecionadas serão transferidos.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ borderRadius: "8px", border: "1px solid lightgray", padding: "12px", marginTop: "8px" }}>
                        <Grid container display="flex" flexDirection="row" wrap='nowrap'
                            sx={{ marginBottom: "10px", alignItems: "center" }}>
                            <Typography variant="caption" color={"red"} fontSize={"18px"}>
                                Boletos
                            </Typography>
                        </Grid>
                        <Typography variant="caption">
                            Para gerenciar os boletos, acessar Gerenciar financeiro/boletos.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ borderRadius: "8px", border: "1px solid lightgray", padding: "12px", marginTop: "8px", marginBottom: "8px" }}>
                        <Grid container display="flex" flexDirection="row" wrap='nowrap'
                            sx={{ marginBottom: "10px", alignItems: "center" }}>
                            <Typography variant="caption" color={"red"} fontSize={"18px"}>
                                Notas Fiscais
                            </Typography>
                        </Grid>
                        <Typography variant="caption">
                            Para gerenciar as notas fiscais, acessar Gerenciar financeiro/notas fiscais.
                        </Typography>
                    </Grid>
                </Grid>

            </LayoutBaseDePagina>

        ) : (<Grid>Sem Permissão para acessar essa página.</Grid>);
};