import { Box, CircularProgress, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { StyledComponentPropsType } from '../../../../shared/types';
import { SinisterDocumentsService, TSinisterDocumentsListaAndTotal } from '../../../../shared/services/api/sinister/SinisterDocumentsService';
import { SinisterDocumentType } from '../../../../shared/services/api/sinister/types';
import {
  Divider, GreyedTypography,
  StyledEmptyListBox,
  StyledFileListContainer,
  StyledFileListSpinnerBox
} from './SinisterFilesList.styled';
import { SinisterFileItem } from './SinisterFileItem';
import { useToast } from '../../../../shared/hooks';


type SinisterFilesListProps = StyledComponentPropsType & {
  sinisterId: string,
  checkListId: string
  statusSinister?: string;
  setRefreshList?: (value: boolean) => void;
}
export const SinisterFilesList = ({ sinisterId, checkListId, statusSinister, setRefreshList }: SinisterFilesListProps) => {
  const [documentsList, setDocumentsList] = useState<SinisterDocumentType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshListDoc, setRefreshListDoc] = useState<boolean>(false);
  const handler = useToast()

  useEffect(() => {
    SinisterDocumentsService.getSinisterDocuments(sinisterId, checkListId).then((value) => {
      // console.log('sinisterFileList....27',value)
      // console.log('sinisterFileList....27aaa',value.data)
      // console.log('sinisterFileList....27bbbbbb',value.data.result)

      setDocumentsList(value.data);
      setIsLoading(false);
    }).catch(_ => {
      setIsLoading(false);
    })
  }, [])

  useEffect(() => {
    if (refreshListDoc) {
      SinisterDocumentsService.getSinisterDocuments(sinisterId, checkListId).then((value) => {
        // console.log('sinisterFileList....27',value)
        // console.log('sinisterFileList....27aaa',value.data)
        // console.log('sinisterFileList....27bbbbbb',value.data.result)

        setDocumentsList(value.data);
        setIsLoading(false);
      }).catch(_ => {
        setIsLoading(false);
      })
    }
  }, [refreshListDoc]);


  const isEmptyList = useMemo(() => {
    // console.log('37....', documentsList);
    return documentsList?.length === 0
  }, [documentsList, isLoading])

  return (
    <StyledFileListContainer>
      {isLoading && (
        <StyledFileListSpinnerBox>
          <CircularProgress variant='indeterminate' />
        </StyledFileListSpinnerBox>
      )}

      {!isLoading && !isEmptyList && (

        documentsList?.map((item, index) =>
        (

          <Box key={item.id} style={{ marginBottom: '16px' }}>
            <SinisterFileItem item={item}
              statusSinister={statusSinister}
              refreshListDocuments={(value: boolean) => {
                setRefreshListDoc(value);
                setRefreshList?.(value);
              }}
            />
            {index < documentsList.length - 1 ? <Divider /> : null}
          </Box>
          // <GreyedTypography> {item.filename} </GreyedTypography>

          // <Box key={item.id} style={{marginBottom: '16px'}}>
          //   <SinisterFileItem item={item}/>
          //   { index < documentsList.length - 1 ? <Divider/> : null}
          // </Box>
        )

        ))}
      {!isLoading && isEmptyList && (
        <StyledEmptyListBox>
          <GreyedTypography>Não há arquivos para serem exibidos</GreyedTypography>
        </StyledEmptyListBox>
      )}
    </StyledFileListContainer>
  )
}