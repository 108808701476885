import {AuthGuard} from '../../shared/guards/AuthGuard';
import {NfseListPresentation} from './components';
import {Sidebar} from '../../shared/components';

export const NfseListPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <NfseListPresentation/>
            </Sidebar>
        </AuthGuard>
    )
};