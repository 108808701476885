
import axios from "axios";
import { responseInterceptor } from './interceptos';
import { Environment } from "../../../environment";
import {StorageKeysEnum} from '../../../enums';

interface IApiParams {
  disableResponseInterceptor: boolean
}

const Api = (params?: IApiParams) => {
  const token = localStorage.getItem(StorageKeysEnum.AccessToken);
  const api = axios.create({
    baseURL: Environment.URL_BASE,
    headers: {
      'Authorization': `Bearer ${token}`
      //'Authorization': `${token}`
    }
  });

      if (!params?.disableResponseInterceptor) {
        api.interceptors.response.use(
          (response) => responseInterceptor(response),
          //(error) => errorInterceptor(error),

          (error) => {
            console.dir(error?.response)
            const originalRequest = error.config;

/*            if (error.response.status === 404) {
              localStorage.removeItem('APP_ACCESS_TOKEN');
              window.location.assign(window.location.protocol + "//" + window.location.host + "/login");
              return Promise.reject(error);
            }*/

            if (error.response.status === 401 && !originalRequest._retry) {
              originalRequest._retry = true;
              const token : string = localStorage.getItem(StorageKeysEnum.AccessToken) || '';
              return refreshAccessToken(token).then((accessToken) => {
                // Replace the access token in the original request config
                originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                return api(originalRequest);
              }).catch(() => {
                localStorage.clear();
                window.location.assign(window.location.protocol + "//" + window.location.host + "/login");
              });
            }

            return Promise.reject(error);
          }


        );
      }

  return api;
};

function refreshAccessToken(refreshToken: string): Promise<string> {
  // Send a request to the server to refresh the access token using the refresh token
  return axios
    .put(`${Environment.URL_BASE}/auth/refresh`,
    { refreshToken },
    { headers:
     {
      'Authorization': `Bearer ${refreshToken}`
      //'Authorization': `${token}`
      }
    })
    .then((response) => response.data.accessToken);
}
export { Api };
