import * as React from 'react';
import { defaultStyles, FileIcon } from 'react-file-icon';

export const getFileExtensionIcon = (mimetype: string): React.ReactElement => {
  const mapper: Record<string, React.ReactElement> = {
    'application/pdf': <FileIcon extension='pdf' {...defaultStyles['pdf']}/>,
  'image/jpeg': <FileIcon extension='jpeg' {...defaultStyles['jpeg']} />,
  'image/jpg': <FileIcon extension='jpg' {...defaultStyles['jpg']}/>,
  'image/png': <FileIcon extension='png' {...defaultStyles['png']}/>,
  'application/msword': <FileIcon extension='doc' {...defaultStyles['doc']}/>,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': <FileIcon extension='docx' {...defaultStyles['docx']}/>,
  'application/vnd.oasis.opendocument.text': <FileIcon extension='odt' {...defaultStyles['odt']}/>
}

  return mapper[mimetype] ? <span style={{
    resize: 'both',
    overflow: 'auto'
  }}>
  {mapper[mimetype]}
  </span> :
  <span style={{
    resize: 'both',
      overflow: 'auto'
  }}>
  <FileIcon extension='ts' {...defaultStyles['ts']}/>
  </span>
}