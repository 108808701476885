import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToolbarGridFinancial } from "../../../shared/components/toolbarGridFinancial/ToolbarGridFinancial";

import {
  ModalContext,
  ToolbarFinancial
} from "../../../shared/components";
import {
  FinancialService,
  ICancelInvoice,
  IListInvoice,
  IPaymentInvoice
} from "../../../shared/services/api/financial/FinancialService";

import { format, isValid } from "date-fns";
import moment from "moment";
import "moment/locale/pt-br";
import bradesco from "../../../assets/banks/bradesco.png";
import pjbank from "../../../assets/banks/pjbank.png";
import sicoob from "../../../assets/banks/sicoob.png";
import { ToastContent } from "../../../shared/components";
import { Environment } from "../../../shared/environment";
import {
  useVForm,
  VForm,
  VTextField
} from "../../../shared/forms";
import { VMaskTextField } from "../../../shared/forms/VMaskTextField";
import { useDebounce, useToast } from "../../../shared/hooks";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { set } from "lodash";

interface IFormInvoice {
  dueDate: string; //"2023-01-11",
  gateway: string; //"EMPTY",
  status: string; //"pending",
  clientId: string; //"55fbd98e-eb54-45b9-9ec4-2369e3ea85d5",
  userId: string; // "01b47ca1-70c1-45ac-8abf-d592e34738f9",
  value: string; //"10.00"
}

const removeMask = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/[^0-9]/g, "")
    .replace(/(\d{1,2})$/, ".$1");
};

function colorBackGround(status: string) {
  if (status === "renovated") {
    return "#a9e9eb";
  }
  if (status === "suspended") {
    return "#EEC5C5";
  }
  if (status === "active") {
    return "#d7ffcb;";
  }
  if (status === "expired") {
    return "#F4ECC0";
  }
  if (status === "canceled") {
    return "#EEC5C5";
  }
  if (status === "pending") {
    return "#e6e1e1";
  }
  if (status === "open") {
    return "#d0fdff";
  }
  if (status === "paid") {
    return "#e0ffd6";
  }

  return "#959595";
}

function colorIcon(status: string) {
  if (status === "expired") {
    return "#e2d73a";
  }
  if (status === "active") {
    return "#5ebe76";
  }
  if (status === "canceled") {
    return "#eb3232";
  }
  if (status === "suspended") {
    return "#eb3232";
  }
  if (status === "renovated") {
    return "#3761eb";
  }
  if (status === "pending") {
    return "#8f9196";
  }
  if (status === "open") {
    return "#7693f3";
  }
  if (status === "paid") {
    return "#5ebe76";
  }
  return "#202020";
}

function colorText(status: string) {
  if (status === "expired") {
    return "#e2c93a";
  }
  if (status === "active") {
    return "#5ebe76";
  }
  if (status === "canceled") {
    return "#eb3232";
  }
  if (status === "suspended") {
    return "#eb3232";
  }
  if (status === "renovated") {
    return "#3761eb";
  }
  if (status === "pending") {
    return "#8f9196";
  }
  if (status === "open") {
    return "#3761eb";
  }
  if (status === "paid") {
    return "#5ebe76";
  }

  return "#202020";
}

function translate(traduzir: String) {
  if (traduzir === "expired") {
    return "Expirada";
  }
  if (traduzir === "active") {
    return "Vigente";
  }
  if (traduzir === "suspended") {
    return "Suspensa";
  }
  if (traduzir === "renovated") {
    return "Renovada";
  }
  if (traduzir === "canceled") {
    return "Cancelada";
  }
  if (traduzir === "pending") {
    return "Faturada";
  }
  if (traduzir === "open") {
    return "Aberto";
  }
  if (traduzir === "paid") {
    return "Pago";
  }

  return traduzir;
}

function colorLine(line: number) {
  if (line % 2 == 0) {
    return "#e5e8eb";
  }
  return "#ffffff";
}

const maskReal = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{1,2})$/, ",$1")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const FinancialInvoiceListPresentation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } =
    useVForm();
  const [rows, setRows] = useState<IListInvoice[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [layoutFinan, setLayoutFinan] = useState("installment");
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [reasonCancel, setReasonCancel] = useState("");
  const toastHandler = useToast();
  const [refreshList, setRefreshList] = useState(false);
  const [modalSend, setModalSend] = useState(false);
  const [modalReport, setModalReport] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const [loadEmail, setLoadEmail] = useState("");
  const [loadInvoice, setLoadInvoice] = useState("");
  const [loadURL, setLoadURL] = useState("");
  const [loadExtract, setLoadExtract] = useState("");
  const [idCancel, setIdCancel] = useState("");
  const [idPayment, setIdPayment] = useState("");
  const [nameclient, setnameclient] = useState("");
  const [valueInvoice, setvalueInvoice] = useState("");
  const [dueDateInvoice, setDueDateInvoice] = useState("");
  const [reportBegin, setReportBegin] = useState("");
  const [reportEnd, setReportEnd] = useState("");
  const [reportType, setReportType] = useState("");
  const [reportOrder, setReportOrder] = useState("nome");

  const busca = useMemo(() => {
    return searchParams.get("busca") || "";
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get("pagina") || "1");
  }, [searchParams]);

  const status = useMemo(() => {
    return searchParams.get("status") || "";
  }, [searchParams]);

  const dataIni = useMemo(() => {
    return (
      searchParams.get("dataini") ||
      moment().startOf("month").format("YYYY-MM-DD")
    );
  }, [searchParams]);

  const dataFim = useMemo(() => {
    return (
      searchParams.get("datafim") ||
      moment().endOf("month").format("YYYY-MM-DD")
    );
  }, [searchParams]);

  // const URL = 'https://appweb-staging.appweb.garantti.com.br'
  const URL = window.location.protocol + "//" + window.location.host;

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      FinancialService.getInvoice(pagina, busca, status, dataIni, dataFim).then(
        (result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        }
      );
    });
  }, [pagina, busca, status, dataIni, dataFim, refreshList]);

  const handleModalCancel = () => {
    setModalCancel(true);
  };

  const handleModalPayment = () => {
    setModalPayment(true);
  };
  const handleModalReport = () => {
    setModalReport(true);
    setReportBegin(dataIni);
    setReportEnd(dataFim);
    setReportType("todos");
    setReportOrder("nome");
  };

  const handleConciliation = async () => {
    setIsLoading(true);
    const dataAtual = moment(dataIni);
    const dataFinal = moment(dataFim);
    const dados = [];

    while (dataAtual.isSameOrBefore(dataFinal)) {
      const dataFormatada = dataAtual.format("YYYY-MM-DD");

      try {
        // Utilize o await para esperar a execução da função assíncrona
        const result = await FinancialService.getConciliation(
          dataFormatada,
          dataFormatada
        );

        if (result instanceof Error) {
          alert(result.message);
        } else {
          // Faça o que precisa com os dados retornados
          // Exemplo: dados.push({ data: dataFormatada, dados: result });
          // setModalDetail(true);
        }
      } catch (error) {
        //  alert(error.message);
      }

      console.log(dataFormatada, dataAtual);

      // Avança para o próximo dia
      dataAtual.add(1, "days");
    }

    setIsLoading(false);
  };

  const handleReportInvoice = () => {
    setIsLoading(true);
    debounce(() => {
      FinancialService.getReportInvoice(
        reportType,
        reportOrder,
        reportBegin,
        reportEnd
      ).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
        } else {
          //setModalDetail(true)
        }
      });
    });
  };

  const handleCancelInvoice = (dados: ICancelInvoice) => {
    FinancialService.CancelInvoice(idCancel, { ...dados }).then((result) => {
      setIsLoading(false);
      //console.log(dados);
      if (result instanceof Error) {
        toastHandler.present({
          type: "error",
          position: "top-right",
          messageOrContent: (
            <ToastContent
              title={"Erro"}
              content={"Erro ao cancelar o boleto"}
            />
          ),
        });
        setRefreshList(!refreshList);
      } else {
        toastHandler.present({
          type: "success",
          position: "top-right",
          messageOrContent: (
            <ToastContent
              title={"Sucesso"}
              content={"Boleto cancelado com sucesso"}
            />
          ),
        });
        setRefreshList(!refreshList);
      }
    });
  };

  const handlePaymentInvoice = (dados: IPaymentInvoice) => {
    // console.log('handlepaymentinvoice....232 ', dados);
    dados.creditAmount = removeMask(dados.creditAmount);
    FinancialService.PaymentInvoice(idCancel, { ...dados }).then((result) => {
      setIsLoading(false);
      //console.log(dados);
      if (result instanceof Error) {
        toastHandler.present({
          type: "error",
          position: "top-right",
          messageOrContent: (
            <ToastContent title={"Erro"} content={"Erro ao baixar o boleto"} />
          ),
        });
        setRefreshList(true);
      } else {
        toastHandler.present({
          type: "success",
          position: "top-right",
          messageOrContent: (
            <ToastContent
              title={"Sucesso"}
              content={"Boleto baixado com sucesso"}
            />
          ),
        });
        setRefreshList(true);
        setModalPayment(false);
      }
    });
  };

  const handleExtractPDF = (IID: string) => {
    setIsLoading(true);
    debounce(() => {
      FinancialService.getInvoicesPDF(IID).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
        } else {
          //setModalDetail(true)
        }
      });
    });
  };

  const handleCriateBillet = (idBIllet: string, dueDate: string) => {
    setIsLoading(true);
    debounce(() => {
      FinancialService.generateBillet(idBIllet, dueDate).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
        } else {
          toastHandler.present({
            type: "success",
            position: "top-right",
            messageOrContent: (
              <ToastContent
                title={"Sucesso"}
                content={"Boleto enviado com sucesso"}
              />
            ),
          });
          setRefreshList(true);
        }
      });
    });
  };

  const handleResendEmail = (idInvoice: string) => {
    setIsLoading(true);
    debounce(() => {
      FinancialService.SendEmail(idInvoice).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
        } else {
          toastHandler.present({
            type: "success",
            position: "top-right",
            messageOrContent: (
              <ToastContent
                title={"Sucesso"}
                content={"Boleto enviado com sucesso"}
              />
            ),
          });
          setRefreshList(true);
        }
      });
    });
  };

  return (
    <LayoutBaseDePagina
      titulo="Financeiro"
      subtitulo="Movimentação Financeira - Boletos"
      barraDeFerramentas={
        <ToolbarFinancial
          onClickFaturamento={() => {
            navigate(`/financeiro/`);
          }}
          onClickBoletos={() => setLayoutFinan("installment")}
          onClickNfse={() => setLayoutFinan("nfse")}
          onClickSetup={() => {setLayoutFinan("setup")}}
          selectBoletos={layoutFinan === "installment"}
          selectFaturamento={layoutFinan === "invoicing"}
          selectNfse={layoutFinan === "nfse"}
          showButtonReport={false}
          onClickReport={handleModalReport}
          onChangeDataIni={(retorno: string) =>
            setSearchParams(
              { busca, pagina: String(1), dataini: retorno, datafim: dataFim },
              { replace: true }
            )
          }
          onChangeDataFim={(retorno: string) =>
            setSearchParams(
              { busca, pagina: String(1), dataini: dataIni, datafim: retorno },
              { replace: true }
            )
          }
        />
      }
    >
      {modalCancel && (
        <ModalContext
          isCloseModal={() => setModalCancel(false)}
          isOpenModal={modalCancel}
          messageType={"question"}
          showButtonCancel={false}
          textButtonConfirm={"Cancelar"}
          textButtonCancel={"Voltar"}
          onclickConfirm={() => {
            handleModalCancel();
          }}
          ITitle={"Confirma o cancelamento do boleto ?"}
          IMessage={[
            <Box style={{ width: "100%" }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
              >
                <VForm
                  id="formIdCancelInvoice"
                  placeholder="Your placeholder"
                  onPointerEnterCapture={() => console.log("Pointer entered")}
                  onPointerLeaveCapture={() => console.log("Pointer left")}
                  ref={formRef}
                  onSubmit={handleCancelInvoice}
                >
                  <Grid container direction="column" padding={1} spacing={2}>
                    {isLoading && (
                      <Grid item>
                        <LinearProgress variant="indeterminate" />
                      </Grid>
                    )}

                    <Grid
                      container
                      item
                      direction="row"
                      spacing={1}
                      sx={{ mb: 0 }}
                    >
                      <Grid item xs={1} sx={{ textAlign: "left" }}>
                        Cliente :
                      </Grid>
                      <Grid item xs={8} sx={{ alignItems: "left" }}>
                        <Typography
                          variant="inherit"
                          sx={{ fontWeight: "bold" }}
                        >
                          {nameclient}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      direction="row"
                      spacing={1}
                      sx={{ mb: 2 }}
                    >
                      <Grid item xs={2} sx={{ textAlign: "left" }}>
                        Vencimento :
                      </Grid>
                      <Grid item xs={2} sx={{ alignItems: "left" }}>
                        <Typography
                          variant="inherit"
                          sx={{ fontWeight: "bold" }}
                        >
                          {moment(dueDateInvoice).format("L")}
                        </Typography>
                      </Grid>

                      <Grid item xs={2} sx={{ textAlign: "right" }}>
                        Valor :
                      </Grid>
                      <Grid item xs={3} sx={{ alignItems: "left" }}>
                        <Typography
                          variant="inherit"
                          sx={{ fontWeight: "bold" }}
                        >
                          {parseFloat(valueInvoice).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Divider />

                    <Grid container item direction="row" spacing={2} mt={1}>
                      <Grid item xs={12} md={8} lg={12} xl={12}>
                        <VTextField
                          fullWidth
                          label="Motivo do cancelamento"
                          name="canceledType"
                          disabled={isLoading}
                          defaultValue="Outros"
                          select
                          onChange={(e) => setReasonCancel(e.target.value)}
                        >
                          <MenuItem value={"Valor incorreto"}>
                            Valor Incorreto
                          </MenuItem>
                          <MenuItem value={"Duplicidade"}>Duplicidade</MenuItem>
                          <MenuItem value={"Isenção de encargos"}>
                            Isenção de encargos
                          </MenuItem>
                          <MenuItem value={"Outros"}>Outros</MenuItem>
                        </VTextField>
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12}>
                        <VTextField
                          fullWidth
                          label="Descreva o motivo"
                          name="canceledReason"
                          disabled={isLoading}
                          multiline
                          rows={4}
                        ></VTextField>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sm={12}
                    sx={{
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    display={"flex"}
                    flexDirection={smDown ? "column" : "row"}
                    padding={2}
                    gap={2}
                  >
                    {reasonCancel != "" ? (
                      <Button
                        color="info"
                        disableElevation
                        variant={"contained"}
                        startIcon={<Icon> checkIcon</Icon>}
                        onClick={() => {
                          formRef.current?.submitForm();
                          navigate("/boletos/");
                          setModalCancel(false);
                        }}
                      >
                        {"Cancelar Boleto"}
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      color="info"
                      disableElevation
                      variant={"outlined"}
                      startIcon={<Icon> arrow_back_Ico</Icon>}
                      onClick={() => {
                        navigate("/boletos/");
                        setModalCancel(false);
                      }}
                    >
                      {"Voltar"}
                    </Button>
                  </Grid>
                </VForm>
              </Typography>
            </Box>,
          ]}
        />
      )}

      {modalPayment && (
        <ModalContext
          isCloseModal={() => setModalPayment(false)}
          isOpenModal={modalPayment}
          messageType={"question"}
          showButtonCancel={false}
          textButtonConfirm={"Baixar"}
          textButtonCancel={"Voltar"}
          onclickConfirm={() => {
            handleModalPayment();
          }}
          ITitle={"Confirma o pagamento manual do boleto ?"}
          IMessage={[
            <Box style={{ width: "100%" }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
              >
                <VForm
                  id="formIdPaymentInvoice"
                  placeholder="Your placeholder"
                  onPointerEnterCapture={() => console.log("Pointer entered")}
                  onPointerLeaveCapture={() => console.log("Pointer left")}
                  ref={formRef}
                  onSubmit={handlePaymentInvoice}
                >
                  <Grid container direction="column" padding={1} spacing={2}>
                    {isLoading && (
                      <Grid item>
                        <LinearProgress variant="indeterminate" />
                      </Grid>
                    )}

                    <Grid
                      container
                      item
                      direction="row"
                      spacing={1}
                      sx={{ mb: 0 }}
                    >
                      <Grid item xs={1} sx={{ textAlign: "left" }}>
                        Cliente :
                      </Grid>
                      <Grid item xs={8} sx={{ alignItems: "left" }}>
                        <Typography
                          variant="inherit"
                          sx={{ fontWeight: "bold" }}
                        >
                          {nameclient}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      direction="row"
                      spacing={1}
                      sx={{ mb: 2 }}
                    >
                      <Grid item xs={2} sx={{ textAlign: "left" }}>
                        Vencimento :
                      </Grid>
                      <Grid item xs={2} sx={{ alignItems: "left" }}>
                        <Typography
                          variant="inherit"
                          sx={{ fontWeight: "bold" }}
                        >
                          {moment(dueDateInvoice).format("L")}
                        </Typography>
                      </Grid>

                      <Grid item xs={2} sx={{ textAlign: "right" }}>
                        Valor :
                      </Grid>
                      <Grid item xs={3} sx={{ alignItems: "left" }}>
                        <Typography
                          variant="inherit"
                          sx={{ fontWeight: "bold" }}
                        >
                          {parseFloat(valueInvoice).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Divider />

                    <Grid container item direction="row" spacing={2} mt={1}>
                      <Grid item xs={4} sx={{ alignItems: "left" }}>
                        <VTextField
                          fullWidth
                          label="Data Pagamento"
                          name="paymentDate"
                          disabled={false}
                          type="date"
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ alignItems: "left" }}>
                        <VMaskTextField
                          fullWidth
                          label="Valor Pago"
                          name="creditAmount"
                          disabled={false}
                          value={parseFloat(valueInvoice).toLocaleString(
                            "pt-br",
                            { style: "currency", currency: "BRL" }
                          )}
                          mask="real"
                        />
                      </Grid>
                    </Grid>

                    <Grid container item direction="row" spacing={2} mt={1}>
                      <Grid item xs={12} md={8} lg={12} xl={12}>
                        <VTextField
                          fullWidth
                          label="Motivo da Baixa manual"
                          name="canceledType"
                          disabled={isLoading}
                          defaultValue="Outros"
                          select
                          onChange={(e) => setReasonCancel(e.target.value)}
                        >
                          <MenuItem value={"Erro no sistema - manually"}>
                            Erro no sistema
                          </MenuItem>
                          <MenuItem value={"Pagamento direto - manually"}>
                            Pagamento Direto Garantti
                          </MenuItem>
                          <MenuItem
                            value={"Boleto descontado/ajustado - manually"}
                          >
                            Boleto descontado/ajustado
                          </MenuItem>
                          <MenuItem value={"Outros - manually"}>
                            Outros
                          </MenuItem>
                        </VTextField>
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12}>
                        <VTextField
                          fullWidth
                          label="Descreva o pagamento"
                          name="canceledReason"
                          disabled={isLoading}
                          multiline
                          rows={4}
                        ></VTextField>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sm={12}
                    sx={{
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    display={"flex"}
                    flexDirection={smDown ? "column" : "row"}
                    padding={2}
                    gap={2}
                  >
                    {reasonCancel != "" ? (
                      <Button
                        color="error"
                        disableElevation
                        variant={"contained"}
                        startIcon={<Icon> checkIcon</Icon>}
                        onClick={() => {
                          formRef.current?.submitForm();
                          // navigate('/boletos/');
                          //    setModalPayment(false)
                        }}
                      >
                        {"Baixar Boleto"}
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      color="error"
                      disableElevation
                      variant={"outlined"}
                      startIcon={<Icon> arrow_back_Ico</Icon>}
                      onClick={() => {
                        //     navigate('/boletos/');
                        setModalPayment(false);
                      }}
                    >
                      {"Voltar"}
                    </Button>
                  </Grid>
                </VForm>
              </Typography>
            </Box>,
          ]}
        />
      )}

      {modalSend && (
        <ModalContext
          isCloseModal={() => setModalSend(false)}
          isOpenModal={modalSend}
          messageType={"alert"}
          showButtonCancel={false}
          textButtonConfirm={"Fechar"}
          onclickConfirm={() => {}}
          ITitle={"Segunda Via de Boleto"}
          IMessage={[
            <>
              <Box>
                <Grid
                  container
                  item
                  direction="row"
                  gap={2}
                  sx={{ mb: "2rem" }}
                >
                  <Typography variant="subtitle1" sx={{ alignSelf: "center" }}>
                    Email cadastrado :
                  </Typography>
                  <Typography variant="h6">{loadEmail}</Typography>
                </Grid>

                <Grid container item direction="row" gap={2}>
                  <Button
                    color="info"
                    disableElevation
                    variant={"contained"}
                    startIcon={<Icon> search</Icon>}
                    onClick={() => {
                      setModalSend(false);
                      window.open(loadURL);
                    }}
                  >
                    {"Visualizar"}
                  </Button>

                  <Button
                    color="info"
                    disableElevation
                    variant={"outlined"}
                    startIcon={<Icon> mail_outlined_icon</Icon>}
                    onClick={() => {
                      setModalSend(false);
                      handleResendEmail(loadInvoice);
                    }}
                  >
                    {"Reenviar por email"}
                  </Button>

                  <Button
                    color="info"
                    disableElevation
                    variant={"outlined"}
                    startIcon={<Icon> close</Icon>}
                    onClick={() => {
                      setModalSend(false);
                    }}
                  >
                    {"Fechar"}
                  </Button>
                </Grid>
              </Box>
            </>,
          ]}
        />
      )}

      {modalReport && (
        <ModalContext
          isCloseModal={() => setModalReport(false)}
          isOpenModal={modalReport}
          messageType={"info"}
          showButtonCancel={false}
          textButtonConfirm={"Fechar"}
          onclickConfirm={() => {}}
          ITitle={"Relatorio de Boletos"}
          IMessage={[
            <>
              <Box style={{ width: "80%" }}>
                <Grid
                  container
                  item
                  direction="row"
                  gap={3}
                  sx={{ mb: 3, mt: "1rem" }}
                >
                  <Grid item xs={4} sx={{ alignItems: "left" }}>
                    <TextField
                      fullWidth
                      label="Tipo de Cliente"
                      name="typePerson"
                      disabled={false}
                      size="small"
                      select
                      InputLabelProps={{ shrink: true, required: true }}
                      defaultValue={reportType}
                      onChange={(e) => setReportType(e.target.value)}
                    >
                      <MenuItem value={"todos"} selected>
                        Todos
                      </MenuItem>
                      <MenuItem value={"pagamento"}>Pago</MenuItem>
                      <MenuItem value={"vencidos"} sx={{ color: "red" }}>
                        Vencidos
                      </MenuItem>
                      <MenuItem value={"avencer"}>A vencer</MenuItem>
                      <MenuItem value={"cancelados"} sx={{ color: "orange" }}>
                        Cancelado
                      </MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={3} sx={{ alignItems: "left" }}>
                    <TextField
                      fullWidth
                      label="Data Inicial"
                      name="reportBegin"
                      disabled={isLoading}
                      size="small"
                      type="date"
                      InputLabelProps={{ shrink: true, required: true }}
                      defaultValue={reportBegin}
                      onChange={(e) => {
                        setReportBegin(e.target.value);
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={3} sx={{ alignItems: "left" }}>
                    <TextField
                      fullWidth
                      label="Data final"
                      name="repostEnd"
                      disabled={isLoading}
                      size="small"
                      type="date"
                      InputLabelProps={{ shrink: true, required: true }}
                      defaultValue={reportEnd}
                      onChange={(e) => {
                        setReportEnd(e.target.value);
                      }}
                    ></TextField>
                  </Grid>

                  <Grid item xs={4} sx={{ alignItems: "left" }}>
                    <TextField
                      fullWidth
                      label="Ordem do relatório"
                      name="order"
                      disabled={false}
                      size="small"
                      select
                      InputLabelProps={{ shrink: true, required: true }}
                      defaultValue={reportOrder}
                      onChange={(e) => setReportOrder(e.target.value)}
                    >
                      <MenuItem value={"nome"} selected>
                        Nome
                      </MenuItem>
                      <MenuItem value={"vencimento"}>Vencimento</MenuItem>
                      <MenuItem value={"status"}>Status</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  direction="row"
                  gap={2}
                  justifyContent={"center"}
                >
                  <Button
                    color="primary"
                    disableElevation
                    variant={"contained"}
                    startIcon={<Icon> search</Icon>}
                    onClick={() => {
                      setModalReport(false);
                      handleReportInvoice();
                    }}
                  >
                    {"Visualizar"}
                  </Button>

                  <Button
                    color="primary"
                    disableElevation
                    variant={"outlined"}
                    startIcon={<Icon> close</Icon>}
                    onClick={() => {
                      setModalReport(false);
                    }}
                  >
                    {"Fechar"}
                  </Button>
                </Grid>
              </Box>
            </>,
          ]}
        />
      )}

      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{
          marginLeft: 1,
          marginTop: 1,
          marginRight: 2,
          width: "auto",
          padding: 4,
        }}
      >
        <Table aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={8}>
                <ToolbarGridFinancial
                  textSearchGrid={busca}
                  onChangeTextSearchGrid={(text) =>
                    setSearchParams(
                      {
                        busca: text,
                        pagina: "1",
                        status: status,
                        dataini: dataIni,
                        datafim: dataFim,
                      },
                      { replace: true }
                    )
                  }
                  onClickAll={() =>
                    setSearchParams(
                      {
                        busca,
                        pagina: String(1),
                        status: "",
                        dataini: dataIni,
                        datafim: dataFim,
                      },
                      { replace: true }
                    )
                  }
                  onClickOpen={() =>
                    setSearchParams(
                      {
                        busca,
                        pagina: String(1),
                        status: "open",
                        dataini: dataIni,
                        datafim: dataFim,
                      },
                      { replace: true }
                    )
                  }
                  onClickBilled={() =>
                    setSearchParams(
                      {
                        busca,
                        pagina: String(1),
                        status: "pending",
                        dataini: dataIni,
                        datafim: dataFim,
                      },
                      { replace: true }
                    )
                  }
                  onClickCanceled={() =>
                    setSearchParams(
                      {
                        busca,
                        pagina: String(1),
                        status: "canceled",
                        dataini: dataIni,
                        datafim: dataFim,
                      },
                      { replace: true }
                    )
                  }
                  onClickPaid={() =>
                    setSearchParams(
                      {
                        busca,
                        pagina: String(1),
                        status: "paid",
                        dataini: dataIni,
                        datafim: dataFim,
                      },
                      { replace: true }
                    )
                  }
                  selectAll={status === ""}
                  selectOpen={status === "open"}
                  selectBilled={status === "pending"}
                  selectCanceled={status === "canceled"}
                  selectPaid={status === "paid"}
                  onClickButtonReport={() => {
                    handleModalReport();
                  }}
                  showButtonAtualiza={true}
                  onClickButtonAtualiza={() => {
                    handleConciliation();
                  }}
                />
              </TableCell>
            </TableRow>

            <TableRow sx={{ background: "#f5fbfd" }}>
              <TableCell>
                <Checkbox
                  color="secondary"
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
              <TableCell sx={{ color: "#7e7777b5" }}> Cliente</TableCell>
              <TableCell sx={{ color: "#7e7777b5" }}> Vencimento</TableCell>
              <TableCell sx={{ color: "#7e7777b5" }}> Valor</TableCell>
              <TableCell sx={{ color: "#7e7777b5" }}> Status </TableCell>
              <TableCell sx={{ color: "#7e7777b5", width: "10%" }}> </TableCell>
              <TableCell sx={{ color: "#7e7777b5", width: "5px" }}> </TableCell>
              <TableCell sx={{ color: "#7e7777b5", width: "5px" }}> </TableCell>
              <TableCell sx={{ color: "#7e7777b5", width: "5px" }}> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row2) => (
              <TableRow hover>
                <TableCell>
                  <Checkbox
                    color="secondary"
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell>
                <TableCell sx={{ fontWeight: "normal" }}>
                  {row2.client.name}
                  {row2.client?.status == "inactive" && " (Inativo)"}
                  {row2.client.name && (
                    <Typography
                      variant="subtitle2"
                      color={"#959595"}
                      fontWeight={"normal"}
                    >
                      {row2.client.document?.length > 11
                        ? row2.client.document.replace(
                            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                            "$1.$2.$3/$4-$5"
                          )
                        : row2.client.document.replace(
                            /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                            "$1.$2.$3-$4"
                          )}
                    </Typography>
                  )}
                </TableCell>

                <TableCell sx={{ textAlign: "start" }}>
                  <Typography
                    variant="subtitle2"
                    marginLeft={1}
                    sx={{ fontWeight: "normal" }}
                  >
                    {moment(row2.dueDate).format("L")}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: "start" }}>
                  <Typography
                    variant="subtitle2"
                    marginLeft={1}
                    sx={{ fontWeight: "normal" }}
                  >
                    {parseFloat(row2.value).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: "start" }}>
                  <IconButton
                    size="small"
                    sx={{
                      background: colorBackGround(row2.status),
                      borderRadius: "18px",
                      paddingX: "15px",
                      paddingY: "1px",
                      marginBottom: "5px",
                    }}
                  >
                    <Icon
                      sx={{
                        color: colorIcon(row2.status),
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      noise_control_off_sharp_icon
                    </Icon>
                    <Typography
                      variant="subtitle2"
                      marginLeft={1}
                      sx={{
                        color: colorText(row2.status),
                        fontWeight: "normal",
                      }}
                    >
                      {translate(row2.status)}
                    </Typography>
                  </IconButton>
                  <Typography variant="subtitle2" color={"green"}>
                    {row2.status == "paid" && row2.paymentDate != null
                      ? format(
                          isValid(row2.paymentDate)
                            ? new Date(row2.paymentDate + " 03:00:00")
                            : Date.now(),
                          "dd/MM/yyyy"
                        )
                      : ""}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: "end" }}>
                  {row2.status != "pending" ? (
                    row2.gateway === "PJBANK" ? (
                      <Box>
                        <Tooltip title="Segunda Via" placement="top-start">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setModalSend(true);
                              setLoadEmail(row2.client.email);
                              setLoadInvoice(row2.id);
                              setLoadURL(row2.url || "");
                            }}
                            sx={{ borderRadius: "8px" }}
                          >
                            <img src={pjbank} alt="cont" width={"75%"} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : row2.gateway === "BRADESCO" ? (
                      <Box>
                        <Tooltip title="Segunda Via" placement="top-start">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setModalSend(true);
                              setLoadEmail(row2.client.email);
                              setLoadInvoice(row2.id);
                              setLoadURL(row2.url || "");
                            }}
                            sx={{ borderRadius: "8px" }}
                          >
                            <img src={bradesco} alt="cont" width={"80%"} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : row2.gateway === "SICOOB" ? (
                      <Box>
                        <Tooltip title="Segunda Via" placement="top-start">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setModalSend(true);
                              setLoadEmail(row2.client.email);
                              setLoadInvoice(row2.id);
                              setLoadURL(row2.url || "");
                            }}
                            sx={{ borderRadius: "8px" }}
                          >
                            <img src={sicoob} alt="cont" width={"80%"} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : (
                      ""
                    )
                  ) : (
                    <IconButton
                      size="small"
                      onClick={() => {
                        // handleCriateBillet(row2.id)
                      }}
                      sx={{ borderRadius: "18px" }}
                    >
                      <Icon sx={{ color: "#0170B3", fontWeight: "normal" }}>
                        {" "}
                        account_balance_OutlinedIcon
                      </Icon>
                      <Typography
                        variant="subtitle2"
                        marginLeft={1}
                        sx={{ color: "#0170B3", fontWeight: "normal" }}
                      >
                        Gerar Boleto
                      </Typography>
                    </IconButton>
                  )}
                </TableCell>

                <TableCell sx={{ textAlign: "center" }}>
                  {row2.status != "pending" ? (
                    <Tooltip title="Extrato" placement="top-start">
                      <IconButton
                        size="small"
                        onClick={() =>
                          window.open(`${URL}/installment/summary/${row2.id}`)
                        }
                        sx={{ borderRadius: "18px" }}
                      >
                        <Icon sx={{ color: "#01b35a", fontWeight: "normal" }}>
                          {" "}
                          link_OutlinedIcon
                        </Icon>
                        {/* <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#01b35a", fontWeight: "normal" }}>
                                                    Extrato
                                                </Typography> */}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </TableCell>

                <TableCell sx={{ textAlign: "center" }}>
                  {row2.status != "pending" ? (
                    <Tooltip title="PDF Extrato" placement="top-start">
                      <IconButton
                        size="small"
                        onClick={() => handleExtractPDF(row2.id)}
                        sx={{ borderRadius: "18px" }}
                      >
                        <Icon sx={{ color: "#7924C7", fontWeight: "normal" }}>
                          {" "}
                          picture_as_pdf_outlined_Icon
                        </Icon>
                        {/* <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#7924c7", fontWeight: "normal" }}>
                                                    Extrato
                                                </Typography> */}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </TableCell>

                <TableCell sx={{ textAlign: "center" }}>
                  {row2.status == "open" || row2.status == "pending" ? (
                    <Tooltip title="Cancelar" placement="top-start">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setModalCancel(true),
                            setIdCancel(row2.id),
                            setnameclient(row2.client.name),
                            setvalueInvoice(row2.value),
                            setDueDateInvoice(row2.dueDate),
                            setReasonCancel("");
                        }}
                        sx={{ borderRadius: "18px" }}
                      >
                        <Icon sx={{ color: "#e63158", fontWeight: "normal" }}>
                          {" "}
                          cancel_Outlined_icon
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </TableCell>

                <TableCell sx={{ textAlign: "center" }}>
                  {row2.status == "open" ? (
                    <Tooltip title="Baixar" placement="top-start">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setModalPayment(true),
                            setIdCancel(row2.id),
                            setnameclient(row2.client.name),
                            setvalueInvoice(row2.value),
                            setDueDateInvoice(row2.dueDate),
                            setReasonCancel("");
                        }}
                        sx={{ borderRadius: "18px" }}
                      >
                        <Icon sx={{ color: "#e63158", fontWeight: "normal" }}>
                          {" "}
                          credit_score_Outlined_icon
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}
            {totalCount > 0 && (
              <TableRow>
                <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_LINHAS)}
                    onChange={(_, newPage2) =>
                      setSearchParams(
                        {
                          busca,
                          pagina: newPage2.toString(),
                          status: status,
                          dataini: dataIni,
                          datafim: dataFim,
                        },
                        { replace: true }
                      )
                    }
                  />
                </TableCell>
                <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                  <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                    Exibindo{" "}
                    {pagina * Environment.LIMITE_LINHAS -
                      (Environment.LIMITE_LINHAS - 1)}
                    {" - "}
                    {Environment.LIMITE_LINHAS * (pagina - 1) +
                      Environment.LIMITE_LINHAS * pagina <
                    totalCount
                      ? Environment.LIMITE_LINHAS * pagina
                      : totalCount}{" "}
                    de
                    <Typography
                      variant="overline"
                      sx={{
                        fontWeight: "bold",
                        color: "#0170B3",
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      {totalCount}{" "}
                    </Typography>
                    registro(s)
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      {/* } */}
    </LayoutBaseDePagina>
  );
};
