import { AuthGuard } from "../../../shared/guards/AuthGuard";
import { Sidebar } from "../../../shared/components";
import { BudgetPresentation } from "./components/BudgetPresentation";

export const BudgetPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <BudgetPresentation/>
            </Sidebar>
        </AuthGuard>
    )
};