import { Box, CircularProgress, Typography } from '@mui/material';
import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import { StyledComponentPropsType } from '../../../../shared/types';
import { SinisterDocumentsService, TSinisterDocumentsListaAndTotal } from '../../../../shared/services/api/sinister/SinisterDocumentsService';
import { GetValuesDocumentTypesResponseType, SinisterDocumentType } from '../../../../shared/services/api/sinister/types';
import {
  Divider, GreyedTypography,
  StyledEmptyListBox,
  StyledFileListContainer,
  StyledFileListSpinnerBox
} from './SinisterValuesFilesList.styled';
import { SinisterValuesFileItem } from './SinisterValuesFileItem';
import {useToast} from '../../../../shared/hooks';
import { SinisterValuesDocumentType } from '../../../../shared/services/api/sinister/types/sinister-values-document.type';


type SinisterValuesFilesListProps = StyledComponentPropsType & {
  sinisterId: string,
  valuesId:string
}
export const SinisterValuesFilesList = ({ sinisterId, valuesId }: SinisterValuesFilesListProps) => {
  const [documentsList, setDocumentsList] = useState<SinisterValuesDocumentType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const handler = useToast()

  useEffect(() => {
    SinisterDocumentsService.getSinisterValuesDocumentTypes(sinisterId,valuesId).then((value) => {
      // console.log('SinisterValuesFileList....27',value)
      // console.log('SinisterValuesFileList....27aaa',value.data)
      // console.log('SinisterValuesFileList....27bbbbbb',value.data.result)

      setDocumentsList(value);
      setIsLoading(false);
    }).catch(_ => {
      setIsLoading(false);
    })
  }, [])

  const isEmptyList = useMemo(() => {
    // console.log('37....', documentsList);
    return documentsList?.length === 0
  }, [documentsList, isLoading])

  return (
          <StyledFileListContainer>
            {isLoading && (
              <StyledFileListSpinnerBox>
                <CircularProgress variant='indeterminate'/>
              </StyledFileListSpinnerBox>
            )}

            { !isLoading && !isEmptyList && (
              
              documentsList?.map((item, index) =>
                  (
                    
                    <Box key={item.id} style={{marginBottom: '16px'}}>
                     <SinisterValuesFileItem item={item}/>
                    { index < documentsList.length - 1 ? <Divider/> : null} 
                  </Box>
                    // <GreyedTypography> {item.filename} </GreyedTypography>
                 
                    // <Box key={item.id} style={{marginBottom: '16px'}}>
                    //   <SinisterValuesFileItem item={item}/>
                    //   { index < documentsList.length - 1 ? <Divider/> : null}
                    // </Box>
                  )

            ))}
            { !isLoading && isEmptyList && (
                  <StyledEmptyListBox>
                    <GreyedTypography>Não há arquivos para serem exibidos</GreyedTypography>
                  </StyledEmptyListBox>
              )}
         </StyledFileListContainer>
  )
}