import { AuthGuard } from "../../shared/guards/AuthGuard";
import { Sidebar } from "../../shared/components";
import { FinancialSetupPresentation } from "./components/FinancialSetupPresentation";

export const FinancialSetupPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <FinancialSetupPresentation />
            </Sidebar>
        </AuthGuard>
    );
};
