import {createTheme} from '@mui/material';
import { cyan } from '@mui/material/colors';
export const DarkTheme = createTheme({
    palette:{
        mode:'dark',
        primary:{
            main:'#E9B801',
            dark:'#E9B801',
            light:'#E9B801',
            contrastText:'#fff',
        },
        secondary:{
            main:cyan[700],
            dark:cyan[800],
            light:cyan[500],
            contrastText:'#fff',
        },
        background:{
            default:'#202124',
            paper:'#303134',
        } 
    },
    typography:{
        allVariants:{
            color: 'white',
        },
    }
});