import { AuthGuard } from '../../shared/guards/AuthGuard';
import { Sidebar } from '../../shared/components';
import { TransferClientsPresentation } from './components/TransferClientsPresentation';

export const TransferClientsPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <TransferClientsPresentation />
            </Sidebar>
        </AuthGuard>
    )
};