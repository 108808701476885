import { formatScaleValueBR } from "../../utils/formatScaleValue";

const itens = [
  "Mes",  //0
  "Garantias",
  "qtdInstallmentProposal",
  "Canceladas",
  "qtdInstallmentCanceled", 
  "Não faturados", //5
  "qtdInstallmentUnbilled",
  "Faturados",
  "qtdInstallmentBilled",
  "Abertos", 
  "qtdInstallmentOpen",//10
  "Vencidas",
  "qtdInstallmentOverdue",
  "Pagas", //13
  "qtdInstallmentPaid",
  "Descontos", //15
  "Desconto_Faturados", //16
  "Desconto_Abertos", //17
  "Desconto_Vencidas"//18
];

export const translateMonths = (month: string): string => {
  const months: any = {
    January: "Janeiro",
    February: "Fevereiro",
    March: "Março",
    April: "Abril",
    May: "Maio",
    June: "Junho",
    July: "Julho",
    August: "Agosto",
    September: "Setembro",
    October: "Outubro",
    November: "Novembro",
    December: "Dezembro",
  };

  return months[month];
};

export type SummaryFinancialProps = {
  prop0?: SummaryFinancialValues;
  prop1?: SummaryFinancialValues;
  prop2?: SummaryFinancialValues;
  prop3?: SummaryFinancialValues;
  prop4?: SummaryFinancialValues;
  prop5?: SummaryFinancialValues;
  prop6?: SummaryFinancialValues;
  prop7?: SummaryFinancialValues;
};

export type SummaryFinancialValues = {
  name: string;
  value: string;
  qtd?: number;
};

export const formatListFinancial = (list: any): SummaryFinancialProps[] => {

  return list.map((e: any) => {
    const obj: SummaryFinancialProps = {
      prop0: {
        name: itens[0], //mes
        value: translateMonths(e[itens[0]]),
      },
      prop1: {
        name: itens[1], //garantias
        value: formatScaleValueBR(e[itens[1]]),
        qtd: Number(e[itens[2]]),
      },
      prop2: {
        name: itens[3], //canceladas
        value: formatScaleValueBR(e[itens[3]]),
        qtd: Number(e[itens[4]]),
      },
      prop3: {
        name: itens[5], //nao faturados
        value: formatScaleValueBR(e[itens[5]]),
        qtd: Number(e[itens[6]]),
      },
      prop4: {
        name: itens[7], //faturadas
        value: formatScaleValueBR((Number(e[itens[7]])- Number(e[itens[16]])).toFixed(2)),
        qtd: Number(e[itens[8]]),
      },
      prop5: {
        name: itens[9],
        value: formatScaleValueBR((Number(e[itens[9]])- Number(e[itens[17]])).toFixed(2)),
        qtd: Number(e[itens[10]]),
      },
      prop6: {
        name: itens[11],
        value: formatScaleValueBR((Number(e[itens[11]])- Number(e[itens[18]])).toFixed(2)),
        qtd: Number(e[itens[12]]),
      },
      prop7: {
        name: itens[13],
        value: formatScaleValueBR((Number(e[itens[13]])- Number(e[itens[15]])).toFixed(2)),
        qtd: Number(e[itens[14]]),
      },
    };

    return obj;
  });
};

export const formatFinancialTotalValues = (list: any): SummaryFinancialProps => {
  console.log('107....',list)
  
  const obj: SummaryFinancialProps = {
    prop1: {
      name: itens[1],
      value: formatScaleValueBR(list[itens[1]]),
    },
    prop2: {
      name: itens[3],
      value: formatScaleValueBR(list[itens[3]]),
    },
    prop3: {
      name: itens[5],
      value: formatScaleValueBR(list[itens[5]]),
    },
    prop4: {
      name: itens[7], //faturados
      value: formatScaleValueBR((Number(list[itens[7]])- Number(list[itens[16]])).toFixed(2) ),
    },
    prop5: {
      name: itens[9],
      value: formatScaleValueBR((Number(list[itens[9]])- Number(list[itens[17]])).toFixed(2)),
    },
    prop6: {
      name: itens[11],
      value: formatScaleValueBR((Number(list[itens[11]])- Number(list[itens[18]])).toFixed(2)),
    },
    prop7: {
      name: itens[13],
      value: formatScaleValueBR((Number(list[itens[13]])- Number(list[itens[15]])).toFixed(2)  ),
    },
  };

  return obj;
};
