import React, { createContext, useContext, useState } from 'react';
import { PermissionServices } from '../services/api/permissions/PermissionService';
import { useMenuPermissions } from './useMenuPermissions';

interface IPermissionData {
    dashboard: boolean;
    clients: boolean;
    users: boolean;
    analysis: boolean;
    warranties: boolean;
    sinister: boolean;
    sinisterValues: boolean;
    reports: boolean;
    financial: boolean;
    generalConditions: boolean;
    charts: boolean;
    permissions: boolean;
    menu: boolean;
    dashboardOptions: IOptionsData;
    clientOptions: IOptionsData;
    usersOptions: IOptionsData;
    analysisOptions: IOptionsData;
    warrantiesOptions: IOptionsData;
    sinisterOptions: IOptionsData;
    sinisterValuesOptions: IOptionsData;
    reportsOptions: IOptionsData;
    financialOptions: IOptionsData;
    generalConditionsOptions: IOptionsData;
    chartsOptions: IOptionsData;
    permissionsOptions: IOptionsData;
    handleDashboard: () => void;
    handleClients: () => void;
    handleUsers: () => void;
    handleAnalysis: () => void;
    handleSinister: () => void;
    handleSinisterValues: () => void;
    handleWarranties: () => void;
    handleReports: () => void;
    handleFinancial: () => void;
    handleGeneralConditions: () => void;
    handleCharts: () => void;
    handlePermissions: () => void;
    handleMenu: () => void;
    handleOptions: (type: string, selected: any) => void;
    handlePermissionsData: () => any;
    handleInitialize: (selected: IOptionsData, type: string) => void;
}

export interface IOptionsData {
    view?: boolean;
    add?: boolean;
    update?: boolean;
    filter?: boolean;
    conclude?: boolean;
    cancel?: boolean;
    approve?: boolean;
    reject?: boolean;
    contest?: boolean;

    type?: string;
    serasa?: boolean;
    clickSign?: boolean;
    tipping?: boolean;
    upProposalPeriod?: boolean;
    upProposalValues?: boolean;
    upProposalAddress?: boolean;
    upProposalLocator?:boolean;
    upProposalConcludeCancel?: boolean;
    upProposalCancel?: boolean;
    reportFinancial?: boolean;
    reportProposals?: boolean;
    reportAnalysis?: boolean;
    reportSinister?: boolean;
    reportAdmin?: boolean;
    reportClient?: boolean;
    reportLogAndAudit?: boolean;
    blockCancel?: boolean;
    walletTransfer?: boolean;
    contracts?: boolean;

}

interface IPermissionsProviderProps {
    children: React.ReactNode;
}

const PermissionHook = createContext({} as IPermissionData);

export const PermissionsProvider: React.FC<IPermissionsProviderProps> = ({ children }) => {


    const [menu, setMenu] = useState<boolean>(true);
    const [dashboard, setDashboard] = useState<boolean>(false);
    const [clients, setClients] = useState<boolean>(false);
    const [users, setUsers] = useState<boolean>(false);
    const [analysis, setAnalysis] = useState<boolean>(false);
    const [warranties, setWarranties] = useState<boolean>(false);
    const [sinister, setSinister] = useState<boolean>(false);
    const [sinisterValues, setSinisterValues] = useState<boolean>(false);
    const [reports, setReports] = useState<boolean>(false);
    const [financial, setFinancial] = useState<boolean>(false);
    const [generalConditions, setGeneralConditions] = useState<boolean>(false);
    const [charts, setCharts] = useState<boolean>(false);
    const [permissions, setPermissions] = useState<boolean>(false);
    const [dashboardOptions, setDashboardOptions] = useState<IOptionsData>({} as IOptionsData);
    const [clientOptions, setClientOptions] = useState<IOptionsData>({} as IOptionsData);
    const [usersOptions, setUserOptions] = useState<IOptionsData>({} as IOptionsData);
    const [analysisOptions, setAnalysisOptions] = useState<IOptionsData>({} as IOptionsData);
    const [warrantiesOptions, setWarrantiesOptions] = useState<IOptionsData>({} as IOptionsData);
    const [sinisterOptions, setSinisterOptions] = useState<IOptionsData>({} as IOptionsData);
    const [sinisterValuesOptions, setSinisterValuesOptions] = useState<IOptionsData>({} as IOptionsData);
    const [reportsOptions, setReportsOptions] = useState<IOptionsData>({} as IOptionsData);
    const [financialOptions, setFinancialOptions] = useState<IOptionsData>({} as IOptionsData);
    const [generalConditionsOptions, setGeneralConditionsOptions] = useState<IOptionsData>({} as IOptionsData);
    const [chartsOptions, setChartsOptions] = useState<IOptionsData>({} as IOptionsData);
    const [permissionsOptions, setPermissionsOptions] = useState<IOptionsData>({} as IOptionsData);

    const handleInitialize = (selected: IOptionsData, type: string) => {
        switch (type) {
            case 'dashboard':
                setDashboardOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    blockCancel: false,
                    type: 'dashboard',
                });
                break;
            case 'clients':
                setClientOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    blockCancel: false,
                    walletTransfer: selected.walletTransfer,
                    contracts: selected.contracts,
                    type: 'clients',
                });
                break;
            case 'users':
                setUserOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    blockCancel: false,
                    type: 'users'
                });
                break;
            case 'analysis':
                setAnalysisOptions({
                    add: selected.add,
                    conclude: selected.conclude,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    serasa: selected.serasa,
                    clickSign: selected.clickSign,
                    tipping: selected.tipping,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    blockCancel: false,
                    type: 'analysis'
                });
                break;
            case 'warranties':
                setWarrantiesOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    cancel: selected.cancel,
                    upProposalPeriod: selected.upProposalPeriod,
                    upProposalValues: selected.upProposalValues,
                    upProposalAddress: selected.upProposalAddress,
                    upProposalLocator: selected.upProposalLocator,
                    upProposalConcludeCancel: selected.upProposalConcludeCancel,
                    upProposalCancel: selected.upProposalCancel,
                    blockCancel: false,
                    type: 'warranties'
                });
                break;
            case 'sinister':
                setSinisterOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    blockCancel: false,
                    type: 'sinister'
                });
                break;
            case 'sinisterValues':
                setSinisterValuesOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    blockCancel: false,
                    type: 'sinisterValues'
                });
                break;
            case 'reports':
                setReportsOptions({
                    add: false,//selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    reportFinancial: selected.reportFinancial,
                    reportProposals: selected.reportProposals,
                    reportAnalysis: selected.reportAnalysis,
                    reportSinister: selected.reportSinister,
                    reportAdmin: selected.reportAdmin,
                    reportClient: selected.reportClient,
                    reportLogAndAudit: selected.reportLogAndAudit,
                    blockCancel: false,
                    // upProposalPeriod: false,
                    // upProposalValues: false,
                    // upProposalAddress: false,
                    // upProposalConcludeCancel: false,
                    type: 'reports'
                });
                break;
            case 'financial':
                setFinancialOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    blockCancel: selected.blockCancel,
                    type: 'financial'
                });
                break;
            case 'generalConditions':
                setGeneralConditionsOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    blockCancel: false,
                    type: 'generalConditions'
                });
                break;
            case 'charts':
                setChartsOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    blockCancel: false,
                    type: 'charts'
                });
                break;
            case 'permissions':
                setPermissionsOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    type: 'permissions'
                });
                break;
            default:
                throw new Error('Tipo não existe');
        }
    }

    const handleMenu = () => {
        setDashboard(false);
        setClients(false);
        setUsers(false);
        setAnalysis(false);
        setWarranties(false);
        setSinister(false);
        setSinisterValues(false);
        setReports(false);
        setFinancial(false);
        setGeneralConditions(false);
        setCharts(false);
        setPermissions(false);
        setMenu(true);
    }

    const handleDashboard = () => {
        setDashboard(true);
        setClients(false);
        setUsers(false);
        setAnalysis(false);
        setWarranties(false);
        setSinister(false);
        setSinisterValues(false);
        setReports(false);
        setFinancial(false);
        setGeneralConditions(false);
        setCharts(false);
        setPermissions(false);
        setMenu(false);
    }

    const handleClients = () => {
        setDashboard(false);
        setClients(true);
        setUsers(false);
        setAnalysis(false);
        setWarranties(false);
        setSinister(false);
        setSinisterValues(false);
        setReports(false);
        setFinancial(false);
        setGeneralConditions(false);
        setCharts(false);
        setPermissions(false);
        setMenu(false);
    }

    const handleUsers = () => {
        setDashboard(false);
        setClients(false);
        setUsers(true);
        setAnalysis(false);
        setWarranties(false);
        setSinister(false);
        setSinisterValues(false);
        setReports(false);
        setFinancial(false);
        setGeneralConditions(false);
        setCharts(false);
        setPermissions(false);
        setMenu(false);
    }

    const handleAnalysis = () => {
        setDashboard(false);
        setClients(false);
        setUsers(false);
        setAnalysis(true);
        setWarranties(false);
        setSinister(false);
        setSinisterValues(false);
        setReports(false);
        setFinancial(false);
        setGeneralConditions(false);
        setCharts(false);
        setPermissions(false);
        setMenu(false);
    }

    const handleWarranties = () => {
        setDashboard(false);
        setClients(false);
        setUsers(false);
        setAnalysis(false);
        setWarranties(true);
        setSinister(false);
        setSinisterValues(false);
        setReports(false);
        setFinancial(false);
        setGeneralConditions(false);
        setCharts(false);
        setPermissions(false);
        setMenu(false);
    }

    const handleSinister = () => {
        setDashboard(false);
        setClients(false);
        setUsers(false);
        setAnalysis(false);
        setWarranties(false);
        setSinister(true);
        setSinisterValues(false);
        setReports(false);
        setFinancial(false);
        setGeneralConditions(false);
        setCharts(false);
        setPermissions(false);
        setMenu(false);
    }

    const handleSinisterValues = () => {
        setDashboard(false);
        setClients(false);
        setUsers(false);
        setAnalysis(false);
        setWarranties(false);
        setSinister(false);
        setSinisterValues(true);
        setReports(false);
        setFinancial(false);
        setGeneralConditions(false);
        setCharts(false);
        setPermissions(false);
        setMenu(false);
    }

    const handleReports = () => {
        setDashboard(false);
        setClients(false);
        setUsers(false);
        setAnalysis(false);
        setWarranties(false);
        setSinister(false);
        setSinisterValues(false);
        setReports(true);
        setFinancial(false);
        setGeneralConditions(false);
        setCharts(false);
        setPermissions(false);
        setMenu(false);
    }

    const handleFinancial = () => {
        setDashboard(false);
        setClients(false);
        setUsers(false);
        setAnalysis(false);
        setWarranties(false);
        setSinister(false);
        setSinisterValues(false);
        setReports(false);
        setFinancial(true);
        setGeneralConditions(false);
        setCharts(false);
        setPermissions(false);
        setMenu(false);
    }

    const handleGeneralConditions = () => {
        setDashboard(false);
        setClients(false);
        setUsers(false);
        setAnalysis(false);
        setWarranties(false);
        setSinister(false);
        setSinisterValues(false);
        setReports(false);
        setFinancial(false);
        setGeneralConditions(true);
        setCharts(false);
        setPermissions(false);
        setMenu(false);
    }

    const handleCharts = () => {
        setDashboard(false);
        setClients(false);
        setUsers(false);
        setAnalysis(false);
        setWarranties(false);
        setSinister(false);
        setSinisterValues(false);
        setReports(false);
        setFinancial(false);
        setGeneralConditions(false);
        setCharts(true);
        setPermissions(false);
        setMenu(false);
    }

    const handlePermissions = () => {
        setDashboard(false);
        setClients(false);
        setUsers(false);
        setAnalysis(false);
        setWarranties(false);
        setSinister(false);
        setSinisterValues(false);
        setReports(false);
        setFinancial(false);
        setGeneralConditions(false);
        setCharts(false);
        setPermissions(true);
        setMenu(false);
    }

    const optionsEmpty = {
        add: false,
        conclude: false,
        filter: false,
        update: false,
        view: false,
        approve: false,
        reject: false,
        contest: false,
        serasa: false,
        clickSing: false,
        tipping: false,
        upProposalPeriod: false,
        upProposalValues: false,
        upProposalAddress: false,
        upProposalLocator: false,
        upProposalConcludeCancel: false,
        BlockCancel:false,
        WalletTransfer:false,
        contracts:false,
        
    }

    const handleOptions = (type: string, selected: any) => {

        const newSelected = (selected == undefined) ? { ...optionsEmpty, type: type } : { ...selected, type: type }
        switch (type) {
            case 'dashboard':
                setDashboardOptions(newSelected);
                //     {
                //     add: selected.add,
                //     conclude: false,
                //     filter: selected.filter,
                //     update: selected.update,
                //     view: selected.view,
                //     type: 'dashboard',
                // });
                break;
            case 'clients':
                setClientOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    walletTransfer: selected.walletTransfer,
                    contracts: selected.contracts,
                    type: 'clients',
                });
                break;
            case 'users':
                setUserOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    type: 'users'
                });
                break;
            case 'analysis':
                setAnalysisOptions({
                    add: selected.add,
                    conclude: selected.conclude,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    serasa: selected.serasa,
                    clickSign: selected.clickSign,
                    tipping: selected.tipping,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    type: 'analysis'
                });
                break;
            case 'warranties':
                setWarrantiesOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    cancel: selected.cancel,
                    upProposalPeriod: selected.upProposalPeriod,
                    upProposalValues: selected.upProposalValues,
                    upProposalAddress: selected.upProposalAddress,
                    upProposalLocator: selected.upProposalLocator,
                    upProposalConcludeCancel: selected.upProposalConcludeCancel,
                    upProposalCancel: selected.upProposalCancel,
                    type: 'warranties'
                });
                break;
            case 'sinister':
                setSinisterOptions(
                    newSelected
                    //     {
                    //     add: selected.add,
                    //     conclude: false,
                    //     filter: selected.filter,
                    //     update: selected.update,
                    //     view: selected.view,
                    //     type: 'sinister'
                    // }
                );
                break;
            case 'sinisterValues':

                setSinisterValuesOptions(newSelected);
                //     selected== undefined? { ...optionsEmpty,

                //         type: 'sinisterValues'
                //     } : 
                //     {
                //         add: selected.add,
                //         conclude: false,
                //         filter: selected.filter,
                //         update: selected.update,
                //         view: selected.view,
                //     type: 'sinisterValues'

                // }
                // );

                //  setSinisterValuesOptions({
                //     add: selected.add :false,
                //     conclude: false,
                //     filter:  (select)?selected.filter:false,
                //     update:  (select)?selected.update:false,
                //     view:  (select)?selected.view:false,
                //     type: 'sinisterValues'
                // });
                break;
            case 'reports':
                setReportsOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    reportFinancial: selected.reportFinancial,
                    reportProposals: selected.reportProposals,
                    reportAnalysis: selected.reportAnalysis,
                    reportSinister: selected.reportSinister,
                    reportAdmin: selected.reportAdmin,
                    reportClient: selected.reportClient,
                    reportLogAndAudit: selected.reportLogAndAudit,
                    // upProposalPeriod: false,
                    // upProposalValues: false,
                    // upProposalAddress: false,
                    // upProposalConcludeCancel: false,
                    type: 'reports'
                });
                break;
            case 'financial':
                setFinancialOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    blockCancel: selected.blockCancel,
                    type: 'financial'
                });
                break;
            case 'generalConditions':
                setGeneralConditionsOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalConcludeCancel: false,
                    type: 'generalConditions'
                });
                break;
            case 'charts':
                setChartsOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    type: 'charts'
                });
                break;
            case 'permissions':
                setPermissionsOptions({
                    add: selected.add,
                    conclude: false,
                    filter: selected.filter,
                    update: selected.update,
                    view: selected.view,
                    upProposalPeriod: false,
                    upProposalValues: false,
                    upProposalAddress: false,
                    upProposalLocator: false,
                    upProposalConcludeCancel: false,
                    type: 'permissions'
                });
                break;
            default:
                throw new Error('Tipo não existe');
        }
    }

    const handlePermissionsData = () => {
        const data = {
            clients_options: clientOptions,
            users_options: usersOptions,
            analysis_options: analysisOptions,
            warranties_options: warrantiesOptions,
            sinister_options: sinisterOptions,
            sinister_values_options: sinisterValuesOptions,
            reports_options: reportsOptions,
            financial_options: financialOptions,
            general_conditions_options: generalConditionsOptions,
            charts_options: chartsOptions,
            permissions_options: permissionsOptions,
            dashboard_options: dashboardOptions,
        }
        return data;
    }


    return (
        <PermissionHook.Provider
            value={{
                menu,
                dashboard,
                clients,
                users,
                analysis,
                warranties,
                sinister,
                sinisterValues,
                reports,
                financial,
                generalConditions,
                charts,
                permissions,
                dashboardOptions,
                clientOptions,
                analysisOptions,
                chartsOptions,
                financialOptions,
                generalConditionsOptions,
                permissionsOptions,
                reportsOptions,
                sinisterOptions,
                sinisterValuesOptions,
                usersOptions,
                warrantiesOptions,
                handleMenu,
                handleDashboard,
                handleAnalysis,
                handleCharts,
                handleClients,
                handleFinancial,
                handleGeneralConditions,
                handlePermissions,
                handleReports,
                handleSinister,
                handleSinisterValues,
                handleUsers,
                handleWarranties,
                handleOptions,
                handlePermissionsData,
                handleInitialize
            }}
        >
            {children}
        </PermissionHook.Provider>
    )
}

export const usePermissions = () => useContext(PermissionHook);



