import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { SxProps, Theme } from '@mui/material';


interface IDateCalendarProps {
    title?: string;
    views?: any[];
    openTo?: any;
    value?: any;
    format?: any;
    disableFuture?: boolean | undefined;
    loading?: boolean | undefined;
    disabled?: boolean | undefined;
    sx?: SxProps<Theme> | undefined;
    onChange?: (value: Date | null) => void;
}

const todayDefaultDate = dayjs(moment().toDate());

export const DateCalendarView: React.FC<IDateCalendarProps> = ({
    value = null,
    title = '' as string,
    openTo = "month" as string,
    views = ['month', 'year'] as string[],
    onChange,
    disableFuture = true,
    loading = false,
    disabled = false,
    format = 'MM/YYYY',
    sx = {},
}) => {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label={title}
                defaultValue={todayDefaultDate}
                minDate={dayjs(moment('1990-01-01').toDate())}
                maxDate={todayDefaultDate}
                value={value}
                views={views}
                openTo={openTo}
                monthsPerRow={4}
                yearsPerRow={4}
                onChange={(obj: any) => {
                    onChange?.(obj?.$d ? dayjs(obj.$d).toDate() : null);
                }}
                disableFuture={disableFuture}
                format={format}
                loading={loading}
                disabled={disabled}
                sx={{
                    ".MuiOutlinedInput-root": {
                        height: "40px",
                        textAlignLast: "center"
                    },
                    ".MuiInputLabel-formControl": {
                        marginTop: "-7px"
                    },
                    ...sx
                }}
            />
        </LocalizationProvider>
    );
}