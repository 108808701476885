import { Box, Button, ButtonGroup, Icon, Paper, Table, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import React from 'react';
import { usePermissions } from '../../hooks/usePermissions';

interface ToolbarPermissionsProps{
    onClickGroup: () => void;
}

const ToolbarDetailPermission: React.FC<ToolbarPermissionsProps> = ({onClickGroup}) => {
    const {
        menu,
        handleMenu,
        dashboard, 
        handleDashboard,
        clients,
        handleClients,
        users,
        handleUsers,
        analysis,
        handleAnalysis,
        warranties,
        handleWarranties,
        sinister,
        handleSinister,
        sinisterValues,
        handleSinisterValues,
        reports,
        handleReports,
        financial,
        handleFinancial,
        generalConditions,
        handleGeneralConditions,
        charts,
        handleCharts,
        permissions,
        handlePermissions, 
    } = usePermissions();
    return (
            <ButtonGroup aria-label="outlined primary button group" sx={{justifyContent: 'space-between'}} fullWidth={true}>
                <Button
                    color="secondary"
                    disableElevation
                    variant={menu ? "contained":"outlined"}
                    onClick={() => {
                        handleMenu();
                        // onClickGroup();
                    }}
                    startIcon={<Icon>menu</Icon>}
                >
                    Menu
                </Button>
                <Button
                    color="secondary"
                    disableElevation
                    variant={dashboard ? "contained":"outlined"}
                    onClick={() => {
                        handleDashboard();
                        // onClickGroup();
                    }}
                    startIcon={<Icon>dashboard_outlined</Icon>}
                >
                    Dashboard
                </Button>

                <Button
                    color="secondary"
                    disableElevation
                    variant={clients ? "contained":"outlined"}
                    onClick={() => {
                        handleClients();
                        // onClickGroup();
                    }}
                    startIcon={<Icon>people_outline</Icon>}
                >
                    Clientes
                </Button>
                <Button
                    color="secondary"
                    disableElevation
                    variant={users ? "contained":"outlined"}
                    onClick={handleUsers}
                    startIcon={<Icon>person_outlinedicon</Icon>}
                >
                    Usuários
                </Button>

                <Button
                    color="secondary"
                    disableElevation
                    variant={analysis ? "contained":"outlined"}
                    onClick={handleAnalysis}
                    startIcon={<Icon>insert_chart_outlinedicon</Icon>}
                >
                    Análises
                </Button>
                <Button
                    color="secondary"
                    disableElevation
                    variant={warranties ? "contained":"outlined"}
                    onClick={handleWarranties}
                    startIcon={<Icon>security_outline</Icon>}
                >
                    Garantias
                </Button>

                <Button
                    color="secondary"
                    disableElevation
                    variant={sinister ? "contained":"outlined"}
                    onClick={handleSinister}
                    startIcon={<Icon>notifications_noneoutlinedicon</Icon>}
                >
                    Sinistro
                </Button>
                <Button
                    color="secondary"
                    disableElevation
                    variant={sinisterValues ? "contained":"outlined"}
                    onClick={handleSinisterValues}
                    startIcon={<Icon>notifications_noneoutlinedicon</Icon>}
                >
                    Sinistro Valores
                </Button>
                <Button
                    color="secondary"
                    disableElevation
                    variant={reports ? "contained":"outlined"}
                    onClick={handleReports}
                    startIcon={<Icon>assignment_outlinedicon</Icon>}
                >
                    Relatórios
                </Button>

                <Button
                    color="secondary"
                    disableElevation
                    variant={financial ? "contained":"outlined"}
                    onClick={handleFinancial}
                    startIcon={<Icon>monetization_onicon</Icon>}
                >
                    Financeiro
                </Button>
                <Button
                    color="secondary"
                    disableElevation
                    variant={generalConditions ? "contained":"outlined"}
                    onClick={handleGeneralConditions}
                    startIcon={<Icon>info_outlinedicon</Icon>}
                >
                    Documentos
                </Button>

                <Button
                    color="secondary"
                    disableElevation
                    variant={charts ? "contained":"outlined"}
                    onClick={handleCharts}
                    startIcon={<Icon>equalizer_outlinedicon</Icon>}
                >
                    Gráficos
                </Button>
                <Button
                    color="secondary"
                    disableElevation
                    variant={permissions ? "contained":"outlined"}
                    onClick={handlePermissions}
                    startIcon={<Icon>admin_panel_settings_outlined</Icon>}
                >
                    Permissões
                    </Button>
            </ButtonGroup>
    )
}

export {ToolbarDetailPermission};