import { useAuthContext } from '../../context';
import { useEffect } from 'react';
import { useProfile } from '../../context/ProfileContext';
import { Navigate } from 'react-router-dom';


interface IAuthGuardProps {
    children: React.ReactNode;
}

export const AuthGuard = ({ children }: IAuthGuardProps): JSX.Element | null => {
    const { handleRefreshToken, accessToken, isAuthenticated } = useAuthContext();
    const { isPermissionInitialized } = useProfile();

    useEffect(() => {

        if (!accessToken) {
            handleRefreshToken();
        }

    }, [accessToken]);

    if (!isPermissionInitialized) return null;

    if (!isAuthenticated) {
        return (
            <Navigate to='/login'></Navigate>
        );
    }

    return (
        <>
            {children}
        </>
    )
}