import {AuthGuard} from '../../shared/guards/AuthGuard';
import {AnalysisListPresentation} from './components';
import {Sidebar} from '../../shared/components';

export const AnalysisListPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <AnalysisListPresentation/>
            </Sidebar>
        </AuthGuard>
    )
};