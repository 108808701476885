import {AuthGuard} from '../../shared/guards/AuthGuard';
import {DetalheUsuariosPresentation} from './components/DetalheUsuariosPresentation';
import {Sidebar} from '../../shared/components';

export const DetalheUsuariosPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <DetalheUsuariosPresentation/>
            </Sidebar>
        </AuthGuard>
    )
};