import {
	Box,
	Button,
	Checkbox,
	Grid,
	Icon,
	IconButton,
	LinearProgress,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import moment from "moment";
import "moment/locale/pt-br";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { ModalContext, ToastContent, ToolbarFinancial } from "../../../shared/components";
import { ToolbarGridFinancial } from "../../../shared/components/toolbarGridFinancial/ToolbarGridFinancial";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { Environment } from "../../../shared/environment";
import { useVForm, VForm } from "../../../shared/forms";
import { checkByProfileGuard, ProfileGuard } from "../../../shared/guards/ProfileGuard";
import { useDebounce, useToast } from "../../../shared/hooks";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { FinancialService, IGenerateInvoice, IInstallmentDocument, IInstallmentInvoiceList } from "../../../shared/services/api/financial/FinancialService";

interface IFormInvoice {
	dueDate: string; //"2023-01-11",
	gateway: string; //"EMPTY",
	status: string; //"pending",
	clientId: string; //"55fbd98e-eb54-45b9-9ec4-2369e3ea85d5",
	userId: string; // "01b47ca1-70c1-45ac-8abf-d592e34738f9",
	value: string; //"10.00"
}

interface IArray {
	id: string;
}

function colorLine(line: number) {
	if (line % 2 == 0) {
		return "#e5e8eb";
	}
	return "#ffffff";
}

export const FinancialListPresentation = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { debounce } = useDebounce();
	const navigate = useNavigate();
	const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } = useVForm();
	const [rows, setRows] = useState<IInstallmentInvoiceList[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [layoutFinan, setLayoutFinan] = useState("invoicing");
	const [modalDetail, setModalDetail] = useState(false);
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const [rowsDetail, setRowsDetail] = useState<IInstallmentDocument[]>([]);
	const toastHandler = useToast();
	const [refreshList, setRefreshList] = useState(false);
	const [modalDueDate, setModalDueDate] = useState(false);
	const [clientId, setClientId] = useState("");
	const [valueInvoice, setValueInvoice] = useState("");
	const [countInstallment, setCountInstallment] = useState("");
	const [dueDateInvoice, setDueDateInvoice] = useState("");
	const [referenceInvoice, setReferenceInvoice] = useState("");
	const [nameInvoice, setNameInvoice] = useState("");
	const hasViewPermission = useMemo(() => {
		return checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.View);
	}, []);

	const selects = [""];

	const busca = useMemo(() => {
		return searchParams.get("busca") || "";
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get("pagina") || "1");
	}, [searchParams]);

	const status = useMemo(() => {
		return searchParams.get("status") || "pending";
	}, [searchParams]);

	const dataIni = useMemo(() => {
		return searchParams.get("dataini") || moment("01/01/2019").format("YYYY-MM-DD");
	}, [searchParams]);

	const dataFim = useMemo(() => {
		return searchParams.get("datafim") || moment().endOf("month").format("YYYY-MM-DD");
	}, [searchParams]);

	const resultado = rowsDetail.reduce(function (a: number, b: any) {
		return a + (b.Installment_type_amount != "discount" ? parseFloat(b.Installment_amount) : 0);
	}, 0);

	const discount = rowsDetail.reduce(function (a: number, b: any) {
		return a + (b.Installment_type_amount == "discount" ? parseFloat(b.Installment_amount) : 0);
	}, 0);

	const saldo = resultado - discount;

	function handleDueDate(due_date: string, day: number) {
		const currentDate = new Date();
		const currentDay = currentDate.getDate();

		const [year, month, dayDate] = due_date.substring(0, 10).split("-").map(Number);

		if (day < currentDay) {
			// Add one month to the due date
			const newMonth = month + 1;
			const newYear = newMonth > 12 ? year + 1 : year;
			const newMonthAdjusted = newMonth > 12 ? 1 : newMonth;

			return `${newYear}-${newMonthAdjusted.toString().padStart(2, "0")}-${dayDate.toString().padStart(2, "0")}`;
		} else {
			// Keep the current month
			return `${year}-${month.toString().padStart(2, "0")}-${dayDate.toString().padStart(2, "0")}`;
		}
	}

	useEffect(() => {
		if (hasViewPermission) {
			setIsLoading(true);
			debounce(() => {
				FinancialService.getAllInstallmentInvoice(pagina, busca, status, dataIni, dataFim).then((result) => {
					setIsLoading(false);
					setRefreshList(false);
					if (result instanceof Error) {
						alert(result.message);
					} else {
						setTotalCount(result.totalCount);
						setRows(result.data);
					}
				});
			});
		}
	}, [pagina, busca, status, dataIni, dataFim, refreshList]);

	const handleSelect = (IID: string) => {
		const selectedIndex = selects.indexOf(IID);
		if (selectedIndex < 0) {
			selects.push(IID);
		}
		if (selectedIndex > 0) {
			selects.splice(selectedIndex, 1);
		}
	};

	const handleExtractPDF = (IID: string) => {
		setIsLoading(true);
		debounce(() => {
			FinancialService.getInstallmentPDF(IID, dataIni, dataFim).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					//alert(result.message);
				} else {
					//setModalDetail(true)
				}
			});
		});
	};

	const handleInvoiceDetail = (idDetail: string) => {
		setIsLoading(true);
		debounce(() => {
		  FinancialService.getInstallmentbyDocument(1,10000,idDetail , dataIni, dataFim)
		    .then((result) => {
		      setIsLoading(false);
		      if (result instanceof Error) {
		        alert(result.message);
		      } else {
		        setRowsDetail(result.data);
		        setModalDetail(true)
		      }
		    });
		});
	};

	const handleGenerateInvoice = (dados: IGenerateInvoice) => {
		setIsLoading(true);
		console.log("dados:", dados);
		debounce(() => {
			FinancialService.generateInvoice(dados, dataIni, dataFim).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					alert(result.message);
				} else {
					toastHandler.present({
						type: "success",
						position: "top-right",
						messageOrContent: <ToastContent title={"Sucesso"} content={"Fatura Gerada com sucesso"} />,
					});
					setRefreshList(true);
				}
			});
		});
	};

	return (
		<LayoutBaseDePagina
			titulo="Financeiro"
			subtitulo="Movimentação Financeira"
			barraDeFerramentas={
				<ToolbarFinancial
					onClickFaturamento={() => setLayoutFinan("invoicing")}
					onClickBoletos={() => {
						setLayoutFinan("installment");
						navigate(`/boletos/`);
					}}
					onClickNfse={() => {
						setLayoutFinan("nfse");
						navigate(`/notas/`);
					}}
					onClickBordero={() => {
						navigate(`/bordero/`);
						setLayoutFinan("bordero");
					}}
					onClickSetup={() => {
						navigate(`/setup/`);
						setLayoutFinan("setup");
					}}
					isDateStartDefault={true}
					showFilterPeriod={true}
					selectBordero={layoutFinan === "bordero"}
					selectBoletos={layoutFinan === "installment"}
					selectFaturamento={layoutFinan === "invoicing"}
					selectSetup={layoutFinan === "setup"}
					showButtonReport={false}
					onChangeDataIni={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								dataini: retorno,
								datafim: dataFim,
							},
							{ replace: true }
						)
					}
					onChangeDataFim={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								dataini: dataIni,
								datafim: retorno,
							},
							{ replace: true }
						)
					}
				/>
			}
		>
			{modalDueDate && (
				<ModalContext
					isCloseModal={() => setModalDueDate(false)}
					isOpenModal={modalDueDate}
					messageType={"question"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					textButtonCancel={"Voltar"}
					onclickConfirm={() => {
						// handleDuaDate();
					}}
					ITitle={"Confirma geração do boleto ?"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formIdGenerateInvoice"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRef}
									onSubmit={handleGenerateInvoice}
								>
									<Grid container direction="column" padding={1} spacing={2}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}

										<Grid container item direction="row" spacing={1}>
											<Grid item xs={1} sx={{ textAlign: "left" }}>
												Cliente:
											</Grid>
											<Grid item xs={8} sx={{ alignItems: "left" }}>
												<Typography variant="inherit" sx={{ fontWeight: "bold" }}>
													{nameInvoice}
												</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid container item direction="row" gap={3} sx={{ mb: 3, mt: "1rem" }}>
										<Grid item xs={12} sx={{ alignItems: "left" }}>
											<TextField
												fullWidth
												label="Referencia"
												name="reference"
												disabled={isLoading}
												size="small"
												type="text"
												InputLabelProps={{
													shrink: true,
													required: true,
												}}
												// defaultValue={dueDateInvoice.substring(0, 10)}
												onChange={(e) => {
													setReferenceInvoice(e.target.value);
												}}
											></TextField>
										</Grid>
									</Grid>

									<Grid container item direction="row" gap={3} sx={{ mb: 3, mt: "1rem" }}>
										<Grid item xs={4} sx={{ alignItems: "left" }}>
											<TextField
												fullWidth
												label="Vencimento"
												name="dueDate"
												disabled={isLoading}
												size="small"
												type="date"
												InputLabelProps={{
													shrink: true,
													required: true,
												}}
												defaultValue={dueDateInvoice.substring(0, 10)}
												onChange={(e) => {
													setDueDateInvoice(e.target.value + " 12:00:00");
												}}
											></TextField>
										</Grid>
										<Grid item xs={4} sx={{ alignItems: "left" }}>
											<TextField
												fullWidth
												label="Valor"
												name="Valor"
												disabled={true}
												value={parseFloat(valueInvoice).toLocaleString("pt-br", {
													style: "currency",
													currency: "BRL",
												})}
												size="small"
											></TextField>
										</Grid>
									</Grid>

									<Grid
										container
										sm={12}
										sx={{
											border: "none",
											display: "flex",
											justifyContent: "center",
										}}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										<Button
											color="info"
											disableElevation
											variant={"contained"}
											startIcon={<Icon> checkIcon</Icon>}
											onClick={() => {
												//formRef.current?.submitForm();
												handleGenerateInvoice({
													clientId: clientId,
													dueDate: dueDateInvoice,
													reference: referenceInvoice,
													id: uuid(),
													value: valueInvoice,
													installmentsNumber: Number(countInstallment ? countInstallment : 0),
													gateway: "EMPTY",
													status: "pending",
												});
												setModalDueDate(false);
												//navigate("/boletos/");
											}}
										>
											{"Confirmar"}
										</Button>
										<Button
											color="info"
											disableElevation
											variant={"outlined"}
											startIcon={<Icon> arrow_back_Ico</Icon>}
											onClick={() => {
												setModalDueDate(false);
											}}
										>
											{"Voltar"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{modalDetail && (
				<ModalContext
					isCloseModal={() => setModalDetail(false)}
					isOpenModal={modalDetail}
					messageType={"info"}
					showButtonCancel={false}
					textButtonConfirm={"Fechar"}
					onclickConfirm={() => {}}
					ITitle={"Extrato Faturamento Cliente"}
					IMessage={[
						<Grid container>
							<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Box} padding={3} spacing={2}>
								{rowsDetail.map((rowD, key) => (
									<Grid
										container
										item
										direction="row"
										spacing={0}
										sx={{ backgroundColor: colorLine(key) }}
										borderBottom={"1px solid #e4e9ee"}
									>
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<Typography variant="caption" color={"text.secondary"}>
												Locatário
											</Typography>
											<Typography variant="subtitle2">{rowD.name}</Typography>
											<Typography variant="caption" color={"text.secondary"}>
												{rowD.document? 
												   rowD.document.length > 11
													? `${rowD.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5").substring(0, 3)}`
													: `${rowD.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")}`
												: ''}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
											<Typography variant="caption" color={"text.secondary"}>
												Vencimento
											</Typography>
											<Typography variant="subtitle2">{moment(rowD.Installment_due_date).format("L")}</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
											<Typography variant="caption" color={rowD.Installment_type_amount != "discount" ? "text.secondary" : "red"}>
												{rowD.Installment_type_amount != "discount" ? "Parcela" : "Desconto"}
											</Typography>
											<Typography variant="subtitle2" color={rowD.Installment_type_amount != "discount" ? "text.secondary" : "red"}>
												{rowD.Installment_type_amount != "discount" ? rowD.Installment_number : rowD.Installment_obs}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={2} lg={2} xl={2} textAlign={"center"}>
											<Typography variant="caption" color={rowD.Installment_type_amount != "discount" ? "text.secondary" : "red"}>
												Valor
											</Typography>

											{rowD.Installment_type_amount != "discount" ? (
												<Typography textAlign={"right"} mr={5}>
													{parseFloat(rowD.Installment_amount).toLocaleString("pt-br", {
														style: "currency",
														currency: "BRL",
													})}
												</Typography>
											) : (
												<Typography sx={{ color: "red" }} textAlign={"right"} mr={5}>
													-{" "}
													{parseFloat(rowD.Installment_amount).toLocaleString("pt-br", {
														style: "currency",
														currency: "BRL",
													})}
												</Typography>
											)}
										</Grid>
									</Grid>
								))}

								<Grid
									container
									sm={12}
									sx={{
										border: "none",
										display: "flex",
										justifyContent: "center",
									}}
									display={"flex"}
									flexDirection={smDown ? "column" : "row"}
									padding={2}
									mt={2}
								>
									<Grid container item direction="row" spacing={1} justifyContent={"end"}>
										<Grid container item direction="row" spacing={1} mb={1} justifyContent={"end"}>
											<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
												<Typography variant="body1" color={"secondary"} textAlign={"right"}>
													Total da Fatura :
												</Typography>
											</Grid>
											<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
												<Typography variant="body1" color={"secondary"} textAlign={"right"}>
													{resultado.toLocaleString("pt-br", {
														style: "currency",
														currency: "BRL",
													})}
												</Typography>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={1} mb={1} justifyContent={"end"}>
											<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
												<Typography variant="body1" color={"primary"} textAlign={"right"}>
													Descontos :
												</Typography>
											</Grid>
											<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
												<Typography variant="body1" color={"primary"} textAlign={"right"}>
													{discount.toLocaleString("pt-br", {
														style: "currency",
														currency: "BRL",
													})}
												</Typography>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={1} justifyContent={"end"}>
											<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
												<Button
													color="info"
													disableElevation
													variant={"contained"}
													startIcon={<Icon> close_Ico</Icon>}
													onClick={() => {
														setModalDetail(false);
													}}
												>
													{"Fechar"}
												</Button>
											</Grid>
											<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
												<Typography variant="body1" color={"secondary"} textAlign={"right"}>
													Saldo a pagar :
												</Typography>
											</Grid>
											<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
												<Typography variant="body1" color={"secondary"} textAlign={"right"}>
													{saldo.toLocaleString("pt-br", {
														style: "currency",
														currency: "BRL",
													})}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>,
					]}
				/>
			)}

			{/* {isLoading && !isExtractPDF && !busca?.trim()?.length ? <Loading />
        : */}
			<ProfileGuard requiredRole={PermissionRolesEnum.FinancialOptions} requiredAction={PermissionActionsEnum.View}>
				<TableContainer
					component={Paper}
					variant="outlined"
					sx={{
						marginLeft: 1,
						marginTop: 1,
						marginRight: 2,
						width: "auto",
						padding: 4,
					}}
				>
					<Table aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell colSpan={7}>
									<ToolbarGridFinancial
										textSearchGrid={busca}
										onChangeTextSearchGrid={(text) => setSearchParams({ busca: text, pagina: "1" }, { replace: true })}
										showButtonBilled={false}
										showButtonCanceled={false}
										showButtonOpen={false}
										showButtonPaid={false}
										showInputSearchGrid={true}
										onClickAll={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "",
													dataini: dataIni,
													datafim: dataFim,
												},
												{ replace: true }
											)
										}
										onClickOpen={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "open",
													dataini: dataIni,
													datafim: dataFim,
												},
												{ replace: true }
											)
										}
										onClickBilled={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "billed",
													dataini: dataIni,
													datafim: dataFim,
												},
												{ replace: true }
											)
										}
										onClickCanceled={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "canceled",
													dataini: dataIni,
													datafim: dataFim,
												},
												{ replace: true }
											)
										}
										selectAll={status === ""}
										selectOpen={status === "open"}
										selectBilled={status === "billed"}
										selectCanceled={status === "canceled"}
										showButtonReport={false}
									/>
								</TableCell>
							</TableRow>

							<TableRow sx={{ background: "#f5fbfd" }}>
								<TableCell>
									<Checkbox
										color="secondary"
										inputProps={{
											"aria-label": "select all desserts",
										}}
										//onSelect={()=>handleSelect(row.name)}
									/>
								</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Cliente</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Garantias</TableCell>
								<TableCell
									sx={{
										color: "#7e7777b5",
										textAlign: "center",
									}}
								>
									{" "}
									Vencimento
								</TableCell>
								<TableCell
									sx={{
										color: "#7e7777b5",
										textAlign: "right",
									}}
								>
									{" "}
									Valor
								</TableCell>
								<TableCell
									sx={{
										color: "#7e7777b5",
										textAlign: "center",
									}}
								>
									{" "}
									Status{" "}
								</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> </TableCell>
								<TableCell sx={{ color: "#7e7777b5", width: "4px" }}> </TableCell>
								<TableCell sx={{ color: "#7e7777b5", width: "4px" }}> </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => (
								<TableRow key={row.name} hover>
									<TableCell>
										<Checkbox
											color="secondary"
											inputProps={{
												"aria-label": "select all desserts",
											}}
											onClick={() => handleSelect(row.name)}
										/>
									</TableCell>
									<TableCell sx={{ fontWeight: "normal" }}>
										<Typography>
											{row.name}
											{row?.status == "inactive" && " (Inativo)"}
										</Typography>
										{row.document && (
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row.document?.length > 11
													? row.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
													: row.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")}
											</Typography>
										)}
									</TableCell>

									<TableCell sx={{ textAlign: "start" }}>
										<Typography variant="subtitle2" marginLeft={1} color={"#959595"} sx={{ fontWeight: "normal" }}>
											{row.count_amount}
										</Typography>
									</TableCell>

									<TableCell sx={{ textAlign: "start" }}>
										<Typography
											variant="subtitle2"
											marginLeft={1}
											color={"#959595"}
											sx={{
												fontWeight: "normal",
												textAlign: "center",
											}}
										>
											{handleDueDate(row.data_generate, row.day_due_date)}
											{/* //moment(row.due_date).format("L")} */}
										</Typography>
									</TableCell>

									<TableCell sx={{ textAlign: "start" }}>
										<Typography
											variant="subtitle2"
											marginLeft={1}
											color={"#959595"}
											sx={{
												fontWeight: "normal",
												textAlign: "right",
											}}
										>
											{parseFloat(row.sum_amount).toLocaleString("pt-br", {
												style: "currency",
												currency: "BRL",
											})}
										</Typography>
									</TableCell>

									<TableCell sx={{ textAlign: "center" }}>
										<IconButton
											size="small"
											sx={{
												background: "#e0ffd6",
												borderRadius: "18px",
												paddingX: "15px",
												paddingY: "1px",
												marginBottom: "5px",
											}}
										>
											<Icon
												sx={{
													color: "#07df4f",
													fontWeight: "normal",
												}}
											>
												{" "}
												noise_control_off_sharp_icon
											</Icon>
											<Typography
												variant="subtitle2"
												marginLeft={1}
												sx={{
													color: "#229649",
													fontWeight: "normal",
												}}
											>
												{"Aberto"}
											</Typography>
										</IconButton>
									</TableCell>

									<TableCell sx={{ textAlign: "right" }}>
										<IconButton
											size="small"
											onClick={() => {
												// handleGenerateInvoice({
												//   clientId: row.id,
												//   dueDate: row.due_date,
												//   id: uuid(),
												//   value: row.sum_amount,
												//   gateway: "EMPTY",
												//   status: "pending",
												// }),
												setValueInvoice(row.sum_amount);
												setCountInstallment(row.count_amount);
												setDueDateInvoice(handleDueDate(row.data_generate, row.day_due_date));
												setNameInvoice(row.name);
												setModalDueDate(true);
												setClientId(row.clientId);
												console.log("clienteid:", rows);
											}}
											sx={{ borderRadius: "18px" }}
										>
											<Icon
												sx={{
													color: "#E9B801",
													fontWeight: "normal",
												}}
											>
												{" "}
												app_registration_OutlinedIcon
											</Icon>
											<Typography
												variant="subtitle2"
												marginLeft={1}
												sx={{
													color: "#E9B801",
													fontWeight: "normal",
												}}
											>
												Faturar
											</Typography>
										</IconButton>
									</TableCell>

									<TableCell sx={{ textAlign: "left" }}>
										<IconButton
											size="small"
											onClick={() => {
												handleInvoiceDetail(row.clientId);
												//setIdPDF(row.id)
											}}
											sx={{ borderRadius: "18px" }}
										>
											<Icon
												sx={{
													color: "#0170B3",
													fontWeight: "normal",
												}}
											>
												{" "}
												search
											</Icon>
											<Typography
												variant="subtitle2"
												marginLeft={1}
												sx={{
													color: "#0170B3",
													fontWeight: "normal",
												}}
											>
												Visualizar
											</Typography>
										</IconButton>
									</TableCell>

									<TableCell sx={{ textAlign: "center" }}>
										<Tooltip title="PDF Extrato" placement="top-start">
											<IconButton size="small" onClick={() => handleExtractPDF(row.clientId)} sx={{ borderRadius: "18px" }}>
												<Icon
													sx={{
														color: "#7924c7",
														fontWeight: "normal",
													}}
												>
													{" "}
													picture_as_pdf_outlined_Icon
												</Icon>
												{/* <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#7924c7", fontWeight: "normal" }}>
                                                    Extrato
                                                </Typography> */}
											</IconButton>
										</Tooltip>
									</TableCell>
								</TableRow>
							))}
						</TableBody>

						{totalCount === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}
						<TableFooter>
							{isLoading && (
								<TableRow>
									<TableCell colSpan={3}>
										<LinearProgress variant="indeterminate" />
									</TableCell>
								</TableRow>
							)}
							{totalCount > 0 && (
								<TableRow>
									<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
										<Pagination
											page={pagina}
											count={Math.ceil(totalCount / Environment.LIMITE_LINHAS)}
											onChange={(_, newPage) =>
												setSearchParams(
													{
														busca,
														pagina: newPage.toString(),
														dataini: dataIni,
														datafim: dataFim,
													},
													{ replace: true }
												)
											}
										/>
									</TableCell>
									<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
										<Typography variant="body1" sx={{ fontWeight: "normal" }}>
											Exibindo {pagina * Environment.LIMITE_LINHAS - (Environment.LIMITE_LINHAS - 1)}
											{" - "}
											{Environment.LIMITE_LINHAS * (pagina - 1) + Environment.LIMITE_LINHAS * pagina < totalCount
												? Environment.LIMITE_LINHAS * pagina
												: totalCount}{" "}
											de
											<Typography
												variant="overline"
												sx={{
													fontWeight: "bold",
													color: "#0170B3",
													fontSize: "16px",
												}}
											>
												{" "}
												{totalCount}{" "}
											</Typography>
											registro(s)
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableFooter>
					</Table>
				</TableContainer>
			</ProfileGuard>
			{/* } */}
		</LayoutBaseDePagina>
	);
};
