import { Box, Grid, LinearProgress, MenuItem, Paper, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { ToastContent } from "../../../../shared/components";
import { VTextField } from "../../../../shared/forms";
import { VMaskTextField } from "../../../../shared/forms/VMaskTextField";
import { useToast } from "../../../../shared/hooks";
import { compareTextEqual, globalColorTytle, RangeStates, typeProperty } from "../../../../shared/utils";
import LoadingDialog from "../../../../shared/components/LoadingModalDialog/LoadingModalDialog";

interface PropertyDataSectionProps {
	formRef: any;
	isLoading: boolean;
}

const PropertyDataSection: React.FC<PropertyDataSectionProps> = ({ formRef, isLoading }) => {
	const toastHandler = useToast();
	const [isLoadingCEP, setIsLoadingCEP] = useState(false);
	const addressNumberRef = useRef<HTMLInputElement | null>(null);
	const addressZipcodeRef = useRef<HTMLInputElement | null>(null);

	const showToast = useCallback(
		(title: string, content: string) => {
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={title} content={content} />,
			});
		},
		[toastHandler]
	);

	const updatePropertyTypeAndPurpose = useCallback(
		(value: string) => {
			const selectedProp = typeProperty.find((opcao) => compareTextEqual(opcao.description, value));
			formRef.current?.setFieldValue("propertyType", selectedProp?.description || "");
			formRef.current?.setFieldValue("propertyFinally", selectedProp?.type || "");
		},
		[formRef]
	);

	const handleTypePropertyChange = useCallback(
		(event: React.ChangeEvent<{ value: unknown }>) => {
			updatePropertyTypeAndPurpose(event.target.value as string);
		},
		[updatePropertyTypeAndPurpose]
	);

	const checkCEP = async (e: React.FocusEvent<HTMLInputElement>) => {
		const cep = e.target.value.replace(/\D/g, "");
		setIsLoadingCEP(true);

		if (cep.length !== 8) {
			setIsLoadingCEP(false);
			return;
		}

		try {
			const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
			const data = await response.json();

			if (data.erro) {
				showToast("Erro de CEP", "CEP não localizado. Verifique o valor digitado.");
				["addressZipcode", "addressStreet", "addressDistrict", "addressCity", "addressState"].forEach((field) =>
					formRef.current?.setFieldValue(field, "")
				);
				addressZipcodeRef.current?.focus();
			} else {
				const fieldsToSet = {
					addressZipcode: data.cep,
					addressStreet: data.logradouro,
					addressDistrict: data.bairro,
					addressCity: data.localidade,
					addressState: data.uf,
					addressIBGE: data.ibge,
				};

				Object.entries(fieldsToSet).forEach(([key, value]) => formRef.current?.setFieldValue(key, value));
				addressNumberRef.current?.focus();
			}
		} catch {
			showToast("Erro na consulta", "Ocorreu um problema ao buscar o CEP. Tente novamente.");
			addressZipcodeRef.current?.focus();
		} finally {
			setIsLoadingCEP(false);
		}
	};

	return (
		<>
			<LoadingDialog isLoading={isLoadingCEP} loadingText={`Localizando CEP...`} />
			<Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
				<Grid container direction="column" padding={2} spacing={2}>
					{isLoading && (
						<Grid item xs={12}>
							<LinearProgress variant="indeterminate" />
						</Grid>
					)}

					<Grid item xs={12}>
						<Typography variant="h6" color={globalColorTytle}>
							Dados do Imóvel
						</Typography>
					</Grid>

					<Grid container item spacing={2}>
						<Grid item xs={12} md={4}>
							<VTextField fullWidth label="Tipo Imóvel" name="propertyType" disabled={isLoading} select onChange={handleTypePropertyChange}>
								{typeProperty.map((element) => (
									<MenuItem key={element.description} value={element.value}>
										{element.description}
									</MenuItem>
								))}
							</VTextField>
						</Grid>

						<Grid item xs={12} md={4}>
							<VTextField fullWidth label="Finalidade" name="propertyFinally" disabled select>
								<MenuItem value="residential">Residencial</MenuItem>
								<MenuItem value="commercial">Comercial</MenuItem>
							</VTextField>
						</Grid>
					</Grid>

					<Grid container item spacing={2}>
						<Grid item xs={12} md={4}>
							<VMaskTextField
								fullWidth
								label="CEP"
								name="addressZipcode"
								disabled={isLoading}
								mask="cep"
								onBlur={checkCEP}
								inputRef={addressZipcodeRef}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<VTextField fullWidth label="Rua" name="addressStreet" disabled={isLoading} />
						</Grid>
						<Grid item xs={12} md={4}>
							<VTextField fullWidth label="Número" name="addressNumber" inputRef={addressNumberRef} disabled={isLoading} />
						</Grid>
					</Grid>

					<Grid container item spacing={2}>
						<Grid item xs={12} md={4}>
							<VTextField fullWidth label="Complemento" name="addressComplement" disabled={isLoading} />
						</Grid>
						<Grid item xs={12} md={4}>
							<VTextField fullWidth label="Bairro" name="addressDistrict" disabled={isLoading} />
						</Grid>
						<Grid item xs={12} md={4}>
							<VTextField fullWidth label="Cidade" name="addressCity" disabled={isLoading} />
						</Grid>
					</Grid>

					<Grid container item spacing={2}>
						<Grid item xs={12} md={4}>
							<VTextField fullWidth label="Estado" name="addressState" disabled={isLoading} select>
								{RangeStates.map(({ value, label }) => (
									<MenuItem key={value} value={value}>
										{label}
									</MenuItem>
								))}
							</VTextField>
						</Grid>
						<Grid item xs={12} md={4}>
							<VTextField fullWidth label="Cód IBGE" name="addressIBGE" disabled sx={{ border: "none" }} />
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default PropertyDataSection;
