import { Box, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ModalContext, ToastContent } from "../../../shared/components";
import { analysisService } from "../../../shared/services/api/analysis/analysisService";
import { useToast } from "../../../shared/hooks";
import { ca } from "date-fns/locale";
interface LoadingModalProps {
	isOpenModal: boolean;
	isClose: (status: string) => void;
	isRepeat?: number;
	Row?: any;
}

const LoadingModal: React.FC<LoadingModalProps> = ({ isOpenModal, isClose, isRepeat = 5, Row }) => {
	const [counter, setCounter] = useState(0);
	const toastHandler = useToast();
	const [newStatus, setNewStatus] = useState(Row.status || "pending");

	const showToast = (contentMessage: string, type: "error" | "success" | "info") => {
		toastHandler.present({
			type,
			position: "top-right",
			messageOrContent: <ToastContent title={"Alerta!"} content={contentMessage} />,
		});
	};

	const handleResult = async (id: string, valor: string): Promise<boolean> => {
		try {
			const data = await analysisService.resendAnalysis(id, valor);
			if (!data || typeof data !== "object" || data instanceof Error) {
				console.error("Erro inesperado ao buscar os dados da análise", data?.message || data);
				showToast("Erro inesperado ao buscar os dados da análise", "error");
				return false;
			}

			const status = data.data.status;
			setNewStatus(status);

			// Se o status for "approved", "awaiting_biometria" ou "refused", retorna true para interromper as tentativas
			if (["approved", "awaiting_biometria", "refused"].includes(status)) {
				isClose(status);
				console.log("Análise finalizada com sucesso", status);
				return true;
			}
		} catch (e: any) {
			showToast("Erro ao buscar os dados da análise", "error");
			console.error("Erro ao buscar os dados da análise", e?.message || e);
		}
		return false;
	};

	const checkStatus = async (id: string, attempt: number) => {
		// Se `newStatus` já mudou, interrompe as tentativas
		if (newStatus !== "pending") {
			isClose(newStatus);
			return;
		}

		if (attempt >= isRepeat) {
			console.error(`Máximo de tentativas (${isRepeat}) alcançado.`);
			showToast(
				"Limite máximo de tentativas atingido. Houve um problema interno, e sua análise estará disponível para nova tentativa mais tarde.",
				"error"
			);

			try {
				analysisService.notificationSend(
					"Erro modulo de análises",
					`Erro ao tentar realizar a análise com ID: " ${id} ' : '${new Date().toLocaleString()}`
				);
			} catch (e) {
				console.error("Erro ao enviar notificação de erro", e);
			}

			isClose(newStatus); // Passa o status ao fechar o modal
			return;
		}

		// Chamando `handleResult` e verificando se a tentativa deve parar
		//caso passa como parametro no handleResult o valor 4 retorna aprovado, 3 recuaso e os outrossegue o fluxo normal
		const shouldStop = await handleResult(id, "5");
		if (!shouldStop) {
			setCounter(attempt + 1);
			setTimeout(() => checkStatus(id, attempt + 1), 3000); // Aguarda 2 segundos antes da próxima tentativa
		}
	};

	useEffect(() => {
		if (isOpenModal && Row?.id) {
			setCounter(0);
			setNewStatus(Row.status); // Reinicia o status ao abrir o modal
			checkStatus(Row.id, 1);
		}
	}, [isOpenModal, Row]);

	const GradientCircularProgress = () => (
		<>
			<svg width={0} height={0}>
				<defs>
					<linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
						<stop offset="0%" stopColor="#e01cd5" />
						<stop offset="100%" stopColor="#1CB5E0" />
					</linearGradient>
				</defs>
			</svg>
			<CircularProgress size={60} thickness={4.5} sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} />
		</>
	);

	return (
		<ModalContext
			isOpenModal={isOpenModal}
			isCloseModal={() => isClose(newStatus)}
			messageType="info"
			showButtonCancel={false}
			showButtonConfirm={false}
			showTitleIcon={false}
			enableBackdropClose={false}
			overrideWidthValue="30%"
			overrideHeightValue="30%"
			ITitle="Realizando análise"
			ISubTitle="Está demorando um pouco mais que o normal, mas estamos quase lá!"
			IMessage={[
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding={3} key="loading-content">
					<Box position="relative" display="inline-flex">
						<Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
							<Typography variant="body1" component="div" color="textSecondary">{`${Math.round((counter / isRepeat) * 100)}%`}</Typography>
						</Box>
						<GradientCircularProgress />
					</Box>

					<Typography variant="caption" marginTop={2}>
						Estamos processando os dados, aguarde um momento...
					</Typography>
					<Typography variant="body1" marginTop={2}>
						{`Tentativa: ${counter} de ${isRepeat}`}
					</Typography>
				</Box>,
			]}
		/>
	);
};

export default LoadingModal;
