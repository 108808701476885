import React from "react";
import { Divider, Icon, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { checkByProfileGuard } from '../../../shared/guards/ProfileGuard';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import { TInvoiceClient } from "./FinancialHealthListPresentation";
import { DownloadDoneOutlined } from "@mui/icons-material";
import DescriptionIcon from '@mui/icons-material/Description';


type InvoiceRowPropsType = {
    row: TInvoiceClient,
    goTo: any,
    handleApproved?: () => void;
}
export function RowMenuInvoice({ row, goTo }: InvoiceRowPropsType) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClick = (event: any
    ) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const statusInvoice: any = ['paid', 'canceled'];

    return (
        <>
            <Menu
                id={`actions-${row.id}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/* {checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.View) ?
                    <MenuItem disabled={!statusInvoice.includes(row?.status)} onClick={(e: any) => { goTo('details', row.id, e, row), handleClose() }} sx={{ color: "#151616", gap: 1 }} ><DescriptionIcon />Detalhes</MenuItem>
                    : ''
                } */}
                {checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Update) ?
                    <MenuItem disabled={(row.status != 'canceled') ? false : true} onClick={(e) => { goTo('extrato', row.id, e, row), handleClose() }} sx={{ color: "#0170B3", gap: 1 }} ><GradingOutlinedIcon />Extrato</MenuItem>
                    : ''
                }
                {checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Add) ?
                    <MenuItem disabled={(row.status != 'canceled') ? false : true} onClick={(e) => { goTo('pdfextrato', row.id, e, row), handleClose() }} sx={{ color: "#0170B3", gap: 1 }} ><PictureAsPdfOutlinedIcon />Extato por PDF</MenuItem>
                    : ''
                }
                <Divider />
                {checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Add) ?
                    <MenuItem disabled={(row.status != 'pending' && row.status != 'canceled') ? false : true} onClick={(e) => { goTo('reenviar', row.id, e, row), handleClose() }} sx={{ color: "orange", gap: 1 }} ><ForwardToInboxOutlinedIcon />Reenviar Email</MenuItem>
                    : ''
                }
                <Divider />
                {checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Add) ?
                    <MenuItem disabled={(row.status != 'paid' && row.status != 'canceled') ? false : true} onClick={(e) => { goTo('cancelar', row.id, e, row), handleClose() }} sx={{ color: "red", gap: 1 }} ><CancelPresentationOutlinedIcon /> Cancelar</MenuItem>
                    : ''
                }
                <Divider />
                {checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Add) ?
                    <MenuItem disabled={(row.status != 'paid' && row.status != 'canceled') ? false : true} onClick={(e) => { goTo('baixar', row.id, e, row), handleClose() }} sx={{ color: "green", gap: 1 }} ><DownloadDoneOutlined /> Baixa Manual</MenuItem>
                    : ''
                }
            </Menu>
            <IconButton size="small" sx={{ borderRadius: "18px" }} onClick={handleClick}>
                <Icon sx={{ color: "#0170B3", fontWeight: "normal" }}> more_horiz</Icon>
                <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#0170B3", fontWeight: "normal" }}>
                </Typography>
            </IconButton>
        </>
    );
}
