import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { ListSearchParams } from "../../../pages/analysis/types/list-search-params.type";
import { useEffect, useMemo, useState } from "react";
import { useProfile } from "../../../context/ProfileContext";
import { ToastContent } from "..";
import { clientService } from "../../services/api/client/clientService";
import { useToast } from "../../hooks";
import { returnMessageError } from "../../utils/returnMessageError";
import { Search } from "@mui/icons-material";
import { DateCalendarView } from "../dateCalendar/DateCalendar";

export type TAutoCompleteOption = {
	id: string;
	label: string;
};

interface IToolFinancialSummaryProps {
	showButtonBack?: boolean;
	showButtonBackLoading?: boolean;
	clickBack?: () => void;

	showPeriodo?: boolean;
	onChangeDataIni?: (value: any) => void;
	onChangeDataFim?: (value: any) => void;

	onClick?: () => void;

	showInputClientFilter?: boolean;
	onChangeClientFilter?: (text: string) => void;

	showTypeReport?: boolean;
	onChangeTypeReport?: (event: SelectChangeEvent) => void;

	filterInitialParams?: ListSearchParams;

	showInputBusca?: boolean;
	loading?: boolean | undefined;
}

export const ToolbarFinancialSummary: React.FC<IToolFinancialSummaryProps> = ({
	showButtonBack = true,
	showButtonBackLoading = false,
	showPeriodo = true,

	showInputClientFilter = true,
	onChangeClientFilter,

	showTypeReport = true,
	onChangeTypeReport,

	showInputBusca = true,
	loading = false,

	clickBack,
	onChangeDataIni,
	onChangeDataFim,
	onClick,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [dataIni, setDataIni] = useState(null);
	const [dataFim, setDataFim] = useState(null);
	const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
	const [selectedId, setSelectedId] = useState<string | undefined>();
	const [busca, setBusca] = useState("");
	const { userClientId, isSuperAdmin, permissionLevel } = useProfile();
	const toastHandler = useToast();
	const theme = useTheme();

	useEffect(() => {
		setIsLoading(true);
		clientService
			.getSelect(1, busca, "", "", "active", isSuperAdmin || permissionLevel == 2 ? "" : userClientId)
			.then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				} else {
					const options = result?.data?.map((client) => ({ id: client.id, label: client.name }));

					if ((!isSuperAdmin || permissionLevel == 2) && options?.length == 1) {
						setSelectedId(options[0].id);
					}

					setOpcoes(options);
				}
			})
			.catch((err: any) => {
				setIsLoading(false);
				const { titleMessage, contentMessage } = returnMessageError(err);

				toastHandler.present({
					type: "error",
					position: "top-right",
					durationMs: 4000,
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			});
	}, [busca]);

	const changeTypeReport = (event: SelectChangeEvent) => {
		onChangeTypeReport?.(event);
		setDataIni(null);
		setDataFim(null);
		setSelectedId("");
	};

	const autoCompleteSelectedOption = useMemo(() => {
		if (!selectedId) return null;
		const selectedOption = opcoes.find((opcao) => opcao.id === selectedId);
		if (!selectedOption) return null;
		return selectedOption;
	}, [selectedId, opcoes]);

	return (
		<Box gap={1} marginX={1} padding={1} paddingX={2} display="flex" alignItems="center" height={theme.spacing(5)} component={Paper}>
			<Grid container display="flex" flexDirection="row">
				{showButtonBack && !showButtonBackLoading && (
					<Grid item xs={12} md={1.5} paddingRight={1}>
						<Button
							color="primary"
							sx={{
								height: "40px",
								borderColor: "rgb(192, 192, 192);",
								color: "rgba(0, 0, 0, 0.6);",
								"&.MuiButtonBase-root.MuiButton-root:hover": {
									borderColor: "rgba(0, 0, 0, 0.87);",
									bgcolor: "white",
								},
							}}
							disableElevation
							variant="outlined"
							onClick={clickBack}
							startIcon={<Icon>keyboard_double_arrow_left_icon</Icon>}
						>
							<Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
								Voltar
							</Typography>
						</Button>
					</Grid>
				)}

				{showInputClientFilter && (
					<Grid item xs={12} md={3.5} paddingRight={1}>
						<FormControl fullWidth>
							<Autocomplete
								fullWidth
								openText="Abrir"
								closeText="Fechar"
								noOptionsText="Nenhuma opção"
								loadingText="Loading ..."
								disablePortal
								value={autoCompleteSelectedOption}
								options={opcoes}
								disabled={loading}
								loading={isLoading}
								size={"small"}
								onInputChange={(_, newValue) => setBusca(newValue)}
								onChange={(_, newValue) => {
									onChangeClientFilter?.(newValue?.id ? newValue?.id : ("" as string));
									setSelectedId(newValue?.id ? newValue?.id : ("" as string));
								}}
								renderInput={(params) => <TextField {...params} label="Parceiro/Cliente" name="cliente" />}
							/>
						</FormControl>
					</Grid>
				)}

				{showPeriodo && (
					<Grid item xs={12} md={3} display={"contents"}>
						<Grid item xs={12} md={2} paddingRight={1}>
							<DateCalendarView
								title="Data Inicial"
								value={dataIni}
								loading={loading}
								disabled={loading}
								onChange={(value: any) => (setDataIni(value), onChangeDataIni?.(value))}
							/>
						</Grid>

						<Grid item xs={12} md={2} paddingRight={1}>
							<DateCalendarView
								title="Data Final"
								value={dataFim}
								loading={loading}
								disabled={loading}
								onChange={(value: any) => (setDataFim(value), onChangeDataFim?.(value))}
							/>
						</Grid>
					</Grid>
				)}

				{showTypeReport && (
					<Grid item xs={12} md={1.5}>
						<FormControl fullWidth>
							<InputLabel id="id_type_report">Tipo relatório</InputLabel>
							<Select
								labelId="typeReport"
								id="id_type_report"
								defaultValue="Sintético"
								size="small"
								label={"Tipo relatório"}
								name="typeReport"
								disabled={loading}
								onChange={changeTypeReport}
							>
								<MenuItem key={0} value={"Sintético"}>
									Sintético
								</MenuItem>
								<MenuItem key={1} value={"Analítico"}>
									Analítico
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>
				)}

				{showInputBusca && (
					<Grid item xs={12} md={1} sx={{ marginLeft: "30px" }}>
						<Box display="flex" justifyContent="end">
							{loading ? (
								<CircularProgress />
							) : (
								<Tooltip title="Clique para Pesquisar">
									<IconButton
										aria-label="toggle password visibility"
										onClick={onClick}
										disableRipple
										disableFocusRipple
										disabled={loading}
										edge="end"
										sx={{
											color: "white",
											backgroundColor: "red",
											borderRadius: "4px",
											width: "120%",
										}}
									>
										<Search />
										<Typography marginLeft={1}>Filtrar</Typography>
									</IconButton>
								</Tooltip>
							)}
						</Box>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};
