import { Box, CircularProgress } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { IDetalheAnalises } from "../../../../shared/services/api/analysis/analysisService";
import ReactJson from "react-json-view";

type SerasaResponseRejectedModalContentPropsType = {
	analysis: IDetalheAnalises;
};

export const SerasaResponseRejectedModalContent = ({ analysis }: SerasaResponseRejectedModalContentPropsType) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	if (isLoading) {
		return (
			<Box>
				<CircularProgress variant="indeterminate" />
			</Box>
		);
	}

	return (
		<>
			<Box>
				<Box
					style={{
						minWidth: "25vw",
					}}
				>
					<ReactJson src={JSON.parse(analysis.serasaResponse)} displayDataTypes={false} quotesOnKeys={false}></ReactJson>
				</Box>
			</Box>
		</>
	);
};
