import { AuthGuard } from '../../shared/guards/AuthGuard';
import { Sidebar } from '../../shared/components';
import { ReportLifecycleProposalPresentation } from './components/ReportLifecycleProposalPresentation';

export const ReportLifecycleProposalPage = (): JSX.Element => {
  return (
    <AuthGuard>
      <Sidebar>
        <ReportLifecycleProposalPresentation />
      </Sidebar>
    </AuthGuard>
  );
};
