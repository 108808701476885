import React, {useCallback, useState} from 'react'
import { toast } from 'react-toastify';

interface IUseToastParams {
  type: 'error'|'success'|'warning'|'info',
  messageOrContent: string | JSX.Element,
  position?: 'top-right' | 'top-center' | 'bottom-right',
  durationMs?: number
}

export const useToast = () => {
  const [id, setId] = useState('')

  const present = useCallback((params: IUseToastParams) => {
    const {type, messageOrContent, position, durationMs} = params;

    const toastPosition = position ?? 'top-right'
    const duration = durationMs ?? 2500;
    const toastId = toast(messageOrContent, { type, autoClose: duration, position: toastPosition })
    setId(String(toastId));
  }, [id])

  const dismiss = () => {
    toast.dismiss(id);
  }

  return {
    present,
    dismiss
  }
}



