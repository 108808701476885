import { Clear, Search } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Icon, IconButton, InputAdornment, Paper, TextField, useTheme } from "@mui/material";

import { Environment } from "../../environment";

export interface IToolbarGridPermissionsProps {
  showInputSearchGrid?: boolean;

  showButtonListagem?: boolean;
}

export const ToolbarGridPermissions: React.FC<IToolbarGridPermissionsProps> = ({
  showButtonListagem = true,
  showInputSearchGrid = true
}) => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            alignItems="start"
            height={theme.spacing(6)}
        >

            <ButtonGroup aria-label="outlined primary button group">

              {
                showButtonListagem &&
                (
                  <Button
                  color="primary"
                  disableElevation
                  // variant={selectTodos ? "contained":"outlined"}
                  // onClick={onClickTodos}
                  startIcon={<Icon>menu</Icon>}
                >
                  {/* {textoBotaoTodos} */}
                  Listagem
                </Button>
                )
              }

            </ButtonGroup>

          {
            showInputSearchGrid &&
            (
              <Box flex={1} display="flex" justifyContent="end">
                <TextField
                  size="small"
                  // value={textoBusca}
                  placeholder={Environment.INPUT_BUSCA}
                  // onChange={(e) => onChangeTextBusca?.(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="start"
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          sx={{ color: "#dbd5d5" }}
                          // onClick={()=>onChangeTextBusca?.('')}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )
          }
        </Box >
    );
}; 