import React, { useCallback, useState } from "react";
import { Box, Button, Grid, Icon, InputAdornment, LinearProgress, MenuItem } from "@mui/material";
import { addMonths, endOfMonth, format, parseISO, startOfMonth } from "date-fns";
import * as yup from "yup";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { IVFormErros, useVForm, VForm, VTextField } from "../../../../../shared/forms";
import { VMaskTextField } from "../../../../../shared/forms/VMaskTextField";
import { useToast } from "../../../../../shared/hooks";
import { IInstallmentProposal, ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";
import { globalRemoveMask } from "../../../../../shared/utils";
import { formatDateForInput } from "../../../../../shared/utils/format-date";

interface ModalFinancialProps {
	isSuperAdmin: boolean;
	rows: any;
	isOpenModal: boolean;
	isClose: () => void;
	isUpdate: () => void;
}

interface IFormFinancial {
	amount: string;
	dueDate: string;
	obs: string;
	status?: string;
	paymentMethod?: string;
	number?: string;
	type?: string;
	typeAmount: string;
	proposalId?: string;
	clientId?: string;
}

export const ModalFinancialLaunch: React.FC<ModalFinancialProps> = ({ isSuperAdmin, isUpdate, rows, isClose, isOpenModal }) => {
	const { formRef } = useVForm();
	const toastHandler = useToast();
	const [rowsInstallment, setRowsInstallment] = useState<IInstallmentProposal[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isFinancialModalValidityDateEnabled, setIsFinancialModalValidityDateEnabled] = useState(true);
	const firstDayOfMonth = format(startOfMonth(new Date()), "yyyy-MM-dd");
	const lastDayOfMonthNextMonth = format(addMonths(endOfMonth(parseISO(firstDayOfMonth)), 6), "yyyy-MM-dd");

	const formValidationSchema: yup.SchemaOf<IFormFinancial> = yup.object().shape({
		amount: yup.string().required("Campo Obrigatório"),
		dueDate: yup.string().required("Campo Obrigatório"),
		obs: yup.string().required("Campo Obrigatório"),
		status: yup.string().notRequired().default(""),
		paymentMethod: yup.string().notRequired(),
		type: yup.string().notRequired().default(""),
		typeAmount: yup.string().required("Campo Obrigatório"),
		number: yup.string().notRequired().default(""),
		proposalId: yup.string().notRequired().default(""),
		clientId: yup.string().notRequired().default(""),
	});

	const canBillInTheSelectedMonth = useCallback(
		(month: number) => {
			const installmentInMonth = rowsInstallment.find((installment) => {
				const installmentMonth = parseInt(installment.dueDate.split("-")[1]);
				return installmentMonth === month;
			});

			return !installmentInMonth || installmentInMonth.status === "canceled";
		},
		[rowsInstallment]
	);

	const handleValidityDateChange = (date: string) => {
		if (!date) return;

		const [year, month] = date.split("-").map(Number);
		const validityDate = new Date(year, month - 1, 1);

		if (!canBillInTheSelectedMonth(validityDate.getMonth() + 1)) {
			const nextMonthDate = addMonths(validityDate, 1);
			formRef.current?.setFieldValue("dueDate", formatDateForInput(nextMonthDate));
			toastHandler.present({
				durationMs: 5000,
				type: "warning",
				position: "top-right",
				messageOrContent: (
					<ToastContent title={"Aviso"} content={"Já existe uma fatura no mês selecionado. O registro será lançado para o próximo mês."} />
				),
			});
		}
	};

	const handleTypeAmountChange = (typeAmount: string) => {
		const isEnabled = typeAmount !== "add";
		setIsFinancialModalValidityDateEnabled(isEnabled);

		if (!isEnabled) {
			const now = new Date();
			const canBill = canBillInTheSelectedMonth(now.getMonth() + 1);
			const targetDate = canBill ? now : addMonths(now, 1);

			formRef.current?.setFieldValue("dueDate", formatDateForInput(targetDate));

			if (!canBill) {
				toastHandler.present({
					durationMs: 5000,
					type: "warning",
					position: "top-right",
					messageOrContent: (
						<ToastContent title={"Aviso"} content={"Já existe uma fatura no mês corrente. O registro será lançado para o mês seguinte."} />
					),
				});
			}
		}
	};

	const handleSave = (data: IFormFinancial) => {
		const cleanedData = {
			...data,
			amount: globalRemoveMask(data.amount),
			paymentMethod: "billet",
			status: "pending",
			type: "monthly_payment",
			number: (rowsInstallment.length + 1).toString(),
			proposalId: rows.id,
			clientId: rows.clientId,
			dueDate: data.dueDate + " 03:00:00",
			
		};

		formValidationSchema
			.validate(cleanedData, { abortEarly: false })
			.then((validatedData) => {
				setIsLoading(true);
				ProposalService.createInstallment(validatedData).then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						toastHandler.present({
							type: "error",
							position: "top-center",
							messageOrContent: <ToastContent title={"Erro"} content={result.message} />,
						});
					} else {
						toastHandler.present({
							type: "success",
							messageOrContent: "Lançamento efetuado com sucesso!",
							position: "top-center",
						});
						isClose();
						isUpdate();
					}
				});
			})
			.catch((errors: yup.ValidationError) => {
				const validationErrors: IVFormErros = {};
				errors.inner.forEach((error) => {
					if (error.path) validationErrors[error.path] = error.message;
				});
				formRef.current?.setErrors(validationErrors);
			});
	};

	return (
		<ModalContext
			isCloseModal={isClose}
			isOpenModal={isOpenModal}
			messageType={"question"}
			showButtonCancel={false}
			showTitleIcon={false}
			textButtonConfirm={"Cancelar"}
			onclickConfirm={() => {}}
			ITitle={"Lançamento Financeiro"}
			IMessage={[
				<Box key="form-container" width="100%">
					<VForm id="formIdSave" ref={formRef} onSubmit={handleSave} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} placeholder="">
						<Grid container direction="column" padding={1} spacing={2}>
							{isLoading && (
								<Grid item>
									<LinearProgress variant="indeterminate" />
								</Grid>
							)}

							<Grid container item direction="row" spacing={2}>
								<Grid item xs={12}>
									<VTextField fullWidth label="Descrição" name="obs" />
								</Grid>
							</Grid>

							<Grid container item direction="row" spacing={2}>
								<Grid item xs={4}>
									<VTextField
										fullWidth
										label="Tipo de lançamento"
										name="typeAmount"
										select
										onChange={(event) => handleTypeAmountChange(event.target.value)}
									>
										<MenuItem value="discount">Desconto</MenuItem>
										<MenuItem value="add">Acrescimo</MenuItem>
										<MenuItem value="parcel">Parcela</MenuItem>
									</VTextField>
								</Grid>

								<Grid item xs={4}>
									<VMaskTextField
										fullWidth
										label="Valor"
										name="amount"
										mask="real"
										InputProps={{
											startAdornment: <InputAdornment position="start">R$</InputAdornment>,
										}}
									/>
								</Grid>

								<Grid item xs={4}>
									<VTextField
										fullWidth
										label="Vencimento"
										name="dueDate"
										type="date"
										inputProps={{ min: firstDayOfMonth }}
										onChange={(event) => handleValidityDateChange(event.target.value)}
										disabled={!isFinancialModalValidityDateEnabled}
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid container justifyContent="center" padding={2} gap={2}>
							<Button color="primary" variant="contained" startIcon={<Icon>checkIcon</Icon>} onClick={() => formRef.current?.submitForm()}>
								Confirmar
							</Button>
							<Button color="primary" variant="outlined" startIcon={<Icon>arrow_back_Ico</Icon>} onClick={isClose}>
								Voltar
							</Button>
						</Grid>
					</VForm>
				</Box>,
			]}
		/>
	);
};
