import {useMediaQuery, useTheme} from '@mui/material';
import {useMemo} from 'react';

interface IToastContentParams {
  title: string,
  subtitle?: string,
  content: string
}

export const ToastContent = (params: IToastContentParams) => {
  const { title, subtitle, content } = params;
  const theme = useTheme();
  const matchesVerySmall = useMediaQuery(theme.breakpoints.only('xs'));
  const matchesSmall = useMediaQuery(theme.breakpoints.only('sm'));
  const matchesLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const width = useMemo(() => {
    if (matchesVerySmall) {
      return '80vw'
    } else if (matchesSmall) {
      return '60vw'
    } else if (matchesLarge) {
      return '20vw'
    }

    return '45vw'

  },[matchesSmall, matchesLarge])

  return (
    <>
      <div style={{ textAlign: 'center', width, padding: '8px' }}>
        { title && <p style={{ fontWeight: 'bold' }}>{title}</p> }
        { subtitle && <label style={{ fontWeight: 'bold' }}>{subtitle}</label> }
        <p>{content}</p>
      </div>
    </>
  )
}