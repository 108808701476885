import React, {useEffect, useMemo, useState} from "react";
import {
  Typography, Box, LinearProgress, Button, Icon
} from "@mui/material";
import 'moment/locale/pt-br';
import { FinancialService } from '../../../shared/services/api/financial/FinancialService';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

type GenerateNFsModalPresentation = {
  nfInstallmentIds: string[];
  onSuccessfulCallHandler: (id: string) => void;
  onFailedCallHandler: (id: string) => void;
  onCompletedHandler: () => void;
}

const normaliseProgress = (value: number, min: number, max: number) => ((value - min) * 100) / (max - min);

export const GenerateNFsModalPresentation = (props: GenerateNFsModalPresentation) => {
  const [successfulCalls, setSuccessfulCalls] = useState(0);
  const [failedCalls, setFailedCalls] = useState(0);
  const completedCalls = useMemo(() => successfulCalls + failedCalls, [failedCalls, successfulCalls]);

  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const operations = props.nfInstallmentIds.map(async (id) => {
      try {
        await FinancialService.generateNfse(id);

        setSuccessfulCalls((previousValue) => previousValue + 1);
        props.onSuccessfulCallHandler(id);

      } catch (e) {
        setFailedCalls((previousValue) => previousValue + 1);
        props.onFailedCallHandler(id);
      }
    });

    Promise.allSettled(operations).then(() => {
      setCompleted(true)
    });
  }, []);

  return (
    <Box>
      {!completed && (
        <>
          <Typography
            id="modal-modal-title"
            fontSize='1.5rem'
            color='primary'
          >
            Gerando notas fiscais
          </Typography>
          <div style={{ marginBottom: '32px' }}>
            <LinearProgress color='primary'
                            sx={{
                              '--LinearProgress-radius': '6px',
                              '--LinearProgress-progressThickness': '128px',
                              boxShadow: 'sm',
                              borderColor: 'neutral.500',
                              height: 10
                            }}
                            variant="determinate" value={normaliseProgress(completedCalls, 0, props.nfInstallmentIds.length)}
                            valueBuffer={Math.trunc(completedCalls / props.nfInstallmentIds.length * 100)}/>
          </div>
        </>
      )}

      {
        completed && props.nfInstallmentIds.length === successfulCalls && (
          <>
            <div style={{textAlign: 'center', marginBottom: '32px', paddingTop: '32px'}}>
              <CheckIcon style={{transform: 'scale(4)', color: 'green'}}/>
            </div>
          </>
        )
      }

      {
        completed && props.nfInstallmentIds.length === failedCalls && (
          <>
            <div style={{textAlign: 'center', marginBottom: '32px', paddingTop: '32px'}}>
              <ErrorIcon style={{transform: 'scale(4)', color: 'red'}}/>
            </div>
          </>
        )
      }

      {
        completed && props.nfInstallmentIds.length > successfulCalls && failedCalls > 0 && successfulCalls > 0 && (
          <>
            <div style={{textAlign: 'center', marginBottom: '32px', paddingTop: '32px'}}>
              <WarningIcon style={{transform: 'scale(4)', color: 'orange'}}/>
            </div>
          </>
        )
      }

      <div style={{textAlign: 'center', marginBottom: '32px'}}>
        <Typography style={{color: '#7924c7 ', fontWeight: 'bold'}}>{completedCalls} de {props.nfInstallmentIds.length} processados</Typography>
      </div>

      {
        completed && (
          <>
            <div style={{textAlign: 'center'}}>
              <Typography style={{
                color: successfulCalls === props.nfInstallmentIds.length ? 'green' : '#7924c7 ',
                fontWeight: successfulCalls === props.nfInstallmentIds.length ? 'bold' : 'normal',
              }}>{successfulCalls} de {props.nfInstallmentIds.length} processados com sucesso.</Typography>
            </div>

            <div style={{textAlign: 'center'}}>
              <Typography style={{
                color: failedCalls ? 'red' : '#7924c7 ',
                fontWeight: failedCalls ? 'bold' : 'normal'
              }}>{failedCalls} de {props.nfInstallmentIds.length} retornaram erro.</Typography>
            </div>

            <div style={{
              marginTop: '32px',
              textAlign: 'center',
            }}>
              <Button
                onClick={props.onCompletedHandler}
                color="info"
                disableElevation
                variant={"contained"}
                startIcon={< Icon > closeIcon</Icon >}
                size={'large'}
                style={{fontSize: '16px'}}
              >
                Fechar
              </Button>
            </div>
          </>
        )
      }

    </Box>
  );
};