export function colorBackGroundProposal(status: string) {
    if (status === 'renovated') { return '#a9e9eb' }
    if (status === 'suspended') { return '#EEC5C5' }
    if (status === 'active') { return '#C5EEC9' }
    if (status === 'expired') { return '#F4ECC0' }
    if (status === 'canceled') { return '#EEC5C5' }
  
    return '#959595';
  }
  export function colorIcon(status: string) {
    if (status === 'expired') { return '#e2d73a' }
    if (status === 'active') { return '#5ebe76' }
    if (status === 'canceled') { return '#eb3232' }
    if (status === 'suspended') { return '#eb3232' }
    if (status === 'renovated') { return '#3761eb' }
    return '#202020';
  }
  
  export function colorText(status: string) {
    if (status === 'expired') { return '#e2c93a' }
    if (status === 'active') { return '#5ebe76' }
    if (status === 'canceled') { return '#eb3232' }
    if (status === 'suspended') { return '#eb3232' }
    if (status === 'renovated') { return '#3761eb' }
  
    return '#202020';
  }
  
  export function translateProposal(traduzir: String) {
    if (traduzir === 'canceled') { return 'Cancelada' }
    if (traduzir === 'pending') { return 'Faturada' }
    if (traduzir === 'open') { return 'Aberto' }
    if (traduzir === 'paid') { return 'Pago' }
  
    if (traduzir === 'active') { return 'Ativa' }
    if (traduzir === 'expired') { return 'Expirada' }
    if (traduzir === 'canceled') { return 'Cancelada' }
    if (traduzir === 'renovated') { return 'Renovada' }
    if (traduzir === 'suspended') { return 'Suspensa' }
  
    return traduzir;
  
  };