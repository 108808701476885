import { useEffect, useState } from "react";
import {
    Typography, Box, useTheme, useMediaQuery, Button, Grid, Paper, TextField, Icon, Tooltip, IconButton
} from "@mui/material";
import { FinancialService, ISummary } from "../../../shared/services/api/financial/FinancialService";
import { useDebounce } from "../../../shared/hooks";
import moment from "moment";
import 'moment/locale/pt-br';
import { useToast } from '../../../shared/hooks';
import { VTextField } from "../../../shared/forms";
import { useParams } from "react-router-dom";
import logo from "../../../assets/logo_extrato.png";
import { useAuthContext, useDrawerContext } from "../../../context";
import { VMaskTextField } from "../../../shared/forms/VMaskTextField";
import InputMask from "react-input-mask";
import { borderColor, fontSize } from "@mui/system";
import { Loading, ToastContent } from "../../../shared/components";
import { Environment } from "../../../shared/environment";



function colorLine(parcela: number) {
    if (parcela % 2 == 0) {
        return '#e8ebee'
    }
    return '#ffffff';
}



const maskCNPJ = (value: string) => {
    let cell = value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    if (cell.length < 1) {
        cell = " ";
    }
    return cell
};

export const SummaryPresentation = () => {
    const { debounce } = useDebounce();
    const [rows, setRows] = useState<ISummary[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();
    const [client, setClient] = useState('');
    const [document, setDocument] = useState('');
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const toastHandler = useToast();
    const { _, id } = useParams();
    const [valid, setValid] = useState(false);
    const [validador, setValidador] = useState('');



    useEffect(() => {
        setIsLoading(true);
        debounce(() => {
            if (valid) {
                FinancialService.getSummary(`${id}`)
                    .then((result) => {
                        
                        setIsLoading(false);
                        if (result instanceof Error) {
                            alert(result.message);
                        } else {
                            setTotalCount(parseInt(result.totalCount));
                            setRows(result.data);
                            setClient(result.data[0].client_name);
                            setDocument(result.data[0].client_document);
                        }
                    });
            }
        });
    }, [valid]);


    const resultado = rows.reduce(function (a: number, b: any) {
        return a + (b.Installment_type_amount != 'discount' ? parseFloat(b.Installment_amount) : 0);
    }, 0);

    const discount = rows.reduce(function (a: number, b: any) {
        return a + (b.Installment_type_amount == 'discount' ? parseFloat(b.Installment_amount) : 0);
    }, 0);

    const saldo = resultado - discount;


    const Input = (props: any) => (
        <InputMask mask="99.999.999/9999-99" value={props.value} onChange={props.onChange} />
    );

    const handleValidarDocument = (value: string) => {
        const documento = value.replace(/[^0-9]/g, "")
        //const ur= 'https://app.garantti.com.br/invoice/valid/'
        const uri = Environment.URL_BASE;
        const ur = `${uri}/invoice/valid/`
        fetch(`${ur}${id}/${documento}`)
            .then(res => res.json())
            .then(data => {
                
                if (data.status == "active") {
                    setValid(true);
                } else {
                    const messageTitleNao = 'Erro na pesquisa'
                    const messageContentNao = 'Documento inválido para abrir o extrato.'
                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={messageTitleNao} content={messageContentNao} />
                    })
                }
            })
            .catch(err => {
                
                const messageTitle = 'Erro na pesquisa'
                const messageContent = 'Erro no processo de busca.'

                toastHandler.present({
                    type: 'error',
                    position: 'top-right',
                    messageOrContent: <ToastContent title={messageTitle} content={messageContent} />
                })
            })
            ;
    }

    const handleExtractPDF = (IID: string) => {
        setIsLoading(true);
        debounce(() => {  //Rogerio vai mexer aqui
            FinancialService.getInvoicesPDF(IID)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        //setModalDetail(true)
                    }
                });
        });
    }


    return (
            valid ?
                <>
                    <Box margin={1}
                        display="flex"
                        flexDirection="column"
                        component={Paper}
                        variant="outlined"
                        marginX={smDown ? 2 : '20%'}

                    >

                        <Grid container item direction="column" padding={2} spacing={2}>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} md={2} >
                                    <Box position={"absolute"}>
                                        <img src={smDown ? '' : logo} alt="logo" width={'50%'} />
                                    </Box>
                                </Grid>
                                <Grid item xs={7}  >
                                    <Typography variant="h6" color={'secondary'} textAlign={"center"}>
                                        Extrato de Garantias da Fatura
                                    </Typography>
                                </Grid>
                                <Grid item xs={11} >
                                    <Typography variant="h6" color={'primary'} textAlign={"center"}>
                                        {client}
                                    </Typography>
                                </Grid>
                                <Grid item xs={11} >
                                    <Typography variant="body1" color={'primary'} textAlign={"center"}>

                                        {document.length > 11 ?
                                            `${document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}`
                                            :
                                            `${document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")}`
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={11} textAlign={"right"} >
                                    <Tooltip title="PDF Extrato" placement="top-start">
                                        <IconButton size="small"
                                            onClick={() => handleExtractPDF(`${id}`)}
                                            sx={{ borderRadius: "18px", color: "#7924c7" }}
                                        >
                                            <Icon sx={{ color: "#7924c7", fontWeight: "normal", mr: '10px' }}> picture_as_pdf_outlined_Icon</Icon>
                                            Baixar PDF
                                        </IconButton>
                                    </Tooltip>
                                </Grid>


                            </Grid>
                        </Grid>
                    </Box>

                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined" padding={2} marginX={smDown ? 2 : '20%'} >
                        {rows.map((rowD, key) => (
                            <Grid container item direction="row" spacing={1} borderBottom={'1px solid #dfdfe6'} mb={1} sx={{ backgroundColor: colorLine(key) }}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Locatário
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rowD.name}
                                    </Typography>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        {rowD.document.length > 11 ?
                                            `${(rowD.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")).substring(0, 3)}`
                                            :
                                            `***${(rowD.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")).substring(3, 12)}**`
                                        }
                                    </Typography>
                                    <Typography variant="subtitle2" color={'text.secondary'}>
                                        {`${rowD.addressStreet != null && rowD.addressStreet != '' ?
                                            `${rowD.addressComplement != null && rowD.addressComplement != '' ?
                                                `${rowD.addressStreet},${rowD.addressNumber} - ${rowD.addressComplement} -
                                            ${rowD.addressDistrict} - ${rowD.addressCity} - ${rowD.addressState} - ${rowD.addressZipcode}`
                                                : `${rowD.addressStreet},${rowD.addressNumber} - 
                                            ${rowD.addressDistrict} - ${rowD.addressCity} - ${rowD.addressState} - ${rowD.addressZipcode}`
                                            } ` : ''
                                            }`
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Vencimento
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {moment(rowD.Installment_due_date).format("L")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} >
                                    <Typography variant="caption" color={
                                        rowD.Installment_type_amount != 'discount' ? 'text.secondary' : 'red'}
                                    >
                                        {rowD.Installment_type_amount != 'discount' ? 'Parcela' : "Desconto"}
                                    </Typography>
                                    <Typography variant="subtitle2" color={
                                        rowD.Installment_type_amount != 'discount' ? 'text.secondary' : 'red'}
                                    >
                                        {rowD.Installment_type_amount != 'discount' ? rowD.Installment_number : rowD.Installment_obs}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} textAlign={"center"} >
                                    <Typography variant="caption" color={
                                        rowD.Installment_type_amount != 'discount' ? 'text.secondary' : 'red'}
                                    >
                                        Valor
                                    </Typography>

                                    {rowD.Installment_type_amount != 'discount' ?
                                        <Typography textAlign={"right"} mr={5} >
                                            {parseFloat(rowD.Installment_amount).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </Typography>
                                        :
                                        <Typography sx={{ color: "red" }} textAlign={"right"} mr={5} >
                                            - {parseFloat(rowD.Installment_amount).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </Typography>
                                    }
                                </Grid>

                            </Grid>
                        ))}
                    </Box>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined" padding={2} marginX={smDown ? 2 : '20%'} >
                        <Grid container item direction="row" spacing={1} mb={1} justifyContent={"end"}>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
                                <Typography variant="body1" color={'secondary'} textAlign={"right"}>
                                    Total da Fatura :
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
                                <Typography variant="body1" color={'secondary'} textAlign={"right"}>
                                    {resultado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={1} mb={1} justifyContent={"end"}>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
                                <Typography variant="body1" color={'primary'} textAlign={"right"}>
                                    Descontos :
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
                                <Typography variant="body1" color={'primary'} textAlign={"right"}>
                                    {discount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={1} mb={1} justifyContent={"end"}>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
                                <Typography variant="body1" color={'secondary'} textAlign={"right"}>
                                    Saldo a pagar :
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
                                <Typography variant="body1" color={'secondary'} textAlign={"right"}>
                                    {saldo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Typography variant="caption" color={'secondary'} textAlign={"left"} mt={3} borderTop={'1px solid #dbdbd4'} paddingTop={2}>
                            As informações existentes nesta mensagem e nos arquivos anexados são para uso restrito,
                            sendo seu sigilo protegido por lei. Caso você a tenha recebido por engano,
                            favor notificar o remetente e, em seguida, apagá-la. Seu uso,
                            cópia ou divulgação não autorizados são expressamente proibidos e
                            serão tratados conforme a legislação vigente. Este ambiente está sujeito a monitoramento.
                        </Typography>
                    </Box>
                </>
                :
                <>
                    <Box margin={1}
                        display="flex"
                        flexDirection="column"
                        component={Paper}
                        variant="outlined"
                        marginX={smDown ? 2 : '20%'}

                    >
                        <Grid container item direction="column" padding={2} spacing={2}>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} md={2} >
                                    <Box position={"absolute"}>
                                        <img src={smDown ? '' : logo} alt="logo" width={'50%'} />
                                    </Box>
                                </Grid>
                                <Grid item xs={7}  >
                                    <Typography variant="h6" color={'secondary'} textAlign={"center"}>
                                        Validação de extrato
                                    </Typography>
                                </Grid>
                                <Grid item xs={11} >
                                    <Typography variant="h6" color={'primary'} textAlign={"center"}>
                                        Informe o documento de validação
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={2} justifyContent={"center"} marginTop={2}>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                                    <InputMask
                                        mask={"99.999.999/9999-99"}
                                        style={{
                                            fontSize: '18px',
                                            border: '1px solid #c1c2c1',
                                            color: "#808486",
                                            padding: 6,
                                            paddingLeft: '16px',
                                            borderRadius: '4px',
                                            fontWeight: "100",
                                            fontFamily: 'roboto'
                                        }}
                                        placeholder='CNPJ'
                                        onChange={(e) => setValidador(e.target.value)}
                                    >
                                    </InputMask>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2} justifyContent={"center"} marginTop={1}>
                                <Button
                                    color="secondary"
                                    disableElevation
                                    variant="outlined"
                                    onClick={() => handleValidarDocument(validador)

                                    }
                                    startIcon={<Icon>check</Icon>}
                                >
                                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        Confirmar
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </>
    );
};