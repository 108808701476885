import { AuthGuard } from '../../shared/guards/AuthGuard';
import { DashboardPresentation } from './components/DashboardPresentation';
import { Sidebar } from '../../shared/components';

export const DashboardPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <DashboardPresentation />
            </Sidebar>
        </AuthGuard>
    )
};