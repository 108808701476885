import { Button, Grid, Paper, Typography, Skeleton } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useNavigate } from "react-router-dom";

interface ProposalRenovatedCardProps {
	rows: any;
	isLoading: boolean;
	smDown: boolean;
}

const ProposalRenovatedCard: React.FC<ProposalRenovatedCardProps> = ({ rows, isLoading, smDown }) => {
	const navigate = useNavigate();
	return (
		<>
			{rows?.renovatedId && (
				<Grid item xs={12} md={12}>
					<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} ml={1}>
						<Grid container direction="row">
							<Grid item xs={12}>
								<Typography variant="h6" color={"secondary"}>
									{isLoading ? <Skeleton width={200} /> : "Garantia Renovada"}
								</Typography>
							</Grid>
						</Grid>

						<Grid container item direction="row" spacing={1} padding={1}>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
								<Typography variant="caption" color={"text.secondary"}>
									Contratada em
								</Typography>
								<Typography variant="subtitle2">
									{isLoading ? (
										<Skeleton width={100} />
									) : rows?.renovatedProposalCreatedAt ? (
										format(new Date(rows.renovatedProposalCreatedAt), "dd/MM/yyyy")
									) : (
										""
									)}
								</Typography>
							</Grid>

							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
								<Typography variant="caption" color={"text.secondary"}>
									Início da Vigência
								</Typography>
								<Typography variant="subtitle2">
									{isLoading ? (
										<Skeleton width={100} />
									) : rows?.renovatedProposalTermBegin ? (
										`${format(new Date(rows.renovatedProposalTermBegin.substring(0, 10) + " 03:00:00"), "dd/MM/yyyy")}`
									) : (
										""
									)}
								</Typography>
							</Grid>

							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
								<Typography variant="caption" color={"text.secondary"}>
									Fim da Vigência
								</Typography>
								<Typography variant="subtitle2">
									{isLoading ? (
										<Skeleton width={100} />
									) : rows?.renovatedProposalExpiresAt ? (
										format(new Date(rows.renovatedProposalExpiresAt), "dd/MM/yyyy")
									) : (
										""
									)}
								</Typography>
							</Grid>
						</Grid>

						<Grid container item direction="row" spacing={1} justifyContent="center">
							<Grid item xs={12}>
								{isLoading ? (
									<Skeleton width={150} height={40} />
								) : (
									<Button
										variant="text"
										onClick={
											!isLoading
												? () => {
														navigate(`/garantias/visualizar/${rows.renovatedId}`);
												  }
												: undefined
										}
									>
										<Typography variant="subtitle2" style={{ color: "#d05f01", fontSize: "14px" }}>
											VISUALIZAR GARANTIA ANTERIOR
										</Typography>
									</Button>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default ProposalRenovatedCard;
