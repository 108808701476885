import moment from "moment";
import { globalBackground, globalColorText, globalMaskDocument, globalTranslate, globalTranslateStatus, maskPhone } from "../../../../shared/utils"
import { Box, Button, Grid, Icon, Paper, Tooltip, Typography } from "@mui/material";

interface IProposalHeaderProps {
    status?: string | null;
    showButtonStatus?: boolean;
    onclickbuttonStatus?: () => void;
    textButtonStatus?: string;
    certificateNumber?: string;
    name?: string | null;
    document?: string;
    typePerson?: string;
    birthDate?: string;
    email?: string;
    phone?: string;
    mobilePhone?: string;
    propertyType?: string;
    addressZipcode?: string;
    addressStreet?: string;
    addressNumber?: string;
    addressComplement?: string;
    addressDistrict?: string;
    addressCity?: string;
    addressState?: string;
    createdAt?: string;
}

export const ProposalHeader: React.FC<IProposalHeaderProps> = ({
    status = '',
    onclickbuttonStatus,
    showButtonStatus = false,
    textButtonStatus = 'Status',
    certificateNumber = '',
    name = '',
    document = '',
    birthDate = '',
    email = '',
    phone = '',
    mobilePhone = '',
    addressZipcode = '',
    addressStreet = '',
    addressNumber = '',
    addressComplement = '',
    addressCity = '',
    addressDistrict = '',
    addressState = '',
    createdAt = ''
}) => {

    return (
        <Box display={'flex'} flexDirection={'column'} component={Paper} width={"95%"} paddingX={3} paddingY={1} ml={4} borderRadius={1} mt={3}>
            <Grid container item direction="row" spacing={1} sx={{ padding: '1px', marginTop: '8px' }}>
                <Grid item md={7} sm={12}>
                    <Typography variant="h6" color={"primary"}>
                        Dados da garantia
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={5} display={"flex"} gap={2} displayPrint={"flex"} justifyContent={'flex-end'}>
                    <Typography variant="h4" color={globalColorText(status ? status : 'active')}>
                        {status ? globalTranslateStatus(status) : ''}
                    </Typography>
                    {showButtonStatus && (
                        <Tooltip title="Alterar Status" placement="top-start">
                            <Button
                                color="primary"
                                disableElevation
                                variant="contained"
                                onClick={onclickbuttonStatus}
                                startIcon={<Icon>cached_Outlined_Icon</Icon>}>
                                {textButtonStatus}
                            </Button>
                        </Tooltip>)}
                </Grid>
            </Grid>

            <Grid container item direction="row" spacing={1} sx={{ padding: '1px', marginTop: '8px' }}>
                <Grid container item direction="row" spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="caption" color={"text.secondary"}>
                            Nome
                        </Typography>
                        <Typography variant="subtitle2">
                            {`${name} (${globalMaskDocument(document)})`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Typography variant="caption" >
                            Nascimento
                        </Typography>
                        <Typography variant="subtitle2">
                            {`${birthDate != "1899-11-30" && birthDate != "" ? moment(birthDate).format("L") : ""}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Typography variant="subtitle2">
                            {createdAt ? moment(createdAt).format("L") : ''}
                        </Typography>
                        <Typography variant="caption" >
                            {"Criada "}
                            {createdAt ? moment(createdAt).startOf("day").fromNow() : ''}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container item direction="row" spacing={1} sx={{ backgroundColor: "#f0f1f1", padding: '1px', marginTop: '8px' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" >
                        Endereço
                    </Typography>
                    <Typography variant="subtitle2">
                        {`${addressStreet != null && addressStreet != ""
                            ? `${addressComplement != null &&
                                addressComplement != ""
                                ? `${addressStreet},${addressNumber} - ${addressComplement} -
                                            ${addressDistrict} - ${addressCity} - ${addressState} - ${addressZipcode}`
                                : `${addressStreet},${addressNumber} - 
                                            ${addressDistrict} - ${addressCity} - ${addressState} - ${addressZipcode}`
                            } ` : ""}`
                        }
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item direction="row" spacing={1} sx={{ padding: '1px', marginTop: '8px' }}>
                <Grid
                    item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography variant="caption" color={"text.secondary"}>
                        Email
                    </Typography>
                    <Typography variant="subtitle2">
                        {email}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography variant="caption" color={"text.secondary"}>
                        Certificado
                    </Typography>
                    <Typography variant="subtitle2">
                        {certificateNumber}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography variant="caption" color={"text.secondary"}>
                        Telefone
                    </Typography>
                    <Typography variant="subtitle2">
                        {maskPhone(mobilePhone)}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}