import {
  Icon,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toolbar } from '../../../shared/components/toolbar/ToolBar';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { PermissionServices } from '../../../shared/services/api/permissions/PermissionService';
import { backgroundColor } from '../../../shared/utils/changeBackgroundColor';
import { changeColorIcon } from '../../../shared/utils/changeColorIcon';
import { changeTextColor } from '../../../shared/utils/changeTextColor';
import { checkByProfileGuard, ProfileGuard } from '../../../shared/guards/ProfileGuard';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';
import { ToolbarGridPermissions } from '../../../shared/components/toolbarGridPermissions/ToolbarGridPermissions';
import { Loading } from '../../../shared/components';

const ListPermissionsPresentation: React.FC = () => {
  const navigate = useNavigate();
  const [permissionsList, setPermissionsList] = useState<any>([]);
  const hasViewPermission = useMemo(() => {
    return checkByProfileGuard(PermissionRolesEnum.PermissionsOptions, PermissionActionsEnum.View)
  }, [])

  const loadPermissions = async () => {
    const response: any = await PermissionServices.getAll();
    const data = response.data;
    setPermissionsList(data);
  }

  useEffect(() => {
    if (hasViewPermission) {
      loadPermissions();
    }
  }, []);

  return (
    <LayoutBaseDePagina
      titulo='Permissões'
      subtitulo='Gerenciamento de Permissões'
    >
      <Toolbar
        textoButtonNew="novo"
        onClickNovo={() => navigate('/permissoes/detalhe/novo')}
        showInputOrigem={false}
        showPeriodo={false}
        showInputClientFilter={checkByProfileGuard(PermissionRolesEnum.PermissionsOptions, PermissionActionsEnum.Filter)}
        showInputStatus={checkByProfileGuard(PermissionRolesEnum.PermissionsOptions, PermissionActionsEnum.Filter)}
        showButtonNew={checkByProfileGuard(PermissionRolesEnum.PermissionsOptions, PermissionActionsEnum.Add)}
      // onChangeStatus={(retorno: string) => setSearchParams({ busca, pagina: String(pagina), tipo: tipoUser, status: retorno }, { replace: true })}
      />

      {!permissionsList?.length && <LinearProgress variant="indeterminate" />  }
      
      <ProfileGuard requiredRole={PermissionRolesEnum.PermissionsOptions} requiredAction={PermissionActionsEnum.View}>
        <TableContainer component={Paper} variant="outlined" sx={{ marginLeft: 1, marginTop: 1, marginRight: 2, width: 'auto', padding: 4 }}>
          <Table aria-label="a dense table" >
            <TableHead>
              <TableRow>
                <TableCell colSpan={7}>
                  <ToolbarGridPermissions
                    showInputSearchGrid={checkByProfileGuard(PermissionRolesEnum.PermissionsOptions, PermissionActionsEnum.Filter)}
                    showButtonListagem={checkByProfileGuard(PermissionRolesEnum.PermissionsOptions, PermissionActionsEnum.Filter)}
                  />
                </TableCell>
              </TableRow>
              <TableRow sx={{ background: '#f5fbfd' }}>
                <TableCell sx={{ color: "#7e7777b5" }}>Descrição</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}>Status</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}>Cadastro</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}>Origem</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}> </TableCell>
              </TableRow>
            </TableHead>

            {
              permissionsList.length > 0 && permissionsList.map((item: any) => {
                return (
                  <>
                    <TableBody>
                      <TableCell sx={{ fontWeight: "normal" }} >
                        <Typography variant="subtitle2" fontWeight={"normal"}>
                          {item.description}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: 'start' }} >
                        <IconButton
                          size="small"
                          sx={{
                            backgroundColor: backgroundColor('active'),
                            borderRadius: '18px',
                            paddingX: '15px',
                            paddingY: '1px',
                            marginBottom: '5px'
                          }}>
                          <Icon
                            sx={{ color: changeColorIcon('active'), fontWeight: 'normal' }}
                          >
                            noise_control_off_sharp_icon
                          </Icon>
                          <Typography
                            variant='subtitle2'
                            marginLeft={'1px'}
                            sx={{ color: changeTextColor('active') }}
                          >
                            Ativo
                          </Typography>
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal" }}>
                          31/10/2022
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={"#959595"}
                          sx={{ fontWeight: "normal" }}
                        >
                          14:00:23
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal" }}>
                          <Tooltip title="Web Aplicação">
                            <Icon sx={{ color: "#959595", fontWeight: "normal" }}> desktop_windows_icon</Icon>
                          </Tooltip>
                        </Typography>
                      </TableCell>
                      <ProfileGuard requiredRole={PermissionRolesEnum.PermissionsOptions} requiredAction={PermissionActionsEnum.Update}>
                        <TableCell sx={{ textAlign: "center" }}>
                          <IconButton
                            size="small"
                            onClick={() => navigate(`/permissoes/detalhe/${item.id}`)}
                            sx={{ borderRadius: "18px" }}
                          >
                            <Icon sx={{ color: "#0170B3", fontWeight: "normal" }}> edit</Icon>
                            <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#0170B3", fontWeight: "normal" }}>
                              Editar
                            </Typography>
                          </IconButton>
                        </TableCell>
                      </ProfileGuard>
                    </TableBody>
                  </>
                )

              })
            }
          </Table>
        </TableContainer>
      </ProfileGuard>

    </LayoutBaseDePagina>
  )
}

export default ListPermissionsPresentation;