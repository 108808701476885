import React, { useEffect, useMemo, useState } from "react";
import {
  Box, Button, Icon, IconButton, LinearProgress, Menu, MenuItem, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ISinisterList, SinisterService } from "../../../shared/services/api/sinister/SinisterService";
import { BasicModal, ModalSinisterCheckList, Toolbar, ToolbarGridSinister } from "../../../shared/components";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { useDebounce } from "../../../shared/hooks";
import { Environment } from "../../../shared/environment";
import moment from "moment";
import 'moment/locale/pt-br';
import { checkByProfileGuard, ProfileGuard } from '../../../shared/guards/ProfileGuard';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';
import { ListSearchParams } from '../../analysis/types/list-search-params.type';
import { ToolbarGridProvision } from "../../../shared/components/toolbarGridProvision/toolbarGridProvision";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IProvisionList, ProvisionService } from "../../../shared/services/api/provisoes/ProvisionService";

function colorBackGround(status: string) {
  if (status === 'created') { return '#ebcea9' }
  if (status === 'in_progress') { return '#a9e9eb' }
  if (status === 'finalized') { return '#C5EEC9' }
  if (status === 'pending') { return '#F4ECC0' }
  if (status === 'canceled') { return '#EEC5C5' }
  return '#959595';
}
function colorIcon(status: string) {
  if (status === 'created') { return '#e4870f' }
  if (status === 'in_progress') { return '#12a6ac' }
  if (status === 'finalized') { return '#12c224' }
  if (status === 'pending') { return '#c4a80d' }
  if (status === 'canceled') { return '#eb3232' }

  return '#202020';
}

function colorText(status: string) {
  if (status === 'created') { return '#e4870f' }
  if (status === 'in_progress') { return '#12a6ac' }
  if (status === 'finalized') { return '#12c224' }
  if (status === 'pending') { return '#c4a80d' }
  if (status === 'canceled') { return '#eb3232' }

  return '#202020';
}

function translate(traduzir: String) {
  if (traduzir === 'in_progress') { return 'Em Andamento' }
  if (traduzir === 'created') { return 'Aberto' }
  if (traduzir === 'finalized') { return 'Finalizado' }
  if (traduzir === 'pending') { return 'Pendente' }
  if (traduzir === 'canceled') { return 'Cancelado' }

  return traduzir;

};

const maskPhone = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, "$1");
};

const maskCell = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, "$0");
};

const maskRealAbs = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{1,2})$/, ",$1")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

const maskReal = (value: string) => {
  const sinal = (Number(value) < 0) ? '-' : '';
  const strValue = value
    .replace(/\D/g, '')
    .replace(/(\d{1,2})$/, ',$1')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  return sinal + strValue;
}
const removeMask = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/[^0-9]/g, "")
    .replace(/(\d{1,2})$/, ".$1");
};

export const SinisterListProvisionPresentation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  const [rows, setRows] = useState<IProvisionList[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const hasViewPermission = useMemo(() => {
    return checkByProfileGuard(PermissionRolesEnum.SinisterOptions, PermissionActionsEnum.View)
  }, [])

  const filterInitialParams: ListSearchParams = useMemo(() => {
    return {
      status: 'Todos',
      origem: 'Todos',
      dataIni: moment().startOf('month').subtract(24, 'months').format("YYYY-MM-DD"),
      dataFim: moment().endOf('month').format("YYYY-MM-DD")
    }
  }, [])

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      dataini: filterInitialParams.dataIni,
      datafim: filterInitialParams.dataFim,
      status: filterInitialParams.status
    }, { replace: true })
  }, [])

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);


  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  // useEffect(() => {
  //   if (hasViewPermission) {
  //     setIsLoading(true);
  //     debounce(() => {
  //       ProvisionService.getAll(pagina, busca)
  //         .then((result) => {
  //           setIsLoading(false);
  //           if (result instanceof Error) {
  //             alert(result.message);
  //           } else {
  //             console.log(result);
  //             setTotalCount(result.totalCount);
  //             setRows(result.data);
  //           }
  //         });
  //     });
  //   }
  // }, [busca, pagina]);

  return (
    <LayoutBaseDePagina
      titulo="Provisionamento de sinistros"
      subtitulo="Listagem de Provisões"
      barraDeFerramentas={
        <Toolbar
          filterInitialParams={filterInitialParams}
          textoButtonNew="novo"
          showInputClientFilter={checkByProfileGuard(PermissionRolesEnum.SinisterOptions, PermissionActionsEnum.Filter)}
          showInputOrigem={checkByProfileGuard(PermissionRolesEnum.SinisterOptions, PermissionActionsEnum.Filter)}
          showPeriodo={checkByProfileGuard(PermissionRolesEnum.SinisterOptions, PermissionActionsEnum.Filter)}
          showInputBusca={false}
          showButtonNew={false}
          // onClickNovo={() => navigate('/sinistros/detalhe/novo')}
          showInputStatus={false}
        />
      }
    >

      <ProfileGuard requiredRole={PermissionRolesEnum.SinisterOptions} requiredAction={PermissionActionsEnum.View}>
        <TableContainer component={Paper} variant="outlined" sx={{ marginLeft: 1, marginTop: 1, marginRight: 2, width: 'auto', padding: 4 }}>
          <Table aria-label="a dense table"  >
            <TableHead>
              <TableRow>
                <TableCell colSpan={7} >
                  <ToolbarGridProvision
                    showInputBuscaGrid={checkByProfileGuard(PermissionRolesEnum.SinisterOptions, PermissionActionsEnum.Filter)}
                    textSearchGrid={busca}
                    onChangeTextBuscaGrid={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}
                  />
                </TableCell>
              </TableRow>

              <TableRow sx={{ background: '#f5fbfd' }} >
                <TableCell sx={{ color: "#7e7777b5" }}> Nome</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}> Parceiro</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}> Valor</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}> Abertura</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}> Aprovação</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}> Data Limite</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}> Status</TableCell>
                <TableCell sx={{ color: "#7e7777b5", width: "5px" }}> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id} hover>
                  <TableCell sx={{ fontWeight: "normal" }}>
                    {row.sinister.proposal.name}
                    <Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} >
                      {row?.sinister.proposal.document.length > 11 ?
                        `${row?.sinister.proposal.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}`
                        :
                        `${row?.sinister.proposal.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")}`
                      }
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
                      {row.client.name}
                    </Typography>
                    <Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} >
                      {row?.client.document.length > 11 ?
                        `${row?.client.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}`
                        :
                        `${row?.client.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")}`
                      }
                    </Typography>
                  </TableCell>

                  <TableCell >
                    <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
                      {maskReal(row.value ? row.value : '0.00')}
                    </Typography>

                  </TableCell>

                  <TableCell >
                    <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
                      {moment(row.createdAt).format("L")}
                    </Typography>
                    <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
                      {moment(row.createdAt).format("hh:mm:ss")}
                    </Typography>
                  </TableCell>

                  <TableCell sx={{ color: "#959595", fontWeight: "normal" }} >
                    <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
                      {moment(row.createdAt).format("L")}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ color: "#959595", fontWeight: "normal" }} >
                    <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
                      {moment(row.createdAt).format("L")}
                    </Typography>
                  </TableCell>
                  < TableCell sx={{ textAlign: "start" }}>
                    <IconButton size="small" sx={{ background: colorBackGround(row.status), borderRadius: "18px", paddingX: "15px", paddingY: "1px", marginBottom: "5px" }}>
                      <Icon sx={{ color: colorIcon(row.status), fontWeight: "normal" }}> noise_control_off_sharp_icon</Icon>
                      <Typography variant="subtitle2" marginLeft={1} sx={{ color: colorText(row.status), fontWeight: "normal" }}>
                        {translate(row.status)}
                      </Typography>
                    </IconButton>
                  </TableCell>


                  <TableCell sx={{ textAlign: "center", display: "flex" }} >

                    <Tooltip title="Visualizar Itens do pagamento" placement="top-start">
                      <IconButton size="small" onClick={handleClose} sx={{ borderRadius: "18px" }}>
                        <InfoOutlinedIcon sx={{ color: "#0170B3", fontWeight: "normal" }} />
                      </IconButton>
                    </Tooltip>


                    <Tooltip title="" placement="top-start">
                      <div>
                        <Button
                          id="demo-positioned-button"
                          aria-controls={open ? 'demo-positioned-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                        >
                          {/* <IconButton size="small" sx={{ borderRadius: "18px" }} onClick={() => { }}> */}
                          <Icon sx={{ color: "#0170B3", fontWeight: "normal" }}> more_horiz</Icon>
                          <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#0170B3", fontWeight: "normal" }}>
                          </Typography>
                          {/* </IconButton> */}
                        </Button>

                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <MenuItem onClick={handleClose} sx={{ color: "green" }} >Pagar</MenuItem>
                          <MenuItem onClick={handleClose} sx={{ color: "orange" }} >Provisionar</MenuItem>
                          <MenuItem onClick={handleClose} sx={{ color: "red" }} >Re-Provisionar</MenuItem>
                        </Menu>
                      </div>

                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}

            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <LinearProgress variant="indeterminate" />
                  </TableCell>
                </TableRow>
              )}
              {(totalCount > 0) && (
                <TableRow>
                  <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / Environment.LIMITE_LINHAS)}
                      onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                    />
                  </TableCell>
                  <TableCell colSpan={5} sx={{ borderBottom: "none" }} >
                    <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                      Exibindo {" "}
                      {(pagina * Environment.LIMITE_LINHAS) - (Environment.LIMITE_LINHAS - 1)}{" - "}
                      {(Environment.LIMITE_LINHAS * (pagina - 1)) +
                        (Environment.LIMITE_LINHAS * pagina) < totalCount ? Environment.LIMITE_LINHAS * pagina : totalCount} de
                      <Typography variant="overline" sx={{ fontWeight: "bold", color: "#0170B3", fontSize: "16px" }}>
                        {" "}{totalCount} {" "}
                      </Typography>
                      registro(s)
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </TableContainer>
      </ProfileGuard>
    </LayoutBaseDePagina >
  );
};