import { CircularProgress, Icon, Input, useTheme } from '@mui/material';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {StyledComponentPropsType} from '../../../../shared/types';
import {useToast} from '../../../../shared/hooks';
                       
import {
  StyledGreyedTypography,
  StyledIconBox,
  StyledNormalTypography,
  StyledSpinnerBox,
  StyledUploadAreaBox,
  StyledUploadAreaContainerBox,
  StyledUploadedFileBox,
  StyledUploadedFileContainerBox,
  StyledUploadedFileIconBox
} from './SinisterFileUploadArea.styled';
import {getFileExtensionIcon} from '../../helpers';

type SinisterFilesUploadAreaPropsType = StyledComponentPropsType & {
  onFileUploadHandler: (file: File | undefined) => void
  isLoadingProp: boolean
  clear: boolean
}

const allowedFileSize = 50 * 1024 * 1024;
export const SinisterFilesUploadArea = ({ className, onFileUploadHandler, isLoadingProp, clear }: SinisterFilesUploadAreaPropsType) => {
  const [fileState, setFileState] = useState<File | ''>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fileInputRef = useRef<any>(null)
  const uploadAreaRef = useRef<any>(null)
  const theme = useTheme()
  const toastHandler = useToast();
  const allowedFileExtensionsForLabel = ['PNG', 'JPG', 'JPEG', 'DOC', 'DOCX', 'PDF', 'ODT']

  useEffect(() => {
    if (clear) {
      clearFile()
    }
  }, [clear])

  const areaHeight = useMemo(() => {
    if (uploadAreaRef?.current) {
      return uploadAreaRef.current.clientHeight
    }
  }, [uploadAreaRef])
  const handleChangeFile = (event: any) => {
    const file = event.target?.files[0]

    const errorsList = validate(file);

    if (errorsList.length === 0) {
      if (file) {
        setFile(file)
      }
    } else {
      errorsList.forEach((error) => {
        toastHandler.present({messageOrContent: error, type: 'error', position: 'top-center'})
      })
      clearFile()
    }
  };

  const loading = useMemo(() => {
    return isLoadingProp || isLoading
  }, [isLoadingProp, isLoading])

  const clearFile = useCallback(() => {
    setFileState('');
    if (fileInputRef) {
      fileInputRef.current.value = ''
    }
    onFileUploadHandler(undefined);
  }, [fileInputRef])

  const setFile = useCallback((file: File) => {
    setFileState(file);
    onFileUploadHandler(file);
  }, [])

  const handleClick = (event: any) => {
    if (fileInputRef) {
      fileInputRef.current?.click()
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  };

  return (
    <>
      {!fileState && (
        <StyledUploadAreaContainerBox hide={loading} className={className}>
          <StyledUploadAreaBox ref={uploadAreaRef}  onClick={loading ? () => {
          } : handleClick} hide={loading} >

            <StyledSpinnerBox hide={!loading}>
              <CircularProgress variant='indeterminate'/>
            </StyledSpinnerBox>

            <StyledGreyedTypography hide={loading} style={{
              textAlign: 'center',
              color: 'grey',
              marginBottom: '16px'
            }}>
              Clique para realizar o carregamento do arquivo
            </StyledGreyedTypography>

            <StyledNormalTypography hide={loading} style={{
              textAlign: 'center',
              fontSize: '1rem',
              fontWeight: 'bold'
            }}>
              Tamanho máximo:
            </StyledNormalTypography>
            <StyledNormalTypography hide={loading} style={{
              textAlign: 'center',
              color: 'grey',
              fontSize: '0.9rem',
            }}>
              {`${(allowedFileSize / (1024 * 1024)).toFixed(2)}MB`}
            </StyledNormalTypography>

            <StyledIconBox hide={loading}>
              <Icon style={{
                fontSize: "5rem"
              }}>backup</Icon>
            </StyledIconBox>

            <StyledNormalTypography hide={loading} style={{
              textAlign: 'center',
              fontSize: '1rem',
              fontWeight: 'bold'
            }}>
              Tipos suportados:
            </StyledNormalTypography>
            <StyledNormalTypography hide={loading} style={{
              textAlign: 'center',
              color: 'grey',
              fontSize: '0.9rem'
            }}>
              {allowedFileExtensionsForLabel.join(', ')}
            </StyledNormalTypography>
          </StyledUploadAreaBox>
        </StyledUploadAreaContainerBox>
      )}
      {fileState && (
        <StyledUploadedFileContainerBox hide={loading}>
          <StyledSpinnerBox hide={!loading}>
            <CircularProgress variant='indeterminate'/>
          </StyledSpinnerBox>

          <StyledUploadedFileBox height={areaHeight} hide={loading} onClick={loading ? () => {
          } : handleClick}>

            <StyledNormalTypography hide={loading} style={{
              textAlign: 'center',
              fontSize: '1.2rem',
              fontWeight: 'bold'
            }}>
              {fileState.name}
            </StyledNormalTypography>

            <StyledUploadedFileIconBox hide={loading} style={{
              height: '5rem',
              width: '5rem'
            }}>
              {getFileExtensionIcon(fileState.type)}
            </StyledUploadedFileIconBox>

            <StyledNormalTypography hide={loading} style={{
              textAlign: 'center',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              marginTop: '16px'
            }}>
              Tamanho:
            </StyledNormalTypography>
            <StyledNormalTypography hide={loading} style={{
              textAlign: 'center',
              color: 'grey',
              fontSize: '1rem',
            }}>
              {`${(fileState.size / (1024 * 1024)).toFixed(2)}MB`}
            </StyledNormalTypography>
          </StyledUploadedFileBox>

        </StyledUploadedFileContainerBox>
      )}
      <Input type='file' inputRef={fileInputRef} onChange={handleChangeFile} style={{
        visibility: 'hidden',
        position: 'fixed',
        zIndex: -1
      }}></Input>
    </>
  )
}

const validate = (file: File): string[] => {
  const errors: string[] = []
  const allowedMimetypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text',
    'image/jpeg',
    'image/jpg',
    'image/png'
  ];

  if (file.size > allowedFileSize) {
    errors.push(`Tamanho do arquivo excede ${(allowedFileSize / (1024 * 1024)).toFixed(2)}MB`)
  }

  if (!allowedMimetypes.includes(file.type)) {
    errors.push('Tipo de arquivo inválido')
  }

  return errors
}



