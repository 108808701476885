import { AuthGuard } from '../../shared/guards/AuthGuard';
import { GraphicPresentation } from './components';
import { Sidebar } from '../../shared/components';

export const DashboardGraphic = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <GraphicPresentation />
            </Sidebar>
        </AuthGuard>
    )
};