import { AuthGuard } from '../../shared/guards/AuthGuard';
import { SummaryPresentation } from './components';
import { Sidebar } from '../../shared/components';

export const SummaryPage = (): JSX.Element => {
    return (
        // <AuthGuard>
            // <Sidebar>
                <SummaryPresentation />
            // </Sidebar>
        // </AuthGuard>
    )
};