import { Grid, Paper, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ToastContent } from "../../../../../shared/components";
import { useToast } from "../../../../../shared/hooks";
import { clientService } from "../../../../../shared/services/api/client/clientService";
import { IDetalheUsuario, userService } from "../../../../../shared/services/api/user/userService";
import { globalFormatDates } from "../../../../../shared/utils";
import { returnMessageError } from "../../../../../shared/utils/returnMessageError";

interface ProposalUserProps {
	rows: any;
	isLoading: boolean;
	smDown: boolean;
}

const ProposalUserCard: React.FC<ProposalUserProps> = ({ rows, isLoading, smDown }) => {
	const [user, setUser] = useState<IDetalheUsuario>();
	const toastHandler = useToast();
	const previousOrigemRef = useRef<string | undefined>(); // Para armazenar o valor anterior de origem

	// Extração de `origem` corretamente
	const origem = rows?.createdFrom || "";

	// Função auxiliar para exibir toast de erro
	const showToastError = (titleMessage: string, contentMessage: string) => {
		toastHandler.present({
			type: "error",
			position: "top-right",
			messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
		});
	};

	// Função auxiliar para tratar erros genéricos
	const handleError = (err: any) => {
		const { titleMessage, contentMessage } = returnMessageError(err);
		showToastError(titleMessage, contentMessage);
	};

	// useEffect para buscar os dados do usuário ou cliente com base na origem (web_app ou web_service)
	useEffect(() => {
		// Verifica se a origem não é vazia e se mudou desde a última execução
		if (origem && origem !== previousOrigemRef.current) {
			previousOrigemRef.current = origem; // Atualiza o valor anterior com o valor atual de origem

			if (origem === "web_app") {
				// Verificação do web app
				if (!rows?.userId) return; // Se o ID do usuário não estiver disponível, não prosseguir

				userService
					.getByID(`${rows.userId}`)
					.then((response: any) => {
						if (response instanceof Error) {
							showToastError("Alerta!!", "Erro: " + response?.message);
						} else {
							setUser(response);
						}
					})
					.catch((err: any) => handleError(err));
			} else if (origem === "web_service") {
				// Integração 4seg
				if (!rows?.clientId) return;

				clientService
					.getPartners(rows.clientId)
					.then((response: any) => {
						if (response instanceof Error) {
							showToastError("Alerta!!", "Erro: " + response?.message);
						} else {
							setUser(response?.data[0]);
						}
					})
					.catch((err: any) => handleError(err));
			}
		}
	}, [origem, rows?.userId, rows?.clientId]); // Dependências atualizadas: 'origem', 'userId' e 'clientId'

	return (
		<Grid item xs={12} md={12}>
			<Grid container display={"flex"} flexDirection={"row"} component={Paper} padding={2} ml={1}>
				<Grid container sm={12} md={12} direction="row" spacing={1}>
					<Grid item xs={11}>
						<Typography variant="h6" color={"primary"}>
							Dados Usuário
						</Typography>
					</Grid>
				</Grid>

				<Grid container item direction="row" spacing={1}>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color="text.secondary">
							Email
						</Typography>
						<Typography variant="subtitle2">
							{isLoading ? <Skeleton width={150} /> : user?.email || "Não informado"}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color="text.secondary">
							Nome
						</Typography>
						<Typography variant="subtitle2">
							{isLoading ? <Skeleton width={150} /> : user?.name || "Não informado"}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color="text.secondary">
							Última atualização
						</Typography>
						<Typography variant="subtitle2">
							{isLoading ? (
								<Skeleton width={150} />
							) : rows?.updatedAt ? (
								globalFormatDates(rows.updatedAt)
							) : rows?.createdAt ? (
								globalFormatDates(rows.createdAt)
							) : (
								"Não informada"
							)}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ProposalUserCard;
