import { LinearProgress, Pagination, TableCell, TableFooter, TableRow, Typography } from "@mui/material";

interface PaginationTableProps {
	totalCount: number;
	isLoading: boolean;
	pagina: number;
	pageSize: number;
	onchange: (pagina: number) => void;
}

export const PaginationTable: React.FC<PaginationTableProps> = ({ totalCount, isLoading, pagina, pageSize, onchange }) => {
	return (
		<>
			{totalCount === 0 && !isLoading && <caption>Nenhum registro listado !!</caption>}
			<TableFooter>
				{isLoading && (
					<TableRow>
						<TableCell colSpan={12}>
							<LinearProgress variant="indeterminate" sx={{ height: "8px", borderRadius: "6px" }} />
						</TableCell>
					</TableRow>
				)}
				{totalCount > 0 && (
					<TableRow>
						<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
							<Pagination page={pagina} count={Math.ceil(totalCount / Number(pageSize))} onChange={(_, newPage) => onchange(newPage)} />
						</TableCell>
						<TableCell colSpan={4} sx={{ borderBottom: "none" }}>
							<Typography variant="body1" sx={{ fontWeight: "normal" }}>
								Exibindo {pagina * Number(pageSize) - (Number(pageSize) - 1)}
								{" - "}
								{Number(pageSize) * (pagina - 1) + Number(pageSize) * pagina < totalCount ? Number(pageSize) * pagina : totalCount} de
								<Typography
									variant="overline"
									color={"primary"}
									sx={{
										fontWeight: "bold",
										fontSize: "16px",
									}}
								>
									{" "}
									{totalCount}{" "}
								</Typography>
								registro(s)
							</Typography>
						</TableCell>
					</TableRow>
				)}
			</TableFooter>
		</>
	);
};
