
import * as React from 'react';
import Modal from '@mui/material/Modal';
import { VForm, useVForm } from "../../forms";
import { useState } from "react";
import {
    Box, Grid, Icon, LinearProgress,
    Typography, Button, DialogActions, DialogContent, DialogTitle,
} from "@mui/material";
import { IListagemUsuario, userService } from "../../services/api/user/userService";

import { ToastContent } from '..';
import { useToast } from '../../hooks';
import { useDebounce } from "../../hooks";
import { returnMessageError } from '../../utils/returnMessageError';
import { SinisterService } from '../../services/api/sinister/SinisterService';
import { AutoCompleteSinisterResponsible } from '../autoCompleteSinisterResponsible/AutoCompleteSinisterResponsible';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '1px solid #605e5e',
    boxShadow: 24,
    p: 2,
    borderRadius: '6px'
};

const typeMessage: ITypeMessage[] = [
    {
        type: 'error',
        title: 'Erro',
        icon: 'cancel_Outlined_Icon',
        color: '#e87b9e'
    },
    {
        type: 'alert',
        title: 'Atenção',
        icon: 'info_Outlined_Icon',
        color: '#ffc107'
    },
    {
        type: 'success',
        title: 'Sucesso',
        icon: 'check_circle_Outline_Icon',
        color: '#39c463'
    },
    {
        type: 'info',
        title: 'Informação',
        icon: 'error_outline_Outlined_Icon',
        color:  '#7924c7'
        // color: '#3f51b5'
    },
];

interface ITypeMessage {
    title: string;
    icon: string;
    color: string;
    type: string;
}

interface IResponsibleModalProps {
    textButtonConfirm?: string;
    showButtonCancel?: boolean;
    isOpenModal: boolean;
    messageType: string;
    onclickConfirm?: () => void;
    isCloseModal: (isAddResponsible?: boolean) => void;
    responsibles: IListagemUsuario[];
    idSinister: string;
}

export const ResponsibleModal: React.FC<IResponsibleModalProps> = ({ textButtonConfirm,
    showButtonCancel, isOpenModal, messageType, responsibles, isCloseModal, onclickConfirm, idSinister }) => {
    showButtonCancel = true;
    textButtonConfirm = 'Adicionar/Alterar';

    const setTypeMessage = () => {
        switch (messageType) {
            case 'error':
                return typeMessage[0];
            case 'alert':
                return typeMessage[1];
            case 'success':
                return typeMessage[2];
            case 'info':
                return typeMessage[3];
            default: return typeMessage[0];
        }
    }

    const { formRef } = useVForm();
    const messageStyle: ITypeMessage = setTypeMessage();
    const [isLoading, setIsLoading] = useState(false);
    const [userSelected, setUserSelected] = useState<IListagemUsuario | null>(null);
    const toastHandler = useToast();
    const { debounce } = useDebounce();

    const handleSave = () => {

        if (userSelected) {
            setIsLoading(true);
            debounce(() => {
                  SinisterService.updateResponsible( idSinister, userSelected.id )
                //    userService.getManagers("", idSinister)
                    .then((result: any) => {
                        setIsLoading(false);
                        if (result instanceof Error) {
                            const titleMessage = "Alerta!!";
                            const contentMessage = "Erro: " + result?.message;

                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            })
                        } else {
                            const titleMessage = "Sucesso";
                            const contentMessage = "Gestor adicionado com sucesso!";

                            toastHandler.present({
                                type: 'success',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            });
                            isCloseModal(true);
                            setUserSelected(null);
                        }

                    }).catch((err: any) => {
                        setIsLoading(false);
                        const { titleMessage, contentMessage } = returnMessageError(err);
                        
                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    })
            });
        }
    }

    return (
        <div>
            <Modal
                open={isOpenModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Icon style={{ color: `${messageStyle.color}`, fontSize: "3rem", fontWeight: 100 }}>
                            {messageStyle.icon}
                        </Icon>
                        <Typography
                            variant='caption'
                            color={`${messageStyle.color}`}
                            marginBottom="1rem"
                        >
                            {messageStyle.title}
                        </Typography>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            color={`${messageStyle.color}`}
                        >
                           Designa/Altera Responsável pelo Sinistro
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2, mb: 2, color: "#7d7d7d", width: "100%" }}
                        >
                            <VForm 
                             id="formIdSave"
                             placeholder="Your placeholder"
                             onPointerEnterCapture={() => console.log("Pointer entered")}
                             onPointerLeaveCapture={() => console.log("Pointer left")}
                            ref={formRef} onSubmit={handleSave}>
                                <Grid container direction="column" padding={1} spacing={2} >
                                    {isLoading && (
                                        <Grid item>
                                            <LinearProgress variant="indeterminate" />
                                        </Grid>
                                    )}
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <AutoCompleteSinisterResponsible
                                                isExternalLoading={isLoading}
                                                labelSelect={'Responsável pelo Sinistro'}
                                                responsibles={responsibles}
                                                idSinister={idSinister}
                                                selected={(user: IListagemUsuario | null) => {
                                                    setUserSelected(user);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </VForm>
                        </Typography>
                    </DialogContent>
                    <DialogActions
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Button
                            color="primary"
                            disableElevation
                            variant={"contained"}
                            startIcon={< Icon > checkIcon</Icon >}
                            onClick={() => {
                                formRef.current?.submitForm();
                                onclickConfirm ? onclickConfirm() : false;
                            }}
                        >
                            {textButtonConfirm}
                        </Button>
                        {showButtonCancel && (
                            <Button
                                onClick={() => {
                                    isCloseModal();
                                    setUserSelected(null);
                                }}
                                color="primary"
                                disableElevation
                                variant={"outlined"}
                                startIcon={< Icon > closeIcon</Icon >}
                            >
                                Cancelar
                            </Button>
                        )}
                    </DialogActions>
                </Box>
            </Modal>
        </div>
    )
}
