import { Grid, Paper, Typography, Skeleton } from "@mui/material";
import React from "react";
import { globalFormatDates, globalMaskDocument, globalTranslateMaritalStatus, globalTranslateProfession, maskPhone } from "../../../../../shared/utils";

interface ProposalTenantCardProps {
	rows: any;
	isLoading: boolean;
	smDown: boolean;
}

const ProposalTenantCard: React.FC<ProposalTenantCardProps> = ({ rows, isLoading, smDown }) => {
	// Função para alternar as cores de fundo (índices ímpares e pares)
	const getBackgroundColor = (index: number) => (index % 2 === 0 ? "white" : "#f0f1f1");

	// Dados organizados em containers para alternância de cores
	const gridContainers = [
		{
			content: (
				<>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Nome
						</Typography>
						<Typography variant="subtitle2">{isLoading ? <Skeleton width={150} /> : rows?.name}</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Documento
						</Typography>
						<Typography variant="subtitle2">{isLoading ? <Skeleton width={100} /> : globalMaskDocument(`${rows?.document}`)}</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Nascimento
						</Typography>
						<Typography variant="subtitle2">{isLoading ? <Skeleton width={100} /> : globalFormatDates(rows?.birthDate)}</Typography>
					</Grid>
				</>
			),
		},
		{
			content: (
				<>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Nome da Mãe
						</Typography>
						<Typography variant="subtitle2">{isLoading ? <Skeleton width={150} /> : rows?.motherName}</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Relação de trabalho
						</Typography>
						<Typography variant="subtitle2">
							{isLoading ? <Skeleton width={150} /> : globalTranslateProfession(`${rows?.employmentRelationShip}`)}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Estado Civil
						</Typography>
						<Typography variant="subtitle2">
							{isLoading ? <Skeleton width={100} /> : globalTranslateMaritalStatus(`${rows?.maritalStatus}`)}
						</Typography>
					</Grid>
				</>
			),
		},
		{
			content: (
				<>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Celular
						</Typography>
						<Typography variant="subtitle2">{isLoading ? <Skeleton width={100} /> : maskPhone(`${rows?.phone}`)}</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Telefone
						</Typography>
						<Typography variant="subtitle2">{isLoading ? <Skeleton width={100} /> : maskPhone(`${rows?.mobilePhone}`)}</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Email
						</Typography>
						<Typography variant="subtitle2">{isLoading ? <Skeleton width={200} /> : rows?.email}</Typography>
					</Grid>
				</>
			),
		},
	];

	return (
		<Grid item xs={12} md={12}>
			<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} ml={1}>
				<Grid container direction="row">
					<Grid item xs={12}>
						<Typography variant="h6" color={"primary"}>
							Dados Inquilino
						</Typography>
					</Grid>
				</Grid>

				{/* Renderizando as linhas com cores alternadas */}
				{gridContainers.map((container, index) => (
					<Grid container item direction="row" padding={0.5} key={index} sx={{ backgroundColor: getBackgroundColor(index) }}>
						{container.content}
					</Grid>
				))}
			</Grid>
		</Grid>
	);
};

export default ProposalTenantCard;
