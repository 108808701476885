import React from 'react';
import {Box, styled, Typography} from '@mui/material';

export const StyledSinisterValuesFileItemContainer = styled(Box)(() => {
  return {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    marginLeft: '8px',
    '& > *:not(:last-child)': {
      borderRight: '3px solid whitesmoke'
    }
  }
})

export const StyledFileBox = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 1 0px'
  }
})

export const StyledFileIconBox = styled(Box)(() => {
  return {
    '& svg': {
      width: '100% !important',
      height: '100% !important'
    },
    borderBottom: '1px solid whitesmoke'
  }
})

export const StyledFileMetadataBox = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: '3 1 0px',
    padding: '0 8px',
    '& > *': {
      marginBottom: '4px'
    },
    lineBreak: 'anywhere'
  }
})

export const StyledTypography = styled(Typography)`
  & {
    line-break: anywhere !important;
  }
`

export const StyledFileOperationsBox = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 0px'
  }
})

export const StyledOperationIconBox = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity linear 0.25s',
    '&:hover': {
      opacity: 0.75,
      cursor: 'pointer'
    }
  }
})