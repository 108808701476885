export const colorBackGroundStatus = (status: string = '') => {
  if (status === "pending") return "#f8e8d2";
  if (status === "active") return "#d9f8e5";
  if (status === "inactive") return "#fce4e4";
  return "#959595";
};

export const colorIconStatus = (status: string = '') => {
  if (status === "pending") return "#f5ba5c";

  if (status === "active") return "#34D399";

  if (status === "inactive") return "#F87171";

  return "#202020";
};
export const colorTextStatus = (status: string = '') => {
  if (status === "pending") return "#e48509";

  if (status === "active") return "#0f8a0d";

  if (status === "inactive") return "#e40909";

  return "#202020";
};

export const translateStatusClient = (status: String = '') => {
  if (status === "pending") return "Pendente";

  if (status === "active") return "Ativo";

  if (status === "inactive") return "Inativo";

  return status;
};
