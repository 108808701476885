import {AuthGuard} from '../../shared/guards/AuthGuard';
import {ClientDetailPresentation} from './components';
import {Sidebar} from '../../shared/components';

export const ClientDetailPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <ClientDetailPresentation/>
            </Sidebar>
        </AuthGuard>
    )
};