import { useNavigate, useSearchParams } from 'react-router-dom';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { useDebounce, useToast } from '../../../shared/hooks';
import { ToastContent } from '../../../shared/components';
import { returnMessageError } from '../../../shared/utils/returnMessageError';
import {
  PermissionActionsEnum,
  PermissionRolesEnum,
} from '../../../shared/enums/profiles';
import {
  ProfileGuard,
  checkByProfileGuard,
} from '../../../shared/guards/ProfileGuard';
import { Environment } from '../../../shared/environment';
import moment from 'moment';
import {
  globalColorText,
  globalTranslate,
  globalTranslateStatus,
} from '../../../shared/utils';
import { FinancialService } from '../../../shared/services/api/financial/FinancialService';
import { IReportProposalList } from '../../../shared/services/api/proposal/ProposalService';
import { ListSearchParams } from '../../analysis/types/list-search-params.type';
import { ToolbarLifecycleProposalFilterReport } from '../../../shared/components/toolbar/report/toolbarLifecycleProposalFilterReport';
function colorText(status: string) {
  if (status === 'expired') {
    return '#e2c93a';
  }
  if (status === 'active') {
    return '#5ebe76';
  }
  if (status === 'canceled') {
    return '#eb3232';
  }
  if (status === 'suspended') {
    return '#eb3232';
  }
  if (status === 'renovated') {
    return '#3761eb';
  }

  return '#202020';
}

function colorBackGround(status: string) {
  if (status === 'renovated') {
    return '#a9e9eb';
  }
  if (status === 'suspended') {
    return '#EEC5C5';
  }
  if (status === 'active') {
    return '#C5EEC9';
  }
  if (status === 'expired') {
    return '#F4ECC0';
  }
  if (status === 'canceled') {
    return '#EEC5C5';
  }

  return '#959595';
}
function colorIcon(status: string) {
  if (status === 'expired') {
    return '#e2d73a';
  }
  if (status === 'active') {
    return '#5ebe76';
  }
  if (status === 'canceled') {
    return '#eb3232';
  }
  if (status === 'suspended') {
    return '#eb3232';
  }
  if (status === 'renovated') {
    return '#3761eb';
  }
  return '#202020';
}

function colorLine(parcela: number) {
  if (parcela % 2 == 0) {
    return '#e5e8eb';
  }
  return '#ffffff';
}

export const ReportLifecycleProposalPresentation = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const toastHandler = useToast();
  const { debounce } = useDebounce();
  const [isLoading, setIsLoading] = useState(false);
  const [dataIni, setDataIni] = useState(
    moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD')
  );
  const [dataFim, setDataFim] = useState(
    moment().endOf('month').format('YYYY-MM-DD')
  );
  const [rows, setRows] = useState<IReportProposalList[]>([]);
  const [rowsSynthetic, setRowsSynthetic] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowPages, setRowPages] = useState(Environment.LIMITE_LINHAS);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [dataClientFilter, setDataClientFilter] = useState('');
  const [dataManagerFilter, setDataManagerFilter] = useState('');
  const [dataOrigemFilter, setDataOrigemFilter] = useState('');

  const [reportType, setReportType] = useState(2);
  const [status, setStatus] = useState('Todos');
  const [document, setDocument] = useState('');

  const hasViewPermission = useMemo(() => {
    return checkByProfileGuard(
      PermissionRolesEnum.WarrantiesOptions,
      PermissionActionsEnum.View
    );
  }, []);

  const [pesquisar, setPesquisar] = useState(true);

  const filterInitialParams: ListSearchParams = useMemo(() => {
    return {
      status: 'Todos',
      origem: 'Todos',
      tipo: 'documento',
      dataIni: moment()
        .startOf('month')
        .subtract(24, 'months')
        .format('YYYY-MM-DD'),
      dataFim: moment().endOf('month').format('YYYY-MM-DD'),
      typePeriod: 'contratacao',
    };
  }, []);

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  useMemo(() => {
    const page = searchParams.get('pagina') ? searchParams.get('pagina') : '1';
    return Number(page);
  }, [searchParams]);

  const clickSearchUpdateProposal = () => {
    setIsLoading(true);
    setPesquisar(!pesquisar);
  };

  const clickExportCsv = async () => {
    setIsLoading(true);
    debounce(() => {
      FinancialService.getReportLifecycleProposal(
        page,
        rowPages,
        dataClientFilter,
        dataIni,
        dataFim,
        dataManagerFilter,
        dataOrigemFilter,
        false,
        reportType,
        status,
        document
      )

        .then((data) => {
          setIsLoading(false);
          if (data instanceof Error) {
            const titleMessage = 'Alerta!!';
            const contentMessage = 'Erro: ' + data?.message;

            toastHandler.present({
              type: 'error',
              position: 'top-right',
              messageOrContent: (
                <ToastContent title={titleMessage} content={contentMessage} />
              ),
            });
          } else {
            if (reportType === 2) {
              setRowsSynthetic(data.result);
            } else {
              setRows(data.result);
              setTotalCount(data.totalCount);
              if (data.totalCount < Environment.LIMITE_LINHAS_GREAT) {
                setPage(1);
              }
            }
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          const { titleMessage, contentMessage } = returnMessageError(err);

          toastHandler.present({
            type: 'error',
            position: 'top-right',
            messageOrContent: (
              <ToastContent title={titleMessage} content={contentMessage} />
            ),
          });
        });
    });
  };

  useEffect(() => {
    if (hasViewPermission) {
      setIsLoading(true);
      debounce(() => {
        FinancialService.getReportLifecycleProposal(
          page,
          rowPages,
          dataClientFilter,
          dataIni,
          dataFim,
          dataManagerFilter,
          dataOrigemFilter,
          true,
          reportType,
          status,
          document
        )

          .then((data) => {
            setIsLoading(false);
            if (data instanceof Error) {
              const titleMessage = 'Alerta!!';
              const contentMessage = 'Erro: ' + data?.message;

              toastHandler.present({
                type: 'error',
                position: 'top-right',
                messageOrContent: (
                  <ToastContent title={titleMessage} content={contentMessage} />
                ),
              });
            } else {
              if (reportType === 2) {
                setRowsSynthetic(data.result);
              } else {
                setRows(data.result);
                setTotalCount(data.totalCount);
                if (data.totalCount < Environment.LIMITE_LINHAS_GREAT) {
                  setPage(1);
                }
              }
              // setTotalCount(data?.totalCount);
              // setRows(data?.result);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            const { titleMessage, contentMessage } = returnMessageError(err);

            toastHandler.present({
              type: 'error',
              position: 'top-right',
              messageOrContent: (
                <ToastContent title={titleMessage} content={contentMessage} />
              ),
            });
          });
      });
    }
  }, [page, pesquisar, rowPages]);

  const renderAnalyticalTable = () => (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{ marginTop: 1, width: 'auto', padding: '20px' }}
    >
      <Table aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={8} sx={{ padding: '0px' }}></TableCell>
          </TableRow>

          <TableRow sx={{ background: '#f5fbfd' }}>
            <TableCell sx={{ color: '#7e7777b5' }}> Parceiro</TableCell>
            <TableCell sx={{ color: '#7e7777b5' }}> Tipo imóvel</TableCell>
            <TableCell sx={{ color: '#7e7777b5' }}> Endereço</TableCell>
            <TableCell sx={{ color: '#7e7777b5' }}> Nome </TableCell>
            <TableCell sx={{ color: '#7e7777b5' }}> V. parcela </TableCell>
            <TableCell sx={{ color: '#7e7777b5' }}> V. aluguel</TableCell>
            <TableCell sx={{ color: '#7e7777b5' }}> Origem</TableCell>
            <TableCell sx={{ color: '#7e7777b5' }}> Emissão</TableCell>
            <TableCell sx={{ color: '#7e7777b5' }}> Estatus</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            rows?.map((row) => (
              <TableRow key={row.id} hover>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    sx={{ fontWeight: 'normal' }}
                  >
                    {row.client ? row.client : ''}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    sx={{ fontWeight: 'normal' }}
                  >
                    {row.property_finally != undefined
                      ? globalTranslate(row.property_finally)
                      : ''}
                  </Typography>
                </TableCell>

                <TableCell sx={{ color: '#959595', fontWeight: 'normal' }}>
                  <Typography
                    variant="subtitle2"
                    color={'#959595'}
                    fontWeight={'normal'}
                  >
                    {row.address_street != null && row.address_street != ''
                      ? `
                  ${
                    row.address_complement != null &&
                    row.address_complement != ''
                      ? `${row.address_street} , ${row.address_number} - ${row.address_complement} - ${row.address_district}`
                      : `${row.address_street} , ${row.address_number} - ${row.address_district}`
                  }`
                      : 'Endereço não cadastrado'}
                    {row.address_city != null && row.address_city != ''
                      ? `${row.address_city} - ${row.address_state}`
                      : ''}
                  </Typography>
                </TableCell>
                <TableCell sx={{ fontWeight: 'normal' }}>
                  <Typography
                    variant="subtitle2"
                    color={'#959595'}
                    fontWeight={'normal'}
                  >
                    {`${row.name} - ${
                      row.document.length > 11
                        ? row.document.replace(
                            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                            '$1.$2.$3/$4-$5'
                          )
                        : row.document.replace(
                            /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                            '$1.$2.$3-$4'
                          )
                    }`}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    sx={{ fontWeight: 'normal' }}
                  >
                    {parseFloat(
                      `${row?.assurance_month_amount}`
                    ).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    sx={{ fontWeight: 'normal' }}
                  >
                    {parseFloat(`${row?.rent_amount}`).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    sx={{ fontWeight: 'normal' }}
                  >
                    {`${
                      row?.created_from == 'web_app' ? 'Manual' : 'Integrada'
                    } `}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography
                    variant="subtitle2"
                    color={'#959595'}
                    fontWeight={'normal'}
                  >
                    {moment(
                      row.term_begin
                        ? row.term_begin.substring(0, 10) + ' 03:00:01'
                        : '2000-01-01 03:00:01'
                    ).format('L')}
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'start' }}>
                  <Typography
                    variant="subtitle2"
                    color={'#959595'}
                    fontWeight={'normal'}
                  >
                    {globalTranslateStatus(row.status)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        {totalCount === 0 && !isLoading && (
          <caption
            style={{
              textAlign: 'center',
              margin: 0,
              color: 'black',
              fontSize: '1rem',
              lineHeight: 1.75,
              letterSpacing: '0.00938em',
            }}
          >
            {Environment.LISTAGEM_VAZIA}
          </caption>
        )}
        <TableFooter>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={9}>
                <LinearProgress variant="indeterminate" />
              </TableCell>
            </TableRow>
          )}
          {totalCount > 0 && (
            <TableRow>
              <TableCell colSpan={5} sx={{ borderBottom: 'none' }}>
                <Pagination
                  page={page}
                  count={Math.ceil(totalCount / rowPages)}
                  onChange={(_, newPage) => {
                    setSearchParams(
                      {
                        busca,
                        pagina: newPage.toString(),
                        //status: statusProposal, tipo: tipoProposal, origem: origemProposal, dataini: dataIniProposal, datafim: dataFimProposal, clientFilter: clientFilter, managerId, typePeriod, tombamento
                      },
                      { replace: true }
                    );
                    setPage(newPage);
                  }}
                />
              </TableCell>
              <TableCell colSpan={5} sx={{ borderBottom: 'none' }}>
                <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                  Exibindo {page * rowPages - (rowPages - 1)}
                  {' - '}
                  {rowPages * (page - 1) + rowPages * page < totalCount
                    ? rowPages * page
                    : totalCount}{' '}
                  de
                  <Typography
                    variant="overline"
                    sx={{
                      fontWeight: 'bold',
                      color: '#0170B3',
                      fontSize: '16px',
                    }}
                  >
                    {' '}
                    {totalCount}{' '}
                  </Typography>
                  registro(s)
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );

  const renderSyntheticScreen = () => (
    <Grid
      container
      item
      sm={12}
      sx={{
        borderRadius: '6px',
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
      }}
      display={'flex'}
      flexDirection={smDown ? 'column' : 'row'}
      component={Paper}
      // padding={2}
    >
      {rowsSynthetic?.map((item: any, index: number) => (
        <Grid container item sm={12} md={3} key={index}>
          <Card
            sx={{
              padding: '2px',
              boxShadow: 'none',
              width: '100%',
              borderRight:
                index !== rows.length - 1 ? '1px solid #c1c1c1' : 'none',
              borderRadius: '0',
            }}
          >
            <CardContent sx={{ textAlign: 'center', paddingTop: '16px' }}>
              <Typography variant="h4" fontWeight={'300'}>
                {globalTranslateStatus(item?.status)}
              </Typography>
              <Typography
                variant="h5"
                sx={{ color: globalColorText(item?.status) }}
                fontWeight={'300'}
              >
                {item.totalCount}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
      {!rowsSynthetic?.length && reportType === 2 && (
        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            border: '1px solid #eaecee',
            padding: 1,
            textAlign: 'center',
            marginTop: '-5px',
            marginRight: '8px',
            borderRadius: '6px',
            boxShadow:
              'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
          }}
        >
          <Typography variant="subtitle1">
            {Environment.LISTAGEM_VAZIA}
          </Typography>
        </Box>
      )}
    </Grid>
  );

  return (
    <LayoutBaseDePagina
      titulo="Relatórios de Garantias"
      subtitulo=""
      barraDeFerramentas={
        <ToolbarLifecycleProposalFilterReport
          clickBack={() => navigate('/relatorios')}
          onClick={clickSearchUpdateProposal}
          showExport={reportType === 1}
          onClickExport={clickExportCsv}
          showInputManagerFilter={true}
          showInputOrigem={true}
          // showButtonFilter={true}
          onChangeClientFilter={(value) => {
            setDataClientFilter(value);
          }}
          onChangeManagerFilter={(value) => {
            setDataManagerFilter(value);
          }}
          onChangeOrigem={(value) => {
            setDataOrigemFilter(value);
          }}
          showLimitPage={reportType === 1}
          onChangeLimitPage={(value) => {
            setRowPages(Number(value));
          }}
          onChangeDataIni={(value) => {
            setDataIni(value);
          }}
          onChangeDataFim={(value) => {
            setDataFim(value);
          }}
          onChangeReportType={(value) => {
            setReportType(value);
          }}
          onChangeStatus={(value) => {
            setStatus(value);
          }}
          onChangeDocument={(value) => {
            setDocument(value);
          }}
          loading={isLoading}
        />
      }
    >
      <ProfileGuard
        requiredRole={PermissionRolesEnum.WarrantiesOptions}
        requiredAction={PermissionActionsEnum.View}
      >
        {reportType === 1 ? renderAnalyticalTable() : renderSyntheticScreen()}
      </ProfileGuard>
      <Grid container display="flex" flexDirection="row"></Grid>
    </LayoutBaseDePagina>
  );
};
