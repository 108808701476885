import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, Button, CircularProgress, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { useToast } from "../../../../../shared/hooks";
import { ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";
import { returnMessageError } from "../../../../../shared/utils/returnMessageError";
import { isObject } from "lodash";

interface Logs {
	proposalLog_id: string;
	proposalLog_action: string;
	proposalLog_created_at: string;
	proposalLog_updated_at?: string;
	proposalLog_proposalId: string;
	proposalLog_userId: string;
	proposalLog_payload_send: string;
	proposalLog_payload_request: string;
	proposalLog_observation?: string;
	proposal_certificate_number: string;
	proposal_id: string;
	proposal_clientId: string;
	proposal_analysisId: string;
	proposal_name: string;
	proposal_document: string;
	user_name?: string;
	proposal_created_at: string;
}

interface Payload {
	proposal: any;
	newProposal: any;
}

interface ModalLogsUpdateProposalProps {
	isClose: () => void;
	isOpenModal: boolean;
	rows: { id: string };
}

export const ModalLogsUpdateProposal: React.FC<ModalLogsUpdateProposalProps> = ({ isClose, isOpenModal, rows }) => {
	const [payload, setPayload] = useState<any>(undefined);
	const [idLog, setIdLog] = useState("");
	const [openModalPayloadID, setOpenModalPayloadID] = useState(false);
	const [dataLogs, setDataLogs] = useState<Logs[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [differencesProposal, setDifferencesProposal] = useState<any>(undefined);
	const toastHandler = useToast();

	const findDifferences = useCallback((oldProposal: any, newProposal: any): any => {
		const differences: any = {};
		Object.keys({ ...oldProposal, ...newProposal }).forEach((key) => {
			if (oldProposal[key] !== newProposal[key]) {
				differences[key] = {
					old: oldProposal[key],
					new: newProposal[key],
				};
			}
		});
		return differences;
	}, []);

	const handleGetLogUpdateProposal = useCallback(
		(proposalId: string) => {
			setIsLoading(true);
			ProposalService.getLogUpdateProposal(proposalId)
				.then((result) => {
					if (result instanceof Error) {
						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title="Alerta!!" content={`Erro: ${result.message}`} />,
						});
					} else if (Array.isArray(result)) {
						setDataLogs(result as unknown as Logs[]);
					} else {
						toastHandler.present({
							type: "warning",
							position: "top-right",
							messageOrContent: <ToastContent title="Aviso" content={"Os dados recebidos não estão no formato esperado."} />,
						});
					}
				})
				.catch((err: any) => {
					const { titleMessage, contentMessage } = returnMessageError(err);
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[toastHandler]
	);

	const handleGetLogsUpdatePayload = (payload?: Payload, logId: string = "") => {
		if (payload && isObject(payload) && payload.proposal && payload.newProposal) {
			const { proposal, newProposal } = payload;
			const proposalDifferences = findDifferences(proposal, newProposal);
			setDifferencesProposal(proposalDifferences);
			setPayload(payload);
			setIdLog(logId);
			setOpenModalPayloadID(true);
		} else {
			toastHandler.present({
				type: "warning",
				position: "top-right",
				messageOrContent: <ToastContent title={"Alerta!!"} content={"Não foi encontrado evidência de payload neste log específico!"} />,
			});
		}
	};

	useEffect(() => {
		if (isOpenModal && rows?.id) {
			handleGetLogUpdateProposal(rows.id);
		}
	}, [isOpenModal]);

	const handleCloseModalLogsPayload = () => setOpenModalPayloadID(false);

	const renderTableHeader = () => (
		<TableRow>
			{["Data Criada", "Tipo Evento", "Nome Tabela", "Referência ID Garantia", "Usuário Id", "Usuário Nome", "Payload"].map((header, index) => (
				<TableCell key={index} sx={{ fontWeight: 500 }}>
					{header}
				</TableCell>
			))}
		</TableRow>
	);

	const renderDifferences = () => (
		<Box sx={{ padding: 2 }}>
			{differencesProposal &&
				Object.keys(differencesProposal).map((key) => (
					<Box key={key} sx={{ marginBottom: 1, background: parseInt(key) % 2 !== 0 ? "#ebeeee" : "transparent", p: 1 }}>
						<Typography variant="subtitle2">{key}</Typography>
						<Typography variant="body2" sx={{ fontWeight: "400" }}>
							Old:{" "}
							{typeof differencesProposal[key].old === "object" ? JSON.stringify(differencesProposal[key].old) : differencesProposal[key].old}
						</Typography>
						<Typography variant="body2" sx={{ fontWeight: "500", color: "orange" }}>
							New:{" "}
							{typeof differencesProposal[key].new === "object" ? JSON.stringify(differencesProposal[key].new) : differencesProposal[key].new}
						</Typography>
					</Box>
				))}
		</Box>
	);

	const renderLogRow = (log: Logs, index: number) => (
		<TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff" }}>
			{[
				format(new Date(log.proposalLog_created_at.slice(0, 10) + " 03:00:00"), "dd/MM/yyyy"),
				log.proposalLog_action,
				log.proposalLog_id,
				log.proposalLog_id,
				log.proposalLog_userId,
				log.user_name,
			].map((cellContent, cellIndex) => (
				<TableCell key={cellIndex} sx={{ fontWeight: "normal" }}>
					<Typography variant="subtitle2" color={"#080808"} fontWeight={"normal"}>
						{cellContent}
					</Typography>
				</TableCell>
			))}
			<TableCell>
				<Button
					color="primary"
					disableElevation
					variant={"outlined"}
					sx={{ alignItems: "flex-start" }}
					startIcon={<RemoveRedEyeOutlinedIcon />}
					onClick={() => handleGetLogsUpdatePayload(JSON.parse(log.proposalLog_payload_send), log.proposalLog_id)}
				>
					Payload
				</Button>
			</TableCell>
		</TableRow>
	);

	const renderNoLogsMessage = () => (
		<TableRow>
			<TableCell colSpan={7} sx={{ fontWeight: "normal", borderBottom: "none", textAlign: "center" }}>
				<Typography color={"primary"} variant="subtitle2" fontWeight={"normal"} fontSize={20}>
					Não há registros de logs desta garantia
				</Typography>
			</TableCell>
		</TableRow>
	);

	return (
		<>
			<ModalContext
				isCloseModal={isClose}
				isOpenModal={isOpenModal}
				messageType={"info"}
				enableBackdropClose={true}
				overrideWidthValue="auto"
				maxWidth="lg"
				showButtonCancel={true}
				textButtonCancel="Sair"
				showTitleIcon={false}
				ITitle="Logs de alterações da Garantia"
				IMessage={[
					<Grid item key="table">
						<Table aria-label="a dense table" sx={{ overflow: "auto", border: "0.5px solid #e5e5e9" }}>
							<TableBody sx={{ textAlign: "center" }}>
								{renderTableHeader()}
								{isLoading ? (
									<TableRow>
										<TableCell colSpan={7} sx={{ textAlign: "center" }}>
											<CircularProgress />
										</TableCell>
									</TableRow>
								) : dataLogs.length > 0 ? (
									dataLogs.map(renderLogRow)
								) : (
									renderNoLogsMessage()
								)}
							</TableBody>
						</Table>
					</Grid>,
				]}
			/>

			{openModalPayloadID && (
				<ModalContext
					isCloseModal={handleCloseModalLogsPayload}
					isOpenModal={openModalPayloadID}
					messageType={"info"}
					enableBackdropClose={true}
					overrideWidthValue="100"
					showButtonCancel={true}
					showTitleIcon={false}
					textButtonCancel="Sair"
					ITitle={`Alteração da garantia`}
					ISubTitle={`Id# ${idLog}`}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Grid container item sx={{ border: "none", display: "inline-flex" }}>
								<Box sx={{ boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px", borderRadius: 6 }}>
									<Typography variant="h6" color={"primary"} sx={{ pt: 2, pl: 2 }}>
										Alterações realizadas
									</Typography>
									{renderDifferences()}
								</Box>

								<Box>
									<pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{JSON.stringify(payload, null, 2)}</pre>
								</Box>
							</Grid>
						</Box>,
					]}
				/>
			)}
		</>
	);
};
