export enum PermissionActionsEnum  {
  Add = 'add',
  Conclude = 'conclude',
  Filter = 'filter',
  Update = 'update',
  View = 'view',
  Cancel ='cancel',
  Approve='approve',
  Reject ='reject',
  Contest='contest',
  Serasa='serasa',
  ClickSign='clickSign',
  Tipping='tipping',
  UpProposalPeriod='upProposalPeriod',
  UpProposalValues='upProposalValues',
  UpProposalAddress='upProposalAddress',
  UpProposalLocator='upProposalLocator',
  UpProposalCancel='upProposalCancel',
  UpProposalConcludeCancel='upProposalConcludeCancel',
  ReportFinancial= 'reportFinancial',
  ReportProposals= 'reportProposals',
  ReportAnalysis= 'reportAnalysis',
  ReportSinister= 'reportSinister',
  ReportAdmin= 'reportAdmin',
  ReportClient= 'reportClient',
  ReportLogAndAudit= 'reportLogAndAudit',
  BlockCancel = 'blockCancel',
  WalletTransfer = 'walletTransfer',
  Contracts = "contracts",
}