import { CreditCardOutlined, MoneyOutlined } from "@mui/icons-material";
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { endOfMonth, format, startOfMonth } from "date-fns";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContent, ToolbarFinancial } from "../../../shared/components";

import { ModalReport } from "../../../shared/components/ModalReport/ModalReport";
import { ModalSendEmail } from "../../../shared/components/ModalSendEmail/ModalSendEmail";
import { PaginationTable } from "../../../shared/components/PaginationTable/PaginationTable";
import { ToolbarSetup } from "../../../shared/components/ToobarSetup/ToobarSetup";
import { useDebounce, useToast } from "../../../shared/hooks";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { FinancialService, ISetup } from "../../../shared/services/api/financial/FinancialService";
import {
	globalColorTextSetup,
	globalMaskDocument,
	globalRemoveSpecialCharacters,
	globalTranslatePayment,
	globalTranslateStatusTaxSetup,
} from "../../../shared/utils";
import { ListSearchSetupParams } from "../../analysis/types/list-search-params.type";
import { ModalCancelSetup } from "./ModalCancelSetup";
import { ModalDetailSetup } from "./ModalDetailSetup";
import { ModalPaymentSetup } from "./ModalPaydSetup";
import { RowMenuSetup } from "./RowMenuSetup";
import { LoadingComp } from "../../../shared/components/loading/Loading";

export const FinancialSetupPresentation = () => {
	const [searchParams, setsearchParams] = useSearchParams();
	const navigate = useNavigate();
	const [layoutFinan, setLayoutFinan] = useState("setup");
	const { debounce } = useDebounce();
	const [isLoading, setIsLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [rows, setRows] = useState<ISetup[]>([]);
	const [modalDetail, setModalDetail] = useState(false);
	const [pesquisar, setPesquisar] = useState(true);
	const [isReport, setIsReport] = useState(false);
	const toastHandler = useToast();
	const [modalCancel, setModalCancel] = useState(false);
	const [modalPayment, setModalPayment] = useState(false);
	const [rowSetup, setRowSetup] = useState<ISetup>();
	const [modalSendMail, setModalSendMail] = useState(false);
	const [isRefresh, setIsRefresh] = useState(false);

	const goTo = (option: string, id: string, e: any, row: ISetup) => {
		e.preventDefault();

		if (option == "detalhar") {
			setModalDetail(true);
			handleDetailSetup(row);
		}
		if (option == "cancelar") {
			handleModalCancel(row);
		}
		if (option == "baixar") {
			handleModalPayment(row);
		}
		if (option == "reenviar") {
			handleModalSendSetup(row);
		}
	};

	const handleDetailSetup = (row: ISetup) => {
		setRowSetup(row);
		console.log(row);
	};

	const handleModalSendSetup = (row: ISetup) => {
		setRowSetup(row);
		setModalSendMail(true);
	};

	const handleModalCancel = (row: ISetup) => {
		setRowSetup(row);
		setModalCancel(true);
	};

	const handleModalPayment = (row: ISetup) => {
		setRowSetup(row);
		setModalPayment(true);
	};

	const handleCloseModalCancel = () => {
		setModalCancel(false);
		setIsRefresh(!isRefresh);
	};

	const handleCloseModalPayment = () => {
		setModalPayment(false);
		setIsRefresh(!isRefresh);
	};

	const handleCloseModalSend = () => {
		setModalSendMail(false);
		setIsRefresh(!isRefresh);
	};

	const filterInitialParams: ListSearchSetupParams = useMemo(() => {
		return {
			search: "",
			status: "",
			dataIni: format(startOfMonth(new Date()), "yyyy-MM-dd"),
			dataFim: format(endOfMonth(new Date()), "yyyy-MM-dd"),
			pageSize: "10",
		};
	}, []);

	const handleReport = () => {
		setIsReport(true);
	};

	const handlesearch = () => {
		setsearchParams(
			{
				search,
				status: statusSetup,
				pagina: String(1),
				pageSize: pageSize,
				dataIni: dataIniSetup,
				dataFim: dataFimSetup,
			},
			{ replace: true }
		);
		setPesquisar(!pesquisar);
	};

	useEffect(() => {
		setsearchParams(
			{
				...searchParams,
				status: filterInitialParams.status,
				pageSize: filterInitialParams.pageSize,
				dataIni: filterInitialParams.dataIni,
				dataFim: filterInitialParams.dataFim,
			},
			{ replace: true }
		);
	}, []);

	const search = useMemo(() => {
		return searchParams.get("search") || "";
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get("pagina") || "1");
	}, [searchParams]);

	const pageSize = useMemo(() => {
		return searchParams.get("pageSize") || "10";
	}, [searchParams]);

	const dataIniSetup = useMemo(() => {
		return searchParams.get("dataIni") || "";
	}, [searchParams]);

	const dataFimSetup = useMemo(() => {
		return searchParams.get("dataFim") || "";
	}, [searchParams]);

	const statusSetup = useMemo(() => {
		return searchParams.get("status") || "";
	}, [searchParams]);

	const managerId = useMemo(() => {
		return searchParams.get("managerId") || "";
	}, [searchParams]);

	useEffect(() => {
		setIsLoading(true);
		debounce(() => {
			FinancialService.getSetupList(search, pagina, pageSize, statusSetup, dataIniSetup, dataFimSetup).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					toastHandler.present({
						type: "error",
						position: "top-right",
						durationMs: 4000,
						messageOrContent: (
							<ToastContent title={"Atenção !!!!"} content={"Falha ao realizar consulta, caso o erro persista entre em contato com o suporte "} />
						),
					});
				} else {
					setTotalCount(result.totalCount);
					setRows(result.data);
				}
			});
		});
	}, [pesquisar, pagina, isRefresh]);

	return (
		<LayoutBaseDePagina
			titulo="Taxa Setup"
			subtitulo="Movimentação Financeira - Taxas de Setup"
			barraDeFerramentas={
				<ToolbarFinancial
					onClickFaturamento={() => navigate(`/financeiro/`)}
					onClickBoletos={() => navigate(`/boletos/`)}
					showPeriodo={false}
					onClickNfse={() => setLayoutFinan("nfse")}
					onClickRecebimento={() => setLayoutFinan("invoicing")}
					onClickSetup={() => setLayoutFinan("setup")}
					selectBoletos={layoutFinan === "installment"}
					selectFaturamento={layoutFinan === "invoicing"}
					selectNfse={layoutFinan === "nfse"}
					selectSetup={layoutFinan === "setup"}
					showButtonReport={true}
					onClickReport={handleReport}
					showInputManagerFilter={true}
					textoButtonManager={"Gestor"}
				/>
			}
		>
			<LoadingComp isopen={isLoading} />
			<TableContainer
				component={Paper}
				variant="outlined"
				sx={{
					marginLeft: 1,
					marginTop: 1,
					marginRight: 2,
					width: "auto",
					padding: 4,
				}}
			>
				<ToolbarSetup
					textSearch={search}
					onChangeTextSearch={(retorno: string) =>
						setsearchParams(
							{
								search: globalRemoveSpecialCharacters(retorno),
								status: statusSetup != "todos" ? statusSetup : "",
								pagina: String(1),
								pageSize,
								dataIni: dataIniSetup,
								dataFim: dataFimSetup,
							},
							{ replace: true }
						)
					}
					onChangeDataIni={(retorno: string) =>
						setsearchParams(
							{
								search: search,
								status: statusSetup != "todos" ? statusSetup : "",
								pagina: String(1),
								pageSize,
								dataIni: retorno,
								dataFim: dataFimSetup,
							},
							{ replace: true }
						)
					}
					onChangeDataFim={(retorno: string) =>
						setsearchParams(
							{
								search,
								status: statusSetup != "todos" ? statusSetup : "",
								pagina: String(1),
								pageSize,
								dataIni: dataIniSetup,
								dataFim: retorno,
							},
							{ replace: true }
						)
					}
					onChangeSelectStatus={(retorno: string) =>
						setsearchParams(
							{
								search,
								status: retorno != "todos" ? retorno : "",
								pagina: String(1),
								pageSize: pageSize,
								dataIni: dataIniSetup,
								dataFim: dataFimSetup,
							},
							{ replace: true }
						)
					}
					onChangeSelectQtde={(retorno: string) =>
						setsearchParams(
							{
								search,
								status: statusSetup != "todos" ? statusSetup : "",
								pagina: String(1),
								pageSize: retorno,
								dataIni: dataIniSetup,
								dataFim: dataFimSetup,
							},
							{ replace: true }
						)
					}
					onClickButtonRefresh={handlesearch}
				/>
				<Table aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography variant="h6">Criação</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="h6">Imobiliaria</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="h6">Cliente</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="h6">Vencimento</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="h6">Valor</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="h6">Status</Typography>
							</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => {
							return (
								<TableRow key={row.id}>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontWeight: "normal" }} display={"flex"} flexDirection={"column"}>
											{moment(row.created_at, "YYYY-MM-DD").format("DD/MM/YYYY")}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontWeight: "normal" }}>
											{row.client_name}
										</Typography>
										<Typography variant="subtitle2">{globalMaskDocument(row.client_document)}</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontWeight: "normal" }}>
											{row.tenant_name}
										</Typography>
										<Typography variant="subtitle2">{globalMaskDocument(row.tenant_document)}</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontWeight: "normal" }}>
											{moment(row.dueDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
										</Typography>
									</TableCell>
									<TableCell>
										<div style={{ display: "flex", alignItems: "center", gap: 15, color: "GrayText" }}>
											<Typography>
												<Tooltip title={globalTranslatePayment(row.payment_method)}>
													{row.status === "credit_card" ? <MoneyOutlined /> : <CreditCardOutlined />}
												</Tooltip>
											</Typography>
											<Typography variant="subtitle2">
												{parseFloat(row.raw_amount).toLocaleString("pt-br", {
													style: "currency",
													currency: "BRL",
												})}
											</Typography>
										</div>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" sx={{ fontWeight: "normal" }}>
											<Chip
												label={globalTranslateStatusTaxSetup(row.status)}
												sx={{
													color: globalColorTextSetup(row.status),
													fontWeight: "500",
												}}
												variant="outlined"
											/>
										</Typography>
									</TableCell>
									<TableCell>
										<RowMenuSetup goTo={goTo} row={row} />
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
					<PaginationTable
						isLoading={isLoading}
						totalCount={totalCount}
						pagina={pagina}
						pageSize={Number(pageSize)}
						onchange={(pagina) => {
							setsearchParams(
								{
									search: search,
									status: statusSetup,
									pagina: pagina.toString(),
									pageSize: pageSize,
									dataini: dataIniSetup,
									datafim: dataFimSetup,
								},
								{ replace: true }
							);
						}}
					/>
				</Table>
			</TableContainer>

			{isReport && <ModalReport isOpenModal={isReport} onClose={() => setIsReport(false)} />}

			{modalDetail && rowSetup && (
				<ModalDetailSetup
					isOpenModal={modalDetail}
					isCloseModal={() => setModalDetail(false)}
					rowSetup={rowSetup}
					onCloseModal={() => setModalDetail(false)}
					key={rowSetup?.id}
					onclickConfirm={() => {}}
					widthModfy="90%"
					overrideHeightValue="auto"
				/>
			)}

			{modalCancel && (
				<ModalCancelSetup
					clientName={rowSetup?.tenant_name ?? ""}
					dueDate={rowSetup?.dueDate ?? format(new Date(), "yyyy-MM-dd 03:00:00")}
					isOpenModal={modalCancel}
					onclickConfirm={handleCloseModalCancel}
					isCloseModal={() => setModalCancel(false)}
					valueSetup={rowSetup?.raw_amount ?? "0"}
					id={rowSetup?.id ?? ""}
				/>
			)}
			{modalSendMail && (
				<ModalSendEmail
					modalSend={modalSendMail}
					title={"Reenvio de link por email"}
					iscloseModal={() => setModalSendMail(false)}
					email={rowSetup?.tenant_email ?? ""}
					onclickConfirm={handleCloseModalSend}
					onclickCancel={() => setModalSendMail(false)}
					id={rowSetup?.id ?? ""}
				/>
			)}

			{modalPayment && (
				<ModalPaymentSetup
					clientName={rowSetup?.tenant_name ?? ""}
					dueDate={rowSetup?.dueDate ?? format(new Date(), "yyyy-MM-dd 03:00:00")}
					isOpenModal={modalPayment}
					onclickConfirm={handleCloseModalPayment}
					isCloseModal={() => setModalPayment(false)}
					valueSetup={rowSetup?.raw_amount ?? "0"}
					id={rowSetup?.id ?? ""}
				/>
			)}
		</LayoutBaseDePagina>
	);
};
