export const cancelReasons = [
	{ value: "Preço", label: "Preço" },
	{ value: "Compra do imóvel", label: "Compra do imóvel" },
	{ value: "Desistência da locação", label: "Desistência da locação" },
	{ value: "Distrato locador e imobiliária", label: "Distrato locador e imobiliária" },
	{ value: "Duplicidade de garantias", label: "Duplicidade de garantias" },
	{ value: "Garantia irregular/fraude", label: "Garantia irregular/fraude" },
	{ value: "Imissão na posse - Sinistro", label: "Imissão na posse - Sinistro" },
	{ value: "Inadimplência (imobiliária)", label: "Inadimplência (imobiliária)" },
	{ value: "LMI/LMG atingido", label: "LMI/LMG atingido" },
	{ value: "Termo de entrega de chaves - Sinistro", label: "Termo de entrega de chaves - Sinistro" },
	{ value: "Termo de entrega de chaves e confissão de dívidas", label: "Termo de entrega de chaves e confissão de dívidas" },
	{
		value: "Termo de entrega de chaves e inexistência de débitos - imobiliária",
		label: "Termo de entrega de chaves e inexistência de débitos - imobiliária",
	},
	{ value: "Termo de revogação/declaração de responsabilidade", label: "Termo de revogação/declaração de responsabilidade" },
	{ value: "Troca de garantia", label: "Troca de garantia" },
	{ value: "Troca de imobiliária/estipulante/administradora", label: "Troca de imobiliária/estipulante/administradora" },
	{ value: "Troca de locatário", label: "Troca de locatário" },
	{ value: "Troca de seguradora", label: "Troca de seguradora" },
	{ value: "Outros", label: "Outros" },
];
