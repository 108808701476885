import { Button, Card } from "@mui/material";

interface CardDocumentProps {
  height: string;
  onclickButton?: () => void;
  logo?: string;
  title?: string;
}

export const CardDocument: React.FC<CardDocumentProps> = ({
  height,
  onclickButton,
  logo,
  title,
}) => {
  return (
    <Card
      sx={{
        textAlign: "center",
        height: { height },
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
      }}
    >
      <Card
        color="secondary"
        sx={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
          width: "100%",
          borderRight: "1px solid #c1c1c1",
          borderRadius: "2px 2px 120px 140px",
          height: "20%",
        }}
      />
      <Button
        sx={{
          marginTop: "10%",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          color: "gray",
        }}
        onClick={onclickButton}
      >
        <img src={logo} alt="cont" width={"25%"} />
        {title}
      </Button>
    </Card>
  );
};
