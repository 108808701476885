import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes';
import {
  AppThemeProvider,
  DrawerProvider,
  AuthProvider,
  ResetPasswordProvider,
} from './context';
import './shared/forms/TraducoesYup';
import { ProfileProvider } from './context/ProfileContext';
import { PermissionsProvider } from './shared/hooks/usePermissions';
import { MenuPermissionsProvider } from './shared/hooks/useMenuPermissions';
import { ToastWrapper } from './shared/components/ToastWrapper/ToastWrapper';
import { Helmet } from 'react-helmet';

const HeaderSuperlogica = () => {
  function isProduction() {
    return process.env.REACT_APP_SUPERLOGICA_ENVIRONMENT === 'production';
  }

  return (
    <Helmet>
      <script src="https://s3-sa-east-1.amazonaws.com/widgets.superlogica.net/embed.js"></script>
      <script type="text/javascript">
        {`
        // Aguarda o carregamento completo do script antes de usar o superlogica
        window.onload = function() {
          superlogica.require("pjbank");
          superlogica.pjbank("checkout_transparente", "${
            process.env.REACT_APP_SUPERLOGICA_CREDENTIAL
          }", ${!isProduction()});
        }
      `}
      </script>
    </Helmet>
  );
};

export const App = () => {
  return (
    <AuthProvider>
      <HeaderSuperlogica />
      <AppThemeProvider>
        <ProfileProvider>
          <ResetPasswordProvider>
            <DrawerProvider>
              <PermissionsProvider>
                <MenuPermissionsProvider>
                  <ToastWrapper />
                  <BrowserRouter>
                    <AppRoutes />
                  </BrowserRouter>
                </MenuPermissionsProvider>
              </PermissionsProvider>
            </DrawerProvider>
          </ResetPasswordProvider>
        </ProfileProvider>
      </AppThemeProvider>
    </AuthProvider>
  );
};

// import { BrowserRouter } from 'react-router-dom';
// import { AppRoutes } from './routes';
// import { AppThemeProvider, DrawerProvider,AuthProvider, ResetPasswordProvider } from './context';
// import { Sidebar } from './shared/components';
// import './shared/forms/TraducoesYup';
// import { Login } from './shared/components/login/Login';
// import { ProfileProvider } from './context/ProfileContext';
// import { PermissionsProvider } from './shared/hooks/usePermissions';
// import { MenuPermissionsProvider } from './shared/hooks/useMenuPermissions';

// export const App = () => {
//   return (
//     <AuthProvider>
//     <AppThemeProvider>
//       <ResetPasswordProvider>
//         <Login>
//           <DrawerProvider>
//             <BrowserRouter>
//               <Sidebar>
//                 <AppRoutes />
//               </Sidebar>
//             </BrowserRouter>
//           </DrawerProvider>
//         </Login>
//       </ResetPasswordProvider>
//     </AppThemeProvider>
//     </AuthProvider>
//   );
// };
