import { Box, Button, Icon, Paper, Skeleton, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";


interface IToolEditProposalProps {

    showButtonBack?: boolean;
    showButtonSave?: boolean;
    showButtonEditValues?: boolean;
    showButtonEditValidity?: boolean;
    showButtonEditAddress?: boolean;
    showButtonEditLocator?: boolean;
    showButtonEditCancel?: boolean;

    isDisabledConfirm?: boolean;


    showButtonBackLoading?: boolean;
    showButtonSaveLoading?: boolean;
    showButtonEditValuesLoading?: boolean;
    showButtonEditValidityLoading?: boolean;
    showButtonEditAddressLoading?: boolean;
    showButtonEditLocatorLoading?: boolean;
    showButtonEditCancelLoading?: boolean;

    clickBack?: () => void;
    clickSave?: () => void;
    clickEditValues?: () => void;
    clickValidity?: () => void;
    clickEditAddress?: () => void;
    clickEditLocator?: () => void;
    clickEditCancel?: () => void;

}


export const ToolbarEditProposal: React.FC<IToolEditProposalProps> = ({
    showButtonBack = true,
    showButtonEditValidity = true,
    showButtonEditValues = true,
    showButtonEditAddress = true,
    showButtonEditLocator = true,
    showButtonEditCancel = true,
    showButtonSave = true,

    showButtonBackLoading = false,
    showButtonSaveLoading = false,
    showButtonEditValidityLoading = false,
    showButtonEditValuesLoading = false,
    showButtonEditAddressLoading = false,
    showButtonEditLocatorLoading = false,
    showButtonEditCancelLoading = false,

    isDisabledConfirm = false,

    clickBack,
    clickSave,
    clickEditValues,
    clickValidity,
    clickEditAddress,
    clickEditLocator,
    clickEditCancel,

}) => {
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            height={theme.spacing(5)}
            component={Paper}
        >

            {(showButtonBack && !showButtonBackLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickBack}
                    startIcon={<Icon>keyboard_double_arrow_left_icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Voltar
                    </Typography>
                </Button>
            )}

            {(showButtonEditValidity && !showButtonEditValidityLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickValidity}
                    startIcon={<Icon>edit_calendar_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Alterar Vigência
                    </Typography>
                </Button>
            )}


            {(showButtonEditValues && !showButtonEditValuesLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickEditValues}
                    startIcon={<Icon>currency_exchange_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Alterar Valores
                    </Typography>
                </Button>
            )}


            {(showButtonEditAddress && !showButtonEditAddressLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickEditAddress}
                    startIcon={<Icon>edit_location_alt_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Alterar Endereço
                    </Typography>
                </Button>
            )}

            {(showButtonEditCancel && !showButtonEditCancelLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickEditCancel}
                    startIcon={<Icon>cancel_outlinedIcon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Alterar Cancelamento
                    </Typography>
                </Button>
            )}


            {(showButtonEditLocator && !showButtonEditLocatorLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickEditLocator}
                    startIcon={<Icon>person_4_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Alterar Locador
                    </Typography>
                </Button>
            )}


            {(showButtonSave && !showButtonSaveLoading) && (
                <Button
                    color={"primary"}
                    disableElevation
                    style={{ border: isDisabledConfirm ? "1px solid #b3afaf" : "" }}
                    variant={isDisabledConfirm ? "outlined" : "contained"}
                    onClick={clickSave}
                    disabled={isDisabledConfirm}
                    startIcon={<Icon>check_icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Concluir Alteração
                    </Typography>
                </Button>
            )}

        </Box>
    );
};