export enum AnalysisStatusesEnum {
  Expired = "expired",
  InProgress = "in_progress",
  Issued = "issued",
  Pending = "pending",
  Refused = "refused",
  Canceled = "canceled",
  Hired = "hired",
  AwaitingSignature = "awaiting_signature",
  AwaitingBiometria = "awaiting_biometria"
}