import React, { createContext, useContext, useState } from 'react';

interface IMenuPermissionsProps{
    children: React.ReactNode;
}

interface IMenuPermissionsData {
    dashboardMenu: IMenuOptions;
    clientsMenu: IMenuOptions;
    usersMenu: IMenuOptions;
    analysisMenu: IMenuOptions;
    warrantiesMenu: IMenuOptions;
    sinisterMenu: IMenuOptions;
    reportsMenu: IMenuOptions;
    financialMenu: IMenuOptions;
    generalConditionsMenu: IMenuOptions;
    chartsMenu: IMenuOptions;
    permissionsMenu: IMenuOptions;
    handleMenuOptions: (type: string, selected: boolean) => void;
    handleMenuData: () => any;
}

interface IMenuOptions{
    view: boolean;
}

const MenuPermissionsContext = createContext({} as IMenuPermissionsData);

export const MenuPermissionsProvider:React.FC<IMenuPermissionsProps> = ({ children }) => {
    const [dashboardMenu, setDashboardMenu] = useState<IMenuOptions>({} as IMenuOptions);
    const [clientsMenu, setClientsMenu] = useState<IMenuOptions>({} as IMenuOptions);
    const [usersMenu, setUsersMenu] = useState<IMenuOptions>({} as IMenuOptions);
    const [warrantiesMenu, setWarrantiesMenu] = useState<IMenuOptions>({} as IMenuOptions);
    const [analysisMenu, setAnalysisMenu] = useState<IMenuOptions>({} as IMenuOptions);
    const [sinisterMenu, setSinisterMenu] = useState<IMenuOptions>({} as IMenuOptions);
    const [reportsMenu, setReportsMenu] = useState<IMenuOptions>({} as IMenuOptions);
    const [financialMenu, setFinancialMenu] = useState<IMenuOptions>({} as IMenuOptions);
    const [generalConditionsMenu, setGeneralConditionsMenu] = useState<IMenuOptions>({} as IMenuOptions);
    const [chartsMenu, setChartsMenu] = useState<IMenuOptions>({} as IMenuOptions);
    const [permissionsMenu, setPermissionsMenu] = useState<IMenuOptions>({} as IMenuOptions);

    const handleMenuOptions = (type: string, selected: boolean) => {
        switch(type){
            case 'dashboardMenu':
                setDashboardMenu({view: selected});
            break;
            
            case 'clientsMenu':
                setClientsMenu({view: selected});
            break;

            case 'usersMenu':
                setUsersMenu({view: selected});
            break;

            case 'analysisMenu':
                setAnalysisMenu({view: selected});
            break;

            case 'warrantiesMenu':
                setWarrantiesMenu({view: selected});
            break;

            case 'sinisterMenu':
                setSinisterMenu({view: selected});
            break;

            case 'reportsMenu':
                setReportsMenu({view: selected});
            break;

            case 'financialMenu':
                setFinancialMenu({view: selected});
            break;

            case 'generalConditionsMenu':
                setGeneralConditionsMenu({view: selected});
            break;

            case 'chartsMenu':
                setChartsMenu({view: selected});
            break;

            case 'permissionsMenu':
                setPermissionsMenu({view: selected});
            break;
        }

    }

    const handleMenuData = () => {
        const data = {
            menu_dashboard: dashboardMenu,
            clients_dashboard: clientsMenu,
            users_dashboard: usersMenu,
            analysis_dashboard: analysisMenu,
            warranties_menu: warrantiesMenu,
            sinister_menu: sinisterMenu,
            reports_menu: reportsMenu,
            financial_menu: financialMenu,
            general_conditions_menu: generalConditionsMenu,
            charts_menu: chartsMenu,
            permissions_menu: permissionsMenu,
        }
        return data;
    }

    return (
        <MenuPermissionsContext.Provider value={{
            dashboardMenu,
            analysisMenu,
            chartsMenu,
            clientsMenu,
            financialMenu,
            generalConditionsMenu,
            permissionsMenu,
            reportsMenu,
            sinisterMenu,
            usersMenu,
            warrantiesMenu,
            handleMenuOptions,
            handleMenuData
        }}>
            {children}
        </MenuPermissionsContext.Provider>
    )
}

export const useMenuPermissions = () => useContext(MenuPermissionsContext);