import { AuthGuard } from '../../shared/guards/AuthGuard';
import { Sidebar } from '../../shared/components';
import { ReportAnalysisPresentation } from './components';

export const ReportAnalysisPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <ReportAnalysisPresentation />
            </Sidebar>
        </AuthGuard>
    )
};