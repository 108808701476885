import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import {
	Badge,
	Box,
	Button,
	Checkbox,
	Chip,
	Divider,
	Grid,
	Icon,
	IconButton,
	MenuItem,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import bradesco from "../../../assets/banks/bradesco.png";
import pjbank from "../../../assets/banks/pjbank.png";
import sicoob from "../../../assets/banks/sicoob.png";
import { ModalContext, ToastContent } from "../../../shared/components";
import { useVForm, VForm, VTextField } from "../../../shared/forms";
import { VMaskTextField } from "../../../shared/forms/VMaskTextField";
import { useDebounce, useToast } from "../../../shared/hooks";
import { FinancialService, ICancelInvoice, IPaymentInvoice } from "../../../shared/services/api/financial/FinancialService";
import {
	globalBackgroundPayment,
	globalColorText,
	globalRemoveMask,
	globalTranslateStatus,
	globalTranslateStatusInvoices,
	isDateBeforeToday,
} from "../../../shared/utils";
import { formatDateBR } from "../../../shared/utils/format-date";
import { formatScaleValueBR } from "../../../shared/utils/formatScaleValue";
import { TInvoiceClient } from "./FinancialHealthListPresentation";
import { RowMenuInvoice } from "./RowMenuInvoice";

interface IInstallmentList {
	id: string;
	clientId?: string;
	clientName?: string;
	clientEmail?: string;
	reference?: string;
	dueDate?: string;
	value?: string;
	paymentDate?: string;
	installmentsNumber?: string;
	status?: string;
	gateway?: string;
	rowMenu?: React.ReactNode;
	onSelectCheckBox: (clientId: string, invoiceId: string, selected: boolean) => void;
	onclickcancel?: (id: string, value: string, duedate: string, clientName: string) => void;
	isChecked?: boolean;
	groupedNumber?: string;
	isRefresh: (value: boolean) => void;
	rowDetail?: TInvoiceClient;
}

export const TableFinancialDetail: React.FC<IInstallmentList> = ({
	id,
	clientId,
	reference,
	dueDate,
	value,
	installmentsNumber,
	status,
	gateway,
	paymentDate,
	onclickcancel,
	onSelectCheckBox,
	isChecked,
	groupedNumber,
	clientName,
	clientEmail,
	isRefresh,
	rowDetail,
}) => {
	const URL = window.location.protocol + "//" + window.location.host;
	const [isLoading, setIsLoading] = useState(true);
	const [checked, setChecked] = useState(false);
	const { debounce } = useDebounce();
	const [modalCancel, setModalCancel] = useState(false);
	const [modalPayment, setModalPayment] = useState(false);
	const toastHandler = useToast();
	const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } = useVForm();
	const [reasonCancel, setReasonCancel] = useState("");
	const [loadEmail, setLoadEmail] = useState("");
	const [refreshList, setRefreshList] = useState(false);
	const [modalDetailsCancel, setModalDetailsCancel] = useState(false);
	const [invoiceRow, setInvoiceRow] = useState<TInvoiceClient>();
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const handleExtract = (id: string) => {
		window.open(`${URL}/installment/summary/${id}`);
	};
	const [loadURL, setLoadURL] = useState("");
	const [modalSend, setModalSend] = useState(false);

	const handleSelectCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
		onSelectCheckBox(clientId ? clientId : "vazio", id, event.target.checked);
	};

	const handleExtractPDF = (IID: string) => {
		setIsLoading(true);
		debounce(() => {
			FinancialService.getInvoicesPDF(IID).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					alert(result.message);
				} else {
					//setModalDetail(true)
				}
			});
		});
	};

	const handleModalCancel = () => {
		setModalCancel(true);
	};

	const handleModalPayment = () => {
		setModalPayment(true);
	};

	const handleModalSend = (id: string) => {
		setModalSend(true);
	};

	const goTo = (option: string, id: string, e: any, row: TInvoiceClient) => {
		e.preventDefault();

		if (option == "details") {
			setModalDetailsCancel(true);
			setInvoiceRow(row);
		}

		if (option == "extrato") {
			handleExtract(id);
		}

		if (option == "pdfextrato") {
			handleExtractPDF(id);
		}
		if (option == "reenviar") {
			setLoadEmail(clientEmail ?? "");
			handleModalSend(id);
			setLoadURL(row.url || "");
			console.log(row.url);
		}

		if (option == "cancelar") {
			handleModalCancel();
		}

		if (option == "baixar") {
			handleModalPayment();
		}
	};

	useEffect(() => {
		if (status && !["paid", "canceled"].includes(status)) {
			setChecked(isChecked || false);
			onSelectCheckBox(clientId ? clientId : "vazio", id, isChecked || false);
		}
	}, [isChecked, refreshList]);

	const handlePaymentInvoice = (dados: IPaymentInvoice) => {
		dados.creditAmount = globalRemoveMask(dados.creditAmount);
		FinancialService.PaymentInvoice(id, { ...dados }).then((result) => {
			setIsLoading(false);
			if (result instanceof Error) {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={"Erro ao baixar o boleto"} />,
				});
				setRefreshList(true);
			} else {
				toastHandler.present({
					type: "success",
					position: "top-right",
					messageOrContent: <ToastContent title={"Sucesso"} content={"Boleto baixado com sucesso"} />,
				});
				setRefreshList(true);
				isRefresh(true);
				setModalPayment(false);
			}
		});
	};

	const handleCancelInvoice = (dados: ICancelInvoice) => {
		FinancialService.CancelInvoice(id, { ...dados }).then((result) => {
			setIsLoading(false);
			//console.log(dados);
			if (result instanceof Error) {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={"Erro ao cancelar o boleto"} />,
				});
				setRefreshList(!refreshList);
			} else {
				toastHandler.present({
					type: "success",
					position: "top-right",
					messageOrContent: <ToastContent title={"Sucesso"} content={"Boleto cancelado com sucesso"} />,
				});
				isRefresh(true);
				setRefreshList(!refreshList);
			}
		});
	};

	const handleResendEmail = (idInvoice: string) => {
		setIsLoading(true);
		debounce(() => {
			FinancialService.SendEmail(idInvoice).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					alert(result.message);
				} else {
					toastHandler.present({
						type: "success",
						position: "top-right",
						messageOrContent: <ToastContent title={"Sucesso"} content={"Boleto enviado com sucesso"} />,
					});
					setRefreshList(true);
				}
			});
		});
		// console.log(idInvoice);
	};

	return (
		<>
			<TableRow key={id}>
				<TableCell component="th" scope="row" sx={{ margin: 0 }}>
					<Checkbox
						color="primary"
						inputProps={{ "aria-label": "select all desserts" }}
						disabled={status && !["paid", "canceled"].includes(status) ? false : true}
						checked={status && !["paid", "canceled"].includes(status) ? checked : false}
						onChange={handleSelectCheckBox}
					/>
				</TableCell>

				<TableCell>
					<Typography variant="subtitle1" color={"#959595"} sx={{ fontWeight: "normal" }}>
						{dueDate ? format(new Date(dueDate), "MM/yyyy") : ""}
					</Typography>
				</TableCell>

				<TableCell>
					<Typography variant="subtitle1" color={"#959595"} sx={{ fontWeight: "normal" }}>
						{dueDate ? format(new Date(dueDate), "dd/MM/yyyy") : ""}
					</Typography>
				</TableCell>

				<TableCell>
					<Typography variant="subtitle1" color={"#959595"} sx={{ fontWeight: "400", textAlign: "left" }}>
						{parseFloat(`${value}`).toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})}
					</Typography>
				</TableCell>

				<TableCell>
					<Box display={"flex"} gap={1} alignItems={"center"}>
						<Typography variant="subtitle1" color={"#959595"}>
							{installmentsNumber}
						</Typography>
					</Box>
				</TableCell>

				<TableCell>
					<Badge color="primary" badgeContent={groupedNumber ? (Number(groupedNumber) > 0 ? groupedNumber : 0) : 0}>
						<Box display={"flex"} gap={0} alignItems={"center"} flexDirection={"column"}>
							<Chip
								sx={{
									paddingX: 2,
									background:
										status && dueDate
											? status == "open" && isDateBeforeToday(new Date(dueDate))
												? globalBackgroundPayment("overdue")
												: globalBackgroundPayment(status)
											: "",
								}}
								label={
									<Typography
										variant="subtitle1"
										sx={{
											color: `${
												status && dueDate
													? status == "open" && isDateBeforeToday(new Date(dueDate))
														? globalColorText("overdue")
														: globalColorText(status)
													: ""
											}`,
										}}
									>
										{status && dueDate
											? status == "open" && isDateBeforeToday(new Date(dueDate))
												? globalTranslateStatus("overdue")
												: globalTranslateStatusInvoices(status)
											: ""}
										{/* {status == "paid" ? <Typography variant="overline"> {moment(paymentDate).format("L")}</Typography> : ""} */}
										{status == "paid" && paymentDate ? (
											<Typography variant="overline"> {format(new Date(paymentDate), "dd/MM/yyyy")}</Typography>
										) : (
											""
										)}
									</Typography>
								}
								icon={
									<MonetizationOnOutlinedIcon
										sx={{
											color:
												status && dueDate
													? status == "open" && isDateBeforeToday(new Date(dueDate))
														? `${globalColorText("overdue")}!important`
														: `${globalColorText(status)}!important`
													: "",
										}}
									/>
								}
							/>
						</Box>
					</Badge>
				</TableCell>

				<TableCell>
					{status != "pending" ? (
						gateway === "PJBANK" ? (
							<Box>
								<Tooltip title="Segunda Via" placement="top-start">
									<IconButton size="small" sx={{ borderRadius: "8px", width: "150px" }}>
										<img src={pjbank} alt="cont" width={"60%"} />
									</IconButton>
								</Tooltip>
							</Box>
						) : gateway === "BRADESCO" ? (
							<Box>
								<Tooltip title="Segunda Via" placement="top-start">
									<IconButton size="small" sx={{ borderRadius: "8px", width: "150px" }}>
										<img src={bradesco} alt="cont" width={"70%"} />
									</IconButton>
								</Tooltip>
							</Box>
						) : gateway === "SICOOB" ? (
							<Box>
								<Tooltip title="Segunda Via" placement="top-start">
									<IconButton size="small" sx={{ borderRadius: "8px", width: "150px" }}>
										<img src={sicoob} alt="cont" width={"65%"} />
									</IconButton>
								</Tooltip>
							</Box>
						) : (
							""
						)
					) : (
						""
					)}
				</TableCell>

				<TableCell sx={{ textAlign: "start" }}>
					<Tooltip title="" placement="top-start">
						<div>
							<RowMenuInvoice
								goTo={goTo}
								row={{
									id,
									reference,
									dueDate,
									value,
									installmentsNumber,
									status,
									gateway,
									...rowDetail,
								}}
							/>
						</div>
					</Tooltip>
				</TableCell>
			</TableRow>

			{modalCancel && (
				<ModalContext
					isCloseModal={() => setModalCancel(false)}
					isOpenModal={modalCancel}
					messageType={"question"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					textButtonCancel={"Voltar"}
					onclickConfirm={() => {
						handleModalCancel();
					}}
					ITitle={"Confirma o cancelamento do boleto ?"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formIdCancelInvoice"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRef}
									onSubmit={handleCancelInvoice}
								>
									<Grid container direction="column" padding={1} spacing={2}>
										<Grid container item direction="row" spacing={1} sx={{ mb: 0 }}>
											<Grid item xs={1} sx={{ textAlign: "left" }}>
												Cliente :
											</Grid>
											<Grid item xs={8} sx={{ alignItems: "left" }}>
												<Typography variant="inherit" sx={{ fontWeight: "bold" }}>
													{clientName}
												</Typography>
											</Grid>
										</Grid>

										<Grid container item direction="row" spacing={1} sx={{ mb: 2 }}>
											<Grid item xs={2} sx={{ textAlign: "left" }}>
												Vencimento :
											</Grid>
											<Grid item xs={2} sx={{ alignItems: "left" }}>
												<Typography variant="inherit" sx={{ fontWeight: "bold" }}>
													{/* {moment(dueDate || "").format("L")} */}
													{dueDate ? format(new Date(dueDate), "dd/MM/yyyy") : ""}
												</Typography>
											</Grid>

											<Grid item xs={2} sx={{ textAlign: "right" }}>
												Valor :
											</Grid>
											<Grid item xs={3} sx={{ alignItems: "left" }}>
												<Typography variant="inherit" sx={{ fontWeight: "bold" }}>
													{parseFloat(value || "").toLocaleString("pt-br", {
														style: "currency",
														currency: "BRL",
													})}
												</Typography>
											</Grid>
										</Grid>

										<Divider />

										<Grid container item direction="row" spacing={2} mt={1}>
											<Grid item xs={12} md={8} lg={12} xl={12}>
												<VTextField
													fullWidth
													label="Motivo do cancelamento"
													name="canceledType"
													disabled={false}
													defaultValue="Outros"
													select
													onChange={(e) => setReasonCancel(e.target.value)}
												>
													<MenuItem value={"Valor incorreto"}>Valor Incorreto</MenuItem>
													<MenuItem value={"Duplicidade"}>Duplicidade</MenuItem>
													<MenuItem value={"Isenção de encargos"}>Isenção de encargos</MenuItem>
													<MenuItem value={"Inadimplencia"}>Inadimplência</MenuItem>
													<MenuItem value={"Outros"}>Outros</MenuItem>
												</VTextField>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12}>
												<VTextField
													fullWidth
													label="Descreva o motivo"
													name="canceledReason"
													disabled={false}
													multiline
													rows={4}
												></VTextField>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										container
										sm={12}
										sx={{
											border: "none",
											display: "flex",
											justifyContent: "center",
										}}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										{reasonCancel != "" ? (
											<Button
												color="primary"
												disableElevation
												variant={"contained"}
												startIcon={<Icon> checkIcon</Icon>}
												onClick={() => {
													formRef.current?.submitForm();
													setModalCancel(false);
												}}
											>
												{"Cancelar Boleto"}
											</Button>
										) : (
											""
										)}
										<Button
											color="primary"
											disableElevation
											variant={"outlined"}
											startIcon={<Icon> arrow_back_Ico</Icon>}
											onClick={() => {
												setModalCancel(false);
											}}
										>
											{"Voltar"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{modalPayment && (
				<ModalContext
					isCloseModal={() => setModalPayment(false)}
					isOpenModal={modalPayment}
					messageType={"question"}
					showButtonCancel={false}
					textButtonConfirm={"Baixar"}
					textButtonCancel={"Voltar"}
					onclickConfirm={() => {
						handleModalPayment();
					}}
					ITitle={"Confirma baixa do boleto ?"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formIdPaymentInvoice"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRef}
									onSubmit={handlePaymentInvoice}
								>
									<Grid container direction="column" padding={1} spacing={2}>
										<Grid container item direction="row" spacing={1} sx={{ mb: 0 }}>
											<Grid item xs={1} sx={{ textAlign: "left" }}>
												Cliente :
											</Grid>
											<Grid item xs={8} sx={{ alignItems: "left" }}>
												<Typography variant="inherit" sx={{ fontWeight: "bold" }}>
													{clientName}
												</Typography>
											</Grid>
										</Grid>

										<Grid container item direction="row" spacing={1} sx={{ mb: 2 }}>
											<Grid item xs={2} sx={{ textAlign: "left" }}>
												Vencimento :
											</Grid>
											<Grid item xs={2} sx={{ alignItems: "left" }}>
												<Typography variant="inherit" sx={{ fontWeight: "bold" }}>
													{/* {moment(dueDate).format("L")} */}
													{dueDate ? format(new Date(dueDate), "dd/MM/yyyy") : ""}
												</Typography>
											</Grid>

											<Grid item xs={2} sx={{ textAlign: "right" }}>
												Valor :
											</Grid>
											<Grid item xs={3} sx={{ alignItems: "left" }}>
												<Typography variant="inherit" sx={{ fontWeight: "bold" }}>
													{parseFloat(value || "0").toLocaleString("pt-br", {
														style: "currency",
														currency: "BRL",
													})}
												</Typography>
											</Grid>
										</Grid>

										<Divider />

										<Grid container item direction="row" spacing={2} mt={1}>
											<Grid item xs={4} sx={{ alignItems: "left" }}>
												<VTextField fullWidth label="Data Pagamento" name="paymentDate" disabled={false} type="date"></VTextField>
											</Grid>
											<Grid item xs={4} sx={{ alignItems: "left" }}>
												<VMaskTextField
													fullWidth
													label="Valor Pago"
													name="creditAmount"
													disabled={false}
													value={parseFloat(value || "0").toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
													mask="real"
												/>
											</Grid>
										</Grid>

										<Grid container item direction="row" spacing={2} mt={1}>
											<Grid item xs={12} md={8} lg={12} xl={12}>
												<VTextField
													fullWidth
													label="Motivo da Baixa manual"
													name="canceledType"
													disabled={false}
													defaultValue="Outros"
													select
													onChange={(e) => setReasonCancel(e.target.value)}
												>
													<MenuItem value={"Erro no sistema - manually"}>Erro no sistema</MenuItem>
													<MenuItem value={"Pagamento direto - manually"}>Pagamento Direto Garantti</MenuItem>
													<MenuItem value={"Boleto descontado/ajustado - manually"}>Boleto descontado/ajustado</MenuItem>
													<MenuItem value={"Outros - manually"}>Outros</MenuItem>
												</VTextField>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12}>
												<VTextField
													fullWidth
													label="Descreva o pagamento"
													name="canceledReason"
													disabled={false}
													multiline
													rows={4}
												></VTextField>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										container
										sm={12}
										sx={{
											border: "none",
											display: "flex",
											justifyContent: "center",
										}}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										{reasonCancel != "" ? (
											<Button
												color="primary"
												disableElevation
												variant={"contained"}
												startIcon={<Icon> checkIcon</Icon>}
												onClick={() => {
													formRef.current?.submitForm();
													// navigate('/boletos/');
													//    setModalPayment(false)
												}}
											>
												{"Baixar Boleto"}
											</Button>
										) : (
											""
										)}
										<Button
											color="primary"
											disableElevation
											variant={"outlined"}
											startIcon={<Icon> arrow_back_Ico</Icon>}
											onClick={() => {
												setModalPayment(false);
											}}
										>
											{"Voltar"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{modalDetailsCancel && (
				<ModalContext
					isCloseModal={() => {
						setModalDetailsCancel(false);
					}}
					isOpenModal={modalDetailsCancel}
					messageType={"info"}
					stylePropert={{
						display: "flex",
						overflowY: "visible",
						padding: "10px",
					}}
					showButtonCancel={false}
					textButtonConfirm={"Fechar"}
					onclickConfirm={() => {
						setModalDetailsCancel(false);
					}}
					ITitle={"Detalhes - Boleto"}
					IMessage={[
						<Box width={"100%"}>
							<Grid container item direction="row" gap={1} sx={{ mb: "10px", justifyContent: "center" }}></Grid>

							<Grid container item direction="row" spacing={1} mb={2}>
								<Grid item xs={4}>
									<Typography variant="subtitle2" color={"text.secondary"}>
										Status:
									</Typography>
									<Typography variant="subtitle2" sx={{ color: globalColorText(invoiceRow?.status) }}>
										{globalTranslateStatusInvoices(invoiceRow?.status)}
									</Typography>
								</Grid>

								<Grid item xs={4}>
									<Typography variant="subtitle2" color={"text.secondary"}>
										Data Vencimento:
									</Typography>
									<Typography variant="subtitle2" color={"text.secondary"}>
										{formatDateBR(invoiceRow?.dueDate)}
									</Typography>
								</Grid>

								<Grid item xs={4}>
									<Typography variant="subtitle2" color={"text.secondary"}>
										Motivo:
									</Typography>
									<Typography variant="subtitle2" color={"text.secondary"}>
										{invoiceRow?.canceledReason || "-----"}
									</Typography>
								</Grid>
							</Grid>

							<Grid container item direction="row" spacing={1} mb={2}>
								<Grid item xs={4}>
									<Typography variant="subtitle2" color={"text.secondary"}>
										Valor:
									</Typography>
									<Typography variant="subtitle2" color={"text.secondary"}>
										{formatScaleValueBR(invoiceRow?.value) || "-----"}
									</Typography>
								</Grid>

								{invoiceRow?.status == "paid" && (
									<>
										<Grid item xs={4}>
											<Typography variant="subtitle2" color={"text.secondary"}>
												Valor quantia paga:
											</Typography>
											<Typography variant="subtitle2" color={"text.secondary"}>
												{formatScaleValueBR(invoiceRow?.amountPaid) || "-----"}
											</Typography>
										</Grid>

										<Grid item xs={4}>
											<Typography variant="subtitle2" color={"text.secondary"}>
												Valor quantia crédito:
											</Typography>
											<Typography variant="subtitle2" color={"text.secondary"}>
												{formatScaleValueBR(invoiceRow?.creditAmount) || "-----"}
											</Typography>
										</Grid>
									</>
								)}
							</Grid>

							<Grid container item direction="row" spacing={1} mb={2}>
								<Grid item xs={4}>
									<Typography variant="subtitle2" color={"text.secondary"}>
										Taxa da plataforma:
									</Typography>
									<Typography variant="subtitle2" color={"text.secondary"}>
										{invoiceRow?.platformTaxes || "-----"}
									</Typography>
								</Grid>

								<Grid item xs={4}>
									<Typography variant="subtitle2" color={"text.secondary"}>
										{invoiceRow?.status == "paid" ? "Data Pagamento:" : "Data Cancelamento:"}
									</Typography>
									<Typography variant="subtitle2" color={"text.secondary"}>
										{invoiceRow?.status == "paid" ? formatDateBR(invoiceRow?.paymentDate) : formatDateBR(invoiceRow?.canceledAt)}
									</Typography>
								</Grid>

								{invoiceRow?.status == "paid" && (
									<Grid item xs={4}>
										<Typography variant="subtitle2" color={"text.secondary"}>
											Data do crédito:
										</Typography>
										<Typography variant="subtitle2" color={"text.secondary"}>
											{formatDateBR(invoiceRow?.creditDate)}
										</Typography>
									</Grid>
								)}
							</Grid>

							<Grid container item direction="row" spacing={1} mb={2}>
								<Grid item xs={4}>
									<Typography variant="subtitle2" color={"text.secondary"}>
										Data Criação:
									</Typography>
									<Typography variant="subtitle2" color={"text.secondary"}>
										{formatDateBR(invoiceRow?.createdAt)}
									</Typography>
								</Grid>

								<Grid item xs={4}>
									<Typography variant="subtitle2" color={"text.secondary"}>
										Data Atualização:
									</Typography>
									<Typography variant="subtitle2" color={"text.secondary"}>
										{formatDateBR(invoiceRow?.updatedAt)}
									</Typography>
								</Grid>
							</Grid>

							<Grid container item direction="row" gap={2}>
								<Button
									color="info"
									disableElevation
									variant={"outlined"}
									startIcon={<Icon> close</Icon>}
									onClick={() => {
										setModalDetailsCancel(false);
									}}
								>
									{"Fechar"}
								</Button>
							</Grid>
						</Box>,
					]}
				/>
			)}

			{modalSend && (
				<ModalContext
					isCloseModal={() => setModalSend(false)}
					isOpenModal={modalSend}
					messageType={"alert"}
					showButtonCancel={false}
					showTitleIcon={false}
					maxWidth="40%"
					textButtonConfirm={"Fechar"}
					onclickConfirm={() => {}}
					ITitle={"Segunda Via de Boleto"}
					IMessage={[
						<>
							<Box>
								<Grid container item direction="row" gap={2} sx={{ mb: "2rem" }}>
									<Typography variant="subtitle1" sx={{ alignSelf: "center" }}>
										Email cadastrado :
									</Typography>
									<Typography variant="h6">{loadEmail}</Typography>
								</Grid>

								<Grid container item direction="row" gap={2}>
									<Button
										color="primary"
										disableElevation
										variant={"outlined"}
										startIcon={<Icon> search</Icon>}
										onClick={() => {
											setLoadURL(invoiceRow?.url || "");
											setModalSend(false);
											window.open(loadURL);
										}}
									>
										{"Visualizar Segunda Via"}
									</Button>

									<Button
										color="primary"
										disableElevation
										variant={"contained"}
										startIcon={<Icon> mail_outlined_icon</Icon>}
										onClick={() => {
											setModalSend(false);
											handleResendEmail(id);
										}}
									>
										{"Reenviar por email"}
									</Button>

									<Button
										color="primary"
										disableElevation
										variant={"outlined"}
										startIcon={<Icon> close</Icon>}
										onClick={() => {
											setModalSend(false);
										}}
									>
										{"Fechar"}
									</Button>
								</Grid>
							</Box>
						</>,
					]}
				/>
			)}
		</>
	);
};
