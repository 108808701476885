import { IProposalList, ProposalService } from "../../../shared/services/api/proposal/ProposalService";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { checkByProfileGuard, ProfileGuard } from "../../../shared/guards/ProfileGuard";
import { ModalPortable } from "../../../shared/components/ModalPortable/ModalPortable";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { ListParamsProposal } from "../types/listParamsProposal.type";
import { ToolbarProposal } from "./toolbarProposal/ToobarProposal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce, useToast } from "../../../shared/hooks";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { ToastContent } from "../../../shared/components";
import TableProposal from "./TableProposal/TableProposal";
import { useEffect, useMemo, useState } from "react";
import { format, subDays } from "date-fns";

export const ProposalListPresentation = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { debounce } = useDebounce();
	const navigate = useNavigate();
	const [rows, setRows] = useState<IProposalList[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const hasViewPermission = useMemo(() => {
		return checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.View);
	}, []);
	const toastHandler = useToast();
	const [pesquisar, setPesquisar] = useState(true);
	const [transfers, setTransfers] = useState(false);

	const today = new Date();

	const filterInitialParams: ListParamsProposal = useMemo(() => {
		return {
			tipo: "documento",
			status: "Todos",
			createFrom: "Todos",
			beginDate: format(subDays(today, 7), "yyyy-MM-dd"),
			endDate: format(today, "yyyy-MM-dd"),
			data: "7",
			filterField: "documento",
			limit: 10,
		};
	}, []);

	const busca = useMemo(() => {
		return searchParams.get("busca") || "";
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get("pagina") || "1");
	}, [searchParams]);

	const typeSearch = useMemo(() => {
		return searchParams.get("tipo") || "";
	}, [searchParams]);

	const statusProposal = useMemo(() => {
		return searchParams.get("status") || "Todos";
	}, [searchParams]);

	const origemProposal = useMemo(() => {
		return searchParams.get("origem") || "";
	}, [searchParams]);

	const dataIniProposal = useMemo(() => {
		return searchParams.get("dataini") || filterInitialParams.beginDate;
	}, [searchParams]);

	const dataFimProposal = useMemo(() => {
		return searchParams.get("datafim") || filterInitialParams.endDate;
	}, [searchParams]);

	const clientFilter = useMemo(() => {
		return searchParams.get("clientFilter") || "";
	}, [searchParams]);

	const managerId = useMemo(() => {
		return searchParams.get("managerId") || "";
	}, [searchParams]);

	const tombamento = useMemo(() => {
		return searchParams.get("tombamento") || "0";
	}, [searchParams]);

	const limit = useMemo(() => {
		return searchParams.get("limit") || "10";
	}, [searchParams]);

	const handleBusca = () => {
		setSearchParams(
			{
				busca,
				pagina: String(1),
				tipo: typeSearch,
				status: statusProposal,
				origem: origemProposal,
				dataini: dataIniProposal,
				datafim: dataFimProposal,
				clientFilter: clientFilter,
				managerId: managerId,
				tombamento: tombamento,
				limit: limit,
			},
			{ replace: true }
		);
		setPesquisar(!pesquisar);
	};

	useEffect(() => {
		setSearchParams(
			{
				...searchParams,
				dataini: filterInitialParams.beginDate,
				datafim: filterInitialParams.endDate,
				tipo: filterInitialParams.filterField,
				status: filterInitialParams.status,
				origemAnalise: filterInitialParams.createFrom,
				limit: filterInitialParams.limit.toString(),
				typePeriod: filterInitialParams.typePeriod,
			},
			{ replace: true }
		);
	}, []);

	useEffect(() => {
		if (hasViewPermission) {
			setIsLoading(true);
			debounce(() => {
				ProposalService.getAll(
					pagina,
					busca,
					typeSearch,
					origemProposal,
					statusProposal,
					dataIniProposal,
					dataFimProposal,
					clientFilter,
					managerId,
					tombamento,
					Number(limit)
				)
					.then((result) => {
						setIsLoading(false);
						if (result instanceof Error) {
							const titleMessage = "Alerta!!";
							const contentMessage = "Erro: " + result?.message;
							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						} else {
							setTotalCount(result.totalCount);
							setRows(result.data);
						}
					})
					.catch((err: any) => {
						setIsLoading(false);
						const { titleMessage, contentMessage } = returnMessageError(err);

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					});
			});
		}
	}, [pagina, statusProposal, pesquisar, tombamento, limit]);

	const handleTransfer = () => {
		setTransfers(!transfers);
	};

	const updateSearchParams = (newParams: Record<string, string | number | undefined>) => {
		setSearchParams(
			(prevParams) => ({
				...prevParams,
				busca,
				pagina: "1",
				tipo: typeSearch,
				status: statusProposal,
				origem: origemProposal,
				dataini: dataIniProposal,
				datafim: dataFimProposal,
				clientFilter,
				managerId,
				tombamento,
				limit,
				...newParams,
			}),
			{ replace: true }
		);
	};

	return (
		<LayoutBaseDePagina
			titulo="Garantias"
			subtitulo="Listagem de garantias"
			barraDeFerramentas={
				<ToolbarProposal
					filterInitialParams={filterInitialParams}
					showInputMananger={true}
					showInputClient={true}
					showCreatedFrom={checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.Filter)}
					showInputDate={true}
					showBeetweenDate={true}
					showButtonNew
					textButtonNew="Nova"
					showInputSearch={true}
					onClickButtonNew={() => navigate("/analises/pedido")}
					onclickSearch={handleBusca}
					onChangeCreatedFrom={(retorno: string) => updateSearchParams({ origem: retorno })}
					onChangeInputClient={(retorno: string) => updateSearchParams({ clientFilter: retorno })}
					onChangeInputMananger={(retorno: string) => updateSearchParams({ managerId: retorno })}
					onChangeFilterField={(retorno: string) => updateSearchParams({ tipo: retorno })}
					onChangeTextSearch={(retorno: string) => updateSearchParams({ busca: retorno?.replace("(Inativo)", "").trim() })}
					onClickClear={() => updateSearchParams({ busca: "" })}
					onChangeDataIni={(retorno: string) => updateSearchParams({ dataini: retorno })}
					onChangeDataFim={(retorno: string) => updateSearchParams({ datafim: retorno })}
					showTransferButton={true}
					onclickTransfer={handleTransfer}

					
				/>
			}
		>
			<ProfileGuard requiredRole={PermissionRolesEnum.WarrantiesOptions} requiredAction={PermissionActionsEnum.View}>
				<TableProposal
					rows={rows}
					isLoading={isLoading}
					totalCount={totalCount}
					pagina={pagina}
					busca={busca}
					typeSearch={typeSearch}
					statusProposal={statusProposal}
					origemProposal={origemProposal}
					dataIniProposal={dataIniProposal}
					dataFimProposal={dataFimProposal}
					clientFilter={clientFilter}
					managerId={managerId}
					tombamento={tombamento}
					setSearchParams={setSearchParams}
					limit={limit}
				/>
			</ProfileGuard>
			{transfers && (
				<ModalPortable
					isOpenModal={transfers}
					iscloseModal={handleTransfer}
					enableBackdropClose={true}
					overrideWidthValue={"80%"}
					overrideHeightValue={"80%"}
					showButtonConfirm={true}
					onclickConfirm={handleTransfer}
				/>
			)}
		</LayoutBaseDePagina>
	);
};
