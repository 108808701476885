import React from "react";
import { Box, Typography, Modal, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import { ModalContext } from "../../../shared/components";
import { globalFormatCurrencyBRL, globalMaskDocument } from "../../../shared/utils";

interface ModalDetailSerasaResponseProps {
	isOpenModal: boolean;
	isClose: () => void;
	data: any;
}

const ModalDetailSerasaResponse: React.FC<ModalDetailSerasaResponseProps> = ({ isOpenModal, isClose, data }) => {
	// Verifique se o objeto data e a propriedade serasaResponse existem antes de continuar
	if (!data || !data.serasaResponse) {
		return null; // Retorna nulo se não houver dados disponíveis
	}

	// Parse o JSON serasaResponse apenas se existir
	let serasaData;
	try {
		serasaData = JSON.parse(data.serasaResponse);
	} catch (error) {
		console.error("Erro ao fazer o parse do JSON serasaResponse:", error);
		return null;
	}

	const serasaDetails = {
		CPF: globalMaskDocument(data.document),
		Nome: data.name,
		Política: serasaData.data?.["DV-Product"]?.Product[0]?.TipoSubProduto2 || "Não informado",
		Decisão: serasaData.data?.["DV-Application"]?.NomeRecomendacaoProposta || "Não informado",
		"QTDE pendências PEFIN": serasaData.data?.["DV-Serasa"]?.SerasaBureau[0]?.AnotacoesFinanceirasPF[0]?.Pefin?.QuantidadeTotalPefin || 0,
		"Valor pendências PEFIN": globalFormatCurrencyBRL(
			serasaData.data?.["DV-Serasa"]?.SerasaBureau[0]?.AnotacoesFinanceirasPF[0]?.Pefin?.ValorTotalPefin || 0
		),
		"QTDE pendências REFIN": serasaData.data?.["DV-Serasa"]?.SerasaBureau[0]?.AnotacoesFinanceirasPF[0]?.Refin?.QuantidadeTotalRefin || 0,
		"Valor pendências REFIN": globalFormatCurrencyBRL(
			serasaData.data?.["DV-Serasa"]?.SerasaBureau[0]?.AnotacoesFinanceirasPF[0]?.Refin?.ValorTotalRefin || 0
		),
		"Quantidade de Protestos": serasaData.data?.["DV-Serasa"]?.SerasaBureau[0]?.AnotacoesFinanceirasPF[0]?.Acao?.ValorTotalAcao || 0,
		"Total Cheque sem Fundo": serasaData.data?.["DV-Serasa"]?.SerasaBureau[0]?.AnotacoesFinanceirasPF[0]?.ChequeSemFundos?.ValorTotalCCF || 0,
		"Situação CPF": serasaData.data?.["DV-Serasa"]?.SerasaBureau[0]?.InformacoesPessoais?.Identificacao?.NomeSituacaoCPF || "Não informado",
		"Nascimento e Idade": `${new Date(data.birthDate).toLocaleDateString("pt-BR")} (${
			new Date().getFullYear() - new Date(data.birthDate).getFullYear()
		} anos)`,
		Score: serasaData.data?.["DV-Serasa"]?.SerasaBureau[0]?.Modelos?.Score?.SerasaScore || 0,
		"Aluguel Encargos Solicitado": globalFormatCurrencyBRL(serasaData.data?.["DV-Application"]?.ProdutoSolicitado?.ValorEmprestimoSolicitado || 0),
		"Comprometimento de Renda": serasaData.data?.["DV-Application"]?.DadosSaidaPersonalizados[7]?.Valor || 0,
		"Renda Presumida": globalFormatCurrencyBRL(serasaData.data?.["DV-Serasa"]?.SerasaBureau[0]?.Modelos?.RendaPresumida?.RendaPresumida || 0),
		"Limite Sugerido": globalFormatCurrencyBRL(serasaData.data?.["DV-Application"]?.DadosSaidaPersonalizados[9]?.Valor || 0),
		"Código Recomendação": serasaData.data?.["DV-Results"]?.MergedResults[0]?.ResultadoDecisao?.CodigoRecomendacao?.join(", ") || "Não informado",
		"Tabela Decisão": serasaData.data?.["DV-Results"]?.MergedResults[0]?.ResultadoDecisao?.TabelaDecisao?.join(", ") || "Não informado",
	};

	return (
		<ModalContext
			isOpenModal={isOpenModal}
			isCloseModal={isClose}
			messageType="info"
			showButtonCancel={true}
			textButtonCancel="Fechar"
			ITitle="Detalhes do Crédito"
			IMessage={[
				<Box width={"80%"}>
					<TableContainer component={Paper}>
						<Table size="small">
							<TableBody>
								{Object.entries(serasaDetails).map(([key, value]) => {
									if (key === "Valor pendências REFIN" || key === "Valor pendências PEFIN") {
										return null; // Pula a exibição individual destes itens
									}
									if (key === "QTDE pendências REFIN") {
										return (
											<TableRow key={key}>
												<TableCell component="th" scope="row">
													Pendências REFIN
												</TableCell>
												<TableCell>{` ${serasaDetails["QTDE pendências REFIN"]} pendências (REFIN): ${serasaDetails["Valor pendências REFIN"]}`}</TableCell>
											</TableRow>
										);
									}
									if (key === "QTDE pendências PEFIN") {
										return (
											<TableRow key={key}>
												<TableCell component="th" scope="row">
													Pendências PEFIN
												</TableCell>
												<TableCell>{`${serasaDetails["QTDE pendências PEFIN"]} pendências (PEFIN): ${serasaDetails["Valor pendências PEFIN"]}`}</TableCell>
											</TableRow>
										);
									}

									return (
										<TableRow key={key}>
											<TableCell component="th" scope="row">
												{key}
											</TableCell>
											<TableCell
												sx={{
													color: key === "Decisão" && value === "DECLINADA" ? "red" : key === "Decisão" ? "green" : "inherit",
												}}
											>
												{value}
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>,
			]}
		/>
	);
};

export default ModalDetailSerasaResponse;
