import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Button, Icon, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { checkByProfileGuard } from '../../../shared/guards/ProfileGuard';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';
import React from "react";
import { IProvisionList } from "../../../shared/services/api/provisoes/ProvisionService";
// import { IApprovedSinisterValues, ICreateSinisterValues, ISinisterDetails, ISinisterValues, SinisterService } from "../../../shared/services/api/sinister/SinisterService";


type BorderoRowPropsType = {
  row: IProvisionList,
  // goToEdit: any,
  goTo: any,
  handleApproved?: () => void;
}
export function RowMenuBordero({ row, goTo }: BorderoRowPropsType) {
  //console.log('170 RowMenu ',row);
  const [anchorEl, setAnchorEl] = React.useState(null);
  //const classes = useStyles();

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event: any
    //React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //RowMenu -- 


  return (
    <>
      <Menu
        id={`actions-${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Update) ?
          <MenuItem disabled={(row.status == 'open' || row.status == 'provisioned') ? false : true} onClick={(e) => { goTo('pagar', row.id, e, row), handleClose() }} sx={{ color: "green" }} >Pagar</MenuItem>
          : ''
        }
        {checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Add) ?
          <MenuItem disabled={(row.status == 'open' ) ? false : true} onClick={(e) => { goTo('provisionar', row.id, e, row), handleClose() }} sx={{ color: "red" }} >Provisionar</MenuItem>
          : ''
        }
        {checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Add) ?
            <MenuItem disabled={(row.status == 'provisioned') ? false : true} onClick={(e) => { goTo('reprovisionar', row.id, e, row), handleClose() }} sx={{ color: "#2471A3" }} >(Re)Provisionar</MenuItem>
            : ''
        }
        {checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Add) ?
          <MenuItem disabled={(row.status != 'paid' && row.status !='canceled') ? false : true} onClick={(e) => { goTo('cancelar', row.id, e, row), handleClose() }} sx={{ color: "orange" }} >Cancelar</MenuItem>
          : ''
        }
      </Menu>
      <IconButton size="small" sx={{ borderRadius: "18px" }} onClick={handleClick}>
        <Icon sx={{ color: "#0170B3", fontWeight: "normal" }}> more_horiz</Icon>
        <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#0170B3", fontWeight: "normal" }}>
        </Typography>
      </IconButton>
    </>
  );
}
