export type TypeMessage = {
  titleMessage: string;
  subtitleMessage?: string;
  contentMessage: string;
};

export const returnMessageErrorAsync = async (e: any): Promise<TypeMessage> => {
  const err = await e;

  let titleMessage = "Erro ";
  let subtitleMessage = "";
  const errResponseDataMessage = err?.response?.data?.message;
  const errResponseMessage = err?.response?.message;
  const errMessage = err?.message;
  const errResponseStatusText = err?.response?.statusText;
  const errResponseDescription = err?.description;
  let contentMessage = ``;

  if (errResponseDataMessage) {
    contentMessage += `resposta dados mensagem error: ${errResponseDataMessage} `;
  }
  if (errResponseMessage) {
    contentMessage += `resposta mensagem error: ${errResponseMessage} `;
  }

  if (errMessage) {
    contentMessage += `mensagem error: ${errMessage} `;
  }

  if (errResponseStatusText) {
    contentMessage += `status mensagem error: ${errResponseStatusText} `;
  }

  if (errResponseDescription) {
    contentMessage += `descrição mensagem error: ${errResponseDescription} `;
  }

  if (
    err?.response?.data?.statusCode == 403 ||
    err?.response?.status == 403 ||
    err?.response?.data?.message?.includes("Invalid permissions") ||
    err?.message?.includes("Invalid permissions") ||
    err?.response?.statusText?.includes("Invalid permissions")
  ) {
    
    titleMessage = "Alerta!!";
    contentMessage =
      "Seu grupo de usuário não tem permissão para acessar este serviço, favor contactar o suporte técnico.";

    if (err.response.config?.url?.includes("?")) {
      const pathURL = err.response.config.url.split("?")[0];
      titleMessage = `${titleMessage} Path URL [ ${pathURL} ] serviço.`;
    }
  } else if (
    err?.response?.data?.statusCode == 404 ||
    err?.response?.status == 404 ||
    err?.response?.statusCode == 404 ||
    err?.status == 404 ||
    err?.message?.includes("code 404")
  ) {
    titleMessage = "Recurso não encontrado! Status: 404";
    subtitleMessage = "Status: 404";
    contentMessage =
      "Não foi possivel encontrar o solicitado -> message: " + contentMessage;
  }

  return {
    titleMessage,
    subtitleMessage,
    contentMessage,
  };
};

export const returnMessageError = (err: any): TypeMessage => {

  let titleMessage = "Erro ";
  let subtitleMessage = "";
  const errResponseDataMessage = err?.response?.data?.message;
  const errResponseMessage = err?.response?.message;
  const errMessage = err?.message;
  const errResponseStatusText = err?.response?.statusText;
  const errResponseDescription = err?.description;
  let contentMessage = ``;

  if (errResponseDataMessage) {
    contentMessage += `resposta dados mensagem error: ${errResponseDataMessage} `;
  }
  if (errResponseMessage) {
    contentMessage += `resposta mensagem error: ${errResponseMessage} `;
  }

  if (errMessage) {
    contentMessage += `mensagem error: ${errMessage} `;
  }

  if (errResponseStatusText) {
    contentMessage += `status mensagem error: ${errResponseStatusText} `;
  }

  if (errResponseDescription) {
    contentMessage += `descrição mensagem error: ${errResponseDescription} `;
  }

  if (
    err?.response?.data?.statusCode == 403 ||
    err?.response?.status == 403 ||
    err?.response?.data?.message?.includes("Invalid permissions") ||
    err?.message?.includes("Invalid permissions") ||
    err?.response?.statusText?.includes("Invalid permissions")
  ) {
    titleMessage = "Alerta!!";
    contentMessage =
      "Seu grupo de usuário não tem permissão para acessar este serviço, favor contactar o suporte técnico.";

    if (err.response.config?.url?.includes("?")) {
      const pathURL = err.response.config.url.split("?")[0];
      titleMessage = `${titleMessage} Path URL [ ${pathURL} ] serviço.`;
    }
  } else if (
    err?.response?.data?.statusCode == 404 ||
    err?.response?.status == 404 ||
    err?.response?.statusCode == 404 ||
    err?.status == 404 ||
    err?.message?.includes("code 404")
  ) {
    titleMessage = "Recurso não encontrado!";
    subtitleMessage = "Status: 404";
    contentMessage =
      "Não foi possivel encontrar o resultado, status: 404,  message => " + contentMessage;
  }

  return {
    titleMessage,
    subtitleMessage,
    contentMessage,
  };
};
