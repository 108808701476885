import { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface IResetPasswordData{
    email:  string;
    token: string;
    password: string;
    confirmPassword: string;
    resetPassword: boolean;
    recoverPassword: boolean;
    setEmail:(email:string) => void;
    setPassword: (password: string) => void;
    setConfirmPassword:(confirmPassword: string) => void;
    setToken:(token:string) => void;
    setRecoverPassword:(recoverPassword: boolean) => void;
    setResetPassword:(resetPassword: boolean) => void;
}

interface IResetPasswordProviderProps {
    children: React.ReactNode;
}

const ResetPasswordContext = createContext({} as unknown as IResetPasswordData);

export const ResetPasswordProvider: React.FC<IResetPasswordProviderProps> = ({children}) => {
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetPassword, setResetPassword] = useState(false);
    const [recoverPassword, setRecoverPassword] = useState(false)

    return(
        <ResetPasswordContext.Provider value={{
                email, 
                password,
                token,
                confirmPassword,
                resetPassword,
                recoverPassword,
                setEmail,
                setPassword,
                setConfirmPassword,
                setToken,
                setRecoverPassword,
                setResetPassword,
            }}>
            {children}
        </ResetPasswordContext.Provider>
    )
}

export const useReset = () => useContext(ResetPasswordContext);