import { Box, Button, CircularProgress, InputLabel, MenuItem, Select, SelectChangeEvent, Tab, Tabs } from '@mui/material';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
// import { SinisterService } from '../../../shared/services/api/sinister/SinisterService';
//import { AnalysisContractTypeEnum } from '../../../enums/analysis-documents';
import { SinisterFilesUploadArea } from './SinisterFilesUploadArea/SinisterFileUploadArea';
import { useToast } from '../../../shared/hooks';
import { SinisterFilesList } from './SinisterFilesList/SinisterFilesList';
import { GetDocumentTypesResponseType } from '../../../shared/services/api/documents/types';
import { DocumentsService } from '../../../shared/services/api/documents/DocumentsService';
import { SinisterDocumentsService } from '../../../shared/services/api/sinister/SinisterDocumentsService';

type SinisterFilesModalContentPropsType = {
  sinisterId: string,
  listId: string,
  subTitle: string
  statusSinister?: string;
  setRefreshList?: (value: boolean) => void;
}

enum SinisterFilesModalTabs {
  FileUpload,
  FileList,
}
export const SinisterFilesModalContent = ({ sinisterId, listId, subTitle, statusSinister, setRefreshList }: SinisterFilesModalContentPropsType) => {
  const [fileTypeId, setFileTypeId] = useState<string>();
  const [file, setFile] = useState<File>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [documentTypes, setDocumentTypes] = useState<GetDocumentTypesResponseType[]>([]);
  const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<SinisterFilesModalTabs>(SinisterFilesModalTabs.FileList);
  const toastHandler = useToast();

  useEffect(() => {
    DocumentsService.getSinisterDocumentTypes(sinisterId, listId).then((result) => {
      setDocumentTypes(result);
      setIsLoading(false);
    }).catch((error) => {
      toastHandler.present({ type: 'error', messageOrContent: error.message, position: 'top-center' })
    })
  }, [])

  const handleChangeType = (event: SelectChangeEvent) => {
    setFileTypeId(event.target.value);
  };

  const onFileUploadHandler = (file: File | undefined) => {
    setFile(file)
  };

  const submitFile = async () => {
    if (file) {
      setIsUploading(true)

      try {
        await SinisterDocumentsService.uploadSinisterDocument(sinisterId, listId, 'otherAtachments', file);
        toastHandler.present({ type: 'success', messageOrContent: 'Arquivo enviado com sucesso!', position: 'top-center' })
        setFileUploadedSuccessfully(true)
        setActiveTab(SinisterFilesModalTabs.FileList)
        setTimeout(() => {
          setFileUploadedSuccessfully(false)
        }, 250)
      } catch (e: any) {
        toastHandler.present({ type: 'error', messageOrContent: e.message, position: 'top-center' })
      } finally {
        setIsUploading(false)
      }
    }
  };

  const validToUpload = useMemo(() => {
    return file //&& fileTypeId
  }, [file])

  const handleTabChange = (_: any, tab: SinisterFilesModalTabs) => {
    setActiveTab(tab)
  }

  if (isLoading) {
    return (
      <Box>
        <CircularProgress variant='indeterminate' />
      </Box>
    )
  }

  return (
    <>
      <Box>
        <Tabs value={activeTab} onChange={handleTabChange} variant={'fullWidth'} indicatorColor={'secondary'} textColor={'secondary'}>
          <Tab value={SinisterFilesModalTabs.FileList} label='Lista de Arquivos' disabled={isUploading} />
          <Tab value={SinisterFilesModalTabs.FileUpload} label='Envio de Arquivo' />
        </Tabs>

        {activeTab === SinisterFilesModalTabs.FileUpload && (
          <Box style={{
            minWidth: '25vw'
          }}>
            <InputLabel id="file_type_label">Tipo de Arquivo</InputLabel>
            {/*     <Select
              labelId="file_type_label"
              id="file_type_dropdown"
              value={fileTypeId}
              size="small"
              label="Tipo de Arquivo"
              onChange={handleChangeType}
              style={{
                minWidth: '100%'
              }
              }
            >
              {
                documentTypes.map(type => {
                  return (
                    <MenuItem value={type.id}>{type.description}</MenuItem>
                  )
                })
              }
            </Select>
          */}
            <SinisterFilesUploadArea onFileUploadHandler={onFileUploadHandler} isLoadingProp={isUploading} clear={fileUploadedSuccessfully} />
            <Button
              color="primary"
              disableElevation
              variant={"contained"}
              onClick={submitFile}
              disabled={!validToUpload || isUploading}
              style={{
                marginTop: '16px',
                width: '100%'
              }}
            >
              {'Confirmar'}
            </Button>
          </Box>
        )}
        {activeTab === SinisterFilesModalTabs.FileList && (
          <SinisterFilesList sinisterId={sinisterId} statusSinister={statusSinister} checkListId={listId}
          setRefreshList={value => setRefreshList?.(value)}></SinisterFilesList>
        )}
      </Box>
    </>
  )
}