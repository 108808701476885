import { Close } from "@mui/icons-material";
import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { ToolbarGridFilterProposal } from "../../../../../shared/components/toolbarGridProposal/ToolbarGridFilterProposal";
import { useToast } from "../../../../../shared/hooks";
import { IInstallmentProposal, ITotalStatusInstallmentProposal, ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";
import { colorText, globalFormatCurrencyBRL, globalTranslatePayment, globalTranslateStatus } from "../../../../../shared/utils";
import { returnMessageError } from "../../../../../shared/utils/returnMessageError";
import { ModalCancelInstallments } from "./ModalCancelInstallments";

interface ModalInstallmentsProps {
	isSuperAdmin: boolean;
	rows: any;
	isOpenModal: boolean;
	isClose: () => void;
}

const getColorForLine = (index: number) => (index % 2 === 0 ? "#e5e8eb" : "#ffffff");

const ModalInstallments: React.FC<ModalInstallmentsProps> = ({ isSuperAdmin, rows, isClose, isOpenModal }) => {
	const [statusInstallmentProposal, setStatusInstallmentProposal] = useState("Todas");
	const [isLoadingInstallmentProposal, setIsLoadingInstallmentProposal] = useState(false);
	const [rowsInstallment, setRowsInstallment] = useState<IInstallmentProposal[]>([]);
	const [rowsTotalInstallment, setRowsTotalInstallment] = useState<ITotalStatusInstallmentProposal>();
	const [openModalCancelInstallment, setOpenModalCancelInstallment] = useState(false);
	const [idInstallmentCancel, setIdInstallmentCancel] = useState("");
	const toastHandler = useToast();

	const handleOpenModalCancelInstallment = (id: string) => {
		setOpenModalCancelInstallment(true);
		setIdInstallmentCancel(id);
	};

	const fetchStatusInstallmentProposal = useCallback(() => {
		setIsLoadingInstallmentProposal(true);
		ProposalService.getStatusInstallmentProposal(statusInstallmentProposal, rows.id)
			.then((result) => {
				setIsLoadingInstallmentProposal(false);
				if (result instanceof Error) {
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title="Alerta!!" content={`Erro: ${result.message}`} />,
					});
				} else {
					setRowsInstallment(result);
				}
			})
			.catch((err) => {
				setIsLoadingInstallmentProposal(false);
				const { titleMessage, contentMessage } = returnMessageError(err);
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			});
	}, [statusInstallmentProposal, rows.id, toastHandler]);

	const fetchTotalStatusInstallmentProposal = useCallback(() => {
		setIsLoadingInstallmentProposal(true);
		ProposalService.getTotalStatusInstallmentProposal(rows.id)
			.then((result) => {
				setIsLoadingInstallmentProposal(false);
				if (result instanceof Error) {
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title="Alerta!!" content={`Erro: ${result.message}`} />,
					});
				} else {
					setRowsTotalInstallment(result);
				}
			})
			.catch((err) => {
				setIsLoadingInstallmentProposal(false);
				const { titleMessage, contentMessage } = returnMessageError(err);
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			});
	}, [rows.id, toastHandler]);

	useEffect(() => {
		if (isOpenModal) {
			fetchStatusInstallmentProposal();
			fetchTotalStatusInstallmentProposal();
		}
	}, [isOpenModal, statusInstallmentProposal]);

	const renderInstallmentRow = (row: IInstallmentProposal, index: number) => (
		<Grid container item direction="row" key={row.id} sx={{ backgroundColor: getColorForLine(index), p: 0.8 }}>
			<Grid item xs={12} sm={4}>
				<Typography variant="subtitle2">
					{row.typeAmount ? globalTranslatePayment(row.typeAmount) : ""} {row.typeAmount === "parcel" ? row.number : ""}
				</Typography>
				<Typography variant="caption">{row.obs}</Typography>
			</Grid>
			<Grid item xs={12} sm={2}>
				<Typography variant="subtitle2">Vencimento</Typography>
				<Typography variant="caption">{format(new Date(row.dueDate +' 03:00:00'), "dd/MM/yyyy")}</Typography> 
			</Grid>
			<Grid item xs={12} sm={2}>
				<Typography variant="subtitle2">Valor</Typography>
				<Typography variant="caption" sx={{ color: row.typeAmount === "discount" ? "red" : "inherit" }}>
					{row.typeAmount === "discount" ? `- ${globalFormatCurrencyBRL(row.amount)}` : globalFormatCurrencyBRL(row.amount)}
				</Typography>
			</Grid>
			<Grid item xs={12} sm={2}>
				<Typography variant="subtitle2">Status</Typography>
				<Typography variant="subtitle2" color={colorText(row.status)}>
					{globalTranslateStatus(row.status)}
				</Typography>
			</Grid>
			<Grid item xs={12} sm={1} alignContent={"center"}>
				{isSuperAdmin && row.status === "pending" && (
					<Button startIcon={<Close />} onClick={() => handleOpenModalCancelInstallment(row.id)} variant="outlined" size="small">
						Cancelar
					</Button>
				)}
			</Grid>
		</Grid>
	);

	return (
		<>
			<ModalContext
				isCloseModal={isClose}
				isOpenModal={isOpenModal}
				messageType={"question"}
				showButtonCancel={false}
				showButtonConfirm={false}
				showTitleIcon={false}
				textButtonCancel="Fechar"
				enableBackdropClose={true}
				overrideWidthValue={"50%"}
				overrideHeightValue={"100%"}
				ITitle={"Extrato de Garantia"}
				IMessage={[
					<Grid container>
						<Grid container display={"flex"} flexDirection={"column"} component={Box} padding={3} spacing={2}>
							<ToolbarGridFilterProposal
								onClickAll={() => setStatusInstallmentProposal("Todas")}
								onClickActive={() => setStatusInstallmentProposal("active")}
								onClickCanceled={() => setStatusInstallmentProposal("canceled")}
								selectAll={statusInstallmentProposal === "Todas"}
								selectAtive={statusInstallmentProposal === "active"}
								selectCanceled={statusInstallmentProposal === "canceled"}
								textButtonAll={`Todas (${rowsTotalInstallment?.total})`}
								textButtonActive={`Ativas (${rowsTotalInstallment?.ativo})`}
								textButtonCanceled={`Canceladas (${rowsTotalInstallment?.cancelada})`}
							/>
							{isLoadingInstallmentProposal && <LinearProgress sx={{ width: "100%" }} variant="indeterminate" />}
							{rowsInstallment.map(renderInstallmentRow)}
						</Grid>
					</Grid>,
				]}
			/>
			{openModalCancelInstallment && (
				<ModalCancelInstallments
					isOpenModal={openModalCancelInstallment}
					isClose={() => {setOpenModalCancelInstallment(false),fetchStatusInstallmentProposal()}}
					isSuperAdmin={isSuperAdmin}
					idInstallmentCancel={idInstallmentCancel}
				/>
			)}
		</>
	);
};

export default ModalInstallments;
