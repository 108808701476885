import { Clear, Search } from "@mui/icons-material";
import { Box, Checkbox, FormControlLabel, IconButton, InputAdornment, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { globalMaskDocument, globalTranslateStatus } from "../../../utils";

type TInstallmentsListProps = {
	id: string;
	client: string;
	status: string;
};

type TListProps = {
	id: string;
	label: string;
	document: string;
	status: string;
	Installments?: TInstallmentsListProps[];
};

interface IProps {
	rows: TListProps[];
	IDS?: (ids: string[]) => void;
}

export const ListTransport: React.FC<IProps> = ({ rows, IDS }) => {
	const [selectAll, setSelectAll] = useState(false);
	const [selectedItems, setSelectedItems] = useState<string[]>([]);
	const [search, setSearch] = useState("");
	const items = rows;

	const handleSelectAll = () => {
		if (selectAll) {
			setSelectedItems([]);
		} else {
			setSelectedItems(items.map((item) => item.id));
		}
		setSelectAll(!selectAll);
	};

	const handleSelectItem = (item: string) => {
		if (selectedItems.includes(item)) {
			setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
		} else {
			setSelectedItems([...selectedItems, item]);
		}
	};

	useEffect(() => {
		if (rows.length === 0) {
			setSelectedItems([]);
			setSelectAll(false);
		}
	}, [rows]);

	useEffect(() => {
		if (items.length > 0 && IDS) {
			IDS(selectedItems);
		}
	}, [selectedItems, items]);

	const FilteredItems = search.length > 0 ? items.filter((item) => item.label.toLowerCase().includes(search.toLowerCase())) : items;

	return (
		<Box display={"flex"} flexDirection={"row"}>
			<Box display={"flex"} flexDirection={"column"} gap={1}>
				<FormControlLabel
					label={
						items.length > 0 && (
							<>
								<Typography variant="body2" color="textPrimary" mb="8">
									<Typography variant="body1" color="textPrimary">
										Selecionar todos
									</Typography>
									<div style={{ display: "flex", alignItems: "center", gap: 8 }}>
										<Typography variant="body2" color="textSecondary">
											{selectedItems.length} de {items.length} selecionados
										</Typography>
									</div>
								</Typography>
							</>
						)
					}
					control={(items.length > 0 && <Checkbox checked={selectAll} onChange={handleSelectAll} color="primary" />) || <></>}
				/>
				<TextField
					size="small"
					value={search}
					placeholder="Buscar"
					onChange={(e) => setSearch(e.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="end">
								<IconButton aria-label="toggle password visibility" edge="start">
									<Search />
								</IconButton>
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton aria-label="toggle password visibility" edge="end" sx={{ color: "#dbd5d5" }} onClick={() => setSearch("")}>
									<Clear />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<List sx={{ maxHeight: 300, overflow: "auto", border: "1px solid #e0e0e0", borderRadius: 1, maxWidth: 500, padding: 2 }}>
					{items.length > 0
						? search.length > 0
							? FilteredItems.map((item) => (
									<ListItem key={item.id}>
										<Checkbox checked={selectedItems.includes(item.id)} color="primary" onChange={() => handleSelectItem(item.id)} />
										<ListItemText
											primary={
												<>
													<Typography variant="body2" color="textPrimary">
														{item.label} - ({globalTranslateStatus(item.status)})
													</Typography>
													<Typography variant="body2" color="textSecondary">
														{globalMaskDocument(item.document)}
													</Typography>
													<Typography variant="body2" color="textSecondary">
														{item.id}
													</Typography>
												</>
											}
										/>
									</ListItem>
							  ))
							: items.map((item) => (
									<ListItem key={item.id}>
										<Checkbox checked={selectedItems.includes(item.id)} color="primary" onChange={() => handleSelectItem(item.id)} />
										<ListItemText
											primary={
												<>
													<Typography variant="body2" color="textPrimary">
														{item.label} - ({globalTranslateStatus(item.status)})
													</Typography>
													<Typography variant="body2" color="textSecondary">
														{globalMaskDocument(item.document)}
													</Typography>
													<Typography variant="body2" color="textSecondary">
														{item.id}
													</Typography>
												</>
											}
										/>
									</ListItem>
							  ))
						: "Nenhum item encontrado"}
				</List>
			</Box>

			<List>
				{/* {items.length > 0
					? selectedItems.map((item) => (
							<ListItem key={item} sx={{ background: "red" }}>
								<ListItemText primary={item} />
							</ListItem>
					  ))
					: ""} */}
			</List>
		</Box>
	);
};
