import { useNavigate } from 'react-router-dom';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { Box, Button, Card, CardContent, CircularProgress, Divider, Grid, Icon, IconButton, LinearProgress, Pagination, Paper, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useProfile } from '../../../context/ProfileContext';
import { useDebounce, useToast } from '../../../shared/hooks';
import { ModalInstallment, ToastContent } from '../../../shared/components';
import { returnMessageError } from '../../../shared/utils/returnMessageError';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';
import { ProfileGuard } from '../../../shared/guards/ProfileGuard';
import { Environment } from '../../../shared/environment';
import { formatScaleValueBR } from '../../../shared/utils/formatScaleValue';
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import moment from "moment";
import { formatClientContractStatus, globalTranslate, globalTranslateStatus, maskCpfOrCnpj } from '../../../shared/utils';
import { ToolbarClientContract } from '../../../shared/components/toolbarClientContract/toolbarClientContract';
import { clientContractService, TClientContractAllPromise, IListagemClientContract, IDetalheClientContract } from '../../../shared/services/api/client-contract/clientContractService';
import { IMessage } from '../../../shared/types/message';
import { formatDateBR } from '../../../shared/utils/format-date';

function colorText(status: string) {
    if (status === "expired") {
        return "#f0e555";
    }
    if (status === "active") {
        return "#38df61";
    }
    if (status === "canceled") {
        return "#eb3232";
    }
    if (status === "suspended") {
        return "#eb3232";
    }
    if (status === "renovated") {
        return "#3761eb";
    }
    if (status === "paid") {
        return "#1ec448";
    }
    if (status === "billed") {
        return "#3761eb";
    }

    return "#202020";
}

function colorLine(parcela: number) {
    if (parcela % 2 == 0) {
        return "#e5e8eb";
    }
    return "#ffffff";
}

export const ClientContractSummaryPresentation = () => {

    const navigate = useNavigate();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [contractFilter, setContractFilter] = useState('');
    const { isSuperAdmin } = useProfile();
    const toastHandler = useToast();
    const { debounce } = useDebounce();
    const [status, setStatus] = useState('');
    const [statusClient, setStatusClient] = useState('');
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [rows, setRows] = useState<IListagemClientContract[]>([]);
    const [isLoadingShoot, setIsLoadingShoot] = useState(false);


    const getAllClientContract = () => {

        if (!contractFilter?.length) {
            const titleMessage = "Alerta!!";
            const contentMessage = "É necessário selecionar um contrato para realizar a busca!";

            toastHandler.present({
                type: 'error',
                position: 'top-right',
                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
            });

            return;
        }

        debounce(() => {
            setIsLoading(true);

            clientContractService.getAll(page, contractFilter, status, statusClient)
                .then((result: TClientContractAllPromise) => {
                    setIsLoading(false);

                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        });

                    } else {
                        setRows(result.results);
                        setTotalCount(result.total);
                    }

                }).catch(err => {
                    setIsLoading(false);
                    const { titleMessage, contentMessage } = returnMessageError(err);

                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    })
                })
        });
    }

    const onChangeStatus = (event: SelectChangeEvent) => {
        setStatus(event.target?.value);
    }

    const onChangeStatusClient = (event: SelectChangeEvent) => {
        setStatusClient(event.target?.value);
    }

    useEffect(() => {
        getAllClientContract();
    }, [page]);

    const handleShootQueue = (idContract: string | undefined) => {

        if (idContract) {
            debounce(() => {
                setIsLoadingShoot(true);

                clientContractService.shootQueue(idContract)
                    .then((result: IMessage<null> | Error) => {
                        setIsLoadingShoot(false);

                        if (result instanceof Error) {
                            const titleMessage = "Alerta!!";
                            const contentMessage = "Erro: " + result?.message;

                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            })
                        } else {
                            toastHandler.present({
                                type: 'success',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={result.message!} content={result.description!} />
                            });

                            getAllClientContract();
                        }

                    }).catch((err: any) => {
                        setIsLoadingShoot(false);
                        const { titleMessage, contentMessage } = returnMessageError(err);

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    });
            });

        } else {
            const titleMessage = "Aviso!!";
            const contentMessage = "Id deste contrato consta vazio para ser reenviado!";

            toastHandler.present({
                type: 'warning',
                position: 'top-right',
                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
            });
        }
    }


    return (
        <LayoutBaseDePagina
            titulo="Contratos Imobiliárias"
            subtitulo='Contratos Imobiliárias'
            barraDeFerramentas={
                <ToolbarClientContract
                    clickBack={() => navigate('/relatorios')}
                    onClick={getAllClientContract}
                    onChangeContractFilter={(value: string) => {
                        setContractFilter(value);
                    }}

                    loading={isLoading}
                    onChangeStatus={onChangeStatus}
                    onChangeStatusClient={onChangeStatusClient}
                />
            }
        >

            <Grid container display="flex" flexDirection="row">

                <ProfileGuard requiredRole={PermissionRolesEnum.ReportsOptions} requiredAction={PermissionActionsEnum.View}>
                    <TableContainer component={Paper} variant="outlined" sx={{ marginTop: 1, width: '96%', padding: '20px' }}>

                        <Table aria-label="a dense table" sx={{ marginTop: "18px" }}>
                            <TableHead>
                                <TableRow sx={{ background: '#f5fbfd' }} >
                                    <TableCell colSpan={4} sx={{ color: "#7e7777b5", textAlign: "center" }}> Nome/Razão Social/Documento</TableCell>
                                    <TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Tipo</TableCell>
                                    <TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Endereço</TableCell>
                                    <TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Status Assinatura </TableCell>
                                    <TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Data Assinatura </TableCell>
                                    <TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Data Solicitação </TableCell>
                                    <TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Ação </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!isLoading && (rows?.map((row, index) => (

                                    <TableRow key={index} hover>
                                        <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                                            <Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
                                                {`${row.client_id?.name},  ${row.client_id?.trade_name}, 
                                                ${maskCpfOrCnpj(row.client_id?.document)}`}
                                            </Typography>
                                        </TableCell>

                                        <TableCell sx={{ textAlign: "center", color: "#959595", fontWeight: "normal" }} >
                                            <Typography variant="subtitle2" fontWeight={"normal"} >
                                                {row.client_id?.type_person == "physical" ? "fisico" : "legal"}
                                            </Typography>
                                        </TableCell>

                                        <TableCell sx={{ textAlign: "center", color: "#959595", fontWeight: "normal" }} >
                                            <Typography variant="subtitle2" fontWeight={"normal"} >
                                                {row.client_id?.address_street}
                                            </Typography>
                                        </TableCell>

                                        <TableCell sx={{ textAlign: "center", color: "#959595", fontWeight: "normal" }} >
                                            <Typography variant="subtitle2" fontWeight={"normal"} >
                                                {formatClientContractStatus(row.statusSignature)}
                                            </Typography>
                                        </TableCell>

                                        <TableCell sx={{ textAlign: "center", color: "#959595", fontWeight: "normal" }} >
                                            <Typography variant="subtitle2" fontWeight={"normal"} >
                                                {row.signed_dateAt ? formatDateBR(row.signed_dateAt) : "-"}
                                            </Typography>
                                        </TableCell>

                                        <TableCell sx={{ textAlign: "center", color: "#959595", fontWeight: "normal" }} >
                                            <Typography variant="subtitle2" fontWeight={"normal"} >
                                                {"-"}
                                            </Typography>
                                        </TableCell>
                                        
                                        < TableCell sx={{ textAlign: "center" }}>
                                            {isLoadingShoot ? <CircularProgress size={24} sx={{ marginLeft: "15px", verticalAlign: "middle" }} variant='indeterminate' />
                                                :
                                                <Button
                                                    sx={{
                                                        fontWeight: "normal",
                                                        borderRadius: "10px",
                                                        width: "25%",
                                                        marginLeft: "15px",
                                                        textTransform: "none"
                                                    }}
                                                    disabled={isLoading || row.statusSignature == "signed_signature"}
                                                    size="small"
                                                    color="primary"
                                                    disableElevation
                                                    variant="outlined"
                                                    onClick={() => handleShootQueue(row.contractId)}
                                                    startIcon={<Icon>check</Icon>}
                                                >
                                                    {"Reenviar Disparo"}
                                                </Button>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )))}
                            </TableBody>
                            {totalCount === 0 && !isLoading && (
                                <caption style={{
                                    textAlign: "center", margin: 0,
                                    color: "black",
                                    fontSize: "1rem",
                                    lineHeight: 1.75,
                                    letterSpacing: "0.00938em"
                                }}>{Environment.LISTAGEM_VAZIA}</caption>
                            )}
                            <TableFooter>
                                {isLoading && (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <LinearProgress variant="indeterminate" />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {(totalCount > 0) && (
                                    <TableRow>
                                        <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                                            <Pagination
                                                page={page}
                                                count={Math.ceil(totalCount / Environment.LIMITE_LINHAS_GREAT)}
                                                onChange={(_, newPage) => setPage(newPage)}
                                            />
                                        </TableCell>
                                        <TableCell colSpan={5} sx={{ borderBottom: "none" }} >
                                            <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                                                Exibindo {" "}
                                                {(page * Environment.LIMITE_LINHAS_GREAT) - (Environment.LIMITE_LINHAS_GREAT - 1)}{" - "}
                                                {(Environment.LIMITE_LINHAS_GREAT * (page - 1)) +
                                                    (Environment.LIMITE_LINHAS_GREAT * page) < totalCount ? Environment.LIMITE_LINHAS_GREAT * page : totalCount} de
                                                <Typography variant="overline" sx={{ fontWeight: "bold", color: "#0170B3", fontSize: "16px" }}>
                                                    {" "}{totalCount} {" "}
                                                </Typography>
                                                registro(s)
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </ProfileGuard>
            </Grid>

        </LayoutBaseDePagina>

    );
};