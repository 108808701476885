import { Box, Button, Divider, Icon, Paper, Skeleton, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";


interface IFerramentaDetalheProps {
    marginTop?: any;

    textoButtonNew?: string;
    textButtonFollowUp?: string;

    showButtonNew?: boolean;
    showBotaoVoltar?: boolean;
    showBotaoApagar?: boolean;
    showBotaoSalvarFechar?: boolean;
    showBotaoSalvar?: boolean;


    showButtonNewLoading?: boolean;
    showBotaoVoltarLoading?: boolean;
    showBotaoApagarLoading?: boolean;
    showBotaoSalvarFecharLoading?: boolean;
    showBotaoSalvarLoading?: boolean;
    showButtonFollowUp?: boolean;
    showConfirm?: boolean;

    clicarNovo?: () => void;
    clicarVoltar?: () => void;
    clicarApagar?: () => void;
    clicarSalvar?: () => void;
    clicarSalvarFechar?: () => void;
    clickButtonFollowUp?: () => void;
    clickConfirm?: () => void;
}


export const FerramentaDetalhe: React.FC<IFerramentaDetalheProps> = ({
    marginTop = null,

    textoButtonNew = 'Novo',
    textButtonFollowUp = 'Follow Up',
    showButtonNew = true,
    showBotaoVoltar = true,
    showBotaoApagar = true,
    showBotaoSalvar = true,
    showBotaoSalvarFechar = true,
    showButtonFollowUp = false,
    showConfirm = false,


    showButtonNewLoading = false,
    showBotaoVoltarLoading = false,
    showBotaoApagarLoading = false,
    showBotaoSalvarFecharLoading = false,
    showBotaoSalvarLoading = false,

    clicarNovo,
    clicarVoltar,
    clicarApagar,
    clicarSalvar,
    clicarSalvarFechar,
    clickButtonFollowUp,
    clickConfirm,

}) => {
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            height={theme.spacing(5)}
            component={Paper}
            marginTop={marginTop}
        >
            {(showBotaoSalvar && !showBotaoSalvarLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    onClick={clicarSalvar}
                    startIcon={<Icon>check</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Salvar
                    </Typography>
                </Button>
            )}

            {showBotaoSalvarLoading && (
                <Skeleton width={110} height={60} ></Skeleton>
            )}

            {(showBotaoSalvarFechar && !showBotaoSalvarFecharLoading && !smDown && !mdDown) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clicarSalvarFechar}
                    startIcon={<Icon>file_download_done_outlined_icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Salvar e fechar
                    </Typography>
                </Button>
            )}

            {(showBotaoSalvarFecharLoading && !smDown && !mdDown) && (
                <Skeleton width={118} height={60} ></Skeleton>
            )}

            {(showBotaoApagar && !showBotaoApagarLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clicarApagar}
                    startIcon={<Icon>delete_outline</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Apagar
                    </Typography>
                </Button>
            )}

            {showBotaoApagarLoading && (
                <Skeleton width={110} height={60} ></Skeleton>
            )}

            {(showButtonNew && !showButtonNewLoading && !smDown) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clicarNovo}
                    startIcon={<Icon>add</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textoButtonNew}
                    </Typography>
                </Button>
            )}

            {(showButtonNewLoading && !smDown) && (
                <Skeleton width={110} height={60} ></Skeleton>
            )}

            {(showBotaoVoltar &&
                (showButtonNew || showBotaoApagar || showBotaoSalvar || showBotaoSalvarFechar)
            ) && (
                    <Divider variant="middle" orientation="vertical" />
                )
            }

            {(showBotaoVoltar && !showBotaoApagarLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clicarVoltar}
                    startIcon={<Icon>reply</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Voltar
                    </Typography>
                </Button>
            )}

            {showConfirm && (
                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    onClick={clickConfirm}
                    startIcon={<Icon>check</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Confirmar
                    </Typography>
                </Button>
            )}

            {showButtonFollowUp && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickButtonFollowUp}
                    startIcon={<Icon>format_list_numbered_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textButtonFollowUp}
                    </Typography>
                </Button>
            )}

            {showBotaoVoltarLoading && (
                <Skeleton width={110} height={60} ></Skeleton>
            )}
        </Box>
    );
};