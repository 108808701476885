import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, Icon, IconButton, Link, Tooltip, Typography } from "@mui/material"
import { IVFormErros, VForm, VTextField, useVForm } from "../../../shared/forms";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { ToolbarSinister } from "../../../shared/components/toolbarSinister/toolbarSinister";
import { ICanceledSinister, ISinisterDetails, ISinistercheckList, ITotalLmi, SinisterService } from "../../../shared/services/api/sinister/SinisterService";
import { checkByProfileGuard } from '../../../shared/guards/ProfileGuard';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';
import { GetDocumentTypesResponseType } from "../../../shared/services/api/documents/types";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useDebounce, useToast } from "../../../shared/hooks";
import { SinisterFilesModalContent } from "./SinisterFilesModalContent";
import { IDetalheUsuario, IListagemUsuario, userService } from "../../../shared/services/api/user/userService";
import { useProfile } from "../../../context/ProfileContext";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { globalTranslate } from "../../../shared/utils";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { SinisterFollowupModalContent } from "./SinisterFollowupModalContent";
import { ProvisionService } from "../../../shared/services/api/provisoes/ProvisionService";
import { SinisterHeader } from "./SinisterHeader/SinisterHeader";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import { Height } from "@mui/icons-material";
import moment from "moment";

export interface IOpenAttachmentParams {
    id: string;
    description: string;
}
interface IModalContextProps {
    onClickDown: () => void;
    onClickOpenAttachment: (params: IOpenAttachmentParams) => void;
    checkList?: ISinistercheckList[] | undefined;

}


export const ModalContextDocument: React.FC<IModalContextProps> = ({
    onClickDown,
    checkList,
    onClickOpenAttachment,

}) => {
    return (

        <Box style={{ width: "100%" }}>
            <Box
                margin={1}
                display="flex"
                flexDirection="column"
                component={Box}
            >
                <Grid container item direction="row" >
                    <Grid item sm={12} md={12} lg={9} xl={9} mb={"15px"} >
                        <Typography variant="subtitle2">
                            Para celeridade e regulação do seu sinistro, favor anexar os documentos solicitados abaixo,
                            para não correr risco de recusa no sinistro. Já começamos a contar o prazo de análise e pagamento
                            de sinistro após o recebimento da documentação completa.
                        </Typography>
                        <Typography variant="subtitle2">
                            Sao aceitos documentos ate 50MB
                        </Typography>
                    </Grid>
                    <Grid item sm={12} md={12} lg={3} xl={3} mb={"10px"} sx={{ display: "flex", justifyContent: "flex-end", paddingY: "10px" }}  >
                        <Tooltip title="Baixar todos os anexos" placement="top-start">
                            <Button
                                onClick={onClickDown}
                                // () => { handleDownloadAll() }}
                                color="primary"
                                disableElevation
                                variant={"outlined"}
                                startIcon={< Icon > download</Icon >}
                            >
                                Baixar Anexos
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Divider sx={{ mb: "15px" }} />

                {checkList && checkList?.map((row, key) => (
                    <>
                        <Grid container item direction="row" >
                            <Grid container xs={12} direction="column" justifyContent="left" alignItems="left" >
                                <Grid >
                                    <Accordion sx={{ backgroundColor: `${key % 2 ? 'white' : '#e7ebff'}` }}>
                                        <AccordionSummary sx={{ minHeight: 32 }}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Grid container justifyContent="left" alignItems="center">
                                                <Grid xs={9}>
                                                    <Typography variant="body2" display={"flex"} alignItems={"center"} >
                                                        {row.quant != '0' ?
                                                            <CheckCircleOutlinedIcon sx={{ color: 'green', mr: "8px" }} />
                                                            :
                                                            <CircleOutlinedIcon  color="primary" sx={{mr: "8px" }} />
                                                        }
                                                        {((row.ocupation != 'busy' && row.open == 'required') ||
                                                            (row.ocupation != 'open' && row.busy == 'required')) ?
                                                            `${row.description}  - Obrigatório`
                                                            :
                                                            `${row.description}`
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={3} display={"flex"} justifyContent="right" alignItems={"right"}>
                                                    <Button
                                                        onClick={() => onClickOpenAttachment({ id: row.Id, description: row.description })}
                                                        color="primary"
                                                        disableElevation
                                                        variant={"outlined"}
                                                    >
                                                        {row.quant != '0' ?
                                                            <>
                                                                <Typography variant="body2" display={"flex"} alignItems={"center"}>
                                                                    Anexos
                                                                </Typography>
                                                                <Tooltip title={`Enviar Documentos - Documentos Anexados ( ${row.quant} ) `} placement="top-start">
                                                                    <IconButton size="small">
                                                                        <AttachFileIcon sx={{ color: "tomato", fontWeight: "normal" }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                            :
                                                            <>
                                                                <Typography variant="body2" display={"flex"} alignItems={"center"}>
                                                                    Anexos
                                                                </Typography>
                                                                <Tooltip title="Enviar Documentos - Nenhum documento em anexo" placement="top-start">
                                                                    <IconButton size="small">
                                                                        < AttachFileIcon color="primary" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        }
                                                    </Button>

                                                </Grid>

                                            </Grid>
                                            {/* <Typography fontSize={14} sx={{ fontWeight: 800, Height: 20 }}>
                                                {row.sinisterDocuments && row.sinisterDocuments.length > 0 ? 'Anexos - datas:' : 'Sem Anexos'}

                                                {row.sinisterDocuments?.map((docRow, docKey) => (
                                                    ` ${docRow?.uploadedDate ? moment(docRow?.uploadedDate).format("L") + ' - ' : ''} `
                                                ))}
                                            </Typography> */}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Divider />
                                            <Typography>
                                                {row.sinisterDocuments?.map((docRow, docKey) => (
                                                    <>
                                                        <Grid>
                                                            <Link href={docRow?.url} color="inherit">
                                                                {docRow.originalFilename} - {docRow?.uploadedDate ? moment(docRow?.uploadedDate).format("DD/MM/YYYY HH:mm:ss") + ' - ' : ''}
                                                                download <SystemUpdateAltOutlinedIcon sx={{ color: 'black', mr: "10px", }} />
                                                            </Link>
                                                        </Grid>

                                                    </>
                                                ))}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} sx={{
                                backgroundColor: `${key % 2 ? 'white' : '#f1091c0d'}`,
                                justifyContent: 'flex-end',
                                paddingBottom: '5px'
                            }} display={"flex"} >

                            </Grid>
                        </Grid >
                    </>
                ))}
            </Box>
        </Box>

    )
}



