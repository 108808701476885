import { Api } from "../axios-config";
import { IRecoverPassword } from "./interfaces/IRecoverPassword";
import { ISavePassword } from "./interfaces/ISaveResetPassword";
import { IGetUserDataByResetTokenParams } from './interfaces/IGetUserDataByResetTokenParams';
import { IGetUserDataByResetTokenResponse} from './interfaces/IGetUserDataByResetTokenResponse';

const getUserDataByToken = async (request: IGetUserDataByResetTokenParams):Promise<IGetUserDataByResetTokenResponse> => {
    const { token } = request;

    try{
      const response = await Api().get(`/forget-password/${token}`);

      return response.data
    } catch(error){
      throw new Error((error as { message: string }).message || 'Dados não encontrados');
    }
}

const save = async (request: ISavePassword):Promise<number | Error> => {
    try{
        const response = await Api().post('/forget-password', request);
        return response.status
    } catch(error){
        throw new Error((error as { message: string }).message || 'Email não foi encontrado.');
    }
}

const recoverPassword = async (request: IRecoverPassword): Promise<number | Error> => {
    try{
        const response = await Api().patch('/forget-password', request);
        return response.status;
    } catch(error){
        throw new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
}   

export const ResetPasswordService = {
    save,
    recoverPassword,
    getUserDataByToken
};