import { Api } from "../axios-config";

export type TWalletTransfer = string | Error;


const getDataInfo = async (
    typeName: string = "",
    clientId: string = "",
    dataPort: string = ""
  ): Promise<any> => {
    try {
      const urlRelativa = `/wallet-transfer/info/transfer?typeName=${typeName}&clientId=${clientId}`;//&dataPort=${dataPort}`;
  
      const { data } = await Api().get(urlRelativa);
  
      if (data.results) {
        return {
          data: data.results,
          total: data.total,
        };
      }
  
      return new Promise((_, reject) =>
        reject(new Error("Erro ao listar os registros."))
      );
    } catch (error: any) {
      console.error(error);
      throw new Promise((_, reject) => reject(error));
    }
  };
  
  const transferProcess = async (
    clientOrigem: string = "",
    clientDestiny: string = "",
    dataPort: string = "",
    idsProposal: String[] = [],
  ): Promise<TWalletTransfer> => {
    try {
      const urlRelativa = `/wallet-transfer/info/transfer/process`;
  
      const { data } = await Api().post(urlRelativa, {
        clientOrigem,
        clientDestiny,
        idsProposal,
        //dataPort,
      });
  
      if(data) return data;
  
      return new Promise((_, reject) =>
        reject(new Error("Erro ao realizar transferência."))
      );
    } catch (error: any) {
      console.error(error);
      throw new Promise((_, reject) => reject(error));
    }
  };



export const walletTransferService = {
    getDataInfo,
    transferProcess,
  };