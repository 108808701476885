import { Autocomplete, CircularProgress, InputAdornment, TextField } from "@mui/material";
import { useField } from "@unform/core";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "../../hooks";
import { clientTypeService } from "../../services/api/clientType/clientTypeService";

type TAutoCompleteOption = {
    id: string;
    label: string;
}
interface IAutoCompleteClientTypeProps {
    isExternalLoading?: boolean;
    labelSelect?: string;
    namecampo: string;
}

export const AutoCompleteClientType: React.FC<IAutoCompleteClientTypeProps> = ({
    isExternalLoading = false,
    labelSelect = '',
}) => {

    const { fieldName, registerField, defaultValue, error, clearError } = useField('typeId');
    const { debounce } = useDebounce();
    const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);
    const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [busca, setBusca] = useState('');

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => selectedId,
            setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
        });
    }, [registerField, fieldName, selectedId]);


    useEffect(() => {
        setIsLoading(true);
        debounce(() => {
            clientTypeService.getAll(1, busca)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        //alert(result.message);
                    } else {
                        // console.log(result);
                        setOpcoes(result.data.map(ClientType => ({ id: ClientType.id, label: ClientType.description })));
                    }
                });
        });
    }, [busca]);


    const autoCompleteSelectedOption = useMemo(() => {
        if (!selectedId) return null;
        const selectedOption = opcoes.find(opcao => opcao.id === selectedId);
        if (!selectedOption) return null;
        return selectedOption
    }, [selectedId, opcoes]);

    return (
        <Autocomplete
            openText="Abrir"
            closeText="Fechar"
            noOptionsText="Nenhuma opção"
            loadingText="Loading ..."
            disablePortal
            // name ={namecampo}
            value={autoCompleteSelectedOption}
            options={opcoes}
            loading={isLoading}
            size={'small'}
            disabled={isExternalLoading}
            onInputChange={(_, newValue) => setBusca(newValue)}
            onChange={(_, newValue) => { setSelectedId(newValue?.id); setBusca(''); clearError(); }}
            popupIcon={isExternalLoading || isLoading ? <CircularProgress size={28} /> : undefined}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={labelSelect}
                    error={!!error}
                    helperText={error}
                />
            )}
        />
    )
};