import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  Checkbox,
  FormControlLabel,
  Grid,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
} from "@mui/material";
import * as yup from "yup";
import { useAuthContext } from "../../../context";
import { Navigate, useNavigate } from "react-router-dom";
import { useToast } from "../../../shared/hooks";
import { Loading, ToastContent } from "../../../shared/components";
import imageSite from "../../../assets/fundo_login.png";
import { AxiosError } from "axios";
import { url } from "inspector";


const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(5),
});

interface ILoginProps {
  children: React.ReactNode;
}
export const LoginPresentation: React.FC = () => {
  const { login } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const toastHandler = useToast();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await loginSchema.validate({ email, password }, { abortEarly: false });

      try {
        await login(email, password);
        navigate("/");
      } catch (e) {
        const messageTitle = "Erro no login";
        let messageContent =
          "Verifique a senha e o login digitado. Caso persista, entre em contato conosco.";

        if (e instanceof AxiosError && e.response?.status == 403) {

          if (e.response?.data?.message) {
            messageContent = e.response?.data?.message;
          }
        }

        toastHandler.present({
          type: "error",
          position: "top-right",
          messageOrContent: (
            <ToastContent title={messageTitle} content={messageContent} />
          ),
        });
      } finally {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);

      if (e instanceof yup.ValidationError) {
        e.inner.forEach((error) => {
          if (error.path === "email") {
            setEmailError(error.message);
          } else if (error.path === "password") {
            setPasswordError(error.message);
          }
        });
      }
    }
  };

  const handleForgotPassword = () => {
    setIsLoading(true);

    navigate("/forgot-password");
  };

  return (
      <Grid container height={"100vh"}>
        <Box
          zIndex={1}
          position={"absolute"}
          width={"100vw"}
          height={"100vh"}
          sx={{
            backgroundImage: `url(${imageSite})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{ width: "100%", height: "100%" }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Card
              sx={{
                borderRadius: "8px",
                maxWidth: "500px",
                height: "550px",
                marginLeft: smDown ? "" : "20%",
              }}
            >
              <CardContent
                sx={{ margin: "1rem 2rem", maxWidth: "400px", height: "300px" }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  width={400}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={"15px"}
                  >
                    <img
                      src="logo_garantti_login.png"
                      alt="Logo"
                      height={"80px"}
                    />
                  </Box>
                  <Typography variant="h6" align="center" color="GrayText">
                    Login de usuário
                  </Typography>
                  <Typography variant="caption" align="center">
                    Insira seu e-mail e sua senha abaixo para concluir seu login.
                  </Typography>
                  <TextField
                    fullWidth
                    type="email"
                    label="Email"
                    value={email}
                    disabled={isLoading}
                    error={!!emailError}
                    helperText={emailError}
                    onKeyDown={() => setEmailError("")}
                    onChange={(e) => setEmail(e.target.value)}
                    size={"small"}
                    sx={{ margin: "1rem 0" }}
                  />
                  <TextField
                    fullWidth
                    label="Senha"
                    type="password"
                    value={password}
                    disabled={isLoading}
                    error={!!passwordError}
                    helperText={passwordError}
                    onKeyDown={() => setPasswordError("")}
                    onChange={(e) => setPassword(e.target.value)}
                    size={"small"}
                  />
                </Box>
              </CardContent>
              <CardActions sx={{ margin: "1rem 2rem", maxWidth: "400px" }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  width="100%"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <FormControlLabel
                      control={<Checkbox name="lembre=me" />}
                      label="Lembre-me"
                    />
                    <Button
                      variant="text"
                      disabled={isLoading}
                      onClick={handleForgotPassword}
                    >
                      Esqueceu sua senha ?
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    onClick={handleSubmit}
                    endIcon={
                      isLoading ? (
                        <CircularProgress variant="indeterminate" />
                      ) : undefined
                    }
                    sx={{
                      width: "inherit",
                      background: "#7924c7",
                      margin: "1rem 0",
                    }}
                  >
                    Entrar
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </Box>
        </Box>
      </Grid>
  );
};
