import * as React from 'react';
import { Autocomplete, Box, Button, CircularProgress, FormControl, FormControlLabel, Grid, Icon, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Switch, SxProps, TextField, Theme, Tooltip } from "@mui/material";
import { Environment } from "../../environment";
import { useEffect, useMemo, useState } from "react";
import moment from 'moment';
import 'moment/locale/pt-br';
import { ListSearchParams } from '../../../pages/analysis/types/list-search-params.type';
import { useProfile } from '../../../context/ProfileContext';
import { useToast } from '../../../shared/hooks';
import { ToastContent } from '../../../shared/components';
import { returnMessageError } from '../../utils/returnMessageError';
import { Clear, ContentCopyRounded, PrintOutlined, Search } from '@mui/icons-material';
import { checkByProfileGuard } from '../../guards/ProfileGuard';
import { useNavigate } from 'react-router-dom';


interface IToolbarProps {

    mdCheckBox?: number;
    sxCheckBox?: SxProps<Theme>;
    sxFormControlLabel?: SxProps<Theme>;
    sxPaper?: SxProps<Theme>;


    showTitle?: boolean;
    onChangeTitle?: (text: string) => void;
    showStatusContract?: boolean;
    showDateRegister?: boolean;
    onChangeDateRegister?: (text: string) => void;
    showButtonFind?: boolean;
    onClickButtonFind?: () => void;
    textButtonFind?: string;
    isLoaddingButtonFind?: boolean;
    textoButtonNewContract?: string;
    showButtonNewContract?: boolean;
    onClickNovoContract?: () => void;
    showInputStatus?: boolean;
    onChangeStatus?: (text: string) => void;


    onChangeDataIni?: (text: string) => void;
    onChangeDataFim?: (text: string) => void;
    isDateStartDefault?: boolean;

    filterInitialParams?: ListSearchParams;


    showCheckBox?: boolean;
    textoCheckBox?: string;
    onChangeCheckBox?: (change: boolean) => void;

    showTransferButton?: boolean;

    showButtonReport?: boolean;
    onClickButtonReport?: () => void;
    textButtonReport?: string;
}

export const ToolbarContract: React.FC<IToolbarProps> = ({
    mdCheckBox = 3,
    sxCheckBox = {},
    sxFormControlLabel = {},

    showTitle = false,
    onChangeTitle,
    showStatusContract = false,
    showDateRegister = false,
    onChangeDateRegister,
    showButtonFind = false,
    onClickButtonFind,
    textButtonFind = "Buscar",
    isLoaddingButtonFind = false,
    textoButtonNewContract = 'Novo',
    showButtonNewContract = false,
    onClickNovoContract,


    showInputStatus = true,
    onChangeStatus,

    isDateStartDefault = false,


    textoCheckBox = "CheckBox ",
    showCheckBox = false,
    onChangeCheckBox,

    textButtonReport = 'Relatório',
    showButtonReport = false,
    onClickButtonReport,

    filterInitialParams,

    sxPaper = {},
}) => {
    const dateStartInitial = moment("01/01/2019").format("YYYY-MM-DD");
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState(filterInitialParams?.status ?? 'Todos');
    const [dataIni, setDataIni] = useState(isDateStartDefault ? dateStartInitial : filterInitialParams?.dataIni ?? moment().startOf('month').subtract(12, 'months').format("YYYY-MM-DD"));
    const [dataFim, setDataFim] = useState(filterInitialParams?.dataFim ?? moment().endOf('month').format("YYYY-MM-DD"));
    const { userClientId, isSuperAdmin, permissionLevel, registerClient } = useProfile();
    const toastHandler = useToast();
    const [statusCheckbox, setStatusCheckBox] = useState(filterInitialParams?.lmiSaldo ?? '0');
    const navigate = useNavigate();

    const handleChangeStatus = (event: SelectChangeEvent) => {
        setStatus(event.target.value as string);
        onChangeStatus?.(event.target.value as string);
    };

    const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeCheckBox?.(event.target.checked)
    }

    return (

        <Box
            gap={1}
            marginX={1}
            padding={2}
            paddingX={3}
            display="flex"
            sx={sxPaper}
            alignItems="space-beetwen"
            component={Paper}
        >
            <Grid container item direction="row" spacing={2}>
                <Grid container item direction="row" xs={12} md={(showButtonNewContract || showButtonReport) ? 10 : 12} rowGap={2}>

                    {showTitle && (
                        <Grid item xs={12} md={4} paddingRight={1}>
                            <TextField
                                fullWidth
                                label='Titulo'
                                name="title"
                                InputLabelProps={{ shrink: true, required: false }}
                                size="small"
                                type="text"
                                onChange={(e: any) => (
                                    onChangeTitle?.(e.target.value)
                                )}
                            >
                            </TextField>
                        </Grid>
                    )}

                    {showInputStatus && (
                        <Grid item xs={12} md={4} paddingRight={1}>
                            <FormControl fullWidth>
                                <InputLabel id="id_status">Status</InputLabel>
                                <Select
                                    labelId="label_Status"
                                    id="id_status"
                                    value={status}
                                    size="small"
                                    label="Status"
                                    onChange={handleChangeStatus}
                                >
                                    <MenuItem value={'Todos'}>Todos </MenuItem>
                                    <MenuItem value={'active'}>Ativo</MenuItem>
                                    <MenuItem value={'inactive'}>Inativo</MenuItem>
                                    {!showStatusContract && <MenuItem value={'pending'}>Pendente</MenuItem>}
                                    {showStatusContract && <MenuItem value={'canceled'}>Cancelado</MenuItem>}
                                    <MenuItem value={'blocked'}>Bloqueado</MenuItem>
                                    {showStatusContract && <MenuItem value={'sent'}>Enviado</MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {showDateRegister && (
                        <Grid item xs={12} md={3} paddingRight={1}>
                            <TextField
                                fullWidth
                                label='Data de Cadastro'
                                name="dateRegister"
                                InputLabelProps={{ shrink: true, required: false }}
                                size="small"
                                type="date"
                                onChange={(e: any) => {
                                    onChangeDateRegister?.(e.target.value)
                                }}
                            >
                            </TextField>
                        </Grid>
                    )}

                    <Grid container item direction="row" >

                        {showCheckBox && (
                            <Grid item xs={12} md={mdCheckBox} sx={sxCheckBox} >
                                <FormControlLabel control={
                                    <Switch
                                        onChange={handleChangeCheckBox}
                                        name='nameCheckBox'
                                        color="secondary"
                                        defaultChecked={(statusCheckbox && statusCheckbox == '1') ? true : false}
                                        disabled={false}
                                    />}
                                    label={textoCheckBox}
                                    sx={sxFormControlLabel}
                                />
                            </Grid>
                        )}
                    </Grid>

                </Grid>

                <Grid container item display="flex" direction="column" xs={12} md={2} gap={2}>

                    {showButtonNewContract && (
                        <Box display="flex" justifyContent="end">
                            <Button
                                fullWidth
                                color="primary"
                                disableElevation
                                variant="contained"
                                onClick={onClickNovoContract}
                                startIcon={<Icon>add</Icon>}
                            >
                                {textoButtonNewContract}
                            </Button>
                        </Box>
                    )}

                    {showButtonReport && (
                        <Box display="flex" justifyContent="end">
                            <Button
                                color="primary"
                                onClick={onClickButtonReport}
                                disableElevation
                                variant="outlined"
                                startIcon={<PrintOutlined />}
                            >
                                {textButtonReport}
                            </Button>
                        </Box>
                    )}


                    {showButtonFind && (

                        <Grid item xs={12} md={3} textAlign={isLoaddingButtonFind ? "center" : "end"}>
                            {isLoaddingButtonFind ? <CircularProgress variant='indeterminate' /> :
                                <Button
                                    sx={{
                                        border: "1px solid darkgray",
                                        marginTop: "3px",
                                        height: "40px",
                                        "&.MuiButtonBase-root:hover": {
                                            border: "1px solid darkgray",
                                        }
                                    }}
                                    fullWidth
                                    color="primary"
                                    disableElevation
                                    variant="contained"
                                    onClick={onClickButtonFind}
                                    startIcon={<Icon>search</Icon>}
                                >
                                    {textButtonFind}
                                </Button>
                            }
                        </Grid>

                    )}


                </Grid>
            </Grid>

        </Box>
    )
} 