import {
  Box,
  Button,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useState } from "react";
import { Clear } from "@mui/icons-material";
import { Environment } from "../../environment";
import { ListFinancialSearchParams } from "../../../pages/financial/types/list-financial-params.type";

interface IToolbarFinancialProps {
  showButtonBack?: boolean;
  showButtonBackLoading?: boolean;
  clickBack?: () => void;

  showSelectStatus?: boolean;
  showLimitInvoice?: boolean;
  showLimitClient?: boolean;
  showGroup?: boolean;
  showButtonExport?: boolean;
  showButtonConcilied?: boolean;
  shwoButtonReport?: boolean;

  showInputClientFilter?: boolean;
  onChangeClientFilter?: (text: string) => void;
  onChangeStatusInvoice?: (text: string) => void;
  onChangeLimitInvoice?: (text: string) => void;
  onChangeLimitClient?: (text: string) => void;
  onChangeGroup?: (text: string) => void;
  onClick?: () => void;
  onClickExport?: () => void;
  onclickConcilied?: () => void;
  onclickReport?: () => void;

  filterInitialParams?: ListFinancialSearchParams;

  showInputSearch?: boolean;
  loading?: boolean | undefined;

  textSearch?: string;
  onChangeTextSearch?: (newText: string) => void;
}

export const ToolbarFinancialHealth: React.FC<IToolbarFinancialProps> = ({
  showButtonBack = true,
  showButtonBackLoading = false,
  showInputSearch = true,
  showLimitInvoice = true,
  showLimitClient = true,
  showGroup = true,
  loading = false,
  clickBack,
  onClick,
  onClickExport,
  textSearch = "",
  onChangeTextSearch,
  onChangeStatusInvoice,
  onChangeLimitInvoice,
  onChangeLimitClient,
  onChangeGroup,
  onclickConcilied,
  onclickReport,
  showButtonConcilied = true,
  showButtonExport = false,
  showSelectStatus = true,
  shwoButtonReport = true,
  filterInitialParams,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [status, setStatus] = useState(filterInitialParams?.status ?? "Todos");
  const [limitInvoice, setLimitInvoice] = useState(
    filterInitialParams?.limit ?? "6"
  );
  const [limitClient, setLimitClient] = useState(
    filterInitialParams?.limit ?? "5"
  );
  const [isGroup, setIsGroup] = useState(filterInitialParams?.limit ?? "Todos");
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
    onChangeStatusInvoice?.(event.target.value as string);
  };

  const handleLimitInvoice = (event: SelectChangeEvent) => {
    setLimitInvoice(event.target.value as string);
    onChangeLimitInvoice?.(event.target.value as string);
  };

  const handleLimitClient = (event: SelectChangeEvent) => {
    setLimitClient(event.target.value as string);
    onChangeLimitClient?.(event.target.value as string);
  };

  const handleGroup = (event: SelectChangeEvent) => {
    setIsGroup(event.target.value as string);
    onChangeGroup?.(event.target.value as string);
  };

  return (
    <Box
      gap={1}
      marginX={1}
      paddingY={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      component={Paper}
    >
      <Grid container item direction={smDown ? "column" : "row"} gap={1}>
        {showButtonBack && !showButtonBackLoading && (
          <Tooltip title="Voltar para pagina inicial">
            <Button
              color="primary"
              disableElevation
              variant={"outlined"}
              disabled={isLoading}
              onClick={clickBack}
              startIcon={<Icon>keyboard_double_arrow_left_icon</Icon>}
            >
              <Typography
                variant="button"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                Voltar
              </Typography>
            </Button>
          </Tooltip>
        )}

        {showSelectStatus && !showButtonBackLoading && (
          <FormControl sx={{ width: "auto", minWidth: "200px" }}>
            <InputLabel id="id_status">Boletos</InputLabel>
            <Select
              labelId="label_Status"
              id="id_status"
              value={status}
              size="small"
              label="Boletos"
              onChange={handleChangeStatus}
            >
              <MenuItem value={"Todos"}>Todos</MenuItem>
              <MenuItem value={"open"}>Em aberto</MenuItem>
              <MenuItem value={"towin"}>A vencer</MenuItem>
              <MenuItem value={"overdue"}>Vencidos</MenuItem>
              <MenuItem value={"paid"}>Pagos</MenuItem>
              <MenuItem value={"canceled"}>Cancelados</MenuItem>
              <MenuItem value={"towin_pending"}>Faturados a Vencer</MenuItem>
              <MenuItem value={"overdue_pending"}>Faturados Vencidos</MenuItem>
            </Select>
          </FormControl>
        )}

        {showLimitClient && (
          <FormControl sx={{ width: "auto", minWidth: "160px" }}>
            <InputLabel id="limit">Clientes por pagina </InputLabel>
            <Select
              labelId="label_Status"
              id="limite_Client"
              value={limitClient}
              size="small"
              label="Clientes por pagina"
              onChange={handleLimitClient}
            >
              <MenuItem value={"5"}> 05</MenuItem>
              <MenuItem value={"10"}>10</MenuItem>
              <MenuItem value={"25"}>25</MenuItem>
              <MenuItem value={"50"}>50</MenuItem>
            </Select>
          </FormControl>
        )}

        {showLimitInvoice && (
          <FormControl sx={{ width: "auto", minWidth: "160px" }}>
            <InputLabel id="limit">Boletos por cliente </InputLabel>
            <Select
              labelId="label_Status"
              id="limite_invoice"
              value={limitInvoice}
              size="small"
              label="Boletos por cliente"
              onChange={handleLimitInvoice}
            >
              <MenuItem value={"6"}> 06</MenuItem>
              <MenuItem value={"12"}>12</MenuItem>
              <MenuItem value={"18"}>18</MenuItem>
              <MenuItem value={"24"}>24</MenuItem>
              <MenuItem value={"36"}>36</MenuItem>
            </Select>
          </FormControl>
        )}

        {showGroup && (
          <FormControl sx={{ width: "auto", minWidth: "150px" }}>
            <InputLabel id="limit">Agrupamento </InputLabel>
            <Select
              labelId="label_Status"
              id="isGrouped"
              value={isGroup}
              size="small"
              label="Agrupamento"
              onChange={handleGroup}
            >
              <MenuItem value={"Todos"}>Todos</MenuItem>
              <MenuItem value={"grouped"}>Somente agrupados</MenuItem>
            </Select>
          </FormControl>
        )}

        {showInputSearch && (
          <>
            <TextField
              sx={{ minWidth: "300px" }}
              size="small"
              value={textSearch}
              placeholder={Environment.INPUT_BUSCA}
              onChange={(e) => onChangeTextSearch?.(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      sx={{ color: "#dbd5d5" }}
                      onClick={() => {
                        {
                          onChangeTextSearch?.(""), onClick;
                        }
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Tooltip title="Clique para Pesquisar">
              <Button
                color="primary"
                sx={{ width: "auto", paddingX: 3 }}
                disableElevation
                variant={"contained"}
                disabled={isLoading}
                onClick={onClick}
                startIcon={<Icon>search</Icon>}
              >
                <Typography
                  variant="button"
                  whiteSpace="nowrap"
                  textOverflow="clip"
                >
                  Pesquisar
                </Typography>
              </Button>
            </Tooltip>

            {showButtonExport && (
              <Tooltip title="Clique para Exportar">
                <Button
                  color="primary"
                  sx={{ width: "auto", paddingX: 3 }}
                  disableElevation
                  variant={"outlined"}
                  disabled={isLoading}
                  onClick={onClickExport}
                  startIcon={<Icon>download</Icon>}
                >
                  <Typography
                    variant="button"
                    whiteSpace="nowrap"
                    textOverflow="clip"
                  >
                    Exportar Csv
                  </Typography>
                </Button>
              </Tooltip>
            )}

            {shwoButtonReport && (
              <Tooltip title="Clique para Exportar relatório">
                <Button
                  color="primary"
                  sx={{ width: "auto", paddingX: 3 }}
                  disableElevation
                  variant={"outlined"}
                  disabled={isLoading}
                  onClick={onclickReport}
                  startIcon={<Icon>system_update_alt_icon</Icon>}
                >
                  <Typography
                    variant="button"
                    whiteSpace="nowrap"
                    textOverflow="clip"
                  >
                    Relatório
                  </Typography>
                </Button>
              </Tooltip>
            )}

            {showButtonConcilied && (
              <Tooltip title="Clique para Conciliar Boletos">
                <Button
                  color="primary"
                  sx={{ width: "auto", paddingX: 3 }}
                  disableElevation
                  variant={"outlined"}
                  disabled={isLoading}
                  onClick={onclickConcilied}
                  startIcon={<Icon>currency_exchange_Icon</Icon>}
                >
                  <Typography
                    variant="button"
                    whiteSpace="nowrap"
                    textOverflow="clip"
                  >
                    Conciliação
                  </Typography>
                </Button>
              </Tooltip>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};
