import { format, differenceInDays } from "date-fns";

export const renderRenovationStatus = (row: any) => {
	const expiresAtDate = new Date(row.expiresAt.substring(0, 10) + " 03:00:01");
	const currentDate = new Date();
	// Caso renovado, exibe a data de renovação
	if (row.renovateAt) {
		return `Renovada em: ${format(new Date(row.renovateAt), "dd/MM/yyyy")}`;
	}
	// Expiração nos próximos 60 dias
	const daysToExpiration = differenceInDays(expiresAtDate, currentDate);
	if (daysToExpiration >= 0 && daysToExpiration <= 60) {
		return daysToExpiration === 0 ? "Atenção: expira Hoje" : `Restam ${daysToExpiration} dia(s)`;
	}
	// Caso não haja condição a ser tratada
	return "";
};
