import { AuthGuard } from '../../shared/guards/AuthGuard';
import { Sidebar } from '../../shared/components';
import {  ReportUpdateProposalPresentation } from './components';

export const ReportUpdateProposalPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <ReportUpdateProposalPresentation  />
            </Sidebar>
        </AuthGuard>
    )
};