import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControlLabel, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { LayoutBaseDePagina } from "../../../../shared/layouts"
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthContext } from "../../../../context";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../../shared/enums/profiles";
import 'moment/locale/pt-br';
import { ProfileGuard, checkByProfileGuard } from "../../../../shared/guards/ProfileGuard";
import { useDebounce } from "../../../../shared/hooks";
//import { VForm, VTextField, useVForm } from "../../../../shared/forms";
import { IVFormErros, VForm, VTextField, useVForm } from "../../../../shared/forms";
import { useState } from "react";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { BudgetResume } from "./BudgetResume";
import { AccordionResumeFire } from "./AccordionResume";


export const BudgetPresentation: React.FC = () => {

  const navigate = useNavigate();
  const { loggedUser } = useAuthContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const { formRef } = useVForm();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LayoutBaseDePagina
      titulo='Seguro Incêndio'
      subtitulo={'Cotação / Contratação'}
    >
      {/* <ProfileGuard requiredRole={PermissionRolesEnum.FinancialOptions} requiredAction={PermissionActionsEnum.View}> */}
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <TableContainer component={Paper} variant="outlined" sx={{ marginLeft: 1, marginTop: 2, marginRight: 0, width: '70%', padding: 3 }} aria-label="customized table">
          <Table aria-label="a dense table"  >
            <TableHead >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <Grid item xs={11} >
                  <Typography variant="h6" color={'primary'}>Dados do Seguro</Typography>
                </Grid>
                <Button
                  variant="contained"
                  onClick={() => navigate('/dashboard')}
                >
                  Voltar
                </Button>
              </Box>
            </TableHead>
            <TableBody>
              <Box marginBottom={2}>

              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <Grid item xs={11} >
                  <Typography variant="h6" color={'primary'}>Nossas Coberturas</Typography>
                </Grid>
              </Box>

            </TableBody>
          </Table>
        </TableContainer>
        <AccordionResumeFire
          propertyType='Residencial'
          propertyOccupation=''
          assistance=''
          rentAmount=''
          PL=''
          personCommission=''
          propertyAddressStreet=''
          propertyAddressNumber=''
          propertyAddressComplement=''
          propertyAddressDistrict=''
          propertyAddressCity=''
          propertyAddressState=''
          propertyAddressZipCode=''
          tenantName=''
          tenantDocument=''
          tenantPhone=''
          tenantEmail=''
          tenantBirthdate=''
          locatorName=''
          locatorDocument=''
          locatorPhone=''
          locatorEmail=''
          locatorBirthdate=''
        />       
      </Box>


      {/* </ProfileGuard> */}
    </LayoutBaseDePagina>
  )
}