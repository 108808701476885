import { IFormData } from "./interfaces";
import * as yup from "yup";

export const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
	clientId: yup.string().required("Campo Obrigatório"),
	typePerson: yup.string().required("Campo Obrigatório"),
	document: yup.string().min(11).required("Campo Obrigatório"),
	name: yup.string().min(3).required("Campo Obrigatório"),
	status: yup.string().notRequired().default(""),
	birthDate: yup.string().required("Campo Obrigatório"),
	motherName: yup.string().min(3).required("Campo Obrigatório"),

	maritalStatus: yup.string().required("Campo Obrigatório"),
	sex: yup.string().required("Campo Obrigatório"),
	email: yup.string().min(3).required("Campo Obrigatório"),
	phone: yup.string().min(11).required("Campo Obrigatório"),

	propertyType: yup.string().required("Campo Obrigatório"),
	propertyFinally: yup.string().required("Campo Obrigatório"),
	addressZipcode: yup.string().required("Campo Obrigatório"),
	addressStreet: yup.string().required("Campo Obrigatório"),
	addressNumber: yup.string().required("Campo Obrigatório"),
	addressComplement: yup.string().notRequired().default(""),
	addressCity: yup.string().required("Campo Obrigatório"),
	addressState: yup.string().required("Campo Obrigatório"),
	addressIBGE: yup.string().required("Campo Obrigatório"),
	addressDistrict: yup.string().required("Campo Obrigatório"),

	isTipping: yup.string().notRequired().default(""),
	sendSerasa: yup.string().notRequired().default(""),
	sendClick: yup.string().notRequired().default(""),
	serasaResponse: yup.string().when("sendSerasa", {
		is: "not_send_serasa",
		then: yup.string().nullable().required("Campo Obrigatório"),
	}),

	isContractRunner: yup.string().notRequired().default(""),
	contractBegin: yup.string().when("isContractRunner", {
        is: "1",
        then: yup.string().nullable().required("Campo Obrigatório"),
        otherwise: yup.string().nullable(),
    }),
    contractEnd: yup.string().when("isContractRunner", {
        is: "1",
        then: yup.string().nullable().required("Campo Obrigatório"),
        otherwise: yup.string().nullable(),
    }),

	serasaAnalysisStatus: yup.string().notRequired().default(""),
	paymentMethod: yup.string().notRequired().default(""),
	assuranceMonthAmount: yup.string().notRequired().default(""),
	rentMonthsCount: yup.string().required("Campo Obrigatório"),
	rentAmount: yup.string().required("Campo Obrigatório"),
	condominiumAmount: yup.string().required("Campo Obrigatório"),
	electricEnergyAmount: yup.string().required("Campo Obrigatório"),
	waterAmount: yup.string().required("Campo Obrigatório"),
	gasAmount: yup.string().required("Campo Obrigatório"),
	iptuAmount: yup.string().required("Campo Obrigatório"),
	rentalCost: yup.string().required("Campo Obrigatório"),
	lmi: yup.string().required("Campo Obrigatório"),
	lmiAmount: yup.string().required("Campo Obrigatório"),
	relation: yup.string().notRequired().default(""),
	planlist: yup.string().notRequired().default(""),
	employmentRelationShip: yup.string().required("Campo obrigatório"),

	createdFrom: yup.string().notRequired().default(""),
	plan: yup
		.array()
		.of(
			yup.object().shape({
				// Defina o esquema dos itens em plan, dependendo da estrutura de IPlan
				id: yup.string().required("Campo Obrigatório"),
				name: yup.string().required("Campo Obrigatório"),
				description: yup.string().notRequired().default(""),
				amount: yup.number().required("Campo Obrigatório"),
			})
		)
		.optional(), // Define 'plan' como um array opcional
});
