import { Email } from "@mui/icons-material";
import { Box, Button, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Paper, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AutoCompleteClient, ToastContent } from "../../../../shared/components";

import { Environment } from "../../../../shared/environment";
import { VTextField } from "../../../../shared/forms";
import { VMaskTextField } from "../../../../shared/forms/VMaskTextField";
import { useToast } from "../../../../shared/hooks";
import { PeopleService } from "../../../../shared/services/api/people/PeopleService";
import { globalColorTytle, globalMaskDocument } from "../../../../shared/utils";
import LoadingModal from "../../../../shared/components/LoadingModalDialog/LoadingModalDialog";
import LoadingDialog from "../../../../shared/components/LoadingModalDialog/LoadingModalDialog";

interface TenantDataSectionProps {
	formRef: any;
	isLoading: boolean;
	permissionLevel: number | null;
	Rows: any;
	id: string;
	isSendSerasa: (valor: boolean) => void;
	isTypping: (valor: boolean) => void;
}

const TenantDataSection: React.FC<TenantDataSectionProps> = ({ formRef, isLoading, permissionLevel, Rows, id, isSendSerasa, isTypping }) => {
	const [cpfValue, setCpfValue] = useState("");
	const [enableReason, setEnableReason] = useState(true);
	const [clientId, setClientId] = useState("");
	const [clientType, setClientType] = useState("physical");
	const [sendSerasaChecked, setSendSerasaChecked] = useState(true);
	const [isTippingChecked, setIsTippingChecked] = useState(false);
	const toastHandler = useToast();
	const [isLoadingDocument, setLoadingDocument] = useState(false);

	// Alterna o estado de envio para Serasa e habilita/desabilita campo de justificativa
	const handleChangeSerasa = (event: React.ChangeEvent<HTMLInputElement>) => {
		const isChecked = event.target.checked;
		setSendSerasaChecked(isChecked);
		isSendSerasa(isChecked);
		setEnableReason(isChecked);
	};

	// Alterna estado de tombamento
	const handleTipping = (event: React.ChangeEvent<HTMLInputElement>) => {
		const isChecked = event.target.checked;
		setIsTippingChecked(isChecked);
		isTypping(isChecked);
	};

	// Exibe toast com erro
	const showToastError = (message: string) => {
		toastHandler.present({
			type: "error",
			position: "top-right",
			messageOrContent: <ToastContent title="Erro" content={message} />,
		});
	};

	// Limpa campos de nome, data de nascimento e nome da mãe
	const clearFields = () => {
		formRef.current?.setFieldValue("name", "");
		formRef.current?.setFieldValue("birthDate", "");
		formRef.current?.setFieldValue("motherName", "");
		formRef.current?.setFieldValue("email", "");
		formRef.current?.setFieldValue("phone", "");
		formRef.current?.setFieldValue("employmentRelationShip", "");
		formRef.current?.setFieldValue("sex", "");
		formRef.current?.setFieldValue("maritalStatus", "");
	};

	// Função para manipular o CPF e fazer validação
	const handleCPF = (value: string) => {
		const documento = value.replace(/\D/g, "").trim();
		setCpfValue(documento);
		
		if (documento.length < 11) return; // CPF incompleto
		setLoadingDocument(true);
		// Verifica se o documento está na lista de números de teste e se o ambiente é DEV
		if (Environment.AMBIENTE === "DEV" && Array.from({ length: 11 }, (_, index) => `0000000000${index}`).includes(documento)) {
			formRef.current?.setFieldValue("name", `Cliente Teste ${documento.slice(-2)}`);
			formRef.current?.setFieldValue("birthDate", "2000-05-01");
			formRef.current?.setFieldValue("motherName", `Mae do Cliente Teste ${documento.slice(-2)}`);
			formRef.current?.setFieldValue("email", `teste${documento.slice(-2)}@garantti.com`);
			formRef.current?.setFieldValue("phone", `889000000$${documento.slice(-2)}`);
			formRef.current?.setFieldValue("employmentRelationShip", "student");
			formRef.current?.setFieldValue("sex", "male");
			formRef.current?.setFieldValue("maritalStatus", "single");
			setLoadingDocument(false);
			return;
		}

		PeopleService.getPersonDataByDocument(documento)
			.then((data) => {
				if (!data) {
					showToastError(`Documento ${globalMaskDocument(value)} não localizado. Verifique se está correto.`);
					clearFields();
					setLoadingDocument(false);
				} else {
					const { name, birthDate, motherName } = data.naturalPersonData || {};
					formRef.current?.setFieldValue("name", name || "");
					formRef.current?.setFieldValue("birthDate", birthDate || "");
					formRef.current?.setFieldValue("motherName", motherName || "");
					setLoadingDocument(false);
				}
			})
			.catch(() => {
				showToastError(`Erro na pesquisa do documento ${globalMaskDocument(value)}`);
				setLoadingDocument(false);
			});
	};

	useEffect(() => {
		setClientId(Rows?.clientId);
	}, [Rows]);

	return (
		<>
			<LoadingDialog isLoading={isLoadingDocument} loadingText={`Localizando documento...`} />
			<Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
				<Grid container direction="column" padding={2} spacing={2}>
					<Grid item xs={11}>
						<Typography variant="h6" color={globalColorTytle}>
							Dados do Inquilino
						</Typography>
					</Grid>
					{/* Campo de autocomplete e aviso */}
					<Grid container item direction="row" spacing={2} marginBottom={2}>
						<Grid item xs={12} sm={12} md={4}>
							<AutoCompleteClient
								isExternalLoading={isLoading}
								namecampo="clientId"
								labelSelect="Corretor / Imobiliaria"
								clientDefault={clientId}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={8}>
							<Paper variant="outlined" sx={{ padding: "0.2% 2.3%", borderRadius: 2, border: "1px solid #5d07ce" }}>
								<Typography variant="caption" color="primary">
									Atenção: É obrigatório o preenchimento do e-mail e celular do locatário. O preenchimento do e-mail e/ou celular que não seja
									do locatário poderá ocasionar em recusa ou em não contratação da garantia.
								</Typography>
							</Paper>
						</Grid>
					</Grid>

					{/* Campos principais do formulário */}
					<Grid container item direction="row" spacing={2}>
						<Grid item xs={12} sm={12} md={4}>
							<VTextField fullWidth label="Tipo de Cliente" name="typePerson" disabled select onChange={(e) => setClientType(e.target.value)}>
								<MenuItem value="physical">Física</MenuItem>
								<MenuItem value="legal">Jurídica</MenuItem>
							</VTextField>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<VMaskTextField
								fullWidth
								label={clientType === "physical" ? "CPF" : "Documento"}
								name="document"
								disabled={id !== "novo" || isLoading}
								mask={clientType === "physical" ? "cpf" : "cnpj"}
								value={cpfValue}
								onChange={(e) => setCpfValue(e.target.value)}
								onBlur={(e) => handleCPF(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<VTextField fullWidth label="Nome" name="name" disabled />
						</Grid>
					</Grid>

					{/* Campos adicionais */}
					<Grid container item direction="row" spacing={2}>
						<Grid item xs={12} sm={12} md={4}>
							<VTextField fullWidth label="Nome da Mãe" name="motherName" disabled={isLoading} />
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<VTextField fullWidth label="Data de Nascimento" name="birthDate" disabled type="date" />
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<VTextField
								fullWidth
								label="Email"
								name="email"
								disabled={isLoading}
								trimEmptySpaces
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton aria-label="toggle email visibility" edge="end">
												<Email />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>

					{/* Campos de relação de trabalho, sexo e estado civil */}
					<Grid container item direction="row" spacing={2}>
						<Grid item xs={12} sm={12} md={4}>
							<VTextField fullWidth label="Relação de Trabalho" name="employmentRelationShip" disabled={isLoading} select>
								<MenuItem value="retired">Aposentado</MenuItem>
								<MenuItem value="freelance">Autônomo</MenuItem>
								<MenuItem value="entrepreneur">Empresário</MenuItem>
								<MenuItem value="student">Estudante</MenuItem>
								<MenuItem value="civil_servant">Funcionário Público</MenuItem>
								<MenuItem value="private_servant">Funcionário CLT</MenuItem>
								<MenuItem value="liberal">Liberal</MenuItem>
								<MenuItem value="rent_income">Renda Aluguéis</MenuItem>
							</VTextField>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<VTextField fullWidth label="Sexo" name="sex" disabled={isLoading} select>
								<MenuItem value="male">Masculino</MenuItem>
								<MenuItem value="female">Feminino</MenuItem>
							</VTextField>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<VTextField fullWidth label="Estado Civil" name="maritalStatus" disabled={isLoading} select>
								<MenuItem value="single">Solteiro</MenuItem>
								<MenuItem value="married">Casado</MenuItem>
								<MenuItem value="widower">Viúvo</MenuItem>
								<MenuItem value="divorced">Divorciado</MenuItem>
								<MenuItem value="stable_union">União Estável</MenuItem>
							</VTextField>
						</Grid>
					</Grid>

					{/* Campos de telefone e celular */}
					<Grid container item direction="row" spacing={2}>
						<Grid item xs={12} sm={12} md={4}>
							<VMaskTextField fullWidth label="Celular" name="phone" disabled={isLoading} mask="cellphone" />
						</Grid>
						<Grid item xs={12} md={4}>
							<VMaskTextField fullWidth label="Telefone" name="mobilePhone" disabled={isLoading} mask="cell" />
						</Grid>
					</Grid>

					{/* Configurações adicionais para perfil com permissão */}
					{permissionLevel === 1 && (
						<Grid container item direction="row" spacing={2}>
							<Grid item xs={12} md={2}>
								<FormControlLabel
									control={<Switch onChange={handleTipping} name="isTipping" color="secondary" checked={isTippingChecked} />}
									label="Tombamento"
								/>
							</Grid>
							<Grid item xs={12} md={2}>
								<FormControlLabel
									control={<Switch onChange={handleChangeSerasa} name="sendSerasa" color="primary" checked={sendSerasaChecked} />}
									label="Enviar Serasa"
								/>
							</Grid>
							<Grid item xs={12} md={8}>
								<VTextField fullWidth label="Motivo não envio para Serasa" name="serasaResponse" disabled={enableReason} />
							</Grid>
						</Grid>
					)}
				</Grid>
			</Box>
		</>
	);
};

export default TenantDataSection;
