import * as React from 'react';
import { Box, Button, FormControl, Grid, Icon, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { Environment } from "../../environment";
import { useState } from "react";
import moment from 'moment';
import 'moment/locale/pt-br';

interface IToolbarGridSinisterValueProps {
    textSearch?: string;
    showInputBusca?: boolean;
    onChangeTextBusca?: (newText: string) => void;

    showInputStatus?: boolean;
    onChangeStatus?: (text: string) => void;

    showInputOrigem?: boolean;
    onChangeOrigem?: (text: string) => void;

    showPeriodo?: boolean;

    onChangeDataIni?: (text: string) => void;
    onChangeDataFim?: (text: string) => void;

    textButtonNew?: string;
    showButtonNew?: boolean;
    onClickNovo?: () => void;

    textButtonConfirm?: string;
    showButtonConfirm?: boolean;
    onClickConfirm?: () => void;

    style?: React.CSSProperties;
}


export const ToolbarGridSinisterValue: React.FC<IToolbarGridSinisterValueProps> = ({
    textSearch = '',
    showInputBusca = true,
    onChangeTextBusca,

    showInputStatus = true,
    onChangeStatus,

    showInputOrigem = true,
    onChangeOrigem,

    showPeriodo = true,
    onChangeDataIni,
    onChangeDataFim,

    textButtonNew = 'Novo',
    showButtonNew = true,
    onClickNovo,

    textButtonConfirm = '',
    showButtonConfirm = true,
    onClickConfirm,

    style = {}

}) => {
    const theme = useTheme();
    const [status, setStatus] = useState('Todos');
    const handleChangeStatus = (event: SelectChangeEvent) => {
        setStatus(event.target.value as string);
        onChangeStatus?.(event.target.value as string)
    };

    const [origem, setOrigem] = useState('Todos');
    const handleChangeOrigem = (event: SelectChangeEvent) => {
        setOrigem(event.target.value as string);
        onChangeOrigem?.(event.target.value as string)
    };

    return (
        <Box
            gap={1}
            marginX={1}
            display="flex"
            alignItems="space-beetwen"
            style={style}
        >


            <Grid container item direction="row" spacing={2}>
                <>
                    <Grid item xs={12} sm={12} lg={2} md={2}>
                        {showInputStatus && (
                            <FormControl fullWidth>
                                <InputLabel id="id_status">Status</InputLabel>
                                <Select
                                    labelId="label_Status"
                                    id="id_status"
                                    value={status}
                                    size="small"
                                    label="Status"
                                    onChange={handleChangeStatus}
                                >
                                    <MenuItem value={'Todos'}>Todos </MenuItem>
                                    <MenuItem value={'active'}>Ativo</MenuItem>
                                    <MenuItem value={'Inactive'}>Inativo</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                </>

                <>
                    <Grid item xs={12} sm={12} lg={2} md={2}>
                        {showInputOrigem && (
                            <FormControl fullWidth>
                                <InputLabel id="id_origem">Origem</InputLabel>
                                <Select
                                    labelId="label_Origem"
                                    id="id_origem"
                                    value={origem}
                                    size="small"
                                    label="Origem"
                                    onChange={handleChangeOrigem}
                                >
                                    <MenuItem value={'Todos'}>Todos</MenuItem>
                                    <MenuItem value={'web_app'}>Web</MenuItem>
                                    <MenuItem value={'web_service'}>Integração</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                </>


                <Grid item xs={12} sm={12} lg={3} md={3}>
                    {showInputBusca && (
                        <TextField
                            fullWidth
                            size="small"
                            value={textSearch}
                            onChange={(e) => onChangeTextBusca?.(e.target.value)}
                            placeholder={'Pesquisar ..'}
                        />
                    )}
                </Grid>

                <Grid item xs={12} md={2} lg={5} xl={5} display="flex" justifyContent="end">
                    <Box display="flex" justifyContent="end" gap={1}>
                        {showButtonNew && (
                            <Button
                                color="primary"
                                disableElevation
                                variant="contained"
                                onClick={onClickNovo}
                                startIcon={<Icon>add</Icon>}
                            >
                                {textButtonNew}
                            </Button>
                        )}
                        {showButtonConfirm && (
                            <Button
                                color="success"
                                disableElevation
                                variant="contained"
                                onClick={onClickConfirm}
                                startIcon={<Icon>check</Icon>}
                            >
                                {textButtonConfirm}
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}; 