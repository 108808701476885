import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tabs,
    Typography,
    CircularProgress,
    Switch,
    Select,
    InputLabel,
    FormControl,
} from "@mui/material";

import { IVFormErros, useVForm, VForm, VTextField } from "../../../shared/forms";
import { VMaskTextField } from "../../../shared/forms/VMaskTextField";
import { BasicModal } from "../../../shared/components/VModal/VModal";
import { FerramentaDetalhe, ModalContext } from "../../../shared/components";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { useNavigate, useParams } from "react-router-dom";
import { Email, } from "@mui/icons-material";
import * as yup from 'yup';
import { useToast } from '../../../shared/hooks';
import { maskPhone, sleep } from '../../../shared/utils';
import { useProfile } from '../../../context/ProfileContext';
import { ToastContent } from '../../../shared/components';
import { useDebounce } from "../../../shared/hooks";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CloseIcon from '@mui/icons-material/Close';
import { Logs } from "../../../shared/interfaces";
import { useAuthContext } from "../../../context";
import moment from "moment";
import { ContractFileUpload } from "./ContractFileUpload";
import { contractService, IDetalheContract, TContractPromise } from "../../../shared/services/api/contract/contractService";
import { isEmpty } from "lodash";

interface IFormData {
    title: string;
    description: string;
    platform: string;
    statusContract?: string;
}

const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
    title: yup.string().required('Campo Obrigatório'),
    description: yup.string().required('Campo Obrigatório'),
    platform: yup.string().required('Campo Obrigatório'),
    statusContract: yup.string().optional().notRequired().default(""),
});

export const DetalheContratosPresentation: React.FC = () => {
    const { id = 'novo' } = useParams<'id'>();
    const navigate = useNavigate();
    const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } = useVForm();
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [row, setRow] = useState<IDetalheContract>();
    const toastHandler = useToast();
    const [file, setFile] = useState<File>();
    const { debounce } = useDebounce();
    const { isSuperAdmin, permissionLevel } = useProfile();
    const { loggedUser } = useAuthContext();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState<boolean>(false);

    useEffect(() => {
        if (id !== 'novo') {
            setIsLoading(true);

            contractService.getByID(id)
                .then((result: IDetalheContract | Error) => {
                    setIsLoading(false);

                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    } else {
                        setTitle(result.title);

                        formRef.current?.setData({
                            ...result,
                        });

                        setRow(result);
                    }
                }).catch((err: any) => {
                    setIsLoading(false);
                    const { titleMessage, contentMessage } = returnMessageError(err);

                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    })
                });
        } else {
            setRow(undefined);
            formRef.current?.setData({
                title: '',
                description: '',
                platform: '',
                statusContract: '',
            });
        }
    }, [id]);



    const handleSave = (dados: IFormData) => {

        if (!file && id == "novo") {
            const titleMessage = "Alerta!!";
            const contentMessage = "Enviar arquivo do contrato é obrigatório!";

            toastHandler.present({
                type: 'error',
                position: 'top-right',
                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
            });

            return;
        }


        if ((!dados.statusContract || !['active', 'inactive'].includes(dados.statusContract)) && id != "novo") {
            const titleMessage = "Alerta!!";
            const contentMessage = "Campo status é obrigatório selecionar!";

            toastHandler.present({
                type: 'error',
                position: 'top-right',
                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
            });

            return;
        }

        setIsLoading(true);
        
        formValidationSchema.
            validate(dados, { abortEarly: false })
            .then((dadosvalidados: any) => {

                setIsLoading(true);

                if (id === 'novo') {
                    contractService
                        .create(dadosvalidados, file)
                        .then(async (result: TContractPromise) => {
                            setIsLoading(false);

                            if (result instanceof Error) {
                                const titleMessage = "Alerta!!";
                                const contentMessage = "Erro: " + result?.message;

                                toastHandler.present({
                                    type: 'error',
                                    position: 'top-right',
                                    messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                                })
                            } else {

                                setFileUploadedSuccessfully(true);
                                setTimeout(() => {
                                    setFileUploadedSuccessfully(false);
                                }, 250);

                                toastHandler.present({
                                    type: 'success',
                                    position: 'top-right',
                                    messageOrContent: <ToastContent title={"Sucesso"} content={"Contrato cadastrado com sucesso."} />
                                })

                                await sleep(3000);

                                if (issaveAndClose()) {
                                    navigate('/clientes/contratos')
                                } else {
                                    navigate(`/clientes/contratos/detalhe/${result}`);
                                }

                            }

                        }).catch((error: any) => {
                            setIsLoading(false);
                            setIsUploading(false);
                            const { titleMessage, contentMessage } = returnMessageError(error);

                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            })
                        });
                } else {
                    contractService
                        .updateById(id, dadosvalidados)
                        .then(async (result: void | Error) => {
                            setIsLoading(false);

                            if (result instanceof Error) {
                                const titleMessage = "Alerta!!";
                                const contentMessage = "Erro: " + result?.message;

                                toastHandler.present({
                                    type: 'error',
                                    position: 'top-right',
                                    messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                                })
                            } else {

                                toastHandler.present({
                                    type: 'success',
                                    position: 'top-right',
                                    messageOrContent: <ToastContent title={"Sucesso"} content={"Contrato atualizado com sucesso."} />
                                })

                                if (issaveAndClose()) {
                                    navigate('/clientes/contratos')
                                }

                            }

                        }).catch((error) => {
                            setIsLoading(false);
                            setIsUploading(false);

                            const { titleMessage, contentMessage } = returnMessageError(error);
                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            })
                        });
                }

            })
            .catch((errors: yup.ValidationError) => {
                setIsUploading(false);
                const validationErros: IVFormErros = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErros[error.path] = error.message;
                });

                formRef.current?.setErrors(
                    validationErros
                );
                setIsLoading(false);
            });
    };

    const onFileUploadHandler = (file: File | undefined) => {
        setFile(file);
    };

    return (
        <LayoutBaseDePagina
            titulo='Contratos'
            subtitulo={id === 'novo' ? 'Cadastro de novo contratos' : `Alteração de contrato [ ${title} ]`}
            barraDeFerramentas={
                <FerramentaDetalhe
                    showButtonNew={false}
                    showBotaoApagar={false}
                    showButtonFollowUp={false}

                    showBotaoSalvar={true}
                    clicarSalvar={save}
                    clicarSalvarFechar={saveAndClose}
                    clicarVoltar={() => navigate('/clientes/contratos')}
                />
            }
        >

            <VForm
                id="formIdSave"
                placeholder="Your placeholder"
                onPointerEnterCapture={() => console.log("Pointer entered")}
                onPointerLeaveCapture={() => console.log("Pointer left")}
                ref={formRef} onSubmit={handleSave}>
                <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined" >
                    <Grid container direction="column" padding={2} spacing={2} >
                        <Grid container item direction="row" spacing={2}>
                            <Grid item xs={11} >
                                <Typography variant="h6" color={'primary'}>Informações Gerais Contrato</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={2}>
                            <Grid item xs={12} md={4}>
                                <VTextField
                                    fullWidth
                                    label='Titulo'
                                    name="title"
                                    disabled={isLoading || id != "novo"}
                                    onChange={e => setTitle(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <VTextField
                                    fullWidth
                                    label='Descrição'
                                    name="description"
                                    disabled={isLoading || id != "novo"}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <VTextField
                                    fullWidth
                                    label='Plataforma'
                                    name="platform"
                                    disabled={isLoading || id != "novo"}
                                    select
                                >
                                    <MenuItem value={''}></MenuItem>
                                    <MenuItem value={'clicksign'}>Clicksign</MenuItem>
                                </VTextField>
                            </Grid>

                        </Grid>

                        <Grid container item direction="row" spacing={2}>
                            <Grid item xs={4}>
                                { id != "novo" ?
                                    <VTextField
                                        fullWidth
                                        label='Status'
                                        name="statusContract"
                                        disabled={isLoading}
                                        select
                                    >
                                        <MenuItem value={'active'}>Ativo</MenuItem>
                                        <MenuItem value={'inactive'}>Inativo</MenuItem>
                                    </VTextField>
                                    :
                                    <ContractFileUpload
                                        onFileUploadHandler={onFileUploadHandler}
                                        isLoadingProp={isUploading}
                                        clear={fileUploadedSuccessfully}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </VForm>

        </LayoutBaseDePagina >
    );
};