import { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CircularProgress,
    LinearProgress,
} from "@mui/material";
import { FerramentaDetalhe, Loading } from "../../../shared/components";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../../../context/ProfileContext";
import moment from "moment";
import { useDebounce, useToast } from '../../../shared/hooks';
import { ToastContent } from '../../../shared/components';
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { ProfileGuard } from "../../../shared/guards/ProfileGuard";
import Chart from "react-google-charts";
import { IAnalysisTotalStatus, analysisService } from "../../../shared/services/api/analysis/analysisService";
import _ from "lodash";
import { validationPermission } from "../../../shared/utils/validationPermission";


export const GraphicPresentation: React.FC = () => {

    const navigate = useNavigate();
    const { debounce } = useDebounce();
    const [isLoading, setIsLoading] = useState(false);
    const { permissions } = useProfile();
    const toastHandler = useToast();
    const [analysisRows, setAnalysisRows] = useState<IAnalysisTotalStatus[]>([]);
    const [dataAnalysis, setDataAnalysis] = useState<(string | number)[][]>();
    const columOptions = {
        title: "Gráfico de Análises x Garantias",
        vAxis: { title: "Q u a n t i d a d e" },
        hAxis: { title: "Meses" },
        colors: ['#7924c7', '#1FDFEF', '#8514D9', '#82DC80'],
        legend: { position: "bottom" },
    };

    const loadDataAnalysis = () => {
        const values = analysisRows;
        const periodo: keyof IAnalysisTotalStatus = "periodo";
        const aprovadas: keyof IAnalysisTotalStatus = "aprovadas";
        const analises: keyof IAnalysisTotalStatus = "analises";
        const garantias: keyof IAnalysisTotalStatus = "garantias";
        const result = _.map(values, (_, key) => [
            values[key][periodo],
            parseFloat(values[key][analises]),
            parseFloat(values[key][aprovadas]),
            parseFloat(values[key][garantias])
        ]);
        return [
            ["Periodo", "Analises", "Aprovados", "Garantias"], ...result]
    };

    useEffect(() => {

        setIsLoading(true);
        const timer = setTimeout(() => {

            if (validationPermission(permissions, PermissionRolesEnum.ChartsOptions, PermissionActionsEnum.View)) {
                debounce(() => {
                    analysisService.getAnalysisStatus()
                        .then(result => {

                            if (result instanceof Error) {
                                const titleMessage = "Alerta!!";
                                const contentMessage = "Erro: " + result?.message;

                                toastHandler.present({
                                    type: 'error',
                                    position: 'top-right',
                                    messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                                });

                                setTimeout(() => {
                                    navigate('/dashboard');
                                }, 3000);

                            } else {
                                setAnalysisRows(result);
                            }
                        }).catch((err: any) => {
                            setIsLoading(false);

                            const { titleMessage, contentMessage } = returnMessageError(err);
                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            });

                            setTimeout(() => {
                                navigate('/dashboard');
                            }, 3000);
                        });
                });
            } else {
                navigate('/dashboard');
            }

        }, 1000);

        return () => clearTimeout(timer);

    }, []);


    useEffect(() => {

        const timer = setTimeout(() => {
            if (validationPermission(permissions, PermissionRolesEnum.ChartsOptions, PermissionActionsEnum.View)) {
                setDataAnalysis(loadDataAnalysis());
            }

        }, 2000);

        return () => clearTimeout(timer);

    }, [analysisRows]);

    useEffect(() => {

        const timer = setTimeout(() => {
            if (dataAnalysis?.length && validationPermission(permissions, PermissionRolesEnum.ChartsOptions, PermissionActionsEnum.View)) {
                setIsLoading(false);
            }

        }, 2500);

        return () => clearTimeout(timer);

    }, [dataAnalysis]);

    return (
        <LayoutBaseDePagina
            titulo={"Gráfico"}
            barraDeFerramentas={
                <FerramentaDetalhe
                    marginTop={-2}
                    showButtonNew={false}
                    showBotaoApagar={false}
                    showBotaoSalvar={false}
                    showBotaoSalvarFechar={false}
                    clicarVoltar={() => navigate('/dashboard')}
                />
            }
        >
            {isLoading && <LinearProgress variant="indeterminate" />}

            {!isLoading && dataAnalysis?.length && <ProfileGuard requiredRole={PermissionRolesEnum.ChartsOptions} requiredAction={PermissionActionsEnum.View}>
                <Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRadius: "0" }}>
                    <CardContent sx={{ textAlign: "center" }}>
                        <Chart
                            chartType="ColumnChart"
                            width="100%"
                            height="400px"
                            data={dataAnalysis}
                            options={columOptions}
                        />
                    </CardContent>
                </Card>
            </ProfileGuard>
            }
        </LayoutBaseDePagina >
    );
};