import { AuthGuard } from "../../shared/guards/AuthGuard";
import { Sidebar } from "../../shared/components";
import { FinancialBorderoPresentation } from "./components/FinancialBorderoPresentation";


export const FinancialBorderoPage = (): JSX.Element => {
  return (
    <AuthGuard>
      <Sidebar>
        <FinancialBorderoPresentation />
      </Sidebar>
    </AuthGuard>
  );
};
