import React, { useState } from "react";
import { BasicModal } from "../../../../../shared/components";
import ModalCheckListSinister from "./ModalCheckListSinister";

interface ModalTypeSinisterProps {
	isClose: () => void;
	isOpenModal: boolean;
	rows: any;
}

const ModalTypeSinister: React.FC<ModalTypeSinisterProps> = ({ isClose, isOpenModal, rows }) => {
	const [propertyOccupied, setPropertyOccupied] = useState<"busy" | "open">("open");
	const [isOpenModalChecklist, setIsOpenModalCheckList] = useState(false);

	const handleOpenCheckList = () => {
		setIsOpenModalCheckList(true);
	};

	return (
		<>
			{isOpenModal && (
				<BasicModal
					isCloseModal={isClose}
					isOpenModal={isOpenModal}
					messageType={"info"}
					showButtonCancel={true}
					showButtonClose={true}
					textButtonConfirm={"Imóvel Ocupado"}
					textButtonCancel={"Imóvel Desocupado"}
					textButtonClose={"Desistir"}
					onclickConfirm={() => {
						setPropertyOccupied("busy"), handleOpenCheckList();
					}}
					onclickCancel={() => {
						setPropertyOccupied("open"), handleOpenCheckList();
					}}
					onclickClose={() => {
						isClose();
					}}
					ITitle={"Tipo de Sinistro"}
					IMessage={"Informe a condição do imóvel para abertura do sinistro"}
				/>
			)}

			{isOpenModalChecklist && (
				<ModalCheckListSinister
					isOpenModal={isOpenModalChecklist}
					isClose={() => setIsOpenModalCheckList(false)}
					rows={rows}
					propertyOccupied={propertyOccupied}
				/>
			)}
		</>
	);
};

export default ModalTypeSinister;
