import { Box, Button, DialogActions, DialogContent, DialogTitle, Icon, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";
import * as React from "react";
//
const typeMessage: ITypeMessage[] = [
	{
		type: "error",
		title: "Erro",
		icon: "cancel_Outlined_Icon",
		color: "primary",
	},
	{
		type: "alert",
		title: "Atenção",
		icon: "help_outline_outlined_Icon",
		color: "#7924C7",
	},
	{
		type: "success",
		title: "Sucesso",
		icon: "check_circle_Outline_Icon",
		color: "#39c463",
	},
	{
		type: "info",
		title: "Informação",
		icon: "error_outline_Outlined_Icon",
		color: "#7924C7",
	},
	{
		type: "question",
		title: "Confirmação",
		icon: "help_outline_outlined_Icon",
		color: "#7924C7",
	},
	{
		type: "pre_success",
		title: "Sucesso",
		icon: "check_circle_Outline_Icon",
		color: "#2b94e2",
	},
];
//
interface ITypeMessage {
	title: string;
	icon: string;
	color: string;
	type: string;
}
//
interface IContextProps {
	ITitle?: string;
	ISubTitle?: string;
	variant?: OverridableStringUnion<Variant | "inherit">;
	textButtonConfirm?: string;
	showButtonCancel?: boolean;
	showButtonConfirm?: boolean;
	textButtonCancel?: string;
	isOpenModal: boolean;
	messageType: string;
	IMessage: React.ReactNode[];
	onclickConfirm?: () => void;
	isCloseModal?: () => void;
	enableBackdropClose?: boolean;
	overrideWidthValue?: string;
	overrideHeightValue?: string;
	stylePropert?: React.CSSProperties;
	maxWidth?: string;
	maxHeight?: string;
	width?: string;
	showTitleIcon?: boolean;
}
//
export const ModalContext: React.FC<IContextProps> = ({
	ITitle,
	ISubTitle,
	IMessage,
	textButtonConfirm,
	showButtonConfirm,
	showButtonCancel,
	isOpenModal,
	messageType,
	isCloseModal,
	onclickConfirm,
	textButtonCancel,
	showTitleIcon,
	enableBackdropClose,
	overrideWidthValue,
	overrideHeightValue,
	stylePropert,
	maxWidth = "70%",
	maxHeight = "70%",
	width = "50%",
	variant = "h4",
}) => {
	const styleDialogContent: React.CSSProperties = stylePropert || { display: "flex", justifyContent: "center" };

	const modalStyle = React.useMemo(
		() => ({
			position: "relative",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			width: overrideWidthValue || width,
			height: overrideHeightValue || "auto",
			maxWidth: maxWidth,
			maxHeight: maxHeight,
			bgcolor: "background.paper",
			border: "1px solid #605e5e",
			boxShadow: 24,
			overflow: "auto",
			p: 2,
			borderRadius: "6px",
			display: "flex",
			flexDirection: "column",
			backgroundColor: "#FFF",
		}),
		[overrideWidthValue, overrideHeightValue, width, maxWidth, maxHeight]
	);
	//
	const messageStyle: ITypeMessage = React.useMemo(() => {
		return typeMessage.find((item) => item.type === messageType) || typeMessage[0];
	}, [messageType]);
	//
	const handleClose = (event: object, reason: string) => {
		if (reason === "backdropClick" && enableBackdropClose && isCloseModal) {
			isCloseModal();
		}
	};
	//
	return (
		<Modal open={isOpenModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={handleClose}>
			<Box sx={modalStyle}>
				<DialogTitle sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					{showTitleIcon && <Icon sx={{ color: messageStyle.color, fontSize: "3rem" }}>{messageStyle.icon}</Icon>}
					<Typography variant={variant} sx={{ fontWeight: 300 }} color={messageStyle.color}>
						{ITitle}
					</Typography>
					{ISubTitle && (
						<Typography variant="subtitle2" color={messageStyle.color}>
							{ISubTitle}
						</Typography>
					)}
				</DialogTitle>
				<DialogContent sx={styleDialogContent}>{IMessage}</DialogContent>
				<DialogActions sx={{ justifyContent: "center" }}>
					{showButtonConfirm && (
						<Button color="primary" variant="contained" startIcon={<Icon>checkIcon</Icon>} onClick={onclickConfirm}>
							{textButtonConfirm}
						</Button>
					)}
					{showButtonCancel && (
						<Button onClick={isCloseModal} color="primary" variant="outlined" startIcon={<Icon>closeIcon</Icon>}>
							{textButtonCancel}
						</Button>
					)}
				</DialogActions>
			</Box>
		</Modal>
	);
};
