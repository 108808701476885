import {sanitizePhoneString} from './sanitize-phone-string';

export const maskPhone = (value: string) => {
  const sanitizedValue = sanitizePhoneString(value);

  if (sanitizedValue.length === 0) {
    return sanitizedValue;
  }

  const formattedValue = sanitizedValue.length <= 10 ?
    value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
    :
    value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')

  return formattedValue;
};

