import { Box, Button, ButtonGroup, Icon, useTheme } from "@mui/material";

interface IToolbarGridFilterProposalProps {

    textButtonActive?: string;
    showButtonActive?: boolean;
    onClickActive?: () => void;
    selectAtive?: boolean;

    textButtonCanceled?: string;
    showButtonCanceled?: boolean;
    onClickCanceled?: () => void;
    selectCanceled?: boolean;


    textButtonAll?: string;
    showButtonAll?: boolean;
    onClickAll?: () => void;
    selectAll?: boolean;

}

export const ToolbarGridFilterProposal: React.FC<IToolbarGridFilterProposalProps> = ({

    textButtonActive = 'Ativas',
    showButtonActive = true,
    onClickActive,
    selectAtive = false,

    textButtonAll = 'Todas',
    showButtonAll = true,
    onClickAll,
    selectAll = true,

    textButtonCanceled = 'Canceladas',
    showButtonCanceled = true,
    onClickCanceled,
    selectCanceled = false,

}) => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            alignItems="start"
            width={"100%"}
            height={theme.spacing(6)}
        >
            <ButtonGroup fullWidth aria-label="outlined primary button group">

                {showButtonActive && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectAtive ? "contained" : "outlined"}
                        onClick={onClickActive}
                        startIcon={<Icon>check_outlined_icon</Icon>}
                    >
                        {textButtonActive}
                    </Button>
                )}

                {showButtonAll && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectAll ? "contained" : "outlined"}
                        onClick={onClickAll}
                        startIcon={<Icon>done_all_icon</Icon>}
                    >
                        {textButtonAll}
                    </Button>
                )}

                {showButtonCanceled && (
                    <Button
                        color="primary"
                        disableElevation
                        sx={{paddingLeft: "25px", paddingRight: "25px" }}
                        variant={selectCanceled ? "contained" : "outlined"}
                        onClick={onClickCanceled}
                        startIcon={<Icon>remove_done_outlinedIcon</Icon>}
                    >
                        {textButtonCanceled}
                    </Button>
                )}
            </ButtonGroup>
        </Box >
    );
}; 