export enum PermissionRolesEnum  {
  ClientsOptions= 'clients_options',
  UsersOptions = 'users_options',
  AnalysisOptions = 'analysis_options',
  WarrantiesOptions = 'warranties_options',
  SinisterOptions = 'sinister_options',
  SinisterValuesOptions = 'sinister_values_options',
  ReportsOptions = 'reports_options',
  FinancialOptions = 'financial_options',
  GeneralConditionsOptions = 'general_conditions_options',
  ChartsOptions = 'charts_options',
  PermissionsOptions = 'permissions_options',
  DashboardOptions = 'dashboard_options',
  MenuDashboard = 'menu_dashboard',
  ClientsDashboard = 'clients_dashboard',
  UsersDashboard = 'users_dashboard',
  AnalysisDashboard = 'analysis_dashboard',
  WarrantiesMenu = 'warranties_menu',
  SinisterMenu = 'sinister_menu',
  ReportsMenu = 'reports_menu',
  FinancialMenu = 'financial_menu',
  GeneralConditionsMenu = 'general_conditions_menu',
  ChartsMenu = 'charts_menu',
  PermissionsMenu = 'permissions_menu',
}