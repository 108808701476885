import { Api } from '../axios-config';
import { GetDocumentTypesResponseType } from './types';
import { DocumentContextNamesEnum } from './enums';
const getProposalDocumentTypes = async (): Promise<GetDocumentTypesResponseType[]> => {
  const { data }  = await Api()
    .get(`/documents/types?contextName=${DocumentContextNamesEnum.Proposal}`);

  return data as GetDocumentTypesResponseType[];
};
const getSinisterDocumentTypes = async (sinisterParam:string,listId:string): Promise<GetDocumentTypesResponseType[]> => {
  const { data }  = await Api()
    .get(`/sinister-document/${sinisterParam}/lista/${listId}`)
    //?_page=1&_limit=100`);

  return data as GetDocumentTypesResponseType[];
};



export const DocumentsService = {
  getProposalDocumentTypes,
  getSinisterDocumentTypes
};

