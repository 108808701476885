import { Box, Button, ButtonGroup, Icon, useTheme } from "@mui/material";

interface IToolbarGridFilterReportProps {

    textButtonProposal?: string;
    showButtonProposal?: boolean;
    onClickProposal?: () => void;
    selectProposal?: boolean;

    textButtonCanceled?: string;
    showButtonCanceled?: boolean;
    onClickCanceled?: () => void;
    selectCanceled?: boolean;

    textButtonUnbilled?: string;
    showButtonUnbilled?: boolean;
    onClickUnbilled?: () => void;
    selectUnbilled?: boolean;

    textButtonBilled?: string;
    showButtonBilled?: boolean;
    onClickBilled?: () => void;
    selectBilled?: boolean;


    textButtonOpen?: string;
    showButtonOpen?: boolean;
    onClickOpen?: () => void;
    selectOpen?: boolean;

    textButtonOverdue?: string;
    showButtonOverdue?: boolean;
    onClickOverdue?: () => void;
    selectOverdue?: boolean;

    textButtonPaid?: string;
    showButtonPaid?: boolean;
    onClickPaid?: () => void;
    selectPaid?: boolean;

}

export const ToolbarGridFilterReport: React.FC<IToolbarGridFilterReportProps> = ({

    textButtonProposal = 'Garantias',
    showButtonProposal = true,
    onClickProposal,
    selectProposal = false,

    textButtonCanceled = 'Canceladas',
    showButtonCanceled = true,
    onClickCanceled,
    selectCanceled = false,

    textButtonUnbilled = "Não faturados",
    showButtonUnbilled = true,
    onClickUnbilled,
    selectUnbilled = false,

    textButtonBilled = "Faturados",
    showButtonBilled = true,
    onClickBilled,
    selectBilled = false,


    textButtonOpen = "Abertos",
    showButtonOpen = true,
    onClickOpen,
    selectOpen = false,

    textButtonOverdue = "Vencidas",
    showButtonOverdue = true,
    onClickOverdue,
    selectOverdue = false,

    textButtonPaid = "Pagas",
    showButtonPaid = true,
    onClickPaid,
    selectPaid = false,

}) => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            alignItems="start"
            width={"100%"}
            height={theme.spacing(6)}
        >
            <ButtonGroup fullWidth aria-label="outlined primary button group">

                {showButtonProposal && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectProposal ? "contained" : "outlined"}
                        onClick={onClickProposal}
                    >
                        {textButtonProposal}
                    </Button>
                )}

                {showButtonCanceled && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectCanceled ? "contained" : "outlined"}
                        onClick={onClickCanceled}
                    >
                        {textButtonCanceled}
                    </Button>
                )}

                {showButtonUnbilled && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectUnbilled ? "contained" : "outlined"}
                        onClick={onClickUnbilled}
                    >
                        {textButtonUnbilled}
                    </Button>
                )}

                {showButtonBilled && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectBilled ? "contained" : "outlined"}
                        onClick={onClickBilled}
                    >
                        {textButtonBilled}
                    </Button>
                )}

                {showButtonOpen && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectOpen ? "contained" : "outlined"}
                        onClick={onClickOpen}
                    >
                        {textButtonOpen}
                    </Button>
                )}

                {showButtonOverdue && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectOverdue ? "contained" : "outlined"}
                        onClick={onClickOverdue}
                    >
                        {textButtonOverdue}
                    </Button>
                )}

                {showButtonPaid && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectPaid ? "contained" : "outlined"}
                        onClick={onClickPaid}
                    >
                        {textButtonPaid}
                    </Button>
                )}
            </ButtonGroup>
        </Box >
    );
}; 