import {
	Box,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Pagination,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContent } from "../../../shared/components";
import { useToast } from "../../../shared/hooks";
import { CreditCardOutlined } from "@mui/icons-material";
import { addDays, format, subDays } from "date-fns";
import { ToolbarGridAnalises } from "../../../shared/components";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { Environment } from "../../../shared/environment";
import { checkByProfileGuard, ProfileGuard } from "../../../shared/guards/ProfileGuard";
import { useDebounce } from "../../../shared/hooks";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { analysisService, IanalysisList } from "../../../shared/services/api/analysis/analysisService";
import { globalBackground, globalColorText, globalMaskDocument, globalMaskReal, globalTranslateStatusAnalysis } from "../../../shared/utils";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { ListParamsAnalysis } from "../types/listParams.type";
import { ToolbarAnalysis } from "./toobalAnalysis/ToolbarAnalysis";

export const AnalysisListPresentation = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { debounce } = useDebounce();
	const navigate = useNavigate();
	const [rows, setRows] = useState<IanalysisList[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const hasViewPermission = useMemo(() => {
		return checkByProfileGuard(PermissionRolesEnum.AnalysisOptions, PermissionActionsEnum.View);
	}, []);
	const toastHandler = useToast();
	const [pesquisar, setPesquisar] = useState(true);
	const today = new Date();

	const filterInitialParams: ListParamsAnalysis = useMemo(() => {
		return {
			tipo: "documento",
			status: "Todos",
			createFrom: "Todos",
			beginDate: format(subDays(today, 7), "yyyy-MM-dd"),
			endDate: format(today, "yyyy-MM-dd"),
			data: "7",
			filterField: "documento",
			limit: 10, 
			clientFilter: "",
		};
	}, []);

	const updateSearchParams = (
		setSearchParams: (params: URLSearchParams, options?: { replace?: boolean }) => void,
		newParams: Record<string, string | number | undefined>
	) => {
		const currentParams = new URLSearchParams(window.location.search); // Obtém os parâmetros atuais da URL
		// Itera sobre os novos parâmetros e os adiciona ou substitui nos parâmetros existentes
		Object.entries(newParams).forEach(([key, value]) => {
			if (value !== undefined) {
				currentParams.set(key, value.toString());
			}
		});
		currentParams.set("pagina", "1"); // Reseta sempre para a página 1
		setSearchParams(currentParams, { replace: true }); // Atualiza os parâmetros de busca
	};

	const busca = useMemo(() => {
		return searchParams.get("busca") || "";
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get("pagina") || "1");
	}, [searchParams]);

	const tipoAnalise = useMemo(() => {
		return searchParams.get("tipo") || "";
	}, [searchParams]);

	const statusAnalise = useMemo(() => {
		return searchParams.get("status") || "";
	}, [searchParams]);

	const origemAnalise = useMemo(() => {
		return searchParams.get("origem") || "";
	}, [searchParams]);

	const dataIniAnalise = useMemo(() => {
		return searchParams.get("dataini") || filterInitialParams.beginDate;
	}, [searchParams]);

	const dataFimAnalise = useMemo(() => {
		return searchParams.get("datafim") || filterInitialParams.endDate;
	}, [searchParams]);

	const clientFilter = useMemo(() => {
		return searchParams.get("clientFilter") || "";
	}, [searchParams]);

	const managerId = useMemo(() => {
		return searchParams.get("managerId") || "";
	}, [searchParams]);

	const limit = useMemo(() => {
		return searchParams.get("limit") || "10";
	}, [searchParams]);

	const handleBusca = () => {
		setSearchParams(
			{
				busca,
				pagina: String(1),
				tipo: tipoAnalise,
				status: statusAnalise,
				origem: origemAnalise,
				dataini: dataIniAnalise,
				datafim: dataFimAnalise,
				clientFilter: clientFilter,
				limit: limit,
				managerId,
			},
			{ replace: true }
		);
		setPesquisar(!pesquisar);
	};

	useEffect(() => {
		setSearchParams(
			{
				...searchParams,
				dataini: filterInitialParams.beginDate,
				datafim: filterInitialParams.endDate,
				tipo: filterInitialParams.filterField,
				status: filterInitialParams.status,
				origemAnalise: filterInitialParams.createFrom,
				limit: filterInitialParams.limit.toString(),
			},
			{ replace: true }
		);
	}, []);

	useEffect(() => {
		if (hasViewPermission) {
			setIsLoading(true);
			debounce(() => {
				analysisService
					.getAll(pagina, busca, tipoAnalise, origemAnalise, statusAnalise, dataIniAnalise, dataFimAnalise, clientFilter, managerId, Number(limit))
					.then((result) => {
						setIsLoading(false);
						if (result instanceof Error) {
							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={"Alerta!"} content={"Erro: " + result?.message} />,
							});
						} else {
							setTotalCount(result.totalCount);
							setRows(result.data);
						}
					})
					.catch((err: any) => {
						setIsLoading(false);
						const { titleMessage, contentMessage } = returnMessageError(err);
						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					});
			});
		}
	}, [pagina, statusAnalise, pesquisar, limit]);

	const URL = window.location.protocol + "//" + window.location.host;

	return (
		<LayoutBaseDePagina
			titulo="Análises"
			subtitulo="Listagem de análises"
			barraDeFerramentas={
				<ToolbarAnalysis
					showInputMananger={true}
					showInputClient={true}
					showCreatedFrom={true}
					showInputDate={true}
					showBeetweenDate={true}
					showButtonNew = {
						!isLoading &&
						  checkByProfileGuard(
							PermissionRolesEnum.AnalysisOptions,
							PermissionActionsEnum.Add
						  )
						  ? true
						  : false
					  }
					textButtonNew="Nova Análise"
					showInputSearch={true}
					onClickButtonNew={() => navigate("/analises/pedido")}
					onclickSearch={handleBusca}
					filterInitialParams={filterInitialParams}
					onChangeCreatedFrom={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoAnalise,
								status: statusAnalise,
								origem: retorno,
								dataini: dataIniAnalise,
								datafim: dataFimAnalise,
								clientFilter: clientFilter,
								limit: limit,
								managerId,
							},
							{ replace: true }
						)
					}
					onChangeInputClient={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoAnalise,
								status: statusAnalise,
								origem: origemAnalise,
								dataini: dataIniAnalise,
								datafim: dataFimAnalise,
								clientFilter: retorno,
								limit: limit,
								managerId,
							},
							{ replace: true }
						)
					}
					onChangeInputMananger={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoAnalise,
								status: statusAnalise,
								origem: origemAnalise,
								dataini: dataIniAnalise,
								datafim: dataFimAnalise,
								clientFilter: clientFilter,
								managerId: retorno,
								limit: limit,
							},
							{ replace: true }
						)
					}
					onChangeFilterField={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: retorno,
								status: statusAnalise,
								origem: origemAnalise,
								dataini: dataIniAnalise,
								datafim: dataFimAnalise,
								clientFilter: clientFilter,
								managerId,
								limit: limit,
							},
							{ replace: true }
						)
					}
					onChangeTextSearch={(retorno: string) => {
						setSearchParams(
							{
								busca: retorno,
								pagina: String(1),
								tipo: tipoAnalise,
								status: statusAnalise,
								origem: origemAnalise,
								dataini: dataIniAnalise,
								datafim: dataFimAnalise,
								clientFilter: clientFilter,
								managerId,
								limit: limit,
							},
							{ replace: true }
						);
					}}
					onClickClear={() => {
						setSearchParams(
							{
								busca: "",
								pagina: String(1),
								tipo: tipoAnalise,
								status: statusAnalise,
								origem: origemAnalise,
								dataini: dataIniAnalise,
								datafim: dataFimAnalise,
								clientFilter: clientFilter,
								managerId,
								limit: limit,
							},
							{ replace: true }
						);
					}}
					onChangeDataIni={(retorno: string) => {
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoAnalise,
								status: statusAnalise,
								origem: origemAnalise,
								dataini: retorno,
								datafim: dataFimAnalise,
								clientFilter: clientFilter,
								managerId,
								limit: limit,
							},
							{ replace: true }
						);
					}}
					onChangeDataFim={(retorno: string) => {
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoAnalise,
								status: statusAnalise,
								origem: origemAnalise,
								dataini: dataIniAnalise,
								datafim: retorno,
								clientFilter: clientFilter,
								managerId,
								limit: limit,
							},
							{ replace: true }
						);
					}}
				/>
			}
		>
			<ProfileGuard requiredRole={PermissionRolesEnum.AnalysisOptions} requiredAction={PermissionActionsEnum.View}>
				<TableContainer
					component={Paper}
					variant="outlined"
					sx={{
						marginLeft: 1,
						marginTop: 1,
						marginRight: 2,
						width: "auto",
						padding: 4,
					}}
				>
					<Table aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell colSpan={7}>
									<ToolbarGridAnalises
										onClickTodos={() => updateSearchParams(setSearchParams, { status: "Todos" })}
										onClickPreAprovadas={() => updateSearchParams(setSearchParams, { status: "pre_approved" })}
										onClickAprovadas={() => updateSearchParams(setSearchParams, { status: "issued" })}
										onClickExpiradas={() => updateSearchParams(setSearchParams, { status: "expired" })}
										onClickRecusadas={() => updateSearchParams(setSearchParams, { status: "refused" })}
										onClickPendentes={() => updateSearchParams(setSearchParams, { status: "awaiting_signature" })}
										onClickBiometria={() => updateSearchParams(setSearchParams, { status: "awaiting_biometria" })}
										onClickCancel={() => updateSearchParams(setSearchParams, { status: "canceled" })}
										selectTodos={searchParams.get("status") === "Todos"}
										selectPreAprovada={searchParams.get("status") === "pre_approved"}
										selectAprovada={searchParams.get("status") === "issued"}
										selectExpirada={searchParams.get("status") === "expired"}
										selectRecusada={searchParams.get("status") === "refused"}
										selectPendente={searchParams.get("status") === "awaiting_signature"}
										selectBiometria={searchParams.get("status") === "awaiting_biometria"}
										selectCancel={searchParams.get("status") === "canceled"}
									/>
								</TableCell>
							</TableRow>

							<TableRow sx={{ background: "#f5fbfd" }}>
								<TableCell sx={{ color: "#7e7777b5" }}> Nome</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Parceiro</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Endereço</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Status </TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Cadastro</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Origem</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!isLoading &&
								rows.map((row) => (
									<TableRow key={row.id} hover>
										<TableCell sx={{ fontWeight: "normal" }}>
											{row.name}
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{globalMaskDocument(row.document)}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
												{row.client?.name}
												{row.client?.status == "inactive" && " (Inativo)"}
											</Typography>
										</TableCell>

										<TableCell
											sx={{
												color: "#959595",
												fontWeight: "normal",
											}}
										>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row.addressStreet != null && row.addressStreet != ""
													? `${
															row.addressComplement != null && row.addressComplement != ""
																? `${row.addressStreet} , ${row.addressNumber} - ${row.addressComplement} - ${row.addressDistrict}`
																: `${row.addressStreet} , ${row.addressNumber} - ${row.addressDistrict}`
													  }`
													: "Endereço não cadastrado"}
											</Typography>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row.addressCity != null && row.addressCity != "" ? `${row.addressCity} - ${row.addressState}` : ""}
											</Typography>
										</TableCell>
										<TableCell sx={{ textAlign: "start" }}>
											<IconButton
												size="small"
												sx={{
													background: globalBackground(row.status),
													borderRadius: "18px",
													paddingX: "15px",
													paddingY: "1px",
													marginBottom: "5px",
												}}
											>
												<Typography
													variant="subtitle2"
													marginLeft={1}
													sx={{
														color: globalColorText(row.status),
														fontWeight: "normal",
														display: "flex",
														flexGrow: "initial",
													}}
												>
													{globalTranslateStatusAnalysis(row.status)}
												</Typography>
											</IconButton>

											<Typography variant="subtitle2" color={globalColorText(row.status)} fontWeight={"normal"}>
												{row.status == "issued" || row.status == "awaiting_signature" || row.status == "awaiting_biometria"
													? `expira
                                         ${
												row.expiresSignature
													? format(new Date(row.expiresSignature), "dd/MM/yyyy")
													: format(addDays(new Date(row.createdAt), 30), "dd/MM/yyyy")
											} `
													: ""}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												variant="subtitle2"
												color={"#959595"}
												fontWeight={"normal"}
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
												}}
											>
												{row.setup_amount != null && (
													<Tooltip title={`Taxa de setup R$  ${globalMaskReal(row?.setup_amount)}`}>
														<CreditCardOutlined />
													</Tooltip>
												)}

												{row?.createdAt ? format(new Date(row.createdAt), "dd/MM/yyyy") : "Não informado"}
											</Typography>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} textAlign={"center"}>
												{row?.createdAt ? format(new Date(row.createdAt), "HH:mm:ss") : ""}
											</Typography>
										</TableCell>
										<TableCell sx={{ textAlign: "start" }}>
											<IconButton
												size="small"
												sx={{
													borderRadius: "18px",
													paddingX: "15px",
													paddingY: "1px",
													marginBottom: "5px",
												}}
											>
												{row.createdFrom == "web_app" ? (
													<img src="/logogar.png" alt="web_app" width={30} />
												) : (
													<img src="/logo4seg.png" alt="web_app" width={30} />
												)}
											</IconButton>
										</TableCell>
										<TableCell sx={{ textAlign: "center" }}>
											<IconButton
												size="small"
												onClick={() => window.open(`${URL}/analises/visualizar/${row.id}`)}
												sx={{ borderRadius: "18px" }}
											>
												<Icon
													sx={{
														color: "#0170B3",
														fontWeight: "normal",
													}}
												>
													{" "}
													crop_free_OutlinedIcon
												</Icon>
												<Typography
													variant="subtitle2"
													marginLeft={1}
													sx={{
														color: "#0170B3",
														fontWeight: "normal",
													}}
												>
													Abrir
												</Typography>
											</IconButton>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
						{totalCount === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}
						<TableFooter>
							{isLoading && (
								<TableRow>
									<TableCell colSpan={3}>
										<LinearProgress variant="indeterminate" />
									</TableCell>
								</TableRow>
							)}

							{totalCount > 0 && (
								<TableCell colSpan={12} sx={{ border: "none" }}>
									<Box sx={{ display: "flex", justifyContent: "space-between" }}>
										<Box sx={{ display: "flex", gap: 2 }}>
											<FormControl sx={{ width: "auto", minWidth: "80px" }}>
												<InputLabel id="limit">Linhas </InputLabel>
												<Select
													labelId="label_Qtde"
													id="limite"
													value={limit}
													size="small"
													label="Linhas"
													onChange={(event) => {
														const value = event.target.value as string;
														setSearchParams(
															{
																busca,
																pagina: "1",
																tipo: tipoAnalise,
																status: statusAnalise,
																origem: origemAnalise,
																dataini: dataIniAnalise,
																datafim: dataFimAnalise,
																clientFilter: clientFilter,
																limit: value || "10",
																managerId,
															},
															{ replace: true }
														);
													}}
												>
													<MenuItem value={"5"}> 05</MenuItem>
													<MenuItem value={"10"}>10</MenuItem>
													<MenuItem value={"25"}>25</MenuItem>
													<MenuItem value={"50"}>50</MenuItem>
												</Select>
											</FormControl>
											<Pagination
												page={pagina}
												count={Math.ceil(totalCount / Number(limit))}
												onChange={(_, newPage) =>
													setSearchParams(
														{
															busca,
															pagina: newPage.toString(),
															tipo: tipoAnalise,
															status: statusAnalise,
															origem: origemAnalise,
															dataini: dataIniAnalise,
															datafim: dataFimAnalise,
															clientFilter: clientFilter,
															limit: limit,
															managerId,
														},
														{ replace: true }
													)
												}
											/>
										</Box>

										<Box>
											<Typography variant="body1" sx={{ fontWeight: "normal" }}>
												Exibindo {pagina * Number(limit) - (Number(limit) - 1)}
												{" - "}
												{Number(limit) * (pagina - 1) + Number(limit) * pagina < totalCount ? Number(limit) * pagina : totalCount} de
												<Typography
													variant="overline"
													sx={{
														fontWeight: "bold",
														color: "#0170B3",
														fontSize: "16px",
													}}
												>
													{" "}
													{totalCount}{" "}
												</Typography>
												registro(s)
											</Typography>
										</Box>
									</Box>
								</TableCell>
							)}
						</TableFooter>
					</Table>
				</TableContainer>
			</ProfileGuard>
		</LayoutBaseDePagina>
	);
};
