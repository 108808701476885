import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ResetPasswordService } from "../../../shared/services/api/reset-password/ResetPasswordService";
import * as yup from "yup";
import imageSite from "../../../assets/site.png";
import { Loading } from "../../../shared/components";

const resetPasswordSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(5),
  confirmPassword: yup.string().required().min(5),
});

const ResetPasswordPresentation: React.FC = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [resetCallSuccessful, setResetCallSuccessful] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await resetPasswordSchema.validate(
        { email, password, confirmPassword },
        { abortEarly: false }
      );

      if (password !== confirmPassword) {
        setConfirmPasswordError("Senhas devem ser iguais!");
        setIsLoading(false);
        return;
      }

      try {
        await ResetPasswordService.recoverPassword({
          token,
          password,
          confirmPassword,
        });

        setResetCallSuccessful(true);
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
        alert(e.message);
      }
    } catch (errors: any) {
      setIsLoading(false);

      errors.inner.forEach((error: yup.ValidationError) => {
        
        if (error.path === "email") {
          setEmailError(error.message);
        }

        if (error.path === "password") {
          setPasswordError(error.message);
        }

        if (error.path === "confirmPassword") {
          setConfirmPasswordError(error.message);
        }
      });
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const providedToken = searchParams.get("token");


      if (!providedToken) {
        return navigate("/login", { replace: true });
      }

      setToken(providedToken);

      try {
        const data = await ResetPasswordService.getUserDataByToken({
          token: providedToken,
        });

        const email = data.email;
        setEmail(email);
        setIsLoading(false);
      } catch (e: any) {
        
        alert(e.message);
        await navigate("/login");
      }
    })();
  }, []);

  const handleNavigateBack = async () => {
    setIsLoading(true);

    navigate("/login");
  };

  return (
      <Box>
        <Grid container>
          <Grid item xs={7} display={smDown ? "none" : "flex"}>
            <Box height="99.5vh" justifyContent="left">
              <img src={imageSite} alt="Site" width="auto" height="100%" />
            </Box>
          </Grid>
          <Grid
            item
            xs={smDown ? 12 : 5}
            display="flex"
            alignItems="center"
            justifyContent={smDown ? "center" : "flex-start"}
          >
            {!resetCallSuccessful ? (
              <>
                <Card sx={{ borderRadius: "8px" }}>
                  <CardContent sx={{ margin: "1rem 2rem" }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      width={400}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <img src="image3.png" alt="Logo" height={"30%"} />
                      </Box>

                      <Typography variant="h6" align="center">
                        Redefina sua senha
                      </Typography>
                      <TextField
                        fullWidth
                        type="email"
                        label="E-mail"
                        value={email}
                        disabled={true}
                        error={!!emailError}
                        helperText={emailError}
                        onKeyDown={() => setEmailError("")}
                        onChange={(e) => setEmail(e.target.value)}
                        size={"small"}
                        sx={{ margin: "1rem 0 0" }}
                      />

                      <TextField
                        fullWidth
                        type="password"
                        label="Nova senha"
                        value={password}
                        disabled={isLoading}
                        error={!!passwordError}
                        helperText={passwordError}
                        onKeyDown={() => setPasswordError("")}
                        onChange={(e) => setPassword(e.target.value)}
                        size={"small"}
                        sx={{ margin: "1rem 0 0" }}
                      />

                      <TextField
                        fullWidth
                        type="password"
                        label="Confirme sua senha"
                        value={confirmPassword}
                        disabled={isLoading}
                        error={!!confirmPasswordError}
                        helperText={confirmPasswordError}
                        onKeyDown={() => setConfirmPasswordError("")}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        size={"small"}
                        sx={{ margin: "1rem 0 0" }}
                      />
                    </Box>
                  </CardContent>
                  <CardActions sx={{ margin: "0 2rem 1rem" }}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Button
                        variant="contained"
                        disabled={isLoading}
                        onClick={handleNavigateBack}
                        endIcon={
                          isLoading ? (
                            <CircularProgress variant="indeterminate" />
                          ) : undefined
                        }
                        sx={{
                          width: "inherit",
                          background: "#F5F5F5",
                          color: "#000000",
                          margin: "1rem 1rem 1rem 0",
                        }}
                      >
                        Voltar
                      </Button>

                      <Button
                        variant="contained"
                        disabled={isLoading}
                        onClick={handleSubmit}
                        endIcon={
                          isLoading ? (
                            <CircularProgress variant="indeterminate" />
                          ) : undefined
                        }
                        sx={{
                          width: "inherit",
                          background: "#7924c7",
                          margin: "1rem 0",
                        }}
                      >
                        Confirmar
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </>
            ) : (
              <>
                <Card
                  sx={{ borderRadius: "8px" }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent
                    sx={{ margin: "1rem 2rem" }}
                    style={{
                      height: "50%",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      width={400}
                    >
                      <Box
                        id="success-box"
                        width="100%"
                        display="flex"
                        alignItems="space-between"
                        justifyContent="center"
                      >
                        <img src="green_check_mark_68.png" alt="Check Mark" />
                      </Box>

                      <Typography variant="h6" align="center">
                        Senha alterada com sucesso!
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions
                    sx={{ margin: "1rem 2rem" }}
                    style={{
                      height: "50%",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      width="100%"
                    >
                      <Button
                        variant="contained"
                        disabled={isLoading}
                        onClick={handleNavigateBack}
                        endIcon={
                          isLoading ? (
                            <CircularProgress variant="indeterminate" />
                          ) : undefined
                        }
                        sx={{
                          width: "inherit",
                          background: "primary",
                          margin: "1rem 0",
                        }}
                      >
                        Voltar
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
  );
};

export default ResetPasswordPresentation;
