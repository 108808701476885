const Weeks = [];
const size = 60;
for (let i = 1; i <= size; i++) {
	Weeks.push(i);
}

export const globalWeeks = Weeks;

interface IRangeState {
	value: string;
	label: string;
}

export const RangeStates: IRangeState[] = [
	{
		value: "AC",
		label: "Acre",
	},
	{
		value: "AL",
		label: "Alagoas",
	},
	{
		value: "AP",
		label: "Amapá",
	},
	{
		value: "AM",
		label: "Amazonas",
	},
	{
		value: "BA",
		label: "Bahia",
	},
	{
		value: "CE",
		label: "Ceará",
	},
	{
		value: "DF",
		label: "Distrito Federal",
	},
	{
		value: "ES",
		label: "Espírito Santo",
	},
	{
		value: "GO",
		label: "Goiás",
	},
	{
		value: "MA",
		label: "Maranhão",
	},
	{
		value: "MT",
		label: "Mato Grosso",
	},
	{
		value: "MS",
		label: "Mato Grosso do Sul",
	},
	{
		value: "MG",
		label: "Minas Gerais",
	},
	{
		value: "PA",
		label: "Pará",
	},
	{
		value: "PB",
		label: "Paraíba",
	},
	{
		value: "PR",
		label: "Paraná",
	},
	{
		value: "PE",
		label: "Pernambuco",
	},
	{
		value: "PI",
		label: "Piauí",
	},
	{
		value: "RJ",
		label: "Rio de Janeiro",
	},
	{
		value: "RN",
		label: "Rio Grande do Norte",
	},
	{
		value: "RS",
		label: "Rio Grande do Sul",
	},
	{
		value: "RO",
		label: "Rondônia",
	},
	{
		value: "RR",
		label: "Roraima",
	},
	{
		value: "SC",
		label: "Santa Catarina",
	},
	{
		value: "SP",
		label: "São Paulo",
	},
	{
		value: "SE",
		label: "Sergipe",
	},
	{
		value: "TO",
		label: "Tocantins",
	},
];

export const typeProperty: any[] = [
	{
		description: "Apartamento Padrao",
		value: "Apartamento Padrao",
		type: "residential",
	},
	{
		description: "Casa Padrão",
		value: "Casa Padrao",
		type: "residential",
	},
	{
		description: "Cobertura",
		value: "Cobertura",
		type: "residential",
	},
	{
		description: "Kitnete",
		value: "Kitnete",
		type: "residential",
	},
	{
		description: "Loft",
		value: "Loft",
		type: "residential",
	},
	{
		description: "Casa de Condominio",
		value: "Casa de Condominio",
		type: "residential",
	},
	{
		description: "Casa de Vila",
		value: "Casa de Vila",
		type: "residential",
	},
	{
		description: "Loteamento/Condominio",
		value: "Loteamento/Condominio",
		type: "residential",
	},
	{
		description: "Terreno Padrao",
		value: "Terreno Padrao",
		type: "commercial",
	},
	{
		description: "Chacara",
		value: "Chacara",
		type: "commercial",
	},
	{
		description: "Fazenda",
		value: "Fazenda",
		type: "commercial",
	},
	{
		description: "Sitio",
		value: "Sitio",
		type: "commercial",
	},
	{
		description: "Haras",
		value: "Haras",
		type: "commercial",
	},
	{
		description: "Flat/Residence Service",
		value: "Flat/Residence Service",
		type: "commercial",
	},
	{
		description: "Casa Comercial",
		value: "Casa Comercial",
		type: "commercial",
	},
	{
		description: "Conjunto Salas",
		value: "Conjunto Salas",
		type: "commercial",
	},
	{
		description: "Galpao/Deposito/Armazem",
		value: "Galpao/Deposito/Armazem",
		type: "commercial",
	},
	{
		description: "Industria",
		value: "Industria",
		type: "commercial",
	},
	{
		description: "Hotel/Pousada",
		value: "Hotel/Pousada",
		type: "commercial",
	},
	{
		description: "Loja Shopping",
		value: "Loja Shopping",
		type: "commercial",
	},
	{
		description: "Loja",
		value: "Loja",
		type: "commercial",
	},
	{
		description: "Sala Comercial",
		value: "Sala Comercial",
		type: "commercial",
	},
	{
		description: "Motel",
		value: "Motel",
		type: "commercial",
	},
	{
		description: "Predio Inteiro",
		value: "Predio Inteiro",
		type: "commercial",
	},
	{
		description: "Studio",
		value: "Studio",
		type: "commercial",
	},
	{
		description: "Atelie",
		value: "Atelie",
		type: "commercial",
	},
	{
		description: "Andar Inteiro",
		value: "Andar Inteiro",
		type: "commercial",
	},
	{
		description: "Loja Rua",
		value: "Loja Rua",
		type: "commercial",
	},

	{
		description: "APARTAMENTO",
		value: "APARTAMENTO",
		type: "residential",
	}
];
