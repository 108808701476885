import React, { useState } from 'react';
import {
  StyledFileBox,
  StyledFileIconBox,
  StyledFileMetadataBox,
  StyledFileOperationsBox, StyledOperationFileExclude, StyledOperationIconBox,
  StyledSinisterFileItemContainer, StyledTypography
} from './SinisterFileItem.styled';
import { SinisterDocumentType } from '../../../../shared/services/api/sinister/types'

import { getFileExtension, getFileExtensionIcon } from '../../helpers';
import { Button, CircularProgress, Icon, Typography } from '@mui/material';
// import {formatAnalysisContractTypeEnum} from '../../../../../../enums/analysis-documents';
import axios from 'axios';
import { formatDate } from '../../../../shared/utils/format-date';
import { useDebounce, useToast } from '../../../../shared/hooks';
import { BasicModal, ToastContent } from '../../../../shared/components';
import { SinisterDocumentsService } from '../../../../shared/services/api/sinister/SinisterDocumentsService';
import { returnMessageError } from '../../../../shared/utils/returnMessageError';
import { useProfile } from '../../../../context/ProfileContext';

type SinisterFileItemProps = {
  item: SinisterDocumentType;
  refreshListDocuments?: (value: boolean) => void;
  statusSinister?: string;
}
export const SinisterFileItem = ({ item, refreshListDocuments, statusSinister }: SinisterFileItemProps) => {

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const toastHandler = useToast();
  const { debounce } = useDebounce();
  const { isSuperAdmin } = useProfile();

  const removeFile = async () => {
    setLoading(true);
    refreshListDocuments?.(false);
    debounce(() => {
      SinisterDocumentsService.removeDocumentBySinister(item.id)
        .then((result: void | Error) => {
          setLoading(false);
          refreshListDocuments?.(true);
          if (result instanceof Error) {
            const titleMessage = "Alerta!!";
            const contentMessage = "Erro: " + result?.message;

            toastHandler.present({
              type: 'error',
              position: 'top-right',
              messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
            });
          } else {
            toastHandler.present({
              type: 'success',
              position: 'top-right',
              durationMs: 3000,
              messageOrContent: <ToastContent title={"SUCESSO!"} content={"Documento excluido com sucesso."} />
            })

            setTimeout(() => {
              refreshListDocuments?.(false);
            }, 500);
          }
        }).catch((err: any) => {
          setLoading(false);
          const { titleMessage, contentMessage } = returnMessageError(err);

          toastHandler.present({
            type: 'error',
            position: 'top-right',
            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
          })
        })
    });
  }


  return (
    <>
      <StyledSinisterFileItemContainer>
        <StyledFileBox>
          <StyledFileIconBox style={{
            height: '4rem',
            width: '4rem'
          }}>
            {getFileExtensionIcon(item.mimetype)}
          </StyledFileIconBox>
        </StyledFileBox>

        <StyledFileMetadataBox>
          <StyledTypography style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>{item.originalFilename}</StyledTypography>
          {/* <StyledTypography style={{fontSize: '0.8rem'}}>{formatAnalysisContractTypeEnum(item.docType)}</StyledTypography> */}
          <StyledTypography style={{ fontSize: '0.8rem' }}>{item.docType}</StyledTypography>
          <StyledTypography style={{ fontSize: '0.8rem', color: 'grey', opacity: 0.9 }}>{formatDate(new Date(item.uploadedDate))}</StyledTypography>
        </StyledFileMetadataBox>

        <StyledFileOperationsBox>
          <a href={item.url} style={{
            textDecoration: 'none',
            color: 'inherit'
          }}>
            <StyledOperationIconBox>
              <Icon style={{
                fontSize: "2.5rem"
              }}>download</Icon>
            </StyledOperationIconBox>
          </a>
        </StyledFileOperationsBox>
        {((statusSinister == "sketch" && !isSuperAdmin) || isSuperAdmin) && (
          <div style={{ alignSelf: "center", cursor: "pointer", marginRight: "15px", marginTop: "10px", marginLeft: "15px" }}>
            {loading ? <CircularProgress />
              : (<>
                <StyledOperationFileExclude />
                <Button
                  color="error"
                  disableElevation
                  sx={{
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "inherit",
                    },
                    padding: 0,
                    minWidth: 0,
                    fontSize: "11px"
                  }}
                  onClick={() => setOpenModal(true)}
                >
                  {'Excluir'}
                </Button>

                {openModal && (
                  <BasicModal
                    isCloseModal={() => setOpenModal(false)}
                    isOpenModal={openModal}
                    messageType={"alert"}
                    showButtonCancel={true}
                    textButtonConfirm={"Confirmar"}
                    textButtonCancel='Cancelar'
                    onclickConfirm={() => {
                      setOpenModal(false);
                      removeFile();
                    }}
                    onclickCancel={() => setOpenModal(false)}
                    IMessage={"Você deseja excluir mesmo o arquivo?"}
                  />
                )}
              </>)}
          </div>
        )}
      </StyledSinisterFileItemContainer>
    </>
  )
}