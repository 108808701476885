import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableFooter,
  LinearProgress,
  Pagination,
  Typography,
  Box,
  IconButton,
  Icon,
  useTheme,
  useMediaQuery,
  Grid,
  Divider,
  Stack,
  Badge,
  Tooltip,
  MenuItem,
  Button,
  InputAdornment,
  Card,
  Checkbox,
  Tabs,
  Tab
} from "@mui/material";
import 'moment-timezone';
import 'moment/locale/pt-br';
import { ToolbarSinister } from "../../../shared/components/toolbarSinister/toolbarSinister";
import {
  IApprovedSinisterValues,
  ICanceledSinisterValues,
  IContestedSinisterValues,
  ICreateBordero,
  ISinisterValueId,
  ICreateSinisterValues,
  IRefusedSinisterValues,
  ISinisterDetails,
  ISinisterValues,
  ISinistercheckList,
  ITotalLmi,
  ITotalStatus,
  SinisterService,
} from "../../../shared/services/api/sinister/SinisterService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { Environment } from "../../../shared/environment";
import { BasicModal, ModalContext, ResponsibleModal, ToastContent, ToolbarGridSinisterValue, } from "../../../shared/components";
import { IVFormErros, VForm, VTextField, useVForm, } from "../../../shared/forms";
import { useToast } from "../../../shared/hooks";
import * as yup from "yup";
import { useDebounce } from "../../../shared/hooks";
import moment from "moment";
import { checkByProfileGuard } from "../../../shared/guards/ProfileGuard";
import { PermissionActionsEnum, PermissionRolesEnum, } from "../../../shared/enums/profiles";
import MessageIcon from "@mui/icons-material/Message";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { SinisterFilesUploadArea } from "../components/SinisterFilesUploadArea/SinisterFileUploadArea";
import { GetDocumentTypesResponseType } from "../../../shared/services/api/documents/types";
import { VMaskTextField } from "../../../shared/forms/VMaskTextField";
import { addMonths, format } from "date-fns";
import { SinisterValuesFilesModalContent } from "./SinisterValuesFilesModalContent";
import { RowMenu } from "./RowMenu";
import { globalTranslate } from "../../../shared/utils";
import { IDetalheUsuario, IListagemUsuario, userService, } from "../../../shared/services/api/user/userService";
import { useProfile } from "../../../context/ProfileContext";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { SinisterFollowupModalContent } from "./SinisterFollowupModalContent";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { SinisterFilesModalContent } from "./SinisterFilesModalContent";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import { IProvisionList, IProvisionListBySinister, ProvisionService } from "../../../shared/services/api/provisoes/ProvisionService";

import { SinisterHeader } from "./SinisterHeader/SinisterHeader";
import { ModalContextDocument } from "./content-modal-documents";
import { formatValueBR } from "../../../shared/utils/formatScaleValue";

interface IFormData {
  sinisterId: string;
  status: string;
  typeId: string;
  value: string;
  dueDate: string;
  reference: string;
  reason: string;
}

interface IFormApproved {
  id: string;
  status: string;
  valueApproved: string;
  reasonApproved: string;
}

interface IFormCancel {
  id: string;
  status: string;
  reasonCancel: string;
}

interface IFormContested {
  id: string;
  status: string;
  reasonContested: string;
}

interface IFormReject {
  id: string;
  status: string;
  reasonRefused: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


function colorBackGround(status: string) {
  if (status === "open") {
    return "#e4d9d9";
  }
  if (status === "provision") {
    return "#a9e9eb";
  }
  if (status === "refused") {
    return "#EEC5C5";
  }
  if (status === "accepted") {
    return "#C5EEC9";
  }
  if (status === "approved") {
    return "#C5EEC9";
  }
  if (status === "analysis") {
    return "#F4ECC0";
  }
  if (status === "canceled") {
    return "#EEC5C5";
  }

  return "#adadad";
}
function colorIcon(status: string) {
  if (status === "created") {
    return "#e4870f";
  }
  if (status === "in_progress") {
    return "#12a6ac";
  }
  if (status === "finalized") {
    return "#12c224";
  }
  if (status === "pending") {
    return "#c4a80d";
  }
  if (status === "canceled") {
    return "#eb3232";
  }
  return "#202020";
}

function colorText(status: string) {
  if (status === "created") {
    return "#e4870f";
  }
  if (status === "in_progress") {
    return "#12a6ac";
  }
  if (status === "finalized") {
    return "#12c224";
  }
  if (status === "pending") {
    return "#c4a80d";
  }
  if (status === "canceled") {
    return "#eb3232";
  }

  if (status === "approved") {
    return "#027502";
  }

  if (status === "rejected") {
    return "#eb3232";
  }

  return "#202020";
}


const removeMask = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/[^0-9]/g, "")
    .replace(/(\d{1,2})$/, ".$1");
};

export type ISinisterValuesUI = ISinisterValues & { checked: boolean };

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export const SinisterValuesPresentation: React.FC = () => {
  const { id = "novo" } = useParams<"id">();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const [rows, setRows] = useState<ISinisterDetails>();
  const [rowsValue, setRowsValue] = useState<ISinisterValuesUI[]>([]);
  const [sumStatus, setSumStatus] = useState<ITotalStatus[]>([]); //ITotalStatusObj>();
  const [statusSinister, setStatusSinister] = useState("");
  const [idValue, setIdValue] = useState("");
  const [rowSelect, setRowSelect] = useState<ISinisterValuesUI>(); // linha selecionada
  const [rowUser, setRowUser] = useState<IDetalheUsuario>();
  const [sumLmi, setSumLmi] = useState<ITotalLmi>();
  const [valor, setValor] = useState("0.00"); // linha selecionada
  const [value, setValue] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [updateRow, setUpdateRow] = useState(false);
  const [totalCountValue, setTotalCountValue] = useState(0);
  const [openModalValue, setOpenModalValue] = useState(false);
  const [openModalValueAttachment, setOpenModalValueAttachment] = useState(false);
  const [openModalValueMessages, setOpenModalValueMessages] = useState(false);
  const [openModalAproved, setOpenModalAproved] = useState(false);
  const [openModalCanceled, setOpenModalCanceled] = useState(false);
  const [openModalContested, setOpenModalContested] = useState(false);
  const [openModalCancelSinister, setOpenModalCancelSinister] = useState(false);
  const [responsibleName, setResponsibleName] = useState("Nenhum responsável designado");

  const [openModalRefused, setOpenModalRefused] = useState(false);
  const [showButtonOpenSinister, setShowButtonOpenSinister] = useState(false);
  const [openModalUpdateStatus, setOpenModalUpdateStatus] = useState(false);
  const [showButtonUpdateStatus, setShowButtonUpdateStatus] = useState(false);
  const toastHandler = useToast();
  const [fileTypeId, setFileTypeId] = useState<string>();
  const [file, setFile] = useState<File>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [documentTypes, setDocumentTypes] = useState<GetDocumentTypesResponseType[]>([]);
  const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState<boolean>(false);

  const [openModalBordero, setOpenModalBordero] = useState(false);
  const [openModalResponsible, setOpenModalResponsible] = useState(false);
  const [responsible, setResponsible] = useState<IListagemUsuario[]>([]);
  const { isSuperAdmin, permissionLevel } = useProfile();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IListagemUsuario[]>([]);
  const [openModalFollowup, setOpenModalFollowup] = useState(false);

  const [openModalCheckList, setOpenModalCheckList] = useState(false);
  const [totalBordero, setTotalBordero] = useState(0);

  const [checklist, setChecklist] = useState<ISinistercheckList[]>();
  const [listSel, setListSel] = useState("0");
  const [listDescription, setListDescription] = useState("");
  const [busyProperty, setBusyProperty] = useState("");
  const [openModalAttachment, setOpenModalAttachment] = useState(false);

  const [showButtonSendSinister, setShowButtonSendSinister] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);
  const [generalCheckboxSelected, setGeneralCheckboxSelected] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [reference, setReference] = useState("");
  const selectedIds: Set<string | undefined> = useMemo(() => { return new Set<string | undefined>(); }, []);
  const [showgrid, setShowgrid] = useState(true);
  const [modalDetailBordero, setModalDetailBordero] = useState(false);

  const [rowsProvision, setRowsProvision] = useState<IProvisionListBySinister[]>([]);
  const ReferenceRegex = /^(0[1-9]|1[0-2])\/\d{4}$/;


  const isValuesSelectable = useCallback((row: ISinisterValuesUI): boolean => {
    return row.status === "approved";
  }, []);

  const [itemBordero, setItemBordero] = useState<ISinisterValuesUI[]>([]);


  const handleCheckboxGeneralChange = (checked: boolean) => {
    setGeneralCheckboxSelected(checked);
    const allSelect: ISinisterValuesUI[] = [];

    for (const row of rowsValue) {
      if (isValuesSelectable(row)) {
        row.checked = checked;

        console.log('323...', row.id, row.status)

        if (checked) {
          if (row.status == 'approved') {
            selectedIds.add(row.id);
            allSelect.push(row);
            //setItemBordero(row);
            console.log('add..')
          } else {
            console.log('remove..')
            selectedIds.delete(row.id);
            setItemBordero([]);
          }
        } else {
          console.log('not checked..')
          selectedIds.delete(row.id);
          setItemBordero([]);
        }
      }
    }
    console.log(allSelect);
    setItemBordero(allSelect);
    setRowsValue([...rowsValue]);
  };

  const handleDownloadAll = () => {
    console.log('download alll', id)
    SinisterService.getDownloadAll(id)
      .then((result) => {


      });
  }

  const handleCheckboxRowChange = (row: ISinisterValuesUI) => {
    let checked = false;

    if (selectedIds.has(row.id)) {
      selectedIds.delete(row.id);
      checked = false;
    } else {
      selectedIds.add(row.id);
      checked = true;
    }

    setRowsValue(
      rowsValue.map((pageRow) => {
        if (pageRow.id === row.id) {
          pageRow.checked = checked;
        }
        return pageRow;
      })
    );


    const selectableRows = rowsValue.filter((row) => isValuesSelectable(row));
    const selectedRows = selectableRows.filter((row) => row.checked);

    setGeneralCheckboxSelected(
      selectableRows.length === selectedRows.length &&
      selectableRows.length != 0 &&
      selectedRows.length != 0
    );
    setItemBordero(selectedRows)

  };

  const onFileUploadHandler = (file: File | undefined) => {
    setFile(file);
  };

  const busca = useMemo(() => {
    return searchParams.get("busca") || "";
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get("pagina") || "1");
  }, [searchParams]);

  const tipo = useMemo(() => {
    return searchParams.get("tipo") || "";
  }, [searchParams]);

  const status = useMemo(() => {
    return searchParams.get("status") || "";
  }, [searchParams]);

  const getAllValuesBySinister = () => {
    setIsLoading(true);
    debounce(() => {
      SinisterService.getAllValues(pagina, busca, tipo, status, id).then(
        (result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCountValue(result.totalCount);

            const rows = result.data?.map((row) => {
              return {
                ...row,
                checked: selectedIds.has(row.id),
              };
            });

            const selectableRows = rows.filter((row) =>
              isValuesSelectable(row)
            );
            const selectedRows = selectableRows.filter((row) => row.checked);

            setGeneralCheckboxSelected(
              selectableRows.length === selectedRows.length &&
              selectableRows.length != 0 &&
              selectedRows.length != 0
            );
            setRowsValue(rows);

            setShowButtonOpenSinister(
              result.data.length > 0 && statusSinister == "sketch"
                ? true
                : false
            );
            setShowButtonUpdateStatus(
              statusSinister == "sketch" || statusSinister == "canceled"
                ? false
                : true
            );
          }
        }
      );
      userService
        .getManagers("", id)
        .then((result) => {
          setLoading(false);
          if (result instanceof Error) {
            const titleMessage = "Alerta!!";
            const contentMessage = "Erro: " + result?.message;

            toastHandler.present({
              type: "error",
              position: "top-right",
              messageOrContent: (
                <ToastContent title={titleMessage} content={contentMessage} />
              ),
            });
          } else setResponsible(result.data);
        })
        .catch((err: any) => {
          setLoading(false);
          const { titleMessage, contentMessage } = returnMessageError(err);

          toastHandler.present({
            type: "error",
            position: "top-right",
            messageOrContent: (
              <ToastContent title={titleMessage} content={contentMessage} />
            ),
          });
        });
    });
  };


  const formValidationSchemaValues: yup.SchemaOf<ICreateSinisterValues> = yup
    .object()
    .shape({
      typeId: yup.string().required("Campo Obrigatório"),
      value: yup.string().required("Campo Obrigatório"),
      reference: yup
        .string()
        .matches(
          ReferenceRegex,
          "Referência inválida  digite no formato MM/AAAA."
        )
        .required("Campo Obrigatório"),
      dueDate: yup.string().required("Campo Obrigatório"),
      // reason  :yup.string().required("Campo Obrigatório"),
    });

  const formValidationSchemaUpdateStatus: yup.SchemaOf<{ status: string }> = yup
    .object()
    .shape({
      status: yup.string().required("Campo Obrigatório"),
    });

  const formValidationSchemaCancel: yup.SchemaOf<ICanceledSinisterValues> = yup
    .object()
    .shape({
      reasonCancel: yup.string().required("Campo Obrigatório"),
    });

  const formValidationSchemaContested: yup.SchemaOf<IContestedSinisterValues> =
    yup.object().shape({
      reasonContested: yup.string().required("Campo Obrigatório"),
    });

  const formValidationSchemaRefused: yup.SchemaOf<IRefusedSinisterValues> = yup
    .object()
    .shape({
      reasonRefused: yup.string().required("Campo Obrigatório"),
    });

  const formValidationSchemaApproved: yup.SchemaOf<IApprovedSinisterValues> =
    yup.object().shape({
      valueApproved: yup.string().required("Campo Obrigatório"),
      reasonApproved: yup.string().required("Campo Obrigatório"),
    });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalValueSinister = () => {
    setOpenModalValue(true);
  };

  const handleOpenModalAproved = (paramIdValue: string) => {
    setIdValue(paramIdValue);
    handleClose(), setOpenModalAproved(true);
  };

  const handleOpenModalCancelSinister = () => {
    //handleClose(),
    setOpenModalCancelSinister(true);
  };

  const handleOpenModalCanceled = () => {
    handleClose(), setOpenModalCanceled(true);
  };

  const handleOpenModalContested = () => {
    handleClose(), setOpenModalContested(true);
  };

  const handleOpenModalRefused = () => {
    handleClose(), setOpenModalRefused(true);
  };

  const handleUpdateStatus = () => {
    setOpenModalUpdateStatus(true);
  };

  const handleModalDetailBordero = (id: string) => {
    debounce(() => {
      SinisterService.getValuesSinisterById(id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            console.log(result);
            setItemBordero(result);
            setModalDetailBordero(true)
          }
        });
    });
  };

  //RowMenu --
  const goToEdit = (id: string, e: any) => {
    e.preventDefault();
    setIdValue(id);
    handleOpenModalAproved(id);
  };

  const goTo = (option: string, id: string, e: any, row: ISinisterValuesUI) => {
    e.preventDefault();
    setIdValue(id);
    setRowSelect(row);

    if (option == "aprovar") {
      handleOpenModalAproved(id);
    }
    if (option == "rejeitar") {
      handleOpenModalRefused();
    }
    if (option == "cancelar") {
      handleOpenModalCanceled();
    }

    if (option == "contestar") {
      handleOpenModalContested();
    }
  };

  const handleOpenSinister = (id: string) => {
    setIsLoading(true);
    SinisterService.confirmOpenSinister(id).then((result) => {
      toastHandler.present({
        type: "success",
        position: "top-right",
        messageOrContent: (
          <ToastContent
            title={"Sucesso"}
            content={"Registro gravado com sucesso"}
          />
        ),
      });
      setStatusSinister(result.status);
      setIsLoading(false);
      setOpenModalValue(false);
    });
  };

  const handleGenerateBordero = () => {
    setIsLoading(true);
    const ivalores = itemBordero?.map((element) => ({
      id: element.id || '',
    }));
    console.log('ivalores', ivalores);
    const data: ICreateBordero = {
      sinisterValuesRequest: ivalores,
      dueDate: format(new Date(addMonths(new Date(), 1)), "yyyy-MM-dd HH:mm:ss"),
      sinisterId: id,
      clientId: rows!.clientId!
    }

    SinisterService.generateBordero(id, data).then((result) => {
      toastHandler.present({
        type: "success",
        position: "top-right",
        messageOrContent: (
          <ToastContent
            title={"Sucesso"}
            content={"Registro gravado com sucesso"}
          />
        ),
      });
      // setStatusSinister(result.status);
      setUpdateRow(!updateRow);
      setIsLoading(false);
      setOpenModalBordero(false);
    });
  };

  const handleSave = (dados: IFormData) => {
    setIsLoading(true);
    dados.sinisterId = id;
    dados.status = "pending";

    formValidationSchemaValues
      .validate(dados, { abortEarly: false })
      .then((dadosvalidados) => {
        try {
          if (file) {
            const dadosForm: ISinisterValues = {
              sinisterId: id,
              status: "open",
              dueDate: format(
                new Date(dados.dueDate + " 03:00:00"),
                "yyyy-MM-dd"
              ),
              value: removeMask(dados.value),
              reason: dados.reason,
              reference: dados.reference,
              typeId: dados.typeId,
              file,
            };

            SinisterService.createValue(dadosForm, file).then((result) => {
              setUpdateRow(true);
              toastHandler.present({
                type: "success",
                position: "top-right",
                messageOrContent: (
                  <ToastContent
                    title={"Sucesso"}
                    content={"Registro gravado com sucesso"}
                  />
                ),
              });
              setIsLoading(false);
              setOpenModalValue(false);
              getAllValuesBySinister();
            });
          }
        } catch (e: any) {
          toastHandler.present({
            type: "error",
            messageOrContent: e.message,
            position: "top-center",
          });
        } finally {
          setIsUploading(false);
        }
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);
        const validationErros: IVFormErros = {};
        errors.inner.forEach((error) => {
          if (!error.path) return;
          validationErros[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErros);
      });
  };

  const handleUpdateStatusSave = (dados: { status: string }) => {
    setIsLoading(true);
    formValidationSchemaUpdateStatus
      .validate(dados, { abortEarly: false })
      .then((dadosvalidados) => {
        try {
          SinisterService.updateStatusSinister(id, dados).then((result) => {
            setStatusSinister(dados.status);
            toastHandler.present({
              type: "success",
              position: "top-right",
              messageOrContent: (
                <ToastContent
                  title={"Sucesso"}
                  content={"Status do sinistro atualizado com sucesso"}
                />
              ),
            });
            setIsLoading(false);
            setOpenModalUpdateStatus(false);
            setUpdateRow(!updateRow);
          });
        } catch (e: any) {
          toastHandler.present({
            type: "error",
            messageOrContent: e.message,
            position: "top-center",
          });
        } finally {
          setIsUploading(false);
        }
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);
        const validationErros: IVFormErros = {};
        errors.inner.forEach((error) => {
          if (!error.path) return;
          validationErros[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErros);
      });
  };

  const handleApproved = (dados: IFormApproved) => {
    dados.id = idValue;
    dados.status = "approved";

    formValidationSchemaApproved
      .validate(dados, { abortEarly: false })
      .then((dadosvalidados) => {
        try {
          const dadosForm: IApprovedSinisterValues = {
            valueApproved: removeMask(dadosvalidados.valueApproved),
            reasonApproved: dadosvalidados.reasonApproved,
          };

          SinisterService.updateValueApproved(idValue, dadosForm).then(
            (result) => {
              toastHandler.present({
                type: "success",
                position: "top-right",
                messageOrContent: (
                  <ToastContent
                    title={"Sucesso"}
                    content={"Registro gravado com sucesso"}
                  />
                ),
              });
              setOpenModalAproved(false);
              setUpdateRow(!updateRow);
            }
          );


        } catch (e: any) {
          toastHandler.present({
            type: "error",
            messageOrContent: e.message,
            position: "top-center",
          });
        } finally {
          setIsUploading(false);
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErros: IVFormErros = {};
        errors.inner.forEach((error) => {
          if (!error.path) return;
          validationErros[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErros);
      });
  };

  const handleCancel = (dados: IFormCancel) => {
    dados.id = idValue;
    dados.status = "canceled";

    formValidationSchemaCancel
      .validate(dados, { abortEarly: false })
      .then((dadosvalidados) => {
        try {
          const dadosForm: ICanceledSinisterValues = {
            reasonCancel: dadosvalidados.reasonCancel,

          };

          SinisterService.updateValueCanceled(idValue, dadosForm).then(
            (result) => {
              toastHandler.present({
                type: "success",
                position: "top-right",
                messageOrContent: (
                  <ToastContent
                    title={"Sucesso"}
                    content={"Cancelado com sucesso"}
                  />
                ),
              });
              setOpenModalCanceled(false);
              setUpdateRow(!updateRow);
              // navigate(`/sinistros/valores/${id}`);
            }
          );
        } catch (e: any) {
          toastHandler.present({
            type: "error",
            messageOrContent: e.message,
            position: "top-center",
          });
        } finally {
          setIsUploading(false);
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErros: IVFormErros = {};
        errors.inner.forEach((error) => {
          if (!error.path) return;
          validationErros[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErros);
      });
  };

  const handleContested = (dados: IFormContested) => {
    dados.id = idValue;
    dados.status = "contested";

    formValidationSchemaContested
      .validate(dados, { abortEarly: false })
      .then((dadosvalidados) => {
        try {
          const dadosForm: IContestedSinisterValues = {
            reasonContested: dadosvalidados.reasonContested,
          };

          SinisterService.updateValueContested(idValue, dadosForm).then(
            (result) => {
              toastHandler.present({
                type: "success",
                position: "top-right",
                messageOrContent: (
                  <ToastContent
                    title={"Sucesso"}
                    content={"Contestado com sucesso"}
                  />
                ),
              });
              setOpenModalContested(false);
              setUpdateRow(!updateRow);
              // navigate(`/sinistros/valores/${id}`);
            }
          );
        } catch (e: any) {
          toastHandler.present({
            type: "error",
            messageOrContent: e.message,
            position: "top-center",
          });
        } finally {
          setIsUploading(false);
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErros: IVFormErros = {};
        errors.inner.forEach((error) => {
          if (!error.path) return;
          validationErros[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErros);
      });
  };

  const handleReject = (dados: IFormReject) => {
    dados.id = idValue;
    dados.status = "refused";
    formValidationSchemaRefused
      .validate(dados, { abortEarly: false })
      .then((dadosvalidados) => {
        try {
          const dadosForm: IRefusedSinisterValues = {
            reasonRefused: dadosvalidados.reasonRefused,
          };

          SinisterService.updateValueRefused(idValue, dadosForm).then(
            (result) => {
              toastHandler.present({
                type: "success",
                position: "top-right",
                messageOrContent: (
                  <ToastContent
                    title={"Sucesso"}
                    content={"Valor Rejeitado!"}
                  />
                ),
              });
              setOpenModalRefused(false);
              setUpdateRow(!updateRow);
            }
          );
        } catch (e: any) {
          toastHandler.present({
            type: "error",
            messageOrContent: e.message,
            position: "top-center",
          });
        } finally {
          setIsUploading(false);
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErros: IVFormErros = {};
        errors.inner.forEach((error) => {
          if (!error.path) return;
          validationErros[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErros);
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTotal = (status: string, flag: number = 0) => {
    const sum = sumStatus.filter((element) => element.status == status);
    if (flag && flag == 1) {
      const sumResult = sum.length > 0 ? sum[0].valorAprovado : 0;
      return
      { parseFloat(`${sumResult}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }
    } else {
      const sumResult = sum.length > 0 ? sum[0].valor : 0;
      return
      { parseFloat(`${sumResult}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }

    }
  };

  const handleOpenModalFollowup = () => {
    setOpenModalFollowup(true);
  };

  const handleOpenModalResponsible = () => {
    setOpenModalResponsible(true);
    setUpdateRow(!updateRow);
  };

  const handleCloseModalResponsible = (isAddManager?: boolean) => {
    if (isAddManager) {
      getManagersByClient();
      handleGetSinister();
    }
    setUpdateRow(!updateRow);
    setOpenModalResponsible(false);
  };

  const getManagersByClient = () => {
    if ((isSuperAdmin || permissionLevel == 2) && id !== "novo") {
      setLoading(true);
      debounce(() => {
        userService
          .getManagersByClient(id)
          .then((result) => {
            setLoading(false);
            if (result instanceof Error) {
              const titleMessage = "Alerta!!";
              const contentMessage = "Erro: " + result?.message;
              toastHandler.present({
                type: "error",
                position: "top-right",
                messageOrContent: (
                  <ToastContent title={titleMessage} content={contentMessage} />
                ),
              });
            } else setUsers(result.data);
          })
          .catch((err: any) => {
            setLoading(false);
            const { titleMessage, contentMessage } = returnMessageError(err);
            toastHandler.present({
              type: "error",
              position: "top-right",
              messageOrContent: (
                <ToastContent title={titleMessage} content={contentMessage} />
              ),
            });
          });
      });
    }
  };

  const handleGetSinister = () => {
    SinisterService.getById(`${id}`).then((result) => {
      if (result instanceof Error) {
        alert(result.message);
        navigate("/sinistros");
      } else {
        setNome(result.proposal.name);
        // setDoc(result.proposal.document);

        //######
        if (result.responsibleId) {
          userService.getByID(result?.responsibleId).then((resultUser) => {
            // setLoading(false);
            if (resultUser instanceof Error) {
              const titleMessage = "Alerta!!";
              const contentMessage = "Erro: " + resultUser?.message;
            } else {

              setRowUser(resultUser);
              setResponsibleName(resultUser.name);
            }
          });
        }
        if (result.id) {
          ProvisionService.countBordero(id)
            .then((resultCount: any) => {
              // setLoading(false);
              if (resultCount instanceof Error) {
                setTotalBordero(0);
              } else {
                setTotalBordero(resultCount)
              }
            });
        }
        setRows(result);
        setStatusSinister(result.status ? result.status : "");
        setIsLoading(false);
        setBusyProperty(result?.busyProperty ? result.busyProperty : "");
      }
    });

    SinisterService.totalStatus(`${id}`).then((result) => {
      setIsLoading(false);
      if (result instanceof Error) {
        alert(result.message);
        navigate("/sinistros");
      } else {
        const objResult: ITotalStatus[] = result.data;
        setSumStatus(objResult);
      }
    });

    SinisterService.totalLmi(`${id}`).then((result) => {
      setIsLoading(false);
      if (result instanceof Error) {
        alert(result.message);
        navigate("/sinister");
      } else {
        setSumLmi(result.data);
      }
    });
  };

  const handleOpenModalAttachment = () => {
    setOpenModalAttachment(true);
  };

  const handleOpenModalValueAttachment = () => {
    setOpenModalValueAttachment(true);
  };

  const handleOpenModalValueMessages = () => {
    setOpenModalValueMessages(true);
  };

  const handleReference = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setReference(value);
  };

  const referenceValid = (value: string) => {
    const regex = /^(0[1-9]|1[0-2])\/\d{4}$/;
    return regex.test(value);
  };
  const handleCheckListFiles = async (id: string, ocuped: string) => {
    try {
      const result = await SinisterService.getSinisterCheckList(id, ocuped);

      if (result) {
        const novo = result;
        const resultado = result.data;

        console.log('322.......', result, resultado, 'ocupado.......', ocuped);

        const libera = resultado.filter(element => (
          (element.quant === "0") &&
          ((ocuped === 'open' && element.open === 'required') ||
            (ocuped === 'busy' && element.busy === 'required'))
        ));

        console.log('libera sinistro....329', ocuped, libera);

        const listaDocuments = await Promise.all(result.data.map(async (element) => {
          const documentList = await SinisterService.getSinisterDocumentByList(id, element.Id);
          //  console.log('241',documentList)
          element.sinisterDocuments = await documentList;
          console.log(element)
          return element;
        }));

        console.log(listaDocuments);

        setChecklist(listaDocuments);
        setShowButtonSendSinister(true);

        const resultOff = await SinisterService.getListOff(id);

        if (resultOff) {
          console.log('337 resultoff.....', resultOff);
          setCheckStatus(resultOff.data === 0 ? true : false);
        }
      }
    } catch (error) {
      console.error('Erro ao lidar com a checklist:', error);
      // Trate o erro de acordo com as necessidades do seu aplicativo
    }

    // SinisterService.getSinisterCheckList(id, ocuped).then((result) => {
    //   if (result) {
    //     const novo = result;
    //     const resultado = result.data;
    //     const libera = resultado.filter(
    //       (element) =>
    //         element.quant == "0" &&
    //         ((ocuped == "open" && element.open == "required") ||
    //           (ocuped == "busy" && element.busy == "required"))
    //     );

    //     const listaDocuments = await Promise.all(result.data.map(async (element) => {
    //       const documentList = await SinisterService.getSinisterDocumentByList(id, element.Id);
    //     //  console.log('241',documentList)
    //       element.sinisterDocuments = await documentList;
    //       console.log(element)
    //       return element;
    //   }));

    //   console.log(listaDocuments);

    //   setChecklist(listaDocuments);


    //     //setChecklist(result.data), 
    //     setShowButtonSendSinister(true);
    //     SinisterService.getListOff(id).then((resultOff) => {
    //       if (resultOff) {
    //         setCheckStatus(resultOff.data == 0 ? true : false);
    //       }
    //     });
    //   }
    // });
  };

  const handleUpdateCheckFiles = () => {
    handleCheckListFiles(id, busyProperty);
  };

  const closeAttachment = () => {
    handleCheckListFiles(id, busyProperty);
  };

  const handlechecklist = (id: string, ocuped: string) => {
    setIsLoading(false);
    const check = handleCheckListFiles(id, ocuped);
    setOpenModalCheckList(true);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleShowGrid = (valor: boolean) => {
    setShowgrid(valor);
  };


  useEffect(() => {
    if (!showgrid) {
      setIsLoading(true);
      debounce(() => {
        ProvisionService.getAllBorderoById(id)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              console.log(result.data);

              setTotalCount(result.totalCount);
              setRowsProvision(result.data);
            }
          });
      });
    }
  }, [showgrid]);

  useEffect(() => {
    if (id !== "novo") {
      setIsLoading(true);
      handleGetSinister();
    }
  }, [id, statusSinister, updateRow]);

  useEffect(() => {
    getAllValuesBySinister();
  }, [pagina, busca, tipo, status, id, updateRow, statusSinister]);
  const valuesBorderoTotal = () =>
    formatValueBR(itemBordero?.map(item => Number(item.value))?.reduce((accValue: number, value: number) => accValue + value, 0));

  const valuesBorderoTotalApproved = () =>
    formatValueBR(rowsValue?.filter(item => item.valueApproved?.trim())
      ?.map(item => Number(item.valueApproved))
      ?.reduce((accValue: number, value: number) => accValue + value, 0));

  useEffect(() => {

    valuesBorderoTotal();

    valuesBorderoTotalApproved();

  }, [itemBordero, rowsValue]);

  return (
    <LayoutBaseDePagina
      titulo="Sinistros"
      subtitulo={`Detalhes do Sinistro  [ ${nome} ]`}
      barraDeFerramentas={
        <ToolbarSinister
          showButtonCancel={checkByProfileGuard(
            PermissionRolesEnum.SinisterOptions,
            PermissionActionsEnum.Cancel
          )}
          clickButtonCancel={() => {
            handleOpenModalCancelSinister();
          }}
          showButtonValues={true}
          showSelectedValue="contained" //selecionado
          showButtonValuesLoading={false}//desabilitado
          showButtonDocument={true}
          clickButtonBack={() => navigate(`/sinistros/detalhe/${id}`)}
          showButtonValid={false}
          showButtonConfirm={showButtonOpenSinister ? true : false}
          textButtonConfirm="Confirmar Abertura"
          clickButtonConfirm={() => { handleOpenSinister(id) }}
          clickButtonResponsible={() => { handleOpenModalResponsible(); }}
          clickButtonDocument={() => { handlechecklist(`${id}`, busyProperty); }}
          showButtonFinish={false}
          showButtonResponsible={
            (isSuperAdmin || (permissionLevel ? permissionLevel < 5 : false)) &&
            !(statusSinister == "sketch")
          } //permissao super Admin e usuario Garantti. criar permissao especifica
          clickButtonFollowUp={handleOpenModalFollowup}
        />
      }
    >

      {modalDetailBordero && (
        <ModalContext
          isCloseModal={() => setModalDetailBordero(false)}
          isOpenModal={modalDetailBordero}
          messageType={"info"}
          enableBackdropClose={false}
          overrideWidthValue="60%"
          showButtonCancel={false}
          overrideHeightValue="auto"
          ITitle="Itens do Bordero "
          IMessage={[
            <Table aria-label="a dense table">
              <TableHead>
                <TableRow sx={{ background: "#f5fbfd" }}>
                  <TableCell sx={{ color: "#7e7777b5" }}> Debito </TableCell>
                  <TableCell sx={{ color: "#7e7777b5" }}> {" "}Referência</TableCell>
                  <TableCell sx={{ color: "#7e7777b5" }}> Valor</TableCell>
                  <TableCell sx={{ color: "#7e7777b5" }}>{" "}Aprovado</TableCell>
                  <TableCell sx={{ color: "#7e7777b5" }}>{" "}Abertura</TableCell>
                  <TableCell sx={{ color: "#7e7777b5" }}>{" "}Vencimento</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemBordero?.map((row) => (
                  <TableRow key={row.id} hover>
                    <TableCell sx={{ fontWeight: "normal" }}>
                      <Typography
                        variant="subtitle2"
                        color={"#5c5858"}
                        sx={{ fontWeight: "normal" }}
                      >
                        {globalTranslate(row.typeId)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        color={"#5c5858"}
                        sx={{ fontWeight: "normal" }}
                      >
                        {row.reference}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{ color: "#959595", fontWeight: "normal" }}
                    >
                      <Typography
                        variant="subtitle2"
                        color={"#959595"}
                        fontWeight={"normal"}
                      >
                        {parseFloat(`${row.value}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{ color: "#959595", fontWeight: "normal" }}
                    >
                      <Typography
                        variant="subtitle2"
                        color={"#959595"}
                        fontWeight={"normal"}
                      >
                        {parseFloat(`${row.valueApproved}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{ color: "#959595", fontWeight: "normal" }}
                    >
                      <Typography
                        variant="subtitle2"
                        color={"#959595"}
                        fontWeight={"normal"}
                      >
                        {moment(row.createdAt).format("L")}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{ color: "#959595", fontWeight: "normal" }}
                    >
                      <Typography
                        variant="subtitle2"
                        color={"#959595"}
                        fontWeight={"normal"}
                      >
                        {moment(row.dueDate).format("L")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2"
                      color={"#5c5858"}
                      sx={{ fontWeight: "normal" }}>
                      Total
                    </Typography>

                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2"
                      color={"#5c5858"}
                      sx={{ fontWeight: "normal" }}>
                      {selectedIds.size}
                    </Typography>

                  </TableCell>
                  <TableCell colSpan={2}>
                    <Typography variant="subtitle2"
                      color={"#5c5858"}
                      sx={{ fontWeight: "normal" }}>
                      {valuesBorderoTotal()}
                      {/* {
                        (rowsValue.map((element) => (element.checked) ? Number(element.valueApproved) : 0)
                          .reduce((acc: number, element) => acc + element)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                      } */}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <Typography variant="subtitle2"
                      color={"#5c5858"}
                      sx={{ fontWeight: "normal" }}>
                      {valuesBorderoTotalApproved()}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          ]}
          showButtonConfirm={true}
          onclickConfirm={() => { setModalDetailBordero(false) }}
          textButtonConfirm="Sair"
        />
      )}

      {openModal && (
        <BasicModal
          isCloseModal={() => setOpenModal(false)}
          isOpenModal={openModal}
          messageType={"success"}
          showButtonCancel={false}
          textButtonConfirm={"OK"}
          onclickConfirm={() => {
            setOpenModal(false), navigate("/analises");
          }}
          IMessage={"Contratação realizada com sucesso !"}
        />
      )}

      {(isSuperAdmin || permissionLevel == 2) && openModalUpdateStatus && (
        <ModalContext
          isCloseModal={() => setOpenModalValue(false)}
          isOpenModal={openModalUpdateStatus}
          messageType={"info"}
          enableBackdropClose={false}
          overrideWidthValue="40%"
          showButtonCancel={false}
          overrideHeightValue="auto"
          ITitle="Alterar status do sinistro "
          IMessage={[
            <Box style={{ width: "90%" }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
              >
                <VForm 
                 id="formIdUpdateStatusSave"
                 placeholder="Your placeholder"
                 onPointerEnterCapture={() => console.log("Pointer entered")}
                 onPointerLeaveCapture={() => console.log("Pointer left")}
                ref={formRef} onSubmit={handleUpdateStatusSave}>
                  <Grid container direction="column" padding={1} spacing={2}>
                    {isLoading && (
                      <Grid item>
                        <LinearProgress variant="indeterminate" />
                      </Grid>
                    )}
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <VTextField
                          fullWidth
                          label="Status do Sinistro"
                          name="status"
                          disabled={isLoading}
                          select
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                        >
                          <MenuItem value={""}>Selecionar Status</MenuItem>
                          <MenuItem value={"open"}>Aberto</MenuItem>
                          <MenuItem value={"in_progress"}>
                            Em análise
                          </MenuItem>
                          <MenuItem value={"debts_confession"}>
                            Confissão de dividas{" "}
                          </MenuItem>
                          <MenuItem value={"monthly_compensation"}>
                            Indenização Mensal
                          </MenuItem>
                          <MenuItem value={"finalized"}>Finalizado</MenuItem>
                          <MenuItem value={"according"}>Em Acordo</MenuItem>
                          <MenuItem value={"pending"}>Pendenciado</MenuItem>
                          <MenuItem value={"canceled"}>Cancelado</MenuItem>
                        </VTextField>
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'}>
                        <Button
                          color="primary"
                          disableElevation
                          variant={"contained"}
                          onClick={() => {
                            formRef.current?.submitForm();
                          }}
                          disabled={isUploading}
                          style={{ marginTop: "16px", marginRight: "10px", width: "30%", }}
                        >
                          {"Confirmar"}
                        </Button>
                        <Button color="primary" disableElevation variant={"outlined"} style={{ marginTop: "16px", width: "30%", }}
                          startIcon={<Icon> arrow_back_Ico</Icon>}
                          onClick={() => {
                            setOpenModalUpdateStatus(false);
                          }}
                        >
                          {"Voltar"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </VForm>
              </Typography>
            </Box>,
          ]}
        />
      )}

      {openModalValue && (
        <ModalContext
          isCloseModal={() => setOpenModalValue(false)}
          isOpenModal={openModalValue}
          messageType={"info"}
          enableBackdropClose={false}
          overrideWidthValue="60%"
          showButtonCancel={false}
          overrideHeightValue="auto"
          ITitle="Adicionar débito do sinistro "
          IMessage={[
            <Box style={{ width: "90%" }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
              >
                <VForm 
                 id="formIdSave"
                 placeholder="Your placeholder"
                 onPointerEnterCapture={() => console.log("Pointer entered")}
                 onPointerLeaveCapture={() => console.log("Pointer left")}
                 ref={formRef} onSubmit={handleSave}>
                  <Grid container direction="column" padding={1} spacing={2}>
                    {isLoading && (
                      <Grid item>
                        <LinearProgress variant="indeterminate" />
                      </Grid>
                    )}
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <VTextField
                          fullWidth
                          label="Tipo"
                          name="typeId"
                          disabled={isLoading}
                          select
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                        >
                          <MenuItem value={"rent_values"}>
                            Somente Aluguel
                          </MenuItem>
                          <MenuItem value={"fine_on_rent"}>
                            Multa sobre Aluguel
                          </MenuItem>
                          <MenuItem value={"condominium_values"}>
                            Condomínio
                          </MenuItem>
                          <MenuItem value={"fine_on_condominium"}>
                            Multa sobre valor do condomínio
                          </MenuItem>
                          <MenuItem value={"water_values"}>Água</MenuItem>
                          <MenuItem value={"energy_values"}>
                            Energia Elétrica
                          </MenuItem>
                          <MenuItem value={"iptu"}>
                            IPTU
                          </MenuItem>
                          <MenuItem value={"gas_values"}>Gás</MenuItem>
                          <MenuItem value={"damage"}>Danos ao Imóvel</MenuItem>
                          <MenuItem value={"painting"}>Pintura</MenuItem>

                          <MenuItem value={"garantti_tax"}>
                            Taxa mensal Garantti
                          </MenuItem>
                          <MenuItem value={"other"}>Outros</MenuItem>
                          <MenuItem value={"fire_insurance"}>
                            Apólice seguro incêndio
                          </MenuItem>

                        </VTextField>
                      </Grid>
                    </Grid>

                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VMaskTextField
                          fullWidth
                          placeholder={"mes/ano"}
                          label="Competência"
                          name="reference"
                          disabled={isLoading}
                          onChange={handleReference}
                          mask="mesano"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VMaskTextField
                          fullWidth
                          label="Valor"
                          name="value"
                          disabled={isLoading}
                          mask="real"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VTextField
                          fullWidth
                          label="Vencimento"
                          name="dueDate"
                          disabled={isLoading}
                          type="date"
                        />
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <VTextField
                          fullWidth
                          label="Observação"
                          name="reason"
                          maxRows={3}
                          multiline={true}
                          disabled={isLoading}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item direction="row">
                    <SinisterFilesUploadArea
                      onFileUploadHandler={onFileUploadHandler}
                      isLoadingProp={isUploading}
                      clear={fileUploadedSuccessfully}
                    />
                  </Grid>

                  <Grid
                    container
                    sm={12}
                    sx={{
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    display={"flex"}
                    flexDirection={smDown ? "column" : "row"}
                    padding={2}
                    gap={2}
                  >
                    <Button
                      color="primary"
                      disableElevation
                      variant={"contained"}
                      onClick={() => {
                        if (!file) {
                          toastHandler.present({
                            type: "error",
                            position: "top-right",
                            messageOrContent: (
                              <ToastContent
                                title={"Sucesso"}
                                content={
                                  "Obrigatório o anexo de arquivo/comprovante "
                                }
                              />
                            ),
                          });
                        } else {
                          formRef.current?.submitForm();
                        }
                      }}
                      disabled={isUploading}
                      style={{
                        marginTop: "16px",
                        width: "100%",
                      }}
                    >
                      {"Confirmar"}
                    </Button>
                    <Button
                      color="primary"
                      disableElevation
                      variant={"outlined"}
                      startIcon={<Icon> arrow_back_Ico</Icon>}
                      onClick={() => {
                        setOpenModalValue(false);
                      }}
                    >
                      {"Voltar"}
                    </Button>
                  </Grid>
                </VForm>
              </Typography>
            </Box>,
          ]}
        />
      )}

      {openModalAproved && (
        <ModalContext
          isCloseModal={() => setOpenModalAproved(false)}
          isOpenModal={openModalAproved}
          messageType={"info"}
          enableBackdropClose={false}
          overrideWidthValue="40%"
          showButtonCancel={false}
          overrideHeightValue="auto"
          ITitle={`Aprovação de item`}
          IMessage={[
            <Box style={{ width: "90%" }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
              >
                <VForm 
                 id="formIdApproved"
                 placeholder="Your placeholder"
                 onPointerEnterCapture={() => console.log("Pointer entered")}
                 onPointerLeaveCapture={() => console.log("Pointer left")}
                ref={formRef} onSubmit={handleApproved}>
                  <Grid container direction="column" padding={1} spacing={2}>
                    {isLoading && (
                      <Grid item>
                        <LinearProgress variant="indeterminate" />
                      </Grid>
                    )}
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container item direction="row" spacing={1}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography
                              variant="caption"
                              color={"text.secondary"}
                            >
                              Status
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{ color: "greenyellow" }}
                            >
                              APROVADO
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography
                              variant="caption"
                              color={"text.secondary"}
                            >
                              Data Aprovação
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "gray" }}
                            >
                              {format(new Date(), "dd/MM/yyyy")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="caption" color={"text.secondary"}>
                          Valor
                        </Typography>
                        <Typography variant="h5" sx={{ color: "red" }}>
                          {/* {maskReal(rowSelect ? rowSelect.value : "0.00")} */}
                          {parseFloat(`${rowSelect?.value}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <VMaskTextField
                          fullWidth
                          label="Valor Aprovado"
                          name="valueApproved"
                          disabled={isLoading}
                          mask="real"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <VTextField
                          fullWidth
                          label="Descreva o motivo"
                          name="reasonApproved"
                          disabled={isLoading}
                          multiline
                          rows={4}
                        ></VTextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item direction="row"></Grid>

                  <Grid
                    container
                    sm={12}
                    sx={{
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    display={"flex"}
                    flexDirection={smDown ? "column" : "row"}
                    padding={2}
                    gap={2}
                  >
                    <Button
                      color="primary"
                      disableElevation
                      variant={"outlined"}
                      startIcon={<Icon> arrow_back_Ico</Icon>}
                      onClick={() => {
                        setOpenModalAproved(false);
                      }}
                    >
                      {"Voltar"}
                    </Button>
                    <Button
                      color="primary"
                      disableElevation
                      variant={"contained"}
                      startIcon={<Icon> checkIcon</Icon>}
                      onClick={() => {
                        //setOpenModalAproved(false)
                        formRef.current?.submitForm();
                      }}
                    >
                      {"Confirmar"}
                    </Button>
                  </Grid>
                </VForm>
              </Typography>
            </Box>,
          ]}
        />
      )}

      {openModalCanceled && (
        <ModalContext
          isCloseModal={() => setOpenModalCanceled(false)}
          isOpenModal={openModalCanceled}
          messageType={"error"}
          enableBackdropClose={false}
          overrideWidthValue="40%"
          showButtonCancel={false}
          overrideHeightValue="auto"
          ITitle="Cancelar item"
          IMessage={[
            <Box style={{ width: "90%" }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
              >
                <VForm 
                 id="formIdCancel"
                 placeholder="Your placeholder"
                 onPointerEnterCapture={() => console.log("Pointer entered")}
                 onPointerLeaveCapture={() => console.log("Pointer left")}
                ref={formRef} onSubmit={handleCancel}>
                  <Grid container direction="column" padding={1} spacing={2}>
                    {isLoading && (
                      <Grid item>
                        <LinearProgress variant="indeterminate" />
                      </Grid>
                    )}
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container item direction="row" spacing={1}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography
                              variant="caption"
                              color={"text.secondary"}
                            >
                              Status
                            </Typography>
                            <Typography variant="h5" sx={{ color: "red" }}>
                              Cancelado
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography
                              variant="caption"
                              color={"text.secondary"}
                            >
                              Data
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "gray" }}
                            >
                              {moment(rows?.createdAt).format("L")}
                              {/* 19/06/2023 */}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12}>
                        <VTextField
                          fullWidth
                          label="Descreva o motivo do cancelamento"
                          name="reasonCancel"
                          disabled={isLoading}
                          multiline
                          rows={4}
                        ></VTextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item direction="row"></Grid>

                  <Grid
                    container
                    sm={12}
                    sx={{
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    display={"flex"}
                    flexDirection={smDown ? "column" : "row"}
                    padding={2}
                    gap={2}
                  >
                    <Button
                      color="primary"
                      disableElevation
                      variant={"outlined"}
                      startIcon={<Icon> arrow_back_Ico</Icon>}
                      onClick={() => {
                        //formRef.current?.submitForm();
                        setOpenModalCanceled(false);
                      }}
                    >
                      {"Voltar"}
                    </Button>
                    <Button
                      color="primary"
                      disableElevation
                      variant={"contained"}
                      startIcon={<Icon> checkIcon</Icon>}
                      onClick={() => {
                        formRef.current?.submitForm();
                        // setOpenModalCanceled(false)
                      }}
                    >
                      {"Confirmar"}
                    </Button>
                  </Grid>
                </VForm>
              </Typography>
            </Box>,
          ]}
        />
      )}

      {openModalRefused && (
        <ModalContext
          isCloseModal={() => setOpenModalRefused(false)}
          isOpenModal={openModalRefused}
          messageType={"error"}
          enableBackdropClose={false}
          overrideWidthValue="40%"
          showButtonCancel={false}
          overrideHeightValue="auto"
          ITitle="Recusa de item"
          IMessage={[
            <Box style={{ width: "90%" }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
              >
                <VForm 
                 id="formIdReject"
                 placeholder="Your placeholder"
                 onPointerEnterCapture={() => console.log("Pointer entered")}
                 onPointerLeaveCapture={() => console.log("Pointer left")}
                ref={formRef} onSubmit={handleReject}>
                  <Grid container direction="column" padding={1} spacing={2}>
                    {isLoading && (
                      <Grid item>
                        <LinearProgress variant="indeterminate" />
                      </Grid>
                    )}
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container item direction="row" spacing={1}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography
                              variant="caption"
                              color={"text.secondary"}
                            >
                              Status
                            </Typography>
                            <Typography variant="h5" sx={{ color: "red" }}>
                              Rejeitado
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography
                              variant="caption"
                              color={"text.secondary"}
                            >
                              Data
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "gray" }}
                            >
                              {moment(rows?.createdAt).format("L")}
                              {/* 19/06/2023 */}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12}>
                        <VTextField
                          fullWidth
                          label="Descreva o motivo da recusa"
                          name="reasonRefused"
                          disabled={isLoading}
                          multiline
                          rows={4}
                        ></VTextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item direction="row"></Grid>

                  <Grid
                    container
                    sm={12}
                    sx={{
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    display={"flex"}
                    flexDirection={smDown ? "column" : "row"}
                    padding={2}
                    gap={2}
                  >
                    <Button
                      color="primary"
                      disableElevation
                      variant={"outlined"}
                      startIcon={<Icon> arrow_back_Ico</Icon>}
                      onClick={() => {
                        setOpenModalRefused(false);
                      }}
                    >
                      {"Voltar"}
                    </Button>
                    <Button
                      color="primary"
                      disableElevation
                      variant={"contained"}
                      startIcon={<Icon> checkIcon</Icon>}
                      onClick={() => {
                        //setOpenModalRefused(false)
                        formRef.current?.submitForm();
                      }}
                    >
                      {"Confirmar"}
                    </Button>
                  </Grid>
                </VForm>
              </Typography>
            </Box>,
          ]}
        />
      )}

      {(isSuperAdmin || permissionLevel == 2) && openModalResponsible && (
        <ResponsibleModal
          isCloseModal={() => {
            handleCloseModalResponsible();
          }}
          isOpenModal={openModalResponsible}
          messageType={"info"}
          onclickConfirm={() => {
            handleCloseModalResponsible(true);
          }}
          responsibles={responsible}
          idSinister={id}
        />
      )}

      {openModalFollowup && (
        <ModalContext
          isCloseModal={() => setOpenModalFollowup(false)}
          isOpenModal={openModalFollowup}
          messageType={"question"}
          showButtonCancel={false}
          showButtonConfirm={false}
          showTitleIcon={false}
          textButtonCancel="Fechar"
          enableBackdropClose={true}
          overrideWidthValue={"45%"}
          overrideHeightValue={"100%"}
          ITitle={"Followup do Sinistro"}
          IMessage={[<SinisterFollowupModalContent sinisterId={id} />]}
        />
      )}

      {openModalCheckList && (
        <ModalContext
          isCloseModal={() => setOpenModalCheckList(false)}
          isOpenModal={openModalCheckList}
          messageType={"info"}
          showButtonCancel={false}
          showButtonConfirm={true}
          textButtonConfirm={"Continuar"}
          textButtonCancel={"Continuar "}
          onclickConfirm={() => {
            setOpenModalCheckList(false);

            //alert("teste feito")
          }}
          ITitle={
            "Documentos do Sinistro"
            // propertyOccupied
            //   ? "Termos de abertura de Sinistro Imóvel Ocupado"
            //   : "Termos de abertura de Sinistro Imóvel Desocupado"
          }
          IMessage={[
            <>
              <ModalContextDocument
                checkList={checklist ? checklist : undefined}
                onClickDown={handleDownloadAll}
                onClickOpenAttachment={handleOpenModalAttachment}
              />
            </>
          ]}
        />
      )}

      {openModalAttachment && (
        <ModalContext
          isCloseModal={() => {
            setOpenModalAttachment(false), closeAttachment();
          }}
          isOpenModal={openModalAttachment}
          messageType={"question"}
          showButtonCancel={true}
          showButtonConfirm={false}
          showTitleIcon={false}
          textButtonCancel="Fechar"
          onclickConfirm={() => {
            handleUpdateCheckFiles();
          }}
          enableBackdropClose={true}
          overrideWidthValue={"45%"}
          ITitle={"Documento do Sinistro"}
          ISubTitle={`Tipo do Documento ${listDescription}`}
          IMessage={[
            <SinisterFilesModalContent
              sinisterId={id}
              listId={listSel}
              subTitle={`Anexar Somente ${listDescription}`}
            />,
          ]}
        />
      )}

      {openModalValueAttachment && (
        <ModalContext
          isCloseModal={() => setOpenModalValueAttachment(false)}
          isOpenModal={openModalValueAttachment}
          messageType={"question"}
          showButtonCancel={false}
          showButtonConfirm={false}
          showTitleIcon={false}
          textButtonCancel="Fechar"
          onclickConfirm={() => { }}
          enableBackdropClose={true}
          overrideWidthValue={"45%"}
          ITitle={"Documento dos Valores do Sinistro"}
          ISubTitle={`Tipo do Documento`}
          IMessage={[
            <SinisterValuesFilesModalContent
              sinisterId={id}
              listId={idValue}
              subTitle={`Anexar Somente `}
              sinisterValues ={ rowSelect?rowSelect:undefined}
            />,
          ]}
        />
      )}

      {openModalContested && (
        <ModalContext
          isCloseModal={() => setOpenModalContested(false)}
          isOpenModal={openModalContested}
          messageType={"warning"}
          enableBackdropClose={false}
          overrideWidthValue="40%"
          showButtonCancel={false}
          overrideHeightValue="auto"
          ITitle="Contestar item"
          IMessage={[
            <Box style={{ width: "90%" }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
              >
                <VForm 
                 id="formIdContested"
                 placeholder="Your placeholder"
                 onPointerEnterCapture={() => console.log("Pointer entered")}
                 onPointerLeaveCapture={() => console.log("Pointer left")}
                ref={formRef} onSubmit={handleContested}>
                  <Grid container direction="column" padding={1} spacing={2}>
                    {isLoading && (
                      <Grid item>
                        <LinearProgress variant="indeterminate" />
                      </Grid>
                    )}
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container item direction="row" spacing={1}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography
                              variant="caption"
                              color={"text.secondary"}
                            >
                              Status
                            </Typography>
                            <Typography variant="h5" sx={{ color: "red" }}>
                              Contestado
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography
                              variant="caption"
                              color={"text.secondary"}
                            >
                              Data
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "gray" }}
                            >
                              {moment(rows?.createdAt).format("L")}
                              {/* 19/06/2023 */}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12}>
                        <VTextField
                          fullWidth
                          label="Descreva o motivo da contestação"
                          name="reasonContested"
                          disabled={isLoading}
                          multiline
                          rows={4}
                        ></VTextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item direction="row"></Grid>

                  <Grid
                    container
                    sm={12}
                    sx={{
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    display={"flex"}
                    flexDirection={smDown ? "column" : "row"}
                    padding={2}
                    gap={2}
                  >
                    <Button
                      color="primary"
                      disableElevation
                      variant={"outlined"}
                      startIcon={<Icon> arrow_back_Ico</Icon>}
                      onClick={() => {
                        //formRef.current?.submitForm();
                        setOpenModalContested(false);
                      }}
                    >
                      {"Voltar"}
                    </Button>
                    <Button
                      color="primary"
                      disableElevation
                      variant={"contained"}
                      startIcon={<Icon> checkIcon</Icon>}
                      onClick={() => {
                        formRef.current?.submitForm();
                        // setOpenModalContested(false)
                      }}
                    >
                      {"Confirmar"}
                    </Button>
                  </Grid>
                </VForm>
              </Typography>
            </Box>,
          ]}
        />
      )}

      {openModalValueMessages && (
        <ModalContext
          isCloseModal={() => setOpenModalValue(false)}
          isOpenModal={openModalValueMessages}
          messageType={"info"}
          enableBackdropClose={false}
          overrideWidthValue="40%"
          showButtonCancel={false}
          overrideHeightValue="auto"
          ITitle="Informações sobre o Sinistro "
          IMessage={[
            <Box style={{ width: "90%" }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
              >
                <Grid container direction="column" padding={1} spacing={2}>
                  {isLoading && (
                    <Grid item>
                      <LinearProgress variant="indeterminate" />
                    </Grid>
                  )}

                  <Grid container item direction="row" spacing={1}>
                    <Grid container item direction="row" spacing={1}>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="caption" color={"text.secondary"}>
                          Inquilino
                        </Typography>
                        <Typography variant="subtitle2">
                          {rows?.proposal?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Typography variant="subtitle2">
                          {moment(rows?.createdAt).format("L")}
                        </Typography>
                        <Typography variant="caption" color={"text.secondary"}>
                          {"Criada "}
                          {moment(rows?.createdAt).startOf("day").fromNow()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {rowSelect &&
                    rowSelect?.reason.length != 0 &&
                    rowSelect?.reason?.length != undefined && (
                      <Grid container item direction="row" spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: "#f0f1f1" }}>
                          <Typography
                            variant="caption"
                            color={"text.secondary"}
                          >
                            Motivo do Sinistro
                          </Typography>
                          <Typography variant="subtitle2">
                            {rowSelect?.reason}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                  {rowSelect &&
                    rowSelect?.reasonApproved?.length != 0 &&
                    rowSelect?.reasonApproved?.length != undefined && (
                      <Grid container item direction="row" spacing={1}>
                        <Grid container item direction="row" spacing={1}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography
                              variant="caption"
                              color={"text.secondary"}
                            >
                              Observações da Aprovação
                            </Typography>
                            <Typography variant="subtitle2">
                              {rowSelect?.reasonApproved}
                              {rowSelect?.reasonApproved?.length}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                  {rowSelect &&
                    rowSelect?.reasonRefused?.length != 0 &&
                    rowSelect?.reasonRefused?.length != undefined && (
                      <Grid container item direction="row" spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: "#f0f1f1" }}>
                          <Typography
                            variant="caption"
                            color={"text.secondary"}
                          >
                            Motivo de Recusa
                          </Typography>
                          <Typography variant="subtitle2">
                            {rowSelect?.reasonRefused}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                  {rowSelect &&
                    rowSelect?.reasonContested?.length != 0 &&
                    rowSelect?.reasonContested?.length != undefined && (
                      <Grid container item direction="row" spacing={1}>
                        <Grid container item direction="row" spacing={1}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography
                              variant="caption"
                              color={"text.secondary"}
                            >
                              Contestação
                            </Typography>
                            <Typography variant="subtitle2">
                              {rowSelect?.reasonContested}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                  {rowSelect &&
                    rowSelect?.reasonCancel?.length != 0 &&
                    rowSelect?.reasonContested?.length != undefined && (
                      <Grid container item direction="row" spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: "#f0f1f1" }}>
                          <Typography
                            variant="caption"
                            color={"text.secondary"}
                          >
                            Motivo de Cancelamento
                          </Typography>
                          <Typography variant="subtitle2">
                            {rowSelect?.reasonCancel}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  <Grid container item direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
                  </Grid>
                  <Grid container item direction="row" spacing={2} textAlign={'center'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Button
                        color="primary"
                        disableElevation
                        variant={"outlined"}
                        style={{
                          marginTop: "16px",
                          width: "30%",
                        }}
                        startIcon={<Icon> arrow_back_Ico</Icon>}
                        onClick={() => {
                          setOpenModalValueMessages(false);
                        }}
                      >
                        {"Voltar"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Typography>
            </Box>,
          ]}
        />
      )}

      {openModalBordero && (
        <ModalContext
          isCloseModal={() => setOpenModalBordero(false)}
          isOpenModal={openModalBordero}
          messageType={"info"}
          enableBackdropClose={false}
          overrideWidthValue="50%"
          showButtonCancel={false}
          overrideHeightValue="auto"
          ITitle="Confirma a geração do Bordero "
          IMessage={[
            <Box style={{ width: "90%" }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
              >
                <Grid container direction="column" padding={1} spacing={2}>
                  {isLoading && (
                    <Grid item>
                      <LinearProgress variant="indeterminate" />
                    </Grid>
                  )}
                  {/* ******************************************** tabela de bordero  *********************************** */}
                  <Table aria-label="a dense table">
                    <TableHead>
                      <TableRow sx={{ background: "#f5fbfd" }}>
                        <TableCell sx={{ color: "#7e7777b5" }}> Debito </TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}> {" "}Referência</TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}> Valor</TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}>{" "}Aprovado</TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}>{" "}Abertura</TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}>{" "}Vencimento</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itemBordero?.map((row) => (
                        <TableRow key={row.id} hover>
                          <TableCell sx={{ fontWeight: "normal" }}>
                            <Typography
                              variant="subtitle2"
                              color={"#5c5858"}
                              sx={{ fontWeight: "normal" }}
                            >
                              {globalTranslate(row.typeId)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              color={"#5c5858"}
                              sx={{ fontWeight: "normal" }}
                            >
                              {row.reference}
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{ color: "#959595", fontWeight: "normal" }}
                          >
                            <Typography
                              variant="subtitle2"
                              color={"#959595"}
                              fontWeight={"normal"}
                            >
                              {/* {'R$'} {maskReal(`${row.value}`)} */}
                              {parseFloat(`${row.value}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{ color: "#959595", fontWeight: "normal" }}
                          >
                            <Typography
                              variant="subtitle2"
                              color={"#959595"}
                              fontWeight={"normal"}
                            >
                              {/* {'R$'} {maskReal(`${row.valueApproved}`)} */}
                              {parseFloat(`${row.valueApproved}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{ color: "#959595", fontWeight: "normal" }}
                          >
                            <Typography
                              variant="subtitle2"
                              color={"#959595"}
                              fontWeight={"normal"}
                            >
                              {moment(row.createdAt).format("L")}
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{ color: "#959595", fontWeight: "normal"}}
                          >
                            <Typography
                              variant="subtitle2"
                              color={"#959595"}
                              fontWeight={"normal"}
                            >
                              {moment(row.dueDate).format("L")}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter  >
                      <TableRow>
                        <TableCell sx={{ border: "none"}}>
                          <Typography variant="subtitle2"
                            color={"#5c5858"}
                            sx={{ fontWeight: "bold" }}>
                            Total
                          </Typography>
                        </TableCell>

                        <TableCell  sx={{ border: "none"}}>
                          <Typography variant="subtitle2" 
                            color={"#5c5858"}
                            sx={{ fontWeight: "bold" }}>
                            {selectedIds.size}
                          </Typography>
                        </TableCell>

                        <TableCell  sx={{ border: "none"}}>
                          <Typography variant="subtitle2"
                            color={"#5c5858"}
                            sx={{ fontWeight: "bold" }}>
                            {
                              valuesBorderoTotal()
                              // (rowsValue.map((element) => (element.checked) ? Number(element.valueApproved) : 0)
                              //   .reduce((acc: number, element) => acc + element)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                            }
                          </Typography>
                        </TableCell>

                        <TableCell  sx={{ border: "none"}}>
                          <Typography variant="subtitle2"
                            color={"#5c5858"}
                            sx={{ fontWeight: "bold" }}>
                            {
                              valuesBorderoTotalApproved()
                            }
                          </Typography>
                        </TableCell>

                      </TableRow>
                    </TableFooter>
                  </Table>


                  {/* ******************************************************************************* */}

                  <Grid container item direction="row" spacing={2}  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={"center"} gap={2} flexDirection={smDown ? 'column' : 'row'} >
                      <Button
                        color="primary"
                        disableElevation
                        variant={"outlined"}
                        style={{
                          marginTop: "16px",
                          width: "20%",
                        }}
                        fullWidth
                        startIcon={<Icon> arrow_back_Ico</Icon>}
                        onClick={() => {
                          setOpenModalBordero(false);
                        }}
                      >
                        {"Voltar"}
                      </Button>

                      <Button
                        color="primary"
                        disableElevation
                        variant={"contained"}
                        style={{
                          marginTop: "16px",
                          width: "20%",
                        }}
                        startIcon={<Icon> check</Icon>}
                        onClick={() => {
                          handleGenerateBordero();
                          setOpenModalBordero(false);
                        }}
                      >
                        {"Gerar"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Typography>
            </Box>,
          ]}
        />
      )}

      <Grid container spacing={3}>
        <SinisterHeader
          LMIResult={sumLmi?.saldo ? sumLmi?.saldo : 0}
          LMIvalue={sumLmi?.lmiTotal ? sumLmi?.lmiTotal : 0}
          borderoTotally={totalBordero}
          certificate={rows?.proposal?.certificateNumber}
          closeSinister={rows?.finalizedAt}
          createAt={rows?.createdAt}
          emailClient={rows?.client?.email}
          name={rows?.proposal?.name}
          openResponse={rows?.client?.name}
          openSinister={rows?.createdAt}
          proportyBusy={rows?.busyProperty}
          showButtonStatus={(isSuperAdmin || permissionLevel == 2) && showButtonUpdateStatus ? true : false}
          sinisterResponse={responsibleName}
          status={rows?.status}
          onclickbuttonStatus={() => { handleUpdateStatus() }}
          textButtonStatus="Status"
        />

        <Box display="flex" flexDirection={"column"} width={"100%"} margin={1} marginLeft={4} component={Paper} padding={2} borderRadius={"2px"}>
          <Grid container item direction="row" spacing={2} mb={1}>
            <Grid item xs={10}>
              <Typography variant="h6" color={"primary"}>
                Itens do Sinistro
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ width: '100%' }}>
            <Box display={"flex"}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '40%' }} >
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Valores" {...a11yProps(0)} onClick={() => { handleShowGrid(true) }} />
                  <Tab label="Borderos" {...a11yProps(1)} onClick={() => { handleShowGrid(false) }} />
                </Tabs>
              </Box>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '60%' }} display={'flex'} justifyContent={'flex-end'} >
                {(showgrid) && (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <ToolbarGridSinisterValue
                        showButtonNew={checkByProfileGuard(
                          PermissionRolesEnum.SinisterValuesOptions,
                          PermissionActionsEnum.Add
                        )}
                        showInputBusca={false}
                        showInputStatus={false}
                        textSearch={busca}
                        onChangeTextBusca={(texto) =>
                          setSearchParams(
                            { busca: texto, pagina: "1" },
                            { replace: true }
                          )
                        }
                        style={{ width: "100%", marginLeft: "45px" }}
                        textButtonNew={"Adicionar"}
                        showInputOrigem={false}
                        onClickNovo={handleOpenModalValueSinister}
                        textButtonConfirm={"Confirmar Abertura"}
                        showButtonConfirm={false}
                      />
                    </TableCell>
                    <TableCell colSpan={5}>
                      <Grid
                        container
                        item
                        direction="row"
                        spacing={2}
                        style={{ marginLeft: "25px" }}
                      >
                        {permissionLevel && permissionLevel < 5 && (
                          <>
                            <Grid item style={{ width: "55%", paddingLeft: 0 }}>
                              <Button
                                fullWidth
                                color="primary"
                                disableElevation
                                disabled={selectedIds.size === 0}
                                variant={"contained"}
                                onClick={() => { setOpenModalBordero(true) }}
                                startIcon={<BookOnlineIcon />}
                              >
                                Gerar{" "}
                                {selectedIds.size >= 1
                                  ? "Borderôs"
                                  : "Borderô"}
                              </Button>
                            </Grid>

                            <Grid item style={{ paddingLeft: 0, marginLeft: "16px" }}>
                              <Typography color={"primary"}
                                style={{
                                  fontWeight: "bold",
                                  marginTop: "8px",
                                }}
                              >
                                {selectedIds.size}{" "}
                                {selectedIds.size > 1
                                  ? "Selecionados"
                                  : "Selecionado"}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
              </Box>
            </Box>
            <TabPanel value={value} index={0}>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} md={12}>
                  <Table aria-label="a dense table">
                    <TableHead>
                      <TableRow sx={{ background: "#f5fbfd" }}>
                        {permissionLevel && permissionLevel < 5 && (
                          <TableCell>
                            <Checkbox
                              checked={generalCheckboxSelected}
                              color="secondary"
                              inputProps={{
                                "aria-label": "select all values",
                              }}
                              onChange={(event) => {
                                handleCheckboxGeneralChange(event.target.checked);
                              }}
                            />
                          </TableCell>
                        )}
                        <TableCell sx={{ color: "#7e7777b5" }}> Debito</TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}> Valor</TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}>{" "}Aprovado</TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}>{" "}Vencimento</TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}>{" "}Lançamento</TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}> Status</TableCell>
                        <TableCell sx={{ color: "#7e7777b5", width: "120px" }}>{" "}Anexos</TableCell>
                        <TableCell sx={{ color: "#7e7777b5", width: "5px" }}>{" "}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsValue?.map((row) => (
                        <TableRow key={row.id} hover>
                          {permissionLevel && permissionLevel < 5 && (
                            <TableCell>
                              <Checkbox
                                color="secondary"
                                inputProps={{
                                  "aria-label": "select all values",
                                }}
                                checked={row.checked}
                                disabled={!isValuesSelectable(row)}
                                onChange={() => handleCheckboxRowChange(row)}
                              />
                            </TableCell>
                          )}

                          <TableCell sx={{ fontWeight: "normal" }}>
                            <Typography
                              variant="subtitle2"
                              color={"#5c5858"}
                              sx={{ fontWeight: "normal" }}
                            >
                              {globalTranslate(row.typeId)}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color={"#5c5858"}
                              sx={{ fontWeight: "normal" }}
                            >
                              {row.reference}
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{ color: "#959595", fontWeight: "normal" }}
                          >
                            <Typography
                              variant="subtitle2"
                              color={"#959595"}
                              fontWeight={"normal"}
                            >
                              {parseFloat(row.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Typography>
                          </TableCell>

                          <TableCell sx={{ color: "#959595", fontWeight: "normal" }}>
                            <Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
                              {parseFloat(`${row.valueApproved}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{ color: "#959595", fontWeight: "normal" }}
                          >
                            <Typography
                              variant="subtitle2"
                              color={"#959595"}
                              fontWeight={"normal"}
                            >
                              {moment(row.dueDate).format("L")}
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{ color: "#959595", fontWeight: "normal" }}
                          >
                            <Typography
                              variant="subtitle2"
                              color={"#959595"}
                              fontWeight={"normal"}
                            >
                              {moment(row.createdAt).format("L")}
                            </Typography>
                          </TableCell>

                          <TableCell sx={{ textAlign: "start" }}>
                            <IconButton
                              size="small"
                              sx={{
                                background: colorBackGround(row.status),
                                borderRadius: "18px",
                                paddingX: "15px",
                                paddingY: "1px",
                                marginBottom: "5px",
                                color: colorText(row.status)
                              }}
                              onClick={() => {
                                setIdValue(`${row.id}`), setRowSelect(row);
                                handleOpenModalValueMessages();
                              }}
                            >
                              <Badge
                                color="secondary"
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                {row?.reasonRefused?.length != 0 ||
                                  row?.reasonApproved?.length != 0 ||
                                  row?.reasonCancel?.length != 0 ? (
                                  <MessageIcon />
                                ) : (
                                  <Icon
                                    sx={{
                                      color: colorIcon(row.status),
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {" "}
                                    noise_control_off_sharp_icon
                                  </Icon>
                                )}
                              </Badge>

                              <Typography
                                variant="subtitle2"
                                marginLeft={1}
                                sx={{
                                  color: colorText(row.status),
                                  fontWeight: "normal",
                                }}
                              >
                                {globalTranslate(row.status)}
                              </Typography>
                            </IconButton>
                          </TableCell>

                          <TableCell sx={{ textAlign: "start" }}>
                            <IconButton
                              size="small"
                              sx={{ borderRadius: "18px" }}
                              onClick={() => {
                                setIdValue(`${row.id}`),
                                setRowSelect(row),
                                  handleOpenModalValueAttachment();
                              }}
                            >
                              <Stack
                                spacing={4}
                                direction="row"
                                sx={{ color: "action.active" }}
                              >
                                <Badge
                                  color="secondary"
                                  badgeContent={
                                    row?.sinisterValuesDocuments?.length
                                  }
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                >
                                  <AttachFileIcon />
                                </Badge>
                              </Stack>
                              Anexos
                            </IconButton>
                          </TableCell>

                          <TableCell sx={{ textAlign: "start" }}>
                            <Tooltip title="" placement="top-start">
                              <div>
                                <RowMenu
                                  goToEdit={goToEdit}
                                  goTo={goTo}
                                  row={row}
                                />
                              </div>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                    {totalCountValue === 0 && !isLoading && (
                      <caption>{Environment.LISTAGEM_VAZIA}</caption>
                    )}

                    <TableFooter>
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <LinearProgress variant="indeterminate" />
                          </TableCell>
                        </TableRow>
                      )}
                      {totalCountValue > 0 && (
                        <TableRow>
                          <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                            <Pagination
                              page={pagina}
                              count={Math.ceil(totalCountValue / Environment.LIMITE_LINHAS_VALORES_BORDEROS)}
                              onChange={(_, newPage) => setSearchParams(
                                { busca, pagina: newPage.toString() },
                                { replace: true }
                              )} />
                          </TableCell>
                          <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "normal" }}>
                              Exibindo{" "}
                              {pagina * Environment.LIMITE_LINHAS_VALORES_BORDEROS -
                                (Environment.LIMITE_LINHAS_VALORES_BORDEROS - 1)}
                              {" - "}
                              {Environment.LIMITE_LINHAS_VALORES_BORDEROS * (pagina - 1) +
                                Environment.LIMITE_LINHAS_VALORES_BORDEROS * pagina <
                                totalCountValue
                                ? Environment.LIMITE_LINHAS_VALORES_BORDEROS * pagina
                                : totalCountValue}{" "}
                              de
                              <Typography variant="overline" sx={{ fontWeight: "bold", color: "#0170B3", fontSize: "16px", }}>
                                {" "}{totalCountValue}{" "}
                              </Typography>
                              registro(s)
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {selectedIds.size >= 1 && (
                        <TableRow>
                          <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                            <Grid item style={{ paddingLeft: 0, marginLeft: "16px" }}>
                              <Typography
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                  marginTop: "8px",
                                }}
                              >

                                {selectedIds.size >= 1
                                  ? " Selecionados : "
                                  : " Selecionado : "}
                                {selectedIds.size}{" "}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            <Typography
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                marginTop: "8px",
                              }}
                            >
                              Total : {
                                (rowsValue?.map((element) => (element.checked) ? Number(element.valueApproved) : 0)
                                  .reduce((acc: number, element) => acc + element)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                //toFixed(2)
                                // .reduce((total:number, valueApproved:number) => 
                                // (Number(total) + Number( (element.checked)? element.valueApproved:0) ) ) 
                              }

                            </Typography>
                          </TableCell>
                        </TableRow>)}
                    </TableFooter>
                  </Table>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} md={12}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ background: '#f5fbfd' }} >
                        <TableCell sx={{ color: "#7e7777b5" }}> #</TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}> Data Criação</TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}> Data Limite</TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}> Data Provisão</TableCell>
                        <TableCell sx={{ color: "#7e7777b5" }}> Valor</TableCell>
                        <TableCell sx={{ color: "#7e7777b5", width: "5%" }}> Status</TableCell>
                        <TableCell sx={{ color: "#7e7777b5", width: "2%" }}> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsProvision.map(row => (
                        <TableRow key={row.id} hover>
                          <TableCell >
                            <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "left" }}>
                              {row.sequencial}
                            </Typography>
                          </TableCell >

                          <TableCell >
                            <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "left" }}>
                              {row.createdAt != null ? moment(row.createdAt).format("L") : ''}
                            </Typography>
                            <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "left" }}>
                              {row.createdAt != null ? moment(row.createdAt).format("hh:mm:ss") : ''}
                            </Typography>
                          </TableCell>

                          <TableCell >
                            <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "left" }}>
                              {row.dueDate != null ? moment(row.dueDate).format("L") : ''}
                            </Typography>
                          </TableCell>

                          <TableCell >
                            <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "left" }}>
                              {row.provisionedAt != null ? moment(row.provisionedAt).format("L") : ''}
                            </Typography>
                          </TableCell>

                          <TableCell >
                            <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "left" }}>
                              {row.value != null ? parseFloat(`${row.value}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}
                            </Typography>
                          </TableCell>

                          < TableCell sx={{ textAlign: "start" }}>
                            <IconButton size="small" sx={{ background: colorBackGround(row.status), borderRadius: "18px", paddingX: "15px", paddingY: "1px", marginBottom: "5px" }}>
                              <Icon sx={{ color: colorIcon(row.status), fontWeight: "normal" }}> noise_control_off_sharp_icon</Icon>
                              <Typography variant="subtitle2" marginLeft={1} sx={{ color: colorText(row.status), fontWeight: "normal" }}>
                                {globalTranslate(row.status)}
                              </Typography>
                              <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "left" }}>
                                {row.paymentAt != null ? moment(row.paymentAt).format("L") : ''}
                              </Typography>
                            </IconButton>
                          </TableCell>

                          < TableCell sx={{ textAlign: "center" }}>
                            <IconButton size="small" onClick={() => handleModalDetailBordero(row.id)}>
                              <Icon sx={{ color: colorIcon(row.status), fontWeight: "normal" }}> remove_red_eye_Outlined_Icon </Icon>
                            </IconButton>
                          </TableCell>

                        </TableRow>
                      ))}
                    </TableBody>

                    {totalCount === 0 && !isLoading && (
                      <caption>{Environment.LISTAGEM_VAZIA}</caption>
                    )}

                    <TableFooter>
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <LinearProgress variant="indeterminate" />
                          </TableCell>
                        </TableRow>
                      )}
                      {(totalCount > 0) && (
                        <TableRow>
                          <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                            <Pagination
                              page={pagina}
                              count={Math.ceil(totalCount / Environment.LIMITE_LINHAS)}
                              onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                            />
                          </TableCell>
                          <TableCell colSpan={3} sx={{ borderBottom: "none" }} >
                            <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                              Exibindo {" "}
                              {(pagina * Environment.LIMITE_LINHAS) - (Environment.LIMITE_LINHAS - 1)}{" - "}
                              {(Environment.LIMITE_LINHAS * (pagina - 1)) +
                                (Environment.LIMITE_LINHAS * pagina) < totalCount ? Environment.LIMITE_LINHAS * pagina : totalCount} de
                              <Typography variant="overline" sx={{ fontWeight: "bold", color: "#0170B3", fontSize: "16px" }}>
                                {" "}{totalCount} {" "}
                              </Typography>
                              registro(s)
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableFooter>
                  </Table>

                </Grid>
              </Grid>
            </TabPanel>
          </Box>
        </Box>
      </Grid>

    </LayoutBaseDePagina >
  );
};
