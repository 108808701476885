
import { Box, Button, Icon, Paper, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
interface IToolSinisterProps {

    showButtonBack?: boolean;
    showButtonCancel?: boolean;
    showButtonFinish?: boolean;
    showButtonDocument?: boolean;
    showButtonValues?: boolean;
    showButtonValid?: boolean;
    showButtonConfirm?: boolean;
    showButtonResponsible?: boolean;
    showButtonFollowUp?: boolean;

    showButtonBackLoading?: boolean;
    showButtonCancelLoading?: boolean;
    showButtonFinishLoading?: boolean;
    showButtonDocumentLoading?: boolean;
    showButtonValuesLoading?: boolean;
    showSelectedValue?: "outlined" | "contained" ;
    showButtonValidLoading?: boolean;
    showButtonConfirmLoading?: boolean;
    showButtonResponsibleLoading?: boolean;
    showButtonFollowUpLoading?: boolean;

    clickButtonBack?: () => void;
    clickButtonCancel?: () => void;
    clickButtonFinish?: () => void;
    clickButtonDocument?: () => void;
    clickButtonValues?: () => void;
    clickButtonValid?: () => void;
    clickButtonConfirm?: () => void;
    clickButtonResponsible?: () => void;
    clickButtonFollowUp?: () => void;

    textButtonBack?: string;
    textButtonCancel?: string;
    textButtonFinish?: string;
    textButtonDocument?: string;
    textButtonValues?: string;
    textButtonValid?: string;
    textButtonConfirm?: string;
    textButtonResponsible?: string;
    textButtonFollowUp?: string;
}


export const ToolbarSinister: React.FC<IToolSinisterProps> = ({
    showButtonBack   = true,
    showButtonCancel = false,
    showButtonFinish = false,
    showButtonDocument = true,
    showButtonValues = true,
    showButtonValid = true,
    showButtonConfirm = true,
    showButtonResponsible = true,
    showButtonFollowUp = true,

    showButtonBackLoading = false,
    showButtonCancelLoading = false,
    showButtonFinishLoading = false,
    showButtonDocumentLoading = false,
    showButtonValuesLoading = false,
    showSelectedValue = "outlined",
    showButtonValidLoading = false,
    showButtonConfirmLoading = false,
    showButtonResponsibleLoading = false,
    showButtonFollowUpLoading = false,

    clickButtonBack,
    clickButtonCancel,
    clickButtonFinish,
    clickButtonDocument,
    clickButtonValues,
    clickButtonValid,
    clickButtonConfirm,
    clickButtonResponsible,
    clickButtonFollowUp,

    textButtonBack = 'Voltar',
    textButtonCancel = 'Cancelar Sinistro',
    textButtonFinish = 'Finalizar Sinistro',
    textButtonDocument = 'Documentos',
    textButtonValues = 'Lançar Valores',
    textButtonValid = 'Validar abertura',
    textButtonConfirm = 'Aceite',
    textButtonResponsible = 'Responsável',
    textButtonFollowUp = 'Follow Up',


}) => {
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            height={theme.spacing(5)}
            component={Paper}
        >
            {showButtonBackLoading &&(
                // <Typography>
                //     Carregando... Verificando permissões
                // </Typography>
                <>
                <Skeleton variant="rectangular" sx={{ bgcolor: 'primary',fontSize: '1rem' }} animation="pulse" width={100} height={30}/>
                <Skeleton variant="rectangular" sx={{ bgcolor: 'primary',fontSize: '1rem' }} animation="pulse" width={100} height={30}/>
                <Skeleton variant="rectangular" sx={{ bgcolor: 'primary',fontSize: '1rem' }} animation="pulse" width={100} height={30}/>
                <Skeleton variant="rectangular" sx={{ bgcolor: 'primary',fontSize: '1rem' }} animation="pulse" width={100} height={30}/>
                <Skeleton variant="rectangular" sx={{ bgcolor: 'primary',fontSize: '1rem' }} animation="pulse" width={100} height={30}/>
                <Skeleton variant="rectangular" sx={{ bgcolor: 'primary',fontSize: '1rem' }} animation="pulse" width={100} height={30}/>
                </>

                // <Skeleton  sx={{ bgcolor: 'grey.900' }} animation="wave"  width={210}
                // height={118}/>

            )}

            {(showButtonBack && !showButtonBackLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    disabled={showButtonBackLoading}
                    onClick={clickButtonBack}
                    startIcon={<Icon>keyboard_double_arrow_left_icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textButtonBack}
                    </Typography>
                </Button>
            )}

            {(showButtonDocument && !showButtonDocumentLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    disabled={showButtonDocumentLoading}
                    onClick={clickButtonDocument}
                    startIcon={<Icon>list_Outlined_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textButtonDocument}
                    </Typography>
                </Button>
            )}

            {(showButtonValues && !showButtonValuesLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant={showSelectedValue} //"outlined"
                    disabled={showSelectedValue=='contained' && showButtonValuesLoading}
                    onClick={clickButtonValues}
                    startIcon={<Icon>monetization_on_Outlined_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textButtonValues}
                    </Typography>
                </Button>
            )}

            {(showButtonResponsible && !showButtonResponsibleLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    disabled={showButtonResponsibleLoading}
                    onClick={clickButtonResponsible}
                    startIcon={<Icon>assignment_ind</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textButtonResponsible}
                    </Typography>
                </Button>
            )}

            {(showButtonValid && !showButtonValidLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    disabled={showButtonValidLoading}
                    onClick={clickButtonValid}
                    startIcon={<Icon>check</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textButtonValid}
                    </Typography>
                </Button>
            )}

            {(showButtonFinish && !showButtonFinishLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    disabled={showButtonFinishLoading}
                    onClick={clickButtonFinish}
                    startIcon={<Icon>check_circle_outline_outlined_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textButtonFinish}
                    </Typography>
                </Button>
            )}

            {(showButtonFollowUp && !showButtonFollowUpLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    disabled={showButtonFollowUpLoading}
                    onClick={clickButtonFollowUp}
                    startIcon={<Icon>format_list_numbered_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textButtonFollowUp}
                    </Typography>
                </Button>
            )}

            {(showButtonCancel && !showButtonCancelLoading) && (
                <Button
                    disableElevation
                    variant="outlined"
                    onClick={clickButtonCancel}
                    disabled={showButtonCancelLoading}
                    startIcon={<Icon>highlight_off_icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textButtonCancel}
                    </Typography>
                </Button>
            )}


            {(showButtonConfirm && !showButtonConfirmLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    onClick={clickButtonConfirm}
                    startIcon={<Icon>check</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textButtonConfirm}
                    </Typography>
                </Button>
            )}
        </Box>
        
    );
};