import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
	Box,
	Card,
	CardContent,
	Divider,
	Grid,
	IconButton,
	LinearProgress,
	Pagination,
	Paper,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModalInstallment, ToastContent } from "../../../shared/components";
import { ToolbarFinancialSummary } from "../../../shared/components/toolbarFinancial/toolbarFinancialSummary";
import { ToolbarGridFilterReport } from "../../../shared/components/toolbarGridReport/ToolbarGridFilterReport";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { Environment } from "../../../shared/environment";
import { ProfileGuard } from "../../../shared/guards/ProfileGuard";
import { useDebounce, useToast } from "../../../shared/hooks";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import {
	FinancialService,
	IInstallmentDocument,
	IInstallmentFinancialSummaryAnalytical,
	InstallmentFinancialSummaryCountData,
} from "../../../shared/services/api/financial/FinancialService";
import { SummaryFinancialProps, formatFinancialTotalValues, formatListFinancial } from "../../../shared/types/summaryFinancial";
import { globalTranslate, globalTranslateStatus } from "../../../shared/utils";
import { formatScaleValueBR } from "../../../shared/utils/formatScaleValue";
import { returnMessageError } from "../../../shared/utils/returnMessageError";

function colorText(status: string) {
	if (status === "expired") {
		return "#f0e555";
	}
	if (status === "active") {
		return "#38df61";
	}
	if (status === "canceled") {
		return "#eb3232";
	}
	if (status === "suspended") {
		return "#eb3232";
	}
	if (status === "renovated") {
		return "#3761eb";
	}
	if (status === "paid") {
		return "#1ec448";
	}
	if (status === "billed") {
		return "#3761eb";
	}

	return "#202020";
}

function colorLine(parcela: number) {
	if (parcela % 2 == 0) {
		return "#e5e8eb";
	}
	return "#ffffff";
}

export const FinancialSummaryPresentation = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingTotalValues, setIsLoadingTotalValues] = useState(false);
	const [dataIni, setDataIni] = useState("");
	const [dataFim, setDataFim] = useState("");
	const [listSummaryFinancial, setListSummaryFinancial] = useState<SummaryFinancialProps[]>([]);
	const [summaryFinancial, setSummaryFinancial] = useState<SummaryFinancialProps | null | undefined>(null);
	const [listSummaryFinancialAnalytical, setListSummaryFinancialAnalytical] = useState<IInstallmentFinancialSummaryAnalytical[]>([]);
	const [dataClientFilter, setDataClientFilter] = useState("");
	//const { isSuperAdmin } = useProfile();
	const toastHandler = useToast();
	const { debounce } = useDebounce();
	const [typeReport, setTypeReport] = useState("Sintético");
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [statusInstallment, setStatusInstallment] = useState<string>("proposal");
	const [openModalInstallment, setOpenModalInstallment] = useState(false);
	const [rowsInstallment, setRowsInstallment] = useState<IInstallmentDocument[]>([]);
	const [isChangeTypeReport, setIsChangeTypeReport] = useState(false);

	const handleOpenModalInstallment = () => {
		setOpenModalInstallment(true);
	};

	const handleCloseModalInstallment = () => {
		setOpenModalInstallment(false);
	};

	const getInstallmentsByClient = (idClient: string) => {
		setIsLoading(true);
		handleOpenModalInstallment();
		setRowsInstallment([]);

		debounce(() => {
			FinancialService.getInstallmentsByClient(idClient, dataIni, dataFim, statusInstallment)
				.then((result: IInstallmentDocument[] | Error) => {
					setIsLoading(false);
					if (result instanceof Error) {
						const titleMessage = "Alerta!!";
						const contentMessage = "Erro: " + result?.message;

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					} else {
						setRowsInstallment(result);
					}
				})
				.catch((err: any) => {
					setIsLoading(false);
					const { titleMessage, contentMessage } = returnMessageError(err);

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				});
		});
	};

	const getListInstallmentFinancialSummary = () => {
		debounce(() => {
			FinancialService.getInstallmentFinancialSummary(dataClientFilter, dataIni, dataFim)
				.then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						const titleMessage = "Alerta!!";
						const contentMessage = "Erro: " + result?.message;

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					} else setListSummaryFinancial(formatListFinancial(result));
				})
				.catch((err) => {
					setIsLoading(false);
					const { titleMessage, contentMessage } = returnMessageError(err);

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				});
		});
	};

	const clickSearchFinancialSummary = () => {
		setIsLoading(true);

		if (dataIni == null || dataIni == "null" || dataIni == undefined || dataIni == "undefined") {
			setDataIni("");
		} else if (dataFim == null || dataFim == "null" || dataFim == undefined || dataFim == "undefined") {
			setDataFim("");
		}

		if (typeReport == "Sintético") {
			setIsLoadingTotalValues(true);

			debounce(() => {
				FinancialService.getInstallmentFinancialSummary(dataClientFilter, dataIni, dataFim, true)
					.then((result) => {
						setIsLoadingTotalValues(false);
						if (result instanceof Error) {
							const titleMessage = "Alerta!!";
							const contentMessage = "Erro: " + result?.message;

							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						} else {
							setSummaryFinancial(formatFinancialTotalValues(result[0]));
							getListInstallmentFinancialSummary();
						}
					})
					.catch((err) => {
						setIsLoadingTotalValues(false);
						const { titleMessage, contentMessage } = returnMessageError(err);

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					});
			});
		} else {
			debounce(() => {
				FinancialService.getInstallmentFinancialSummaryAnalytical(
					dataClientFilter,
					dataIni,
					dataFim,
					statusInstallment,
					page?.toString(),
					Environment.LIMITE_LINHAS_GREAT?.toString()
				)
					.then((data: InstallmentFinancialSummaryCountData<IInstallmentFinancialSummaryAnalytical[]> | Error) => {
						setIsLoading(false);
						if (data instanceof Error) {
							const titleMessage = "Alerta!!";
							const contentMessage = "Erro: " + data?.message;

							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						} else {
							setListSummaryFinancialAnalytical(data.result);
							setTotalCount(data.resultCount);
							if (data.resultCount < Environment.LIMITE_LINHAS_GREAT) {
								setPage(1);
							}
						}
					})
					.catch((err) => {
						setIsLoading(false);
						const { titleMessage, contentMessage } = returnMessageError(err);

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					});
			});
		}
	};

	const onChangeTypeReport = (event: SelectChangeEvent) => {
		setTypeReport(event.target?.value);
		setIsChangeTypeReport(true);
		changeTypeReport();
	};

	const changeTypeReport = () => {
		if (typeReport == "Analítico") {
			setPage(page);
			setTotalCount(0);
			setStatusInstallment("proposal");
			setListSummaryFinancial([]);
			setSummaryFinancial(null);
		} else {
			setListSummaryFinancialAnalytical([]);
			setRowsInstallment([]);
		}
	};

	const paginationAnalytical = () => {
		setIsChangeTypeReport(false);
		if (typeReport == "Analítico") {
			setPage(page);
		}
	};

	useEffect(() => {
		if (typeReport == "Analítico" && !isChangeTypeReport) {
			clickSearchFinancialSummary();
		}
	}, [statusInstallment, page]);

	return (
		<LayoutBaseDePagina
			titulo="Resumo financeiro"
			subtitulo="Resumo financeiro"
			barraDeFerramentas={
				<ToolbarFinancialSummary
					clickBack={() => navigate("/relatorios")}
					onClick={clickSearchFinancialSummary}
					onChangeClientFilter={(value) => {
						setDataClientFilter(value);
						paginationAnalytical();
					}}
					onChangeDataIni={(value) => {
						setDataIni(value);
						paginationAnalytical();
					}}
					onChangeDataFim={(value) => {
						setDataFim(value);
						paginationAnalytical();
					}}
					loading={isLoading}
					onChangeTypeReport={onChangeTypeReport}
				/>
			}
		>
			{openModalInstallment && (
				<ModalInstallment
					isCloseModal={() => handleCloseModalInstallment()}
					isOpenModal={openModalInstallment}
					messageType={"info"}
					showButtonCancel={false}
					textButtonConfirm={"Fechar"}
					onclickConfirm={() => handleCloseModalInstallment()}
					onCloseModal={() => handleCloseModalInstallment()}
					ITitle={"Extrato do Cliente"}
					widthModfy={!rowsInstallment?.length ? "30%" : "50%"}
					IMessage={[
						<Grid container>
							<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Box} padding={2} spacing={2}>
								{!rowsInstallment?.length && !isLoading && (
									<caption
										style={{
											textAlign: "center",
											margin: 0,
											color: "black",
											fontSize: "1.2rem",
											lineHeight: 1.75,
											letterSpacing: "0.00938em",
											width: "100%",
										}}
									>
										{Environment.LISTAGEM_VAZIA}
									</caption>
								)}

								{isLoading && <LinearProgress sx={{ width: "100%" }} variant="indeterminate" />}

								{rowsInstallment?.map((row: IInstallmentDocument) => (
									<Grid container item direction="row" key={row.Installment_id} spacing={1}>
										<Grid
											item
											xs={12}
											sm={12}
											md={6}
											lg={6}
											xl={6}
											sx={{
												backgroundColor: colorLine(Number(row.Installment_number)),
												paddingRight: "5px",
												paddingLeft: "10px !important",
											}}
										>
											<Typography variant="caption" color={"text.secondary"}>
												Locatário {row.Installment_type_amount ? globalTranslate(row.Installment_type_amount) : ""}{" "}
												{row.Installment_type_amount === "parcel" ? row.Installment_number : ""}
											</Typography>
											<Typography variant="subtitle2">{row.name}</Typography>
											<Typography variant="caption" color={"text.secondary"}>
												{row.document?.length > 11
													? `${row.document?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")?.substring(0, 3)}`
													: `${row.document?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")}`}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{ backgroundColor: colorLine(Number(row.Installment_number)) }}>
											<Typography variant="caption" color={"text.secondary"}>
												Vencimento
											</Typography>
											<Typography variant="subtitle2">{moment(row.Installment_due_date).format("L")}</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{ backgroundColor: colorLine(Number(row.Installment_number)) }}>
											<Typography variant="caption" color={"text.secondary"}>
												Valor
											</Typography>
											{row.Installment_type_amount != "discount" ? (
												<Typography variant="subtitle2">{formatScaleValueBR(row.Installment_amount)}</Typography>
											) : (
												<Typography variant="subtitle2" sx={{ color: "red" }}>
													- {formatScaleValueBR(row.Installment_amount)}
												</Typography>
											)}
										</Grid>
										<Grid
											item
											xs={12}
											sm={12}
											md={2}
											lg={2}
											xl={2}
											sx={{ backgroundColor: colorLine(Number(row.Installment_number)), paddingLeft: "0px !important" }}
										>
											<Typography variant="caption" color={"text.secondary"}>
												Status
											</Typography>
											<Typography variant="subtitle2" color={colorText(row.Installment_status)}>
												{globalTranslateStatus(row.Installment_status)}
											</Typography>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>,
					]}
				/>
			)}

			<Grid container display="flex" flexDirection="row">
				{typeReport == "Sintético" && summaryFinancial && (
					<ProfileGuard requiredRole={PermissionRolesEnum.WarrantiesOptions} requiredAction={PermissionActionsEnum.View}>
						<Grid
							container
							item
							sm={12}
							sx={{ borderRadius: "6px", paddingTop: "0px !important", paddingBottom: "0px !important" }}
							display={"flex"}
							flexDirection={smDown ? "column" : "row"}
							component={Paper}
							padding={2}
						>
							<Grid container item sm={12} md={3}>
								<Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRight: "1px solid #c1c1c1", borderRadius: "0" }}>
									<CardContent sx={{ textAlign: "center", paddingTop: "16px" }}>
										<Typography variant="h4" fontWeight={"300"}>
											{summaryFinancial?.prop1?.value}
										</Typography>
										<Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
											{summaryFinancial?.prop1?.name}
										</Typography>
									</CardContent>
								</Card>
							</Grid>

							<Grid container item sm={12} md={3}>
								<Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRight: "1px solid #c1c1c1", borderRadius: "0" }}>
									<CardContent sx={{ textAlign: "center", paddingTop: "16px" }}>
										<Typography variant="h4" fontWeight={"300"} sx={{ color: "#7924c7" }}>
											{summaryFinancial?.prop2?.value}
										</Typography>
										<Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
											{summaryFinancial?.prop2?.name}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid container item sm={12} md={3}>
								<Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRight: "1px solid #c1c1c1", borderRadius: "0" }}>
									<CardContent sx={{ textAlign: "center", paddingTop: "16px" }}>
										<Typography variant="h4" fontWeight={"300"} color={"#FFD700"}>
											{summaryFinancial?.prop3?.value}
										</Typography>
										<Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
											{summaryFinancial?.prop3?.name}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid container item sm={12} md={3}>
								<Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRadius: "0" }}>
									<CardContent sx={{ textAlign: "center", paddingTop: "16px" }}>
										<Typography variant="h4" fontWeight={"300"}>
											{summaryFinancial?.prop4?.value}
										</Typography>
										<Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
											{summaryFinancial?.prop4?.name}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						</Grid>

						<Grid
							container
							item
							sm={12}
							sx={{ borderRadius: "6px", paddingTop: "0px !important", paddingBottom: "0px !important" }}
							display={"flex"}
							flexDirection={smDown ? "column" : "row"}
							component={Paper}
							padding={2}
							justifyContent={"center"}
							columnGap={3}
						>
							<Grid container item sm={12} md={3}>
								<Card
									sx={{
										padding: "2px",
										boxShadow: "none",
										width: "100%",
										borderRight: "1px solid #c1c1c1",
										borderRadius: "0",
										borderLeft: "1px solid #c1c1c1",
									}}
								>
									<CardContent sx={{ textAlign: "center", paddingTop: "16px" }}>
										<Typography variant="h4" fontWeight={"300"} color={"#00FFFF"}>
											{summaryFinancial?.prop5?.value}
										</Typography>
										<Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
											{summaryFinancial?.prop5?.name}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid container item sm={12} md={3}>
								<Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRight: "1px solid #c1c1c1", borderRadius: "0" }}>
									<CardContent sx={{ textAlign: "center", paddingTop: "16px" }}>
										<Typography variant="h4" fontWeight={"300"} color={"#7924c7"}>
											{summaryFinancial?.prop6?.value}
										</Typography>
										<Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
											{summaryFinancial?.prop6?.name}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid container item sm={12} md={3}>
								<Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRight: "1px solid #c1c1c1", borderRadius: "0" }}>
									<CardContent sx={{ textAlign: "center", paddingTop: "16px" }}>
										<Typography variant="h4" fontWeight={"300"} color={"#00FF7F"}>
											{summaryFinancial?.prop7?.value}
										</Typography>
										<Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
											{summaryFinancial?.prop7?.name}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</ProfileGuard>
				)}

				{!listSummaryFinancial?.length && typeReport == "Sintético" && (
					<Box
						sx={{
							width: "100%",
							bgcolor: "background.paper",
							border: "1px solid #eaecee",
							padding: 1,
							textAlign: "center",
							marginTop: "-5px",
							marginRight: "8px",
							borderRadius: "6px",
							boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
						}}
					>
						<Typography variant="subtitle1">{Environment.LISTAGEM_VAZIA}</Typography>
					</Box>
				)}

				{typeReport == "Sintético" &&
					listSummaryFinancial?.map((obj: SummaryFinancialProps) => (
						<Grid
							item
							xs={12}
							md={3.8}
							marginTop={"10px"}
							sx={{ border: "none", justifyContent: "center" }}
							border={"4px"}
							wrap="nowrap"
							display={"flex"}
							flexDirection={smDown ? "column" : "row"}
							padding={2}
							marginLeft="10px"
							marginBottom="15px"
						>
							<Box
								sx={{
									width: "100%",
									bgcolor: "background.paper",
									border: "1px solid #eaecee",
									borderRadius: "10px",
									padding: 2,
									paddingLeft: "20px",
									boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
								}}
							>
								<Typography variant="h5" sx={{ color: "#7924c7", marginBottom: "1.2em", marginTop: "0.7em" }}>
									{obj?.prop0?.value}
								</Typography>

								<Divider />
								<nav aria-label="secondary mailbox folders" style={{ marginTop: "2em", marginBottom: "10px" }}>
									<Grid container width="100%">
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Tooltip title="Exibe o total de parcelas das garantias do período informado" placement="top">
												<Typography variant="subtitle2">{obj.prop1?.name}</Typography>
											</Tooltip>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px", textAlign: "center" }}>
											<Typography variant="subtitle2">{obj.prop1?.qtd}</Typography>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Typography variant="subtitle2">{obj.prop1?.value}</Typography>
										</Grid>

										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Tooltip title="Exibe o total de parcelas canceladas do período informado" placement="top">
												<Typography variant="subtitle2">{obj.prop2?.name}</Typography>
											</Tooltip>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px", textAlign: "center" }}>
											<Typography variant="subtitle2">{obj.prop2?.qtd}</Typography>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Typography variant="subtitle2" color="#7924c7">
												{obj.prop2?.value}
											</Typography>
										</Grid>

										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Tooltip title="Exibe o total de parcelas não faturados do período informado" placement="top">
												<Typography variant="subtitle2">{obj.prop3?.name}</Typography>
											</Tooltip>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px", textAlign: "center" }}>
											<Typography variant="subtitle2">{obj.prop3?.qtd}</Typography>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Typography variant="subtitle2" color="#FFD700">
												{obj.prop3?.value}
											</Typography>
										</Grid>

										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Tooltip title="Exibe o total de parcelas faturados no período informado" placement="top">
												<Typography variant="subtitle2">{obj.prop4?.name}</Typography>
											</Tooltip>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px", textAlign: "center" }}>
											<Typography variant="subtitle2">{obj.prop4?.qtd}</Typography>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Typography variant="subtitle2">{obj.prop4?.value}</Typography>
										</Grid>

										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Tooltip
												title="Exibe o total de parcelas em aberto (a pagar que ainda não venceram) do período informado"
												placement="top"
											>
												<Typography variant="subtitle2">{obj.prop5?.name}</Typography>
											</Tooltip>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px", textAlign: "center" }}>
											<Typography variant="subtitle2">{obj.prop5?.qtd}</Typography>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Typography variant="subtitle2" color="#00FFFF">
												{obj.prop5?.value}
											</Typography>
										</Grid>

										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Tooltip title="Exibe o total de parcelas faturadas que estão vencidas do período informado" placement="top">
												<Typography variant="subtitle2">{obj.prop6?.name}</Typography>
											</Tooltip>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px", textAlign: "center" }}>
											<Typography variant="subtitle2">{obj.prop6?.qtd}</Typography>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Typography variant="subtitle2" color="#7924c7">
												{obj.prop6?.value}
											</Typography>
										</Grid>

										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Tooltip title="Exibe o total de parcelas que estão como pagas no período informado" placement="top">
												<Typography variant="subtitle2">{obj.prop7?.name}</Typography>
											</Tooltip>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px", textAlign: "center" }}>
											<Typography variant="subtitle2">{obj.prop7?.qtd}</Typography>
										</Grid>
										<Grid item xs={12} sm={4} sx={{ padding: "8px 0px" }}>
											<Typography variant="subtitle2" color="#00FF7F">
												{obj.prop7?.value}
											</Typography>
										</Grid>
									</Grid>
								</nav>
							</Box>
						</Grid>
					))}

				{typeReport == "Analítico" && (
					<ProfileGuard requiredRole={PermissionRolesEnum.FinancialOptions} requiredAction={PermissionActionsEnum.View}>
						<TableContainer component={Paper} variant="outlined" sx={{ marginTop: 1, width: "96%", padding: "20px" }}>
							<ToolbarGridFilterReport
								onClickProposal={() => {
									setStatusInstallment("proposal");
									paginationAnalytical();
								}}
								onClickCanceled={() => {
									setStatusInstallment("canceled");
									paginationAnalytical();
								}}
								onClickBilled={() => {
									setStatusInstallment("billed");
									paginationAnalytical();
								}}
								onClickOpen={() => {
									setStatusInstallment("open");
									paginationAnalytical();
								}}
								onClickOverdue={() => {
									setStatusInstallment("overdue");
									paginationAnalytical();
								}}
								onClickPaid={() => {
									setStatusInstallment("paid");
									paginationAnalytical();
								}}
								onClickUnbilled={() => {
									setStatusInstallment("unbilled");
									paginationAnalytical();
								}}
								selectProposal={statusInstallment === "proposal"}
								selectCanceled={statusInstallment === "canceled"}
								selectBilled={statusInstallment === "billed"}
								selectOpen={statusInstallment === "open"}
								selectOverdue={statusInstallment === "overdue"}
								selectPaid={statusInstallment === "paid"}
								selectUnbilled={statusInstallment === "unbilled"}
							/>
							<Table aria-label="a dense table" sx={{ marginTop: "18px" }}>
								<TableHead>
									<TableRow sx={{ background: "#f5fbfd" }}>
										<TableCell colSpan={4} sx={{ color: "#7e7777b5", textAlign: "center" }}>
											{" "}
											Nome da imobiliária
										</TableCell>
										<TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Qtd de parcelas</TableCell>
										<TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Valor total</TableCell>
										<TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Visualizar </TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{!isLoading &&
										listSummaryFinancialAnalytical?.map((row, index) => (
											<TableRow key={index} hover>
												<TableCell colSpan={4} sx={{ textAlign: "center" }}>
													<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
														{row?.clientName}
													</Typography>
												</TableCell>

												<TableCell sx={{ textAlign: "center", color: "#959595", fontWeight: "normal" }}>
													<Typography variant="caption" fontWeight={"normal"}>
														{row?.qtdInstallment}
													</Typography>
												</TableCell>
												<TableCell sx={{ textAlign: "center" }}>
													<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
														{formatScaleValueBR(`${row?.totalValue}`)}
													</Typography>
												</TableCell>
												<TableCell sx={{ textAlign: "center" }}>
													<Tooltip title="Clique aqui para visualizar as parcelas" placement="top-start">
														<IconButton
															size="small"
															onClick={() => getInstallmentsByClient(row?.idClient)}
															sx={{ borderRadius: "18px", paddingLeft: "0px !important" }}
														>
															<RemoveRedEyeOutlinedIcon sx={{ color: "#0170B3", fontWeight: "normal" }} />
														</IconButton>
													</Tooltip>
												</TableCell>
											</TableRow>
										))}
								</TableBody>
								{totalCount === 0 && !isLoading && (
									<caption
										style={{
											textAlign: "center",
											margin: 0,
											color: "black",
											fontSize: "1rem",
											lineHeight: 1.75,
											letterSpacing: "0.00938em",
										}}
									>
										{Environment.LISTAGEM_VAZIA}
									</caption>
								)}
								<TableFooter>
									{isLoading && (
										<TableRow>
											<TableCell colSpan={8}>
												<LinearProgress variant="indeterminate" />
											</TableCell>
										</TableRow>
									)}
									{totalCount > 0 && (
										<TableRow>
											<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
												<Pagination
													page={page}
													count={Math.ceil(totalCount / Environment.LIMITE_LINHAS_GREAT)}
													onChange={(_, newPage) => setPage(newPage)}
												/>
											</TableCell>
											<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
												<Typography variant="body1" sx={{ fontWeight: "normal" }}>
													Exibindo {page * Environment.LIMITE_LINHAS_GREAT - (Environment.LIMITE_LINHAS_GREAT - 1)}
													{" - "}
													{Environment.LIMITE_LINHAS_GREAT * (page - 1) + Environment.LIMITE_LINHAS_GREAT * page < totalCount
														? Environment.LIMITE_LINHAS_GREAT * page
														: totalCount}{" "}
													de
													<Typography variant="overline" sx={{ fontWeight: "bold", color: "#0170B3", fontSize: "16px" }}>
														{" "}
														{totalCount}{" "}
													</Typography>
													registro(s)
												</Typography>
											</TableCell>
										</TableRow>
									)}
								</TableFooter>
							</Table>
						</TableContainer>
					</ProfileGuard>
				)}
			</Grid>
		</LayoutBaseDePagina>
	);
};
