import React from 'react';
import {
  StyledFileBox,
  StyledFileIconBox,
  StyledFileMetadataBox,
  StyledFileOperationsBox, StyledOperationIconBox,
  StyledSinisterValuesFileItemContainer, StyledTypography
} from './SinisterValuesFileItem.styled';

import {globalTranslate} from  '../../../../shared/utils/global-translate';
import {getFileExtension, getFileExtensionIcon} from '../../helpers';
import {Icon, Typography} from '@mui/material';
// import {formatAnalysisContractTypeEnum} from '../../../../../../enums/analysis-documents';
import axios from 'axios';
import {formatDate} from '../../../../shared/utils/format-date';
import { SinisterValuesDocumentType } from '../../../../shared/services/api/sinister/types/sinister-values-document.type';

type SinisterValuesFileItemProps = {
  item: SinisterValuesDocumentType
}
export const SinisterValuesFileItem = ({ item }: SinisterValuesFileItemProps) => {
  return (
    <>
      <StyledSinisterValuesFileItemContainer>
        <StyledFileBox>
          <StyledFileIconBox style={{
            height: '4rem',
            width: '4rem'
          }}>
            {getFileExtensionIcon(item.mimetype)}
          </StyledFileIconBox>
        </StyledFileBox>

      <StyledFileMetadataBox>
          <StyledTypography style={{fontWeight: 'bold', fontSize: '0.9rem'}}>{globalTranslate( item.originalFilename)}</StyledTypography>
          {/* <StyledTypography style={{fontSize: '0.8rem'}}>{formatAnalysisContractTypeEnum(item.docType)}</StyledTypography> */}
          <StyledTypography style={{fontSize: '0.8rem'}}>{globalTranslate(item.docType)}</StyledTypography>
          <StyledTypography style={{fontSize: '0.8rem', color: 'grey', opacity: 0.9}}>{ formatDate( new Date(item.uploadedDate)) }</StyledTypography>
        </StyledFileMetadataBox>

        <StyledFileOperationsBox>
        <a href={item.url} style={{
          textDecoration: 'none',
          color: 'inherit'
        }}>
          <StyledOperationIconBox>
            <Icon style={{
              fontSize: "2.5rem"
            }}>download</Icon>
          </StyledOperationIconBox>
        </a>
        </StyledFileOperationsBox>
      </StyledSinisterValuesFileItemContainer>
    </>
  )
}