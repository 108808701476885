import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

export default function PaymentSuccess() {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '50vh' }}
    >
      <CheckCircleOutlineRoundedIcon color="success" fontSize="large" />
      <Typography variant="h5">Pagamento Realizado com Sucesso!</Typography>
    </Stack>
  );
}
