
import React, { useState } from "react";
import Modal from '@mui/material/Modal';
import {
    Box, Icon, Typography, DialogContent, DialogTitle, DialogActions, Grid, Checkbox
} from "@mui/material";
import Button from '@mui/material/Button';


const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: 'auto',
    maxWidth: '75%',
    maxHeight: '85%',
    bgcolor: 'background.paper',
    border: '1px solid #605e5e',
    boxShadow: 24,
    overflow: 'auto',
    p: 2,
    borderRadius: '6px',
    display: "flex",
    flexDirection: 'column',
    backgroundColor: '#FFF'

};


export type dataComparation = {
    Field?: string;
    ValueBack?: string;
    ValueNew?: string;
}

interface IContextProps {
    ITitle?: string;
    textButtonConfirm?: string;
    showButtonCancel?: boolean;
    showButtonConfirm?: boolean;
    textButtonCancel?: string;
    isOpenModal: boolean;
    messageType?: string;
    IMessage?: React.ReactNode[];
    TitleBack: string;
    TitleNew: string;
    dataComparation: dataComparation[];

    onclickConfirm?: () => void;
    isCloseModal?: () => void;
    contextMessage?: string;
    zwidth?: string;
    showTitleIcon?: boolean;
    enableBackdropClose?: boolean;
    overrideWidthValue?: string;
    overrideHeightValue?: string;
}

function colorLine(parcela: number) {
    if (parcela % 2 == 0) {
        return "#f3ebff";
    }
    return "#ffffff";
}

export const ModalComparation: React.FC<IContextProps> = ({ ITitle, textButtonConfirm, showButtonConfirm, TitleBack, TitleNew, dataComparation,
    showButtonCancel, isOpenModal, isCloseModal, onclickConfirm, textButtonCancel, enableBackdropClose, overrideWidthValue, overrideHeightValue }) => {

    const handleClose = (event: object, reason: string) => {
        if (reason === 'backdropClick' && isCloseModal) {
            isCloseModal()
        }
    }

    const [checkTerm, setCheckTerm] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckTerm(event.target.checked);
        console.log(checkTerm)
    };

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


    return (
        <div>

            <Modal
                open={isOpenModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={enableBackdropClose ? handleClose : () => { isOpenModal }}
            >
                <Box sx={{ ...style, width: overrideWidthValue ? overrideWidthValue : style.width, height: overrideHeightValue ? overrideHeightValue : style.height }} >
                    <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography
                            id="modal-modal-title"
                            variant="h5"
                            color={"primary"}
                        >
                            {ITitle}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>

                        <Box margin={1} display="flex" flexDirection="column" width={"95%"}  >
                            <Grid container item direction="row" spacing={2} className="oioi" mt={3}>
                                <Grid md={4} />
                                <Grid md={4}>
                                    <Typography variant="h6" color={'gray'} paddingX={2}>
                                        {TitleBack}
                                    </Typography>
                                </Grid>
                                <Grid md={4}>
                                    <Typography variant="h6" color={'primary'} paddingX={2}>
                                        {TitleNew}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Box mt={2}>
                                {dataComparation.map((row, index) => (
                                    <Grid container item direction="row" className="linhaminha" gap={1} width={"99%"} display={"flex"} flexWrap={"nowrap"} >
                                        <Grid md={4} sx={{ backgroundColor: colorLine(index) }} p={1}  >
                                            <Typography variant="subtitle1" color={'gray'}>
                                                {row.Field}
                                            </Typography>
                                        </Grid>
                                        <Grid md={4} sx={{ backgroundColor: colorLine(index) }} p={1} >
                                            <Typography variant="subtitle1" color={'gray'}>
                                                {row.ValueBack}
                                            </Typography>
                                        </Grid>
                                        <Grid md={4} sx={{ backgroundColor: colorLine(index) }} p={1} display={"flex"} justifyContent={"space-between"} >
                                            {row.ValueBack != row.ValueNew ?
                                                <>
                                                    <Typography variant="subtitle1" color={'primary'} fontWeight="800" >
                                                        {row.ValueNew}
                                                    </Typography>
                                                    <Typography>
                                                        <Icon sx={{ color: "#7924c7", fontWeight: "800" }} >refresh_outlinedIcon</Icon>
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <Typography variant="subtitle1" color={'gray'}>
                                                        {row.ValueNew}
                                                    </Typography>
                                                    <Typography>
                                                        <Icon sx={{ color: "gray", fontWeight: "800" }} >check_outlinedIcon</Icon>
                                                    </Typography>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>

                                ))}
                            </Box>
                            <Box display={"flex"} justifyContent={"center"} mt={2} alignItems={"center"}>
                                <Typography>
                                    <Checkbox
                                        checked={checkTerm}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    />
                                </Typography>
                                <Typography variant='caption'  >
                                    Confirmo que estou ciente que os dados serão modificados de acordo com o demonstrativo acima
                                </Typography>
                            </Box>
                        </Box>
                    </DialogContent>

                    <DialogActions style={{ display: 'flex', justifyContent: 'center' }}          >
                        {showButtonCancel && (
                            <Button
                                onClick={isCloseModal}
                                color="primary"
                                disableElevation
                                variant={"outlined"}
                                startIcon={< Icon > closeIcon</Icon >}
                            >
                                {textButtonCancel}
                            </Button>
                        )}
                        {showButtonConfirm && (
                            <Button
                                color="primary"
                                disableElevation
                                variant={"contained"}
                                disabled={!checkTerm}
                                startIcon={< Icon > checkIcon</Icon >}
                                onClick={onclickConfirm}
                            >
                                {textButtonConfirm}
                            </Button>
                        )}

                    </DialogActions>
                </Box>
            </Modal >
        </div >
    );
}