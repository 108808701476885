import * as React from 'react';
import { Box, Button, FormControl, Grid, Icon, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { Environment } from "../../environment";
import { useState } from "react";
import moment from 'moment';

import 'moment/locale/pt-br';

interface IToolBarGraphProps {
    textSearch?: string;
    showInputBusca?: boolean;
    onChangeTextBusca?: (newText: string) => void;
    onChangeDataIni?: (newText: string) => void;
    onChangeDataFim?: (newText: string) => void;

    textoButtonNew?: string;
    showButtonNew?: boolean;
    onClickNovo?: () => void;
}

let dataini = moment().startOf('month').format( "YYYY-MM-DD");
let datafim = moment().endOf('month').format( "YYYY-MM-DD");

// console.log('data',dataini, datafim);

export const ToolBarGraph: React.FC<IToolBarGraphProps> = ({
    textSearch = '',
    showInputBusca = false,
    onChangeTextBusca,

    onChangeDataIni,
    onChangeDataFim,

    textoButtonNew = 'Filtrar',
    showButtonNew = true,
    onClickNovo,

}) => {
    const theme = useTheme();

    const [status, setStatus] = useState('Todos');
    
    const [datai, setDatai] = useState(dataini);
    const [dataf, setDataf] = useState(datafim);

    const handleChangeStatus = (event: SelectChangeEvent) => {
        setStatus(event.target.value as string);
    };

    const [origem, setOrigem] = useState('Todos');

    const handleChangeOrigem = (event: SelectChangeEvent) => {
        setOrigem(event.target.value as string);
    };




    return (

        <Box
            gap={1}
            marginX={1}
            padding={2}
            paddingX={3}
            display="flex"
            alignItems="center"
            // height={theme.spacing(5)}
            component={Paper}
        >
            {showInputBusca && (
                <TextField
                    size="small"
                    value={textSearch}
                    placeholder={Environment.INPUT_BUSCA}
                    onChange={(e) => onChangeTextBusca?.(e.target.value)}
                />
            )}


            <Grid container item direction="row" spacing={2}>
                {/* <Grid item xs={12} sm={12} md={2}>
                    <FormControl fullWidth>
                        <InputLabel id="id_status">Status</InputLabel>
                        <Select
                            labelId="label_Status"
                            id="id_status"
                            value={status}
                            size="small"
                            label="Status"
                            onChange={handleChangeStatus}
                        >
                            <MenuItem value={'Todos'}>Todos </MenuItem>
                            <MenuItem value={'Ativo'}>Ativo</MenuItem>
                            <MenuItem value={'Inativo'}>Inativo</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                    <FormControl fullWidth>
                        <InputLabel id="id_origem">Origem</InputLabel>
                        <Select
                            labelId="label_Origem"
                            id="id_origem"
                            value={origem}
                            size="small"
                            label="Origem"
                            onChange={handleChangeOrigem}
                        >
                            <MenuItem value={'Todos'}>Todos</MenuItem>
                            <MenuItem value={'Web'}>Web</MenuItem>
                            <MenuItem value={'Integracao'}>Integração</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> */}


                <Grid item xs={12} sm={12} md={2}>
                    <TextField
                        fullWidth
                        label='Data Inicial'
                        name="datainicial"
                        size="small"
                        type="date"
                        value={ datai }
                        onChange={(e) => {
                            setDatai(e.target.value)
                            onChangeDataIni?.(e.target.value)
                            }
                        }
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                    <TextField
                        fullWidth
                        label='Data Final'
                        name="datafinal"
                        size="small"
                        type="date"
                        value={dataf}
                        onChange={(e) => {
                                setDataf(e.target.value)
                                onChangeDataFim?.(e.target.value)
                        }
                        }
                    >
                    </TextField>
                </Grid>



                <Grid item xs={12} md={2} lg={4} xl={4}>
                    <Box display="flex" justifyContent="end">
                        {showButtonNew && (
                            <Button
                                color="primary"
                                disableElevation
                                variant="contained"
                                onClick={onClickNovo}
                                startIcon={<Icon>filter_list</Icon>}
                            >
                                {textoButtonNew}
                            </Button>

                        )}
                    </Box>
                </Grid>
            </Grid>












        </Box>

    );
}; 