import { AccountCircleOutlined, Clear, Search } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Icon, IconButton, InputAdornment, Paper, TextField, useTheme } from "@mui/material";

import { Environment } from "../../environment";

interface IToolbarGridClientesProps {
    textSearchGrid?: string;
    showInputBuscaGrid?: boolean;
    onChangeTextBuscaGrid?: (newText: string) => void;

    textButtonCorretor?: string;
    showBotaoCorretor?: boolean;
    onClickCorretor?: () => void;
    selectCorretor?: boolean;

    textButtonImobiliaria?: string;
    showBotaoImobiliaria?: boolean;
    onClickImobiliaria?: () => void;
    selectImobiliaria?: boolean;

    textButtonGestorContas?: string;
    showBotaoGestorContas?: boolean;
    onClickGestorContas?: () => void;
    selectGestorContas?: boolean;

    textButtonTodos?: string;
    showBotaoTodos?: boolean;
    onClickTodos?: () => void;
    selectTodos?: boolean;

}

export const ToolbarGridClientes: React.FC<IToolbarGridClientesProps> = ({
    textSearchGrid: textSearch = '',
    showInputBuscaGrid: showInputBusca = true,
    onChangeTextBuscaGrid: onChangeTextBusca,

    textButtonCorretor = 'Corretor de imóveis',
    showBotaoCorretor = true,
    onClickCorretor,
    selectCorretor = false,

    textButtonImobiliaria = 'Imobiliária',
    showBotaoImobiliaria = true,
    onClickImobiliaria,
    selectImobiliaria = false,

    textButtonGestorContas = 'Gestor de Contas',
    showBotaoGestorContas = true,
    onClickGestorContas,
    selectGestorContas = false,

    textButtonTodos = 'Todos',
    showBotaoTodos = true,
    onClickTodos,
    selectTodos = true,

}) => {
    const theme = useTheme();

    const handleTodos = () => {
        selectTodos
    };


    return (
        <Box
            display="flex"
            alignItems="start"
            height={theme.spacing(6)}
        >

            <ButtonGroup aria-label="outlined primary button group">
                {showBotaoTodos && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectTodos ? "contained" : "outlined"}
                        onClick={onClickTodos}
                        startIcon={<Icon>done_all_icon</Icon>}
                    >
                        {textButtonTodos}

                    </Button>
                )}

                {showBotaoCorretor && (
                    <Button
                        color="primary"
                        disableElevation
                        onClick={onClickCorretor}
                        variant={selectCorretor ? "contained" : "outlined"}
                        startIcon={<Icon>portrait_outlined_icon</Icon>}
                    >
                        {textButtonCorretor}
                    </Button>
                )}

                {showBotaoImobiliaria && (
                    <Button
                        color="primary"
                        disableElevation
                        onClick={onClickImobiliaria}
                        variant={selectImobiliaria ? "contained" : "outlined"}
                        startIcon={<Icon>corporate_fare_outlined_icon</Icon>}
                    >
                        {textButtonImobiliaria}
                    </Button>
                )}

                {showBotaoGestorContas && (
                    <Button
                        color="primary"
                        disableElevation
                        onClick={onClickGestorContas}
                        variant={selectGestorContas ? "contained" : "outlined"}
                        startIcon={<Icon>badge_outlined_icon</Icon>}
                    >
                        {textButtonGestorContas}
                    </Button>
                )}

            </ButtonGroup>

            <Box flex={1} display="flex" justifyContent="end">
                {showInputBusca && (
                    <TextField
                        size="small"
                        value={textSearch}
                        placeholder={Environment.INPUT_BUSCA}
                        onChange={(e) => onChangeTextBusca?.(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="start"
                                    >
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        sx={{ color: "#dbd5d5" }}
                                        onClick={() => onChangeTextBusca?.('')}
                                    >
                                        <Clear />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            </Box>
        </Box >
    );
}; 