import { Avatar, Box, Card, CardContent, CardHeader, Chip, Divider, IconButton, Modal, Typography } from "@mui/material";
import moment from "moment";
import { ISetup } from "../../../shared/services/api/financial/FinancialService";
import { globalColorTextSetup, globalMaskDocument, globalTranslatePayment, globalTranslateStatusTaxSetup } from "../../../shared/utils";

interface IModalDetailSetupProps {
	rowSetup: ISetup;
	isOpenModal: boolean;
	isCloseModal?: () => void;
	widthModfy?: string;
	heightModfy?: string;
	onclickConfirm?: () => void;
	onCloseModal?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
	overrideWidthValue?: string;
	overrideHeightValue?: string;
}

const style = {
	position: "relative",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "50%",
	height: "auto",
	maxWidth: "75%",
	maxHeight: "85%",
	bgcolor: "background.paper",
	border: "1px solid #605e5e",
	boxShadow: 24,
	borderRadius: "6px",
	display: "flex",
	flexDirection: "column",
	backgroundColor: "#FFF",
	padding: 3,
};

export const ModalDetailSetup: React.FC<IModalDetailSetupProps> = ({
	rowSetup,
	isOpenModal: isOpenModal,
	onCloseModal,
	overrideWidthValue,
	overrideHeightValue,
}) => {
	return (
		<Modal open={isOpenModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={onCloseModal}>
			<Box
				sx={{
					...style,
					width: overrideWidthValue ? overrideWidthValue : style.width,
					height: overrideHeightValue ? overrideHeightValue : style.height,
				}}
			>
				<Box display={"flex"} justifyContent={"center"}>
					<Typography variant="h5" paddingY={2} textAlign={"center"} color={"primary"}>
						Detalhamento de taxa de setup
					</Typography>
				</Box>
				<Card variant="outlined" sx={{ width: "100%" }}>
					<Typography variant="h6" component="div" paddingX={2}>
						Imobiliária
					</Typography>

					<Box paddingX={3} paddingTop={1}>
						<Typography variant="body2" fontWeight={"500"}>
							{rowSetup.client_name}
						</Typography>
						<Typography variant="body2">{globalMaskDocument(rowSetup.client_document ?? "0")}</Typography>
					</Box>

					<CardContent>
						<Divider />
						<Typography variant="h6" component="div">
							Cliente
						</Typography>
						<CardHeader
							avatar={
								<Avatar sx={{ bgcolor: "primary" }} aria-label="recipe">
									{rowSetup.tenant_name.trim()?.charAt(0)}
								</Avatar>
							}
							action={<IconButton aria-label="settings">{/* <MoreVertIcon /> */}</IconButton>}
							title={rowSetup.tenant_name}
							subheader={globalMaskDocument(rowSetup.tenant_document ?? "")}
						/>
						<Divider />
						<Typography variant="h6" component="div" marginBottom={2}>
							Cobrança
						</Typography>
						<Box display={"flex"} flexDirection={"column"} gap={3} marginBottom={1}>
							<Box display={"flex"} gap={5} justifyContent={"flex-start"}>
								<Box>
									<Typography variant="body2" fontWeight={"500"}>
										Dada de criação
									</Typography>
									<Typography variant="body2">{moment(rowSetup.canceled_at ?? "").format("DD/MM/YYYY")}</Typography>
								</Box>
							</Box>
							{rowSetup.status === "canceled" && (
								<Box display={"flex"} gap={5} justifyContent={"flex-start"}>
									<Box>
										<Typography variant="body2" fontWeight={"500"} color={"red"}>
											Data Cancelamento
										</Typography>
										<Typography variant="body2">{moment(rowSetup.canceled_at ?? "").format("DD/MM/YYYY")}</Typography>
									</Box>
									<Box>
										<Typography variant="body2" fontWeight={"500"} color={"red"}>
											Motivo Cancelamento
										</Typography>
										<Typography variant="body2">{rowSetup.reason}</Typography>
									</Box>
									<Box>
										<Typography variant="body2" fontWeight={"500"} color={"red"}>
											Descrição
										</Typography>
										<Typography variant="body2">{rowSetup.note}</Typography>
									</Box>
								</Box>
							)}
							{rowSetup.status === "paid" && (
								<Box display={"flex"} gap={5} justifyContent={"flex-start"}>
									<Box>
										<Typography variant="body2" fontWeight={"500"}>
											Data Pagamento
										</Typography>
										<Typography variant="body2">{moment(rowSetup.payment_date ?? "").format("DD/MM/YYYY")}</Typography>
									</Box>
									<Box>
										<Typography variant="body2" fontWeight={"500"}>
											Valor Pago
										</Typography>
										<Typography variant="body2">
											{parseFloat(rowSetup.credit_amount ?? "0").toLocaleString("pt-br", {
												style: "currency",
												currency: "BRL",
											})}
										</Typography>
									</Box>
									<Box>
										<Typography variant="body2" fontWeight={"500"}>
											Taxa Administrativa
										</Typography>
										<Typography variant="body2">{parseFloat(rowSetup.administrative_amount?? "0").toLocaleString("pt-br", {
												style: "currency",
												currency: "BRL",
											})}</Typography>
									</Box>
								</Box>
							)}
						</Box>
						<Divider />
						<Typography variant="h6" component="div" marginBottom={2}>
							Pagamento
						</Typography>
						<Box gap={5} justifyContent={"flex-start"}>
							<Typography variant="body2" fontWeight={"500"} paddingLeft={2}>
								Status
							</Typography>
							<Chip
								label={globalTranslateStatusTaxSetup(rowSetup.status)}
								sx={{
									fontSize: 20,
									color: globalColorTextSetup(rowSetup.status),
									borderColor: globalColorTextSetup(rowSetup.status),
								}}
								variant="outlined"
							/>
						</Box>
						<Box display={"flex"} gap={5} justifyContent={"flex-end"}>
							<Box>
								<Typography variant="body2" fontWeight={"500"}>
									Forma de pagamento
								</Typography>
								<Typography variant="body2">{globalTranslatePayment(rowSetup.payment_method ?? "")}</Typography>
							</Box>
							<Box>
								<Typography variant="body2" fontWeight={"500"}>
									Vencimento
								</Typography>
								<Typography variant="body2">{moment(rowSetup.dueDate ?? "").format("DD/MM/YYYY")}</Typography>
							</Box>
							<Box alignItems={"center"} minWidth={200} justifyContent={"end"} justifyItems={"center"} display={"flex"} gap={2}>
								<Typography variant="body2" fontWeight={"500"}>
									Valor
								</Typography>
								<Chip
									label={parseFloat(rowSetup.raw_amount ?? "0").toLocaleString("pt-br", {
										style: "currency",
										currency: "BRL",
									})}
									color="primary"
									sx={{ fontSize: 20 }}
								/>
							</Box>
						</Box>
					</CardContent>
				</Card>
			</Box>
		</Modal>
	);
};
