import {AuthGuard} from '../../shared/guards/AuthGuard';
import {FinancialInvoiceListPresentation} from './components';
import {Sidebar} from '../../shared/components';

export const FinancialInvoiceListPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <FinancialInvoiceListPresentation/>
            </Sidebar>
        </AuthGuard>
    )
};