import {
	Box,
	Button,
	Grid,
	Icon,
	LinearProgress,
	MenuItem,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { endOfMonth } from "date-fns";
import { ModalContext, ToastContent, ToolbarFinancialHealth } from "../../../shared/components";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { Environment } from "../../../shared/environment";
import { ProfileGuard, checkByProfileGuard } from "../../../shared/guards/ProfileGuard";
import { useDebounce, useToast } from "../../../shared/hooks";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { FinancialService, IListFinancial } from "../../../shared/services/api/financial/FinancialService";
import { CustomizedAccordions } from "./TableFinancial";
import { format, isBefore, addDays } from "date-fns";
import { ptBR } from "date-fns/locale";

export type TInvoiceSelect = {
	clientId: string;
	invoiceId: string;
	selected: boolean;
};
let listInvoiceSelect: TInvoiceSelect[] = [];

export type TInvoiceClient = {
	id: string;
	clientId?: string;
	clientName?: string;
	reference?: string;
	dueDate?: string;
	value?: string;
	email?: string;
	paymentDate?: string;
	installmentsNumber?: string;
	status?: string;
	gateway?: string;
	groupedNumber?: string;
	canceledReason?: string;
	canceledType?: string;
	canceledAt?: string;
	canceledUser?: string;
	amountPaid?: string;
	creditAmount?: string;
	platformTaxes?: string;
	creditDate?: string;
	createdAt?: string;
	updatedAt?: string;
	url?: string;
};

export interface IClientInvoices {
	id: string;
	name: string;
	document: string;
	invoice_due_date?: string;
	email?: string;
	phone?: string;
	statusClient: string;
	invoice: TInvoiceClient[];
	openSum?: string;
	paidSum?: string;
	pendingSum?: string;
	overdueSum?: string;
	openCount?: string;
	paidCount?: string;
	overdueCount?: string;
	pendingCount?: string;
	nameLoading?: boolean;
	invoiceLoading?: boolean;
	listInvoiceSelect: TInvoiceSelect[];
	overRefresh: (value: boolean) => void;
}

export const FinancialHealthListPresentation: React.FC = () => {
	const toastHandler = useToast();
	const [searchParams, setSearchParams] = useSearchParams();
	const { debounce } = useDebounce();
	const navigate = useNavigate();
	const [rowsFinancial, setRowsFinancial] = useState<IListFinancial[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const permissionUpdate = checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.Update);
	const [totalCountFinancial, setTotalCountFinancial] = useState(0);
	const [stringDocument, setDocument] = useState("");
	const [pesquisar, setPesquisar] = useState(true);
	const [refreshList, setRefreshList] = useState(false);
	const [reportBegin, setReportBegin] = useState("");
	const [reportEnd, setReportEnd] = useState("");
	const [reportType, setReportType] = useState("");
	const [reportOrder, setReportOrder] = useState("nome");
	const [modalReport, setModalReport] = useState(false);

	const pagina = useMemo(() => {
		return Number(searchParams.get("pagina") || "1");
	}, [searchParams]);

	const busca = useMemo(() => {
		return stringDocument;
	}, [stringDocument]);

	const statusInvoice = useMemo(() => {
		return searchParams.get("statusInvoice") || "Todos";
	}, [searchParams]);

	const limitInvoice = useMemo(() => {
		return searchParams.get("limitInvoice") || "06";
	}, [searchParams]);

	const limitClient = useMemo(() => {
		return searchParams.get("limitClient") || "05";
	}, [searchParams]);

	const grouped = useMemo(() => {
		return searchParams.get("grouped") || "Todos";
	}, [searchParams]);

	const handleBusca = () => {
		setSearchParams(
			{
				pagina: String(1),
				busca,
				statusInvoice: statusInvoice,
				limitInvoice,
				limitClient,
				grouped,
			},
			{ replace: true }
		);
		setPesquisar(!pesquisar);
	};

	const hasViewPermission = useMemo(() => {
		return checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.View);
	}, []);

	const handleExport = async () => {
		setIsLoading(true);
		try {
			const { data } = await FinancialService.getExportInvoiceCsv(busca, statusInvoice);
			if (data) {
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "exported_data.csv");
				document.body.appendChild(link);
				link.click();
				setIsLoading(false);
			}
		} catch (error) {
			console.error("Erro ao fazer o download do arquivo:", error);
			const titleMessage = "Alerta!!";
			const contentMessage = "Erro: " + "Não foi possível fazer download";

			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});
			setIsLoading(false);
		}
	};

	const dataIni = useMemo(() => {
		return searchParams.get("dataini") || new Date().toISOString().split("T")[0];
	}, [searchParams]);

	const dataFim = useMemo(() => {
		return searchParams.get("datafim") || endOfMonth(new Date()).toISOString().split("T")[0];
	}, [searchParams]);

	const handleModalReport = () => {
		setModalReport(true);
		setReportBegin(dataIni);
		setReportEnd(dataFim);
		setReportType("todos");
		setReportOrder("nome");
	};

	const handleReportInvoice = () => {
		setIsLoading(true);
		debounce(() => {
			FinancialService.getReportInvoice(reportType, reportOrder, reportBegin, reportEnd).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={"Relatório de Boletos"} content={"Nenhum registro localizado"} />,
					});
					console.log(result.message);
				} else {
					//setModalDetail(true)
				}
			});
		});
	};

	const handleConciliation = async () => {
		setIsLoading(true);
		let dataAtual = new Date(dataIni); // Converte a string/objeto para o formato de data
		const dataFinal = new Date(dataFim);
		const dados = [];
		while (!isBefore(dataFinal, dataAtual)) {
			// Enquanto dataAtual <= dataFinal
			const dataFormatada = format(dataAtual, "yyyy-MM-dd", { locale: ptBR });
			try {
				const result = await FinancialService.getConciliation(dataFormatada, dataFormatada);
				if (result instanceof Error) {
					alert(result.message);
				}
			} catch (error) {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={String(error)} />,
				});
			}
			console.log(dataFormatada, dataAtual);
			// Avança para o próximo dia
			dataAtual = addDays(dataAtual, 1); // Incrementa 1 dia
		}

		setIsLoading(false);
	};

	useEffect(() => {
		setSearchParams(
			{
				...searchParams,
				pagina: "1",
				busca: "",
				status: statusInvoice,
				limit: limitInvoice,
				limitClient: limitClient,
				grouped: grouped,
			},
			{ replace: true }
		);
	}, []);

	useEffect(() => {
		handleBusca();
	}, [refreshList]);

	useEffect(() => {
		setIsLoading(true);
		debounce(() => {
			FinancialService.getInvoiceForClient(pagina, limitClient, busca, statusInvoice, limitInvoice, grouped).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					alert(result.message);
				} else {
					setTotalCountFinancial(result.total);
					setRowsFinancial(result.result);
					listInvoiceSelect = [];
				}
			});
		});
	}, [pesquisar, pagina]);

	return (
		<LayoutBaseDePagina
			titulo="Financeiro"
			subtitulo="Movimentação Financeira - Boletos"
			barraDeFerramentas={
				<ToolbarFinancialHealth
					clickBack={() => {
						navigate(`/dashboard/`);
					}}
					onChangeTextSearch={(texto) => {
						setDocument(texto), { replace: true };
					}}
					textSearch={busca}
					onClick={handleBusca}
					onClickExport={handleExport}
					onChangeStatusInvoice={(retorno: string) =>
						setSearchParams(
							{
								pagina: String(1),
								busca,
								statusInvoice: retorno,
								limitInvoice,
							},
							{ replace: true }
						)
					}
					onChangeLimitInvoice={(retorno: string) =>
						setSearchParams(
							{
								pagina: String(1),
								busca,
								statusInvoice: statusInvoice,
								limitInvoice: retorno,
							},
							{ replace: true }
						)
					}
					onChangeLimitClient={(retorno: string) =>
						setSearchParams(
							{
								pagina: String(1),
								busca,
								statusInvoice,
								limitInvoice,
								limitClient: retorno,
							},
							{ replace: true }
						)
					}
					onChangeGroup={(retorno: string) =>
						setSearchParams(
							{
								pagina: String(1),
								busca,
								statusInvoice,
								limitInvoice,
								limitClient,
								grouped: retorno,
							},
							{ replace: true }
						)
					}
					onclickReport={handleModalReport}
					onclickConcilied={() => {
						handleConciliation();
					}}
				/>
			}
		>
			{modalReport && (
				<ModalContext
					isCloseModal={() => setModalReport(false)}
					isOpenModal={modalReport}
					messageType={"info"}
					showButtonCancel={false}
					textButtonConfirm={"Fechar"}
					onclickConfirm={() => {}}
					ITitle={"Relatorio de Boletos"}
					IMessage={[
						<>
							<Box style={{ width: "80%" }}>
								<Grid container item direction="row" gap={3} sx={{ mb: 3, mt: "1rem" }}>
									<Grid item xs={4} sx={{ alignItems: "left" }}>
										<TextField
											fullWidth
											label="Tipo de Cliente"
											name="typePerson"
											disabled={false}
											size="small"
											select
											InputLabelProps={{ shrink: true, required: true }}
											defaultValue={reportType}
											onChange={(e) => setReportType(e.target.value)}
										>
											<MenuItem value={"todos"} selected>
												Todos
											</MenuItem>
											<MenuItem value={"pagamento"}>Pago</MenuItem>
											<MenuItem value={"vencidos"} sx={{ color: "red" }}>
												Vencidos
											</MenuItem>
											<MenuItem value={"avencer"}>A vencer</MenuItem>
											<MenuItem value={"cancelados"} sx={{ color: "orange" }}>
												Cancelado
											</MenuItem>
										</TextField>
									</Grid>

									<Grid item xs={3} sx={{ alignItems: "left" }}>
										<TextField
											fullWidth
											label="Data Inicial"
											name="reportBegin"
											disabled={isLoading}
											size="small"
											type="date"
											InputLabelProps={{ shrink: true, required: true }}
											defaultValue={reportBegin}
											onChange={(e) => {
												setReportBegin(e.target.value);
											}}
										></TextField>
									</Grid>
									<Grid item xs={3} sx={{ alignItems: "left" }}>
										<TextField
											fullWidth
											label="Data final"
											name="repostEnd"
											disabled={isLoading}
											size="small"
											type="date"
											InputLabelProps={{ shrink: true, required: true }}
											defaultValue={reportEnd}
											onChange={(e) => {
												setReportEnd(e.target.value);
											}}
										></TextField>
									</Grid>

									<Grid item xs={4} sx={{ alignItems: "left" }}>
										<TextField
											fullWidth
											label="Ordem do relatório"
											name="order"
											disabled={false}
											size="small"
											select
											InputLabelProps={{ shrink: true, required: true }}
											defaultValue={reportOrder}
											onChange={(e) => setReportOrder(e.target.value)}
										>
											<MenuItem value={"nome"} selected>
												Nome
											</MenuItem>
											<MenuItem value={"vencimento"}>Vencimento</MenuItem>
											<MenuItem value={"status"}>Status</MenuItem>
										</TextField>
									</Grid>
								</Grid>

								<Grid container item direction="row" gap={2} justifyContent={"center"}>
									<Button
										color="primary"
										disableElevation
										variant={"contained"}
										startIcon={<Icon> search</Icon>}
										onClick={() => {
											setModalReport(false);
											handleReportInvoice();
										}}
									>
										{"Visualizar"}
									</Button>

									<Button
										color="primary"
										disableElevation
										variant={"outlined"}
										startIcon={<Icon> close</Icon>}
										onClick={() => {
											setModalReport(false);
										}}
									>
										{"Fechar"}
									</Button>
								</Grid>
							</Box>
						</>,
					]}
				/>
			)}

			<ProfileGuard requiredRole={PermissionRolesEnum.FinancialOptions} requiredAction={PermissionActionsEnum.View}>
				<TableContainer
					component={Paper}
					variant="outlined"
					sx={{
						marginLeft: 1,
						marginTop: 1,
						marginRight: 2,
						width: "auto",
						padding: 3,
					}}
					aria-label="customized table"
				>
					<Table aria-label="a dense table">
						<TableHead>
							<TableRow
								sx={{
									background: "#f5fbfd",
									borderRight: "1px solid #bdbdbdb5",
									borderLeft: "1px solid #bdbdbdb5",
								}}
							>
								<TableCell sx={{ color: "#7e7777b5", borderTop: "1px solid #bdbdbdb5" }} colSpan={12}>
									<Box display={"flex"} justifyContent={"space-between"}>
										<Typography variant="body1">Cliente</Typography>
										{/* <Button
											variant="contained"
											color="primary"
											size="small"
											onClick={() => {
												toastHandler.present({
													type: "info",
													position: "top-right",
													messageOrContent: <ToastContent title={"Teste"} content={"Gerando todos os boletos da pagina"} />,
												});
											}}
										>
											Gerar Boletos
										</Button> */}
									</Box>
								</TableCell>
								{/* vamos implmenetar na segunda versao --- Leandro */}
							</TableRow>
						</TableHead>

						<TableBody>
							<TableRow>
								<TableCell key={pagina} colSpan={12} sx={{ padding: 0 }}>
									{rowsFinancial?.map((row) => (
										<div>
											<CustomizedAccordions
												id={row.id ? row.id : ""}
												name={row.name}
												document={row.document}
												invoice_due_date={row.invoice_due_date}
												email={row.email}
												invoice={row.invoices}
												statusClient={row.status}
												nameLoading={isLoading}
												openSum={row.openSum}
												openCount={row.openCount}
												pendingCount={row.pendingCount}
												pendingSum={row.pendingSum}
												paidCount={row.paidCount}
												paidSum={row.paidSum}
												overdueCount={row.overdueCount}
												overdueSum={row.overdueSum}
												listInvoiceSelect={listInvoiceSelect}
												overRefresh={handleBusca}
											/>
										</div>
									))}
								</TableCell>
							</TableRow>
						</TableBody>

						{totalCountFinancial === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}
						<TableFooter>
							{isLoading && (
								<TableRow>
									<TableCell colSpan={12}>
										<LinearProgress variant="indeterminate" sx={{ height: "8px", borderRadius: "6px" }} />
									</TableCell>
								</TableRow>
							)}
							{totalCountFinancial > 0 && (
								<TableRow>
									<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
										<Pagination
											page={pagina}
											count={Math.ceil(totalCountFinancial / Number(limitClient))}
											onChange={(_, newPage) =>
												setSearchParams(
													{
														busca,
														pagina: newPage.toString(),
														statusInvoice: statusInvoice,
														limit: limitInvoice,
														limitClient: limitClient,
														grouped: grouped,
													},
													{ replace: true }
												)
											}
										/>
									</TableCell>
									<TableCell colSpan={4} sx={{ borderBottom: "none" }}>
										<Typography variant="body1" sx={{ fontWeight: "normal" }}>
											Exibindo {pagina * Number(limitClient) - (Number(limitClient) - 1)}
											{" - "}
											{Number(limitClient) * (pagina - 1) + Number(limitClient) * pagina < totalCountFinancial
												? Number(limitClient) * pagina
												: totalCountFinancial}{" "}
											de
											<Typography
												variant="overline"
												sx={{
													fontWeight: "bold",
													color: "red",
													fontSize: "16px",
												}}
											>
												{" "}
												{totalCountFinancial}{" "}
											</Typography>
											registro(s)
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableFooter>
					</Table>
				</TableContainer>
			</ProfileGuard>
		</LayoutBaseDePagina>
	);
};
