export const backgroundColor: (value: string) => string = (value: string) => {
  switch (value) {
    case "active":
      return "#38df61";
    case "inactive":
      return "#efe1e1";
    case "canceled":
      return "#ffdbdb";
    case "blocked":
      return "#fce4e4";
    case "sent":
      return "#0f8a0d";
    default:
      return "#ffffff";
  }
};
