export const changeColorIcon: (value: string) => string = (value: string) => {
  switch (value) {
    case "active":
      return "#f1f1f1";
    case "inactive":
      return "#e40909";
    case "blocked":
      return "#fce4e4";
    case "canceled":
      return "#9c0000";
    case "sent":
      return "#f3f0f0";
    default:
      return "#ffffff";
  }
};
