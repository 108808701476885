import React from "react";
import { Icon, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { checkByProfileGuard } from "../../../shared/guards/ProfileGuard";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { ISetup } from "../../../shared/services/api/financial/FinancialService";

type SetupRowPropsType = {
	row: ISetup;
	goTo: any;
	handleApproved?: () => void;
};
export function RowMenuSetup({ row, goTo }: SetupRowPropsType) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleMenuClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	//RowMenu --

	return (
		<>
			<Menu id={`actions-${row.id}`} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
				{checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.View) ? (
					<MenuItem
						onClick={(e) => {
							goTo("detalhar", row.id, e, row), handleClose();
						}}
						sx={{ color: "GrayText" }}
					>
						Detalhar
					</MenuItem>
				) : (
					""
				)}
				{checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Update) ? (
					<MenuItem
						disabled={row.status == "not_paid" || row.status == "pending" ? false : true}
						onClick={(e) => {
							goTo("baixar", row.id, e, row), handleClose();
						}}
						sx={{ color: "green" }}
					>
						Baixar
					</MenuItem>
				) : (
					""
				)}
				{checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Add) ? (
					<MenuItem
						disabled={row.status == "not_paid" || row.status == "pending" ? false : true}
						onClick={(e) => {
							goTo("reenviar", row.id, e, row), handleClose();
						}}
						sx={{ color: "#2471a3" }}
					>
						Reenviar Link
					</MenuItem>
				) : (
					""
				)}

				{checkByProfileGuard(PermissionRolesEnum.FinancialOptions, PermissionActionsEnum.Add) ? (
					<MenuItem
						disabled={row.status != "paid" && row.status != "canceled" ? false : true}
						onClick={(e) => {
							goTo("cancelar", row.id, e, row), handleClose();
						}}
						sx={{ color: "red" }}
					>
						Cancelar
					</MenuItem>
				) : (
					""
				)}
			</Menu>
			<IconButton
				size="small"
				sx={{
					borderRadius: "18px",
					backgroundColor: "#f5f5f5",
				}}
				onClick={handleClick}
			>
				<Icon sx={{ color: "#0170B3" }}>more_horiz</Icon>
			</IconButton>
		</>
	);
}
