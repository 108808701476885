import { Box, Button, Icon, Paper, Skeleton, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";


interface IToolAnalysisProps {

    showButtonBack?: boolean;
    showButtonCancel?: boolean;
    showButtonAnalysis?: boolean;
    showButtonExit?: boolean;
    showButtonProposal?: boolean;
    showButtonEmailresend?: boolean;
    showButtonReAnalysis?: boolean;
    showButtonUpdateContact?: boolean;

    showButtonBackLoading?: boolean;
    showButtonCancelLoading?: boolean;
    showButtonAnalysisLoading?: boolean;
    showButtonExitLoading?: boolean;
    showButtonProposalLoading?: boolean;
    showButtonEmailresendLoading?: boolean;
    showButtonReAnalysisLoading?: boolean;


    clickBack?: () => void;
    clickCancel?: () => void;
    clickAnalysis?: () => void;
    clickProposal?: () => void;
    clickExit?: () => void;
    clickEmailResend?: () => void;
    clickReAnalysis?: () => void;
    clickUpdateContact?: () => void;

    textButtonAnalysis?: string;
    textButtonProposal?: string;
}


export const ToolbarAnalysis: React.FC<IToolAnalysisProps> = ({
    showButtonBack = true,
    showButtonCancel = true,
    showButtonAnalysis = true,
    showButtonExit = true,
    showButtonProposal = false,
    showButtonEmailresend = true,
    showButtonReAnalysis = true,

    showButtonBackLoading = false,
    showButtonCancelLoading = false,
    showButtonAnalysisLoading = false,
    showButtonExitLoading = false,
    showButtonProposalLoading = false,
    showButtonEmailresendLoading = false,
    showButtonReAnalysisLoading = false,
    showButtonUpdateContact = false,

    clickBack,
    clickAnalysis,
    clickCancel,
    clickExit,
    clickProposal,
    clickEmailResend,
    clickReAnalysis,
    clickUpdateContact,

    textButtonAnalysis = 'Concluir Análise',
    textButtonProposal = "Gerar Garantia",

}) => {
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            height={theme.spacing(5)}
            component={Paper}
        >

            {(showButtonBack && !showButtonBackLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickBack}
                    startIcon={<Icon>keyboard_double_arrow_left_icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Voltar
                    </Typography>
                </Button>
            )}

            {(showButtonCancel && !showButtonCancelLoading) && (
                <Button
                    // color="error"
                    disableElevation
                    variant="outlined"
                    onClick={clickCancel}
                    startIcon={<Icon>highlight_off_icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Cancelar
                    </Typography>
                </Button>
            )}

            {(showButtonAnalysis && !showButtonAnalysisLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    onClick={clickAnalysis}
                    startIcon={<Icon>check</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textButtonAnalysis}
                    </Typography>
                </Button>
            )}

            {(showButtonProposal && !showButtonProposalLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickProposal}
                    startIcon={<Icon>add_moderator_outlined_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textButtonProposal}
                    </Typography>
                </Button>
            )}

            {(showButtonEmailresend && !showButtonEmailresendLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickEmailResend}
                    startIcon={<Icon>forward_to_inbox_Outlined_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Reenviar email
                    </Typography>
                </Button>
            )}

            {(showButtonReAnalysis && !showButtonReAnalysisLoading) && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickReAnalysis}
                    startIcon={<Icon>published_with_changes_Outlined_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Reanalise
                    </Typography>
                </Button>
            )}

            {showButtonUpdateContact && (
                <Button
                    color="primary"
                    disableElevation
                    variant="outlined"
                    onClick={clickUpdateContact}
                    startIcon={<Icon>send_outlined_Icon</Icon>}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Reenviar Link
                    </Typography>
                </Button>
            )}

        </Box>
    );
};