const options: any = {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
  // maximumFractionDigits: 3,
};
const formatNumber = new Intl.NumberFormat("pt-BR", options);

export const formatScaleValue = (value: string = "") => {
  if (!value) return value;

  value = Number(Number(value) / 100).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
  });

  return value;
};

export const formatScaleValueBR = (value: string = "") => {
  if (!value) return value;

  return formatNumber.format(Number(value));
};

export const formatValueBR = (value: number = 0) => {
  if (value <= 0) return formatNumber.format(0.00);

  return formatNumber.format(value);
};
