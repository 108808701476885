import CheckIcon from "@mui/icons-material/Check";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { Button, Grid, Paper, Typography, Skeleton, Box } from "@mui/material";
import { format, isValid } from "date-fns";
import React from "react";
import { ModalConcludeCancelProposal } from "../Cancel/ModalConcludeCancel";
import ModalWaiverCancel from "../Cancel/ModalWaiverCancel";

interface ProposalCanceledCardProps {
	rows: any;
	smDown: boolean;
	permissionLevel: number;

	isLoading: boolean;
}

const ProposalCanceledCard: React.FC<ProposalCanceledCardProps> = ({ rows, smDown, permissionLevel, isLoading }) => {
	const backgroundStyle = { backgroundColor: "#f0f1f1" };
	const [openModalCancelConclude, setOpenModalCancelConclude] = React.useState(false);
	const [openModalCancelWaiver, setOpenModalCancelWaiver] = React.useState(false);

	// Função para formatar datas com verificação
	const formatDate = (date?: Date | string) => {
		if (!date) return "N/A";
		const validDate = new Date(date);
		return isValid(validDate) ? format(validDate, "dd/MM/yyyy") : "Data inválida";
	};

	if (rows?.status !== "suspended" && rows?.status !== "canceled") {
		return null;
	}

	return (
		<>
			<Grid item xs={12} md={12}>
				<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} ml={1}>
					<Grid container item direction="row">
						<Typography variant="h6" color={"red"}>
							{isLoading ? <Skeleton width={200} /> : "Cancelamento de Garantia"}
						</Typography>
					</Grid>

					<Grid container item direction="row" padding={1} spacing={1}>
						<Grid item xs={12} sm={12} md={2} sx={backgroundStyle}>
							<Typography variant="caption" color="text.secondary">
								Data do Cancelamento
							</Typography>
							<Typography variant="subtitle2">{isLoading ? <Skeleton width={100} /> : formatDate(rows?.canceledOpen)}</Typography>
						</Grid>

						<Grid item xs={12} sm={12} md={2} sx={backgroundStyle}>
							<Typography variant="caption" color="text.secondary">
								Cancelamento do Contrato
							</Typography>
							{/* Aqui tratamos a data sem adicionar manualmente o horário */}
							<Typography variant="subtitle2">{isLoading ? <Skeleton width={100} /> : formatDate(rows?.canceledAt + " 03:00:00")}</Typography>
						</Grid>

						<Grid item xs={12} sm={12} md={2} sx={backgroundStyle}>
							<Typography variant="caption" color="text.secondary">
								Efetivação do Cancelamento
							</Typography>
							<Typography variant="subtitle2">
								{isLoading ? <Skeleton width={100} /> : formatDate(rows?.canceledConclude) || "Em processamento"}
							</Typography>
						</Grid>

						<Grid item xs={12} sm={12} md={2} sx={backgroundStyle}>
							<Typography variant="caption" color="text.secondary">
								Motivo
							</Typography>
							<Typography variant="subtitle2">{isLoading ? <Skeleton width={100} /> : rows?.canceledType || "N/A"}</Typography>
						</Grid>

						<Grid item xs={12} sm={12} md={4} sx={backgroundStyle}>
							<Typography variant="caption" color="text.secondary">
								Descrição
							</Typography>
							<Typography variant="subtitle2">{isLoading ? <Skeleton width={200} /> : rows?.canceledReason || "N/A"}</Typography>
						</Grid>

						<Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
							{rows?.status === "suspended" && (
								<>
									<Box>
										{isLoading ? (
											<Skeleton width={150} height={40} />
										) : (
											<Button
												color="primary"
												disableElevation
												variant="outlined"
												startIcon={<PowerSettingsNewOutlinedIcon />}
												onClick={() => setOpenModalCancelWaiver(true)}
											>
												Desistir do Cancelamento
											</Button>
										)}
									</Box>
									{permissionLevel === 1 && (
										<Box>
											{isLoading ? (
												<Skeleton width={150} height={40} />
											) : (
												<Button
													color="primary"
													disableElevation
													variant="contained"
													onClick={() => setOpenModalCancelConclude(true)}
													startIcon={<CheckIcon />}
												>
													Concluir Cancelamento
												</Button>
											)}
										</Box>
									)}
								</>
							)}
						</Box>
					</Grid>
				</Grid>
			</Grid>

			{openModalCancelConclude && (
				<ModalConcludeCancelProposal isClose={() => setOpenModalCancelConclude(false)} isOpenModal={openModalCancelConclude} rows={rows} />
			)}

			{openModalCancelWaiver && <ModalWaiverCancel isClose={() => setOpenModalCancelWaiver(false)} isOpenModal={openModalCancelWaiver} rows={rows} />}
		</>
	);
};

export default ProposalCanceledCard;
