import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Paper,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  analysisService,
  Client,
  IDetalheAnalises,
  IPlan,
} from '../../../shared/services/api/analysis/analysisService';
import { BasicModal, ModalContext } from '../../../shared/components';
import { VMaskTextField } from '../../../shared/forms/VMaskTextField';
import {
  useVForm,
  VForm,
  VTextField,
  IVFormErros,
} from '../../../shared/forms';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToolbarAnalysis } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import React, { useEffect, useState } from 'react';
import { Email, EmailOutlined, HelpOutlineOutlined } from '@mui/icons-material';
import * as yup from 'yup';
import { add, format, parseISO, subDays } from 'date-fns';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useToast } from '../../../shared/hooks';
import { ToastContent } from '../../../shared/components';
import { useAuthContext } from '../../../context';
import { useProfile } from '../../../context/ProfileContext';
import { PeopleService } from '../../../shared/services/api/people/PeopleService';
import {
  RangeStates,
  globalColorTytle,
  globalColorInputBackGround,
  globalMaskReal,
} from '../../../shared/utils';
import { Environment } from '../../../shared/environment';

interface IFormData {
  paymentMethod: string;
  fee: string;
  lessorName: string;
  lessorCpf: string;
  assuranceMonthAmount: string;
  lmi: string;
  lmiAmount: string;
  lessorTypePerson: string;
  lessorBirthDate?: string;
  lessorEmail: string;
  rentMonthsCount: string;
  termBegin: string;
  planId?: string;
  lessorPhone: string;
  lessorMobilePhone?: string;
  email?: string;
  contractBegin: string;
  contractEnd: string;
  sendClick?: string;
  setup_tax?: string;
  setup_amount?: string;
  addressNumber?: string;
  addressStreet?: string;
  addressDistrict?: string;
  addressComplement?: string;
  addressZipcode?: string;
  addressCity?: string;
  addressState?: string;
}

const createRequiredMessage = (fieldName: string) =>
  `Campo ${fieldName} é Obrigatório`;

const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
  paymentMethod: yup.string().required(createRequiredMessage('paymentMethod')),
  fee: yup.string().required(createRequiredMessage('fee')),
  lessorName: yup.string().required(createRequiredMessage('lessorName')),
  lessorCpf: yup.string().required(createRequiredMessage('lessorCpf')),
  assuranceMonthAmount: yup
    .string()
    .required(createRequiredMessage('assuranceMonthAmount')),
  lmi: yup.string().required(createRequiredMessage('lmi')),
  lmiAmount: yup.string().required(createRequiredMessage('lmiAmount')),
  lessorTypePerson: yup
    .string()
    .required(createRequiredMessage('lessorTypePerson')),
  lessorBirthDate: yup.string().when('lessorTypePerson', {
    is: 'PF',
    then: yup.string().required(createRequiredMessage('lessorBirthDate')),
    otherwise: yup.string().notRequired(),
  }),
  lessorEmail: yup.string().required(createRequiredMessage('lessorEmail')),
  rentMonthsCount: yup
    .string()
    .required(createRequiredMessage('rentMonthsCount')),
  termBegin: yup.string().required(createRequiredMessage('termBegin')),
  planId: yup.string().notRequired(),
  lessorPhone: yup.string().required(createRequiredMessage('lessorPhone')),
  lessorMobilePhone: yup.string().notRequired().default(''),
  email: yup.string().notRequired().default(''),
  contractBegin: yup.string().required(createRequiredMessage('contractBegin')),
  contractEnd: yup.string().required(createRequiredMessage('contractEnd')),
  sendClick: yup.string().notRequired().default(''),
  setup_tax: yup.string().notRequired().default(''),
  setup_amount: yup.string().notRequired().default(''),
  addressNumber: yup.string().notRequired(),
  addressCity: yup.string().notRequired(),
  addressState: yup.string().notRequired(),
  addressComplement: yup.string().notRequired(),
  addressDistrict: yup.string().notRequired(),
  addressZipcode: yup.string().notRequired(),
  addressStreet: yup.string().notRequired(),
});

const removeMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/[^0-9]/g, '')
    .replace(/(\d{1,2})$/, '.$1');
};

const ITEM_HEIGHT = 8;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      height: 150,
      maxHeight: ITEM_HEIGHT * 1 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const AnalysisHiringPresentation: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const analise = searchParams.get('analise') || '';
  const plano = searchParams.get('plano') || '';
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } =
    useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [nome, setNome] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [plan, setPlan] = useState('');
  const [validity, setValidity] = useState('12');
  const [documentType, setDocumentType] = useState('cpf');
  const [document, setDocument] = useState('');
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [listPlans, setListPlans] = useState<IPlan[]>([]);
  const [beginDate, setBeginDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [termBegin, setTermBegin] = useState('');
  const [baseCalc, setBaseCalc] = useState(0);
  const [calcAssuranceMonthAmount, setCalcAssuranceMonthAmount] = useState(0);
  const [createAnalysis, setCreateAnalysis] = useState('');
  const [modalValidity, setModalValidity] = useState(false);
  const [ValiditYError, setValidityError] = useState('');
  const [prevEmail, setPrevEmail] = useState('');
  const toastHandler = useToast();
  const [isSendClick, SetIsSendClick] = useState(true);
  const [isSendClickSetup, SetIsSendClickSetup] = useState(true);
  const [charges,setCharges] = useState(false);
  const { loggedUser } = useAuthContext();
  const { permissionLevel } = useProfile();
  const clientObject: Client = {
    activeSignature: 0,
    baseRateCalc: '',
    name: '',
    document: '',
    id: '',
    tax_setup_enable: 1,
    tax_setup_limit_default: '',
    tax_setup_limit_min: '',
    tax_setup_limit_max: '',
  };
  const [client, setClient] = useState<Client>(clientObject);

  const weeks = Array.from({ length: 49 }, (_, index) => index + 12);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleChangeClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetIsSendClick(event.target.checked);
  };

  const handleChangeClickSetup = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    SetIsSendClickSetup(event.target.checked);
    formRef.current?.setFieldValue('setup_amount', '0,00');
  };

  useEffect(() => {
    if (termBegin && validity && endDate) {
      const parsedExpireAt: Date = parseISO(termBegin);
      const parsedEndDate: Date = parseISO(endDate);
      const expires: Date = add(parsedExpireAt, {
        months: parseInt(`${validity}`),
      });
      const subtractedExpireDate: Date = subDays(expires, 1);
      const formattedSubtractedExpireDate: string = format(
        subtractedExpireDate,
        'yyyy-MM-dd'
      );
      formRef.current?.setFieldValue(
        'expiresAt',
        formattedSubtractedExpireDate
      );

      if (subtractedExpireDate.getTime() > parsedEndDate.getTime()) {
        setEndDate(formattedSubtractedExpireDate);
        formRef.current?.setFieldValue(
          'contractEnd',
          formattedSubtractedExpireDate
        );
      }
    }
  }, [termBegin, validity, endDate]);

  const handleExpiresAt = (date: string) => {
    const expires = format(
      add(parseISO(date), { months: parseInt(`${validity}`) }),
      'yyyy-MM-dd'
    );
    var expires2 = format(subDays(parseISO(expires), 1), 'yyyy-MM-dd');
    var d1 = Date.parse(beginDate);
    var d2 = Date.parse(endDate);
    var d3 = Date.parse(termBegin);
    var d4 = Date.parse(createAnalysis);
    var d5 = Date.parse(expires2);

    const diffInMs = d2 - d1;
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    const diffInMs2 = d3 - d4;
    const diffInDays2 = diffInMs2 / (1000 * 60 * 60 * 24);

    if (diffInDays < 90) {
      setValidityError('O periodo mínimo de vigência é de 3 meses');
      setModalValidity(true);
    } else {
      if (diffInDays2 < 0 || diffInDays2 > 30) {
        setValidityError(
          'Data de início de vigência não pode ser menor que data de aprovação da análise ou superior a 30 dias da aprovação'
        );
        setModalValidity(true);
      } else {
        if (d5 > d2) {
          setValidityError(
            'O fim do período de vigência não pode ser superior ao fim do contrato de locação'
          );
          setModalValidity(true);
        } else {
          setEmail('');
          setConfirmEmail(true);
        }
      }
    }
  };

  const handleDocumentType = (document: any) => {
    if (document === 'physical') {
      setDocumentType('cpf');
    }
    if (document === 'legal') {
      setDocumentType('cnpj');
    }
  };

  const handleCPF = (value: string) => {
    const documento = value.replace(/[^0-9]/g, '');
    const ur = `${Environment.URL_API_CPF}`; //'https://api.cpfcnpj.com.br/548312cf26cd55f10c94de3c123f5c83/2/'

    if (documento === document) {
      toastHandler.present({
        type: 'error',
        position: 'top-right',
        messageOrContent: (
          <ToastContent
            title={'Erro '}
            content={
              'Documento do locador deve ser diferente do documento do locatário'
            }
          />
        ),
      });
      formRef.current?.setFieldValue('lessorCpf', ' ');
      formRef.current?.setFieldValue('lessorName', '');
      formRef.current?.setFieldValue('lessorMotherName', '');
    } else {
      PeopleService.getPersonDataByDocument(documento)
        .then((data) => {
          formRef.current?.setFieldValue(
            'lessorName',
            data.naturalPersonData?.name
          );
          formRef.current?.setFieldValue(
            'lessorBirthDate',
            data.naturalPersonData?.birthDate
          );
          formRef.current?.setFieldValue(
            'lessorMotherName',
            data.naturalPersonData?.motherName
          );
        })
        .catch((err) => {
          const messageTitle = 'Erro na pesquisa';
          const messageContent =
            'CPF não localizado na base, verifique se esta correto.';

          toastHandler.present({
            type: 'error',
            position: 'top-right',
            messageOrContent: (
              <ToastContent title={messageTitle} content={messageContent} />
            ),
          });
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    analysisService
      .getById(`${analise}`)
      .then((result: IDetalheAnalises | Error) => {
        setIsLoading(false);
        if (result instanceof Error) {
          navigate('/analises');
        } else {
          setNome(result.name);
          setDocument(result.document);
          let baseRateCalc = result.baseCalc ? result.baseCalc : 'aluguel'; // setando base de calculo de taxas vindo do cliente
          const pplano = result.planlist; //******setando o objeto string dos planos carregandos na result****
          const planos = JSON.parse(pplano); //******convertendo string de planos para objeto**********
          const AutoCompletePlan = planos.find(
            (searchid: IPlan) => searchid.planId === plano
          ); //******localizando o plano dentro do objeto**********
          setListPlans(planos);
          const fee =
            result.propertyFinally === 'residential'
              ? AutoCompletePlan.residentialFee
              : AutoCompletePlan.commercialFee;
          const lmi = AutoCompletePlan.lmi;
          // setPlan(AutoCompletePlan.plan.id);
          setPlan(AutoCompletePlan.planId);
          setCreateAnalysis(result.createdAt.substring(0, 10));
          setPrevEmail(result.email);
          setClient(result.client as Client);

          const rentMonthsCount = result.rentMonthsCount
            ? result.rentMonthsCount
            : 12;
          let feepc = parseFloat(fee) / 100;
          let varBaseCalc = parseFloat(result.rentAmount);
          let varAssuranceMonthAmount = varBaseCalc * feepc;

                    if (plano.includes('__charges') ) {
                          //baseRateCalc === "encargos") {
                          console.log('includes... charges.', planos)
                          setCharges(true);
                          baseRateCalc = 'encargos'; 
                        varBaseCalc =
                            parseFloat(result.rentAmount) +
                            parseFloat(result.condominiumAmount) +
                            parseFloat(result.iptuAmount);
                        varAssuranceMonthAmount = varBaseCalc * feepc;
                    }
                    setCalcAssuranceMonthAmount(varAssuranceMonthAmount);
                    setBaseCalc(varBaseCalc);
                    setTermBegin(moment().format("YYYY-MM-DD"));
                    setEndDate(
                        moment()
                            .add(rentMonthsCount, "month")
                            .format("YYYY-MM-DD")
                    );
                    setBeginDate(moment().format("YYYY-MM-DD"));
                    setValidity(rentMonthsCount.toString());

          formRef.current?.setData({
            lessorTypePerson: result.lessorTypePerson,
            lessorCpf: result.lessorCpf,
            lessorName: result.lessorName,
            status: 'pending',
            lessorBirthDate: result.lessorBirthDate,
            lessorPhone: result.lessorPhone,
            lessorMobilePhone: result.lessorMobilePhone,
            lessorEmail: result.lessorEmail,
            termBegin: moment().format('YYYY-MM-DD'), //'2022-11-26',//apagar
            expiresAt: moment()
              .add(rentMonthsCount, 'month')
              .format('YYYY-MM-DD'), //'2023-11-25',//apagar
            contractBegin: moment().format('YYYY-MM-DD'), //'2022-11-26',//apagar
            contractEnd: moment()
              .add(rentMonthsCount, 'month')
              .format('YYYY-MM-DD'), //'2023-11-25',//apagar
            paymentMethod: 'billet',
            rentMonthsCount,
            addressZipcode: result.addressZipcode,
            propertyFinally: result.propertyFinally,
            propertyType: result.propertyType,
            addressStreet: result.addressStreet,
            addressNumber: result.addressNumber,
            addressCity: result.addressCity,
            addressState: result.addressState,
            addressComplement: result.addressComplement,
            addressDistrict: result.addressDistrict,
            lmi: lmi,
            rentAmount: result.rentAmount,
            lmiAmount: (varBaseCalc * parseInt(lmi)).toFixed(2),
            fee: fee,
            assuranceMonthAmount: varAssuranceMonthAmount.toFixed(2),
            setup_amount: result.client?.tax_setup_limit_default,
            setup_tax: result.setup_tax,
          });
        }
      });
  }, []);

  useEffect(() => {
    formRef.current?.getFieldValue('planId') === '' ||
    formRef.current?.getFieldValue('planId') == null
      ? formRef.current?.setFieldValue('planId', plan)
      : formRef.current?.setFieldValue('planId', plan);
  }, [plan]);

  useEffect(() => {
    SetIsSendClick(true);

    if (client?.activeSignature != 1) {
      SetIsSendClick(false);
    }
    if (client?.tax_setup_enable == 0) {
      SetIsSendClickSetup(false);
    }
  }, [client]);

    const handleSave = (dados: IFormData) => {
        setConfirmEmail(false);
        dados.planId = plan;
        dados.lessorCpf = dados.lessorCpf.replace(/[^0-9]/g, "");
        dados.lessorPhone = dados.lessorPhone.replace(/[^0-9]/g, "");
        dados.lessorMobilePhone = dados.lessorMobilePhone?.replace(
            /[^0-9]/g,
            ""
        );
        dados.sendClick = isSendClick === true ? "true" : "false";
        dados.setup_tax = isSendClickSetup === true ? "true" : "false";
        dados.fee = removeMask(dados.fee);
        dados.assuranceMonthAmount = removeMask(dados.assuranceMonthAmount);
        dados.setup_amount = dados.setup_tax
            ? removeMask(dados.setup_amount || "0")
            : "0";
        dados.email = email;
        delete dados.addressCity;
        delete dados.addressState;
        delete dados.addressDistrict;
        delete dados.addressNumber;
        delete dados.addressZipcode;
        delete dados.addressStreet;
      

        const   setup_tax = isSendClickSetup;
        // console.log("dados :", dados);
        formValidationSchema
            .validate(dados, { abortEarly: false })
            .then((dadosvalidados) => {
                setIsLoading(true);
                console.log("dados :", dados);
                delete dados.setup_tax //remover setup_tax do objeto
                analysisService
                    .updateById(analise, { ...dadosvalidados, setup_tax, charges:charges})
                    .then((result) => {
                        setIsLoading(false);
                        if (result instanceof Error) {
                            const titleMessage = "Alerta!!";
                            const contentMessage = "Erro: " + result?.message;

              toastHandler.present({
                type: 'error',
                position: 'top-right',
                messageOrContent: (
                  <ToastContent title={titleMessage} content={contentMessage} />
                ),
              });
            } else if (result && result?.isExpired) {
              const titleMessage = 'Alerta!';
              const contentMessage =
                'Não foi possível confirmar a contratação, pois a data está expirada dessa ánalise.';

              toastHandler.present({
                type: 'warning',
                position: 'top-right',
                messageOrContent: (
                  <ToastContent title={titleMessage} content={contentMessage} />
                ),
              });
            } else {
              handleOpenModal();
            }
          })
          .catch((errors) => {
            const titleMessage = 'Alerta!';
            const contentMessage = errors.response.data.message;
            toastHandler.present({
              type: 'warning',
              position: 'top-right',
              messageOrContent: (
                <ToastContent title={titleMessage} content={contentMessage} />
              ),
            });
            setIsLoading(false);
          });
      })
      .catch((errors: yup.ValidationError) => {
        console.log(errors);
        const validationErros: IVFormErros = {};
        errors.inner.forEach((error) => {
          const titleMessage = 'Erro!';
          const contentMessage = errors?.message;
          toastHandler.present({
            type: 'error',
            position: 'top-right',
            messageOrContent: (
              <ToastContent title={titleMessage} content={contentMessage} />
            ),
          });

          if (!error.path) return;
          validationErros[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErros);
      });
  };

  const handleCheckSetupValue = (value: string) => {
    const setupValue = parseFloat(value.replace(',', '.'));
    const setupLimitMin = parseFloat(client.tax_setup_limit_min);
    const setupLimitMax = parseFloat(client.tax_setup_limit_max);
    if (setupValue < setupLimitMin) {
      formRef.current?.setFieldValue(
        'setup_amount',
        removeMask(setupLimitMin.toFixed(2))
      );
      toastHandler.present({
        type: 'error',
        position: 'bottom-right',
        messageOrContent: (
          <ToastContent
            title={'Erro de valor'}
            content={`Valor mínimo para setup é de R$ ${setupLimitMin}`}
          />
        ),
      });
    } else if (setupValue > setupLimitMax) {
      formRef.current?.setFieldValue(
        'setup_amount',
        removeMask(setupLimitMax.toFixed(2))
      );
      toastHandler.present({
        type: 'error',
        position: 'bottom-right',
        messageOrContent: (
          <ToastContent
            title={'Erro de valor'}
            content={`Valor máximo para setup é de R$ ${setupLimitMax}`}
          />
        ),
      });
    }
  };

  return (
    <LayoutBaseDePagina
      titulo="Análises"
      subtitulo={
        analise === 'novo'
          ? 'Nova análise de crédito'
          : `Alteração de análise [ ${nome} ]`
      }
      barraDeFerramentas={
        <ToolbarAnalysis
          clickAnalysis={() => handleExpiresAt(termBegin)}
          showButtonAnalysis={true}
          clickCancel={() => navigate('/analises')}
          clickBack={() => navigate('/analises')}
          textButtonAnalysis={'Finalizar contratação'}
          showButtonCancel={false}
          showButtonProposal={false}
          showButtonEmailresend={false}
          showButtonReAnalysis={false}
        />
      }
    >
      {openModal && (
        <BasicModal
          isCloseModal={() => setOpenModal(false)}
          isOpenModal={openModal}
          messageType={'success'}
          showButtonCancel={false}
          textButtonConfirm={'OK'}
          onclickConfirm={() => {
            setOpenModal(false), navigate('/analises');
          }}
          IMessage={'Contratação realizada com sucesso !'}
        />
      )}

      {modalValidity && (
        <ModalContext
          isCloseModal={() => setModalValidity(false)}
          isOpenModal={modalValidity}
          messageType={'error'}
          showButtonCancel={false}
          textButtonConfirm={'Cancelar'}
          onclickConfirm={() => {}}
          ITitle={'Erro de período de vigência'}
          IMessage={[
            <Box style={{ width: '100%' }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, color: '#7d7d7d' }}
              >
                {ValiditYError}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, color: '#7d7d7d' }}
              >
                Data de aprovação da analise:{' '}
                {moment(createAnalysis).format('L')}
              </Typography>

              <Grid
                container
                item
                sm={12}
                sx={{
                  border: 'none',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                display={'flex'}
                flexDirection={smDown ? 'column' : 'row'}
                padding={2}
                gap={2}
              >
                <Button
                  color="primary"
                  disableElevation
                  variant={'outlined'}
                  startIcon={<Icon> arrow_back_Ico</Icon>}
                  onClick={() => {
                    setModalValidity(false);
                  }}
                >
                  {'Voltar'}
                </Button>
              </Grid>
            </Box>,
          ]}
        />
      )}

      {confirmEmail && (
        <ModalContext
          isCloseModal={() => setConfirmEmail(false)}
          isOpenModal={confirmEmail}
          messageType={'alert'}
          showButtonCancel={false}
          textButtonConfirm={'Cancelar'}
          onclickConfirm={() => {}}
          ITitle={'Confirmação de email'}
          IMessage={[
            <Box style={{ width: '100%' }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, color: '#7d7d7d' }}
              >
                <VForm
                  id="formIdSave"
                  placeholder="Your placeholder"
                  onPointerEnterCapture={() => console.log('Pointer entered')}
                  onPointerLeaveCapture={() => console.log('Pointer left')}
                  ref={formRef}
                  onSubmit={handleSave}
                >
                  <Grid
                    container
                    item
                    direction="column"
                    padding={1}
                    spacing={2}
                  >
                    {isLoading && (
                      <Grid item>
                        <LinearProgress variant="indeterminate" />
                      </Grid>
                    )}

                    <Grid
                      container
                      item
                      direction="row"
                      spacing={1}
                      sx={{ mb: 2 }}
                    >
                      <Grid item xs={4} sx={{ textAlign: 'right' }}>
                        Confirme o email cadastrado :
                      </Grid>
                      <Grid item xs={6} sx={{ alignItems: 'left' }}>
                        <Typography
                          variant="inherit"
                          sx={{ fontWeight: 'bold' }}
                        >
                          {prevEmail}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12}>
                        <VTextField
                          fullWidth
                          label="Email do locatário"
                          name="email"
                          trimEmptySpaces={true}
                          disabled={isLoading}
                          onChange={(e) => setEmail(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                >
                                  <EmailOutlined />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    sm={12}
                    sx={{
                      border: 'none',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    display={'flex'}
                    flexDirection={smDown ? 'column' : 'row'}
                    padding={2}
                    gap={2}
                  >
                    <Button
                      color="primary"
                      disableElevation
                      variant={'contained'}
                      startIcon={<Icon> checkIcon</Icon>}
                      onClick={() => {
                        email !== ''
                          ? formRef.current?.submitForm()
                          : toastHandler.present({
                              type: 'error',
                              position: 'top-right',
                              messageOrContent: (
                                <ToastContent
                                  title={'Erro de confirmação'}
                                  content={'Favor confirmar o email'}
                                />
                              ),
                            });
                      }}
                    >
                      {'Confirmar'}
                    </Button>
                    <Button
                      color="primary"
                      disableElevation
                      variant={'outlined'}
                      startIcon={<Icon> arrow_back_Ico</Icon>}
                      onClick={() => {
                        setConfirmEmail(false);
                      }}
                    >
                      {'Voltar'}
                    </Button>
                  </Grid>
                </VForm>
              </Typography>
            </Box>,
          ]}
        />
      )}

      <VForm
        id="formIdSave2"
        placeholder="Your placeholder"
        onPointerEnterCapture={() => console.log('Pointer entered')}
        onPointerLeaveCapture={() => console.log('Pointer left')}
        ref={formRef}
        onSubmit={handleSave}
      >
        {/* ******************************************** */}

        <Box
          margin={1}
          display="flex"
          flexDirection="column"
          component={Paper}
          variant="outlined"
        >
          <Grid container item direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant="indeterminate" />
              </Grid>
            )}
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={11}>
                <Typography variant="h6" color={globalColorTytle}>
                  Dados do Imóvel{' '}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Tipo Imóvel "
                  name="propertyType"
                  disabled={true}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Finalidade"
                  name="propertyFinally"
                  disabled={true}
                  select
                >
                  <MenuItem value="residential">Residencial</MenuItem>
                  <MenuItem value="commercial">Comercial</MenuItem>
                </VTextField>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VMaskTextField
                  fullWidth
                  label="CEP"
                  name="addressZipcode"
                  disabled={true}
                  mask="cep"
                />
              </Grid>
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Rua"
                  name="addressStreet"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Numero"
                  name="addressNumber"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Tooltip title="Digite ou altere o complemento">
                  <VTextField
                    fullWidth
                    label="Complemento * "
                    name="addressComplement"
                    disabled={isLoading}
                    sx={{
                      background: globalColorInputBackGround,
                    }}
                    className="Testecomp"
                  />
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Bairro"
                  name="addressDistrict"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Cidade"
                  name="addressCity"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Estado"
                  name="addressState"
                  disabled={true}
                  select
                >
                  {RangeStates.map(({ value, label }, key) => (
                    <MenuItem value={value}>{label}</MenuItem>
                  ))}
                </VTextField>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box
          margin={1}
          display="flex"
          flexDirection="column"
          component={Paper}
          variant="outlined"
        >
          <Grid container item direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant="indeterminate" />
              </Grid>
            )}
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={11}>
                <Typography variant="h6" color={globalColorTytle}>
                  Dados Locador
                </Typography>
              </Grid>
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} md={4}>
                <VTextField
                  fullWidth
                  label="Tipo de Locador"
                  name="lessorTypePerson"
                  disabled={isLoading}
                  select
                  onChange={(e) => handleDocumentType(e.target.value)}
                >
                  <MenuItem value={'physical'}>Fisica</MenuItem>
                  <MenuItem value={'legal'}>Juridica</MenuItem>
                </VTextField>
              </Grid>
              {permissionLevel === 1 && (
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={handleChangeClick}
                        name="sendClick"
                        color="primary"
                        checked={isSendClick}
                        defaultChecked={isSendClick}
                        disabled={client?.activeSignature == 1 ? false : true}
                      />
                    }
                    label="Enviar ClickSign"
                  />
                </Grid>
              )}
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VMaskTextField
                  fullWidth
                  label="Documento"
                  name="lessorCpf"
                  disabled={isLoading}
                  mask={documentType}
                  onBlur={(e) =>
                    documentType === 'cpf' && handleCPF(e.target.value)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Nome"
                  name="lessorName"
                  disabled={isLoading}
                />
              </Grid>

              {documentType === 'cpf' && (
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Dada de nascimento"
                    name="lessorBirthDate"
                    disabled={isLoading}
                    type="date"
                  />
                </Grid>
              )}
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VMaskTextField
                  fullWidth
                  label="Celular"
                  name="lessorPhone"
                  disabled={isLoading}
                  mask="cell"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VMaskTextField
                  fullWidth
                  label="Telefone"
                  name="lessorMobilePhone"
                  disabled={isLoading}
                  mask="cell"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="E-mail"
                  name="lessorEmail"
                  disabled={isLoading}
                  trimEmptySpaces={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <Email />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box
          margin={1}
          display="flex"
          flexDirection="column"
          component={Paper}
          variant="outlined"
        >
          <Grid container item direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant="indeterminate" />
              </Grid>
            )}
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={11}>
                <Typography variant="h6" color={globalColorTytle}>
                  Informações da contratação{' '}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} md={2}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangeClickSetup}
                      name="sendSetup"
                      color="primary"
                      checked={isSendClickSetup}
                      defaultChecked={isSendClickSetup}
                      disabled={isLoading}
                    />
                  }
                  label="Cobrar taxa de setup"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <VMaskTextField
                  fullWidth
                  label="Valor Taxa de Setup"
                  name="setup_amount"
                  disabled={!isSendClickSetup}
                  mask="real"
                  onBlur={(e) => {
                    handleCheckSetupValue(e.target.value);
                  }}
                />
              </Grid>

              <Tooltip
                title={`O valor da taxa de setup deverá estar entre R$ ${globalMaskReal(
                  client.tax_setup_limit_min
                )} e R$ ${globalMaskReal(client.tax_setup_limit_max)}`}
              >
                <IconButton color="primary">
                  <HelpOutlineOutlined />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Forma de pagamento "
                  name="paymentMethod"
                  disabled={isLoading}
                  select
                >
                  <MenuItem value={'billet'}>Fatura Imobiliária</MenuItem>
                </VTextField>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Inicio do contrato de locação"
                  name="contractBegin"
                  disabled={isLoading}
                  type="date"
                  onChange={(e) => setBeginDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Quantidade de meses Vigência"
                  name="rentMonthsCount"
                  disabled={isLoading}
                  onChange={(e) => setValidity(e.target.value)}
                  select
                >
                  {weeks.map((element, key) => (
                    <MenuItem value={element}>{element}</MenuItem>
                  ))}
                </VTextField>
              </Grid>
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Fim do contrato de locação"
                  name="contractEnd"
                  disabled={isLoading}
                  onChange={(e) => setEndDate(e.target.value)}
                  type="date"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Inicio da Vigencia"
                  name="termBegin"
                  disabled={isLoading}
                  type="date"
                  onChange={(e) => setTermBegin(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Fim da Vigencia"
                  name="expiresAt"
                  disabled={true}
                  type="date"
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Plano Selecionado"
                  name="planId"
                  disabled={true}
                  select
                >
                  {listPlans.map((element, key) => (
                    <MenuItem value={element.planId}>
                      {element.plan?.description}
                      {element.flag_rateCharge=='1'? ' com encargos' :'' }
                    </MenuItem>
                  ))}
                </VTextField>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField fullWidth label="LMI" name="lmi" disabled={true} />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label="Taxa Aplicada"
                  name="fee"
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VMaskTextField
                  fullWidth
                  label="Valor LMI"
                  name="lmiAmount"
                  disabled={true}
                  mask="real"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VMaskTextField
                  fullWidth
                  label="Valor do Aluguel"
                  name="rentAmount"
                  disabled={true}
                  mask="real"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VMaskTextField
                  fullWidth
                  label="Valor Mensal"
                  name="assuranceMonthAmount"
                  disabled={true}
                  mask={'real'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePagina>
  );
};
