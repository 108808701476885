import { Box, Button, Card, CardActions, CardContent, Divider, Icon, Modal, Typography } from "@mui/material";

interface IModalConfirmPortableProps {
	isOpenModal: boolean;
	isCloseModal?: () => void;
	widthModfy?: string;
	heightModfy?: string;
	onclickConfirm?: () => void;
	onCloseModal?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
	overrideWidthValue?: string;
	overrideHeightValue?: string;
}

const style = {
	position: "relative",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "50%",
	height: "auto",
	maxWidth: "75%",
	maxHeight: "85%",
	bgcolor: "background.paper",
	border: "1px solid #605e5e",
	boxShadow: 24,
	borderRadius: "6px",
	display: "flex",
	flexDirection: "column",
	backgroundColor: "#FFF",
	padding: 3,
};

export const ModalConfirmPortable: React.FC<IModalConfirmPortableProps> = ({
	isOpenModal: isOpenModal,
	onCloseModal,
	overrideWidthValue,
	overrideHeightValue,
	onclickConfirm,
}) => {
	return (
		<Modal open={isOpenModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={onCloseModal}>
			<Box
				sx={{
					...style,
					width: overrideWidthValue ? overrideWidthValue : style.width,
					height: overrideHeightValue ? overrideHeightValue : style.height,
				}}
			>
				<Box display={"flex"} justifyContent={"center"}>
					<Typography variant="h4" paddingY={2} textAlign={"center"} color={"primary"} fontWeight={"200"}>
						Confirmação
					</Typography>
				</Box>
				<Card elevation={0} sx={{ width: "100%" }}>
					<CardContent>
						<Box paddingX={3} paddingTop={1} sx={{ background: "#ffe3e3", borderRadius: 4, border: "1px solid #ffb6b6 " }}>
							<Typography variant="h6" component="div" color={"red"} paddingX={1}>
								Atenção
							</Typography>
							<Typography variant="body2" fontWeight={"500"} color={"red"} padding={1}>
								Esta operação não pode ser desfeita. Ao confirmar, todas as garantias selecionadas serão transferidas para nova imobiliária, bem
								como as parcelas em aberto, sinistros e taxas de Setup.
							</Typography>
						</Box>

						<Typography variant="h5" fontWeight={"300"} textAlign={"center"} padding={1}>
							Confirma a transferência de garantias, parcelas, sinistros,taxas Setup ?
						</Typography>
					</CardContent>
					<CardActions sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
						<Button variant="contained" color="inherit" onClick={onCloseModal} sx={{ gap: 1 }}>
							<Icon>reply</Icon>Voltar
						</Button>
						<Button variant="contained" color="primary" onClick={onclickConfirm} sx={{ gap: 1 }}>
							<Icon>send</Icon>Confirmar
						</Button>
					</CardActions>
				</Card>
			</Box>
		</Modal>
	);
};
