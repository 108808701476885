import { Api } from "../axios-config";
import { AnalysisContractTypeEnum } from "../../../../enums/analysis-documents";
import { ProposalDocumentType } from "./types";

interface IAuth {
  accessToken: string;
}

const uploadProposalDocument = async (
  proposalId: string,
  documentTypeId: string,
  file: File
): Promise<any> => {
  const formData = new FormData();
  formData.append("proposalId", proposalId);
  formData.append("documentTypeId", documentTypeId);
  formData.append("file", file);
  const { data } = await Api().post("/proposal-documents", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return data as any;
};

const getProposalDocuments = async (
  proposalId: string
): Promise<ProposalDocumentType[]> => {
  const { data } = await Api().get(
    `/proposal-documents/proposals/${proposalId}`
  );

  return data as ProposalDocumentType[];
};

const removeDocumentByProposal = async (
  documentId: string
): Promise<void | Error> => {

  try {
    const { data } = await Api().patch(
      `/proposal-documents/proposals/${documentId}`
    );

    if (!data)
      return new Promise((_, reject) =>
        reject(new Error("Erro ao remover documento."))
      );
  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

export const ProposalDocumentsService = {
  uploadProposalDocument,
  getProposalDocuments,
  removeDocumentByProposal,
};
