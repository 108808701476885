import { Box, Button, ButtonGroup, Icon, useTheme } from "@mui/material";

interface IToolbarGridAnalisesProps {
	textButtonPreAprovadas?: string;
	showBotaoPreAprovadas?: boolean;
	onClickPreAprovadas?: () => void;
	selectPreAprovada?: boolean;

	textButtonAprovadas?: string;
	showBotaoAprovadas?: boolean;
	onClickAprovadas?: () => void;
	selectAprovada?: boolean;

	textButtonExpiradas?: string;
	showBotaoExpiradas?: boolean;
	onClickExpiradas?: () => void;
	selectExpirada?: boolean;

	textButtonRecusadas?: string;
	showBotaoRecusadas?: boolean;
	onClickRecusadas?: () => void;
	selectRecusada?: boolean;

	textButtonPendentes?: string;
	showBotaoPendentes?: boolean;
	onClickPendentes?: () => void;
	selectPendente?: boolean;

	textButtonBiometria?: string;
	showBotaoBiometria?: boolean;
	onClickBiometria?: () => void;
	selectBiometria?: boolean;

	textButtonTodos?: string;
	showBotaoTodos?: boolean;
	onClickTodos?: () => void;
	selectTodos?: boolean;

	textButtonCancel?: string;
	showBotaoCancel?: boolean;
	onClickCancel?: () => void;
	selectCancel?: boolean;
}

export const ToolbarGridAnalises: React.FC<IToolbarGridAnalisesProps> = ({
	textButtonPreAprovadas = "Pre-Aprovadas",
	showBotaoPreAprovadas = true,
	onClickPreAprovadas,
	selectPreAprovada = false,

	textButtonAprovadas = "Aprovadas",
	showBotaoAprovadas = true,
	onClickAprovadas,
	selectAprovada = false,

	textButtonExpiradas = "Expiradas",
	showBotaoExpiradas = true,
	onClickExpiradas,
	selectExpirada = false,

	textButtonRecusadas = "Recusadas",
	showBotaoRecusadas = true,
	onClickRecusadas,
	selectRecusada = false,

	textButtonPendentes = "Pendentes",
	showBotaoPendentes = true,
	onClickPendentes,
	selectPendente = false,

	textButtonBiometria = "Biometria",
	showBotaoBiometria = true,
	onClickBiometria,
	selectBiometria = false,

	textButtonTodos = "Todos",
	showBotaoTodos = true,
	onClickTodos,
	selectTodos = true,

	textButtonCancel = "Canceladas",
	showBotaoCancel = true,
	onClickCancel,
	selectCancel = false,
}) => {
	const theme = useTheme();

	return (
		<Box display="flex" alignItems="start" height={theme.spacing(6)}>
			<ButtonGroup aria-label="outlined primary button group">
				{showBotaoTodos && (
					<Button
						color="primary"
						disableElevation
						variant={selectTodos ? "contained" : "outlined"}
						onClick={onClickTodos}
						startIcon={<Icon>done_all_icon</Icon>}
					>
						{textButtonTodos}
					</Button>
				)}

				{showBotaoPreAprovadas && (
					<Button
						color="primary"
						disableElevation
						variant={selectPreAprovada ? "contained" : "outlined"}
						onClick={onClickPreAprovadas}
						startIcon={<Icon>done_outlined_icon</Icon>}
					>
						{textButtonPreAprovadas}
					</Button>
				)}
				{showBotaoBiometria && (
					<Button
						color="primary"
						disableElevation
						variant={selectBiometria ? "contained" : "outlined"}
						onClick={onClickBiometria}
						startIcon={<Icon> fingerprint_OutlinedIcon</Icon>}
					>
						{textButtonBiometria}
					</Button>
				)}

				{showBotaoAprovadas && (
					<Button
						color="primary"
						disableElevation
						variant={selectAprovada ? "contained" : "outlined"}
						onClick={onClickAprovadas}
						startIcon={<Icon>how_to_reg_outlined_icon</Icon>}
					>
						{textButtonAprovadas}
					</Button>
				)}

				{showBotaoExpiradas && (
					<Button
						color="primary"
						disableElevation
						variant={selectExpirada ? "contained" : "outlined"}
						onClick={onClickExpiradas}
						startIcon={<Icon>alarm_off_Outlined_Icon</Icon>}
					>
						{textButtonExpiradas}
					</Button>
				)}

				{showBotaoRecusadas && (
					<Button
						color="primary"
						disableElevation
						variant={selectRecusada ? "contained" : "outlined"}
						onClick={onClickRecusadas}
						startIcon={<Icon>cancel_outlinedIcon</Icon>}
					>
						{textButtonRecusadas}
					</Button>
				)}

				{showBotaoPendentes && (
					<Button
						color="primary"
						disableElevation
						variant={selectPendente ? "contained" : "outlined"}
						onClick={onClickPendentes}
						startIcon={<Icon> pending_actionsOutlinedIcon</Icon>}
					>
						{textButtonPendentes}
					</Button>
				)}

				{showBotaoCancel && (
					<Button
						color="primary"
						disableElevation
						variant={selectCancel ? "contained" : "outlined"}
						onClick={onClickCancel}
						startIcon={<Icon> cancel_presentation_outlinedIcon</Icon>}
					>
						{textButtonCancel}
					</Button>
				)}
			</ButtonGroup>
		</Box>
	);
};
