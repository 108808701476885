export const changeTextColor: (value: string) => string = (value: string) => {
  switch (value) {
    case "active":
      return "#0f8a0d";
    case "inactive":
      return "#e40909";
    default:
      return "#fff";
  }
};

export const formatColorStatusAnalysis = (status: string) => {
  switch (status) {
    case "expired":
      return "#f0e555";
    case "pre_approved":
      return "#3761eb";
    case "in_progress":
      return "#1ec448";
    case "issued":
      return "#38df61";
    case "pending":
      return "#9D9A99";
    case "refused":
      return "#A72F11";
    case "canceled":
      return "#eb3232";
    case "hired":
      return "#3761eb";
    case "awaiting_signature":
      return "#fcfcfc";
    case "awaiting_biometria":
      return "#52380c";
    default:
      return "#444444";
  }
};

export const formatColorStatusReports = (status: string) => {
  switch (status) {
    case "available":
      return "#1ec448";
    case "in_process":
      return "#5a7ff9";
    case "expired":
      return "#F87171";

    default:
      return "#c1c1c1";
  }
};

export const translateStatusAnalysis = (traduzir: String) => {
  if (traduzir === "awaiting_signature") {
    return "Pend. Assinatura";
  }
  if (traduzir === "awaiting_biometria") {
    return "Pend. Autenticação";
  }
  if (traduzir === "in_progress") {
    return "Em andamento";
  }
  if (traduzir === "expired") {
    return "Expirada";
  }
  if (traduzir === "issued") {
    return "Aprovada";
  }
  if (traduzir === "pending") {
    return "Pendente";
  }
  if (traduzir === "refused") {
    return "Recusada";
  }
  if (traduzir === "canceled") {
    return "Cancelada";
  }
  if (traduzir === "residential") {
    return "(Residencial)";
  }
  if (traduzir === "commercial") {
    return "(Comercial)";
  }
  if (traduzir === "Reanalise") {
    return "(Reanalisada)";
  }

  if (traduzir === "hired") {
    return "Contratada";
  }

  if (traduzir === "pre_approved") {
    return "Pré Aprovada";
  }

  if (traduzir === 'awaiting_biometria') {
    return 'Pend. Biometria';
  }
  return traduzir;
};
