import { VMaskTextField } from "../../../../shared/forms/VMaskTextField";
import { Box, Grid, MenuItem, Paper, Typography } from "@mui/material"
import { VTextField } from "../../../../shared/forms"
import { RangeStates } from "../../../../shared/utils";

interface IAddressClientProps {
    isLoading: boolean;
    checkCEP: (e: any) => void;
}

export const AddressClient: React.FC<IAddressClientProps> = ({
    isLoading,
    checkCEP
}) => {

    return (
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
            <Grid container direction="column" padding={2} spacing={2}>
                <Grid container item direction="row" spacing={2}>
                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Typography variant="h6" color={'primary'}>Endereço</Typography>
                    </Grid>
                </Grid>

                <Grid container item direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VMaskTextField
                            fullWidth
                            label='CEP'
                            name="address_zipcode"
                            disabled={isLoading}
                            mask="cep"
                            onBlur={checkCEP}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VTextField
                            fullWidth
                            label='Rua'
                            name="address_street"
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VTextField
                            fullWidth
                            label='Numero'
                            name="address_number"
                            disabled={isLoading}
                        />
                    </Grid>
                </Grid>

                <Grid container item direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VTextField
                            fullWidth
                            label='Complemento'
                            name="address_complement"
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VTextField
                            fullWidth
                            label='Bairro'
                            name="address_district"
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VTextField
                            fullWidth
                            label='Cidade'
                            name="address_city"
                            disabled={isLoading}
                        />
                    </Grid>
                </Grid>

                <Grid container item direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VTextField
                            fullWidth
                            label='Estado'
                            name="address_state"
                            disabled={isLoading}
                            select
                        >
                            {RangeStates.map(({ value, label }, key) => (
                                <MenuItem value={value}>{label}</MenuItem>
                            ))}                            
                        </VTextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VTextField
                            fullWidth
                            label='Cód IBGE'
                            name="address_ibge"
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
};