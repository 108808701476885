import {
	Box,
	Button,
	ButtonGroup,
	FormControl,
	Icon,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { ReactNode } from "react";

interface IToolbarGridProposalProps {
	textButtonActive?: string;
	showButtonActive?: boolean;
	onClickActive?: () => void;
	selectAtive?: boolean;

	textButtonEmitidas?: string;
	showButtonEmitidas?: boolean;
	onClickEmitidas?: () => void;
	selectEmitidas?: boolean;

	textButtonExpires?: string;
	showButtonExpires?: boolean;
	onClickExpires?: () => void;
	selectExpires?: boolean;

	textButtonExpired?: string;
	showButtonExpired?: boolean;
	onClickExpired?: () => void;
	selectExpired?: boolean;

	textButtonCanceled?: string;
	showButtonCanceled?: boolean;
	onClickCanceled?: () => void;
	selectCanceled?: boolean;

	textButtonSuspended?: string;
	showButtonSuspended?: boolean;
	onClickSuspended?: () => void;
	selectSuspended?: boolean;

	textButtonRenovated?: string;
	showButtonRenovated?: boolean;
	onClickRenovated?: () => void;
	selectRenovated?: boolean;

	onClickRenovation?: () => void;
	selectRenovation?: boolean;

	textButtonAll?: string;
	showButtonAll?: boolean;
	onClickAll?: () => void;
	selectAll?: boolean;

	filterdescription?: string;
	showFilterDescription?: boolean;
}

export const ToolbarGridProposal: React.FC<IToolbarGridProposalProps> = ({
	textButtonActive = "Vigentes",
	showButtonActive = true,
	onClickActive,
	selectAtive = false,

	onClickEmitidas,
	selectEmitidas = false,

	onClickExpires,
	selectExpires = false,

	textButtonExpired = "Expiradas",
	showButtonExpired = true,
	onClickExpired,
	selectExpired = false,

	textButtonCanceled = "Canceladas",
	showButtonCanceled = true,
	onClickCanceled,
	selectCanceled = false,

	textButtonSuspended = "Suspensas",
	showButtonSuspended = true,
	onClickSuspended,
	selectSuspended = false,

	onClickRenovated,
	selectRenovated = false,

	onClickRenovation,
	selectRenovation = false,

	textButtonAll = "Todos",
	showButtonAll = true,
	onClickAll,
	selectAll = true,

	filterdescription = "",
	showFilterDescription = false,
}) => {
	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down("md"));

	function handleStatusActive(event: SelectChangeEvent<string>, child: ReactNode): void {
		switch (event.target.value) {
			case "active":
				onClickActive && onClickActive();
				break;
			case "emited":
				onClickEmitidas && onClickEmitidas();
				break;
			case "renovated":
				onClickRenovated && onClickRenovated();
				break;
			case "expires":
				onClickExpires && onClickExpires();
				break;
			case "renovation":
				onClickRenovation && onClickRenovation();
				break;
			default:
				break;
		}
	}

	return (
		<>
			<Box display="flex" alignItems="start" height={theme.spacing(6)} gap={3}>
				<ButtonGroup aria-label="outlined primary button group">
					{showButtonAll && (
						<Button
							color="primary"
							disableElevation
							variant={selectAll ? "contained" : "outlined"}
							onClick={onClickAll}
							startIcon={<Icon>done_all_icon</Icon>}
						>
							{textButtonAll}
						</Button>
					)}

					{showButtonActive && (
						<Button
							color="primary"
							disableElevation
							variant={selectAtive || selectEmitidas || selectExpires || selectRenovation || selectRenovated ? "contained" : "outlined"}
							onClick={onClickActive}
							startIcon={<Icon>portrait_outlined_icon</Icon>}
						>
							{textButtonActive}
						</Button>
					)}

					{showButtonExpired && (
						<Button
							color="primary"
							disableElevation
							variant={selectExpired ? "contained" : "outlined"}
							onClick={onClickExpired}
							startIcon={<Icon>pending_actionsOutlinedIcon</Icon>}
						>
							{textButtonExpired}
						</Button>
					)}

					{showButtonSuspended && (
						<Button
							color="primary"
							disableElevation
							variant={selectSuspended ? "contained" : "outlined"}
							onClick={onClickSuspended}
							startIcon={<Icon>pause_circle_outlineOutlinedIcon</Icon>}
						>
							{textButtonSuspended}
						</Button>
					)}

					{showButtonCanceled && (
						<Button
							color="primary"
							disableElevation
							variant={selectCanceled ? "contained" : "outlined"}
							onClick={onClickCanceled}
							startIcon={<Icon>cancel_outlinedIcon</Icon>}
						>
							{textButtonCanceled}
						</Button>
					)}
				</ButtonGroup>

				{(selectAtive || selectEmitidas || selectExpires || selectRenovated || selectRenovation) && (
					<FormControl sx={{ minWidth: "150px" }} fullWidth={mdDown ? true : false}>
						<InputLabel id="id_vigentes">Filtro de Vigentes</InputLabel>
						<Select
							labelId="label_data"
							id="id_data"
							value={
								selectAtive
									? "active"
									: selectEmitidas
									? "emited"
									: selectRenovated
									? "renovated"
									: selectExpires
									? "expires"
									: selectRenovation
									? "renovation"
									: ""
							}
							size="small"
							onChange={handleStatusActive}
							label="Filtro de Vigentes"
						>
							<MenuItem value={"active"}>Todas Vigentes</MenuItem>
							<MenuItem value={"emited"}>Garantias Contratadas</MenuItem>
							<MenuItem value={"renovated"}>Garantias Renovadas</MenuItem>
							<MenuItem value={"expires"}>Garantias a Expirar</MenuItem>
							<MenuItem value={"renovation"}>Garantias em Renovação</MenuItem>
						</Select>
					</FormControl>
				)}
			</Box>
			<Typography variant="subtitle2" color="primary" sx={{ display: showFilterDescription ? "block" : "none", py: 1 }}>
				{filterdescription}
			</Typography>
		</>
	);
};
