import {AuthGuard} from '../../shared/guards/AuthGuard';
import {Sidebar} from '../../shared/components';
import { FinancialHealthListPresentation } from './components/FinancialHealthListPresentation';


export const FinancialHealthListPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <FinancialHealthListPresentation/>
            </Sidebar>
        </AuthGuard>
    )
};