import { Clear, Search } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Icon, IconButton, InputAdornment, Paper, TextField, useTheme } from "@mui/material";
import { Environment } from "../../environment";

interface IToolbarGridFinancialProps {
    textSearchGrid?: string;
    showInputSearchGrid?: boolean;
    onChangeTextSearchGrid?: (newtext: string) => void;
    onClearTextSearchGrid?: (emptyText: string) => void;

    textButtonOpen?: string;
    showButtonOpen?: boolean;
    onClickOpen?: () => void;
    selectOpen?: boolean;

    textButtonOpenN?: string;
    showButtonOpenN?: boolean;
    onClickOpenN?: () => void;
    selectOpenN?: boolean;

    textButtonBilled?: string;
    showButtonBilled?: boolean;
    onClickBilled?: () => void;
    selectBilled?: boolean;

    textButtonCanceled?: string;
    showButtonCanceled?: boolean;
    onClickCanceled?: () => void;
    selectCanceled?: boolean;

    textButtonCanceledN?: string;
    showButtonCanceledN?: boolean;
    onClickCanceledN?: () => void;
    selectCanceledN?: boolean;

    textButtonPaid?: string;
    showButtonPaid?: boolean;
    onClickPaid?: () => void;
    selectPaid?: boolean;

    textButtonPaidN?: string;
    showButtonPaidN?: boolean;
    onClickPaidN?: () => void;
    selectPaidN?: boolean;

    textButtonNotPaid?: string;
    showButtonNotPaid?: boolean;
    onClickNotPaid?: () => void;
    selectNotPaid?: boolean;

    textButtonIssued?: string;
    showButtonIssued?: boolean;
    onClickIssued?: () => void;
    selectIssued?: boolean;

    textButtonRejected?: string;
    showButtonRejected?: boolean;
    onClickRejected?: () => void;
    selectRejected?: boolean;

    textButtonAll?: string;
    showButtonAll?: boolean;
    onClickAll?: () => void;
    selectAll?: boolean;

    textButtonAllN?: string;
    showButtonAllN?: boolean;
    onClickAllN?: () => void;
    selectAllN?: boolean;

    textButtonReport?: string;
    showButtonReport?: boolean;
    onClickButtonReport?: () => void;
    selectButtonReport?: boolean;

    textButtonAtualiza?: string;
    showButtonAtualiza?: boolean;
    onClickButtonAtualiza?: () => void;
    selectButtonAtualiza?: boolean;
}

export const ToolbarGridFinancial: React.FC<IToolbarGridFinancialProps> = ({
    textSearchGrid: textSearch = '',
    showInputSearchGrid: showInputSearch = true,
    onChangeTextSearchGrid: onChangeTextSearch,
    onClearTextSearchGrid: onClearTextSearchGrid,

    textButtonOpen = 'Aberto',
    showButtonOpen = true,
    onClickOpen,
    selectOpen = false,

    textButtonOpenN = 'Em emissão',
    showButtonOpenN = false,
    onClickOpenN,
    selectOpenN = false,

    textButtonBilled = 'Faturada',
    showButtonBilled = true,
    onClickBilled,
    selectBilled = false,

    textButtonCanceled = 'Canceladas',
    showButtonCanceled = true,
    onClickCanceled,
    selectCanceled = false,

    textButtonCanceledN = 'Canceladas',
    showButtonCanceledN = false,
    onClickCanceledN,
    selectCanceledN = false,

    textButtonNotPaid = "A Receber",
    showButtonNotPaid = false,
    onClickNotPaid,
    selectNotPaid = false,

    textButtonIssued = "Emitidas",
    showButtonIssued = false,
    onClickIssued,
    selectIssued = false,

    textButtonRejected = "Erros",
    showButtonRejected = false,
    onClickRejected,
    selectRejected = false,

    textButtonPaidN = 'A Emitir',
    showButtonPaidN = false,
    onClickPaidN,
    selectPaidN = false,

    textButtonPaid = 'Pagos',
    showButtonPaid = true,
    onClickPaid,
    selectPaid = false,

    textButtonAll = 'Todos',
    showButtonAll = true,
    onClickAll,
    selectAll = true,

    textButtonAllN = 'Todos',
    showButtonAllN = false,
    onClickAllN,
    selectAllN = true,

    textButtonReport = 'Relatório',
    showButtonReport = true,
    onClickButtonReport,
    selectButtonReport = true,


    textButtonAtualiza = 'Conciliação',
    showButtonAtualiza = false,
    onClickButtonAtualiza,
    selectButtonAtualiza = true,

}) => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            alignItems="start"
            height={theme.spacing(6)}
        >

            <ButtonGroup aria-label="outlined primary button group"
                sx={showButtonIssued ? { height: "60px", marginTop: "-10px" } : {}}
            >
                {showButtonAll && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectAll ? "contained" : "outlined"}
                        onClick={onClickAll}
                        startIcon={<Icon>done_all_icon</Icon>}
                    >
                        {textButtonAll}
                    </Button>
                )}

                {showButtonAllN && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectAllN ? "contained" : "outlined"}
                        onClick={onClickAllN}
                    // startIcon={<Icon>done_all_icon</Icon>}
                    >
                        {textButtonAllN}
                    </Button>
                )}

                {showButtonNotPaid && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectNotPaid ? "contained" : "outlined"}
                        onClick={onClickNotPaid}
                        startIcon={<Icon>portrait_outlined_icon</Icon>}
                    >
                        {textButtonNotPaid}
                    </Button>
                )}

                {showButtonPaidN && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectPaidN ? "contained" : "outlined"}
                        onClick={onClickPaidN}
                    //startIcon={<Icon>portrait_outlined_icon</Icon>}
                    >
                        {textButtonPaidN}
                    </Button>
                )}

                {showButtonOpen && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectOpen ? "contained" : "outlined"}
                        onClick={onClickOpen}
                        startIcon={<Icon>portrait_outlined_icon</Icon>}
                    >
                        {textButtonOpen}
                    </Button>
                )}

                {showButtonOpenN && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectOpenN ? "contained" : "outlined"}
                        onClick={onClickOpenN}
                    // startIcon={<Icon>portrait_outlined_icon</Icon>}
                    >
                        {textButtonOpenN}
                    </Button>
                )}

                {showButtonIssued && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectIssued ? "contained" : "outlined"}
                        onClick={onClickIssued}
                    // startIcon={<Icon>pending_actionsOutlinedIcon</Icon>}

                    >
                        {textButtonIssued}
                    </Button>
                )}

                {showButtonBilled && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectBilled ? "contained" : "outlined"}
                        onClick={onClickBilled}
                        startIcon={<Icon>pending_actionsOutlinedIcon</Icon>}

                    >
                        {textButtonBilled}
                    </Button>
                )}

                {showButtonPaid && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectPaid ? "contained" : "outlined"}
                        onClick={onClickPaid}
                        startIcon={<Icon>paid_Outlined_Icon</Icon>}
                    >
                        {textButtonPaid}
                    </Button>
                )}

                {showButtonCanceled && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectCanceled ? "contained" : "outlined"}
                        onClick={onClickCanceled}
                        startIcon={<Icon>cancel_outlinedIcon</Icon>}
                    >
                        {textButtonCanceled}
                    </Button>
                )}

                {showButtonCanceledN && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectCanceledN ? "contained" : "outlined"}
                        onClick={onClickCanceledN}
                    // startIcon={<Icon>cancel_outlinedIcon</Icon>}
                    >
                        {textButtonCanceledN}
                    </Button>
                )}

                {showButtonRejected && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectRejected ? "contained" : "outlined"}
                        onClick={onClickRejected}
                    // startIcon={<Icon>cancel_outlinedIcon</Icon>}
                    >
                        {textButtonRejected}
                    </Button>
                )}
            </ButtonGroup>

            <ButtonGroup aria-label="outlined primary button group" sx={{ ml: "3rem" }}>
                {showButtonReport && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={"outlined"}
                        onClick={onClickButtonReport}
                        startIcon={<Icon>summarize_outlined_icon</Icon>}
                    >
                        {textButtonReport}
                    </Button>
                )}
                {showButtonAtualiza && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={"outlined"}
                        onClick={onClickButtonAtualiza}
                        startIcon={<Icon>all_inclusive_outlined_icon</Icon>}
                    >
                        {textButtonAtualiza}
                    </Button>
                )}
            </ButtonGroup>


            <Box flex={1} display="flex" justifyContent="end">
                {showInputSearch && (
                    <TextField
                        size="small"
                        sx={showButtonIssued ? { marginRight: "-50px" } : {}}
                        value={textSearch}
                        placeholder={Environment.INPUT_BUSCA}
                        onChange={(e) => onChangeTextSearch?.(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="start"
                                    >
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        sx={{ color: "#dbd5d5" }}
                                        onClick={() => onChangeTextSearch?.('')}
                                    >
                                        <Clear />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            </Box>
        </Box >
    );
}; 