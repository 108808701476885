import { FormHandles } from '@unform/core';
import { useCallback, useRef } from 'react';

export const useVForm = () => {
  const formRef = useRef<FormHandles>(null);

  const isSavingAndClose = useRef(false);
  const isSavingAndNew = useRef(false);

  const handleSave = useCallback(() => {
    isSavingAndClose.current = false;
    isSavingAndNew.current = false;
    formRef.current?.submitForm();
  }, [formRef, formRef.current]);

  const handleSaveAndNew = useCallback(() => {
    isSavingAndClose.current = false;
    isSavingAndNew.current = true;
    formRef.current?.submitForm();
  }, [formRef, formRef.current]);

  const handleSaveAndClose = useCallback(() => {
    isSavingAndClose.current = true;
    isSavingAndNew.current = false;
    formRef.current?.submitForm();
  }, [formRef, formRef.current]);

  const handleIsSaveAndNew = useCallback(() => {
    return isSavingAndNew.current;
  }, [formRef, formRef.current]);

  const handleIsSaveAndClose = useCallback(() => {
    return isSavingAndClose.current;
  }, [formRef, formRef.current]);

  return {
    formRef,
    save: handleSave,
    saveAndNew: handleSaveAndNew,
    saveAndClose: handleSaveAndClose,

    issaveAndNew: handleIsSaveAndNew,
    issaveAndClose: handleIsSaveAndClose,
  };
};
