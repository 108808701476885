
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@mui/material';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '500px',
    width: '50%',
    bgcolor: 'background.paper',
    border: '1px solid #605e5e',
    boxShadow: 24,
    p: 2,
    borderRadius: '6px'
};

const typeMessage: ITypeMessage[] = [
    {
        type: 'error',
        title: 'Erro',
        icon: 'cancel_Outlined_Icon',
        color: '#e87b9e'
    },
    {
        type: 'alert',
        title: 'Atenção',
        icon: 'info_Outlined_Icon',
        color: '#ffc107'
    },
    {
        type: 'success',
        title: 'Sucesso',
        icon: 'check_circle_Outline_Icon',
        color: '#39c463'
    },
    {
        type: 'info',
        title: 'Informação',
        icon: 'error_outline_Outlined_Icon',
        color: '#7924c7 '
    },
];

interface ITypeMessage {
    title: string;
    icon: string;
    color: string;
    type: string;
}

interface IBasicModalProps {
    ITitle?: string;
    IMessage?: string;
    textButtonConfirm?: string;
    textButtonCancel?: string;
    showButtonCancel?: boolean;
    textButtonClose?: string;
    showButtonClose?: boolean;

    isOpenModal: boolean;
    messageType: string;
    onclickConfirm?: () => void;
    onclickCancel?: () => void;
    onclickClose?: () => void;
    isCloseModal?: () => void;
}

export const BasicModal: React.FC<IBasicModalProps> = ({ 
    ITitle, 
    IMessage, 
    textButtonConfirm,
    textButtonCancel,
    textButtonClose,
    showButtonCancel,
    showButtonClose,
    isOpenModal, 
    messageType,
    isCloseModal, 
    onclickConfirm,
    onclickCancel,
    onclickClose
    }) => {

    const setTypeMessage = () => {
        switch (messageType) {
            case 'error':
                return typeMessage[0];
            case 'alert':
                return typeMessage[1];
            case 'success':
                return typeMessage[2];
            case 'info':
                return typeMessage[3];
            default: return typeMessage[0];
        }
    }

    const messageStyle: ITypeMessage = setTypeMessage();

    return (
        <div>
            <Modal
                open={isOpenModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Icon style={{ color: `${messageStyle.color}`, fontSize: "3rem", fontWeight: 100 }}>
                            {messageStyle.icon}
                        </Icon>
                        <Typography
                            variant='caption'
                            color={`${messageStyle.color}`}
                            marginBottom="1rem"
                        >
                            {messageStyle.title}
                        </Typography>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            color={`${messageStyle.color}`}
                        >
                            {ITitle}
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
                        >
                            {IMessage}
                        </Typography>
                    </DialogContent>
                    <DialogActions
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Button
                            color="primary"
                            disableElevation
                            variant={"contained"}
                            startIcon={< Icon > checkIcon</Icon >}
                            onClick={onclickConfirm}
                        >
                            {textButtonConfirm}
                        </Button>
                        {showButtonCancel && (
                            <Button
                                onClick={onclickCancel}
                                color="primary"
                                disableElevation
                                variant={"outlined"}
                                startIcon={< Icon > closeIcon</Icon >}
                            >
                                {textButtonCancel}
                            </Button>
                        )}

                        {showButtonClose && (
                            <Button
                                onClick={onclickClose}
                                color="primary"
                                disableElevation
                                variant={"outlined"}
                                startIcon={< Icon > arrow_back_Ico</Icon >}
                            >
                                {textButtonClose}
                            </Button>
                        )}
                    </DialogActions>
                </Box>
            </Modal>
        </div>
    );
}

