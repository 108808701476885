import { Box, DialogContent, DialogTitle, Icon, IconButton, Modal, Typography, TypographyPropsVariantOverrides } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";
import React from "react";
import TabsPortable from "./components/TabsPortable";

interface IModalPortableProps {
	iscloseModal?: () => void;
	onclickConfirm?: () => void;
	variant?: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>;
	isOpenModal: boolean;
	enableBackdropClose?: boolean;
	overrideWidthValue?: string;
	overrideHeightValue?: string;
	showButtonConfirm?: boolean;
	stylePropert?: React.CSSProperties;
	maxWidth?: string;
	maxHeight?: string;
	width?: string;
	onChangeTextSearch?: (newText: string) => void;
	textSearch?: string;
}

export const ModalPortable: React.FC<IModalPortableProps> = ({
	onclickConfirm,
	isOpenModal,
	overrideWidthValue,
	overrideHeightValue,
	maxWidth,
	maxHeight,
	width,
	variant = "h4",
}) => {
	const style = {
		position: "relative",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: width ? width : "50%",
		height: "auto",
		maxWidth: maxWidth ? maxWidth : "70%",
		maxHeight: maxHeight ? maxHeight : "70%",
		bgcolor: "background.paper",
		border: "1px solid #605e5e",
		boxShadow: 24,
		overflow: "auto",
		p: 2,
		borderRadius: "6px",
		display: "flex",
		flexDirection: "column",
		backgroundColor: "#FFF",
	};

	return (
		<Modal open={isOpenModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
			<Box
				sx={{
					...style,
					width: overrideWidthValue ? overrideWidthValue : style.width,
					height: overrideHeightValue ? overrideHeightValue : style.height,
				}}
			>
				<DialogTitle style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 10 }}>
						<Typography id="modal-modal-title" variant={variant} sx={{ fontWeight: 300 }} color={"primary"}>
							Portabilidade de garantias
						</Typography>
						<IconButton color="primary" onClick={onclickConfirm} sx={{ top: 20, right: 20, position: "absolute" }}>
							<Icon sx={{ fontSize: 40 }}> cancel_Outlined_Icon</Icon>
						</IconButton>
					</Box>
				</DialogTitle>
				<DialogContent>
					{/* componente que contém as tabs */}
					<TabsPortable />
				</DialogContent>
			</Box>
		</Modal>
	);
};