import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { ISerasaData, ISerasaResponse, serasaService } from "../../../../../shared/services/api/serasa/serasaService";
import { IMessage } from "../../../../../shared/types/message";
import { returnMessageError } from "../../../../../shared/utils/returnMessageError";
import { useToast } from "../../../../../shared/hooks";

interface SerasaLogsModalProps {
	isOpenModal: boolean;
	isClose: () => void;
	rows: any;
}

export const ModalLogSerasa: React.FC<SerasaLogsModalProps> = ({ isOpenModal, isClose, rows }) => {
	const [dataSerasaResponse, setDataSerasaResponse] = useState<ISerasaResponse>();
	const [dataSerasa, setDataSerasa] = useState<ISerasaData>();
	const [isLoad, setIsLoad] = useState(true);
	const toastHandler = useToast();

	const convertStatusSerasa = (status: string = "Não fornecida") => (status === "approved" ? "Aprovado" : status === "refused" ? "Reprovado" : status);

	const handleGetLogsSerasa = async (analysisId: string) => {
		setIsLoad(true);
		try {
			const result: IMessage<ISerasaData> = await serasaService.getSerasaByAnalysisId(analysisId);
			if (result instanceof Error) {
				throw new Error(result.message);
			}

			const parsedData = JSON.parse("" + result.data?.data) as ISerasaResponse;
			setDataSerasaResponse(parsedData);

			const incomeValue = parsedData.response?.body?.split("RENDA")[1]?.split("|")[0];
			const income = incomeValue?.includes("PONTUACAO@") ? incomeValue.split("PONTUACAO@")[1] : "";
			setDataSerasa({ ...result.data, income });
		} catch (err) {
			const { titleMessage, subtitleMessage, contentMessage } = returnMessageError(err);
			toastHandler.present({
				type: "error",
				position: "top-right",
				durationMs: 4000,
				messageOrContent: <ToastContent title={titleMessage} subtitle={subtitleMessage} content={contentMessage} />,
			});
		} finally {
			setIsLoad(false);
		}
	};

	useEffect(() => {
		if (isOpenModal) {
			handleGetLogsSerasa(rows?.analysisId);
		}
	}, [isOpenModal]);

	const renderSerasaRow = (index: number, children: React.ReactNode) => (
		<Box
			sx={{
				backgroundColor: index % 2 === 0 ? "#ffffff" : "#f1f1f1", // Alternância de cores zebradas
				padding: 1,
			}}
		>
			<Grid container spacing={1}>
				{children}
			</Grid>
		</Box>
	);

	const renderSerasaDetail = (label: string, value: string | undefined) => (
		<Grid item xs={12} md={6}>
			<Typography variant="caption" color={"text.secondary"}>
				{label}
			</Typography>
			<Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
				{value || "Não fornecida"}
			</Typography>
		</Grid>
	);

	return (
		<ModalContext
			isCloseModal={isClose}
			isOpenModal={isOpenModal}
			messageType={"info"}
			enableBackdropClose={true}
			overrideWidthValue="100"
			showButtonCancel={true}
			textButtonCancel={"Sair"}
			showTitleIcon={false}
			ITitle="Log do Serasa"
			IMessage={[
				<Box key="serasa-log-modal" style={{ width: "100%", padding: 1 }}>
					{isLoad ? (
						<Box display="flex" justifyContent="center" alignItems="center" height="200px">
							<CircularProgress />
						</Box>
					) : (
						<>
							{renderSerasaRow(
								0,
								<>
									{renderSerasaDetail("Status do Sistema", convertStatusSerasa(rows?.serasaAnalysisStatus))}
									{renderSerasaDetail("Status Fornecido Pelo Serasa", convertStatusSerasa(dataSerasaResponse?.status))}
								</>
							)}

							{renderSerasaRow(1, renderSerasaDetail("Mensagem do Serasa", dataSerasaResponse?.serasa_response?.message || "Não fornecida"))}

							{renderSerasaRow(
								2,
								<>
									{renderSerasaDetail("Renda do Serasa", dataSerasa?.income)}
									{renderSerasaDetail("Score do Serasa", dataSerasa?.naturalPersonSerasaScore?.toString())}
								</>
							)}

							{renderSerasaRow(
								3,
								renderSerasaDetail(
									"Descrição da Mensagem do Serasa",
									dataSerasaResponse?.serasa_response?.message_description || "Não fornecido"
								)
							)}

							<Box sx={{ mt: 2, padding: 1, backgroundColor: "#f1f1f1", borderRadius: 1 }}>
								<Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
									Detalhes do JSON:
								</Typography>
								<pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{JSON.stringify(dataSerasaResponse, null, 2)}</pre>
							</Box>
						</>
					)}
				</Box>,
			]}
		/>
	);
};
