import { AuthGuard } from '../../shared/guards/AuthGuard';
import { Sidebar } from '../../shared/components';
import { ReportsPresentation } from './components/ReportsPresentation';


export const ReportsPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <ReportsPresentation  />
            </Sidebar>
        </AuthGuard>
    )
};