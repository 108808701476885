import { Api } from "../axios-config";

export interface IProposal {
  certificateNumber?: string;
  id: string;
  clientId: string;
  analysisId: string;
  status: string;
  name: string;
  motherName?: string;
  type_person?: string;
  document: string;
  birthDate?: string;
  canceledReason?: string;
  canceledType?: string;
  mobilePhone?: string;
  createdAt?: string;
  updatedAt?: string;
  canceledAt?: string;
  canceledOpen?: Date | null;
  canceledConclude?: Date | null;
  renovateAt?: string;
  termBegin?: string;
  expiresAt?: string;
  expiredAt?: string;
  installmentsNumber?: string;
  createdFrom?: string;
  employmentRelationShip?: string;
  maritalStatus?: string;
  wifeName?: string;
  email?: string;
  isTipping?: string;
  phone?: string;
  rentalCost?: string;
  sendSerasa?: string;
  propertyType?: string;
  propertyFinally?: string;
  addressZipcode?: string;
  addressStreet?: string;
  addressResidenceType?: string;
  addressNumber?: string;
  addressComplement?: string;
  addressDistrict?: string;
  addressCity?: string;
  addressState?: string;
  addressIbge?: string;
  serasaId?: string;
  serasaResponse?: string;
  serasaAnalysisStatus?: string;
  paymentMethod?: string;
  rentMonthsCount?: string;
  residentialFee?: string;
  commercialFee?: string;
  rentAmount?: string;
  condominiumAmount?: string;
  electricEnergyAmount?: string;
  waterAmount?: string;
  gasAmount?: string;
  iptuAmount?: string;
  assuranceMonthAmount?: string;
  lmi?: string;
  lmiAmount?: string;
  lmiBalance?: string;
  userId?: string;
  relation?: string;
  planId?: string;
  peopleId?: string;
  reasonId?: string;
  lessorName?: string;
  lessorCpf?: string;
  propertyId?: string;
  lessorTypePerson?: string;
  lessorBirthDate?: string;
  lessorEmail?: string;
  lessorPhone?: string;
  lessorMobilePhone?: string;
  contractBegin?: string;
  contractEnd?: string;
  renovatedId?: string;
  obs?: string;
  sinister?: any[];
  clientPlan?: {
    id?: string;
    lmi?: Number;
    residentialFee?: string;
    commercialFee?: string;
  };
  client: {
    id?: string;
    document: string;
    type_person?: string;
    status?: string;
    email?: string;
    creci?: string;
    name: string;
    trade_name?: string;
    responsible_name?: string;
    responsible_document?: string;
    phone?: string;
    mobilePhone?: string;
    address_zipcode?: string;
    address_street?: string;
    address_number?: string;
    address_complement_type?: string;
    address_complement?: string;
    address_district?: string;
    address_city?: string;
    address_state?: string;
    address_ibge?: string;
    wallet_properties_count?: string;
    accept_terms_service?: string;
    residential_fee?: string;
    commercial_fee?: string;
    created_from?: string;
    lmi?: string;
    invoice_due_date?: string;
    agency_number?: string;
    bank_code?: string;
    bank_manager?: string;
    agency_digit?: string;
    account_number?: string;
    account_digit?: string;
    pix_key?: string;
    pix_key_type?: string;
    created_at?: string;
    updated_at?: string;
    typeId?: string;
    clientType?: {
      id?: string;
      description?: string;
      status?: string;
      level?: string;
      created_at?: string;
      updated_at?: string;
    };
  };
  plan: {
    id: string;
    description: string;
    lmi: Number;
    residentialFee: string;
    commercialFee: string;
    status: string;
    created_at: string;
    updated_at: string;
    productId: string;
  };
  renovatedProposalTermBegin: Date | null;
  renovatedProposalExpiresAt: Date | null;
  renovatedProposalCreatedAt: Date | null;
}

export interface ISimulateEditProposal {
  termBegin?: string;
  expiresAt?: string;
  contractBegin?: string;
  contractEnd?: string;
  rentMonthsCount?: string;
  rentMonthsCountContract?: string;
}

export interface ISimulateEditProposalValues {
  rentAmount: string;
  fee: string;
  assuranceMonthAmount: string;
  lmi: string;
  lmiAmount: string;
  planId?: string;
}

export interface ISimulateEditProposalAddress {
  addressStreet?: string;
  addressNumber?: string;
  addressComplement?: string;
  addressDistrict?: string;
  addressCity?: string;
  addressState?: string;
  addressIBGE?: string;
}

export interface ISimulateEditLocator {
  lessorBirthDate?: string;
  lessorEmail?: string;
  lessorPhone?: string;
  lessorMobilePhone?: string;
  lessorCpf?: string;
  lessorName?: string;
  lessorTypePerson?: string;
  obs?: string;
}

export interface ISimulateEditCancel {
  canceledReason?: string;
  canceledType?: string;
  canceledAt?: string;
}

export type IProposalSimulationResult = {
  rentMonthsCount: string;
  assuranceMonthAmount: string;
  lmi: string;
  lmiAmount: string;
  termBegin: string;
  contractBegin: string;
  contractEnd: string;
  createdAt: string;
  expiresAt: string;
};

export type TProposalSimulation<T> = T | IProposal | Error;

const simulate = async (
  id: string,
  dados: ISimulateEditProposal
): Promise<any | Error> => {
  try {
    const { data } = await Api().post(
      `/proposal/simular-alteracao/vigencia/${id}`,
      dados
    );
    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
};

const simulateValue = async (
  id: string,
  dados: ISimulateEditProposalValues
): Promise<any | Error> => {
  try {
    const { data } = await Api().post(
      `/proposal/simular-alteracao/aluguel/${id}`,
      dados
    );
    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
};

const simulateAddress = async (
  id: string,
  dados: ISimulateEditProposalAddress
): Promise<any | Error> => {
  try {
    const { data } = await Api().post(
      `/proposal/simular-alteracao/endereco/${id}`,
      dados
    );
    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
};

const simulateLocator = async (
  id: string,
  dados: ISimulateEditLocator
): Promise<TProposalSimulation<any>> => {
  try {
    const { data } = await Api().patch(
      `/proposal/simular-alteracao/locator/${id}`,
      dados
    );
    if (data) return data;

    return new Promise((_, reject) =>
      reject(new Error("Erro ao atualizar registro."))
    );
  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const updateValidity = async (
  id: string,
  dados: ISimulateEditProposal
): Promise<any | Error> => {
  try {
    const { data } = await Api().post(
      `/proposal/alteracao/vigencia/${id}`,
      dados
    );
    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
};

const updateValues = async (
  id: string,
  dados: ISimulateEditProposalValues
): Promise<any | Error> => {
  try {
    const { data } = await Api().post(
      `/proposal/alteracao/aluguel/${id}`,
      dados
    );
    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
};

const updateAddress = async (
  id: string,
  dados: ISimulateEditProposalAddress
): Promise<any | Error> => {
  try {
    const { data } = await Api().post(
      `/proposal/alteracao/endereco/${id}`,
      dados
    );
    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
};

const updateLocator = async (
  id: string,
  dados: ISimulateEditLocator
): Promise<TProposalSimulation<any>> => {
  try {
    const { data } = await Api().patch(
      `/proposal/alteracao/locator/${id}`,
      dados
    );
    if (data) return data;

    return new Promise((_, reject) =>
      reject(new Error("Erro ao atualizar registro."))
    );
  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const updateCancel = async (
  id: string,
  dados: ISimulateEditCancel
): Promise<TProposalSimulation<any>> => {
  try {
    const { data } = await Api().patch(
      `/proposal/alteracao/cancel/${id}`,
      dados
    );
    if (data) return data;

    return new Promise((_, reject) =>
      reject(new Error("Erro ao atualizar registro."))
    );
  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

export const ProposalEditService = {
  simulate,
  simulateValue,
  simulateAddress,
  updateValidity,
  updateValues,
  updateAddress,
  simulateLocator,
  updateLocator,
  updateCancel,
};
