import { AxiosError } from 'axios';
import { StorageKeysEnum } from '../../../../enums';


export const errorInterceptor = (error: AxiosError) => {

  if (error.message === 'Network Error') {
    return Promise.reject(new Error('Erro de conexão.'));
  }

  if (error.response?.status === 401) {
    localStorage.removeItem(StorageKeysEnum.AccessToken);
    window.location.assign(window.location.protocol + "//" + window.location.host + "/auth/login");
    //window.location.reload();
    return Promise.reject(error);
  }

  return Promise.reject(error);
};
