import {AuthGuard} from '../../shared/guards/AuthGuard';
import {ProposalDetailPresentation} from './components';
import {Sidebar} from '../../shared/components';
import { ProposalRenovatePresentation } from './components/ProposalRenovate';

export const ProposalRenovatePage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <ProposalRenovatePresentation/>
            </Sidebar>
        </AuthGuard>
    )
};