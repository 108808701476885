import { styled, Typography } from '@mui/material';
import { Box } from '@mui/system';

type HideProps = {
  hide: boolean
}
export const StyledUploadAreaContainerBox = styled(Box)((props: HideProps) => {
  return {
      position: 'relative',
      transition: 'opacity linear 0.25s, z-index linear 0.25s',
      width: '25vw',
      minWidth: '100%',
      marginTop: '16px',
      zIndex: 1,
      '&:hover': {
        cursor: props.hide ? 'cursor' : 'pointer',
        opacity: props.hide ? 1 : 0.75,
        zIndex: props.hide ? -1 : 1,
      }
  }
})

export const StyledUploadAreaBox = styled(Box)((props: HideProps) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '200px',
    padding: '8px',
    transition: 'border-color linear 0.25s',
    border: '2px dashed rgba(233, 184, 1, 1)',
    borderColor: props.hide ? 'rgba(233, 184, 1, 0)' : 'rgba(233, 184, 1, 1)',
    borderRadius: '5px'
  }
})

export const StyledGreyedTypography = styled(Typography)((props: HideProps) => {
  return {
    transition: 'opacity linear 0.25s',
    opacity: props.hide ? 0 : 0.9,
    fontSize: '0.9rem'
  }
})

export const StyledNormalTypography = styled(Typography)((props: HideProps) => {
  return {
    transition: 'opacity linear 0.25s',
    opacity: props.hide ? 0 : 1
  }
})

export const StyledIconBox = styled(Box)((props: HideProps) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity linear 0.25s',
    opacity: props.hide ? 0 : 1
  }
})

export const StyledSpinnerBox = styled(Box)((props: HideProps) => {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    transition: 'opacity linear 0.25s, z-index linear 0.25s',
    opacity: props.hide ? 0 : 1,
    pointerEvents: 'none'
  }
})

type UploadedFileBoxProps = {
  height: number;
}

export const StyledUploadedFileContainerBox = styled(Box)((props: HideProps) => {
  return {
    position: 'relative',
    transition: 'opacity linear 0.25s',
    width: '25vw',
    marginTop: '16px',
    '&:hover': {
      cursor: props.hide ? 'cursor' : 'pointer',
      opacity: props.hide ? 1 : 0.75
    }
  }
})
export const StyledUploadedFileBox = styled(Box)((props: UploadedFileBoxProps & HideProps) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
    padding: '8px',
    transition: 'border-color linear 0.25s',
    border: '2px dashed rgba(30, 47, 151, 1)',
    borderColor: props.hide ? 'rgba(30, 47, 151, 0)' : 'rgba(30, 47, 151, 1)',
    borderRadius: '5px'
  }
})

export const StyledUploadedFileIconBox = styled(Box)((props: HideProps) => {
  return {
    marginTop: '16px',
    marginDown: '16px',
    transition: 'opacity linear 0.25s',
    opacity: props.hide ? 0 : 1,
    '& svg': {
      width: '100% !important',
      height: '100% !important'
    }
  }
})
