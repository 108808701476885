import {
	Autocomplete,
	Box,
	Button,
	FormControl,
	Grid,
	Icon,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	TextField,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { format, subDays } from "date-fns"; // Importe as funções do date-fns
import { Clear } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { useProfile } from "../../../../context/ProfileContext";
import { ToastContent } from "../../../../shared/components";
import { useToast } from "../../../../shared/hooks";
import { clientService, IListagemCliente } from "../../../../shared/services/api/client/clientService";
import { IListagemUsuario, userService } from "../../../../shared/services/api/user/userService";
import { returnMessageError } from "../../../../shared/utils/returnMessageError";
import { ListParamsProposal } from "../../types/listParamsProposal.type";
import { checkByProfileGuard } from "../../../../shared/guards/ProfileGuard";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../../shared/enums/profiles";

export type TAutoCompletOptions = {
	id: string;
	label: string;
};

interface IToolbarProposalProps {
	showInputMananger?: boolean;
	showInputClient?: boolean;
	showBeetweenDate?: boolean;
	showInputDate?: boolean;
	showInputSearch?: boolean;
	showButtonNew?: boolean;
	showCreatedFrom?: boolean;
	showTransferButton?: boolean;

	isLoading?: boolean;

	onChangeInputMananger?: (text: string) => void;
	onChangeInputClient?: (text: string) => void;
	onChangeBeetweenDate?: (text: string) => void;
	onChangeInputDate?: (text: string) => void;
	onChangeCreatedFrom?: (text: string) => void;
	onChangeFilterField?: (text: string) => void;
	onClickButtonNew?: () => void;

	filterInitialParams?: ListParamsProposal;
	textButtonNew?: string;
	textSearch?: string;
	onChangeTextSearch?: (newText: string) => void;
	onClickClear?: () => void;
	onclickSearch?: () => void;
	onChangeDataIni?: (text: string) => void;
	onChangeDataFim?: (text: string) => void;
	onclickTransfer?: () => void;
}

export const ToolbarProposal: React.FC<IToolbarProposalProps> = ({
	showInputMananger,
	showInputClient,
	showBeetweenDate,
	showInputDate,
	showCreatedFrom,
	showButtonNew,
	onClickButtonNew,
	onChangeInputMananger,
	onChangeInputClient,
	onChangeInputDate,
	onChangeCreatedFrom,
	showInputSearch,
	filterInitialParams,
	onChangeTextSearch,
	onChangeFilterField,
	textButtonNew,
	onClickClear,
	onclickSearch,
	onChangeDataIni,
	showTransferButton,
	onclickTransfer,
	onChangeDataFim,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const { userClientId, isSuperAdmin, permissionLevel, registerClient } = useProfile();
	const mdDown = useMediaQuery(theme.breakpoints.down("md"));
	const [selectedIdManager, setSelectedIdManager] = useState<string | undefined>();
	const [selectedIdClient, setSelectedIdClient] = useState<string | undefined>();
	const [opcoesManager, setOpcoesManager] = useState<TAutoCompletOptions[]>([]);
	const [opcoesClient, setOpcoesClient] = useState<TAutoCompletOptions[]>([]);
	const [nameManager, setNameManager] = useState<string>("");
	const [nameClient, setNameClient] = useState<string>("");
	const [createdFrom, setCreatedFrom] = useState(filterInitialParams?.createFrom ?? "Todos");
	const [filterField, setFilterField] = useState(filterInitialParams?.filterField ?? "documento");
	const [data, setData] = useState(filterInitialParams?.data ?? "7");
	const [dataIni, setDataIni] = useState(filterInitialParams?.beginDate);
	const [dataFim, setDataFim] = useState(filterInitialParams?.endDate);
	const [textButtonSearch, setTextButtonSearch] = useState<string>("Filtrar");
	const [iconButtonSearch, setIconButtonSearch] = useState<string>("filter_list");
	const [inputTextValue, setInputTextValue] = useState("");

	const toastHandler = useToast();

	const autoCompleteSelectedManager = useMemo(() => {
		if (!selectedIdManager) return null;
		const selectedOption = opcoesManager.find((opcao) => opcao.id === selectedIdManager);
		if (!selectedOption) return null;
		return selectedOption;
	}, [selectedIdManager, opcoesManager]);

	const autoCompleteSelectedClient = useMemo(() => {
		if (!selectedIdClient) return null;
		const selectedOption = opcoesClient.find((opcao) => opcao.id === selectedIdClient);
		if (!selectedOption) return null;
		return selectedOption;
	}, [selectedIdClient, opcoesClient]);

	const handleChangeOrigem = (event: SelectChangeEvent) => {
		setCreatedFrom(event.target.value as string);
		onChangeCreatedFrom?.(event.target.value as string);
	};

	const handleChangeData = (event: SelectChangeEvent) => {
		const selectedValue = event.target.value as string;
		setData(selectedValue);
		onChangeInputDate?.(selectedValue);

		// Atualiza as datas com base na opção selecionada
		const today = new Date();
		let startDate = format(today, "yyyy-MM-dd"); // Data inicial como hoje, por padrão
		let endDate = format(today, "yyyy-MM-dd"); // Data final como hoje, por padrão

		switch (selectedValue) {
			case "hoje":
				startDate = format(today, "yyyy-MM-dd");
				endDate = format(today, "yyyy-MM-dd");
				break;
			case "7":
				startDate = format(subDays(today, 7), "yyyy-MM-dd");
				endDate = format(today, "yyyy-MM-dd");
				break;
			case "15":
				startDate = format(subDays(today, 15), "yyyy-MM-dd");
				endDate = format(today, "yyyy-MM-dd");
				break;
			case "30":
				startDate = format(subDays(today, 30), "yyyy-MM-dd");
				endDate = format(today, "yyyy-MM-dd");
				break;
			case "60":
				startDate = format(subDays(today, 60), "yyyy-MM-dd");
				endDate = format(today, "yyyy-MM-dd");
				break;
			case "mes":
				startDate = format(new Date(today.getFullYear(), today.getMonth(), 1), "yyyy-MM-dd"); // Primeiro dia do mês atual
				endDate = format(new Date(today.getFullYear(), today.getMonth() + 1, 0), "yyyy-MM-dd"); // Último dia do mês atual
				break;
			case "ano":
				startDate = format(subDays(today, 365), "yyyy-MM-dd");
				endDate = format(today, "yyyy-MM-dd");
				break;
			case "periodo":
				startDate = format(subDays(today, 30), "yyyy-MM-dd");
				endDate = format(today, "yyyy-MM-dd");
				break;
			default:
				break;
		}

		setDataIni(startDate);
		setDataFim(endDate);
		onChangeDataIni?.(startDate);
		onChangeDataFim?.(endDate);
	};

	const handleChangeFilterField = (event: SelectChangeEvent) => {
		setFilterField(event.target.value as string);
		onChangeFilterField?.(event.target.value as string);
	};

	useEffect(() => {
		onChangeDataFim?.(dataFim ?? "");
		onChangeDataIni?.(dataIni ?? "");
	}, [dataIni, dataFim]);

	useEffect(() => {
		if (inputTextValue) {
			setTextButtonSearch("Pesquisar");
			setIconButtonSearch("search");
		} else {
			setTextButtonSearch("Filtrar");
			setIconButtonSearch("filter_list");
		}
	}, [inputTextValue]);

	useEffect(() => {
		if (isSuperAdmin || permissionLevel == 2) {
			setIsLoading(true);
			userService
				.getAdminManagers(nameManager)
				.then((result: any) => {
					setIsLoading(false);
					if (result instanceof Error) {
						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={"Erro"} content={"Erro: " + result?.message} />,
						});
					} else {
						const options = result?.data?.map((user: IListagemUsuario) => ({ id: user.id, label: user.name }));
						setOpcoesManager(options);
					}
				})
				.catch((err: any) => {
					setIsLoading(false);
					const { titleMessage, contentMessage } = returnMessageError(err);
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				});
		}
	}, [nameManager]);

	useEffect(() => {
		if (isSuperAdmin || permissionLevel == 2 || permissionLevel == 10) {
			setIsLoading(true);
			clientService
				.getSelect(1, nameClient.replace("(Inativo)", "")?.trim(), "", "", "", isSuperAdmin || permissionLevel == 2 ? "" : userClientId)
				.then((result: any) => {
					setIsLoading(false);
					if (result instanceof Error) {
						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={"Erro"} content={"Erro: " + result?.message} />,
						});
					} else {
						const options = result?.data?.map((user: IListagemCliente) => ({ id: user.id, label: user.name }));
						setOpcoesClient(options);
					}
				})
				.catch((err: any) => {
					setIsLoading(false);
					const { titleMessage, contentMessage } = returnMessageError(err);
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				});
		}
	}, [nameClient]);

	return (
		<Box gap={1} marginX={1} paddingY={1} paddingX={2} display="flex" flexDirection={smDown ? "column" : "row"} alignItems="center" component={Paper}>
			<Grid container item direction={smDown ? "column" : "row"} gap={1}>
				{showInputMananger && (
					<FormControl sx={{ minWidth: "300px" }} fullWidth={mdDown ? true : false}>
						<Autocomplete
							fullWidth
							openText="Abrir"
							closeText="Fechar"
							noOptionsText="Nenhuma opção"
							loadingText="Loading ..."
							disablePortal
							value={autoCompleteSelectedManager}
							options={opcoesManager}
							loading={isLoading}
							size={"small"}
							onInputChange={(_, newValue) => {
								setNameManager(newValue);
							}}
							onChange={(_, value: any) => {
								setSelectedIdManager(value?.id ? value?.id : "");
								onChangeInputMananger?.(value?.id ? value?.id : ("" as string));
							}}
							renderInput={(params) => <TextField {...params} label={"Gestor"} name="gestor" />}
						/>
					</FormControl>
				)}

				{showInputClient && (
					<>
						<FormControl sx={{ minWidth: "330px" }} fullWidth={mdDown ? true : false}>
							<Autocomplete
								fullWidth
								openText="Abrir"
								closeText="Fechar"
								noOptionsText="Nenhuma opção"
								loadingText="Loading ..."
								disablePortal
								value={autoCompleteSelectedClient}
								options={opcoesClient}
								loading={isLoading}
								size={"small"}
								onInputChange={(_, newValue) => {
									setNameClient(newValue);
								}}
								onChange={(_, value: any) => {
									setSelectedIdClient(value?.id ? value?.id : "");
									onChangeInputClient?.(value?.id ? value?.id : ("" as string));
								}}
								renderInput={(params) => <TextField {...params} label={"Cliente"} name="client" />}
							/>
						</FormControl>
					</>
				)}

				{showCreatedFrom && (
					<FormControl sx={{ minWidth: "150px" }} fullWidth={mdDown ? true : false}>
						<InputLabel id="id_origem">Origem</InputLabel>
						<Select labelId="label_Origem" id="id_origem" value={createdFrom} size="small" label="Origem" onChange={handleChangeOrigem}>
							<MenuItem value={"Todos"}>Todos</MenuItem>
							<MenuItem value={"web_app"} sx={{ color: "#8446d6" }}>
								Garantti
							</MenuItem>
							<MenuItem value={"web_service"}>Integração</MenuItem>
						</Select>
					</FormControl>
				)}

				{showInputDate && (
					<FormControl sx={{ minWidth: "150px" }} fullWidth={mdDown ? true : false}>
						<InputLabel id="id_data">Data</InputLabel>
						<Select labelId="label_data" id="id_data" value={data} size="small" label="Data" onChange={handleChangeData}>
							<MenuItem value={"hoje"}>Hoje</MenuItem>
							<MenuItem value={"7"}>Últimos 7 dias</MenuItem>
							<MenuItem value={"15"}>Últimos 15 dias</MenuItem>
							<MenuItem value={"30"}>Últimos 30 dias</MenuItem>
							<MenuItem value={"60"}>Últimos 60 dias</MenuItem>
							<MenuItem value={"ano"}>Último Ano</MenuItem>
							<MenuItem value={"mes"}>Mês Atual</MenuItem>
							<MenuItem value={"periodo"} sx={{ color: "#a268e4" }}>
								Período
							</MenuItem>
						</Select>
					</FormControl>
				)}

				{/* {showInputDate && showBeetweenDate && ( */}
				{showInputDate && showBeetweenDate && data === "periodo" && (
					<FormControl sx={{ minWidth: "130px" }} fullWidth={mdDown ? true : false}>
						<Box display={"flex"} gap={1}>
							<TextField
								fullWidth
								label="Data Inicial"
								name="datainicial"
								size="small"
								type="date"
								value={dataIni}
								onChange={(e) => (setDataIni(e.target.value), onChangeDataIni?.(e.target.value))}
							></TextField>

							<TextField
								fullWidth
								label="Data Final"
								name="datafinal"
								size="small"
								type="date"
								value={dataFim}
								onChange={(e) => (setDataFim(e.target.value), onChangeDataFim?.(e.target.value))}
							></TextField>
						</Box>
					</FormControl>
				)}

				{showInputSearch && (
					<Box display="flex" flexDirection={mdDown ? "column" : "row"} gap={1}>
						<FormControl sx={{ minWidth: "150px" }} fullWidth={mdDown ? true : false}>
							<InputLabel id="filterField">Pesquisar por</InputLabel>
							<Select
								labelId="filterField"
								id="filterField"
								value={filterField}
								size="small"
								label="Pesquisar por"
								onChange={handleChangeFilterField}
							>
								<MenuItem value={"documento"}>Documento Locatario</MenuItem>
								<MenuItem value={"nome"}>Nome Locatario</MenuItem>
								<MenuItem value={"documentoLocator"}>Documento Locator</MenuItem>
								<MenuItem value={"nomeLocator"}>Nome Locator</MenuItem>
							</Select>
						</FormControl>

						<FormControl sx={{ minWidth: "400px" }} fullWidth={mdDown ? true : false}>
							<TextField
								fullWidth
								label="Pesquisar"
								name="search"
								size="small"
								value={inputTextValue}
								onChange={(e) => {
									onChangeTextSearch?.(e.target.value), setInputTextValue(e.target.value);
								}}
								InputProps={{
									endAdornment: (
										<>
											{" "}
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													edge="end"
													sx={{ color: "#dbd5d5" }}
													onClick={() => {
														onChangeTextSearch?.(""), onClickClear, setInputTextValue("");
													}}
												>
													<Clear />
												</IconButton>
											</InputAdornment>
										</>
									),
								}}
							></TextField>
						</FormControl>

						<Box display="flex" justifyContent="start">
							<Button
								color={inputTextValue.length > 1 ? "secondary" : "primary"}
								disableElevation
								variant="contained"
								onClick={onclickSearch}
								startIcon={<Icon>{iconButtonSearch}</Icon>}
							>
								{textButtonSearch}
							</Button>
						</Box>
					</Box>
				)}
			</Grid>

			<Box display="flex" gap={1} justifyItems={"end"} minWidth={"260px"}>
				{showTransferButton &&
					registerClient.walletTransfer &&
					checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.WalletTransfer) && (
						<Box display="flex" justifyContent="end">
							<Button
								color="primary"
								disableElevation
								variant="outlined"
								onClick={onclickTransfer}
								startIcon={<Icon sx={{ marginRight: "1px" }}>swap_horiz</Icon>}
							>
								Portabilidade
							</Button>
						</Box>
					)}

				{showButtonNew && (
					<Box display="flex" justifyContent="end">
						<Button color="primary" disableElevation variant="contained" onClick={onClickButtonNew} startIcon={<Icon>add</Icon>}>
							{textButtonNew}
						</Button>
					</Box>
				)}
			</Box>
		</Box>
	);
};
