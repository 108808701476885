import { AccountCircleOutlined, Clear, Search } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Icon, IconButton, InputAdornment, Paper, TextField, useTheme } from "@mui/material";

import { Environment } from "../../environment";

interface IToolbarGridProvisionProps {
    textSearchGrid?: string;
    showInputBuscaGrid?: boolean;
    onChangeTextBuscaGrid?: (newText: string) => void;
    aoLimparTextBuscaGrid?: (textEmpty: string) => void;

    textButtonAberto?: string;
    showBotaoAberto?: boolean;
    onClickAberto?: () => void;
    selectAberto?: boolean;

    textButtonProvision?: string;
    showBotaoProvision?: boolean;
    onClickProvision?: () => void;
    selectProvision?: boolean;

    textButtonPaid?: string;
    showBotaoPaid?: boolean;
    onClickPaid?: () => void;
    selectPaid?: boolean;

    textButtonTodos?: string;
    showBotaoTodos?: boolean;
    onClickTodos?: () => void;
    selectTodos?: boolean;

    textButtonCancel?: string;
    showBotaoCancel?: boolean;
    onClickCancel?: () => void;
    selectCancel?: boolean;
}

export const ToolbarGridProvision: React.FC<IToolbarGridProvisionProps> = ({
    textSearchGrid: textSearch = '',
    showInputBuscaGrid: showInputBusca = true,
    onChangeTextBuscaGrid: onChangeTextBusca,

    textButtonAberto = 'Aberto',
    showBotaoAberto = true,
    onClickAberto,
    selectAberto = false,

    textButtonProvision = 'Provisionados',
    showBotaoProvision = true,
    onClickProvision,
    selectProvision = false,

    textButtonPaid = 'Pagos',
    showBotaoPaid = true,
    onClickPaid,
    selectPaid = false,

    textButtonCancel = 'Canceladas',
    showBotaoCancel = true,
    onClickCancel,
    selectCancel = false,

    textButtonTodos = 'Todos',
    showBotaoTodos = true,
    onClickTodos,
    selectTodos = false,

}) => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            alignItems="start"
            height={theme.spacing(6)}
        >
            <ButtonGroup aria-label="outlined primary button group">
                {showBotaoTodos && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectTodos ? "contained" : "outlined"}
                        onClick={onClickTodos}
                        startIcon={<Icon>done_all_icon</Icon>}
                    >
                        {textButtonTodos}
                    </Button>
                )}

                {showBotaoAberto && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectAberto ? "contained" : "outlined"}
                        onClick={onClickAberto}
                        startIcon={<Icon>arrow_forward_outlineOutlinedIcon</Icon>}
                    >
                        {textButtonAberto}
                    </Button>
                )}

                {showBotaoProvision && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectProvision ? "contained" : "outlined"}
                        onClick={onClickProvision}
                        startIcon={<Icon>portrait_outlined_icon</Icon>}
                    >
                        {textButtonProvision}
                    </Button>
                )}
                {showBotaoPaid && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectPaid ? "contained" : "outlined"}
                        onClick={onClickPaid}
                        startIcon={<Icon>done_all_outlineOutlinedIcon</Icon>}
                    >
                        {textButtonPaid}
                    </Button>
                )}

                {showBotaoCancel && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectCancel ? "contained" : "outlined"}
                        onClick={onClickCancel}
                        startIcon={<Icon> cancel_presentation_outlinedIcon</Icon>}
                    >
                        {textButtonCancel}
                    </Button>
                )}

            </ButtonGroup>

            <Box flex={1} display="flex" justifyContent="end">
                {showInputBusca && (
                    <TextField
                        size="small"
                        value={textSearch}
                        placeholder={Environment.INPUT_BUSCA}
                        onChange={(e) => onChangeTextBusca?.(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        // onClick={handleClickShowPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="start"
                                    >
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        // onClick={handleClickShowPassword}
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        sx={{ color: "#dbd5d5" }}
                                    >
                                        <Clear />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            </Box>
        </Box >
    );
}; 