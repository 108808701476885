import { Box, Button, Grid, Icon, LinearProgress, MenuItem, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { IVFormErros, useVForm, VForm, VTextField } from "../../../../../shared/forms";
import { useToast } from "../../../../../shared/hooks";
import { ProposalDocumentsService } from "../../../../../shared/services/api/proposal/ProposalDocumentsService";
import { ICancelProposalConclude, ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";
import { cancelReasons } from "../helpers/reasonCancel";

interface ModalCancelProps {
	isClose: () => void;
	isOpenModal: boolean;
	rows: any;
}

export const ModalConcludeCancelProposal: React.FC<ModalCancelProps> = ({ isClose, isOpenModal, rows }) => {
	const toastHandler = useToast();
	const navigate = useNavigate();
	const [file, setFile] = useState<File>();
	const [isLoading, setIsLoading] = useState(false);
	const { formRef } = useVForm();

	const formValidationSchemaCancelConclude: yup.SchemaOf<ICancelProposalConclude> = yup.object().shape({
		confirmCanceledAt: yup.string().required("Campo Obrigatório"),
		confirmCanceledType: yup.string().required("Campo Obrigatório"),
		confirmCanceledReason: yup.string().required("Campo Obrigatório"),
	});

	const handleFileUpload = async (proposalId: string, dados: ICancelProposalConclude) => {
		if (!file) return;

		try {
			const resultFile = await ProposalDocumentsService.uploadProposalDocument(proposalId, "cd8a1b83-35ec-4dfc-a837-513e494007e8", file);
			if (resultFile.status === 400) {
				throw new Error(resultFile.data.message);
			}

			toastHandler.present({
				type: "success",
				messageOrContent: "Arquivo enviado com sucesso!",
				position: "top-center",
			});

			await ProposalService.updateConfirmCancelId(proposalId, { ...dados });
		} catch (error: any) {
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={"Erro Arquivo"} content={"Erro ao enviar Arquivo | " + error.message} />,
			});
		}
	};

	const handleCancelProposalConclude = async (dados: ICancelProposalConclude) => {
		try {
			await formValidationSchemaCancelConclude.validate(dados, { abortEarly: false });

			setIsLoading(true);
			await handleFileUpload(rows.id, dados);

			const result = await ProposalService.updateConfirmCancelId(rows.id, { ...dados });
			setIsLoading(false);

			if (result instanceof Error) {
				throw new Error(result.message);
			}

			toastHandler.present({
				type: "success",
				position: "top-right",
				messageOrContent: <ToastContent title={"Sucesso"} content={"Cancelamento efetivado com sucesso"} />,
			});
			navigate(`/garantias/`);
		} catch (error: any) {
			if (error instanceof yup.ValidationError) {
				const validationErrors: IVFormErros = {};
				error.inner.forEach((err) => {
					if (!err.path) return;
					validationErrors[err.path] = err.message;
				});
				formRef.current?.setErrors(validationErrors);
			} else {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={error.message} />,
				});
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{isOpenModal && (
				<ModalContext
					isCloseModal={isClose}
					isOpenModal={isOpenModal}
					messageType={"question"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					textButtonCancel={"Voltar"}
					ITitle={"Deseja finalizar o cancelamento de garantia ?"}
					IMessage={[
						<Box key="modal-cancel" style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									ref={formRef}
									onSubmit={handleCancelProposalConclude}
									onPointerEnterCapture={() => {}}
									onPointerLeaveCapture={() => {}}
									placeholder=""
								>
									<Grid container direction="column" padding={1} spacing={2}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}

										<Typography mb={3} color={"red"}>
											{`Data informada pela imobiliária para o término do Contrato de garantia  ${
												rows?.canceledAt ? format(new Date(rows.canceledAt + " 03:00:00"), "dd/MM/yyyy") : "Data não informada"
											}`}
										</Typography>
										<Typography mb={3} color={"red"}>
											A alteração da data de confirmação de cancelamento do contrato de garantia afetará as parcelas a serem canceladas
										</Typography>

										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12} md={8}>
												<VTextField
													fullWidth
													label="Motivo do cancelamento"
													name="confirmCanceledType"
													disabled={isLoading}
													defaultValue="Outros"
													select
												>
													{cancelReasons.map((reason, index) => (
														<MenuItem key={index} value={reason.value}>
															{reason.label}
														</MenuItem>
													))}
												</VTextField>
											</Grid>
											<Grid item xs={12} md={4}>
												<VTextField
													fullWidth
													label="Data para o término do Contrato de garantia para efeito de cancelamento da garantia"
													name="confirmCanceledAt"
													disabled={false}
													type="date"
												/>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12}>
												<VTextField
													fullWidth
													label="Descreva o motivo"
													name="confirmCanceledReason"
													disabled={isLoading}
													multiline
													rows={4}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid container sx={{ display: "flex", justifyContent: "center", gap: 2, padding: 2 }}>
										<Button color="inherit" disableElevation variant={"contained"} startIcon={<Icon>arrow_back</Icon>} onClick={isClose}>
											{"Voltar"}
										</Button>
										<Button
											color="primary"
											disableElevation
											variant={"contained"}
											startIcon={<Icon>check</Icon>}
											onClick={() => formRef.current?.submitForm()}
										>
											{"Concluir Cancelamento"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}
		</>
	);
};
