import { PermissionActionsEnum, PermissionRolesEnum } from "../enums/profiles";
import { PermissionRolesType } from "../types";

export const validationPermission = (
  permissions: PermissionRolesType | null,
  requiredRole: PermissionRolesEnum,
  requiredAction: PermissionActionsEnum
): boolean => {
  if (!permissions) return false;

  const foundRole = permissions[requiredRole];

  if (!foundRole || !foundRole[requiredAction]) return false;

  return true;
};
