import { Api } from '../axios-config';
import { Environment } from '../../../environment';

export interface IAuth {
    accessToken: string;
}

const auth = async (email: string, password: string): Promise<IAuth> => {
  const { data }  = await Api({ disableResponseInterceptor: true }).post('/auth/login', { email, password });

  return data as IAuth;
};

const refreshAccessToken = async (accessToken: string): Promise<IAuth> => {
  const { data } = await Api()
    .put(`${Environment.URL_BASE}/auth/refresh`,
      { refreshToken: accessToken },
      { headers:
          {
            'Authorization': `Bearer ${accessToken}`
          }
      });

  return {
    accessToken: data.accessToken
  }
}

export const AuthService = {
    auth,
    refreshAccessToken
};

