import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { DialogContent, DialogTitle, Icon } from '@mui/material';

const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: 'auto',
    maxHeight: '85%',
    bgcolor: 'background.paper',
    border: '1px solid #605e5e',
    boxShadow: 24,
    overflow: 'auto',
    p: 2,
    borderRadius: '6px',
    display: "flex",
    flexDirection: 'column',
    backgroundColor: '#FFF'

};

const typeMessage: ITypeMessage[] = [
    {
        type: 'error',
        title: 'Erro',
        icon: 'cancel_Outlined_Icon',
        color: '#e87b9e'
    },
    {
        type: 'alert',
        title: 'Atenção',
        icon: 'info_Outlined_Icon',
        color: '#ffc107'
    },
    {
        type: 'success',
        title: 'Sucesso',
        icon: 'check_circle_Outline_Icon',
        color: '#39c463'
    },
    {
        type: 'info',
        title: 'Informação',
        icon: 'error_outline_Outlined_Icon',
        color: '#7924c7 '
    },
];

interface ITypeMessage {
    title: string;
    icon: string;
    color: string;
    type: string;
}

interface IResultAnalysisProps {
    ITitle?: string;
    IMessage: React.ReactNode[];
    textButtonConfirm?: string;
    showButtonCancel?: boolean;
    isOpenModal: boolean;
    messageType: string;
    onclickConfirm?: () => void;
    isCloseModal?: () => void;
    overrideWidthValue?: string;
    overrideHeightValue?: string;
}

export const ModalResultAnalysis: React.FC<IResultAnalysisProps> = ({ ITitle, IMessage, overrideWidthValue, overrideHeightValue,
    isOpenModal, messageType, isCloseModal, onclickConfirm }) => {

    const setTypeMessage = () => {
        switch (messageType) {
            case 'error':
                return typeMessage[0];
            case 'alert':
                return typeMessage[1];
            case 'success':
                return typeMessage[2];
            case 'info':
                return typeMessage[3];
            default: return typeMessage[0];
        }
    }

    const messageStyle: ITypeMessage = setTypeMessage();


    return (
        <div>
            <Modal
                open={isOpenModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: overrideWidthValue ? overrideWidthValue : style.width, height: overrideHeightValue ? overrideHeightValue : style.height }}>
                    <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Icon style={{ color: `${messageStyle.color}`, fontSize: "3rem", fontWeight: 100 }}>
                            {messageStyle.icon}
                        </Icon>
                        <Typography
                            id="modal-modal-title"
                            variant="h4"
                            sx={{ fontWeight: 300 }}
                            color={`${messageStyle.color}`}
                        >
                            {ITitle}
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                        {IMessage}
                    </DialogContent>
                </Box>
            </Modal>
        </div>
    );
}

