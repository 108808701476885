import { createContext, useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { AuthService, IAuth } from '../shared/services/api/auth/AuthService';
import { decodeJwt } from '../shared/utils';
import { JwtPayloadType } from '../shared/services/api/auth/types/jwt-payload.type';
import { StorageKeysEnum } from '../shared/enums';

interface IAuthContextData {
  appIsInitialized: boolean;
  logout: () => void;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<string | void>;
  handleRefreshToken: () => Promise<void>;
  accessToken: string | null;

  loggedUser: LoggedUser | null;
}

type LoggedUser = {
  id: string;
  name: string;
  role: string;
  email: string;
  permission_description: string;
}

const AuthContext = createContext({} as IAuthContextData);
interface IAuthProviderProps {
  children: React.ReactNode;
}
export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [loggedUser, setLoggedUser] = useState<LoggedUser | null>(null);
  const [appIsInitialized, setAppIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    const accessToken = localStorage.getItem(StorageKeysEnum.AccessToken);

    if (accessToken && accessToken != "undefined") {
      setAccessToken(accessToken);
    } else {
      setAccessToken(null);
      setLoggedUser(null);
    }

    setAppIsInitialized(true)
  }, []);

  useEffect(() => {
    if (accessToken && accessToken != "undefined") {
      const decoded: JwtPayloadType = decodeJwt(accessToken);

      const isAdminGarantti = decoded.permission_id == "1" && decoded.permission_level == 1;

      setLoggedUser({
        name: decoded.user_name,
        id: decoded.user_id,
        email: decoded.user_email,
        role: decoded.user_role,
        permission_description: isAdminGarantti ? `${decoded.permission_description} Garantti` : decoded.permission_description,
      })
    }
  }, [accessToken]);

  const handleLogin = useCallback(async (email: string, password: string) => {
    const result: IAuth = await AuthService.auth(email, password);
    localStorage.clear();
    localStorage.setItem(StorageKeysEnum.AccessToken, result.accessToken);

    if (result) {
      setAccessToken(result.accessToken);
    }
  }, []);

  const handleRefreshToken = useCallback(async () => {
    const storageToken: string | null = localStorage.getItem(StorageKeysEnum.AccessToken);

    const refreshToken: string = accessToken ? accessToken : storageToken ? storageToken : '';

    setAccessToken(refreshToken);


    const result: IAuth = await AuthService.refreshAccessToken(refreshToken);
    localStorage.setItem(StorageKeysEnum.AccessToken, result.accessToken);

    setAccessToken(result.accessToken);

  }, [accessToken]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem(StorageKeysEnum.AccessToken);
    setAccessToken(null);
  }, []);

  const isAuthenticated = useMemo(() => !!accessToken, [accessToken]);

  const memoizedIsInitialized = useMemo(() => appIsInitialized, [appIsInitialized]);

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      login: handleLogin,
      logout: handleLogout,
      accessToken,
      handleRefreshToken,
      loggedUser,
      appIsInitialized: memoizedIsInitialized
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
