import { Autocomplete, Box, Button, CircularProgress, FormControl, Grid, Icon, Paper, TextField, Tooltip, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useProfile } from "../../../context/ProfileContext";
import { ListSearchParams } from "../../../pages/analysis/types/list-search-params.type";
import { ToastContent } from "../../../shared/components";
import { useToast } from "../../../shared/hooks";
import { clientService } from "../../services/api/client/clientService";
import { returnMessageError } from "../../utils/returnMessageError";

export type TAutoCompleteOption = {
	id: string;
	label: string;
};

interface IToolTransferClientsProps {
	showButtonBack?: boolean;
	showButtonBackLoading?: boolean;
	clickBack?: () => void;

	showDate?: boolean;
	onChangeDate?: (value: any) => void;

	showInputClientFilter?: boolean;
	onChangeClientFilter?: (text: string) => void;
	onChangeClientFilterName?: (text: string) => void;

	showInputClientFilter2?: boolean;
	onChangeClientFilter2?: (text: string) => void;
	onChangeClientFilterName2?: (text: string) => void;

	filterInitialParams?: ListSearchParams;

	showTransfer?: boolean;
	onClickTransfer?: () => void;
	loading?: boolean | undefined;
}

export const ToolbarTransferClients: React.FC<IToolTransferClientsProps> = ({
	loading = false,
	showInputClientFilter = true,
	onChangeClientFilter,
	onChangeClientFilterName,

	showInputClientFilter2 = true,
	onChangeClientFilter2,
	onChangeClientFilterName2,

	showTransfer = true,
	onClickTransfer,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
	const [opcoes2, setOpcoes2] = useState<TAutoCompleteOption[]>([]);
	const [selectedId, setSelectedId] = useState<string | undefined>();
	const [selectedId2, setSelectedId2] = useState<string | undefined>();
	const [busca, setBusca] = useState("");
	const [busca2, setBusca2] = useState("");
	const { userClientId, isSuperAdmin, permissionLevel } = useProfile();
	const toastHandler = useToast();
	const theme = useTheme();

	useEffect(() => {
		setIsLoading(true);
		clientService
			.getSelect(1, busca, "", "", "active", isSuperAdmin || permissionLevel == 2 ? "" : userClientId)
			.then((result: any) => {
				setIsLoading(false);
				if (result instanceof Error) {
					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				} else {
					const options = result?.data?.map((client: any) => ({ id: client.id, label: client.name }));

					if ((!isSuperAdmin || permissionLevel == 2) && options?.length == 1) {
						setSelectedId(options[0].id);
					}

					setOpcoes(options);
				}
			})
			.catch((err: any) => {
				setIsLoading(false);
				const { titleMessage, contentMessage } = returnMessageError(err);

				toastHandler.present({
					type: "error",
					position: "top-right",
					durationMs: 4000,
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			});
	}, [busca]);

	useEffect(() => {
		setIsLoading(true);
		clientService
			.getSelect(1, busca2, "", "", "active", isSuperAdmin || permissionLevel == 2 ? "" : userClientId)
			.then((result: any) => {
				setIsLoading(false);
				if (result instanceof Error) {
					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				} else {
					const options = result?.data?.map((client: any) => ({ id: client.id, label: client.name }));

					if ((!isSuperAdmin || permissionLevel == 2) && options?.length == 1) {
						setSelectedId2(options[0].id);
					}

					setOpcoes2(options);
				}
			})
			.catch((err: any) => {
				setIsLoading(false);
				const { titleMessage, contentMessage } = returnMessageError(err);

				toastHandler.present({
					type: "error",
					position: "top-right",
					durationMs: 4000,
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			});
	}, [busca2]);

	const autoCompleteSelectedOption = useMemo(() => {
		if (!selectedId) return null;
		const selectedOption = opcoes.find((opcao) => opcao.id === selectedId);
		if (!selectedOption) return null;
		return selectedOption;
	}, [selectedId, opcoes]);

	const autoCompleteSelectedOption2 = useMemo(() => {
		if (!selectedId2) return null;
		const selectedOption = opcoes2.find((opcao) => opcao.id === selectedId2);
		if (!selectedOption) return null;
		return selectedOption;
	}, [selectedId2, opcoes2]);

	return (
		<Box gap={1} marginX={1} padding={1} paddingX={2} display="flex" alignItems="center" height={theme.spacing(5)} component={Paper} elevation={0} m={1}>
			<Grid container display="flex" flexDirection="row" justifyContent={"space-between"}>
				{showInputClientFilter && (
					<Grid item xs={12} md={4.2} paddingRight={1}>
						<FormControl fullWidth>
							<Autocomplete
								fullWidth
								openText="Abrir"
								closeText="Fechar"
								noOptionsText="Nenhuma opção"
								loadingText="Loading ..."
								disablePortal
								value={autoCompleteSelectedOption}
								options={opcoes}
								disabled={loading}
								loading={isLoading}
								size={"small"}
								onInputChange={(_, newValue) => {
									setBusca(newValue);
								}}
								onChange={(_, newValue) => {
									onChangeClientFilter?.(newValue?.id ? newValue?.id : ("" as string));
									onChangeClientFilterName?.(newValue?.label ? newValue?.label : ("" as string));
									setSelectedId(newValue?.id ? newValue?.id : ("" as string));
								}}
								renderInput={(params) => <TextField {...params} label="Cliente de Origem" name="cliente1" />}
							/>
						</FormControl>
					</Grid>
				)}

				{showInputClientFilter2 && (
					<Grid item xs={12} md={4.2} paddingRight={1}>
						<FormControl fullWidth>
							<Autocomplete
								fullWidth
								openText="Abrir"
								closeText="Fechar"
								noOptionsText="Nenhuma opção"
								loadingText="Loading ..."
								disablePortal
								value={autoCompleteSelectedOption2}
								options={opcoes2}
								disabled={loading}
								loading={isLoading}
								size={"small"}
								onInputChange={(_, newValue) => setBusca2(newValue)}
								onChange={(_, newValue) => {
									onChangeClientFilter2?.(newValue?.id ? newValue?.id : ("" as string));
									onChangeClientFilterName2?.(newValue?.label ? newValue?.label : ("" as string));
									setSelectedId2(newValue?.id ? newValue?.id : ("" as string));
								}}
								renderInput={(params) => <TextField {...params} label="Cliente de Destino" name="cliente2" />}
							/>
						</FormControl>
					</Grid>
				)}

				{showTransfer && (
					<Grid item xs={12} md={"auto"}>
						<Box display="flex" justifyContent={loading ? "center" : "end"}>
							{loading ? (
								<CircularProgress />
							) : (
								<Tooltip title="Clique para Transferir" >
									<Button variant="contained" endIcon={<Icon>send</Icon>} onClick={onClickTransfer}>
										Transferir
									</Button>
								</Tooltip>
							)}
						</Box>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};
