import { Box, Button, Grid, Icon, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { VForm, useVForm } from "../../../shared/forms";
import { useToast } from "../../../shared/hooks";
import { FinancialService, ISendMailSetup } from "../../services/api/financial/FinancialService";
import { ToastContent } from "../ToastContent";
import { ModalContext } from "../VModalContext/VModalContext";

interface IModalSendEmailProps {
	id: string;
	modalSend: boolean;
	title: string;
    iscloseModal: () => void;
	email: string;
	onclickConfirm?: () => void;
	onclickCancel?: () => void;
}

export const ModalSendEmail: React.FC<IModalSendEmailProps> = ({ id, modalSend, title, iscloseModal, email, onclickConfirm, onclickCancel }) => {
	const [emailSend, setEmailSend] = useState<string>("");
	const [isLoading, setIsLoading] = useState(false);
	const { formRef } = useVForm();
	const toastHandler = useToast();

	const isEmailValid = (email: string): boolean => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handleSendMail = (dados: ISendMailSetup) => {
		dados.email = emailSend;
		console.log("id:", id, "", dados);
		FinancialService.sendMailSetup(id, { ...dados }).then((result) => {
			setIsLoading(false);
			if (result instanceof Error) {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={"Erro ao enviar email"} />,
				});
			} else {
				toastHandler.present({
					type: "success",
					position: "top-right",
					messageOrContent: <ToastContent title={"Sucesso"} content={"Email enviado com sucesso !!!"} />,
				});
				onclickConfirm ? onclickConfirm() : () => {};
			}
		});
	};

	return (
		<ModalContext
			isCloseModal={iscloseModal}
			isOpenModal={modalSend}
			messageType={"alert"}
			showButtonCancel={false}
			textButtonConfirm={"Fechar"}
			onclickConfirm={() => {}}
			ITitle={title}
			IMessage={[
				<VForm
					id="formIdCancelSetup"
					placeholder="Your placeholder"
					onPointerEnterCapture={() => console.log("Pointer entered")}
					onPointerLeaveCapture={() => console.log("Pointer left")}
					ref={formRef}
					onSubmit={handleSendMail}
				>
					<Box>
						<Grid container item direction="row" gap={2} sx={{ mb: "2rem" }}>
							<Typography variant="subtitle1" sx={{ alignSelf: "center" }}>
								Email cadastrado :
							</Typography>
							<Typography variant="h6">{email}</Typography>
						</Grid>
						<Grid container item direction="row" sx={{ mb: "2rem", minWidth: "300px" }}>
							<TextField
								label="Confirme o email cadastrado"
								name="email"
								type="email"
								variant="outlined"
								fullWidth
								value={emailSend}
								onChange={(e) => setEmailSend(e.target.value)}
							/>
						</Grid>

						<Grid container item direction="row" gap={2}>
							<Button color="inherit" disableElevation variant={"contained"} startIcon={<Icon> close</Icon>} onClick={onclickCancel}>
								{"Fechar"}
							</Button>
							{emailSend.trim() !== "" && isEmailValid(emailSend) && (
								<Button
									color="primary"
									disableElevation
									variant={"contained"}
									startIcon={<Icon> mail_outlined_icon</Icon>}
									onClick={() => {
                                        formRef.current?.submitForm();
                                    }}
								>
									{"Reenviar por email"}
								</Button>
							)}
						</Grid>
					</Box>
				</VForm>,
			]}
		/>
	);
};
