import { Box, Button, CircularProgress, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Tab, Tabs, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
// import { SinisterService } from '../../../shared/services/api/sinister/SinisterService';
//import { AnalysisContractTypeEnum } from '../../../enums/analysis-documents';
import { SinisterFilesUploadArea } from './SinisterFilesUploadArea/SinisterFileUploadArea';
import { useToast } from '../../../shared/hooks';
import { SinisterValuesFilesList } from './SinisterValuesFilesList/SinisterValuesFilesList';
import { GetDocumentTypesResponseType } from '../../../shared/services/api/documents/types';
import { DocumentsService } from '../../../shared/services/api/documents/DocumentsService';
import { SinisterDocumentsService } from '../../../shared/services/api/sinister/SinisterDocumentsService';
import { GetValuesDocumentTypesResponseType } from '../../../shared/services/api/sinister/types';
import { SinisterValuesDocumentType } from '../../../shared/services/api/sinister/types/sinister-values-document.type';
import { ISinisterValues } from '../../../shared/services/api/sinister/SinisterService';
import { ISinisterValuesUI } from './SinisterValuesPresentation';

type SinisterValuesFilesModalContentPropsType = {
  sinisterId: string,
  listId: string,
  subTitle: string,
  sinisterValues: ISinisterValuesUI | undefined;
}

enum SinisterFilesModalTabs {
  FileUpload,
  FileList,
}
export const SinisterValuesFilesModalContent = ({ sinisterId, listId, subTitle, sinisterValues }: SinisterValuesFilesModalContentPropsType) => {
  const [fileTypeId, setFileTypeId] = useState<string>();
  const [file, setFile] = useState<File>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [documentTypes, setDocumentTypes] = useState<SinisterValuesDocumentType[]>([]);
  //GetValuesDocumentTypesResponseType[]
  const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<SinisterFilesModalTabs>(SinisterFilesModalTabs.FileList);
  const toastHandler = useToast();
  console.log('sinister values id .... sinisterFiles 30', sinisterId, listId, sinisterValues)
  useEffect(() => {
    SinisterDocumentsService.getSinisterValuesDocumentTypes(sinisterId, listId).then((result) => {
      console.log('36 valuesfiles modals ', result)
      setDocumentTypes(result);
      setIsLoading(false);
    })
    // .catch((error) => {
    //   toastHandler.present({ type: 'error', messageOrContent: error.message, position: 'top-center' })
    // })
  }, [])

  const handleChangeType = (event: SelectChangeEvent) => {
    setFileTypeId(event.target.value);
  };

  const onFileUploadHandler = (file: File | undefined) => {
    setFile(file)
  };

  const submitFile = async () => {
    if (file) {
      setIsUploading(true)

      try {
        await SinisterDocumentsService.uploadSinisterValuesDocument(sinisterId, listId, 'otherAtachments', file);
        toastHandler.present({ type: 'success', messageOrContent: 'Arquivo enviado com sucesso!', position: 'top-center' })
        setFileUploadedSuccessfully(true)
        setActiveTab(SinisterFilesModalTabs.FileList)
        setTimeout(() => {
          setFileUploadedSuccessfully(false)
        }, 250)
      } catch (e: any) {
        toastHandler.present({ type: 'error', messageOrContent: e.message, position: 'top-center' })
      } finally {
        setIsUploading(false)
      }
    }
  };

  const validToUpload = useMemo(() => {
    return file //&& fileTypeId
  }, [file])

  const handleTabChange = (_: any, tab: SinisterFilesModalTabs) => {
    setActiveTab(tab)
  }

  if (isLoading) {
    return (
      <Box>
        <CircularProgress variant='indeterminate' />
      </Box>
    )
  }

  return (
    <>
      <Box>
        <Tabs value={activeTab} onChange={handleTabChange} variant={'fullWidth'} indicatorColor={'secondary'} textColor={'secondary'}>
          <Tab value={SinisterFilesModalTabs.FileList} label='Lista de Arquivos' disabled={isUploading} />
          <Tab value={SinisterFilesModalTabs.FileUpload} label='Envio de Arquivo' />
        </Tabs>
        {activeTab === SinisterFilesModalTabs.FileUpload && 
        (sinisterValues &&  (
          sinisterValues?.status!='open' &&
          sinisterValues?.status!='refused' && 
          sinisterValues?.status!='contested')
        )
        &&
        (
          <Box style={{
            minWidth: '25vw'
          }}>
            <Grid container item direction="row" spacing={1}>
                <Grid container item direction="row" spacing={4}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop:'50px' }}>
                       <Typography
                       id="msg_desabled"
                       variant="h6"
                       color={`error`}
                     >
                        Envio de arquivos desabilitado
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
          </Box>
        )}
        {activeTab === SinisterFilesModalTabs.FileUpload && 
        (sinisterValues &&  (sinisterValues?.status=='open' || sinisterValues?.status=='refused' || sinisterValues?.status=='contested'))
        &&
        (
          <Box style={{
            minWidth: '25vw'
          }}>
            <InputLabel id="file_type_label">Tipo de Arquivo</InputLabel>

            <SinisterFilesUploadArea onFileUploadHandler={onFileUploadHandler} isLoadingProp={isUploading} clear={fileUploadedSuccessfully} />
            <Button
              color="primary"
              disableElevation
              variant={"contained"}
              onClick={submitFile}
              disabled={!validToUpload || isUploading}
              style={{
                marginTop: '16px',
                width: '100%'
              }}
            >
              {'Confirmar'}
            </Button>
          </Box>
        )
        }
        {activeTab === SinisterFilesModalTabs.FileList && (
          <SinisterValuesFilesList sinisterId={sinisterId} valuesId={listId}></SinisterValuesFilesList>
        )}
      </Box>
    </>
  )
}