// LoadingDialog.tsx
import React from "react";
import { Dialog, DialogContent, CircularProgress, Typography, Box } from "@mui/material";

interface LoadingDialogProps {
	isLoading: boolean;
	loadingText?: string;
}

const LoadingDialog: React.FC<LoadingDialogProps> = ({ isLoading, loadingText = "Carregando..." }) => (
	<Dialog
		open={isLoading}
		aria-labelledby="loading-dialog"
		PaperProps={{
			style: {
				backgroundColor: "rgba(255, 255, 255, 0.5)", // Fundo com opacidade
				border: "2px solid rgba(0, 0, 0, 0.2)", // Borda transparente
				boxShadow: "none",
				borderRadius: 10,
			},
		}}
	>
		<DialogContent>
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
				<CircularProgress />
				<Typography sx={{ mt: 2 }}>{loadingText}</Typography>
			</Box>
		</DialogContent>
	</Dialog>
);

export default LoadingDialog;
