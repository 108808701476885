import { useNavigate } from 'react-router-dom';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import {
  Grid,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Card,
  CardContent,
  useMediaQuery,
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { useDebounce, useToast } from '../../../shared/hooks';
import { ToastContent } from '../../../shared/components';
import { returnMessageError } from '../../../shared/utils/returnMessageError';
import {
  PermissionActionsEnum,
  PermissionRolesEnum,
} from '../../../shared/enums/profiles';
import { ProfileGuard } from '../../../shared/guards/ProfileGuard';
import { Environment } from '../../../shared/environment';
import {
  formatScaleValueBR,
  formatValueBR,
} from '../../../shared/utils/formatScaleValue';
import moment from 'moment';
import { FinancialService } from '../../../shared/services/api/financial/FinancialService';
import { ToolbarLifecycleAnalysisFilterReport } from '../../../shared/components/toolbar/report/toolbarLifecycleAnalysisFilterReport';
import {
  globalColorText,
  maskCpfOrCnpj,
  translateStatusAnalysis,
} from '../../../shared/utils';

export const ReportLifecycleAnalysisPresentation = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const toastHandler = useToast();
  const { debounce } = useDebounce();
  const [isLoading, setIsLoading] = useState(false);
  const [dataIni, setDataIni] = useState(
    moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD')
  );
  const [dataFim, setDataFim] = useState(
    moment().endOf('month').format('YYYY-MM-DD')
  );
  const [origem, setOrigem] = useState('Todos');
  const [dataManagerFilter, setDataManagerFilter] = useState('');
  const [dataClientFilter, setDataClientFilter] = useState('');
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState<any>([]);
  const [rowsSynthetic, setRowsSynthetic] = useState<any>([]);
  const [pageSize, setPageSize] = useState(Environment.LIMITE_LINHAS);
  const [loadingExport, setLoadingExport] = useState(false);
  const [isFilter, setIsFilter] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isRealod, setIsRealod] = useState(false);
  const [reportType, setReportType] = useState(2);
  const [status, setStatus] = useState('Todos');
  const [document, setDocument] = useState('');

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const searchReportLifecycle = () => {
    if (isFilter) setIsLoading(true);
    if (!isFilter) setLoadingExport(true);

    debounce(() => {
      FinancialService.getReportLifecycleAnalysis(
        page,
        pageSize,
        dataClientFilter,
        dataIni,
        dataFim,
        dataManagerFilter,
        origem,
        isFilter,
        reportType,
        status,
        document
      )
        .then((result: any) => {
          if (isFilter) setIsLoading(false);

          if (!isFilter) setLoadingExport(false);

          if (result instanceof Error) {
            const titleMessage = 'Alerta!!';
            const contentMessage = 'Erro: ' + result?.message;

            toastHandler.present({
              type: 'error',
              position: 'top-right',
              messageOrContent: (
                <ToastContent title={titleMessage} content={contentMessage} />
              ),
            });
          } else if (isFilter) {
            if (reportType === 2) {
              setRowsSynthetic(result.result);
            } else {
              setRows(result.result);
              setTotalCount(result.totalCount);
              if (result.totalCount < Environment.LIMITE_LINHAS_GREAT) {
                setPage(1);
              }
            }
          } else if (result) {
            toastHandler.present({
              type: 'info',
              position: 'top-right',
              messageOrContent: (
                <ToastContent title={'Atenção!'} content={result} />
              ),
            });
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          if (!isFilter) setLoadingExport(false);
          if (isFilter) setIsLoading(false);

          const { titleMessage, contentMessage } = returnMessageError(err);

          toastHandler.present({
            type: 'error',
            position: 'top-right',
            messageOrContent: (
              <ToastContent title={titleMessage} content={contentMessage} />
            ),
          });
        });
    });
  };

  useEffect(() => {
    if (!isFirstLoad && isFilter) searchReportLifecycle();
  }, [page, pageSize, isRealod]);

  useEffect(() => {
    if (!isFirstLoad && !isFilter) searchReportLifecycle();
  }, [isRealod]);

  useEffect(() => {
    setRows([]);
    setRowsSynthetic([]);
    setPage(page);
    setTotalCount(0);
  }, [reportType]);

  const renderAnalyticalTable = () => (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{ marginTop: 1, width: '96%', padding: '20px' }}
    >
      <Table aria-label="a dense table" sx={{ marginTop: '18px' }}>
        <TableHead>
          <TableRow sx={{ background: '#f5fbfd' }}>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Nome Imobiliária
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Documento Imobiliária
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Tipo Imóvel
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Endereço Completo{' '}
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Locatário{' '}
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Documento Locatário{' '}
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Valor Aluguel{' '}
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Condomínio{' '}
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Água{' '}
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Luz{' '}
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Gás{' '}
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              IPTU{' '}
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Origem{' '}
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Status{' '}
            </TableCell>
            <TableCell sx={{ color: '#7e7777b5', textAlign: 'center' }}>
              {' '}
              Origem Imobiliária
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            rows?.map((row: any, index: any) => (
              <TableRow key={index} hover>
                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ width: '150px' }}
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {row?.clientName}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="caption"
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {maskCpfOrCnpj(row?.clientDocument)}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {row?.propertyType}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      width: '145px',
                      whiteSpace: 'nowrap',
                      overflowX: 'auto',
                      scrollBehavior: 'smooth',
                    }}
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {row?.streetComplet}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center', padding: 0 }}>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {row?.locatorName}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center', padding: 0 }}>
                  <Typography
                    variant="caption"
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {maskCpfOrCnpj(row?.locatorDocument)}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="caption"
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {formatValueBR(
                      Number(row?.assuranceMonthAmount) +
                        Number(row?.rentAmount)
                    )}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {formatScaleValueBR(row?.condominiumAmount)}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {formatScaleValueBR(row?.waterAmount)}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {formatScaleValueBR(row?.electricEnergyAmount)}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {formatScaleValueBR(row?.gasAmount)}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {formatScaleValueBR(row?.iptuAmount)}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {row?.createdFrom}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {translateStatusAnalysis(row?.status)}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="subtitle2"
                    color={'#5c5858'}
                    fontWeight={'normal'}
                  >
                    {row?.clientFrom}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        {totalCount === 0 && !isLoading && (
          <caption
            style={{
              textAlign: 'center',
              margin: 0,
              color: 'black',
              fontSize: '1rem',
              lineHeight: 1.75,
              letterSpacing: '0.00938em',
            }}
          >
            {Environment.LISTAGEM_VAZIA}
          </caption>
        )}
        <TableFooter>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={15}>
                <LinearProgress variant="indeterminate" />
              </TableCell>
            </TableRow>
          )}
          {totalCount > 0 && (
            <TableRow>
              <TableCell colSpan={5} sx={{ borderBottom: 'none' }}>
                <Pagination
                  page={page}
                  count={Math.ceil(totalCount / pageSize)}
                  onChange={(_, newPage) => {
                    setPage(newPage);
                    setIsFilter(true);
                    setIsRealod(!isRealod);
                  }}
                />
              </TableCell>
              <TableCell colSpan={5} sx={{ borderBottom: 'none' }}>
                <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                  Exibindo {page * pageSize - (pageSize - 1)}
                  {' - '}
                  {pageSize * (page - 1) + pageSize * page < totalCount
                    ? pageSize * page
                    : totalCount}{' '}
                  de
                  <Typography
                    variant="overline"
                    sx={{
                      fontWeight: 'bold',
                      color: '#0170B3',
                      fontSize: '16px',
                    }}
                  >
                    {' '}
                    {totalCount}{' '}
                  </Typography>
                  registro(s)
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );

  const renderSyntheticScreen = () => (
    <Grid
      container
      item
      sm={12}
      sx={{
        borderRadius: '6px',
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
      }}
      display={'flex'}
      flexDirection={smDown ? 'column' : 'row'}
      component={Paper}
      // padding={2}
    >
      {rowsSynthetic?.map((item: any, index: number) => (
        <Grid container item sm={12} md={3} key={index}>
          <Card
            sx={{
              padding: '2px',
              boxShadow: 'none',
              width: '100%',
              borderRight:
                index !== rows.length - 1 ? '1px solid #c1c1c1' : 'none',
              borderRadius: '0',
            }}
          >
            <CardContent sx={{ textAlign: 'center', paddingTop: '16px' }}>
              <Typography variant="h4" fontWeight={'300'}>
                {translateStatusAnalysis(item?.status)}
              </Typography>
              <Typography
                variant="h5"
                sx={{ color: globalColorText(item?.status) }}
              >
                {item.totalCount}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
      {!rowsSynthetic?.length && reportType === 2 && (
        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            border: '1px solid #eaecee',
            padding: 1,
            textAlign: 'center',
            marginTop: '-5px',
            marginRight: '8px',
            borderRadius: '6px',
            boxShadow:
              'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
          }}
        >
          <Typography variant="subtitle1">
            {Environment.LISTAGEM_VAZIA}
          </Typography>
        </Box>
      )}
    </Grid>
  );

  return (
    <LayoutBaseDePagina
      titulo="Relatório de Análises"
      barraDeFerramentas={
        <ToolbarLifecycleAnalysisFilterReport
          clickBack={() => navigate('/relatorios')}
          onClick={() => {
            setIsFirstLoad(false);
            setIsFilter(true);
            setIsRealod(!isRealod);
          }}
          onClickExport={() => {
            setIsFirstLoad(false);
            setIsFilter(false);
            setIsRealod(!isRealod);
          }}
          showInputManagerFilter={true}
          onChangeManagerFilter={(value) => {
            setDataManagerFilter(value);
          }}
          onChangeOrigem={(value) => {
            setOrigem(value);
          }}
          onChangeClientFilter={(value) => {
            setDataClientFilter(value);
          }}
          onChangeDataIni={(value) => {
            setDataIni(value);
          }}
          onChangeDataFim={(value) => {
            setDataFim(value);
          }}
          onChangeLimitPage={(value) => {
            setPageSize(Number(value));
          }}
          onChangeReportType={(value) => {
            setReportType(value);
          }}
          onChangeStatus={(value) => {
            setStatus(value);
          }}
          onChangeDocument={(value) => {
            setDocument(value);
          }}
          showLimitPage={reportType === 1}
          showExport={reportType === 1}
          loadingExport={loadingExport}
          loading={isLoading}
        />
      }
    >
      <Grid container display="flex" flexDirection="row">
        <ProfileGuard
          requiredRole={PermissionRolesEnum.FinancialOptions}
          requiredAction={PermissionActionsEnum.View}
        >
          {reportType === 1 ? renderAnalyticalTable() : renderSyntheticScreen()}
        </ProfileGuard>
      </Grid>
    </LayoutBaseDePagina>
  );
};
