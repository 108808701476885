import { Box, Grid, Switch, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMenuPermissions } from '../../hooks/useMenuPermissions';
import { IOptionsData, usePermissions } from '../../hooks/usePermissions';

interface IPermissionBoxProps {
    type: string;
    permissions: string;
    display: string;
}

export const PermissionBox: React.FC<IPermissionBoxProps> = ({ type, permissions, display }) => {
    const {
        handleOptions,
        dashboardOptions,
        clientOptions,
        usersOptions,
        analysisOptions,
        chartsOptions,
        financialOptions,
        generalConditionsOptions,
        permissionsOptions,
        reportsOptions,
        sinisterOptions,
        sinisterValuesOptions,
        warrantiesOptions,
    } = usePermissions();

    const {
        analysisMenu,
        chartsMenu,
        clientsMenu,
        dashboardMenu,
        financialMenu,
        generalConditionsMenu,
        permissionsMenu,
        reportsMenu,
        sinisterMenu,
        usersMenu,
        warrantiesMenu,
        handleMenuOptions,
    } = useMenuPermissions();

    const { id = 'novo' } = useParams<'id'>();

    const handleChooseState: () => IOptionsData = () => {
        switch (type) {
            case 'dashboard':
                return dashboardOptions;
            case 'clients':
                return clientOptions;
            case 'users':
                return usersOptions;
            case 'analysis':
                return analysisOptions;
            case 'warranties':
                return warrantiesOptions;
            case 'sinister':
                return sinisterOptions;
            case 'sinisterValues':
                return sinisterValuesOptions;
            case 'reports':
                return reportsOptions;
            case 'financial':
                return financialOptions;
            case 'generalConditions':
                return generalConditionsOptions;
            case 'charts':
                return chartsOptions;
            case 'permissions':
                return permissionsOptions;
            default:
                return dashboardOptions;
        }
    }



    const selectedState = handleChooseState()

    const [view, setView] = useState({} as any);
    const [add, setAdd] = useState({} as any);
    const [update, setUpdate] = useState({} as any);
    const [filter, setFilter] = useState({} as any);
    const [conclude, setConclude] = useState({} as any);
    const [cancel, setCancel] = useState({} as any);

    const [approve, setApprove] = useState({} as any);
    const [reject, setReject] = useState({} as any);
    const [contest, setContest] = useState({} as any);

    const [serasa, setSerasa] = useState({} as any);
    const [clickSign, setclickSign] = useState({} as any);
    const [tipping, setTipping] = useState({} as any);

    const [upProposalPeriod, setUpProposalPeriod] = useState({} as any);
    const [upProposalValues, setUpProposalValues] = useState({} as any);
    const [upProposalAddress, setUpProposalAddress] = useState({} as any);
    const [upProposalLocator, setUpProposalLocator] = useState({} as any);
    const [upProposalConcludeCancel, setUpProposalConcludeCancel] = useState({} as any);
    const [upProposalCancel, setUpProposalCancel] = useState({} as any);

    const [reportFinancial, setReportFinancial] = useState({} as any); 
    const [reportProposals, setReportProposals] = useState({} as any); 
    const [reportAnalysis, setReportAnalysis] = useState({} as any); 
    const [reportSinister, setReportSinister] = useState({} as any); 
    const [reportAdmin, setReportAdmin] = useState({} as any); 
    const [reportClient, setReportClient] = useState({} as any); 
    const [reportLogAndAudit, setReportLogAndAudit] = useState({} as any); 
    const [blockCancel, setBlockCancel] = useState({} as any); 
    const [walletTransfer, setWalletTransfer] = useState({} as any); 
    const [contracts, setContracts] = useState({} as any);

    const [checked, setChecked] = useState(false);
    const [data, setData] = useState({} as any);
    const [openForm, setOpenForm] = useState<boolean>(false);

    const handleChange = () => {
        handleOptions(type, {
            add: add,
            filter: filter,
            update: update,
            view: view,
            conclude: conclude,
            cancel: cancel,
            approve: approve,
            reject: reject,
            contest: contest,
            serasa: serasa,
            clickSign: clickSign,
            tipping: tipping,
            upProposalPeriod: upProposalPeriod,
            upProposalValues: upProposalValues,
            upProposalAddress: upProposalAddress,
            upProposalLocator: upProposalLocator,
            upProposalConcludeCancel: upProposalConcludeCancel,
            upProposalCancel: upProposalCancel,

            reportFinancial: reportFinancial,
            reportProposals: reportProposals,
            reportAnalysis: reportAnalysis,
            reportSinister: reportSinister,
            reportAdmin: reportAdmin,
            reportClient: reportClient,
            reportLogAndAudit: reportLogAndAudit,
            blockCancel: blockCancel,
            walletTransfer: walletTransfer,
            contracts: contracts,

        });
    };

    const handleMenuOptionsState = useCallback((permissionData: any) => {
        handleMenuOptions('dashboardMenu', permissionData?.menu_dashboard?.view);
        handleMenuOptions('clientsMenu', permissionData?.clients_dashboard?.view);
        handleMenuOptions('analysisMenu', permissionData?.analysis_dashboard?.view);
        handleMenuOptions('usersMenu', permissionData?.users_dashboard?.view);
        handleMenuOptions('warrantiesMenu', permissionData?.warranties_menu?.view);
        handleMenuOptions('sinisterMenu', permissionData?.sinister_menu?.view);
        handleMenuOptions('reportsMenu', permissionData?.reports_menu?.view);
        handleMenuOptions('financialMenu', permissionData?.financial_menu?.view);
        handleMenuOptions('generalConditionsMenu', permissionData?.general_conditions_menu?.view);
        handleMenuOptions('chartsMenu', permissionData?.charts_menu?.view);
        handleMenuOptions('permissionsMenu', permissionData?.permissions_menu?.view);
    }, [permissions]);

    function setOptionsState(permissionsObject: any) {
        setAdd(permissionsObject?.add)
        setUpdate(permissionsObject?.update);
        setFilter(permissionsObject?.filter);
        setView(permissionsObject?.view);
        setConclude(permissionsObject?.conclude);

        setApprove(permissionsObject?.approve);
        setReject(permissionsObject?.reject);
        setContest(permissionsObject?.contest);

        setSerasa(permissionsObject?.serasa);
        setclickSign(permissionsObject?.clickSign);
        setTipping(permissionsObject?.tipping);

        setUpProposalPeriod(permissionsObject?.upProposalPeriod);
        setUpProposalValues(permissionsObject?.upProposalValues);
        setUpProposalAddress(permissionsObject?.upProposalAddress);
        setUpProposalLocator(permissionsObject?.upProposalLocator);
        setUpProposalConcludeCancel(permissionsObject?.upProposalConcludeCancel);
        setUpProposalCancel(permissionsObject?.upProposalCancel);
        setCancel(permissionsObject?.cancel);

        setReportFinancial(permissionsObject?.reportFinancial);
        setReportProposals(permissionsObject?.reportProposals);
        setReportAnalysis(permissionsObject?.reportAnalysis);
        setReportSinister(permissionsObject?.reportSinister);
        setReportAdmin(permissionsObject?.reportAdmin);
        setReportClient(permissionsObject?.reportClient);
        setReportLogAndAudit(permissionsObject?.reportLogAndAudit);
        setBlockCancel(permissionsObject?.blockCancel);
        setWalletTransfer(permissionsObject?.walletTransfer);
        setContracts(permissionsObject?.contracts);

        handleChange();
    }

    const handleOptionsState = (permissionData: any) => {
        switch (type) {
            case 'dashboard':
                setOptionsState(permissionData?.dashboard_options);
                break;
            case 'clients':
                setOptionsState(permissionData?.clients_options);
                break;
            case 'users':
                setOptionsState(permissionData?.users_options);
                break
            case 'analysis':
                setOptionsState(permissionData?.analysis_options);
                break;
            case 'warranties':
                setOptionsState(permissionData?.warranties_options);
                break;
            case 'sinister':
                setOptionsState(permissionData?.sinister_options);
                break;
            case 'sinisterValues':
                setOptionsState(permissionData?.sinister_values_options);
                break;
            case 'reports':
                setOptionsState(permissionData?.reports_options);
                break;
            case 'generalConditions':
                setOptionsState(permissionData?.general_conditions_options);
                break;
            case 'financial':
                setOptionsState(permissionData?.financial_options);
                break;
            case 'charts':
                setOptionsState(permissionData?.charts_options);
                break;
            case 'permissions':
                setOptionsState(permissionData?.permissions_options);
                break;
        }
    }

    useEffect(() => {
        if (typeof permissions === 'string') {
            const permissionsParsed = JSON.parse(permissions);
            setData(permissionsParsed);
            handleMenuOptionsState(permissionsParsed);
            handleOptionsState(permissionsParsed);
            handleOptions('dashboard', permissionsParsed?.dashboard_options);
            handleOptions('clients', permissionsParsed?.clients_options);
            handleOptions('users', permissionsParsed?.users_options);
            handleOptions('analysis', permissionsParsed?.analysis_options);
            handleOptions('warranties', permissionsParsed?.warranties_options);
            handleOptions('sinister', permissionsParsed?.sinister_options);
            handleOptions('sinisterValues', permissionsParsed?.sinister_values_options);
            handleOptions('reports', permissionsParsed?.reports_options);
            handleOptions('generalConditions', permissionsParsed?.general_conditions_options);
            handleOptions('financial', permissionsParsed?.financial_options);
            handleOptions('charts', permissionsParsed?.charts_options);
            handleOptions('permissions', permissionsParsed?.permissions_options);

        } else {
            handleMenuOptionsState({});
            setOpenForm(true);
        }

    }, [type, permissions]);

    function handleOpenForm() {
        setOpenForm(true);
    }

    return (
        <Box sx={{ marginTop: '30px', display: display == 'none' ? 'none' : '' }} >
            {
                type === 'menu'
                    ?
                    <>
                        <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }} >
                            <Typography>Dashboard</Typography>
                            <Switch
                                color='secondary'
                                checked={dashboardMenu ? dashboardMenu?.view : false}
                                onChange={(e) => handleMenuOptions('dashboardMenu', e.target.checked)}
                            />
                        </Grid>
                        <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }} >
                            <Typography>Clientes</Typography>
                            <Switch
                                color='secondary'
                                checked={clientsMenu ? clientsMenu.view : false}
                                onChange={(e) => handleMenuOptions('clientsMenu', e.target.checked)}
                            />
                        </Grid>
                        <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }} >
                            <Typography>Usuários</Typography>
                            <Switch
                                color='secondary'
                                checked={usersMenu ? usersMenu?.view : false}
                                onChange={(e: any) => handleMenuOptions('usersMenu', e.target.checked)}
                            />
                        </Grid>
                        <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }} >
                            <Typography>Análises</Typography>
                            <Switch
                                color='secondary'
                                checked={dashboardMenu ? analysisMenu.view : false}
                                onChange={(e: any) => { handleMenuOptions('analysisMenu', e.target.checked) }}
                            />
                        </Grid>
                        <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }} >
                            <Typography>Garantias</Typography>
                            <Switch
                                color='secondary'
                                checked={warrantiesMenu ? warrantiesMenu.view : false}
                                onChange={(e: any) => handleMenuOptions('warrantiesMenu', e.target.checked)}
                            />
                        </Grid>
                        <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }} >
                            <Typography>Sinistro</Typography>
                            <Switch
                                color='secondary'
                                checked={sinisterMenu ? sinisterMenu.view : false}
                                onChange={(e: any) => handleMenuOptions('sinisterMenu', e.target.checked)}
                            />
                        </Grid>

                        <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }} >
                            <Typography>Relatórios</Typography>
                            <Switch
                                color='secondary'
                                checked={reportsMenu ? reportsMenu.view : false}
                                onChange={(e: any) => handleMenuOptions('reportsMenu', e.target.checked)}
                            />
                        </Grid>
                        <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }} >
                            <Typography>Financeiro</Typography>
                            <Switch
                                color='secondary'
                                checked={financialMenu ? financialMenu.view : false}
                                onChange={(e: any) => handleMenuOptions('financialMenu', e.target.checked)}
                            />
                        </Grid>
                        <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }} >
                            <Typography>Documentos para Download</Typography>
                            <Switch
                                color='secondary'
                                checked={generalConditionsMenu ? generalConditionsMenu.view : false}
                                onChange={(e: any) => handleMenuOptions('generalConditionsMenu', e.target.checked)}
                            />
                        </Grid>
                        <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }} >
                            <Typography>Gráficos</Typography>
                            <Switch
                                color='secondary'
                                checked={chartsMenu ? chartsMenu.view : false}
                                onChange={(e: any) => handleMenuOptions('chartsMenu', e.target.checked)}
                            />
                        </Grid>
                        <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }} >
                            <Typography>Permissões</Typography>
                            <Switch
                                color='secondary'
                                checked={permissionsMenu ? permissionsMenu.view : false}
                                onChange={(e: any) => handleMenuOptions('permissionsMenu', e.target.checked)}
                            />
                        </Grid>
                    </>
                    :
                    <>
                        <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography>Visualizar</Typography>
                            <Switch
                                color='secondary'
                                checked={view === true && type === selectedState.type}
                                onChange={(e: any) => setView(e.target.checked)}
                                onBlur={() => {
                                    if (type === selectedState?.type) {
                                        handleChange()
                                    }
                                }}
                                onLoad={(e: any) => handleChange()}
                            />
                        </Grid>
                        {(type != 'reports' && type != 'generalConditions' && type != 'dashboard') &&
                            <>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography> {type == 'sinisterValues' ? 'Adicionar' : 'Adicionar'}</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setAdd(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={add === true && type === selectedState?.type}
                                        onLoad={(e: any) => handleChange()}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Atualizar</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setUpdate(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={update === true && type === selectedState?.type}
                                        onLoad={(e: any) => handleChange()}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Filtrar</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setFilter(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={filter === true && type === selectedState?.type}
                                    />
                                </Grid>
                            </>
                        }
                        {
                            type == 'analysis' &&
                            <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography>Concluir</Typography>
                                <Switch
                                    color='secondary'
                                    onChange={(e: any) => setConclude(e.target.checked)}
                                    onBlur={() => {
                                        if (type === selectedState?.type) {
                                            handleChange()
                                        }
                                    }}
                                    checked={conclude === true && type === selectedState?.type}
                                />
                            </Grid>
                        }

                        {
                            (type == 'warranties' || type == 'sinisterValues') &&
                            <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography>Cancelar</Typography>
                                <Switch
                                    color='secondary'
                                    onChange={(e: any) => setCancel(e.target.checked)}
                                    onBlur={() => {
                                        if (type === selectedState?.type) {
                                            handleChange()
                                        }
                                    }}
                                    checked={cancel === true && type === selectedState?.type}
                                />
                            </Grid>
                        }

                        {
                            (type == 'financial') &&
                            <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography>Cancelar/Bloquear imobiliária</Typography>
                                <Switch
                                    color='secondary'
                                    onChange={(e: any) => setBlockCancel(e.target.checked)}
                                    onBlur={() => {
                                        if (type === selectedState?.type) {
                                            handleChange()
                                        }
                                    }}
                                    checked={blockCancel === true && type === selectedState?.type}
                                />
                            </Grid>
                        }
                        {
                            (type == 'warranties') &&
                            <>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Alterar Vigência</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setUpProposalPeriod(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={upProposalPeriod === true && type === selectedState?.type}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Alterar Valores</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setUpProposalValues(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={upProposalValues === true && type === selectedState?.type}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Alterar Endereço</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setUpProposalAddress(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={upProposalAddress === true && type === selectedState?.type}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Alterar Locador</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setUpProposalLocator(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={upProposalLocator === true && type === selectedState?.type}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Alterar Cancelamento</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setUpProposalCancel(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={upProposalCancel === true && type === selectedState?.type}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Concluir Cancelamento</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setUpProposalConcludeCancel(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={upProposalConcludeCancel === true && type === selectedState?.type}
                                    />
                                </Grid>
                            </>
                        }
                        {
                            type == 'sinisterValues' &&
                            <>

                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Aprovar</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setApprove(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={approve === true && type === selectedState?.type}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Rejeitar</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setReject(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={reject === true && type === selectedState?.type}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Contestar</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setContest(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={contest === true && type === selectedState?.type}
                                    />
                                </Grid>
                            </>
                        }
                        {
                            type == 'analysis' &&
                            <>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Tombamento</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setTipping(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={tipping === true && type === selectedState?.type}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Enviar clickSign</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setclickSign(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={clickSign === true && type === selectedState?.type}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Consultar Serasa</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setSerasa(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={serasa === true && type === selectedState?.type}
                                    />
                                </Grid>
                            </>
                        }

                        {
                            (type == 'reports') &&
                            <>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Imobiliárias</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setReportClient(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={reportClient === true && type === selectedState?.type}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Análises</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setReportAnalysis(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={reportAnalysis === true && type === selectedState?.type}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Garantias</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setReportProposals(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={reportProposals === true && type === selectedState?.type}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Sinistros</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setReportSinister(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={reportSinister === true && type === selectedState?.type}
                                    />
                                </Grid>

                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Financeiros</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setReportFinancial(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={reportFinancial === true && type === selectedState?.type}
                                    />
                                </Grid>

                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Administrativos</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setReportAdmin(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={reportAdmin === true && type === selectedState?.type}
                                    />
                                </Grid>
                                <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography>Auditoria  e Logs</Typography>
                                    <Switch
                                        color='secondary'
                                        onChange={(e: any) => setReportLogAndAudit(e.target.checked)}
                                        onBlur={() => {
                                            if (type === selectedState?.type) {
                                                handleChange()
                                            }
                                        }}
                                        checked={reportLogAndAudit === true && type === selectedState?.type}
                                    />
                                </Grid>
                            </>
                        }
 {
                            (type == 'clients') &&
                            <>
                            <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography>Transferir Carteira</Typography>
                                <Switch
                                    color='secondary'
                                    onChange={(e: any) => setWalletTransfer(e.target.checked)}
                                    onBlur={() => {
                                        if (type === selectedState?.type) {
                                            handleChange()
                                        }
                                    }}
                                    checked={walletTransfer === true && type === selectedState?.type}
                                />
                            </Grid>
                             <Grid container xs={3} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                             <Typography>Gerenciar Contrato</Typography>
                             <Switch
                                 color='secondary'
                                 onChange={(e: any) => setContracts(e.target.checked)}
                                 onBlur={() => {
                                     if (type === selectedState?.type) {
                                         handleChange()
                                     }
                                 }}
                                 checked={contracts === true && type === selectedState?.type}
                             />
                         </Grid>
                         </>
                        }

                    </>
            }

        </Box>
    )
}