import { AuthGuard } from '../../shared/guards/AuthGuard';
import { ListagemClientesPresentation } from './components';
import { Sidebar } from '../../shared/components';

export const ListagemClientesPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <ListagemClientesPresentation/>
            </Sidebar>
        </AuthGuard>
    )
};