import { Box, Button, CircularProgress, Grid, Icon, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { IListagemUsuario, userService } from "../../../../shared/services/api/user/userService";
import { useToast } from "../../../../shared/hooks";
import { useEffect, useState } from "react";
import React from "react";
import { useDebounce } from "../../../../shared/hooks";
import { ModalContext, ToastContent } from "../../../../shared/components";
import { returnMessageError } from "../../../../shared/utils/returnMessageError";
import { ManagerModal } from "../../../../shared/components/VModalManager/VModalManager";


interface IManagerClientProps {
    isLoading: boolean;
    id: string;
    isSuperAdmin: any;
    permissionLevel: any;
}

interface IManagerClientGrid {
    id: string;
    name: string;
    email: string;
    status: string;
}

export const ManagerClient: React.FC<IManagerClientProps> = ({
    id,
    isLoading,
    isSuperAdmin,
    permissionLevel
}) => {

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [openModalManager, setOpenModalManager] = useState(false);
    const [userClientId, setUserClientId] = useState<string | undefined>('');
    const [managers, setManagers] = useState<IListagemUsuario[]>([]);
    const [users, setUsers] = useState<IListagemUsuario[]>([]);
    const [isRefresh, setIsRefresh] = useState(true);
    const { debounce } = useDebounce();
    const toastHandler = useToast();

    const getManagersByClient = () => {
        if ((isSuperAdmin || permissionLevel == 2) && id !== 'novo') {
            setIsRefresh(true);
            debounce(() => {
                userService.getManagersByClient(id)
                    .then((result) => {
                        setIsRefresh(false);
                        if (result instanceof Error) {
                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={"Alerta!!"} content={result?.message} />
                            })

                        } else {
                            setUsers(result.data);
                            setManagers(result.data)
                        }

                    }).catch((err: any) => {
                        setIsRefresh(false);
                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={"Alerta!!"} content={err} />
                        })
                    })
            });
        }
    }

    const handleOpenModalManager = () => {
        setOpenModalManager(true);
    };

    const handleCloseModalManager = () => {
        setOpenModalManager(false);
        setIsRefresh(true);
    }


    const handleDeleteManager = () => {
        if ((isSuperAdmin || permissionLevel == 2) && id !== 'novo' && userClientId) {
            setIsRefresh(true);
            console.log('userClientId2', userClientId)
            userService.deleteManagerById(userClientId)
                .then((result) => {
                    setIsRefresh(false);
                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    } else {
                        toastHandler.present({
                            type: 'success',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={result?.message} content={result?.description} />
                        });
                        getManagersByClient();
                        setOpenModalConfirm(false);
                    }

                }).catch((err: any) => {
                    setIsRefresh(false);
                    const { titleMessage, contentMessage } = returnMessageError(err);
                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    })
                })


        }
    };

    useEffect(() => {
        if (isRefresh && !openModalManager) {
            getManagersByClient();
        }
    }, [isRefresh, openModalManager]);

    return (
        <>
            <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TableContainer component={Paper} variant="outlined" sx={{ marginLeft: 1, marginTop: 1, marginRight: 2, width: 'auto', padding: 4, overflow: "auto" }} aria-label="customized table">
                        <Table aria-label="a dense table" >
                            <TableRow >
                                <TableCell sx={{ color: "#7e7777b5" }}> Nome</TableCell>
                                <TableCell sx={{ color: "#7e7777b5" }}> E-mail</TableCell>
                                <TableCell sx={{ color: "#7e7777b5" }}> Status</TableCell>
                                <TableCell sx={{ color: "#7e7777b5" }}> Ação</TableCell>
                                <Button
                                    color="primary"
                                    disableElevation
                                    variant="contained"
                                    onClick={handleOpenModalManager}

                                >Adicionar</Button>
                            </TableRow>
                            <TableBody>
                                {isRefresh ? <TableRow> <TableCell sx={{ textAlign: "center" }} colSpan={4}><CircularProgress /> </TableCell> </TableRow> :
                                    !users?.length ? <TableRow> <TableCell sx={{ textAlign: "center" }} colSpan={4}> Nenhum gestor encontrado! </TableCell> </TableRow> :
                                        users?.map(user => (
                                            <TableRow key={user.user_client_id} hover >
                                                <TableCell sx={{ fontWeight: "normal" }}>
                                                    <Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} >
                                                        {user.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: "normal" }} >
                                                    <Typography variant="subtitle2" fontWeight={"normal"} >
                                                        {user.email}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: "normal" }} >
                                                    <Typography variant="subtitle2" fontWeight={"normal"} textAlign={"center"} >
                                                        {user.status == 'active' ? 'Ativo' : 'Inativo'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ textAlign: "center" }}>
                                                    <IconButton size="small"
                                                        onClick={() => {
                                                            setManagers([user])
                                                            setUserClientId(user.user_client_id);
                                                            setOpenModalConfirm(true);
                                                        }} sx={{ borderRadius: "18px" }}
                                                    >
                                                        {isRefresh && user.user_client_id == userClientId ? <CircularProgress /> : <>
                                                            <Icon sx={{ color: "#D72802", fontWeight: "normal" }}> remove_done_Outlined_icon</Icon>
                                                            <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#D72802", fontWeight: "normal" }}>
                                                                Remover
                                                            </Typography>
                                                        </>}
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>


            {openModalConfirm && <ModalContext
                isCloseModal={() => setOpenModalConfirm(false)}
                isOpenModal={openModalConfirm}
                messageType={'alert'}
                ITitle="Confirma a exclusão Gestor ?"
                showButtonCancel={false}
                textButtonConfirm={'Excluir'}
                textButtonCancel={"Cancelar"}
                IMessage={[
                    <Box display={'flex'} flexDirection={'column'} padding={1} gap={2}  >
                        <Typography variant="subtitle1" sx={{ color: "#7e7777b5" }}>
                            Ao excluir o Gestor, o mesmo será removido da lista.
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: "#7e7777b5" }}>
                            {managers.map((row) => (
                                <Box display={'flex'} gap={3} border={'1px solid #dcdcdd'} padding={1} borderRadius={2} justifyContent={'center'} >
                                    <Typography variant="subtitle1" sx={{ color: "#7e7777b5" }}>
                                        <strong>Gestor:</strong> {row.name}
                                    </Typography>
                                </Box>
                            ))}
                        </Typography>
                        <Box display={'flex'} padding={1} gap={2} justifyContent={'center'} >
                            <Button
                                color="inherit"
                                disableElevation
                                variant="contained"
                                onClick={() => {
                                    setOpenModalConfirm(false);
                                    setIsRefresh(true);
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                color="primary"
                                disableElevation
                                variant="contained"
                                onClick={handleDeleteManager}
                            >
                                Excluir
                            </Button>
                        </Box>
                    </Box>
                ]}
            />}


            {(isSuperAdmin || permissionLevel == 2) && openModalManager && <ManagerModal
                isCloseModal={() => setOpenModalManager(false)}
                isOpenModal={openModalManager}
                messageType={'info'}
                onclickConfirm={handleCloseModalManager}
                managers={managers}
                idClient={id}
            />}
        </>
    )
}
