import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Button, Icon, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { checkByProfileGuard } from '../../../shared/guards/ProfileGuard';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';
import React from "react";
import { IApprovedSinisterValues, ICreateSinisterValues, ISinisterDetails, ISinisterValues, SinisterService } from "../../../shared/services/api/sinister/SinisterService";


type SinisterRowPropsType = {
  row: ISinisterValues,
  goToEdit: any,
  goTo: any,
  handleApproved?: () => void;
}
export function RowMenu({ row, goToEdit, goTo }: SinisterRowPropsType) {
  //console.log('170 RowMenu ',row);
  const [anchorEl, setAnchorEl] = React.useState(null);
  //const classes = useStyles();

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event: any
    //React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //RowMenu -- 


  return (
    <>
      <Menu
        id={`actions-${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {checkByProfileGuard(PermissionRolesEnum.SinisterValuesOptions, PermissionActionsEnum.Approve) ?
          <MenuItem disabled={(row.status == 'open' || row.status == 'contested' || row.status =='refused' ) ? false : true} onClick={(e) => { goTo('aprovar', row.id, e, row), handleClose() }} sx={{ color: "green" }} >Aprovar</MenuItem>
          : ''
        }
        {checkByProfileGuard(PermissionRolesEnum.SinisterValuesOptions, PermissionActionsEnum.Reject) ?
          <MenuItem disabled={(row.status == 'open' || row.status == 'contested'  || row.status =='approved' ) ? false : true} onClick={(e) => { goTo('rejeitar', row.id, e, row), handleClose() }} sx={{ color: "red" }} >Rejeitar</MenuItem>
          : ''
        }
        {checkByProfileGuard(PermissionRolesEnum.SinisterValuesOptions, PermissionActionsEnum.Cancel) ?
          <MenuItem disabled={(row.status == 'open' || row.status =='refused' || row.status =='approved') ? false : true} onClick={(e) => { goTo('cancelar', row.id, e, row), handleClose() }} sx={{ color: "orange" }} >Cancelar</MenuItem>
          : ''
        }
        {checkByProfileGuard(PermissionRolesEnum.SinisterValuesOptions, PermissionActionsEnum.Contest) ?
          <MenuItem disabled={(row.status == 'refused' || row.status == 'approved') ? false : true} onClick={(e) => { goTo('contestar', row.id, e, row), handleClose() }} sx={{ color: "gray" }} >Constestar</MenuItem>
          : ''
        }
      </Menu>
      <IconButton size="small" sx={{ borderRadius: "18px" }} onClick={handleClick}>
        <Icon sx={{ color: "#0170B3", fontWeight: "normal" }}> more_horiz</Icon>
        <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#0170B3", fontWeight: "normal" }}>
        </Typography>
      </IconButton>
    </>
  );
}
