import {createTheme} from '@mui/material';
export const LightTheme = createTheme({
    palette:{
        // mode:'dark',
        primary:{
            main:'#7924c7',
            dark:'#7924c7',
            light:'#7924c7',
            contrastText:'#e9e9e9',
        },
        secondary:{
            main:'#3cbd4d',   // cyan[700],
            dark:'#3cbd4d',   //cyan[800],
            light:'#3cbd4d',   //cyan[500],
            contrastText:'#fff',
        },        
       
        background:{
            default:'#f7f6f3',
            paper:'#FFF',
        }, 
    }
});