import { Box, Button, Grid, Icon, LinearProgress, MenuItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { IVFormErros, useVForm, VForm, VTextField } from "../../../../../shared/forms";
import { useToast } from "../../../../../shared/hooks";
import { ProposalDocumentsService } from "../../../../../shared/services/api/proposal/ProposalDocumentsService";
import { ICancelProposal, ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";
import { ProposalFilesUploadArea } from "../components/ProposalFilesUploadArea/ProposalFileUploadArea";
import { cancelReasons } from "../helpers/reasonCancel";

interface ModalCancelProps {
	isClose: () => void;
	isOpenModal: boolean;
	rows: any;
}

export const ModalCancelProposal: React.FC<ModalCancelProps> = ({ isClose, isOpenModal, rows }) => {
	const toastHandler = useToast();
	const navigate = useNavigate();
	const [file, setFile] = useState<File>();
	const [isLoading, setIsLoading] = useState(false);
	const [reasonCancel, setReasonCancel] = useState("");
	const { formRef } = useVForm();
	const [selectedDate, setSelectedDate] = useState("");
	const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState<boolean>(false);
	const [isUploading, setIsUploading] = useState<boolean>(false);

	const onFileUploadHandler = (file: File | undefined) => {
		setFile(file);
	};

	const formValidationSchemaCancel: yup.SchemaOf<ICancelProposal> = yup.object().shape({
		canceledType: yup.string().required("Campo Obrigatório"),
		canceledReason: yup.string().required("Campo Obrigatório"),
		canceledAt: yup.string().required("Campo Obrigatório"),
	});

	const isDateValid = () => {
		if (!selectedDate) return "";
		const currentDate = new Date();
		const selectedDateObj = new Date(selectedDate);
		return selectedDateObj <= currentDate;
	};

	const handleCancelProposal = (dados: ICancelProposal) => {
		if (!isDateValid()) {
			toastHandler.present({
				type: "error",
				position: "top-right",
				durationMs: 6000,
				messageOrContent: (
					<ToastContent
						title={"Erro ao lançar data de cancelamento"}
						content={"Não é permitido lançar a data de cancelamento maior que a data atual, verifique!"}
					/>
				),
			});

			setSelectedDate("");
			return;
		}

		setIsLoading(true);
		formValidationSchemaCancel
			.validate(dados, { abortEarly: false })
			.then((dadosvalidados) => {
				if (file) {
					ProposalDocumentsService.uploadProposalDocument(rows.id, "cd8a1b83-35ec-4dfc-a837-513e494007e8", file)
						.then((resultFile) => {
							if (resultFile.status === 400) {
								toastHandler.present({
									type: "error",
									position: "top-right",
									messageOrContent: <ToastContent title={"Erro"} content={"Erro ao cancelar garantia   |  " + resultFile.data.message} />,
								});
							} else {
								toastHandler.present({
									type: "success",
									messageOrContent: "Arquivo enviado com sucesso!",
									position: "top-center",
								});
								setFileUploadedSuccessfully(true);
								setTimeout(() => setFileUploadedSuccessfully(false), 250);
								ProposalService.updateCanceledId(rows.id, { ...dados }).then((result) => {
									if (result instanceof Error) {
										toastHandler.present({
											type: "error",
											position: "top-right",
											messageOrContent: <ToastContent title={"Erro"} content={"Erro ao cancelar garantia   |  " + result.message} />,
										});
									} else {
										toastHandler.present({
											type: "success",
											position: "top-right",
											messageOrContent: <ToastContent title={"Sucesso"} content={"Cancelamento iniciado com sucesso"} />,
										});
										navigate(`/garantias/`);
									}
								});
							}
						})
						.catch((errors) => {
							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={"Erro Arquivo"} content={"Erro ao enviar Arquivo   |  " + errors.message} />,
							});
						})
						.finally(() => {
							setIsUploading(false);
						});
				}
			})
			.catch((errors: yup.ValidationError) => {
				const validationErros: IVFormErros = {};
				errors.inner.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRef.current?.setErrors(validationErros);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<>
			{isOpenModal && (
				<ModalContext
					isCloseModal={isClose}
					isOpenModal={isOpenModal}
					messageType={"question"}
					showTitleIcon={false}
					overrideHeightValue="100%"
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					ITitle={"Deseja cancelar a garantia ?"}
					IMessage={[
						<Box key="modal-cancel" style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									ref={formRef}
									onSubmit={handleCancelProposal}
									placeholder=""
									onPointerEnterCapture={() => {}}
									onPointerLeaveCapture={() => {}}
								>
									<Grid container direction="column" spacing={1}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}

										<Box padding={2} style={{ background: "#ffe7e7", borderRadius: 8, marginBottom: 4 }}>
											<Typography color={"red"}>
												Para efetivação do cancelamento, é necessário anexar o termo de rescisão contratual de acordo com o motivo e os
												documentos disponíveis para o download.
											</Typography>
										</Box>

										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12} md={8} lg={8} xl={8}>
												<VTextField
													fullWidth
													label="Motivo do cancelamento"
													name="canceledType"
													disabled={isLoading}
													defaultValue="Outros"
													select
													onChange={(e) => setReasonCancel(e.target.value)}
												>
													{cancelReasons.map((reason, index) => (
														<MenuItem key={index} value={reason.value}>
															{reason.label}
														</MenuItem>
													))}
												</VTextField>
											</Grid>
											<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
												<VTextField
													fullWidth
													label="Data do cancelamento do contrato"
													name="canceledAt"
													disabled={false}
													type="date"
													onChange={(event: any) => setSelectedDate(event.target.value)}
												/>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12}>
												<VTextField fullWidth label="Descreva o motivo" name="canceledReason" disabled={isLoading} multiline rows={4} />
											</Grid>
										</Grid>
									</Grid>

									<ProposalFilesUploadArea
										onFileUploadHandler={onFileUploadHandler}
										isLoadingProp={isUploading}
										clear={fileUploadedSuccessfully}
									/>

									<Grid container sx={{ justifyContent: "center", display: "flex" }} padding={2} gap={2}>
										<Button color="inherit" disableElevation variant={"contained"} startIcon={<Icon>arrow_back</Icon>} onClick={isClose}>
											{"Voltar"}
										</Button>
										{reasonCancel !== "" && (
											<Button
												color="primary"
												disableElevation
												variant={"contained"}
												startIcon={<Icon>check</Icon>}
												onClick={() => {
													if (!file) {
														toastHandler.present({
															type: "error",
															position: "top-right",
															messageOrContent: (
																<ToastContent
																	title={"Erro"}
																	content={"Arquivo Termo de Entrega de Chaves e quitação de Débito Requerido"}
																/>
															),
														});
													} else {
														formRef.current?.submitForm();
													}
												}}
											>
												{"Cancelar Garantia"}
											</Button>
										)}
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}
		</>
	);
};
