import { AuthGuard } from '../../shared/guards/AuthGuard';
import { Sidebar } from '../../shared/components';
import { FinancialSummaryPresentation } from './components';

export const FinancialSummaryPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <FinancialSummaryPresentation />
            </Sidebar>
        </AuthGuard>
    )
};