import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Icon, LinearProgress, MenuItem, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useToast } from "../../../shared/hooks";
import { ToastContent } from "../../../shared/components";
import { VForm, VTextField, useVForm } from "../../../shared/forms";
import { FinancialService, ICancelSetup } from "../../../shared/services/api/financial/FinancialService";
import moment from "moment";

interface IModalCancelInvocieProps {
	id: string;
	clientName: string;
	dueDate: string;
	valueSetup: string;
	isOpenModal: boolean;
	isCloseModal?: () => void;
	widthModfy?: string;
	heightModfy?: string;
	onclickConfirm?: () => void;
	onCloseModal?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
}

export const ModalCancelSetup: React.FC<IModalCancelInvocieProps> = ({
	id,
	clientName,
	dueDate,
	valueSetup,
	isCloseModal,
	isOpenModal,
	onCloseModal,
	widthModfy,
	heightModfy,
	onclickConfirm,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const { formRef } = useVForm();
	const toastHandler = useToast();
	const [note, setNote] = useState("");
	const [reason, setReason] = useState("");

	const style = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: widthModfy?.trim() ? widthModfy : "auto",
		height: heightModfy?.trim() ? heightModfy : "auto",
		minWidth: "650px",
		bgcolor: "background.paper",
		border: "1px solid #605e5e",
		boxShadow: 24,
		p: 4,
		borderRadius: "6px",
	};

	const handleCancelSetup = (dados: ICancelSetup) => {
		dados.note = note;
		dados.reason = reason;
		console.log("id:", id, "", dados);
		FinancialService.CancelSetup(id, { ...dados }).then((result) => {
			setIsLoading(false);
			if (result instanceof Error) {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={"Erro ao cancelar o Taxa Setup"} />,
				});
			} else {
				toastHandler.present({
					type: "success",
					position: "top-right",
					messageOrContent: <ToastContent title={"Sucesso"} content={"Taxa cancelada com sucesso"} />,
				});
				isCloseModal;
				onclickConfirm ? onclickConfirm() : () => {};
			}
		});
	};

	return (
		<div>
			<Modal open={isOpenModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={onCloseModal}>
				<Box sx={style}>
					<DialogTitle style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<Typography id="modal-modal-title" variant="h5" color={"primary"} marginBottom={3}>
							Cancelamento de cobraça de taxa
						</Typography>
					</DialogTitle>
					<DialogContent style={{ display: "flex", justifyContent: "center", padding: "2px", margin: 0 }}>
						<Box sx={{ width: "100%" }}>
							<Typography>
								<VForm
									id="formIdCancelSetup"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRef}
									onSubmit={handleCancelSetup}
								>
									<Box className="teste" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}

										<Grid container item direction="row" spacing={1} sx={{ mb: 0 }}>
											<Grid item xs={2} sx={{ textAlign: "left" }}>
												Cliente :
											</Grid>
											<Grid item xs={8} sx={{ alignItems: "left" }}>
												<Typography variant="inherit" sx={{ fontWeight: "bold" }}>
													{clientName}
												</Typography>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={1} sx={{ mb: 2 }}>
											<Grid item xs={2} sx={{ textAlign: "left" }}>
												Vencimento :
											</Grid>
											<Grid item xs={2} sx={{ alignItems: "left" }}>
												<Typography variant="inherit" sx={{ fontWeight: "bold" }}>
													{moment(dueDate).format("L")}
												</Typography>
											</Grid>

											<Grid item xs={2} sx={{ textAlign: "right" }}>
												Valor :
											</Grid>
											<Grid item xs={3} sx={{ alignItems: "left" }}>
												<Typography variant="inherit" sx={{ fontWeight: "bold" }}>
													{parseFloat(valueSetup).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
												</Typography>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={2} mt={1}>
											<Grid item xs={12} md={8} lg={12} xl={12}>
												<VTextField
													fullWidth
													label="Motivo do cancelamento"
													name="reason"
													disabled={isLoading}
													defaultValue="Outros"
													select
													onChange={(e) => setReason(e.target.value)}
												>
													<MenuItem value={"Valor incorreto"}>Valor Incorreto</MenuItem>
													<MenuItem value={"Duplicidade"}>Duplicidade</MenuItem>
													<MenuItem value={"Isenção de encargos"}>Isenção</MenuItem>
													<MenuItem value={"Desistencia"}>Desistencia</MenuItem>
													<MenuItem value={"Outros"}>Outros</MenuItem>
												</VTextField>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12}>
												<VTextField
													fullWidth
													label="Descreva o motivo"
													name="note"
													disabled={isLoading}
													multiline
													rows={4}
													onChange={(e) => setNote(e.target.value)}
												></VTextField>
											</Grid>
										</Grid>
									</Box>
								</VForm>
							</Typography>
						</Box>
					</DialogContent>
					<DialogActions style={{ display: "flex", justifyContent: "center" }}>
						<Button onClick={isCloseModal} color="inherit" disableElevation variant={"contained"} startIcon={<Icon> arrow_back_icon</Icon>}>
							Voltar
						</Button>
						{reason != "" ? (
							<Button
								color="primary"
								disableElevation
								variant={"contained"}
								startIcon={<Icon> checkIcon</Icon>}
								onClick={() => {
									formRef.current?.submitForm(), isCloseModal;
								}}
							>
								Confirmar
							</Button>
						) : (
							""
						)}
					</DialogActions>
				</Box>
			</Modal>
		</div>
	);
};
