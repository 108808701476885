import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToolbarUpdateProposal } from '../../../shared/components/toolbar/report/toolbarUpdateProposal';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { useProfile } from '../../../context/ProfileContext';
import { useDebounce, useToast } from '../../../shared/hooks';
import { ModalInstallment, ToastContent } from '../../../shared/components';
import { returnMessageError } from '../../../shared/utils/returnMessageError';
import {
  SummaryFinancialProps,
  formatFinancialTotalValues,
  formatListFinancial,
} from '../../../shared/types/summaryFinancial';
import {
  PermissionActionsEnum,
  PermissionRolesEnum,
} from '../../../shared/enums/profiles';
import {
  ProfileGuard,
  checkByProfileGuard,
} from '../../../shared/guards/ProfileGuard';
import { Environment } from '../../../shared/environment';
import { differenceInDays } from 'date-fns';
import { ToolbarGridFilterReport } from '../../../shared/components/toolbarGridReport/ToolbarGridFilterReport';
import { formatScaleValueBR } from '../../../shared/utils/formatScaleValue';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import moment from 'moment';
import { globalTranslate, globalTranslateStatus } from '../../../shared/utils';
import { FinancialService } from '../../../shared/services/api/financial/FinancialService';
import {
  IProposalList,
  IReportProposalList,
  ProposalService,
} from '../../../shared/services/api/proposal/ProposalService';
import { ListSearchParams } from '../../analysis/types/list-search-params.type';
function colorText(status: string) {
  if (status === 'expired') {
    return '#e2c93a';
  }
  if (status === 'active') {
    return '#5ebe76';
  }
  if (status === 'canceled') {
    return '#eb3232';
  }
  if (status === 'suspended') {
    return '#eb3232';
  }
  if (status === 'renovated') {
    return '#3761eb';
  }

  return '#202020';
}

function colorBackGround(status: string) {
  if (status === 'renovated') {
    return '#a9e9eb';
  }
  if (status === 'suspended') {
    return '#EEC5C5';
  }
  if (status === 'active') {
    return '#C5EEC9';
  }
  if (status === 'expired') {
    return '#F4ECC0';
  }
  if (status === 'canceled') {
    return '#EEC5C5';
  }

  return '#959595';
}
function colorIcon(status: string) {
  if (status === 'expired') {
    return '#e2d73a';
  }
  if (status === 'active') {
    return '#5ebe76';
  }
  if (status === 'canceled') {
    return '#eb3232';
  }
  if (status === 'suspended') {
    return '#eb3232';
  }
  if (status === 'renovated') {
    return '#3761eb';
  }
  return '#202020';
}

function colorLine(parcela: number) {
  if (parcela % 2 == 0) {
    return '#e5e8eb';
  }
  return '#ffffff';
}

export const ReportProposalPresentation = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const toastHandler = useToast();
  const { debounce } = useDebounce();
  const [isLoading, setIsLoading] = useState(false);
  const [dataIni, setDataIni] = useState(
    moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD')
  );
  const [dataFim, setDataFim] = useState(
    moment().endOf('month').format('YYYY-MM-DD')
  );
  const [rows, setRows] = useState<IReportProposalList[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowPages, setRowPages] = useState(100);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  // const [listSummaryFinancial, setListSummaryFinancial] = useState<SummaryFinancialProps[]>([]);

  // const [isLoadingTotalValues, setIsLoadingTotalValues] = useState(false);
  // const [summaryFinancial, setSummaryFinancial] = useState<SummaryFinancialProps | null | undefined>(null);
  // const [listSummaryFinancialAnalytical, setListSummaryFinancialAnalytical] = useState<IInstallmentUpdateProposalAnalytical[]>([]);
  const [dataClientFilter, setDataClientFilter] = useState('');
  const [dataManagerFilter, setDataManagerFilter] = useState('');
  const [dataOrigemFilter, setDataOrigemFilter] = useState('');

  const hasViewPermission = useMemo(() => {
    return checkByProfileGuard(
      PermissionRolesEnum.WarrantiesOptions,
      PermissionActionsEnum.View
    );
  }, []);

  const { isSuperAdmin, permissionLevel } = useProfile();
  const [pesquisar, setPesquisar] = useState(true);

  const filterInitialParams: ListSearchParams = useMemo(() => {
    return {
      status: 'Todos',
      origem: 'Todos',
      tipo: 'documento',
      dataIni: moment()
        .startOf('month')
        .subtract(24, 'months')
        .format('YYYY-MM-DD'),
      dataFim: moment().endOf('month').format('YYYY-MM-DD'),
      typePeriod: 'contratacao',
    };
  }, []);

  const searchParamsInitial = () => {
    setSearchParams(
      {
        ...searchParams,
        status: filterInitialParams.status,
        origem: filterInitialParams.origem,
        typePeriod: filterInitialParams.typePeriod,
        tipo: filterInitialParams.tipo,
        dataini: moment('01/01/2019').format('YYYY-MM-DD'),
        datafim: filterInitialParams.dataFim,
      },
      { replace: true }
    );
  };

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    const page = searchParams.get('pagina') ? searchParams.get('pagina') : '1';
    return Number(page);
  }, [searchParams]);

  // //const { isSuperAdmin } = useProfile();
  // const [typeReport, setTypeReport] = useState('Sintético');
  // const [page, setPage] = useState(1);
  // const [totalCount, setTotalCount] = useState(0);
  // const [statusInstallment, setStatusInstallment] = useState<string>('proposal');
  // const [openModalInstallment, setOpenModalInstallment] = useState(false);
  // const [rowsInstallment, setRowsInstallment] = useState<IInstallmentDocument[]>([]);
  // const [isChangeTypeReport, setIsChangeTypeReport] = useState(false);

  //  const handleOpenModalInstallment = () => {
  //     setOpenModalInstallment(true);
  // };

  // const handleCloseModalInstallment = () => {
  //     setOpenModalInstallment(false);
  // };

  // const getInstallmentsByClient = (idClient: string) => {
  //     setIsLoading(true);
  //     handleOpenModalInstallment();
  //     setRowsInstallment([]);

  //     debounce(() => {
  //         FinancialService.getInstallmentsByClient(idClient, dataIni, dataFim, statusInstallment)
  //             .then((result: IInstallmentDocument[] | Error) => {
  //                 setIsLoading(false);
  //                 if (result instanceof Error) {
  //                     const titleMessage = "Alerta!!";
  //                     const contentMessage = "Erro: " + result?.message;

  //                     toastHandler.present({
  //                         type: 'error',
  //                         position: 'top-right',
  //                         messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
  //                     });
  //                 } else {
  //                     setRowsInstallment(result);
  //                 }
  //             }).catch((err: any) => {
  //                 setIsLoading(false);
  //                 const { titleMessage, contentMessage } = returnMessageError(err);

  //                 toastHandler.present({
  //                     type: 'error',
  //                     position: 'top-right',
  //                     messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
  //                 })
  //             })
  //     });
  // }

  // const getListInstallmentUpdateProposal = () => {
  //     debounce(() => {
  //         FinancialService.getInstallmentUpdateProposal(dataClientFilter, dataIni, dataFim)
  //             .then(result => {
  //                 setIsLoading(false);
  //                 if (result instanceof Error) {
  //                     const titleMessage = "Alerta!!";
  //                     const contentMessage = "Erro: " + result?.message;

  //                     toastHandler.present({
  //                         type: 'error',
  //                         position: 'top-right',
  //                         messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
  //                     });

  //                 } else setListSummaryFinancial(formatListFinancial(result));

  //             }).catch(err => {
  //                 setIsLoading(false);
  //                 const { titleMessage, contentMessage } = returnMessageError(err);

  //                 toastHandler.present({
  //                     type: 'error',
  //                     position: 'top-right',
  //                     messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
  //                 })
  //             })
  //     });
  // }

  const clickSearchUpdateProposal = () => {
    setIsLoading(true);
    setPesquisar(!pesquisar);
    // if (dataIni == null || dataIni == "null" || dataIni == undefined || dataIni == "undefined") {
    //     setDataIni('');
    // }
    // if (dataFim == null || dataFim == "null" || dataFim == undefined || dataFim == "undefined") {
    //     setDataFim('');
    // }

    //     debounce(() => {
    //         console.log('162',dataIni,dataFim)
    //         FinancialService.getReportProposal(dataClientFilter, dataManagerFilter, dataOrigemFilter, dataIni, dataFim)
    //         .then((result:any) => {
    //             console.log('chamando....')
    //             setIsLoading(false);
    //            // setIsExtractPDF(false);
    //             if (result instanceof Error) {
    //               //alert(result.message);
    //             } else {
    //               //setModalDetail(true)
    //             }
    //           });

    //         // FinancialService.getInstallmentUpdateProposalAnalytical(dataClientFilter, dataIni, dataFim, statusInstallment, page?.toString(), Environment.LIMITE_LINHAS_GREAT?.toString())
    //         //     .then((data: InstallmentUpdateProposalCountData<
    //         //         IInstallmentUpdateProposalAnalytical[]
    //         //     > | Error) => {
    //         //         setIsLoading(false);
    //         //         if (data instanceof Error) {
    //         //             const titleMessage = "Alerta!!";
    //         //             const contentMessage = "Erro: " + data?.message;

    //         //             toastHandler.present({
    //         //                 type: 'error',
    //         //                 position: 'top-right',
    //         //                 messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
    //         //             });

    //         //         } else {
    //         //             setListSummaryFinancialAnalytical(data.result);
    //         //             setTotalCount(data.resultCount);
    //         //             if (data.resultCount < Environment.LIMITE_LINHAS_GREAT) {
    //         //                 setPage(1);
    //         //             }
    //         //         }

    //         //     }).catch(err => {
    //         //         setIsLoading(false);
    //         //         const { titleMessage, contentMessage } = returnMessageError(err);

    //         //         toastHandler.present({
    //         //             type: 'error',
    //         //             position: 'top-right',
    //         //             messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
    //         //         })
    //         //     })
    //     });
  };

  //}

  // const onChangeTypeReport = (event: SelectChangeEvent) => {
  //     setTypeReport(event.target?.value);
  //     setIsChangeTypeReport(true);
  //     changeTypeReport();
  // }

  // const changeTypeReport = () => {
  //     if (typeReport == 'Analítico') {
  //         setPage(page);
  //         setTotalCount(0);
  //         setStatusInstallment("proposal")
  //         setListSummaryFinancial([]);
  //         setSummaryFinancial(null);
  //     } else {
  //         setListSummaryFinancialAnalytical([]);
  //         setRowsInstallment([]);
  //     }
  // }

  // const paginationAnalytical = () => {
  //     setIsChangeTypeReport(false);
  //     if (typeReport == 'Analítico') {
  //         setPage(page);
  //     }
  // }

  // useEffect(() => {
  //     if (typeReport == 'Analítico' && !isChangeTypeReport) {
  //         clickSearchUpdateProposal();
  //     }
  // }, [statusInstallment, page]);

  const clickExportCsv = async () => {
    setIsLoading(true);
    try {
      const { data } = await FinancialService.getExportCsv(
        page,
        rowPages,
        dataClientFilter,
        dataManagerFilter,
        dataOrigemFilter,
        dataIni,
        dataFim
      );
      setIsLoading(false);

      if (!data)
        toastHandler.present({
          type: 'error',
          position: 'top-right',
          messageOrContent: (
            <ToastContent
              title={'Erro'}
              content={'Erro ao consultar registro!'}
            />
          ),
        });
      else if (data?.size == 0)
        toastHandler.present({
          type: 'info',
          position: 'top-right',
          messageOrContent: (
            <ToastContent
              title={'Atenção!'}
              content={
                'Nenhum registro encontrado referente a busca solicitada!'
              }
            />
          ),
        });
      else {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'exported_data.csv');
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      console.error('Erro ao fazer o download do arquivo:', error);
      const titleMessage = 'Alerta!!';
      const contentMessage = 'Erro: ' + 'Não foi possível fazer download';

      toastHandler.present({
        type: 'error',
        position: 'top-right',
        messageOrContent: (
          <ToastContent title={titleMessage} content={contentMessage} />
        ),
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (hasViewPermission) {
      setIsLoading(true);
      debounce(() => {
        FinancialService.getReportProposal(
          page,
          rowPages,
          dataClientFilter,
          dataManagerFilter,
          dataOrigemFilter,
          dataIni,
          dataFim
        )
          //getAll(pagina, busca,
          //tipoProposal, origemProposal, statusProposal, dataIniProposal, dataFimProposal, clientFilter, managerId, typePeriod, tombamento
          // )
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              const titleMessage = 'Alerta!!';
              const contentMessage = 'Erro: ' + result?.message;

              toastHandler.present({
                type: 'error',
                position: 'top-right',
                messageOrContent: (
                  <ToastContent title={titleMessage} content={contentMessage} />
                ),
              });
            } else {
              setTotalCount(result?.totalCount);
              setRows(result?.data);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            const { titleMessage, contentMessage } = returnMessageError(err);

            toastHandler.present({
              type: 'error',
              position: 'top-right',
              messageOrContent: (
                <ToastContent title={titleMessage} content={contentMessage} />
              ),
            });
          });
      });
    }
  }, [
    page,
    pesquisar,
    rowPages,
    //statusProposal, pesquisar, tombamento
  ]);

  return (
    <LayoutBaseDePagina
      titulo="Relatórios de Garantias"
      subtitulo=""
      barraDeFerramentas={
        <ToolbarUpdateProposal
          clickBack={() => navigate('/relatorios')}
          onClick={clickSearchUpdateProposal}
          onClickExport={clickExportCsv}
          showInputManagerFilter={true}
          showInputOrigem={true}
          showButtonFilter={true}
          onChangeClientFilter={(value) => {
            setDataClientFilter(value);
            // paginationAnalytical();
          }}
          onChangeManagerFilter={(value) => {
            setDataManagerFilter(value);
            // paginationAnalytical();
          }}
          onChangeOrigem={(value) => {
            setDataOrigemFilter(value);
            // paginationAnalytical();
          }}
          showLimit={true}
          onChangeLimit={(value) => {
            setRowPages(Number(value));
            // paginationAnalytical();
          }}
          onChangeDataIni={(value) => {
            setDataIni(value);
            // paginationAnalytical();
          }}
          onChangeDataFim={(value) => {
            setDataFim(value);
            // paginationAnalytical();
          }}
          loading={isLoading}
          // onChangeTypeReport={onChangeTypeReport}
        />
      }
    >
      <ProfileGuard
        requiredRole={PermissionRolesEnum.WarrantiesOptions}
        requiredAction={PermissionActionsEnum.View}
      >
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{ marginTop: 1, width: 'auto', padding: '20px' }}
        >
          <Table aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={8} sx={{ padding: '0px' }}>
                  {/* <ToolbarGridProposal
                    textSearchGrid={busca}
                    onChangeTextSearchGrid={(text) => setSearchParams({ busca: text, pagina: '1', tipo: tipoProposal, status: '', origem: origemProposal, dataini: dataIniProposal, datafim: dataFimProposal, clientFilter: clientFilter, managerId, typePeriod, tombamento }, { replace: true })}
                    showInputSearchGrid={false}
                    onClickAll={() => setSearchParams({ busca, pagina: String(1), tipo: tipoProposal, status: 'Todos', origem: origemProposal, dataini: dataIniProposal, datafim: dataFimProposal, clientFilter: clientFilter, managerId, typePeriod, tombamento }, { replace: true })}
                    onClickActive={() => setSearchParams({ busca, pagina: String(1), tipo: tipoProposal, status: 'active', origem: origemProposal, dataini: dataIniProposal, datafim: dataFimProposal, clientFilter: clientFilter, managerId, typePeriod, tombamento }, { replace: true })}
                    onClickExpires={() => setSearchParams({ busca, pagina: String(1), tipo: tipoProposal, status: 'expires', origem: origemProposal, dataini: dataIniProposal, datafim: dataFimProposal, clientFilter: clientFilter, managerId, typePeriod, tombamento }, { replace: true })}
                    onClickExpired={() => setSearchParams({ busca, pagina: String(1), tipo: tipoProposal, status: 'expired', origem: origemProposal, dataini: dataIniProposal, datafim: dataFimProposal, clientFilter: clientFilter, managerId, typePeriod, tombamento }, { replace: true })}
                    onClickCanceled={() => setSearchParams({ busca, pagina: String(1), tipo: tipoProposal, status: 'canceled', origem: origemProposal, dataini: dataIniProposal, datafim: dataFimProposal, clientFilter: clientFilter, managerId, typePeriod, tombamento }, { replace: true })}
                    onClickSuspended={() => setSearchParams({ busca, pagina: String(1), tipo: tipoProposal, status: 'suspended', origem: origemProposal, dataini: dataIniProposal, datafim: dataFimProposal, clientFilter: clientFilter, managerId, typePeriod, tombamento }, { replace: true })}
                    onClickRenovated={() => setSearchParams({ busca, pagina: String(1), tipo: tipoProposal, status: 'renovated', origem: origemProposal, dataini: dataIniProposal, datafim: dataFimProposal, clientFilter: clientFilter, managerId, typePeriod, tombamento }, { replace: true })}

                    selectAll={statusProposal === 'Todos'}
                    selectAtive={statusProposal === 'active'}
                    selectRenovated={statusProposal === 'renovated'}
                    selectExpires={statusProposal === 'expires'}
                    selectExpired={statusProposal === 'expired'}
                    selectSuspended={statusProposal === 'suspended'}
                    selectCanceled={statusProposal === 'canceled'}
                  /> */}
                </TableCell>
              </TableRow>

              <TableRow sx={{ background: '#f5fbfd' }}>
                <TableCell sx={{ color: '#7e7777b5' }}> Parceiro</TableCell>
                <TableCell sx={{ color: '#7e7777b5' }}> Tipo imóvel</TableCell>
                <TableCell sx={{ color: '#7e7777b5' }}> Endereço</TableCell>
                <TableCell sx={{ color: '#7e7777b5' }}> Nome </TableCell>
                <TableCell sx={{ color: '#7e7777b5' }}> V. parcela </TableCell>
                <TableCell sx={{ color: '#7e7777b5' }}> V. aluguel</TableCell>
                <TableCell sx={{ color: '#7e7777b5' }}> Origem</TableCell>
                <TableCell sx={{ color: '#7e7777b5' }}> Emissão</TableCell>
                <TableCell sx={{ color: '#7e7777b5' }}> Estatus</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading &&
                rows.map((row) => (
                  <TableRow key={row.id} hover>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        color={'#5c5858'}
                        sx={{ fontWeight: 'normal' }}
                      >
                        {row.client ? row.client : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        color={'#5c5858'}
                        sx={{ fontWeight: 'normal' }}
                      >
                        {row.property_finally != undefined
                          ? globalTranslate(row.property_finally)
                          : ''}
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ color: '#959595', fontWeight: 'normal' }}>
                      <Typography
                        variant="subtitle2"
                        color={'#959595'}
                        fontWeight={'normal'}
                      >
                        {row.address_street != null && row.address_street != ''
                          ? `
                        ${
                          row.address_complement != null &&
                          row.address_complement != ''
                            ? `${row.address_street} , ${row.address_number} - ${row.address_complement} - ${row.address_district}`
                            : `${row.address_street} , ${row.address_number} - ${row.address_district}`
                        }`
                          : 'Endereço não cadastrado'}
                        {row.address_city != null && row.address_city != ''
                          ? `${row.address_city} - ${row.address_state}`
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'normal' }}>
                      <Typography
                        variant="subtitle2"
                        color={'#959595'}
                        fontWeight={'normal'}
                      >
                        {`${row.name} - ${
                          row.document.length > 11
                            ? row.document.replace(
                                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                                '$1.$2.$3/$4-$5'
                              )
                            : row.document.replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                                '$1.$2.$3-$4'
                              )
                        }`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        color={'#5c5858'}
                        sx={{ fontWeight: 'normal' }}
                      >
                        {parseFloat(
                          `${row?.assurance_month_amount}`
                        ).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        color={'#5c5858'}
                        sx={{ fontWeight: 'normal' }}
                      >
                        {/* {maskReal(`${row?.assuranceMonthAmount}`)} */}
                        {parseFloat(`${row?.rent_amount}`).toLocaleString(
                          'pt-br',
                          { style: 'currency', currency: 'BRL' }
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        color={'#5c5858'}
                        sx={{ fontWeight: 'normal' }}
                      >
                        {`${
                          row?.created_from == 'web_app'
                            ? 'Manual'
                            : 'Integrada'
                        } `}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        color={'#959595'}
                        fontWeight={'normal'}
                      >
                        {moment(
                          row.term_begin
                            ? row.term_begin.substring(0, 10) + ' 03:00:01'
                            : '2000-01-01 03:00:01'
                        ).format('L')}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'start' }}>
                      <Typography
                        variant="subtitle2"
                        color={'#959595'}
                        fontWeight={'normal'}
                      >
                        {globalTranslate(row.status)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <LinearProgress variant="indeterminate" />
                  </TableCell>
                </TableRow>
              )}
              {totalCount > 0 && (
                <TableRow>
                  <TableCell colSpan={5} sx={{ borderBottom: 'none' }}>
                    <Pagination
                      page={page}
                      count={Math.ceil(totalCount / rowPages)}
                      onChange={(_, newPage) => {
                        setSearchParams(
                          {
                            busca,
                            pagina: newPage.toString(),
                            //status: statusProposal, tipo: tipoProposal, origem: origemProposal, dataini: dataIniProposal, datafim: dataFimProposal, clientFilter: clientFilter, managerId, typePeriod, tombamento
                          },
                          { replace: true }
                        );
                        setPage(newPage);
                      }}
                    />
                  </TableCell>
                  <TableCell colSpan={5} sx={{ borderBottom: 'none' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                      Exibindo {page * rowPages - (rowPages - 1)}
                      {' - '}
                      {rowPages * (page - 1) + rowPages * page < totalCount
                        ? rowPages * page
                        : totalCount}{' '}
                      de
                      <Typography
                        variant="overline"
                        sx={{
                          fontWeight: 'bold',
                          color: '#0170B3',
                          fontSize: '16px',
                        }}
                      >
                        {' '}
                        {totalCount}{' '}
                      </Typography>
                      registro(s)
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </TableContainer>
      </ProfileGuard>
      <Grid container display="flex" flexDirection="row"></Grid>
    </LayoutBaseDePagina>
  );
};
